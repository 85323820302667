import Tooltip from '@components/common/Tooltip'
import React, { useState } from 'react'
import { OCRContent } from './OCRContent'
import './styles.css'

export const OCR = ({ id, platform, image, children }) => {
  const [open, setOpen] = useState(false)
  const [openTips, setOpenTips] = useState(false)

  const content = (
    <div styleName="popover">
      <button styleName="close" onClick={() => setOpen(false)}>
        <i className="icon-close" />
      </button>
      <OCRContent image={image} id={id} platform={platform} />
    </div>
  )

  return (
    <div className="relative">
      <div styleName="wrapper">
        <Tooltip
          className="tooltip-image-item"
          inline={true}
          placement="top"
          trigger="click"
          tooltip={content}
          delayHide={120}
          tooltipShown={open}
          onVisibilityChange={show => {
            if (show) {
              setOpen(true)
            } else {
              setOpen(false)
            }
          }}
        >
          <button styleName="btn">
            <Tooltip
              inline={true}
              placement="right"
              trigger="hover"
              tooltip="Click this icon to translate any text in this image."
              delayHide={120}
              tooltipShown={openTips && !open}
              onVisibilityChange={show => {
                if (show) {
                  setOpenTips(true)
                } else {
                  setOpenTips(false)
                }
              }}
            >
              <span styleName="inner" />
              <span styleName="outer" />
            </Tooltip>
          </button>
        </Tooltip>
      </div>
      {children}
    </div>
  )
}
