import ACTION from '@constants/coupon'
import { CheckedCoupon } from '@interfaces/coupon'
import { createStandardAction } from 'typesafe-actions'

export const applyCoupon = createStandardAction(ACTION.USE_COUPON_REQUEST).map((code: string) => ({
  payload: { code },
  meta: {
    lifecycle: {
      resolve: ACTION.USE_COUPON_SUCCESS,
      reject: ACTION.USE_COUPON_FAILURE,
    },
  },
}))

export const applyCouponSuccess = createStandardAction(ACTION.USE_COUPON_SUCCESS)<CheckedCoupon>()

export const applyCouponFailure = createStandardAction(ACTION.USE_COUPON_FAILURE)<Error>()
