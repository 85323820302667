import { Platform } from '@interfaces/platform'
import { fetchSellerDetails } from '@services/seller'
import { FRESH_MARKET_SELLER, GENERAL_MARKET_SELLER } from '@utils/productUtils'
import { useEffect, useState } from 'react'

export const useSellerDetails = (id: number, platform: Platform) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [data, setData] = useState<any>({ id: 0, titles: {} })

  const setTmallData = (en: string) => {
    setData({ id, titles: { en, zhCn: '天猫超市' } })
    setLoading(false)
  }

  useEffect(
    () => {
      if (id === FRESH_MARKET_SELLER) {
        return setTmallData('FRESH MARKET')
      }
      if (id === GENERAL_MARKET_SELLER) {
        return setTmallData('GENERAL MARKET')
      }

      const sub = fetchSellerDetails(id, platform).subscribe((data: any) => {
        setData(data)
        setLoading(false)
      })

      return () => sub.unsubscribe()
    },
    [id]
  )

  return { loading, data }
}
