import React, {FC} from "react";
import {FormattedMessage} from 'react-intl'
import './styles.css'

const BASE_URL = 'https://res.cloudinary.com/baopals/image/upload/v1593739689/'

const Unit: FC<{type: string}> = ({type}) => {
  return (
    <div className='tw-py-4' styleName='wrapper'>
      <div>
        <img src={`${BASE_URL}${type}.jpg`} />
      </div>
      <div className='tw-pt-4 bold'>
        <FormattedMessage id={`express.benefit-title-${type}`} />
      </div>
    </div>
  )
}

export default Unit
