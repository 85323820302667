import * as ACTION from '@actions/orders'
import { Item, Order, OrderStatus } from '@interfaces/order'
import { PaginationMeta } from '@interfaces/pagination'
import { ReviewData } from '@interfaces/reviews'
import { createReducer } from 'typesafe-actions'

export interface OrdersState {
  readonly orders: Order[]
  readonly parcels: Order[]
  readonly items: Item[]
  readonly dimensions: any[]
  readonly parcelItems: any[]
  readonly meta: PaginationMeta
  readonly reviews: ReviewData[]
}

const initialMeta = { total: 0, currentPage: 1, totalPages: 0 }

const initialState: OrdersState = {
  orders: [],
  items: [],
  parcels: [],
  meta: initialMeta,
  reviews: [],
  dimensions: [],
  parcelItems: [],
  packageingFormula: {},
}

export const orders = createReducer(initialState)
  .handleAction(ACTION.fetchOrders, state => ({
    ...state,
    items: [],
    reviews: [],
    parcels: [],
  }))
  .handleAction(ACTION.fetchSingleOrderSuccess, (state, action) => ({
    ...state,
    orders: [action.payload.order],
    items: action.payload.items,
    dimensions: action.payload.dimensions,
    packageingFormula: action.payload.packageingFormula,
    parcelItems: action.payload.parcelItems,
    warehouseFees: action.payload.warehouseFees,
  }))
  .handleAction(ACTION.cancelOrderSuccess, (state, { payload }) => {
    const id = +payload.id
    const index = state.orders.findIndex(o => o.bundleId === id || o.id === id)
    return {
      ...state,
      orders: state.orders
        .slice(0, index)
        .concat({ ...state.orders[index], status: OrderStatus.CANCELD })
        .concat(state.orders.slice(index + 1)),
    }
  })
  .handleAction(ACTION.deleteOrderSuccess, (state, { payload }) => {
    const id = +payload.id

    return {
      ...state,
      orders: state.orders.filter(o => ![o.bundleId, o.id].includes(id)),
    }
  })
  .handleAction(ACTION.fetchOrdersSuccess, (state, action) => ({
    ...state,
    orders: action.payload.orders,
    items: action.payload.items,
    reviews: action.payload.reviews,
    meta: action.payload.meta,
    parcelItems: action.payload.parcelItems,
  }))
  .handleAction(ACTION.fetchParcelsSuccess, (state, action) => ({
    ...state,
    parcels: action.payload.orders,
    items: action.payload.items,
    meta: action.payload.meta,
  }))
  .handleAction(ACTION.archiveUnarchiveOrderSuccess, (state, { payload }) => {
    const id = +payload.id
    const index = state.orders.findIndex(o => o.bundleId === id || o.id === id)

    return {
      ...state,
      orders: state.orders
        .slice(0, index)
        .concat({ ...state.orders[index], archived: payload.archive })
        .concat(state.orders.slice(index + 1)),
    }
  })
  .handleAction(ACTION.updateOrderItemsReview, (state, { payload: { review } }) => {
    const index = state.reviews.findIndex(r => r.productId === review.productId)
    return {
      ...state,
      reviews:
        index > -1
          ? state.reviews
            .slice(0, index)
            .concat(review)
            .concat(state.reviews.slice(index + 1))
          : state.reviews.concat(review),
    }
  })
