import React from 'react'
import { Panel } from 'react-bootstrap'
import * as service from '../../../../services/category'
import CategoryItemUnit from './CategoryItemUnit'
import './styles.css'

const ROOT_CATEGORY_ID = '50514008'

class CategoryItem extends React.Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      activeCategory: props.activeCategory,
    }

    this.handleOnToggle = this.handleOnToggle.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    const curActiveCategory = this.props.activeCategory
    const nextActiveCategory = nextProps.activeCategory

    if (curActiveCategory !== nextActiveCategory) {
      this.setState({ activeCategory: nextActiveCategory })
    }
  }

  handleOnToggle(id) {
    const { activeCategory } = this.state
    const currentToggleAncestors = this.findAncestors(id)
    const currentActiveAncestors = this.findAncestors(activeCategory)

    const currentToggleAncestor = currentToggleAncestors[currentToggleAncestors.length - 1]
    const currentActiveAncestor = currentActiveAncestors[currentActiveAncestors.length - 1]

    if (currentToggleAncestor === currentActiveAncestor) {
      this.setState({ activeCategory: '' })
    } else {
      this.setState({ activeCategory: id })
    }
  }

  findAncestors(id) {
    var parentId = id
    const ancestors = []
    const { categories } = this.props

    while (parentId && parentId !== this.props.rootCategory) {
      const parentCat = categories.find(c => c.id === parentId)

      if (parentCat) {
        ancestors.push(parentCat.id)
        parentId = parentCat.parent
      } else {
        parentId = undefined
      }
    }

    return ancestors
  }

  render() {
    const { id, main, nestedCategories, categories } = this.props
    const { activeCategory } = this.state

    const currentCat = categories.find(d => d.id === activeCategory)
    const expandedIdList = [activeCategory]

    if (currentCat) {
      expandedIdList.push(...this.findAncestors(currentCat.parent))
    }

    return nestedCategories.map((cat, index) => {
      const expanded = expandedIdList.indexOf(cat.id) > -1
      return cat.children ? (
        <Panel
          eventKey={cat.id}
          key={cat.id}
          styleName="panel"
          expanded={expanded}
          onClick={() => this.handleOnToggle(cat.id)}
        >
          <Panel.Heading
            styleName={`panel-heading ${expanded ? 'active' : ''} ${main ? 'main' : ''} ${
              expanded ? 'expanded' : ''
            }`}
            data-active={expanded}
          >
            <Panel.Title>
              {main && (
                <Panel.Toggle componentClass="span">
                  <span className="tw-ml-2 cursor-pointer" styleName="icon">
                    {expanded ? (
                      <i className="icon-arrow-down" />
                    ) : (
                      <i className="icon-arrow-right" />
                    )}
                  </span>
                </Panel.Toggle>
              )}

              <CategoryItemUnit
                id={cat.id}
                name={cat.name}
                seller={cat.seller}
                link={this.props.renderLink(cat)}
                hasChild={true}
                isActive={cat.id === this.props.activeCategory}
              />
            </Panel.Title>
          </Panel.Heading>
          <Panel.Body collapsible>
            {cat.children ? (
              <CategoryItem
                main={false}
                id={index}
                renderLink={this.props.renderLink}
                nestedCategories={cat.children}
                categories={categories}
                activeCategory={this.props.activeCategory}
                rootCategory={this.props.rootCategory}
              />
            ) : (
              cat.name
            )}
          </Panel.Body>
        </Panel>
      ) : (
        <div className="category-list-unit d-flex align-items-center" data-main={main}>
          <CategoryItemUnit
            key={cat.id}
            id={cat.id}
            name={cat.name}
            seller={cat.seller}
            hasChild={false}
            isActive={cat.id === this.props.activeCategory}
            link={this.props.renderLink(cat)}
          />
        </div>
      )
    })
  }
}

export default CategoryItem
