import { of, Observable } from 'rxjs'
import { map, pluck, tap, catchError } from 'rxjs/operators'
import ajax from '../api/ajax'
import { formatOrderStatus } from './order'

const toCountryWithMethodStr = (countryCode, shippingMethod) => `${countryCode}-${shippingMethod ?? ''}`
const toTitleStr = (titles) => titles?.en ?? titles?.zhCn

const transformFormulas = (formulas, deliveries) => {
  return formulas.map(f => {
    const delivery = deliveries.find(d => d.shippingMethod === f.shippingMethod)

    return {
      range: [/^\d+$/.test(f.weightMin) ? +f.weightMin : f.weightMin].concat(f.weightMax ? [+f.weightMax] : []),
      additional: [f.additionalFee.price / 100, f.additionalFee.weight],
      base: f.baseFee.price / 100,
      baseWeight: f.baseFee.weight,
      deliveryTime: [+delivery.daysMin, +delivery.daysMax],
      shippingMethod: f.shippingMethod,
    }
  })
}

export const fetchWarehouseItems = (): Observable<any> =>
  ajax({
    url: `/global/cart/items`,
    method: 'GET',
  }).pipe(
    pluck('response', 'data'),
    map(({
       shippingLines, receivedItems, products, shipping, dimensions, warehouseFees, selection,
       pendingItems, bulkDiscount, warehouseFormulas, largePackage, extraFees, pro
    }) => {
      const formula = shipping.reduce((prev, next) => ({ ...prev, [next.country]: transformFormulas(next.formulas, next.deliveries) }), {})

      const items = receivedItems.map(({ orderItem, ...rest }) => {
        const product = products.find(p => p.id === orderItem.productId)
        return {
          ...orderItem,
          ...rest,
          orderItemId: orderItem.id,
          title: toTitleStr(product.titles),
          seller: product.seller,
          shopName: product.shop?.titles?.en ?? product.shop?.titles?.zhCn,
          countryWithMethod: toCountryWithMethodStr(rest.countryCode, rest.shippingMethod),
          trackingId: orderItem.id
        }
      })

      const pendingOrderItems = pendingItems.map(({ order: { orderItems, country, shippingMethod } }) => orderItems.map(i => ({
        ...i,
        status: formatOrderStatus(i.status, i.inWarehouse, i.inGlobalParcel),
        countryWithMethod: toCountryWithMethodStr(country, shippingMethod),
        title: toTitleStr(i.titles),
        shopName: i.sellerName,
        trackingId: i.id
      }))).flat()

      return {
        shippingLines,
        selection,
        warehouseFees,
        warehouseFormulas,
        items,
        formula,
        dimensions: dimensions.map(d => ({ ...d, externalId: d.externalOrderId || d.externalId })),
        pendingItems: pendingOrderItems,
        bulkDiscount,
        largePackage,
        extraFees,
        proDiscountPercent: pro?.discountPercent || 0
      }
    })
  )

export const fetchShippingFormula = (country: string) =>
  ajax({
    url: `/global/shipping`,
    method: 'GET',
    query: { countries: country }
  }).pipe(
    pluck('response', 'data'),
    map((data) => ({
      [country]: transformFormulas(data.shipping[0].formulas, data.shipping[0].deliveries),
      [`text_${country}`]: data.texts.find(t => t.country === country)?.feesText
    })),
    catchError(() => of({ [country]: [] }))
  )
