import {Platform} from '@interfaces/platform';

const SHITTY_TAOBAO_CDN = /^https?:\/\/img\d+\.taobaocdn\.com\//;
const CN_TAOBAO_CDN = /^https?:\/\/img\.alicdn\.com\//;
const GW_TAOBAO_CDN = /^https?:\/\/gw\.alicdn\.com\//;

export const imageToObject = url => {
  if (!url) {
    return null;
  }

  if (SHITTY_TAOBAO_CDN.test(url)) {
    url = url.replace(SHITTY_TAOBAO_CDN, "");
  } else if (CN_TAOBAO_CDN.test(url)) {
    url = url.replace(CN_TAOBAO_CDN, "");
  } else if (GW_TAOBAO_CDN.test(url)) {
    url = url.replace(GW_TAOBAO_CDN, "");
  }

  url = url.replace(/_\d+x\d+$/, "");

  return {
    provider: "taobao",
    id: url
  };
};

const tbRegex = /(http|https):\/\/[\w]+.[\w]+.*\/item.htm\?.*?id=([\d]+).*?/;
const jdRegex = /(http|https):\/\/[\w]+.[\w]+.*\/([\d]+)\.htm/;

export const isProductUrl = text => {
  try {
  const tbMatch = text.match(tbRegex);
  const jdMatch = text.match(jdRegex);

  const isInvalid = !tbRegex && !jdRegex
  if (isInvalid) {
    return false;
  }

  const id = parseInt(tbMatch?.[2] || jdMatch?.[2]);

  return {  
    ...(!isNaN(id) && { id }),
    ...(!isInvalid && { platform: jdMatch?.[2] ? Platform.JD : Platform.TAOBAO })
  }
  } catch(err) {
    return { }
  }
};
