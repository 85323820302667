import React        from 'react'
import Slider       from 'react-slick'
import { useMedia } from 'use-media';
import Product from "./Product";
import { AttachableData } from "@interfaces/blog";
import "./styles.css"

type ProductSliderProps = {
  products: AttachableData[];
}

const ProductsSlider: React.SFC<ProductSliderProps> = props => {
  const slider = React.useRef(null)

  const preventClickOnDragging = (e: any) => {
    if(slider.current.innerSlider.state.animating) {
      e.preventDefault()
      return
    }
  }

  const isXS = useMedia({ maxWidth: 767 });
  const { products } = props

  const slideCount = (() => {
    if (isXS) return 2
    return 4
  })()

  const Arrow = (side: string) =>
    <div>
      <i className={`icon-arrow-${side}`} />
    </div>

  const settings = {
    className: "responsive-slider",
    dots: true,
    infinite: false,
    slidesToShow: slideCount,
    slidesToScroll: slideCount,
    nextArrow: Arrow('right'),
    prevArrow: Arrow('left'),
    lazyLoad: true,
  }

  return (
    <Slider {...settings} ref={slider}>
      {products.map((product: any) => (
        <Product
          key={product.id}
          product={product}
          preventClickOnDragging={preventClickOnDragging}
        />
      ))}
    </Slider>
  )
}

export default ProductsSlider
