import React from 'react'
import { Dot, CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel'
import { cloudObjectToURL } from '@utils/cloudStorage'
import { DEFAULT_PRODUCT_PLACEHOLDER } from '@constants/index'
import SlideImage from './SlideImage'
import './styles.css'

const StyledButton = ({ direction }: { direction: 'left' | 'right' }) => {
  const child = <i className={`icon-arrow-${direction}`} />

  const className =
    'tw-z-20 bd-light tw-rounded-full tw-cursor-pointer tw-text-[12px] tw-leading-none tw-absolute tw-top-8 tw-p-2'

  if (direction === 'left') {
    return (
      <ButtonBack styleName="buttonBack" data-direction={direction} className={className}>
        {child}
      </ButtonBack>
    )
  }

  return (
    <ButtonNext styleName="buttonBack" data-direction={direction} className={className}>
      {child}
    </ButtonNext>
  )
}

export const Image = props => {
  return (
    <CarouselProvider
      naturalSlideWidth={100}
      naturalSlideHeight={100}
      totalSlides={props.images.length}
    >
      <div className="relative bd-light">
        <Slider>
          {props.images.map((img, i) => (
            <SlideImage img={img} index={i} />
          ))}
        </Slider>

        {props.images.length > 1 && (
          <>
            <StyledButton direction="left" />
            <StyledButton direction="right" />
          </>
        )}
        {props.children}
      </div>

      {props.images.length > 1 && (
        <div
          className="tw-flex tw-flex-row tw-space-x-3 tw-mt-4 md:tw-hidden"
          styleName="img"
        >
          {/*{props.images.slice(0, 4).map((img, i) => {*/}
            {/*const pic = cloudObjectToURL(img, { size: '80x80' }) || DEFAULT_PRODUCT_PLACEHOLDER*/}
            {/*return (*/}
              {/*<Dot slide={i} className="tw-aspect-square bd-none tw-basis-1/5 tw-px-0" key={i}>*/}
                {/*<img className="bd-light" src={pic} />*/}
              {/*</Dot>*/}
            {/*)*/}
          {/*})}*/}
        </div>
      )}
    </CarouselProvider>
  )
}

Image.defaultProps = {
  images: [],
  withDot: false,
}
