import React from 'react'
import {Route, Switch} from 'react-router-dom'
import LocationWidget from './Widget'

const Location = ({onChange}) => (
  <Switch>
    <Route path="/supermarket" component={null} />
    <Route path="/seller/:id" component={null} />
    <Route path="/express/category/:id" component={null} />
    <Route
      path="*"
      render={() => (
        <>
          <div className="visible-xs">
            <LocationWidget onChange={onChange} className="tw-mb-6 tw-pb-2" />
          </div>

          <div className="hidden-xs">
            <LocationWidget className="tw-mr-4 products-filter-location" />
          </div>
        </>
      )}
    />
  </Switch>
)

export default Location
