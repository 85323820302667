import React from 'react'
import { Navbar } from 'react-bootstrap'
import Trending from './Trending'
import SearchInputWithSuggestions from 'components/common/SearchInputWithSuggestions'

const SearchNavbar = ({scrolled }) => {
  return (
    <Navbar className={`navbar navbar-scroll ${scrolled ? 'scrolled' : ''}`}>
      <div className="search-scroll">
        <div className="col-md-8 col-md-offset-2">
          <div className="form-horizontal">
            <SearchInputWithSuggestions />
            <Trending />
          </div>
        </div>
      </div>
    </Navbar>
  )
}

export default SearchNavbar
