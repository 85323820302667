import { Pagination as Pager } from '@react-bootstrap/pagination'
import qs from 'qs'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { RouteComponentProps, withRouter } from 'react-router'
import './styles.css'

interface AwesomePaginationProps extends RouteComponentProps<{ page?: string }> {
  totalPages?: number
  totalCount?: number
  itemsPerPage?: number
  summary?: boolean
  maxButtons?: number
  bsSize?: string
}

const AwesomePagination: React.FC<AwesomePaginationProps> = ({
  summary,
  totalCount,
  itemsPerPage,
  ...props
}) => {
  const query = qs.parse(props.location.search, { ignoreQueryPrefix: true })
  const page = +query.page || 1

  const handlePageChange = (page: number) => {
    props.history.push({
      pathname: props.match.url,
      search: qs.stringify({ ...query, page }),
    })
  }

  const totalPages = props.totalPages || Math.ceil(totalCount / itemsPerPage)

  return (
    <div>
      <Pager
        styleName="pagination"
        first={true}
        last={true}
        prev={true}
        next={true}
        ellipsis={true}
        maxButtons={props.maxButtons || 5}
        bsSize={props.bsSize || 'large'}
        items={totalPages}
        activePage={page}
        onSelect={handlePageChange}
      />

      {summary && (
        <div>
          <span className="search--pager__total linethrough text-center">
            <FormattedMessage
              id="general.pagination_help_text"
              values={{
                start: (page - 1) * itemsPerPage + 1,
                end: Math.min(page * itemsPerPage, totalCount),
                total: totalCount,
              }}
            />
          </span>
        </div>
      )}
    </div>
  )
}

AwesomePagination.defaultProps = {
  summary: false,
}

export default withRouter(AwesomePagination)
