import * as ACTION from '@actions/products'
import { LOCATION_CHANGE } from 'connected-react-router'
import qs from 'qs'
import { createReducer, isOfType } from 'typesafe-actions'

export interface ProductAffiliate {
  affiliate: string
  via: string

  affiliateSId: string
}

const initialState: ProductAffiliate = {
  affiliate: '',
  via: '',
  affiliateSId: '',
}

export const productAffiliate = createReducer(initialState).handleAction(
  LOCATION_CHANGE,
  (state, action) => {
    const query = qs.parse(action.payload.location.search, { ignoreQueryPrefix: true })

    return {
      ...state,
      affiliate: query.at,
      via: query.via,
      affiliateSId: query.at_sid,
    }
  }
  // isOfType(LOCATION_CHANGE),
  // (state, { type, payload }) => {
  //  const query = qs.parse(payload.location.search, { ignoreQueryPrefix: true })

  //  return {
  //    ...state,
  //    affiliate: query.at,
  //    via: query.via,
  //    affiliateSId: query.at_sid,
  //  }
  // }
)
