import React from 'react'

const Arrow: React.SFC<any> = ({ side, className, style, onClick }) => (
  <div className={className} style={style} onClick={onClick}>
    <span className={`slick-divider ${side}`} />
    <i className={`icon-arrow-${side}`} />
  </div>
)

export default Arrow
