const { formatDecimal } = require("./number");

//const calcFinalPrice = (minPrice, maxPrice, minPriceWithDiscount, maxPriceWithDiscount) => {
//  if (minPriceWithDiscount && maxPriceWithDiscount) {
//    return maxPriceWithDiscount > minPriceWithDiscount ? minPriceWithDiscount + ' - ' + maxPriceWithDiscount : maxPriceWithDiscount
//  } else {
//    return maxPrice> minPrice ? minPrice + ' - ' + maxPrice : maxPrice
//  }
//}

const calcPriceStr = (min, max, qty = 1) =>
  `¥${
    max > min
      ? formatDecimal(+calcBaopalPrice(min, qty), 2) +
        " - " +
        formatDecimal(+calcBaopalPrice(max, qty), 2)
      : formatDecimal(+calcBaopalPrice(max, qty), 2)
  }`;

/*
 * Formula for Baopals price calculation
 * 0 - 29.99 rmb = 2 + 5%
 * 30 - 59.99 rmb = 4 + 5%
 * 60 - 89.99 rmb = 6 + 5%
 * 90 and above = 8 + 5%
 */
function calcBaopalPrice(price, qty = 1) {
  //return formatDecimal(+formatDecimal(price) * qty)

  // if (!isNumeric(price)) throw new Error('The price is invalid')
  const minQty = getMinQty(price);
  const priceWithRate = +price + price * 0.06;

  const finalPrice = (() => {
    if (+price >= 0 && +price <= 29.99) return priceWithRate + 2;
    if (+price >= 30 && +price <= 59.99) return priceWithRate + 4;
    if (+price >= 60 && +price <= 89.99) return priceWithRate + 6;

    return priceWithRate + 8;
  })();

  if (minQty > 1 && qty > 10) {
    return formatDecimal(
      +(+formatDecimal(finalPrice) * 10) + +(+formatDecimal(price) * (qty - 10))
    );
  } else {
    return formatDecimal(+formatDecimal(finalPrice) * qty);
  }
}

//function calcBaopalPriceWithQty(price, qty) {
//  return formatDecimal(+formatDecimal(calcBaopalPrice(price)) * qty)
//  //if (qty <=1 ) return calcBaopalPrice(price, true)
//  //else {
//  //  return calcBaopalPrice(price, true) + calcBaopalPrice(price, false) * (qty - 1)
//  //}
//}

function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

function getMinQty(price) {
  if (price >= 0.01 && price <= 0.49) {
    return 100;
  } else if (price >= 0.5 && price <= 0.99) {
    return 50;
  } else if (price >= 1.0 && price <= 1.49) {
    return 25;
  } else if (price >= 1.5 && price <= 1.99) {
    return 10;
  } else if (price >= 2.0 && price <= 2.99) {
    return 5;
  } else if (price >= 3.0 && price <= 3.99) {
    return 4;
  } else if (price >= 4.0 && price <= 4.99) {
    return 3;
  } else if (price >= 5.0 && price <= 5.99) {
    return 2;
  }

  return 1;
}

exports.calcPriceStr = calcPriceStr;
//exports.calcFinalPrice = calcFinalPrice
exports.calcBaopalPrice = calcBaopalPrice;
exports.getMinQty = getMinQty;
//exports.calcBaopalPriceWithQty = calcBaopalPriceWithQty
