import * as PARCEL_ACTIONS from '@actions/orders'
import * as ACTIONS from '@actions/warehouse'
import { useActions } from '@hooks/useActions'
import { useDispatch, useSelector } from 'react-redux'

export const useWarehouseItems = () => {
  const dispatch = useDispatch()
  const state = useSelector(state => state.warehouseItems)
  const parcelsState = useSelector(state => state.orders)

  const actions = useActions(ACTIONS)
  const parcelActions = useActions(PARCEL_ACTIONS)

  return {
    ...state,
    actions,
    parcelActions,
    incompletePaymentsParcels: {
      orders: parcelsState.parcels,
      items: parcelsState.items,
      parcelItems: parcelsState.parcelItems,
    },
  }
}
