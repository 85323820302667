import SEO from '@components/SEO'
import ROUTING from '@constants/url'
import { useMatchPath } from '@hooks/useRouter'
import React from 'react'

const products = {
  title: 'Hot Products',
  description: `Browse the most popular products in China across all Baopals categories. Find the best products with real customer reviews and spot the latest trends.`,
}

const HotSEO = () => {
  const isProduct = useMatchPath(ROUTING.HOT)
  const data = isProduct ? products : null

  if (!data) {
    return null
  }

  return <SEO {...data} />
}

export default HotSEO
