export enum HttpStatus {
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  CONFLICT = 409,
  INVALID_TOKEN = 498,
  RACK_ATTACK = 429,
  UNKNOWN = 500,

  UNPROCESSABLE_ENTITY = 422,
}
