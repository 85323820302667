import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link, Route, Switch } from 'react-router-dom'
import ROUTING from '@constants/url'
import { Nav, NavItem, Navbar } from 'react-bootstrap'
import HomePage from '../../home'
import ChinaOnly from '../../common/ChinaOnly'
import { BeijingTime } from './BeijingTime'
import { isGreaterChina, isSoutheastAsia } from '@utils/i18n'
import { useReferals } from '../../../hooks/useReferrals'
import './styles.css'

const hideBeijingTime = (country) => {
  return isGreaterChina(country) || isSoutheastAsia(country)
}

const NavLinks = ({ lang, scrolled }) => {
  const data = useReferals()

  return (
    <Navbar
      className={`navbar navbar-secondary upper ${scrolled ? 'scrolled' : ''}`}
      styleName="container"
    >
      <Nav>
        <Switch>
          <Route path="/" exact={true} component={null} />
          <Route
            path="/*"
            component={() => {
              return (
                <span styleName="wrapper">
                  <ChinaOnly otherwiseRender={<BeijingTime />} isChina={hideBeijingTime} />
                </span>
              )
            }}
          />
        </Switch>

        <NavItem eventKey="blog" href={`/blog`} to={`/blog`} componentClass={Link} rel="noopener">
          <FormattedMessage id="header.blog" />
        </NavItem>
        <NavItem eventKey="dot1" className="list-dot">
          &middot;
        </NavItem>
        <NavItem href="/help/" to="/help/" componentClass={Link} rel="noopener" eventKey="tips">
          HELP CENTER
        </NavItem>
        <NavItem eventKey="dot3" className="list-dot">
          &middot;
        </NavItem>
        <ChinaOnly
          otherwiseRender={
            <NavItem
              eventKey="shipping_fees"
              href="/overseas-shipping-rates"
              to="/overseas-shipping-rates"
              componentClass={Link}
              rel="noopener"
            >
              Shipping Fees
            </NavItem>
          }
        >
          <NavItem
            eventKey="ccc"
            href="/blog?cat=the-ccc"
            to="/blog?cat=the-ccc"
            componentClass={Link}
            rel="noopener"
          >
            <FormattedMessage id="header.the_ccc" />
          </NavItem>
        </ChinaOnly>
        <NavItem eventKey="dot2" className="list-dot">
          &middot;
        </NavItem>
        <NavItem
          href={ROUTING.CLOTHING_SIZE_GUIDE}
          to={ROUTING.CLOTHING_SIZE_GUIDE}
          componentClass={Link}
          rel="noopener"
          eventKey="clothing-size-guide"
        >
          CLOTHING SIZE GUIDE
        </NavItem>
        <NavItem eventKey="dot3" className="list-dot">
          &middot;
        </NavItem>
        <NavItem
          href={ROUTING.GET_THE_APP}
          to={ROUTING.GET_THE_APP}
          componentClass={Link}
          rel="noopener"
          eventKey="refer-a-pal"
        >
          <i className="icon-baopals" /> GET THE APP
        </NavItem>
      </Nav>
    </Navbar>
  )
}

export default NavLinks
