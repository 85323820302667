import {FC} from 'react'
import {Panel} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import './styles.css'

const LinkTo = ({id, active, onClick, name}) => (
  <Link
    to={`/express/category/${id}`}
    className={`tw-my-4 fs14 d-block link-black ${active ? 'link-active' : ''}`}
    onClick={onClick}
  >
    {name}
  </Link>
)

const Item: FC<{activeId?: number}> = ({category, activeId, onClick}) => {
  return (
    <Panel eventKey={category.id} styleName="panel" className="tw-mt-0" data-id={category.id}>
      <Panel.Heading className="strong">
        <Panel.Title toggle={true}>
          <LinkTo id={category.id} name={category.name} active={false} onClick={() => {}} />
        </Panel.Title>
      </Panel.Heading>
      <Panel.Body collapsible={true} className="tw-py-0">
        {category.children.map(c => (
          <LinkTo id={c.id} name={c.name} active={activeId === c.id} onClick={onClick} />
        ))}
      </Panel.Body>
    </Panel>
  )
}

Item.defaultProps = {}

export default Item
