export enum Platform {
  TAOBAO = 'taobao',
  TMALL = 'tmall',
  JD = 'jingdong',
}

export enum StockState {
  AVAILABLE = 33,
  ON_THE_WAY_TO_WAREHOUSE = 39,
  ON_THE_WAY_FROM__WAREHOUSE = 40,
  PRESALES = 36,
  UNAVAILABLE = 34,
}

export const AVAILABLE_STOCK_STATE = [
  StockState.AVAILABLE,
  StockState.ON_THE_WAY_TO_WAREHOUSE,
  StockState.ON_THE_WAY_FROM__WAREHOUSE,
]
