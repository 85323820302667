import { MyEarningsLink } from '@components/account/AccountWithSidebar/Sidebar'
import UserWalletBalance from '@components/common/UserWalletBalance'
import ROUTING from '@constants/url'
import useAuth from '@hooks/useAuth'
import React, { FC } from 'react'
import SubMenu from '../SubMenu'
import { ModalProps } from '../SubMenu/interface'

const WalletMenu: FC<ModalProps> = props => {
  const { user } = useAuth()
  if (!user) {
    return null
  }

  const items = [
    {
      text: (
        <>
          MY WALLET {'('}
          <UserWalletBalance />
          {')'}
        </>
      ),
      link: ROUTING.ACCOUNT_MY_WALLET,
    },
    {
      text: (
        <span className="text-uppercase">
          <MyEarningsLink />
        </span>
      ),
      link: ROUTING.ACCOUNT_EARNINGS,
    },
    {
      text: 'MY REFERRALS',
      link: ROUTING.ACCOUNT_REFERRALS,
    },
  ]

  return (
    <UserWalletBalance>
      {({ enable }) => enable && <SubMenu label="Wallet" items={items} {...props} />}
    </UserWalletBalance>
  )
}

export default WalletMenu
