import Username from '@components/auth/SignUp/SignUpForm/Username'
import { useCountries } from '@hooks/useCountries'
import AnimatedButton from '@components/common/AnimatedButton'
import AuthModalTrigger from '@components/common/AuthModalTrigger'
import TextBox from '@components/common/Inputs/TextBox'
import { SYSTEM_UNAVAILABLE } from '@constants/'
import { ConflictError } from '@errors/ConflictError'
import useAuth from '@hooks/useAuth'
import { Auth, ValidateUserOpt } from "@interfaces/auth";
import { imageUrlToFile } from '@utils/image'
import React, { FC, useEffect, useRef, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useFormState } from 'react-use-form-state'
import Avatar from './Avatar'
import './styles.css'
import TermsOfUse from './TermsOfUse'
import { useEnter } from '@hooks/useKeyCode'
import CountrySelector from '@components/common/CountrySelector'
import * as api from '@services/auth'
import { useQuery } from '@hooks/useRouter'
import { useMyIp } from '@hooks/useMyIp'
import { WHITE_LIST } from '@constants/index'
import './styles.css'

export const MIN_PASSWD_LENGTH = 8
const MIN_USERNAME_LENGTH = 5

export const validateRequired = (field: string, value: string) => {
  if (!value.trim()) {
    return `${field} can't be blank`
  }
}

export const validateLength = (field: string, value: string, min: number) => {
  if (value.trim().length < min) {
    return `${field} is too short (minimum is ${min} characters)`
  }
}

const inputProps = {
  autoCorrect: 'off',
  autoComplete: 'off',
  autoCapitalize: 'none',
}

const validateUsername = (value: string) =>
  (/^[-_a-z\d]+$/i.test(value) ? undefined : 'Username can contain only letters and numbers') ||
  (value.toUpperCase().includes('BAOPAL') ? 'This username is unavailable' : undefined)



export const validatePassword = (value: string) => {
  const STRONG_PASSWORD_REGEX = /^(?:(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*)$/

  if (!STRONG_PASSWORD_REGEX.test(value)) {
    return 'Please use at least one capital letter, one lowercase letter, and one number in your password.'
  }
}

export const validateEmail = (value: string, ele: HTMLInputElement) => {
  return (
    validateRequired('Email', value) ||
    (ele.validity.typeMismatch
      ? `Please include an '@' in the email address. '${value}' is missing an '@'.`
      : undefined)
  )
}

interface FormProps {
  // Wechat
  avatar?: string // auto fill
  username?: string // auto fill

  auth?: Auth
}

const Form: FC<FormProps> = props => {
  const [formState, { email, password, text }] = useFormState()
  const { data } = useMyIp()
  const query = useQuery()

  const [avatar, setAvatar] = useState<File>(null)
  const [country, setCountry] = useState(WHITE_LIST.includes(data.countryCode) ? data.countryCode : 'cn')
  const [usernameExistsError, setUsernameExistsError] = useState<string>(undefined)
  const [emailError, setEmailError] = useState<string>(undefined)
  const [affiliateTokenExistsError, setAffiliateTokenExistsError] = useState<string>(undefined)

  useEffect(
    () => {
      if (props.avatar) {
        imageUrlToFile(props.avatar)
          .then(file => {
            setAvatar(file)
          })
          .catch(() => { })
      }
    },
    [props.avatar]
  )

  useEffect(() => {
    if (query.at) {
      formState.setField('affiliateToken', query.at)
      handleValidateUser({ type: 'affiliate_token', sq: query.at })
    }
    if (query.email) {
      formState.setField('email', query.email)
    }
  }, [])

  // Auto fill username when it exist
  useEffect(
    () => {
      if (props.username) {
        formState.setField('username', props.username)
      }
    },
    [props.username]
  )

  const { signup } = useAuth()

  const emailInput = useRef(null)
  const btnRef = useRef(null)

  const { values } = formState
  const { affiliateToken, ...restValues } = values

  const hasFormValue = Object.values(restValues).every(v => v)
  const hasFormError = Object.values(formState.validity).some(v => v === false)
  const hasExistError = usernameExistsError || emailError || affiliateTokenExistsError

  const shouldDisableSubmit = !hasFormValue || hasFormError || hasExistError

  const enter = useEnter()

  const { countries, isLoading } = useCountries()

  if (isLoading) {
    return <>Loading...</>
  }

  const handleSubmit = () => {
    if (shouldDisableSubmit) return

    // const at = (() => {
    //   const [affiliate, ts] = (readAffiliateFromLocalStorage() || '').split(';')
    //   if (affiliate && +ts && Date.now() - +ts < 86400) {
    //     return affiliate
    //   }
    // })()

    const validateValueInvalid = (errors: any[]) => (errors || []).some(v => v.error === 'invalid')

    const form = {
      ...restValues,
      ...(avatar && { avatar }),
      ...(affiliateToken && { affiliateToken }),
      ...(props.auth && { auth: props.auth }),
      country: countries.find(c => c.abbr === country).alias
    }

    return signup(form)
      .then(() => props.onSubmitted(form))
      .catch(err => {
        console.error(err)

        if (err instanceof ConflictError) {
          return Promise.reject(err.message)
        }
        if (validateValueInvalid(err.error?.email)) {
          setEmailError(`Invalid email`)
        }

        return Promise.reject(SYSTEM_UNAVAILABLE)
      })
  }

  if (enter) {
    if (shouldDisableSubmit) return

    return btnRef.current.click()
  }

  const handleValidateUser = (opt: ValidateUserOpt) => {
    const updateErrorFunc = (() => {
      switch (opt.type) {
        case 'username':
          return setUsernameExistsError
        case 'email':
          return setEmailError
        case 'affiliate_token':
          return setAffiliateTokenExistsError
      }
    })()


    if (opt.sq) {
      api.validateUser(opt).toPromise()
        .then(() => updateErrorFunc?.(undefined))
        .catch(err => {
          if (err.duplicated) {
            updateErrorFunc?.(err.duplicated);
          }
        })
    } else {
      updateErrorFunc?.(undefined);
    }
  }

  return (
    <form autoComplete="off" className="signup__form" styleName="form">
      {props.avatar && <Avatar url={props.avatar} />}
      {props.username && <Username username={props.username} />}
      <Row>
        <Col xs={12} className="tw-mb-3">
          <label htmlFor={'country'} className="control-label">
            Where will you have your Baopals orders shipped?
          </label>
          <CountrySelector
            whiteList={WHITE_LIST}
            selected={country}
            onChange={(country) => setCountry(country.abbr)}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <TextBox
            id="name"
            label={'First Name:'}
            placeholder={'Enter your first name'}
            error={formState.errors?.firstName}
            value={formState.values.firstName}
            {...inputProps}
            {...text({
              name: 'firstName',
              validate(value, values, event) {
                return validateRequired('First Name', value)
              },
            })}
          />
        </Col>
        <Col xs={6}>
          <TextBox
            id="lastName"
            label={'Last Name:'}
            placeholder={'Enter your last name'}
            error={formState.errors?.lastName}
            value={formState.values.lastName}
            {...inputProps}
            {...text({
              name: 'lastName',
              validate(value, values, event) {
                return validateRequired('Last Name', value)
              },
            })}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <TextBox
            id="email"
            label={'Email:'}
            forwardRef={emailInput}
            placeholder="Enter your email address"
            error={formState.errors?.email || emailError}
            value={formState.values.email}
            {...inputProps}
            {...email({
              name: 'email',
              onBlur: e => handleValidateUser({ type: 'email', sq: e.target.value }),
              validate(value, values, event) {
                return validateEmail(value, emailInput.current)
              },
            })}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={6}>
          <TextBox
            id="username"
            label={'Username:'}
            placeholder="Enter your username"
            error={formState.errors?.username || usernameExistsError}
            value={formState.values.username}
            {...inputProps}
            minLength={5}
            {...text({
              name: 'username',
              onBlur: e => handleValidateUser({ type: 'username', sq: e.target.value }),
              validate(value, values, event) {
                return (
                  validateRequired('Username', value) ||
                  validateLength('Username', value, MIN_USERNAME_LENGTH) ||
                  validateUsername(value)
                )
              },
            })}
          />
        </Col>

        <Col xs={6}>
          <TextBox
            id="password"
            label={'Password:'}
            placeholder="Enter your password"
            minLength={8}
            error={formState.errors?.password}
            value={formState.values.password}
            {...inputProps}
            {...password({
              name: 'password',
              validate(value, values, event) {
                return (
                  validateRequired('Password', value) ||
                  validateLength('Password', value, MIN_PASSWD_LENGTH) ||
                  validatePassword(value)
                )
              },
            })}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <TextBox
            id="affiliateToken"
            label={'Referral Code:'}
            placeholder="Enter a referral code or leave this blank"
            error={formState.errors?.affiliateToken || affiliateTokenExistsError}
            value={formState.values.affiliateToken}
            required={false}
            {...inputProps}
            {...text({
              name: 'affiliateToken',
              onBlur: e => handleValidateUser({ type: 'affiliate_token', sq: e.target.value }),
            })}
          />
        </Col>

        <AnimatedButton
          className="btn btn-animated-default tw-my-6 py-4 btn-register strong fs18"
          styleName="button"
          type="button"
          onClick={handleSubmit}
          disabled={shouldDisableSubmit}
          forwardRef={btnRef}
          {...shouldDisableSubmit && { successTransition: 0, errorTransition: 0 }}
        >
          Create Account
        </AnimatedButton>
      </Row>

      <small>
        <FormattedMessage id="sign_up.have_an_account" /> <AuthModalTrigger />
      </small>

      <div className="tw-mt-6">
        <TermsOfUse />
      </div>
    </form>
  )
}

Form.defaultProps = {
  username: '',
}

export default Form
