export class HttpError extends Error {
  public httpCode: number
  public error: any

  constructor(httpCode: number, error?: any) {
    super(`${httpCode}`)
    Object.setPrototypeOf(this, HttpError.prototype)

    if (httpCode) {
      this.httpCode = httpCode
    }
    if (error) {
      this.error = error
    }

    // this.stack = new Error().stack
  }
}
