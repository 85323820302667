import React, { PureComponent } from 'react'
import { toProductLinkStr } from '@components/common/ProductLink'
import { Modal } from 'react-bootstrap'
import { withRouter } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import ShareButtonGroup from '@components/common/ShareButtonGroup'
import Input from './Input'
import './styles.css'

class MobileShareModal extends PureComponent {
  render() {
    const { platform, open, title, pic, location, onClose, id } = this.props
    const url = `https://www.baopals.com${toProductLinkStr(id, { platform })}`

    return (
      <Modal className="product-share-mobile" show={open} onHide={onClose}>
        <div className="p-4">
          <div className="d-flex justify-content-between tw-mb-6">
            <FormattedMessage id="product_page.share_modal_mobile.share_icon" />
            <div onClick={onClose}>
              <i className="icon-close fs12 black-link" />
            </div>
          </div>
          <div className="tw-w-full tw-mb-6" styleName="group">
            <ShareButtonGroup title={title} pic={pic} url={url} id={+id} platform={platform} />
          </div>
          <Input value={url} />
        </div>
      </Modal>
    )
  }
}

MobileShareModal.defaultProps = {
  open: false,
}
MobileShareModal.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
  pic: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default withRouter(MobileShareModal)
