import React, {useEffect} from 'react'
import {FormattedMessage} from 'react-intl'
import {animateScroll} from 'react-scroll'
import './styles.css'

interface TopProps {
  className?: string
  scroll?: boolean
  withNavigation?: boolean
}

const BacktoTop: React.FC<TopProps> = props => {
  const handleScroll = () => {
    const scroll = window.scrollY
    if (props.scroll && scroll > 900) {
      document.body.classList.add('footer-scrolled')
      if(document.querySelector('#cookie-policy')) {
        document.querySelector('#cookie-policy').classList.add('cookie-with-back-to-top')
      }
    } else if (props.scroll && scroll <= 900) {
      document.body.classList.remove('footer-scrolled')
      if(document.querySelector('#cookie-policy')) {
        document.querySelector('#cookie-policy').classList.remove('cookie-with-back-to-top')
      }
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  const className = ['text-center']
  if (props.className) {
    className.push(props.className)
  }

  if (props.withNavigation) {
    className.push('with-navigation')
  }

  // will fixed on bottom if scroll to show
  if (props.scroll) {
    className.push('totop')
  }

  const onScroll = () => animateScroll.scrollToTop()

  return (
    <div className={className.join(' ')} styleName="wrapper" onClick={onScroll}>
      <div styleName="text">
        <div className="icon-arrow-up fs12 tw-pt-2" />

        <div className="fs12 bold upper tw-mb-2 hidden-xs hidden-sm">
          <FormattedMessage id="footer.back_to_top" defaultMessage="BACK TO TOP" />
        </div>
      </div>

      <div className="hidden-xs hidden-sm" styleName="background" />
    </div>
  )
}

BacktoTop.defaultProps = {
  withNavigation: true,
  scroll: false,
}

export default BacktoTop
