import { composeSkuKey, toSku } from './product/skuOptionUtils'
import { totalPrice, getMinimumQuantity as getMinQuantity } from './product/priceUtils'
import { hasSku, containsOption, containsId, toIds } from './product/skuUtils'
import { Platform } from '@interfaces/platform'

export const FRESH_MARKET_SELLER = 1910146537
export const GENERAL_MARKET_SELLER = 725677994
export const APPLIANCE_MARKET_SELLER = 2136152958

/**
 *
 * return a range of 2 baopals prices for a given quantity and sku options
 *
 * @param product (Product)
 * @param selectedOptions (Array[Product/SkuOption])
 * @param quantity (integer) - default 1
 * @returns {[int minPrice, intMaxPrice]}
 */
export const getPrices = (product, selectedOptions, quantity = 1) => {
  const [minPrice, maxPrice] = getOriginalPrices(product, selectedOptions)
  return [totalPrice(minPrice, quantity), totalPrice(maxPrice, quantity)]
}

/**
 *
 * return a range of 2 original prices for a given quantity and sku options
 *
 * @param product (Product)
 * @param selectedOptions (Array[Product/SkuOption])
 * @param quantity (integer) - default 1
 * @returns {[int minPrice, intMaxPrice]}
 */
export const getOriginalPrices = (product, selectedOptions) => {
  let prices = [product.minPrice, product.maxPrice]

  if (product.promotion) {
    prices = [product.promotion.minPrice, product.promotion.maxPrice]
  }

  if (selectedOptions?.length) {
    prices = getSkuPrices(product, selectedOptions)
  }

  return [Math.min.apply(Math, prices), Math.max.apply(Math, prices)]
}

/**
 *
 * returns the minimum quantity for a given product and sku options
 *
 * @param product (Product)
 * @param selectedOptions (Array[Product/SkuOption])
 * @returns {number}
 */
export const getMinimumQuantity = (product, selectedOptions) => {
  const prices = getOriginalPrices(product, selectedOptions)
  const hasNegativePrice = prices.some(v => v <= 0)

  // Apply minimum quantity on the smallest price
  return hasNegativePrice ||
    (product.platform !== Platform.JD && product.skus.length > 0 && selectedOptions.length === 0)
    ? Math.max(1, product.multipleOrderQuantity)
    : getMinQuantity(prices[0], product.multipleOrderQuantity)
}

/**
 *
 * returns the available stock for a given product and sku options
 *
 * @param product (Product)
 * @param selectedOptions (Array[Product/SkuOption])
 * @returns {number}
 */
export const getAvailableStock = (product, selectedOptions) => {
  if (selectedOptions?.length) {
    return getSkuStock(product, selectedOptions)
  } else if (
    selectedOptions?.length === 0 &&
    product.platform === Platform.JD &&
    product.skus?.length === 1
  ) {
    return product.skus[0].quantity || 0
  }

  return product.stock || 0
}

/**
 *
 * returns whether or not a product is unavailable.
 *
 * returns false if a product is available
 * returns a string if the product is not
 *
 * - area => the product is disabled by taobao
 * - stock => not enough stock for this product to be ordered
 * - shop => the product has no shop
 * - seller => the seller is banned
 * - provider => the product doesn't exist in provider system anymore
 * - banned => product has been banned by content team
 *
 * @param product (Product)
 * @param selectedOptions (Array[Product/SkuOption])
 * @param quantity (integer)
 * @returns {string}
 */
export const getUnavailability = (product, selectedOptions, quantity) => {
  //if (!product.shop) return "shop";

  //const titles = product.shop.titles;
  //if (!product.shop.id || (!titles[snakeLocale] && !titles[camelLocale])) {
  //  return "shop";
  //}

  const stock = getAvailableStock(product, selectedOptions)
  const minQty = getMinimumQuantity(product, selectedOptions)

  if (stock === 0 || quantity > stock || quantity < minQty) {
    return 'stock'
  }

  return product.unavailable
}

/**
 *
 * returns whether or not a sku is complete, ie if all options have a selection
 *
 * @params product (Product)
 * @params selectedOptions (Array[SkuOption])
 *
 * @return {boolean}
 */
export const isSkuComplete = (product, selectedOptions) => {
  return product.skuCategories.length == selectedOptions.length
}

// Private

// returns sku prices for selected options
const getSkuPrices = (product, selectedOptions) => {
  return (
    getSelectedSku(product, selectedOptions)?.prices ??
    (product.promotion?.minPrice && product.promotion?.maxPrice
      ? [product.promotion?.minPrice, product.promotion?.maxPrice]
      : [product.minPrice, product.maxPrice])
  )
}

// returns stock for selected options
const getSkuStock = (product, selectedOptions) =>
  getSelectedSku(product, selectedOptions)?.quantity ?? product.stock

// Returns a sku for a given set of options
const getSelectedSku = (product, selectedOptions) => getSku(product, composeSkuKey(selectedOptions))

// finds sku by id
const getSku = (product, skuId) => product.skus.find(sku => sku.id == skuId)

// An option is considered as "qualified" (available for selection) if
//
// - it's the same type of the selected option
// - it's having a sku in the sku list
const isQualifiedOption = (option, selectedOption, skus) =>
  option.categoryId == selectedOption.categoryId && hasSku(toSku(option), skus)

export const SUPERMARKET_SELLERS = [
  GENERAL_MARKET_SELLER /*, FRESH_MARKET_SELLER, APPLIANCE_MARKET_SELLER*/,
]

export const SELLER_PLATFORMS = [Platform.JD, Platform.TMALL]

export const isSupermarket = product => {
  //return false
  return SUPERMARKET_SELLERS.includes(+product.sellerId)
}
