import { FormattedMessage } from 'react-intl'
import React, { FC } from 'react'
import { EMPTY_REVIEW_IMAGE } from '@constants/index'
import { Default, Mobile } from '@components/common/Responsive'

const Empty = props => (
  <div className="text-center tw-mt-10 bp-reviews-empty">
    <Default>
      <img src={EMPTY_REVIEW_IMAGE} className="tw-mb-5" width="80" />
      <div className="text-grey">{props.children}</div>
    </Default>
    <Mobile>
      <div>{props.children}</div>
    </Mobile>
  </div>
)

Empty.defaultProps = {
  children: <FormattedMessage id="review_form.no_reviews" />,
}

export default Empty
