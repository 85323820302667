import { useProductPrice, useProductUnavailability, useSingleProduct } from '@hooks/useProduct'
import { FC } from 'react'
import ShippingFee from './ShippingFee'

const ProductShipping: FC<{ id: number }> = ({ id }) => {
  const { product } = useSingleProduct(id)
  const { shippingPrice } = useProductPrice(id)
  const unavailability = useProductUnavailability(id)

  return (
    <ShippingFee
      unable={unavailability}
      price={shippingPrice}
      showFreeTips={product.isSupermarket}
      platform={product.platform}
    />
  )
}

export default ProductShipping
