import * as ACTIONS from '@actions/paymentConfig'
import { useActions } from '@hooks/useActions'
import { useSelector } from 'react-redux'

export const usePaymentConfig = () => {
  const { currency, rate, currencyLocale, ...config } = useSelector(state => state.paymentConfig)
  const actions = useActions(ACTIONS)
  const currencyLocales = currencyLocale || {}

  return {
    ...config,
    currency,
    rate,
    currencyRate: rate[currency],
    currencyLocale: currencyLocales[currency],
    currencyLocales: currencyLocales,
    actions: {
      ...actions,
      updateSelectedPaymentMethod: method =>
        actions.updateSelectedPaymentMethod({
          method,
        }),
    },
  }
}
