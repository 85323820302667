import ACTION from '@constants/shipping-formula'
import { HttpError } from '@errors/HttpError'
import { createStandardAction } from 'typesafe-actions'

export const fetchShippingFormula = createStandardAction(ACTION.FETCH_SHIPPING_FORMULA_REQUEST).map(
  payload => ({
    payload,
    meta: {
      lifecycle: {
        resolve: ACTION.FETCH_SHIPPING_FORMULA_SUCCESS,
        reject: ACTION.FETCH_SHIPPING_FORMULA_FAILURE,
      },
    },
  })
)

export const fetchShippingFormulaSuccess = createStandardAction(
  ACTION.FETCH_SHIPPING_FORMULA_SUCCESS
)<{
  data: any
}>()
export const fetchShippingFormulaFailure = createStandardAction(
  ACTION.FETCH_SHIPPING_FORMULA_FAILURE
)<HttpError>()
