import * as ActionCreator from '@actions/coupon'
import { of } from 'rxjs'
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { Epic } from './index'

export const useCoupon: Epic = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(ActionCreator.applyCoupon)),
    switchMap(({ payload }) =>
      api.useCoupon(payload.code).pipe(
        map(ActionCreator.applyCouponSuccess),
        catchError(err => of(ActionCreator.applyCouponFailure(err)))
      )
    )
  )
