import React from 'react'
import {Rating as rat} from '@interfaces/reviews'
import Rating from './Rating'

type RatingsProps = {
  value?: Rating
  onChange: (value?: Rating) => void
}

const Ratings: React.FC<RatingsProps> = props => (
  <div className="text-center tw-mb-6">
    <Rating
      type="buy"
      onSelect={() => props.onChange(rat.BUY)}
      actived={props.value === rat.BUY}
    />
    <Rating
      type="pass"
      onSelect={() => props.onChange(rat.PASS)}
      actived={props.value === rat.PASS}
    />
  </div>
)

export default Ratings
