import { useTotalProductReviews } from '@hooks/useTotalProductReviews'
import { cloudObjectToURL } from '@utils/'
import React, { useEffect, useState } from 'react'
import { Panel } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import ProductReviews from '../../Reviews'
import './styles.css'

const Reviews = ({ product }) => {
  const totalReviews = useTotalProductReviews(
    product.id,
    product.reviewsCount + (product.taobaoReviewsCount ?? 0)
  )

  return (
    <ProductReviews
      reviewId={product.internalId}
      productId={product.id}
      sellerId={+product.sellerId}
      translatedTitle={product.titles.en}
      originalTitle={product.titles.zhCn}
      productImage={cloudObjectToURL(product.cover)}
      platform={product.platform}
      product={product}
      categories={product.categories}
    />
  )
}

export default Reviews
