import Pluralize from '@components/common/Pluralize'
import { FC } from 'react'

const CollectionInfo: FC<{
  name: string
  id: number
  itemsCount: number
}> = props => (
  <div className="d-lg-flex justify-content-between">
    <div className="tw-mb-4 mb-lg-0">
      <div className="d-block strong collection-modal-heading">{props.name}</div>
      <small>
        {props.itemsCount} <Pluralize singular="item" count={props.itemsCount} />{' '}
      </small>
    </div>
  </div>
)

export default CollectionInfo
