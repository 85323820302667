import * as React from 'react'
import {Link} from 'react-router-dom'
import PriceWithCurrency from '@components/common/PriceWithCurrency'
import ROUTING from '@constants/url'
import {useState, useEffect} from 'react'
import {Desktop, MobileAndTablet} from '@components/common/Responsive'
import './styles.css'
import { useReferals } from '@hooks/useReferrals'
import { useMyCountry } from '@hooks/useAuth'

const links = (isInternational) => isInternational ? [
  {label: 'THE BLOG', to: '/blog', icon: 'blog-v2'},
  {label: 'HELP CENTER', to: '/help', icon: 'help-center'},
  {label: 'SHIPPING FEES', to: '/overseas-shipping-rates', icon: 'shipping'},
  {label: 'CLOTHING SIZE GUIDE', to: '/clothing-size-guide', icon: 'size'},
  {label: 'GET THE APP', to: '/get-the-app', icon: 'baopals'},
  // {label: `REFER A PAL FOR ${data.amountWithCurrency}`, to: ROUTING.ACCOUNT_REFERRALS, icon: 'money'},
] : [
  {label: 'THE BLOG', to: '/blog', icon: 'blog-v2'},
  {label: 'THE CCC', to: '/blog?cat=the-ccc', icon: 'ccc'},
  {label: 'HELP CENTER', to: '/help', icon: 'help-center'},
  {label: 'CLOTHING SIZE GUIDE', to: '/clothing-size-guide', icon: 'size'},
  {label: 'GET THE APP', to: '/get-the-app', icon: 'baopals'},
]

const baseUrl = "https://res.cloudinary.com/baopals/image/upload/v1646359976/global_banners/banner_"
const renderImage = (url, isInternational) => (
  <div
    styleName="wrapper"
    className="intl-navbar-banner d-flex tw-w-full justify-content-center align-items-center"
    style={{backgroundImage: `url("${url}")`}}
  >
    <div className="text-center text-uppercase" styleName="heading">
      The <span className="text-orange">Best</span> Way to Shop {`${isInternational ? 'from' : 'in'}`} China
    </div>
  </div>
)

const Banner = () => {
  const [index, setIndex] = useState(null)
  const data = useReferals()
  useEffect(() => setIndex(Math.floor(Math.random() * 11) + 1), [])
  const bannerUrl = `${baseUrl}${index}.jpg`
  const mobileBannerUrl = `${baseUrl}mobile_${index}.jpg`
  const { isInternational } = useMyCountry()

  return (
    <>
      <Desktop>
        <div className="relative">
          {renderImage(bannerUrl, isInternational)}
          <div styleName="footer">
            <div className="container">
              <div className="v4-row justify-content-between">
                {links(isInternational).map((l, i) => (
                  <div key={i} id={`helper-links-${l.icon}`} styleName="item">
                    <Link to={l.to} target="_blank">
                      <i className={`d-inline-block tw-mr-2 icon-${l.icon} fs24`} styleName="icon" />{' '}
                      <span className="fs18">{l.label}</span>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Desktop>
      <MobileAndTablet>
        {renderImage(mobileBannerUrl, isInternational)}
      </MobileAndTablet>
    </>
  )
}

export default Banner
