import DeptCarousel from '@components/product/DeptCarousel'
import { Platform } from '@interfaces/platform'
import qs from 'qs'
import { Route, Switch } from 'react-router-dom'
import Category from './Category'

const DeptsCarousel = ({ platform, children, setIsRenderCarousel }) => (
  <>
    <Switch>
      <Route
        path="/:platform(express|tb)?/category/:id"
        render={prop => (
          <>
            {platform !== Platform.JD && (
              <DeptCarousel
                type="category"
                keyword={prop.match.params.id}
                platform={platform}
                children={children}
              />
            )}
            <Category platform={platform} />
          </>
        )}
      />
      <Route
        path="/search"
        render={prop => (
          <DeptCarousel
            type="search"
            keyword={qs.parse(prop.location.search, { ignoreQueryPrefix: true })?.q}
            platform={platform}
            children={children}
            setIsRenderCarousel={setIsRenderCarousel}
          />
        )}
      />
    </Switch>
  </>
)

export default DeptsCarousel
