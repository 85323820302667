import { Default, Mobile } from '@components/common/Responsive'
import TranslationText from '@components/common/TranslationText'
import MobileOverview from '@components/product/v2/Mobile/Overview'
import ProductOverview from '@components/product/v2/Overview'
import SEO from '@components/SEO'
import { useResetProductMinQty } from '@hooks/useProduct'
import { Platform } from '@interfaces/platform'
import { imageToUrl } from '@utils/imageUtils'
import React, { useEffect, useState } from 'react'
import CategoryBreadCrumb from '../CategoryBreadCrumb'
import ProductDetails from '../ProductDetails'
import SimilarProducts from '../SimilarProducts'
import ScrollspyNav from "react-scrollspy-nav"
import './styles.css'
import DottedLine from '@components/common/DottedLine'

interface SingleProductProps {
  id: string
}

const TABS = [
  {nodeName: "#image", tabName: "Overview"},
  {nodeName: "#product_reviews", tabName: "Reviews"},
  {nodeName: "#product_qa", tabName: "Q&A"},
  {nodeName: "#product_details", tabName: "Details"},
]

const SIMILAR = [
  {nodeName: "#similar_products", tabName: "Similar"}
]

const SingleProduct: React.SFC<SingleProductProps | any> = props => {
  const [showOverviewTab, setShowOverviewTab] = useState(false)
  const [hasSimilar, setHasSimilar] = useState(false)
  const { product } = props
  const reset = useResetProductMinQty()
  const handleScroll = () => {
    const scroll = window.scrollY
    if (scroll > 400) {
      setShowOverviewTab(true)
    } else {
      setShowOverviewTab(false)
    }
  }

  useEffect(
    () => {
      reset(product.id)
    },
    [product.id]
  )

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <div styleName="wrapper" data-product-platform={product.platform}>
      {/*<CategoryBreadCrumb categories={product.categories} platform={product.platform} />*/}
      <Mobile>
        {showOverviewTab && (
          <div styleName="tabs">
            <ScrollspyNav
              scrollTargetIds={
                hasSimilar ? 
                  ["product_reviews", "product_qa", "product_details", "similar_products"] :
                  ["product_reviews", "product_qa", "product_details"]
              }
              offset={0}
              activeNavClass="is-active"
              scrollDuration="500"
              headerBackground="true"
            >
              <div className="d-flex justify-content-between">
                {(hasSimilar ? [...TABS, ...SIMILAR] : TABS).map((tab, index) => (
                  <div key={index} styleName="tab">
                    <a href={tab.nodeName}><div>{tab.tabName}</div></a>
                  </div>
                ))}
              </div>
            </ScrollspyNav>
          </div>
        )}
      </Mobile>

      <div className="mt-md-5" id="image">
        <ProductDetails {...props} />

        <div className="d-flex product-details-footer">
          {/* related product */}
          {props.enableSimilarProducts &&
            ((product.platform !== Platform.JD && product.id.includes('-')) ||
              product.platform === Platform.JD) && (
              <SimilarProducts
                id={product.id}
                platform={product.platform}
                supermarket={product.isSupermarket}
                setHasSimilar={setHasSimilar}
              />
            )}

          {/* Product Overview */}
          <section className="section-product-overview tw-w-full">
            <DottedLine className="tw-my-6" />
            <Mobile>
              <div className="tw-mt-12">
                <MobileOverview product={product} />
              </div>
            </Mobile>
            <Default>
              <ProductOverview product={product} />
            </Default>
          </section>
        </div>
      </div>

      {product.titles?.en ? (
        <SEO title={product.titles.en} image={imageToUrl(product.cover)} />
      ) : (
        <TranslationText texts={product.titles}>
          {text => <SEO title={text} image={imageToUrl(product.cover)} />}
        </TranslationText>
      )}
    </div>
  )
}

SingleProduct.defaultProps = {
  enableSimilarProducts: true,
}

export default SingleProduct
