import React from 'react'
import Selector from '../CountrySelector/selector'
import { matchPath } from 'react-router'
import { Currency } from '@interfaces/currency'

const ALLOWED_CURRENCIES = [Currency.CNY, Currency.HKD, Currency.USD, Currency.EUR, Currency.AUD, Currency.CAD,  Currency.GBP];

const CurrencySelector = ({ selected, onChange, disabled, currencies }) => {
  const allowedCurrencies = ALLOWED_CURRENCIES.map((a) => currencies.find(c => c.value === a))
  const isActive = (currency) => selected === currency.label
  const isCheckout = matchPath(location.pathname, {
    path: '/:checkoutType(orders|bundles|parcels)/:id/checkout',
  })

  const isTopup = matchPath(location.pathname, { path: '/account/topup' })

  return (
    <Selector
      id="countries-dropdown"
      title={currencies.find(c => c.label === selected)?.label}
      menuItems={allowedCurrencies}
      renderItem={(currency) => (<span>{currency.label}</span>)}
      onClick={(currency) => onChange(currency)}
      isActive={isActive}
      disabled={!!(isCheckout || isTopup || disabled)}
    />
  )
}

export default CurrencySelector
