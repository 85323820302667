import { DEFAULT_COUNTRY_CODE, DEFAULT_CURRENCY } from '@constants/'
import ajax from '@services/client'
import { useQuery } from '@tanstack/react-query'
import useAuth from './useAuth'
import { CountryCode } from '@interfaces/intl'

export function useMyIp() {
  const fallback = {
    countryCode: DEFAULT_COUNTRY_CODE,
    currency: DEFAULT_CURRENCY,
  }

  const { user } = useAuth()

  const results = useQuery({
    placeholderData: fallback,
    enabled: user === null,
    queryKey: ['sessionSettings'],
    retry: 1,
    queryFn: () =>
      ajax
        .get('/sessions/settings', { timeout: 3000 })
        .then(({ data }) => {
          if ([
                CountryCode.HongKong,
                CountryCode.Macau,
                CountryCode.China
              ].includes(data?.data.countryCode)) {
                return data.data
          }

          return fallback
        })
        .catch(() =>
          fallback
        ),
  })


  return results
}
