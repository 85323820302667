import React from 'react'
import Textarea from 'react-textarea-autosize'
import './styles.css'

interface TextboxWithCounterProps {
  value?: string
  className?: string
  maxLength?: number
  minLength?: number
  textarea?: boolean
  placeholder?: string
  required?: boolean
}

const TextboxWithCounter: React.FC<TextboxWithCounterProps> = ({
  className,
  textarea,
  children,
  ...props
}) => {
  return (
    <div className="form-group relative">
      {textarea ? (
        <Textarea className={`form-control ${className}`} {...props} />
      ) : (
        <input className={`form-control ${className}`} {...props} />
      )}

      {props.maxLength && (
        <div styleName="counter" className="fs10 text-grey">
          {props.value.length}/{props.maxLength}
        </div>
      )}
      {children}
    </div>
  )
}

TextboxWithCounter.defaultProps = {
  value: '',
  className: '',
}

export default TextboxWithCounter
