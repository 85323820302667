import React, { Component } from 'react'
import { cloudObjectToURL } from '@utils/'
import { Link } from 'react-router-dom'
import './styles.css'

const DepartmentInProducts = ({ department }) => {
  const url = cloudObjectToURL(department.mobileCover) || ''
  return (
    <div className="relative tw-h-full tw-w-full">
      <img src={url} alt="banner" styleName="banner" />
      <Link to={`/departments/${department.slug}`} target="_blank" styleName="button">
        Department
      </Link>
    </div>
  )
}

export default DepartmentInProducts
