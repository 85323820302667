import QuestionUnit from '@components/product/ProductQAndA/QuestionList/QuestionUnit'
import React, { FC } from 'react'

import { Question } from '@interfaces/question'
import { CommonUser } from '@interfaces/userProfile'

interface Props {
  questions: Question[]
  users: CommonUser[]
}

const QuestionList: FC<Props> = ({ questions, users }) => (
  <div className="qa-wrapper">
    {questions.map(q => {
      const user = users.find(u => u.id == q.userId)
      return user ? <QuestionUnit key={q.id} user={user} {...q} /> : null
    })}
  </div>
)

export default QuestionList
