import React        from 'react'
import CategoryUnit from './CategoryUnit'
import * as service from '../../../../services/category'
import { FRESH_MARKET_SELLER } from '@utils/productUtils'
import './styles.css'

const mainCategoryList = service.fetchMainCategory()
const mainCategoryIds = ['51454011', '50506009', '50520007']

const top3Categories = mainCategoryList.filter(c => mainCategoryIds.includes(c.id))
const categories = mainCategoryList.filter(c => !mainCategoryIds.includes(c.id) && +c.seller !== FRESH_MARKET_SELLER)

export const GridColumn = ({ category, className }) => (
  <div className={className} key={category.id}>
    <CategoryUnit 
      id={category.id}
      name={category.name}
      seller={category.seller}
      pic={category.cssClass}
      link={category.link}
    />
  </div>
)

const GridRow = ({ categories, className }) => (
  <div className="v4-row v4-no-gutters">
    {
      categories.map(c => <GridColumn category={c} key={c.id} name={c.name} className={className} />)
    }
  </div>
)

const Grid = () => (
  <div className='container'>
    <div styleName='wrapper'>
      <div className="v4-row v4-no-gutters">
        {
          top3Categories.map(c => (
            <div className="v4-col-4" key={c.id}>
              <CategoryUnit 
                id={c.id}
                name={c.name}
                seller={c.seller}
                pic={c.cssClass}
              />
            </div>
          ))
        }
      </div>

      <div className='visible-xs'>
        <div className="v4-row v4-no-gutters">
          {
            categories.map(c => (
              <GridColumn key={c.id} category={c} className='v4-col-4' />
            ))
          }
        </div>
      </div>

      <div className='hidden-xs'>
        {
          categories.reduce((pairs, cat, index) => {
            if(index % 5 === 0) {
               pairs.push([])
            }
            pairs[pairs.length - 1].push(cat)
            return pairs
          }, []).map((pair, index) => (
             <GridRow categories={pair} key={index} className='v4-col' />
          ))
        }
      </div>
    </div>
  </div>
)

export default Grid
