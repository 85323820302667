import React from 'react'
import PDF from './PDF'
import Image from './Image'
import Video from './Video'
import Other from './Other'
import './styles.css'

type PreviewerProps = {
  files: File[]
  onDelete: (index: number) => void
  errorFileIndex?: number
}

const PreviewerList: {[s: string]: React.ElementType} = {
  'image/jpeg': Image,
  'image/png': Image,
  'image/gif': Image,
}

const Previewer: React.FC<PreviewerProps> = props => {
  return (
    <>
      {props.files.map((file, index) => {
        const Preview = (() => {
          if (typeof file.url === 'string' || typeof file.picture === 'string') {return Image}
          return PreviewerList[file.type] || Other
        })()

        const handleDelete = (e: React.MouseEvent<HTMLElement>, index: number) => {
          e.stopPropagation()
          props.onDelete(index)
        }

        const renderClose = (
          <span
            onClick={e => handleDelete(e, index)}
            styleName="delete"
            className="black-link cursor-pointer"
          >
            <i className="icon-close fs10" />
          </span>
        )

        return (
          <div
            key={index}
            className="relative d-inline-block tw-px-0 tw-mt-4"
            styleName="unit"
            data-error={props.errorFileIndex === index}
          >
            <Preview file={file.url || file.picture || file} renderClose={renderClose} />
          </div>
        )
      })}
    </>
  )
}

export default Previewer
