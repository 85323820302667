import React, { Component } from 'react'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import Section from './DepartmentMenu/Section'
import Express from './Express'

import './styles.css'

class DepartmentMenu extends Component {
  constructor(props) {
    super(props)

    this.state = {
      canOpen: -1,
      x: null,
      lastX: null,
    }
  }

  componentWillReceiveProps(newProps) {
    if (this.state.canOpen != -1) {
      this.setState({ canOpen: -1 })
      let all = document.getElementById('menu').childNodes
      for (let i = 0; i < all.length; i++) {
        if (/active-submenu/.test(all[i].className)) {
          all[i].className = 'dropdown-submenu'
        }
      }
    }
  }

  hover = index => {
    if (this.state.canOpen != index) {
      this.setState({ canOpen: index })
    }

    let difference = Math.abs(this.state.x - this.state.lastX)
    let timeout = 12
    if (difference == 1) {
      timeout = 50
    } else if (difference > 1) {
      timeout = difference * 75
    }

    let this_ = this
    setTimeout(function() {
      if (this_.state.canOpen != index) return

      let all = document.getElementById('menu').childNodes
      for (let i = 0; i < all.length; i++) {
        if (i != index) {
          if (/active-submenu/.test(all[i].className)) {
            all[i].className = 'dropdown-submenu'
          }
        }
      }

      let index_str = 'menu-' + String(index)
      let d = document.getElementById(index_str)
      if (!/active-submenu/.test(d.className)) {
        d.className += ' active-submenu'
      }
    }, timeout)
  }

  move = e => {
    this.setState({
      lastX: this.state.x,
      x: e.screenX,
    })
  }

  render() {
    const { sections, departments, isDesktop } = this.props
    const getSectionDepartments = (section, departments) =>
      _.filter(departments, ['sectionId', section.id])
    const sortedSections = _.sortBy(sections, ['position'])

    return (
      <ul
        id="menu"
        className="dropdown-menu"
        styleName="dept-drop"
        role="menu"
        aria-labelledby="department-nav-dropdown"
      >
        <Express onHover={() => this.hover('express')} />
        {sortedSections.map((section, index) => (
          <Section
            activeIndex={this.state.canOpen}
            key={`parent-department-${section.id}`}
            section={section}
            index={index}
            isDesktop={isDesktop}
            onHover={this.hover}
            onMove={this.move}
            departments={getSectionDepartments(section, departments)}
          />
        ))}
      </ul>
    )
  }
}

export default DepartmentMenu
