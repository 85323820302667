import * as ACTION from '../constants/myCollection'
import { groupBy } from '@utils'
import update from 'immutability-helper'
import { findCollection } from '../actions/myCollection'

const initialState = {
  data: [],

  meta: {},
  favoriteCollections: [],
}

export default function myCollection(state = initialState, { type, payload = {} }) {
  switch (type) {
    case ACTION.FETCH_MY_SINGLE_COLLECTION_SUCCESS: {
      const { data } = payload
      const index = state.data.findIndex(d => d.id === data.id)

      const newCollection =
        index > -1
          ? state.data
            .slice(0, index)
            .concat(data)
            .concat(state.data.slice(index + 1))
          : [].concat(data).concat(state.data)

      return {
        ...state,
        data: newCollection,
      }
    }

    case ACTION.CREATE_COLLECTION_SUCCESS: {
      const collection = [...state.data].concat(payload.data)

      return {
        ...state,
        data: collection,
      }
    }

    case ACTION.DELETE_COLLECTION_SUCCESS: {
      const collection = (state.data || []).filter(d => d.id !== +payload.id)

      return {
        ...state,
        data: collection,
      }
    }

    case ACTION.MOVE_COLLECTION: {
      const { id, index: atIndex, type } = payload

      const collectionType = (() => {
        if (type === 'public') {
          return 'publicCollection'
        } else if (type === 'private') {
          return 'privateCollection'
        }

        return null
      })()

      if (!collectionType) return state

      const collection = state[collectionType]

      const card = collection.find(c => c.id === id)

      const { collection: data, index } = findCollection(id, state[collectionType])

      const newCollection = update(state[collectionType], {
        $splice: [[index, 1], [atIndex, 0, data]],
      })

      return {
        ...state,
        [collectionType]: newCollection,
      }
    }

    case ACTION.FETCH_MY_COLLECTIONS_SUCCESS: {
      return {
        ...state,
        data: payload.collections,
      }
    }

    case ACTION.UPDATE_COLLECTION_SUCCESS: {
      const {
        data: { id },
      } = payload
      const index = state.data.findIndex(d => d.id === id)

      const collection = state.data
        .slice(0, index)
        .concat({ ...state.data[index], ...payload.data })
        .concat(state.data.slice(index + 1))

      return {
        ...state,
        data: collection,
      }
    }

    case ACTION.FETCH_FAVORITES_SUCCESS: {
      const { collections, users, meta } = payload

      const favoriteCollections = collections.map(c => {
        const { userName: username, avatar, displayName } = users.find(u => u.id === c.ownerId)
        return { ...c, user: { avatar, username, displayName } }
      })

      return {
        ...state,
        favoriteCollections,
        meta,
      }
    }

    default:
      return state
  }
}
