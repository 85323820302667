import { getMinimumQuantity as getMinQuantity, totalPrice as total } from '../product/priceUtils'
import { Platform } from '@interfaces/platform'

export const unitPrice = item => total(item.price)

export const totalPrice = item => total(item.price, item.quantity)

export const getMinimumQuantity = (price, minQty) => getMinQuantity(price, minQty)

export const getProduct = (item, products) => products.find(p => p.id == item.productId)

export const isAvailable = item => {
  switch (item.target) {
    case Platform.JD:
      return item.price > 0 && item.stock > 0

    default:
      return item.shipping?.available
  }
}

export const hasStock = item => {
  switch (item.target) {
    case Platform.JD:
      return item.price > 0

    default:
      return typeof item.stock !== 'undefined' ? item.stock > 0 : item.shipping?.stock > 0
  }
}

export const canBeCheckedOut = item =>
  isAvailable(item) && hasStock(item) && !!item.unavailable !== true
