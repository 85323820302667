import AwesomeBurgerMenu from '@components/common/AwesomeBurgerMenu'
import MobileMenuPanel from '@components/common/MobileMenuPanel'
import MobileProfile from '@components/common/MobileProfile'
import React from 'react'
import Menus from './Menus'
import './styles.css'

const Menu = props => {
  const { isOpen } = props

  return (
    <AwesomeBurgerMenu
      id="mobile-menu"
      width="100%"
      className={`mobile-menu ${isOpen ? 'open' : ''}`}
      overlayClassName="mobile-menu-overlay"
      open={isOpen}
      onMenuStateChange={props.onMenuStateChange}
    >
      {/*<section className='h-100' style={{overflow: 'hidden'}}>*/}
      {props.loading ? (
        <section className="tw-h-full">
          <div className="tw-mt-12">Loading...</div>
        </section>
      ) : (
        <section className="tw-h-full mobile-menu-wrapper">
          <MobileMenuPanel>
            <MobileProfile />
          </MobileMenuPanel>

          <Menus />
        </section>
      )}
    </AwesomeBurgerMenu>
  )
}

export default Menu
