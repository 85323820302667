import {
  fetchOwnFavoriteSellers,
  updateFavoriteSeller,
  deleteFavoriteSeller,
  updateFavoriteSellerType,
  fetchFavoriteSellers
} from '@services/favoriteSeller'
import { useEffect, useState } from 'react'
import { forkJoin } from "rxjs";

const REFRESH_WHEN_FIELDS_CHANGE = ['date_filter', 'sq', 'category', 'page', 'username', 'country']

export const sellersWithLikedUser = (sellers, likes, users) => {
  if(!sellers.length) return []

  return sellers.map(s => {
    const like = likes.find(l => l.sellerId === s. internalId)
    const newUsers =  like ? like.userIds.map(id => {
      return users.find(u => u.id === id)
    }) : []

    return { ...s, likedUsers: newUsers }
  })
}

export const usePublicFavoriteSellers = (opts: {
  date_filter?: string
  sq?: string
  category?: string
  page: number
  username?: string
  country: string
}) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [sellers, setSellers] = useState<any[]>([])
  const [totalPages, setTotalPages] = useState<number>(1)
  const { date_filter, country, ...rest } = opts
  const newOpts = {
    ...rest,
    ...(date_filter && date_filter !== 'last_updated' && { date_filter })
  }

  const startLoading = () => setLoading(true)

  useEffect(() => {
    startLoading()
    if(opts.page === 1) {
      setSellers([])
    }

    const sub = fetchFavoriteSellers(newOpts).subscribe(
      (res: any) => {
        const newSellers = sellersWithLikedUser(res.sellers, res.likes, res.users).map(s => {
          if(res.liked.includes(s.internalId)) {
            return { category: s.category,  productSeller: { ...s, liked: true } }
          }

          return { category: s.category, productSeller: { ...s } }
        })

        setSellers(opts.page === 1 ? newSellers : sellers.concat(newSellers))
        setLoading(false)
        setTotalPages(res.totalPages)
      },
      err => console.warn(err)
    )

    return () => sub.unsubscribe()
  }, [...REFRESH_WHEN_FIELDS_CHANGE.map(field => opts[field])])

  return { loading, sellers, totalPages, startLoading }
}

export const useUserFavoriteSellers = (opt: {
  page: number
}) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [sellers, setSellers] = useState<any[]>([])
  const [totalPages, setTotalPages] = useState<number>(1)

  const startLoading = () => setLoading(true)

  useEffect(() => {
    startLoading()

    const sub = fetchOwnFavoriteSellers(opt).subscribe(
      (res: any) => {
        setSellers(opt.page === 1 ? res.sellers : sellers.concat(res.sellers))
        setLoading(false)
        setTotalPages(res.totalPages)
      },
      err => console.warn(err)
    )

    return () => sub.unsubscribe()
  }, [opt.page])

  const updateUserFavoriteSellers = (id, params, callBack) => {
    const currentSeller= sellers.find(s => s.id === id)
    let apis = []
    if(currentSeller.category !== params.category) {
      apis.push(updateFavoriteSeller(id, {category: params.category}))
    }

    if(currentSeller.access !== params.access) {
      apis.push(updateFavoriteSellerType(id, params.access))
    }

    return new Promise((resolve, reject) => {
      forkJoin(apis.map(api => api)).subscribe(result => {
        const data = result.reduce((prev, cur) => {
          return { ...prev, ...cur }
        }, {})

        const updatedIndex = sellers.findIndex(s => s.id === id)
        const updatedSeller = { ...currentSeller, ...data }
        const updatedSellers = [...sellers]
        updatedSellers.splice(updatedIndex, 1, updatedSeller)
        setSellers(updatedSellers)
        callBack()
      }, err => reject(err))
    })
  }

  const deleteUserFavoriteSeller = (id, callBack) => {
    const sub = deleteFavoriteSeller(id).subscribe(
      () => {
        setSellers(sellers.filter(s => s.id !== id))
        callBack()
      },
      err => console.warn(err)
    )
    return () => sub.unsubscribe()
  }

  return { loading, sellers, totalPages, updateUserFavoriteSellers, deleteUserFavoriteSeller, startLoading }
}
