import Pluralize from '@components/common/Pluralize'
import React, { FC } from 'react'
import { Link } from 'react-router-dom'

interface Props {
  alias: string
  totalReviews: number
}

const ViewMoreText: FC<Props> = ({ alias, totalReviews }) => {
  const path = `/discover/reviews?q=${alias}`

  return (
    <div
      className={`text-center tw-mt-2 ${totalReviews > 0 ? '' : 'd-none'}`}
      data-reviews-count={totalReviews}
    >
      <Link to={path}>Click here</Link> to see all reviews for "{alias}"
    </div>
  )
}

export default ViewMoreText
