import ACTION from '@constants/cart'
import { createStandardAction } from 'typesafe-actions'

export const addItemToCart = createStandardAction(ACTION.ADD_ITEM_TO_CART_REQUEST).map(
  (payload: any) => ({
    payload,
    meta: {
      lifecycle: {
        resolve: ACTION.ADD_ITEM_TO_CART_SUCCESS,
        reject: ACTION.ADD_ITEM_TO_CART_FAILURE,
      },
    },
  })
)

export const addItemToCartSuccess = createStandardAction(ACTION.ADD_ITEM_TO_CART_SUCCESS)<{
  data: any
}>()
export const addItemToCartFailure = createStandardAction(ACTION.ADD_ITEM_TO_CART_FAILURE)<Error>()

export const fetchCart = createStandardAction(ACTION.FETCH_CART_REQUEST).map(
  ({ zone, country }: { zone: number; country: string }) => ({
    payload: {
      zone,
      country,
    },
    meta: {
      lifecycle: {
        resolve: ACTION.FETCH_CART_SUCCESS,
        reject: ACTION.FETCH_CART_FAILURE,
      },
    },
  })
)

export const fetchCartSuccess = createStandardAction(ACTION.FETCH_CART_SUCCESS)<{
  products: any
  items: any
}>()
export const fetchCartFailure = createStandardAction(ACTION.FETCH_CART_FAILURE)<Error>()

export const updateCartItemQty = createStandardAction(ACTION.UPDATE_CART_ITEM_QTY_REQUEST).map(
  (payload: {
    id: number
    qty: number
    opts: {
      affiliate?: string
      via?: string
      affiliateSId?: string
    }
  }) => ({
    payload,
    meta: {
      lifecycle: {
        resolve: ACTION.UPDATE_CART_ITEM_QTY_SUCCESS,
        reject: ACTION.UPDATE_CART_ITEM_QTY_FAILURE,
      },
    },
  })
)

export const updateCartItemQtySuccess = createStandardAction(ACTION.UPDATE_CART_ITEM_QTY_SUCCESS)<{
  id: number
  qty: number
}>()
export const updateCartItemQtyFailure = createStandardAction(ACTION.UPDATE_CART_ITEM_QTY_FAILURE)<
  Error
>()

export const removeCartItem = createStandardAction(ACTION.REMOVE_CART_ITEM_REQUEST).map(
  (itemId: number) => ({
    payload: { itemId },
    meta: {
      lifecycle: {
        resolve: ACTION.REMOVE_CART_ITEM_SUCCESS,
        reject: ACTION.REMOVE_CART_ITEM_FAILURE,
      },
    },
  })
)

export const removeCartItemSuccess = createStandardAction(ACTION.REMOVE_CART_ITEM_SUCCESS)<{
  id: number
}>()
export const removeCartItemFailure = createStandardAction(ACTION.REMOVE_CART_ITEM_FAILURE)<Error>()

export const selectItem = createStandardAction(ACTION.SELECT_CART_ITEMS)<number[]>()
export const deselectItem = createStandardAction(ACTION.DESELECT_CART_ITEMS)<number[]>()
