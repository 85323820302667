import { Preferences, User } from '@interfaces/user'
import { AuthContext } from '@providers/Auth'
import * as api from '@services/auth'
import { updateMyProfile as doUpdateMyProfile } from '@services/me'
import { mergeState } from '@utils/fn'
import { useContext, useMemo } from "react";
import { useAddress } from './useAddress'
import { useCountries } from './useCountries'
import { DEFAULT_COUNTRY_CODE } from '@constants/index'
import { useMyIp } from './useMyIp'

type Fn = typeof mergeState


const updateMyProfile = (update: Fn) => (user: User) =>
  new Promise((resolve, reject) => {
    return update((currentUser: User) => {
      if (!currentUser) {
        return resolve()
      }

      return doUpdateMyProfile(user).subscribe(
        nextUser => {
          // keep profile uncomplete to show the success message
          const user =
            !currentUser.isComplete && nextUser
              ? {
                ...nextUser,
                isComplete: false,
              }
              : nextUser

          update(user)
          resolve(user)
        },
        err => {
          return reject(err)
        }
      )
    })
  })

const updateEmail = (update: Fn) => (email: string) => {
  return new Promise((resolve, reject) => {
    return update((user: User) => {
      return api.updateEmail(email).subscribe(
        () => {
          update({ ...user, email })
          resolve({ ...user, email })
        },
        err => {
          return reject(err)
        }
      )
    })
  })
}

/*
  update(
    (): Promise<User> => {
      return new Promise((resolve, reject) => {
        doUpdateMyProfile(user).subscribe(
          user => {
            update(user)
            resolve(user)
          },
          err => {
            console.log('eee', err)
            return reject(err)
          }
        )
      })
    }
    )*/

const updatePassword = (currentPassword: string, newPassword: string) => {
  return new Promise((resolve, reject) => {
    api.updatePassword(currentPassword, newPassword).subscribe(resolve, reject)
  })
}

const updatePreferences = (update: Fn) => (preferences: Preferences) => update({ preferences })
const updateCollectionsCount = (update: Fn) => (collectionsCount: number) =>
  update((user: User) => ({ collectionsCount: user.collectionsCount + collectionsCount }))

const updateUserBalance = (update: Fn) => (amount: number) =>
  update((user: User) => ({ walletBalance: user.walletBalance + amount }))

const updateUserWarehouseItemsCount = (update: Fn) => (diff: number) =>
  update((user: User) => ({ warehouseItems: Math.max(user.warehouseItems + diff, 0) }))

const updateCountry = (update: Fn) => (country: country) =>
  update((user: User) => ({ locationCountry: country }))

const updateToolTips = (update: Fn) => (tooltips: string[]) =>
  update((user: User) => ({ tooltips: tooltips }))

const updateUser = (update: Fn) => (user: Partial<User>) =>
  update((user: User) => (user))

const useAuth = () => {
  const { __setUser, user, ...context } = useContext(AuthContext)

  const doUpdate = (u: User) =>
    new Promise(resolve => {
      __setUser(u)
      return resolve(u)
    })

  const updateFn = mergeState(doUpdate, user)

  return {
    user,
    __setUser,
    ...context,

    updateUserWarehouseItemsCount: updateUserWarehouseItemsCount(updateFn),
    updateUser: updateUser(updateFn),

    updateMyProfile: updateMyProfile(updateFn),
    updateUserBalance: updateUserBalance(updateFn),
    updateCountry: updateCountry(updateFn),
    updateToolTips: updateToolTips(updateFn),

    updatePreferences: updatePreferences(updateFn),

    updateCollectionsCount: updateCollectionsCount(updateFn),

    updateEmail: updateEmail(updateFn),
    updatePassword,
  }
}

export const useMyCurrency = () => {
  const { user } = useAuth()

  return user?.currency
}

export const useMyCountry = () => {
  const { user } = useAuth()
  const { defaultAddress } = useAddress()
  const { countries, isLoading } = useCountries()

  const name = (defaultAddress && !isLoading && !!countries.length) ? countries.find(c => c.abbr === defaultAddress.country).alias : user?.locationCountry

  const result = countries.find(c => c.alias === name)

  const userCountryData = useMemo(() => countries.find(c => c.alias === user?.locationCountry), [user?.locationCountry, isLoading])

  const { data: ip } = useMyIp()

  const curCountry = countries.find(c => c.abbr === (userCountryData?.abbr ?? ip.countryCode))
  const currentCountryCode = curCountry?.abbr ?? DEFAULT_COUNTRY_CODE

  return {
    isSignedIn: !!user,
    isLoading: isLoading,
    country: result?.alias,
    code: result?.abbr,
    defaultZone: result?.defaultZoneId || curCountry?.defaultZoneId,
    locationCountry: curCountry?.alias,
    locationCountryCode: currentCountryCode,
    isInternational: currentCountryCode !== DEFAULT_COUNTRY_CODE,//user?.locationCountry === undefined ? false : user?.locationCountry !== country[CountryCode.China].alias
  }
}

export default useAuth
