import { Image } from '@interfaces/image'
import { FetchOrdersParam, Item, Order, OrderStatus, OrderType } from '@interfaces/order'
import { PopularSeller } from '@interfaces/order'
import { PaginationMeta } from '@interfaces/pagination'
import { PaymentMethods } from '@interfaces/payment'
import { ReviewData } from '@interfaces/reviews'
import { cloudObjectToURL, isIOS } from '@utils/'
import { formatAddressWithZones, getCompatibleAddress, findZonesByLastZoneId } from '@utils/address'
import { BehaviorSubject, Observable } from 'rxjs'
import { map, pluck, tap } from 'rxjs/operators'
import ajax from '../api/ajax'

const BASE_PATH = '/me/orders'
const BASE_BUNDLE_PATH = '/me/order/bundles'
const BASE_PARCEL_PATH = '/me/global/parcels'

export const onOrderAmountChange$ = new BehaviorSubject(0)

export const fetchPopularSellers = (): Observable<PopularSeller[]> =>
  ajax({
    url: `/me/sellers`,
    method: 'GET',
  }).pipe(pluck('response', 'data', 'sellerNames'))

export const fetchSingleOrder = (
  id: number,
  {
    isBundle, isParcel, countries
  }: {
    isBundle: boolean,
    isParcel: boolean,
    countries: any[]
  }
): Observable<{ order: Order; items: Item[] }> =>
  ajax({
    url: `${isBundle ? BASE_BUNDLE_PATH : isParcel ? BASE_PARCEL_PATH : BASE_PATH}/${id}`,
    method: 'GET',
  }).pipe(
    pluck('response', 'data'),
    map(({ items, ...rest }) => ({
      ...rest,
      items: items.map(({ orderItem, ...item }) => ({
        ...orderItem,
        title: orderItem?.titles?.en,
        ...item,
        id: orderItem?.id ?? item.id,
        trackingId: orderItem?.id ?? item.id,
        orderItemId: orderItem?.id ?? item.id
      }))
    })),
    map((res: any) => {
      const order = isBundle ? res.bundle?.order : isParcel ? res.parcel : res.order

      const paymentId = res.bundle?.id || order.id
      const paymentFee = order.paymentFee / +order.currencyRate

      const zones = res.address.zoneId ? findZonesByLastZoneId(res.address.zoneId, res.zones) : [];

      return {
        parcelItems: res.parcels ?? [],
        order: {
          ...order,
          paymentFee,
          total: order.total + paymentFee,
          ...(!order.number && isParcel && { number: order.parcelNum }),
          ...(isParcel && { total: order.shipping + paymentFee }),
          isParcel,
          paymentId,
          status: formatOrderStatus(order.status),
          archived: res.archived || res.bundle?.archived,
          phone: res.address.phone,
          receiver: res.address.name,
          discount: res.coupon ? res.coupon.currencyDiscount : {},
          coupon: res.coupon ? res.coupon.code : '',
          // currency: order.currency,

          address: {
            ...res.address,
            state: zones[0]?.name ?? "",
            city: zones[1]?.name ?? "",
            alias: formatAddressWithZones(
              getCompatibleAddress(res.address.zoneId ? res.address : res.address.address || ''),
              res.zones, countries
            ),
          },
          paymentMethod: formatPaymentMethod(res.paymentMethod || ''),
        },
        items: res.items.map(item => {
          const currentReview =
            res.reviews && res.reviews.find(r => r.productId === item.productId)
          return {
            ...item,
            isParcel,
            status: formatOrderStatus(item.status, item.inWarehouse, item.inGlobalParcel),
            image: formatImage(item.imageUrl, item.image),
            reviewId: currentReview && currentReview.id,
            review: currentReview,
            platform: item.shopType,
            shopName: item.sellerName,
            externalId: item.externalOrderId,
            unitPrice: item.price / item.quantity,
            // Override item status when the status is UNPAID/CANCEL on items
            ...(formatOrderStatus(order.status) === OrderStatus.UNPAID && {
              status: OrderStatus.UNPAID,
            }),
            ...(formatOrderStatus(order.status) === OrderStatus.CANCELD && {
              status: OrderStatus.CANCELD,
            }),
          }
        }),
        dimensions: res.dimensions || [],
        selection: res.selection,
        warehouseFees: res.warehouseFees
      }
    })
  )

export const fetchOrders = (
  { isParcel, ...params }: FetchOrdersParam
): Observable<{ orders: Order[]; totalRecords: number; items: Item[]; reviews: ReviewData[] }> =>
  ajax({
    url: isParcel ? '/me/global/parcels' : BASE_PATH,
    method: 'GET',
    query: {
      ...params,
      per: 20,
    },
  }).pipe(
    pluck('response'),
    map((res: any) => ({
      items: res.data.items,
      orders: res.data.orders || res.data.parcels,
      reviews: res.data.reviews || [],
      meta: res.meta,
      addresses: res.data.addresses || [],
      parcelItems: isParcel ? [] : (res.data.parcels ?? []),
    })),
    map(({ items, ...rest }) => ({ ...rest, items: items.map(({ orderItem, ...item }) => ({ ...orderItem, ...item, id: orderItem?.id ?? item.id, trackingId: orderItem?.id ?? item.id })) })),
    map(
      ({
        items,
        orders,
        reviews,
        addresses,
        meta,
        parcelItems,
      }: {
        orders: Order[]
        items: Item[]
        reviews: any[]
        parcelItems: any[]
        meta: PaginationMeta
      }) => ({
        orders: orders.map(o => ({
          isParcel,
          ...o,
          total: o.total + (o.paymentFee / +o.currencyRate),
          status: formatOrderStatus(o.status),
          ...(o.hasPaypalPayment && { paymentMethod: PaymentMethods.PayPal }),
          archived: !!params.archived,
          unreviewed: !!params.unreviewed,
          bundles: [o.id].concat(o.bundledOrderId || []),
          ...(isParcel && { country: addresses.find(a => a.id === o.deliveryAddressId)?.country })
        })),
        items: items.map(item => ({
          ...item,
          isParcel,
          ...(isParcel && { orderId: item.parcelId }),
          status: formatOrderStatus(item.status, item.inWarehouse, item.inGlobalParcel),
          image: formatImage(item.imageUrl, item.image),
        })),
        reviews,
        meta,
        parcelItems,
      })
    ),
  )

export const fetchOrderItem = (id: number): Observable<any> =>
  ajax({
    url: `/orders/order_items/${id}`,
    method: 'GET',
  }).pipe(pluck('response'))

export const fetchOrder = (id: number): Observable<any> =>
  ajax({
    url: `${BASE_PATH}/${id}`,
    method: 'GET',
  }).pipe(
    pluck('response', 'data', 'order'),
  )

export const cancelOrder = (id: number, { isParcel } = { isParcel: false }): Observable<{ id: number }> => {
  return ajax({
    url: `${isParcel ? BASE_PARCEL_PATH : BASE_PATH}/${id}/cancel`,
    method: 'DELETE',
  }).pipe(map(() => ({ id })))
}

export const archiveUnarchiveOrder = (id: number, archive: boolean, isBundle = false): Observable<any> =>
  ajax({
    url: `${isBundle ? BASE_BUNDLE_PATH : BASE_PATH}/${id}/${archive ? 'archive' : 'unarchive'}`,
    method: 'PUT',
  }).pipe(pluck('response'))

export const deleteOrder = (id: number, isBundle = false): Observable<{ id: number }> => {
  return ajax({
    url: `${isBundle ? BASE_BUNDLE_PATH : BASE_PATH}/${id}`,
    method: 'DELETE',
  }).pipe(map(() => ({ id })))
}

export const formatOrderStatus = (status: string, isInWarehouse?: boolean, isInGlobalParcel?: boolean): OrderStatus => {
  if (isInWarehouse) {
    return OrderStatus.IN_WAREHOUSE
  }

  if (isInGlobalParcel) {
    return OrderStatus.IN_GLOBAL_PARCEL
  }

  switch (status) {
    case 'void':
    case 'paying':
      return OrderStatus.UNPAID

    // case 'paying':
    //   return OrderStatus.PENDING

    case 'processed':
    case 'paid':
      return OrderStatus.PAID

    case 'refunded':
      return OrderStatus.REFUNDED

    case 'refunding':
      return OrderStatus.REFUNDING

    case 'canceled':
      return OrderStatus.CANCELD

    case 'expired':
      return OrderStatus.EXPIRED

    default:
      return OrderStatus.UNKNOWN
  }
}

const formatPaymentMethod = (method: string): PaymentMethods => {
  if (method.includes('ali')) {
    return PaymentMethods.AliPay
  }
  if (method.includes('wechat') || method.includes('wx')) {
    return PaymentMethods.WechatPay
  }
  if (method.includes('paypal')) {
    return PaymentMethods.PayPal
  }
  if (method.includes('airwallex_card')) {
    return PaymentMethods.VisaCard
  }
  if (method.includes('apple_pay')) {
    return PaymentMethods.ApplePay
  }
  if (method.includes('google_pay')) {
    return PaymentMethods.GooglePay
  }
  if (method.includes('upacp')) {
    return PaymentMethods.UnionPay
  }
  if (method.includes('baopals_wallet')) {
    return PaymentMethods.BaopalsWallet
  }

  return PaymentMethods.Unknown
}

export const formatImage = (url: string, image: Image) =>
  url || cloudObjectToURL(image, { size: '180x180' })

export const createPaymentCharge = (id: number, { type, ...opts }: { type: OrderType }) => {
  const base = (() => {
    switch (type) {
      case OrderType.Bundle:
        return `/order/bundles`

      case OrderType.Parcel:
        return `/global/parcels`

      case OrderType.Wallet:
        return '/me/wallet/top_ups'

      case OrderType.AdditionalPayment:

      default:
        return `/orders`
    }
  })()

  const url = type === OrderType.AdditionalPayment ? `/order/item/additional_payments/${id}/pay` : `${base}/${id}/payments`
  const { isFps, isAlipayHkPc, isAlipayHkWap, ...rest } = opts

  return ajax({
    url,
    method: 'POST',
    body: {
      payment: rest,
      ...(isFps && { airwallex_qr: true }),
      ...(isAlipayHkPc && { alipay_hk_qr: true, flow: "webqr" }),
      ...(isAlipayHkWap  && { alipay_hk_qr: true, flow: "mweb", platform: isIOS ? 'ios' : 'android' })
    }
  }).pipe(
    pluck('response', 'data', 'payment'),
    map(payment => {
      const { originalCharge, ...props } = payment
      const charge = JSON.parse(originalCharge)
      const p = { originalCharge: charge, ...props }
      return p
    }),
  )
}


export const createPayment = ({ isParcel, addressId, items, currency, couponId, shippingOption }: { shippingOption?: string, addressId: number, items: number[], currency: string, couponId?: number, isParcel: false }) => {
  const url = isParcel ? '/me/global/parcels' : '/me/orders'
  const body = isParcel ? 'parcel' : 'order'

  return ajax({
    url,
    method: 'POST',
    body: {
      [body]: {
        address_id: addressId,
        item_ids: items,
        currency,
        couponId,
        ...(shippingOption && { shipping_method: shippingOption })
      },
    },
  }).pipe(
    pluck('response', 'data'),
    map(({ items: _, ...rest }) => ({ id: rest[body].id, items }))
  )
}
