const replaceAll = (str, find, replace) => str.replace(new RegExp(find, 'g'), replace)

const replaceMultipleStr = (str, keywords) =>
  keywords.reduce((prev, cur) => prev.replace(cur, ''), str)

const truncate = (str, maxLen, format = '...') =>
  str.length > maxLen ? str.substring(0, maxLen) + format : str

function hasChinese(str) {
  return !(str.match(/[\u3400-\u9FBF]/) === null)
}

const capitalized = str => str.charAt(0).toUpperCase() + str.slice(1)

const isUppercase = str => str === str.toUpperCase()
const isLowercase = str => str === str.toLowerCase()

const replaceTarget = str => {
  if (!str) return

  return str.replace(/href/g, "target='_blank' href")
}

const titlesToString = titles => titles?.en || titles?.zhCn || 'Unknown'

const replaceLineBreak = str => {
  if (!str || typeof str !== 'string') return

  return str.replace(/\\n\\n/g, "<div style='margin: 10px auto' />").replace(/\\n/g, '<br>')
}

const strWithEmoji = (str) => str.replace(/\\u\w{4}/g, function(match) {
  return String.fromCodePoint(parseInt(match.substr(2), 16))
});

export {
  hasChinese,
  capitalized,
  truncate,
  replaceAll,
  isUppercase,
  isLowercase,
  replaceTarget,
  replaceMultipleStr,
  replaceLineBreak,
  titlesToString,
  strWithEmoji
}
