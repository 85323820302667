import WeChatAuthBtn from '@components/auth/WeChatAuthBtn'
import SignupLink from '@components/common/SignupLink'
import {closeLoginModal} from '@hooks/useLoginModal'
import {isWeChat} from '@utils'
import React from 'react'
import {FormattedMessage} from 'react-intl'
import MediaQuery from 'react-responsive'
import {Link} from 'react-router-dom'

const Links = () => (
  <>
    <p>
      <FormattedMessage id="login_modal.no_account" />
      <SignupLink className="bold" onClick={closeLoginModal} />
    </p>
    <p>
      <Link to="/forgot" className="bold" onClick={closeLoginModal}>
        <FormattedMessage id="login_modal.forgot_password" />
      </Link>
    </p>

    <MediaQuery minDeviceWidth={992}>
      {matches => (matches || isWeChat()) && <WeChatAuthBtn className="tw-mt-6" />}
    </MediaQuery>
  </>
)

export default Links
