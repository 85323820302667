import React, {FC} from 'react'
import {formatDate} from '@utils/dateFormat'
import {UserData, ReviewData, Rating} from '@interfaces/reviews'

import ReviewRating from '../ReviewRating'
import ReviewVote from '../ReviewVote'
import './styles.css'

interface DefaultProps {
  review: ReviewData
  user?: UserData
  handleUpvote: () => void;
  handleDownvote: () => void;
  className?: string

  showDate?: boolean
  showReport?: boolean
}

const Bar: FC<DefaultProps> = props => (
  <div className={`flex-center-between tw-ml-4 review-bar ${props.className}`}>
    <ReviewRating rating={props.review.rating == Rating.BUY} />

    <div className='flex-center'>
      {props.showDate && (
        <div styleName='hide-xs'>{formatDate(props.review.createdAt)}</div>
      )}
      {props.showDate && <div styleName='divide hide-xs' />}

      <ReviewVote
        review={props.review}
        userId={props.user.id}
        userName={props.user.userName}
        onUpVote={props.handleUpvote}
        onDownVote={props.handleDownvote}
      />

      {props.showReport && <div styleName='divide' />}
      {props.showReport && <div>report</div>}
    </div>
  </div>
)

Bar.defaultProps = {
  className: '',
  showDate: false,
  showReport: false,
}

export default Bar
