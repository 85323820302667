import Pluralize from '@components/common/Pluralize'
import DashboardNums from '@components/common/ProfileDetails/DashboardNums'
import Unit from '@components/common/ProfileDetails/DashboardNums/Unit'
import URL from '@constants/url'
import { useUserProfile } from '@hooks/useUserProfile'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { NavLink } from 'react-router-dom'
import '../styles.css'

interface NumsProps {
  username: string
}

const Nums: React.FC<NumsProps> = props => {
  const profile = useUserProfile(props.username)

  const { ordersCount = 0, reviewsCount = 0, collectionsCount = 0, sellersCount = 0 } =
    (profile && profile.profile) || {}

  return (
    <DashboardNums styleName="nums">
      <>
        <Unit
          target="orders"
          label={<Pluralize count={ordersCount} singular="Order" />}
          value={ordersCount}
        />
        <Unit
          target="reviews"
          label={<Pluralize count={reviewsCount} singular="Review" />}
          value={reviewsCount}
        />
        <Unit
          target="shops"
          label={<Pluralize count={sellersCount} singular="Shop" />}
          value={sellersCount}
        />
        <Unit
          target="wishlists"
          label={<Pluralize count={collectionsCount} singular="WISHLIST" />}
          value={collectionsCount}
        />
      </>
    </DashboardNums>
  )
}

export default Nums
