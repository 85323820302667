import useAuth from '@hooks/useAuth'
import { FormattedMessage } from 'react-intl'
import React, { FC } from 'react'
import { openSignUpModal } from '@hooks/useSignUpModal'
import './styles.css'
import { Link } from 'react-router-dom'

interface BtnProps {
  addedSlug?: string
  onClick: () => void
  withLabel: boolean
}

const GoTo = ({ username, children, slug }) => (
  <Link to={`/discover/users/${username}/wishlists/${slug}`} className="bold upper link-black">
    {children}
  </Link>
)

const Btn: FC<BtnProps> = ({ addedSlug, ...props }) => {
  const { user } = useAuth()

  const rest = { username: user?.username, slug: addedSlug }

  const icon = <i className={addedSlug ? 'icon-check-mark' : 'icon-heart-addToList'} />

  return (
    <span
      className="black-link cursor-pointer"
      onClick={addedSlug ? null : user ? props.onClick : openSignUpModal}
      styleName={`wrapper ${addedSlug ? 'added' : ''}`}
    >
      {addedSlug ? <GoTo {...rest}>{icon}</GoTo> : icon}{' '}
      {addedSlug ? (
        <GoTo {...rest}>{props.withLabel ? 'Added to Wishlist' : <i />}</GoTo>
      ) : (
        <span className="bold upper text-nowrap">
          {props.withLabel ? <FormattedMessage id="product_page.add_to_list" /> : <i />}
        </span>
      )}
    </span>
  )
}

Btn.defaultProps = {
  withLabel: true,
}

export default Btn
