import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import './styles.css'

interface InnerProps {
  icon: string
  bubbleValue?: number
}

interface MobileNavItemProps extends InnerProps {
  active?: boolean
  link?: string
  disabled?: boolean
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
  id?: string
}

const Inner: React.SFC<InnerProps> = ({ icon, bubbleValue }) => (
  <>
    <i className={`icon-${icon}`} />
    {bubbleValue > 0 && <div styleName="remind">{bubbleValue <= 99 ? bubbleValue : '···'}</div>}
  </>
)

const MobileNavItem: React.SFC<MobileNavItemProps> = props => {
  const inner = <Inner icon={props.icon} bubbleValue={props.bubbleValue} />

  return !props.disabled ? (
    <Link
      data-active={props.active}
      to={props.link}
      styleName="icon"
      className={`mobile-nav-${props.icon} ${props.active ? 'link-active' : ''}`}
      onClick={props.onClick}
      {...(props.id && {id: props.id})}
    >
      {inner}
    </Link>
  ) : (
    <a styleName="icon" onClick={props.onClick} className={props.active ? 'link-active' : '' }>
      {inner}
    </a>
  )
}

MobileNavItem.defaultProps = {
  active: false,
  disabled: false,
  bubbleValue: -1,
}

export default MobileNavItem
