import React, { useRef, useState, useEffect, FC, isValidElement } from 'react'
import { useDispatch } from 'react-redux'
import { useMyCollections } from '@hooks/useMyCollections'
import useAuth from '@hooks/useAuth'
import AnimatedButton from '@components/common/AnimatedButton'
import CollectionUnit from './CollectionUnit'
import './styles.css'
import { addToast } from '@actions/toasts'

const DEF_TEXT = 'New List'

const CollectionList: FC<{ selectedId?: number, collections: any[], onAddToCollection: (id: number, shouldUpdateCover: boolean) => void }> = ({ collections, ...props }) => {
  const { actions } = useMyCollections()
  const [id, setId] = useState(props.selectedId ? props.selectedId : (collections.find(c => c.isDefault)?.id ?? collections[0]?.id ?? -1))
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)

  const [text, setText] = useState(DEF_TEXT)
  const { updateCollectionsCount } = useAuth()

  const isValid = text.length >= 3

  const handleChange = evt =>
    setText(evt.target.value)


  const handleCreate = async () => {
    setLoading(true)

    return fetch('https://res.cloudinary.com/baopals/image/upload/v1678861704/app/baopals-app-icon.png')
      .then(resp => resp.arrayBuffer())
      .then(buf => new File([buf], 'cover.jpg', { type: 'image/jpeg' }))
      .then(pic => {
        actions
          .createCollection({
            title: text,
            pic,
          })
          .then((resp) => {
            setId(resp.payload.data.id)
            updateCollectionsCount(1)

            setLoading(false)
            setText(DEF_TEXT)
          }).catch(err => {
            dispatch(addToast({ text: err.payload.error.message }))
            setLoading(false)
            setText(DEF_TEXT)
          }
          )
      })
  }

  // useEffect(
  //   () => {
  //     setId(props.selectedId || collections[0]?.id || -1)
  //   },
  //   [collections[0]?.id]
  // )

  const selectedCollection = collections.find(c => c.id === id)

  return (
    <>
      {
        collections.map((item, index) => {
          return (
            <CollectionUnit
              id={item.id}
              key={item.id}
              pic={item.picture || BAOPALS.IMAGE_PLACEHOLDER}
              className={[].concat(selectedCollection?.id === item.id ? 'active' : []).join(' ')}
              updatedAt={item.updatedAt}
              itemsCount={item.itemsCount}
              name={item.name}
              onSelect={() => setId(item.id)}
            />
          )
        })
      }
      <div className="media px-4">
        <div className="media-left"
        >
          <div
            className="media-object tw-rounded-full bg-grey tw-flex tw-content-center tw-items-center tw-p-4"
          >
            <i className="icon-like tw-text-white fs24" onClick={() => node.current.focus()} />
          </div>
        </div>
        <div className="media-body">
          <h4 className="media-heading strong">
            <div className="form-group tw-mb-0">
              <div className="input-group">
                <input type="text" className="form-control tw-border-r-0"
                  value={text}
                  onChange={handleChange}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      e.stopPropagation()
                      e.preventDefault()
                      if (isValid) {
                        document.activeElement?.blur();
                        handleCreate()
                      }
                    }
                  }}
                  styleName="input"
                />
                <div
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    if (isValid) {
                      handleCreate()
                    }
                  }}
                  className="tw-cursor-pointer input-group-addon"
                >
                  <i
                    className={isValid ? 'icon-check-mark text-success' : 'text-danger icon-close'} />
                </div>
              </div>
            </div>
          </h4>
          <small style={{ transform: "translateY(-2px)" }} className='help-block tw-mt-0 tw-ml-4'>
            {loading && 'Creating...'}
          </small>
        </div>
      </div >

      <div className='text-center'>
        <AnimatedButton
          disabled={collections.length === 0 || loading}
          className="btn-white fs14 tw-mt-6 bold"
          successTransition={2000}
          onClick={() => props.onAddToCollection(selectedCollection?.id, false)}
        >
          CONFIRM
        </AnimatedButton>
      </div>
    </>
  )

}

export default CollectionList
