import { FormattedMessage } from 'react-intl'
import { Switch, Route } from 'react-router-dom'
import './styles.css'

const Head = ()  => (
  <div className="intro intro--header intro--dots">
    <div
      className='dotted-line'
      styleName="line"
    />

    <h3 styleName="title">
      <Switch>
        <Route path="/supermarket">
          <FormattedMessage id='supermarket.reviews' />
        </Route>
        <Route path="*">
          <FormattedMessage id='homepage.reviews' />
        </Route>
      </Switch>
    </h3>
  </div>
)

export default Head
