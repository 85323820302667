import TextWithTooltip from '@components/common/TextWithTooltip'
import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { useIntl } from 'react-intl'
import './styles.css'

const TranslationToggler: FC<any> = ({ toggle, onToggle }) => {
  const { formatMessage } = useIntl()

  return (
    <div className="checkbox fs16">
      <label>
        <input className="translateSearch" type="checkbox" checked={!toggle} onChange={onToggle} />
        <FormattedMessage id="homepage.search_translate-checkbox" />
        <TextWithTooltip
          id="tooltips-translate"
          tooltip={formatMessage({ id: 'homepage.search_tooltip' })}
        >
          <i className="icon-question-mark fs12" />
        </TextWithTooltip>
      </label>
    </div>
  )
}

TranslationToggler.defaultProps = {
  toggle: false,
}

export default TranslationToggler
