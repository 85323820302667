import { DefaultProps, Display, LeftProps } from '@interfaces/notification'
import React, { FC, memo } from 'react'
import Main from './Main'

interface Props extends DefaultProps, LeftProps {
  link?: string
}

export const MemoWrapper: FC<Props> = memo(props => (
  <Main
    link={props.link}
    date={props.createdAt || props.notification.createdAt}
    display={props.display}
    onClick={props.handleClick}
    users={props.withImage ? props.users : null}
    icon={props.withImage ? null : props.icon}
    renderLeft={props.renderLeft}
  >
    {props.children}
  </Main>
))

MemoWrapper.defaultProps = {
  display: Display.page,
  withImage: false,
}

export default MemoWrapper
