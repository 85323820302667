import MobileHead from '@components/home/MobileShuffleHead'
import Head from '@components/home/Reviews/List/Head'
import Shuffle from '@components/home/Reviews/List/Shuffle'
import { ProductData, ReviewData, UserData } from '@interfaces/reviews'
import React, { FC, ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'
import ReviewList from './ReviewList'
import { Link } from 'react-router-dom'

interface DefaultProps {
  loading: boolean
  reviews: ReviewData[]
  users?: UserData[]
  products?: ProductData[]

  handleUpvote: (id: number) => void
  handleDownvote: (id: number) => void

  handleShuffle?: () => void
}

const Reviews: FC<DefaultProps> = props => {
  return (
    <section className="section-reviews mb-sm-4 mt-sm-5 pt-5 pb-4 py-sm-0">
      {/* Heading on XS */}
      <div className="section-reviews-heading">
        <div className="visible-xs px-4 tw-pt-1">
          <MobileHead
            className="tw-mt-0"
            text={
              <Link to="/discover/reviews" className="black-link">
                <FormattedMessage id="home_reviews" defaultMessage="reviews on baopals" />
              </Link>
            }
            onShuffle={props.handleShuffle}
          />
        </div>

        {/* Heading on > SM  */}
        <div className="hidden-xs">
          <Head />
          <Shuffle handleShuffle={props.handleShuffle} />
        </div>
      </div>

      <div className="container mt-sm-5 reviews-wrapper">
        <ReviewList
          loading={props.loading}
          users={props.users}
          products={props.products}
          reviews={props.reviews}
          handleUpvote={props.handleUpvote}
          handleDownvote={props.handleDownvote}
        />
        {props.children}
      </div>
    </section>
  )
}

export default Reviews
