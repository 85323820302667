import React, {FC} from 'react'
import {TextsLoading, ImageWithSticker, LoadingProps} from '../'
import './styles.css'

const Loading: FC<LoadingProps> = props => (
  <div className={props.className || ''}>
    <div className="bd-light tw-h-full">
      <div styleName="images">
        {new Array(5)
          .fill('')
          .map((_, index) => (
            <ImageWithSticker key={index} className="bg-white" />
          ))}
      </div>
      <div className="p-4 bd-t-light">
        <TextsLoading />
      </div>
    </div>
  </div>
)

export default Loading
