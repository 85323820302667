import Products from '@components/products'
import { useQuery, useLocation, useRouter } from '@hooks/useRouter'
import { queryToPlatform } from '@utils/platform'
import { matchPath, Redirect, Route, Switch } from 'react-router-dom'

export const NewProducts = () => {
  const query = useQuery()

  const { location: loc } = useLocation()

  return (
    <Products
      key={loc.key}
      innerClassName="container"
      keyword={query.q}
      platform={queryToPlatform(query.platform)}
      image={query.img}
    />
  )
}
