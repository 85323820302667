import qs from 'qs'
import { Observable, of } from 'rxjs'
import { catchError, map, pluck } from 'rxjs/operators'
import ajax from '../api/ajax'

export const fetchFeatedDepts = () =>
  ajax({
    url: '/featured_department',
    query: { locale: localStorage.getItem('preferredLanguage') || 'en' },
  }).pipe(
    pluck('response', 'data'),
    catchError(err => of(null))
  )

interface DeptLinks {
  categories: any[]
  links: any[]
  department: any
  selectedLink: number
}

interface fetchDeptLinksParams {
  kind: 'search' | 'category'
  keyword?: string
  category?: number
  sort?: string
  props: string
  sellerType?: string
  department: string
}

export const fetchDeptLinks = (params: fetchDeptLinksParams): Observable<any> => {
  const { keyword, category, ...rest } = params

  const url = (() => {
    if (!keyword && category) {
      return `/search_page_content/${category}`
    } else if (keyword) {
      return `/search_page_content?term=${keyword}&${qs.stringify(rest)}`
    }
  })()

  return ajax({
    url,
  }).pipe(
    pluck('response', 'data'),
    catchError(() => of(null))
  )
}

export const fetchCategoryLinks = (categoryId: number): Observable<any> => {
  const url = `/content/categories/${categoryId}/links`

  return ajax({
    url,
  }).pipe(
    pluck('response', 'data'),
    catchError(() => of({ links: [] }))
  )
}

export const fetchDeptPopularProducts = (slug: string, params) =>
  ajax({
    url: `/departments/${slug}/featured_products`,
    query: params
  }).pipe(
    pluck('response'),
    map(({ data, meta }) => ({
      featuredProducts: data.featuredProducts.map(p => ({ ...p, platform: p.shopType })),
      ...meta,
    }))
  )

export const fetchDeptPopularSellers = (slug: string) =>
  ajax({
    url: `/departments/${slug}/featured_sellers`,
  }).pipe(pluck('response', 'data'))

export const fetchAllDepartments = params =>
  ajax({
    url: '/departments',
    query: params,
  }).pipe(pluck('response', 'data'))

export const fetchSingleDepartment = id =>
  ajax({
    url: `/departments/${id}`,
  }).pipe(pluck('response', 'data'))
