import TranslationText from '@components/common/TranslationText'
import { useMyCountry } from '@hooks/useAuth'
import { Titles } from '@interfaces/common'
import React, { FC } from 'react'
import { Default, Mobile } from '@components/common/Responsive'
import './styles.css'

const overseaCity = ['美国', '香港', '新界', '九龙']
const formatLocation = (loc1, loc2) => {
  if (!loc1) {
    return null
  } else {
    if (overseaCity.includes(loc2)) {
      return loc1
    }
    return loc1.split(' ')[0].replace(',', '').replace('Kwangtung', 'Guangdong')
  }
}

const getLocation = (location) => {
  const province = location[0]
  const city = location[1]

  if (city && (city.en || city.zhCn)) {
    return city
  } else if (province && (province.en || province.zhCn)) {
    return province
  } else {
    return { en: '', zhCn: '' }
  }
}

const Location: FC<{ location: Titles[] }> = ({ location, ...props }) => {
  const { isInternational } = useMyCountry()
  const loc = getLocation(location)

  return isInternational ? null
    : (
      <div styleName="loc">
        {loc && (
          <TranslationText texts={loc}>
            {text => (
              <span className="text-capitalize product-card-location-unit hide-empty">
                <i className="icon-location fs10 tw-mr-2" />
                {formatLocation(text, loc.zhCn)}
              </span>
            )}
          </TranslationText>
        )}
      </div>
    )
}

Location.defaultProps = {
  location: [],
}

export default Location
