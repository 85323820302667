import PictureUploader from '@components/common/PictureUploader'
import { fileToBlob, formatBytes } from '@utils/'
import { FC, ReactNode, useState } from 'react'
import './styles.css'

interface AvatarProps {
  avatar?: string | File

  onChange?: (avatar: File) => void
  onError?: (err: string) => void

  placeholder: ReactNode
}

const MAX = 10485760

const Avatar: FC<AvatarProps> = props => {
  const [error, setError] = useState(null)

  const handleUpload = file => {
    setError(null)

    if (file.size <= MAX) {
      props.onChange && props.onChange(file)
    } else {
      const msg = `File is larger than ${formatBytes(MAX)}`
      setError(msg)
      props.onError && props.onError(msg)
    }
  }

  const avatar = props.avatar instanceof File ? fileToBlob(props.avatar) : props.avatar

  const render = (
    <PictureUploader
      hideShadow={false}
      pic={avatar}
      onUpload={handleUpload}
      circle={true}
      placeholder={
        <div styleName="upload" className="fs16 strong">
          {props.placeholder}
        </div>
      }
    />
  )

  return typeof props.children === 'function' ? props.children({ render, error }) : render
}

Avatar.defaultProps = {
  avatar: '',
  placeholder: 'UPLOAD',
}

export default Avatar
