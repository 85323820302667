import React, { Component } from 'react'
import { sortBy } from 'lodash'

import Department from './Department'

export default class Section extends Component {
  render() {
    const { index, section, departments, onHover, onMove, isDesktop, activeIndex } = this.props

    const hover = () => onHover(index)
    const classNames = ['fa', 'fa-fw', section.icon]

    const sortedDepartments = sortBy(departments, 'name')

    return (
      <li
        id={`menu-${index}`}
        className="dropdown-submenu"
        onClick={hover}
        onMouseOver={isDesktop ? hover : null}
        onMouseMove={isDesktop ? onMove : null}
      >
        <a className="clearfix">
          <div className="pull-left">
            <i className={classNames.join(' ')} aria-hidden="true" />
            {section.name}
          </div>
          <div className="clearfix">
            <i className="icon-arrow-right" aria-hidden="true" />
          </div>
        </a>
        <ul className="dropdown-menu dropdown-menu--sub" data-depts-size={sortedDepartments.length}>
          {sortedDepartments.map(department => (
            <Department
              key={`child-department-${department.slug}`}
              department={department}
              actived={activeIndex === index}
            />
          ))}
        </ul>
      </li>
    )
  }
}
