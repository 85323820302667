import PriceWithCurrency from '@components/common/PriceWithCurrency'
import ProductLink from '@components/common/ProductLink'
import TranslationText from '@components/common/TranslationText'
import { BLOG_AFFILIATE_TK } from '@constants/'
import { cloudObjectToURL } from '@utils/'
import { totalPrice } from '@utils/product/priceUtils'
import React from 'react'
import './styles.css'

const Product = ({ product, preventClickOnDragging }) => {
  const promotion = product.promotion && product.promotion.minPrice < product.minPrice
  const cover = product.cover
  const coverUrl = cloudObjectToURL(cover, { size: '200x200' })
  const preventDefault = e => e.preventDefault()

  return (
    <TranslationText texts={product.titles}>
      {text => (
        <div
          styleName="wrapper"
          data-shipping-status={`${!!product.unavailable ? 'China Only' : ''}`}
        >
          <ProductLink
            id={product.id}
            platform={product.platformType}
            className="black-link text-center d-block"
            affiliateToken={product.authorToken || BLOG_AFFILIATE_TK}
            target="_blank"
            onDragStart={preventDefault}
            onClick={preventClickOnDragging}
            shareableId={product.articleId}
            affiliateVia="blog_article"
          >
            <div styleName="product" className="bd-light relative">
              <div
                styleName="image"
                className="bg-fit-center tw-w-full"
                style={{ backgroundImage: `url("${coverUrl}")` }}
                title={text}
              />

              <span className="fs14 d-block" styleName="price">
                <PriceWithCurrency
                  price={totalPrice(promotion ? product.promotion.minPrice : product.minPrice)}
                />
              </span>
            </div>
          </ProductLink>
        </div>
      )}
    </TranslationText>
  )
}

export default Product
