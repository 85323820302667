import LastNMonth from '@components/common/LastNMonthFilter'
import UserWalletBalance from '@components/common/UserWalletBalance'
import ROUTING from '@constants/url'
import React from 'react'
import {FormattedMessage} from 'react-intl'
import {NavLink} from 'react-router-dom'
import {links, rewardLinks, walletLinks} from './links'
import { isSmallChina } from '@utils/i18n'
import useAuth, { useMyCountry } from '@hooks/useAuth'
import { useAddress } from '@hooks/useAddress'

export const MyEarningsLink = props => (
  <LastNMonth
    month={1}
    pathname={ROUTING.ACCOUNT_EARNINGS}
    className="fs16 d-block tw-mt-4"
    activeClassName="strong"
  >
    {typeof props.children === 'function'
      ? props.children(ROUTING.ACCOUNT_EARNINGS)
      : 'My Earnings'}
  </LastNMonth>
)

const Heading = ({children}) => <div className="fs16 bold upper tw-pb-2 bd-b-dark">{children}</div>

const Link = ({url, label}) => (
  <NavLink to={url} className="fs16 black-link d-block tw-mt-4" activeClassName="link-active strong">
    {label}
  </NavLink>
)

const AccountSidebar = (props) => {
  const { locationCountryCode } = useMyCountry();
  const { defaultAddress } = useAddress();
  const { user } = useAuth()
  const getAccountLinks = () => {
    const newLinks = links.map((l) => {
      if(user?.subscription?.active && l.label === 'Get Baopals Pro') {
        return {
          label: 'My Baopals Pro',
          url: ROUTING.ACCOUNT_BAOPALS_PRO,
        }
      }
      return l
    })

    return isSmallChina(defaultAddress?.country || locationCountryCode) ?
      newLinks:
      newLinks.filter((l) => ['Get Baopals Pro', 'My Baopals Pro'].includes(l.label))
  }

  return (
    <>
      <div className="pb-6">
        <UserWalletBalance>
          {({enable}) =>
            enable && (
              <div className="tw-mb-12">
                <Heading>WALLET</Heading>

                {walletLinks.map((link, idx) => (
                  <Link url={link.url} label={link.label} key={idx} />
                ))}
              </div>
            )
          }
        </UserWalletBalance>

        <div className="tw-mb-12">
          <Heading>REWARDS</Heading>

          <MyEarningsLink />

          {rewardLinks.map((link, idx) => (
            <Link url={link.url} label={link.label} key={idx} />
          ))}
        </div>

        <div className="fs16 bold upper tw-pb-2 bd-b-dark">
          <FormattedMessage id={`myAccount.sidebar_account`} defaultMessage="MY ACCOUNT" />
        </div>
        {getAccountLinks().map((link, idx) => (
          <Link url={link.url} label={link.label} key={idx} />
        ))}
      </div>
    </>
)}

export default AccountSidebar
