import React from 'react'
import { Modal } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import QRCode from './QRCode'
import './styles.css'

const QRCodeModal = props => {
  return (
    <Modal
      bsSize="sm"
      show={props.open}
      onHide={props.onClose}
      className="login-modal-container"
      styleName="modal"
    >
      <Modal.Body styleName="modal-body">
        <div className="login-modal-container__title hidden-xs hidden-sm">
          <FormattedMessage id="login_modal.title_wechat" />
        </div>
        <Modal.Header closeButton={true} />

        <div className="bodo visible-xs visible-sm" styleName="heading">
          <FormattedMessage id="login_modal.title_wechat" />
        </div>

        <QRCode bind={props.bind} />
      </Modal.Body>
    </Modal>
  )
}
export default QRCodeModal
