import DottedLine from '@components/common/DottedLine'
import LightBox from '@components/common/LightBox'
import { ProductData, ReviewData, ReviewForm, UserData } from '@interfaces/reviews'
import { getImages } from '@utils/review'
import React, { FC } from 'react'

import ByUser from './ByUser'
import Content from './Content'
import './styles.css'
import Title from './Title'

interface DefaultProps {
  review: ReviewData
  user?: UserData
  product?: ProductData

  showByUser?: boolean
  showImages?: boolean
  isTitleLink?: boolean
  showCompleteDetails?: boolean
  showTitleRows?: number
  showContentRows?: number

  showEdit?: boolean
  handleUpdate?: (id: number, review: ReviewForm) => void

  className?: string

  enableAffiliate?: boolean
  reviewId?: number
}

const Details: FC<DefaultProps> = ({ review, user, ...props }) => (
  <div className={`review-details ${props.className}`}>
    {props.showByUser && (
      <ByUser
        anonymous={review.anonymous}
        displayName={user.displayName}
        username={user.userName}
      />
    )}
    {props.showByUser && <DottedLine />}

    <Title
      title={review.title}
      showCompleteDetails={props.showCompleteDetails}
      showTitleRows={props.showTitleRows}
      productId={review.productId}
      isTitleLink={props.isTitleLink}
      affiliateToken={user.affiliateToken}
      reviewId={props.reviewId || review.id}
      enableAffiliate={props.enableAffiliate || review.boughtOnBaopals}
      review={review}
      showEdit={props.showEdit}
      handleUpdate={props.handleUpdate}
      anonymous={review.anonymous}
    />

    <Content
      content={review.content}
      showCompleteDetails={props.showCompleteDetails}
      showContentRows={props.showContentRows}
      sku={review.sku}
      clothingFit={review.clothingFit}
    />

    {props.showImages &&
      review.pictures &&
      review.pictures.length > 0 && (
        <div styleName="review-pics">
          <div styleName="images">
            <LightBox
              images={getImages(review.pictures, '100x100')}
              galleryImages={getImages(review.pictures)}
              previewWidth={100}
              previewHeight={100}
            />
          </div>
        </div>
      )}
  </div>
)

Details.defaultProps = {
  className: 'tw-my-2 tw-mx-4',
  showByUser: false,
  showImages: true,
  isTitleLink: true,
  showCompleteDetails: true,
  showTitleRows: 1,
  showContentRows: 3,
}

export default Details
