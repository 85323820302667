import { ArticleData, CategoryData } from "@interfaces/blog";

export const getLinks = (categories: CategoryData[], cat: string, article?: ArticleData) => {
  const category = categories?.find(c => c.slug === cat)

  if(!category) { return [] }

  if(category.parentId) {
    const mainCategory = categories.find(c => c.id === category.parentId)

    if(article) {
      return [
        {
          link: `/blog?cat=${mainCategory.slug}`,
          title: mainCategory.title
        },
        {
          link: `/blog?cat=${category.slug}`,
          title: category.title
        },
        {
          title: article.title
        }
      ]
    }
    return [
      {
        link: `/blog?cat=${mainCategory.slug}`,
        title: mainCategory.title
      },
      {
        title: category.title
      }
    ]
  }

  if(article) {
    return [
      {
        link: `/blog?cat=${category.slug}`,
        title: category.title
      },
      {
        title: article.title
      }
    ]
  }

  return [{ title: category.title }]
}
