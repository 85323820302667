import { useUserProfile } from '@hooks/useUserProfile'
import React, { FC } from 'react'

export const UserProfileContext = React.createContext(null)

interface UserProfileProviderProps {
  username: string
}

const UserProfileProvider: FC<UserProfileProviderProps> = props => {
  const { loading, profile } = useUserProfile(props.username)

  return (
    <UserProfileContext.Provider value={{ profile, loading }}>
      {props.children}
    </UserProfileContext.Provider>
  )
}

export default UserProfileProvider

export const UserProfileConsumer = UserProfileContext.Consumer
