import {openLoginModal} from '@hooks/useLoginModal'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import './styles.css'

const Welcome = () => (
  <div styleName="wrapper">
    <div styleName="inner" className="text-center">
      <FormattedMessage id="general.sign-to-checkout_please" />
      <a styleName="link" onClick={openLoginModal}>
        <FormattedMessage id="general.sign-to-checkout_sign-in" />
      </a>
      <FormattedMessage id="general.sign-to-checkout_check-out" />
    </div>
  </div>
)

export default Welcome
