import useAuth from '@hooks/useAuth'
import { useLocation } from '@hooks/useRouter'
import qs from 'qs'
import React, { useEffect } from 'react'

const AfterLoginHook = () => {
  const { onLoginSuccessful } = useAuth()
  const { navigate } = useLocation()

  useEffect(() => {
    const sub = onLoginSuccessful.subscribe(() => {
      const { redirect_to } = qs.parse(window.location.search, { ignoreQueryPrefix: true })

      if (redirect_to) {
        navigate(redirect_to, { replace: true })
      }
    })

    return () => sub.unsubscribe()
  }, [])

  return null
}

export default AfterLoginHook
