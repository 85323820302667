import React from "react"
import Unit from './Unit'
import './styles.css'

const WhyShopExpress = () => (
  <div styleName='wrapper' className="container">
    <div className='intro intro--header intro--dots'>
      <h3>Why shop Express?</h3>
    </div>
    <div>
      Express products come from China's largest single retailer and <br /> set the standard in quality assurance, authenticity, and delivery speed.
    </div>
    <div styleName='units' className='d-flex tw-mt-4 justify-content-between'>
      <Unit type='delivery' />
      <Unit type='quality_controlled' />
    </div>
  </div>
)

export default WhyShopExpress
