import { useEffect, useState } from 'react'
import { usePaymentConfig } from '@hooks/usePaymentConfig'
import { useCountries } from '@hooks/useCountries'

export const useWelcomeCoupon = () => {
  const { welcomeCoupon } = useCountries()
  const { currency, currencyLocale } = usePaymentConfig()
  const defaultCoupon = { discount: 50, minOrder: 250, currencyLocale: 'zh-CN', currency: 'cny' }
  const [coupon, setCoupon] = useState(defaultCoupon)
  const showDefault = !welcomeCoupon || !currency || !currencyLocale

  useEffect(() => {
    if(showDefault) {
      setCoupon(defaultCoupon)
    } else {
      setCoupon({
        discount: welcomeCoupon.discount[currency] / 100,
        minOrder: welcomeCoupon.minOrder[currency] / 100,
        currencyLocale: currencyLocale,
        currency: currency
      })
    }
  }, [currency, welcomeCoupon, currencyLocale])

  return { welcomeCoupon: coupon }
}
