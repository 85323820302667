import NewUserTooltips from '@components/common/NewUserTooltips'
import { data, tooltips } from '@components/common/NewUserTooltips/json'
import useAuth from '@hooks/useAuth'
import { useProductPrice } from '@hooks/useProduct'
import { useSingleProduct } from '@hooks/useProduct'
import { useLocation } from '@hooks/useRouter'
import { Platform } from '@interfaces/platform'

const ProductPageTour = props => {
  const id = props.match.params.id

  const { product } = useSingleProduct(id)

  const { shippingPrice } = useProductPrice(product.id)

  let steps = []

  if(document.getElementById('tour-product-image-search')) {
    steps.push({
      target: '#tour-product-image-search',
      content: data.product_image_search.text,
      type: tooltips.PRODUCT_IMAGE_SEARCH,
    })

    if(product.sellerId) {
      steps.push({
        target: '#tour-seller',
        content: data.productSeller.text,
        type: tooltips.PRODUCT_SELLER,
      })
    }

    if (shippingPrice) {
      steps.push({
        target: '#tour-shipping',
        content: data.sellerShipping.text,
        type: tooltips.SELLER_SHIPPING,
      })
    }
  }

  return <NewUserTooltips steps={steps} />
}

export default ProductPageTour
