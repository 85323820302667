import { Image } from '@interfaces/image'
import { ReviewForm as IReviewForm } from '@interfaces/reviews'
import React from 'react'
import MainForm from './MainForm'
import Ratings from './Ratings'

interface ReviewFormProps {
  review: Review
  onSubmit: () => void
}

export interface FormProps {
  title: string
  content: string
  anonymous: boolean
  clothingFit?: string
  skuId?: string
  pictures?: Image[]
  deletedPictures?: string[]

  onChange?: (form: IReviewForm) => void
}

const ReviewForm: React.FC<ReviewFormProps> = ({ review, ...props }) => {

  return (
    <div>
      <Ratings onChange={rating => props.onChange({ rating })} value={review.rating} />

      <MainForm
        anonymous={review.anonymous}
        rating={review.rating}
        title={review.title}
        content={review.content}
        clothingFit={review.clothingFit}
        skuId={review.skuId || props.selectedSkuId}
        pictures={review.pictures}
        onSubmit={props.onSubmit}
        onChange={props.onChange}
        categories={props.categories}
        sizeOptions={props.sizeOptions}
        selectedSizeOptionId={props.selectedSizeOptionId}
        hasSelected={props.hasSelected}
      />
    </div>
  )
}

export default ReviewForm
