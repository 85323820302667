import { Option } from '@components/common/CurrencySwitcher/Option'
import { useQueryClient } from '@tanstack/react-query'
import { useCountries } from '@hooks/useCountries'
import FullScreenModal from '@components/common/FullScreenModal'
import useAuth, { useMyCountry } from '@hooks/useAuth'
import { useLanguage } from '@hooks/useLanguage'
import React, { useState } from 'react'
import CountrySelector from '@components/common/CountrySelector'
import { WHITE_LIST } from '@constants/index'
import './styles.css'

const LangSwithcer = props => {
  const { locale, updateLang } = useLanguage()
  const [open, setOpen] = useState(false)

  const { user, updateMyProfile } = useAuth()
  const { locationCountry, locationCountryCode } = useMyCountry()
  const { countries, isLoading } = useCountries()

  const queryClient = useQueryClient()

  if (isLoading) return null

  const handleChange = (params, meta) => {
    if (user) {
      return updateMyProfile(params).then(() => setOpen(false))
    } else {
      queryClient.setQueryData(['sessionSettings'], prev => {
        const newCountry = countries.find(c => c.alias == params.locationCountry)

        return { ...prev, countryCode: newCountry.abbr }
      })

      setOpen(false)
    }
  }

  return (
    <>
      <div onClick={() => setOpen(true)}>
        <i className="fa fa-globe tw-mx-0 tw-mr-1" />
        {locationCountry}
      </div>
      <FullScreenModal
        open={open}
        onClose={() => setOpen(false)}
        containerStyle={{ background: 'rgba(255,255,255,0.95)' }}
        footerStyle={{ color: '#000' }}
      >
        <div className="fs20 bd-b-dark tw-pb-2 tw-mb-6">Region</div>

        <div>
          <CountrySelector
            selected={locationCountryCode}
            onChange={country =>
              handleChange({ locationCountry: country.alias, locale: 'en' }, { code: country.abbr })
            }
            whiteList={WHITE_LIST}
          />
        </div>

        <div className="fs20 bd-b-dark tw-pb-2 tw-mb-6 tw-mt-12 tw-pt-4">Language</div>
        <div className="v4-row" styleName="wrapper">
          {[{ lang: 'en', text: 'English' }, { lang: 'zh-CN', text: '中文' }].map(lang => (
            <Option
              key={lang.lang}
              label={lang.text}
              active={locale === lang.lang || (lang.lang == 'zh-CN' && locale == 'zh')}
              onChange={() => updateLang(lang.lang, () => setOpen(false))}
            />
          ))}
        </div>
      </FullScreenModal>
    </>
  )
}

export default LangSwithcer
