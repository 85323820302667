import * as ActionCreator from '@actions/auth'
import { useActions } from '@hooks/useActions'
import useAuth from '@hooks/useAuth'
import { useLocation } from '@hooks/useRouter'
import React, { FC, ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'

interface SignoutProps {
  children?: ReactNode
}

const Signout: FC<SignoutProps> = ({ children }) => {
  const { logout } = useAuth()
  const { navigate } = useLocation()

  const actions = useActions(ActionCreator)

  const handleLogout = () => {
    navigate('/', { replace: true })
    logout()
    actions.logout()
    setTimeout(() => window.location.reload())
  }

  return typeof children === 'function' ? (
    children(handleLogout)
  ) : (
    <span onClick={handleLogout}>
      <FormattedMessage id="mobile_menu.sign_out" />
    </span>
  )
}

export default Signout
