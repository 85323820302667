import { useRouter } from '@hooks/useRouter'
import _ from 'lodash'
import React from 'react'
import { Helmet } from 'react-helmet-async';


const SEO_DEF_IMG = `https://res.cloudinary.com/baopals/image/upload/v1711946107/logo/icon_logo.png`

const getMetaTags = ({
  title,
  description,
  path,
  contentType,
  published,
  updated,
  category,
  tags,
  twitter,
  image: img,
}: any) => {
  const image = img ?? SEO_DEF_IMG;

  const metaTags = [
    { property: 'og:site_name', content: 'Baopals' },
    { property: 'fb:app_id', content: '1702208563396103' },
    { property: 'og:title', content: `${title} | Baopals.com` },
    { property: 'og:type', content: contentType ?? 'website' },
    { property: 'og:url', content: `https://www.baopals.com${path}` },
    { property: 'og:image', content: image },
    { property: 'og:image:width', content: '600' },
    { property: 'og:image:height', content: '315' },
    { property: 'og:description', content: description },
    { itemprop: 'name', content: title },
    { itemprop: 'description', content: description },
    { itemprop: 'image', content: image },
    { name: 'description', content: description },
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:site', content: '@baopals' },
    { name: 'twitter:title', content: `${title} | Baopals.com` },
    { name: 'twitter:description', content: description },
    { name: 'twitter:creator', content: twitter || '@baopals' },
    {
      name: 'twitter:image',
      content: image,
    },
  ]

  if (published) {
    metaTags.push({ name: 'article:published_time', content: published })
  }
  if (updated) {
    metaTags.push({ name: 'article:modified_time', content: updated })
  }
  if (category) {
    metaTags.push({ name: 'article:section', content: category })
  }
  if (tags) {
    metaTags.push({ name: 'article:tag', content: tags })
  }
  return metaTags
}

const SEO: React.SFC<any> = ({
  schema,
  title,
  description,
  contentType,
  published,
  updated,
  category,
  tags,
  twitter,
  image,
}) => {
  const {
    match: { url },
  } = useRouter()

  return (
    <Helmet
      htmlAttributes={{
        lang: localStorage.getItem('preferredLanguage') || 'en',
        itemscope: undefined,
        itemtype: `http://schema.org/${schema}`,
      }}
      title={`${title === undefined ? 'Loading...' : title} | baopals.com`}
      link={[{ rel: 'canonical', href: window.location.href }]}
      meta={getMetaTags({
        image,
        schema,
        title,
        description,
        path: url,
        contentType,
        published,
        updated,
        category,
        tags,
        twitter,
      })}
    />
  )
}

export default SEO
