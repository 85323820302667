import {
  ArticleData,
  CategoryData, Cover,
  FetchArticleRespond,
  FetchArticlesOpt,
  FetchArticlesRespond,
  FetchCategoriesRespond,
  FetchTrendingProductsRespond,
  MetaData,
  ProductData,
  segmentAttachmentsData,
  SegmentData
} from "@interfaces/blog";
import { fetchBlog } from '@services/blog'
import * as service from '@services/newBlog'
import { useEffect, useState } from 'react'
import { useLoading } from './useLoading'
import { sortBy } from "lodash"

export interface SingleArticleProps {
  article: ArticleData
  segments?: SegmentData[]
  segmentAttachments?: segmentAttachmentsData[]
  suggested: ArticleData[]
  users?: any[]
  products?: any[]
}

export interface DataProps {
  articles: ArticleData[]
  products?: ProductData[]
  covers?: Cover[]
}

const defaultMeta: MetaData = {
  currentPage: 0,
  articlesCount: 0,
  totalPages: 0,
}

const defaultCategories: any[] = []
const defaultData: DataProps = {
  products: [],
  articles: [],
  covers: []
}
const defaultSingleArticle: SingleArticleProps = {
  article: null,
  segments: [],
  segmentAttachments: [],
  suggested: [],
}

export const useBlog = (categories: string) => {
  const { loading, onStart, onStop } = useLoading()
  const [blog, setBlog] = useState<any[]>([])

  useEffect(
    () => {
      onStart()

      const sub = fetchBlog(categories).subscribe(
        data => {
          setBlog(data)
          onStop()
        },
        err => console.warn(err)
      )

      return () => sub.unsubscribe()
    },
    [categories]
  )

  return { loading, blog }
}


export const useBlogCategories = () => {
  const [categories, setCategories] = useState<CategoryData[]>(defaultCategories)
  const { loading, onStart, onStop } = useLoading()

  useEffect(() => {
    onStart()

    const sub = service.fetchBlogCategories().subscribe((res: FetchCategoriesRespond) => {
      setCategories(sortBy(res.data.categories, 'title'))
      onStop()
    })

    return () => sub.unsubscribe()
  }, [])

  return {
    loading,
    categories,
  }
}

export const useArticles = (opt: FetchArticlesOpt = {}) => {
  const { loading, onStart, onStop } = useLoading()

  const [data, setData] = useState<DataProps>(defaultData)
  const [meta, setMeta] = useState<MetaData>(defaultMeta)

  const refetchFields = ['slug', 'page', 'sq', 'latest', 'per', 'target'].map(key => opt[key])

  useEffect(() => {
    onStart()

    const sub = service.fetchArticles(opt).subscribe((res: FetchArticlesRespond) => {
      setData(res?.data ?? defaultData)
      setMeta(res?.meta ?? defaultMeta)
      onStop()
    })

    return () => sub.unsubscribe()
  }, refetchFields)

  return {
    loading,
    data,
    meta,
  }
}

export const useTrendingProducts = () => {
  const { loading, onStop, onStart } = useLoading()
  const [products, setProducts] = useState<any[]>([])
  const [trendings, setTrendings] = useState<any[]>([])

  useEffect(() => {
    onStart()

    const sub = service.fetchTrendingProducts().subscribe((res: FetchTrendingProductsRespond) => {
      setProducts(res.data.products)
      setTrendings(res.data.trendings)
      onStop()
    })

    return () => sub.unsubscribe()
  }, [])

  return {
    loading,
    products,
    trendings
  }
}

export const useArticle = (slug: string) => {
  const { loading, onStart, onStop } = useLoading()
  const [data, setData] = useState<SingleArticleProps>(defaultSingleArticle)

  useEffect(() => {
    onStart()

    const sub = service.fetchArticle(slug).subscribe((res: FetchArticleRespond) => {
      if (res.status === 200) {
        setData(res.response.data)
      }

      onStop()
    })

    return () => sub.unsubscribe()
  }, [slug])

  return {
    loading,
    data,
  }
}
