import {Link} from 'react-router-dom'
import {isInClothingCategoryList} from '@utils/product/clothingUtils'

export const ClothingSizeGuide = ({categories}) => {
  const current = isInClothingCategoryList(categories)

  if (current) {
    return (
      <div className='tw-mt-2'>
        Click here for our handy <Link to={`/clothing-size-guide/${current.slug}`} target='_blank'>Clothing Size Guide</Link>.
      </div>
    )
  }

  return null
}

ClothingSizeGuide.defaultProps = {
  categories: []
}
