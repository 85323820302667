import Selector from '@components/common/Selector'
import React, { FC, useEffect, useState } from 'react'
import CollectionCategories from '../CollectionCategories'
import './styles.css'

interface CollectionCategorySelectorProps {
  value: string
  placeholder?: string

  className?: string
  arrowSize?: 'sm' | 'md'

  onChange: (v: string) => void
}

const CollectionCategorySelector: FC<CollectionCategorySelectorProps> = props => {
  const [value, setValue] = useState(props.value)

  const handleChange = v => {
    setValue(v)
    props.onChange(v)
  }

  useEffect(
    () => {
      setValue(props.value)
    },
    [props.value]
  )

  return (
    <CollectionCategories>
      {({ data }) => (
        <Selector
          className={`fs14 ${props.className}`}
          arrowSize={props.arrowSize}
          styleName="select"
          name="category"
          placeholder={props.placeholder}
          value={value}
          onChange={handleChange}
          renderOptions={
            <>
              <option value="" className="text-capitalize">
                {props.placeholder}
              </option>
              {data.map((c, index) => (
                <option className="text-capitalize" value={c.value} key={index}>
                  {c.name}
                </option>
              ))}
            </>
          }
        />
      )}
    </CollectionCategories>
  )
}

CollectionCategorySelector.defaultProps = {
  placeholder: 'Select a Category',
  className: '',
  arrowSize: 'sm',
}

export default CollectionCategorySelector
