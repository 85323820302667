import React, {FC} from 'react'
import {FormattedMessage} from 'react-intl'

interface DefaultProps {
  handleShuffle: () => void
}

const Shuffle: FC<DefaultProps> = ({handleShuffle}) => (
  <div className="text-center tw-mt-6 tw-mb-12 bold">
    <a
      className='load-more blue-link load-more--reviews text-center tw-m-0'
      onClick={handleShuffle}
    >
      <i className="icon-shuffle-loadMore" />
      <div className="fs12">
        <FormattedMessage id="homepage.reviews_more" />
      </div>
    </a>
  </div>
)

export default Shuffle
