import { Data, Meta } from '@interfaces/notification'
import { Observable, of } from 'rxjs'
import { catchError, map, pluck } from 'rxjs/operators'
import ajax from '../api/ajax'
import { sortByDate } from '@utils/array'

export const fetchNotifications = (page: number): Observable<{ data: Data; meta: Meta }> =>
  ajax({
    url: '/me/notifications',
    query: { page },
  }).pipe(
    pluck('response'),
    map(res => {
      const data = res.data
      const notifications = data.notifications.sort(sortByDate)

      return { ...res, meta: { ...res.meta, unreadCount: data.unreadCount }, data: { ...res.data, notifications  } }
    }),
    catchError(e =>
      of({
        data: { notifications: [] },
        meta: { total: 0 },
      })
    )
  )

export const markAsRead = (ids: number[]): Observable<any> =>
  ajax({
    url: '/me/notifications/mark_as_read',
    method: 'POST',
    body: { ids },
  }).pipe(pluck('response'))

export const markAllAsRead = (): Observable<any> =>
  ajax({
    url: '/me/notifications/mark_all_as_read',
    method: 'POST',
  }).pipe(pluck('response'))
