import { Desktop } from '@components/common/Responsive'
import { useMyCountry } from '@hooks/useAuth'
import { useRouter } from '@hooks/useRouter'
import { useScroll } from '@hooks/useScroll'
import { useEffect } from 'react'
import { Route, Switch } from 'react-router'
import Banner from '../Nav/Intl/Banner'
import SearchNavbar from '@components/layout/SearchNavbar'
import NavLinks from '@components/layout/NavLinks'
import './styles.css'

const Style = () => {
  // useEffect(() => {
  //  const $content = document.querySelector('#content')

  //  $content.style.marginTop = '0px'

  //  // return () => {
  //  //  $content.style.removeProperty('margin-top')
  //  // }
  // }, [])

  return null
}

const Hook = ({ scrolled, pathname }) => {
  useEffect(
    () => {
      const $content = document.querySelector('#content')

      if (scrolled) {
        $content.style.marginTop = pathname === '/' ? '530px' : pathname === '/signup' ? '-10px' : '195px'
      } else {
        $content.style.marginTop = '30px'
        if (pathname === '/signup') {
          $content.style.marginTop = '-10px'
        }
      }
    },
    [scrolled]
  )
  return <div className="hack" />
}

const IntlNav = ({ }) => {
  const { scrollY } = useScroll()
  const { location } = useRouter()
  // const scrollY = useScrollPosition(60)
  const scrolled = scrollY >= (location.pathname === '/' ? 385 : 50)

  const { isInternational } = useMyCountry()

  useEffect(
    () => {
      const $content = document.querySelector('#content')

      if (isInternational) {
        $content.style.marginTop = '30px'
        if (location.pathname === '/signup') {
          $content.style.marginTop = '-10px'
        }
      }

      return () => {
        $content.style.removeProperty('margin-top')
      }
    },
    [isInternational]
  )

  const search = location.pathname.includes('signup') ? null : (
    <div styleName="search">
      <SearchNavbar scrolled={scrolled} />
    </div>
  )

  // return isInternational ? (
  //   //   <>
  //   //     <div
  //   //       data-scrolled={scrolled}
  //   //       styleName="wrapper"
  //   //       className="global-navbar"
  //   //       data-url={location.pathname}
  //   //       id="global-navbar"
  //   //     >
  //   //       <Route path="/" exact={true} component={Banner} />
  //   //       <Switch>
  //   //         <Route path="/" exact={true} render={() => search} />
  //   //         <Route
  //   //           path="*"
  //   //           render={() => (
  //   //             <div styleName="inner">
  //   //               <NavLinks scrolled={scrolled} />
  //   //               {search}
  //   //             </div>
  //   //           )}
  //   //         />
  //   //       </Switch>
  //   //     </div>
  //   //     <Hook scrolled={scrolled} pathname={location.pathname} />
  //   //     <Desktop>
  //   //       <Style />
  //   //     </Desktop>
  //   //   </>
  //   // ) : null

  return (
    <>
      <div
        data-scrolled={scrolled}
        styleName="wrapper"
        className="global-navbar"
        data-url={location.pathname}
        id="global-navbar"
      >
        <Route path="/" exact={true} component={Banner} />
        <Switch>
          <Route path="/" exact={true} render={() => search} />
          <Route
            path="*"
            render={() => (
              <div styleName="inner">
                <NavLinks scrolled={scrolled} />
                {search}
              </div>
            )}
          />
        </Switch>
      </div>
      <Hook scrolled={scrolled} pathname={location.pathname} />
      <Desktop>
        <Style />
      </Desktop>
    </>
  )
}

IntlNav.defaultProps = {
  scrolled: false,
}

export default IntlNav
