import { truthyOrZero } from '@utils/objectUtils'
import React, { ReactNode } from 'react'
import './styles.css'

interface Counter {
  className?: string
  quantity: number
  min: number
  max: number
  decreaseQuantity: any
  increaseQuantity: any
  updateQuantity: any
  onFocus: any
  disabled?: boolean

  renderMinus?: ReactNode
  renderPlus?: ReactNode
}

const Counter: React.SFC<Counter> = ({
  quantity,
  min,
  max,
  decreaseQuantity,
  increaseQuantity,
  updateQuantity,
  onFocus,
  ...props
}) => {
  const minusClassNames = ['l-h-1']
  const minusDivClassNames = []

  if (quantity <= min || props.disabled) {
    minusClassNames.push('bp-grey-text')
    minusClassNames.push('cursor-default')
    minusDivClassNames.push('cursor-default')
    minusDivClassNames.push('no-pointer-events')
  }

  const plusClassNames = ['l-h-1']
  const plusDivClassNames = []

  if ((truthyOrZero(max) && quantity >= max) || props.disabled) {
    plusDivClassNames.push('cursor-default')
    plusDivClassNames.push('no-pointer-events')
    plusClassNames.push('bp-grey-text')
    plusClassNames.push('cursor-default')
  }

  return (
    <div styleName="wrapper" className={`input-number ${props.className}`}>
      <div styleName="unit" className={minusDivClassNames.join(' ')} onClick={decreaseQuantity}>
        <span className={minusClassNames.join(' ')}>{props.renderMinus}</span>
      </div>

      <input
        styleName="qty"
        type="number"
        min={min}
        value={quantity}
        disabled={max === 0 || props.disabled}
        onChange={updateQuantity}
        onFocus={onFocus}
      />

      <div styleName="unit" className={plusDivClassNames.join(' ')} onClick={increaseQuantity}>
        <span className={plusClassNames.join(' ')}>{props.renderPlus}</span>
      </div>
    </div>
  )
}

Counter.defaultProps = {
  className: '',
  renderMinus: <i className="icon-minus fs10" />,
  renderPlus: <i className="icon-plus fs10" />,
}

export default Counter
