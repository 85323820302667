import { Platform } from '@interfaces/platform'
import qs from 'qs'
import React from 'react'
import { Link } from 'react-router-dom'
import '../styles.css'

const SellerLink: React.SFC<{ id: number }> = ({ id, platform, ...props }) => {
  return (
    <Link
      to={`${[]
        .concat(platform === Platform.JD ? '/express/seller' : '/seller')
        .concat(id)
        .join('/')}${qs.stringify({ ...props.query }, { addQueryPrefix: true })}`}
      className="black-link"
      styleName="seller"
    >
      <i className="icon-seller-shop" />
    </Link>
  )
}

export default SellerLink
