import * as constants from '../constants/departmentConstants'
import {
  fetchDeptPopularSellers,
  fetchSingleDepartment,
  fetchAllDepartments,
  fetchDeptPopularProducts,
} from '@services/'

export const fetchAll = params => dispatch => {
  dispatch({ type: constants.FETCH_ALL_REQUEST })

  return fetchAllDepartments(params)
    .toPromise()
    .then(res =>
      dispatch({
        type: constants.FETCH_ALL_DONE,
        payload: res,
      })
    )
    .catch(err => {})
}

export const fetch = id => dispatch => {
  dispatch({ type: constants.FETCH_REQUEST })

  return fetchSingleDepartment(id)
    .toPromise()
    .then(dept =>
      dispatch({
        type: constants.FETCH_DONE,
        payload: dept,
      })
    )
    .catch(err => {})
}

export const fetchPopularProducts = (id, params) => dispatch => {
  dispatch({ type: constants.FETCH_POPULAR_PRODUCTS_REQUEST })

  return fetchDeptPopularProducts(id, params)
    .toPromise()
    .then(res =>
      dispatch({
        type: constants.FETCH_POPULAR_PRODUCTS_DONE,
        payload: res,
      })
    )
    .catch(err => {})
}

export const fetchFeaturedSellers = id => dispatch => {
  dispatch({ type: constants.FETCH_FEATURED_SELLERS_REQUEST })

  return fetchDeptPopularSellers(id)
    .toPromise()
    .then(res =>
      dispatch({
        type: constants.FETCH_FEATURED_SELLERS_DONE,
        payload: res,
      })
    )
    .catch(err => {})
}
