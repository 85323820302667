import { HttpError } from '@errors/HttpError'
import { Channel, PaymentMethods } from '@interfaces/payment'
import { createStandardAction } from 'typesafe-actions'
import ACTION from '../constants/paymentConfig'

export const fetchPaymentConfig = createStandardAction(ACTION.FETCH_PAYMENT_CONFIG_REQUEST)()
export const fetchPaymentConfigSuccess = createStandardAction(ACTION.FETCH_PAYMENT_CONFIG_SUCCESS)<{
  rates: number
  channels: Channel
}>()
export const fetchPaymentConfigFailed = createStandardAction(ACTION.FETCH_PAYMENT_CONFIG_FAILURE)<
  HttpError
>()

export const updateDefaultCurrency = createStandardAction(ACTION.UPDATE_DEFAULT_CURRENCY)<
  string
>()

export const updateSelectedPaymentMethod = createStandardAction(
  ACTION.UPDATE_SELECTED_PAYMENT_METHOD
)<{ method: PaymentMethods; countryCode: String }>()
