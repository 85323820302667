import { ADD_TOAST, REMOVE_TOAST } from '../constants/toasts'

const initialState = []

export default function toasts(state = initialState, { type, payload = {} }) {
  switch (type) {
    case ADD_TOAST:
      return [{ id: Date.now(), ...payload }, ...state]

    case REMOVE_TOAST:
      return state.filter(toast => toast.id !== payload)

    default:
      return state
  }
}
