import React from 'react'
import { Modal } from 'react-bootstrap'
import ProductLoading from '@components/product/v4/ProductLoading'
import MySingleProduct from '@components/product/v4/SingleProduct'
import ErrorPage from '@components/common/ErrorPage'
import StickyProductPrice from '@components/common/StickyProductPrice'

const ProductDetailsModal = ({ product, open, onClose, loading, ...props }) => (
  <>
    <Modal
      show={open}
      onHide={onClose}
      bsSize="large"
      className={`product-details-modal ${props.className}`}
      backdropClassName="product-details-modal-backdrop"
    >
      <Modal.Header closeButton />

      <Modal.Body>
        {loading ? (
          <ProductLoading />
        ) : product ? (
          <>
            <MySingleProduct
              selected={props.selected}
              product={product}
              onAddedToCart={onClose}
              enableSimilarProducts={false}
            />
          </>
        ) : (
          <ErrorPage error={{ status: 500 }} />
        )}
      </Modal.Body>
    </Modal>
    {product?.id && <StickyProductPrice id={product.id} />}
  </>
)

ProductDetailsModal.defaultProps = {
  className: '',
}

export default ProductDetailsModal
