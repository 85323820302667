import { shuffle } from 'lodash'

export const combineProductsWithOtherItems = (_items, products, delta, alpha) => {
  const items = _items ?? []
  const firstItem = items[0] || []
  const restItems = items.filter((i, index) => index > 0)
  const topProducts = products.slice(0, delta)
  const restProducts = products.slice(delta, products.length)
  const combineFirstItem = topProducts.concat(firstItem)
  let combineRestItems  = [...restProducts]

  for (let i = 0; i < restProducts.length; i++) {
    const index = (i + 1) / alpha - 1

    if((i + 1) % alpha === 0 && restItems[index]) {
      combineRestItems.splice(i, 0, restItems[index])
    }
  }

  return combineFirstItem.concat(combineRestItems)
}

export const combineProductsWithModules = (_modules, products, delta, alpha) => {
  const { blogArticles, globalParcels, reviews, sellers, departments } = _modules
  const modules = shuffle([...globalParcels, ...blogArticles, ...departments, ...sellers, ...reviews])
  const firstItem = modules[0] || []
  const restItems = modules.filter((i, index) => index > 0)
  const topProducts = products.slice(0, delta)
  const restProducts = products.slice(delta, products.length)
  const combineFirstItem = topProducts.concat(firstItem)
  let combineRestItems  = [...restProducts]

  for (let i = 0; i < restProducts.length; i++) {
    const index = (i + 1) / alpha - 1

    if((i + 1) % alpha === 0 && restItems[index]) {
      combineRestItems.splice(i, 0, restItems[index])
    }
  }

  return combineFirstItem.concat(combineRestItems)
}
