import TranslationText from '@components/common/TranslationText'
import { Titles } from '@interfaces/common'
import { Platform } from '@interfaces/platform'
import React from 'react'
import './styles.css'

interface ProductTitleProps {
  titles: Titles
  platform: Platform
  selected: Array<any>
}

const ProductTitle: React.SFC<ProductTitleProps> = ({ platform, titles, selected }) => {
  const target = platform === Platform.JD ? (selected ?? []).filter(v => v.thumbnail).find(v => v.titles)?.titles : null
  const _titles = target ?? titles

  return (
    <>
      {_titles?.en ? (
        <div
          styleName="title"
          className="product-title tw-my-4 mb-md-5 line-max line-max-3 text-capitalize"
        >
          {_titles.en}
        </div>
      ) : (
        <TranslationText texts={target ?? titles}>
          {text => (
            <div
              styleName="title"
              className="product-title tw-my-4 mb-md-5 line-max line-max-3 text-capitalize"
            >
              {text}
            </div>
          )}
        </TranslationText>
      )}
    </>
  )
}

export default ProductTitle
