import React, { Component } from 'react'
import './styles.css'

const CircleSpinner = ({ className }) => {
  return <div styleName="spinner" className={className} />
}

CircleSpinner.defaultProps = {
  className: '',
}

export default CircleSpinner
