import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ProductFilter from '../ProductFilter'
import ProductBrandFilter from '../ProductBrandFilter'
import ProductPropFilter from '../ProductPropFilter'
import { withRouter } from 'react-router'
import { Button, Panel } from 'react-bootstrap'
import CircleSpinner from '../../common/CircleSpinner'
import { FormattedMessage } from 'react-intl'
import './styles.css'

class ProductFilters extends Component {
  constructor(props) {
    super(props)

    this.state = { onExit: false }

    this.onExit = this.onExit.bind(this)
    this.onExited = this.onExited.bind(this)
  }

  onExit() {
    this.setState({
      onExit: true,
    })
  }

  onExited() {
    this.setState({
      onExit: false,
    })
  }

  render() {
    const {
      // Brand Filter
      brands,
      selectedBrands,
      onBrandFilterChange,
      onConfirm,

      // Properties Filter
      props,
      selectedProps,
      onPropFilterChange,
      status,
    } = this.props

    return (
      <div styleName={status == 0 ? 'wrapper-loading' : 'wrapper'} className="search-filter">
        <Button
          bsClass=""
          styleName={this.props.open ? 'filter-toggle-expanded' : 'filter-toggle-collapsed'}
          onClick={this.props.onToggleFilter}
          style={{ zIndex: this.state.onExit ? 12 : 0 }}
        >
          {status == 0 && <CircleSpinner />}
          <div className="bold upper">
            <FormattedMessage id="search_category_page.filters" />
          </div>
        </Button>

        <Panel
          id="tmall-filters"
          expanded={this.props.open}
          styleName="inner"
          onToggle={() => null}
        >
          <Panel.Collapse>
            <Panel.Body>
              {/*
                brands.length ?
                <ProductFilter
                  type='brand'
                  items={brands}
                  selectedItems={selectedBrands}
                  onFilterChange={onBrandFilterChange}
                />
                : null
              */}

              {props.length
                ? props.map((prop, propIndex) => {
                    return (
                      <ProductFilter
                        key={prop.id}
                        type="prop"
                        id={prop.id}
                        title={prop.name}
                        items={prop.values}
                        selectedItems={selectedProps}
                        onFilterChange={value => onPropFilterChange(prop.id, value)}
                      />
                    )
                  })
                : null}

              {brands.length || props.length ? (
                <div className="text-center">
                  <a styleName="confirm" className="btn btn-white" onClick={onConfirm}>
                    <FormattedMessage id="search_category_page.confirm" />
                  </a>
                </div>
              ) : null}

              {brands.length == 0 && props.length == 0 ? (
                <div className="text-center">
                  <FormattedMessage id="search_category_page.no_filters" />
                </div>
              ) : null}
            </Panel.Body>
          </Panel.Collapse>
        </Panel>
      </div>
    )
  }
}

ProductFilters.defaultProps = {
  brands: [],
  selectedBrands: [],
  props: [],
  selectedProps: [],
}

ProductFilters.propTypes = {
  status: PropTypes.number,

  brands: PropTypes.array,
  selectedBrands: PropTypes.array,
  onBrandFilterChange: PropTypes.func,

  props: PropTypes.array,
  selectedProps: PropTypes.array,
  onPropFilterChange: PropTypes.func,
  fetchProps: PropTypes.func,

  onConfirm: PropTypes.func.isRequired,
}

export default withRouter(ProductFilters)
