import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'

import { WechatAuthState } from '@interfaces/auth'
import WeChatAuth from './WeChatAuth'

interface WechatAuthBtnProps {
  className?: string
}

const WechatAuthBtn: FC<WechatAuthBtnProps> = ({ className }) => {
  return (
    <div className={className}>
      <WeChatAuth state={WechatAuthState.MANUALLY_AUTH} />
      <FormattedMessage id="general.sign_in_wechat" />
    </div>
  )
}

WechatAuthBtn.defaultProps = {
  className: '',
}

export default WechatAuthBtn
