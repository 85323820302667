import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as ProductPropsActionCreator from '../actions/productProps'
import ProductFilters from '../components/product/ProductFilters'
import qs from 'qs'

const mapStateToProps = state => ({
  state: state.productProps,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(ProductPropsActionCreator, dispatch),
})

@connect(
  mapStateToProps,
  mapDispatchToProps
)
class TmallProductPropsContainer extends Component {
  constructor(props) {
    super(props)
    this.onBrandFilterChange = this.onBrandFilterChange.bind(this)
    this.onPropFilterChange = this.onPropFilterChange.bind(this)
    this.onConfirm = this.onConfirm.bind(this)

    this.onToggleFilter = this.onToggleFilter.bind(this)
  }

  componentDidMount() {
    const {
      category,
      actions: { fetchProductProps },
    } = this.props

    if (category && this.props.state.selectedProps.length) fetchProductProps(category)
  }

  async componentWillReceiveProps(nextProps) {
    if (this.props.category !== nextProps.category) {
      if (nextProps.state.selectedProps.length) {
        nextProps.actions.togglePropSection(false)
        if (nextProps.category) {
          await nextProps.actions.fetchProductProps(nextProps.category)
        }
      } else {
        await nextProps.actions.resetProps()
      }
    }
  }

  onBrandFilterChange({ brand }) {
    const { actions } = this.props
    actions.updateSelectedBrands(brand)
  }

  onPropFilterChange(key, value) {
    const { actions } = this.props
    actions.updateSelectedProps(key, value)
  }

  onConfirm() {
    this.props.actions.togglePropSection(false)
    const { location, state, actions } = this.props

    const brands = state.selectedBrands.join(',')
    const props = state.selectedProps.map(prop => `${prop.id}:${prop.value.join(',')}`).join(';')

    const { brand, prop, ...query } = qs.parse(location.search, { ignoreQueryPrefix: true })
    this.props.history.push({
      pathname: location.pathname,
      search: qs.stringify({
        ...query,
        ...(brands && { brand: brands }),
        ...(props && { prop: props }),
      }),
    })
  }

  async onToggleFilter() {
    if (!this.props.state.props.length && this.props.category) {
      await this.props.actions.fetchProductProps(this.props.category)
    }
    this.props.actions.togglePropSection(!this.props.state.toggle)
  }

  handleFetchProps = () => {
    if (this.props.category) {
      this.props.actions.fetchProductProps(this.props.category)
    }
  }

  render() {
    const {
      state: { status, toggle, props, brands, ...state },
      actions,
    } = this.props

    return (
      <ProductFilters
        status={status}
        brands={brands}
        selectedBrands={state.selectedBrands}
        onBrandFilterChange={this.onBrandFilterChange}
        props={props}
        selectedProps={state.selectedProps}
        fetchProps={this.handleFetchProps}
        onPropFilterChange={this.onPropFilterChange}
        onConfirm={this.onConfirm}
        open={toggle}
        onToggleFilter={this.onToggleFilter}
      />
    )
  }
}

TmallProductPropsContainer.propTypes = {
  category: PropTypes.string,
}

export default withRouter(TmallProductPropsContainer)
