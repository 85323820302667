import { ImageWithOverlay } from '@components/common/Image'
import ProductLink from '@components/common/ProductLink'
import UserLink from '@components/common/UserLink'
import { ProductData, UserData } from '@interfaces/reviews'
import { cloudObjectToURL } from '@utils/'
import { getAvatar } from '@utils/review'
import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { DEFAULT_PRODUCT_PLACEHOLDER } from '@constants/index'

interface DefaultProps {
  showUser?: boolean
  user: UserData
  product: ProductData
  enableAffiliate?: boolean
  reviewId: number
  withoutLink?: boolean
}

const Overlay = (
  <div>
    <FormattedMessage id="general.view_product" />
  </div>
)

const ShortOverlay = (
  <div>
    <FormattedMessage id="general.view" defaultMessage="view" />
  </div>
)

const ReviewPic: FC<DefaultProps> = ({ showUser, user, product, withoutLink, ...props }) => {
  const pic = showUser
    ? cloudObjectToURL(user.avatar) || BAOPALS.ANONYMOUS_PLACEHOLDER
    : cloudObjectToURL(product.cover) || DEFAULT_PRODUCT_PLACEHOLDER

  if (showUser && (!user || withoutLink)) {
    return <ImageWithOverlay pic={pic} circle={true} disableOverlay={true} />
  }

  return (
    <ImageWithOverlay
      pic={pic}
      link={
        showUser ? (
          <UserLink username={user.userName} />
        ) : (
          <ProductLink
            id={product.id}
            review={true}
            title=""
            platform={product.platformType}
            {...props.enableAffiliate && {
              affiliateVia: 'product_review',
              affiliateToken: user.affiliateToken,
              shareableId: props.reviewId,
            }}
          />
        )
      }
      OverlayInfo={showUser ? ShortOverlay : Overlay}
      circle={showUser}
    />
  )
}

ReviewPic.defaultProps = {
  showUser: false,
  enableAffiliate: false,
}

export default ReviewPic
