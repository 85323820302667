import React from 'react'
import { fileToBlob } from '@utils/'

type OtherProps = {
  file: File
  renderClose: React.ReactNode
}

const handleClick = (file: File) => {
  const a = document.createElement('a')
  const blob = fileToBlob(file)
  document.body.appendChild(a)
  a.style.display = 'none'
  a.href = blob
  a.download = file.name
  a.click()
  window.URL.revokeObjectURL(blob)
}

const Other: React.FC<OtherProps> = ({file, renderClose}) => (
  <a className="previewer-other" onClick={() => handleClick(file)}>
    {file.name}
    {' '}
    {renderClose}
  </a>
)

export default Other
