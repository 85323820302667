import useAuth from '@hooks/useAuth'
import { openSignUpModal } from '@hooks/useSignUpModal'

export const WithAuth = props => {
  const { user } = useAuth()

  const handleClick = e => {
    if (!user) {
      e && e.preventDefault()
      openSignUpModal()

      return
    }

    return props.onClick?.()
  }

  return props.children(handleClick)
}
