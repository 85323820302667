import React               from 'react'
import ProgressiveSkeleton from '../ProgressiveSkeleton'

const MediaLoading = () => (
  <div className="media">
    <div className="media-left">
      <ProgressiveSkeleton circle={true} width={65} height={65}  />
    </div>

    <div className="media-body">
      <ProgressiveSkeleton width='100%' height={18} enableMoveAnimation />
      <ProgressiveSkeleton width='80%' height={18} enableMoveAnimation />
      <ProgressiveSkeleton width='50%' height={18} enableMoveAnimation />
    </div>
  </div>
)

export default MediaLoading
