import * as ACTION from '@actions/coupon'
import * as ORDER_ACTIONS from '@actions/orders'
import AUTH_CONSTANTS from '@constants/auth'
import { createReducer } from 'typesafe-actions'

export interface CouponProps {
  readonly coupon: null
}

const initialState: CouponProps = {
  coupon: null,
}

export const coupon = createReducer(initialState)
  .handleAction(ACTION.applyCoupon, (state, action) => {
    return { ...state, coupon: null }
  })
  .handleAction(ACTION.applyCouponSuccess, (state, action) => {
    return { ...state, coupon: action.payload }
  })
  .handleAction(
    [ORDER_ACTIONS.createPaymentSuccess, AUTH_CONSTANTS.USER_SIGN_OUT],
    () => initialState
  )
