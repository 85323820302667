import { useQuery } from '@hooks/useRouter'
import qs from 'qs'
import React, { FC, useEffect, useState } from 'react'
import Dropdown from './Dropdown'
import List from './List'
import options from './options.json'

interface SortingsProps {
  display: 'list' | 'dropdown'
  disabled?: boolean
}

const Sortings: FC<SortingsProps> = props => {
  const [date, setDate] = useState('')

  const { date: dt, ...query } = useQuery()

  useEffect(
    () => {
      setDate(dt || '')
      props.onChange?.(dt || '')
    },
    [dt]
  )

  const commonProps = {
    value: date,
    disabled: props.disabled,
    onChange: val => {
      props.onChange?.(val)
      setDate(val)
    },
  }

  return (
    <>
      {props.display === 'list' && <List options={options} {...commonProps} name="date" />}
      {props.display === 'dropdown' && (
        <Dropdown
          options={options.map(opt => ({
            ...opt,
            url: `${window.location.pathname}?${qs.stringify({
              ...query,
              ...(opt.value && { date: opt.value }),
            })}`,
          }))}
          value={date}
          {...commonProps}
        />
      )}
    </>
  )
}

Sortings.defaultProps = {
  disabled: false,
}

export default Sortings
