import { FavoriteProductSeller } from '@interfaces/seller'
import React, { FC } from 'react'
import Images from './Images'
import Info from './Info'
import { UserProfile } from '@interfaces/user'
import './styles.css'

interface Props {
  seller: {
    access?: string
    category: string
    id?: number
    position?: number
    productSeller: FavoriteProductSeller
  }
  className?: string
  isMe?: boolean
  onUpdate?: any
  onDelete?: any
  country: string
  userProfile?: UserProfile
  affiliateToken?: string
  isWhatsHot?: boolean
}

const SellerCard: FC<Props> = ({ seller, isMe, ...props }) => {
  const query = props.userProfile
    ? {
        at: props.userProfile.affiliateToken,
        via: 'seller',
      }
    : undefined

  return (
    <div
      className={props.className || ''}
      styleName="wrapper"
      data-banned={seller.productSeller.bannedFor.includes(props.country)}
      data-isme={isMe}
    >
      <div className="bd-light tw-h-full">
        <Images covers={seller.productSeller.covers} query={query} isWhatsHot={props.isWhatsHot} />
        <Info
          seller={seller}
          isMe={isMe}
          onUpdate={props.onUpdate}
          onDelete={props.onDelete}
          query={query}
          isWhatsHot={props.isWhatsHot}
        />
      </div>
    </div>
  )
}

export default SellerCard
