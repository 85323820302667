import EmptyCart from '@components/checkout/EmptyCart'
import ToggleContent from '@components/layout/header/Cart/ToggleContent'
import MyCart from '@hoc/Cart'
import {useCartItems} from '@hooks/useCart'
import React, {memo, useState} from 'react'
import {Dropdown} from 'react-bootstrap'
import Widget from '../../../cart/Widget'
import '../styles.css'
import Welcome from '../Welcome'

const ShoppingCart = props => {
  return (
    <>
      <MyCart>
        {({totalCounts, loading, actions, products}) => {
          return (
            <Cart
              totalCounts={totalCounts}
              loading={loading}
              isSignedIn={true}
              products={products}
              {...actions}
            />
          )
        }}
      </MyCart>
    </>
  )
}

export default ShoppingCart

const Cart = memo(({products, ...props}) => {
  const {items, totalCounts} = useCartItems()

  const [showCart, setShowCart] = useState(false)

  const updateItemQuantity = (id, qty, opts: any) => props.updateCartItemQty({id, qty, opts})

  const toggleCart = (state, e) => {
    if (e) {
      setShowCart(state)
    }
  }

  return (
    <ul className="nav navbar-nav navbar-right hidden-xs">
      <li className="dropdown-toggle">
        <Dropdown id="mini-cart-dropdown" open={showCart} onToggle={toggleCart}>
          <Dropdown.Toggle className="mini-cart-dropdown-toggle">
            <ToggleContent count={props.totalCounts} />
          </Dropdown.Toggle>

          <Dropdown.Menu className="cart-dropdown-menu" styleName="cartdrop">
            {!props.isSignedIn ? (
              <Welcome />
            ) : totalCounts === 0 ? (
              <div className="text-center tw-mt-12 tw-mb-12" styleName="emptycart">
                <EmptyCart />
              </div>
            ) : (
              <Widget
                cart={{products, items}}
                loading={props.loading}
                onUpdateItemQuantity={updateItemQuantity}
                onDeleteItem={props.removeCartItem}
                onClose={() => toggleCart(false, 1)}
              />
            )}
          </Dropdown.Menu>
        </Dropdown>
      </li>
    </ul>
  )
})
