import { Tracking } from '@interfaces/tracking'
import { formatAddressWithZones, getCompatibleAddress } from '@utils/address'
import { isGreaterBayArea } from '@utils/i18n'
import { Observable, of } from 'rxjs'
import { catchError, map, pluck } from 'rxjs/operators'
import ajax from '../api/ajax'

export const fetchTracking = (id: number, countries = []): Observable<Tracking> =>
  ajax({
    url: `/me/orders/items/${id}/track`,
    method: 'GET',
  }).pipe(
    pluck('response', 'data'),
    map(res => ({
      ...res,
      address: {
        ...res.address,
        address: formatAddressWithZones(
          getCompatibleAddress(res.address.zoneId ? res.address : res.address.address || ''),
          isGreaterBayArea(res.address.country) ? [] : res.zones, countries
        ),
      },
    })),
    catchError(() => of({ tracking: null }))
  )
