import useAuth from '@hooks/useAuth'
import { openSignUpModal } from '@hooks/useSignUpModal'
import React                from "react"
import { MenuItem } from 'react-bootstrap'
import { Nav, NavDropdown } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import DropdownInner        from './DropdownInner'
import DropdownTitle        from './DropdownTitle'
import "./styles.css"

const SignIn = () => (
  <MenuItem onClick={openSignUpModal}>
    <div className="text-center">
      <FormattedMessage id="notifications.sign_in" />
    </div>
  </MenuItem>
)

const NotificationsDropDown = () => {
  const { user } = useAuth()
  const [open, setOpen] = React.useState(false)

  const toggleDropdown = () => setOpen(!open)

  return (
    <>
      <Nav pullRight={true}>
        <NavDropdown
          id="dropdown-notifications"
          title={<DropdownTitle />}
          styleName="dropdown"
          open={open}
          onToggle={toggleDropdown}
          noCaret={true}
        >
          {!user ? <SignIn /> : <DropdownInner toggleDropdown={toggleDropdown} />}
        </NavDropdown>
      </Nav>
    </>
  )
}

export default NotificationsDropDown

