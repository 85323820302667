import React, { Component }           from 'react'
import PropTypes                      from 'prop-types'
import { connect }                    from 'react-redux'
import { bindActionCreators }         from 'redux'
import * as MyCollectionActionCreator from '../actions/myCollection'

const mapStateToProps = state => ({ state: state.myCollection })

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(MyCollectionActionCreator, dispatch)
})

const injectMyCollections = WrappedComponent => {
  class MyCollections extends Component {
    render() {
      const { state, actions, ...props } = this.props
      return (
        <WrappedComponent 
          myCollections={{
            ...state,
            ...actions
          }}
          { ...props }
        />
      )
    }
  }

  return connect(mapStateToProps, mapDispatchToProps)(MyCollections)
}

export default injectMyCollections
