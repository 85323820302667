import { useEffect, useState } from 'react'
import { usePaymentConfig } from '@hooks/usePaymentConfig'
import { useCountries } from '@hooks/useCountries'
import { DEFAULT_CURRENCY } from '@constants/index'
import { formatCurrency, formatCouponOptions } from '@helpers/currencyHelper'

export const useReferals = () => {
  const { referrals } = useCountries()
  const { currency, currencyLocale, rate } = usePaymentConfig()
  const defaultData = { amount: 50, amountWithCurrency: '¥50', spentAmountWithCurrency: '¥500' }
  const [data, setData] = useState(defaultData)
  const showDefault = !referrals || !currency || !currencyLocale || !rate

  useEffect(
    () => {
      if (showDefault) {
        setData(defaultData)
      } else {
        setData({
          amount: referrals.rewards[currency] / 100,

          amountWithCurrency: `${formatCurrency(
            referrals.rewards[currency] / 100,
            currencyLocale,
            currency,
            formatCouponOptions
          )}`,
          spentAmountWithCurrency: `${formatCurrency(
            Math.floor((referrals.minSpent[DEFAULT_CURRENCY] / 100) * rate[currency]),
            currencyLocale,
            currency,
            formatCouponOptions
          )}`,
        })
      }
    },
    [currency, referrals, currencyLocale, rate]
  )

  return data
}
