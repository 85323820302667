import { FC, memo } from 'react'

const Banner: FC<{ id: number }> = memo(({ id }) => (
  <img
    className="img-responsive"
    src={`https://res.cloudinary.com/baopals/image/upload/v1592193289/categories/jd/jd_cat_${id}.jpg`}
    alt=""
  />
))

export default Banner
