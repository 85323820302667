import * as ACTION from '../constants/deptPopularProduct'

const initialState = {
  status: null, // Pending: 0, Success: 1, Error: -1
  error: null,

  products: null,
}

export default function deptPopularProduct(state = initialState, { type, payload = {} }) {
  if (!payload) return state
  const { status, error, products, ...data } = payload

  switch (type) {
    case ACTION.DEPT_POPULAR_PRODUCT:
      return {
        ...state,
        status,
        error,
        products,
      }

    default:
      return state
  }
}
