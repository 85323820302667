import { useLocation } from '@hooks/useRouter'
import React, { useEffect } from 'react'
import { openSignUpModal } from '@hooks/useSignUpModal'

const LoginRequiredHook = () => {
  const { location } = useLocation()

  const fromLocation = (() => {
    if (location.state && location.state.from) {
      return location.state.from
    }
  })()

  useEffect(
    () => {
      if (fromLocation) {
        openSignUpModal()
      }
    },
    [fromLocation]
  )

  return null
}

export default LoginRequiredHook
