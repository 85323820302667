export const CREATE_PRODUCT_REVIEW_REQUEST = `CREATE_PRODUCT_REVIEW_REQUEST`
export const CREATE_PRODUCT_REVIEW_SUCCESS = `CREATE_PRODUCT_REVIEW_SUCCESS`
export const CREATE_PRODUCT_REVIEW_FAILED = `CREATE_PRODUCT_REVIEW_FAILED`

export const EDIT_MY_REVIEW_REQUEST = `EDIT_MY_REVIEW_REQUEST`
export const EDIT_MY_REVIEW_SUCCESS = `EDIT_MY_REVIEW_SUCCESS`
export const EDIT_MY_REVIEW_FAILED = `EDIT_MY_REVIEW_FAILED`

export const FETCH_USER_REVIEWS_REQUEST = `FETCH_USER_REVIEWS_REQUEST`
export const FETCH_USER_REVIEWS_SUCCESS = `FETCH_USER_REVIEWS_SUCCESS`
export const FETCH_USER_REVIEWS_FAILED = `FETCH_USER_REVIEWS_FAILED`

export const FETCH_REVIEW_DETAILS_REQUEST = `FETCH_REVIEW_DETAILS_REQUEST`
export const FETCH_REVIEW_DETAILS_SUCCESS = `FETCH_REVIEW_DETAILS_SUCCESS`
export const FETCH_REVIEW_DETAILS_FAILED = `FETCH_REVIEW_DETAILS_FAILED`

export const DELETE_REVIEW_REQUEST = `DELETE_REVIEW_REQUEST`
export const DELETE_REVIEW_SUCCESS = `DELETE_REVIEW_SUCCESS`
export const DELETE_REVIEW_FAILED = `DELETE_REVIEW_FAILED`

export const CREATE_USER_REVIEW_REQUEST = `CREATE_USER_REVIEW_REQUEST`
export const CREATE_USER_REVIEW_SUCCESS = `CREATE_USER_REVIEW_SUCCESS`
export const CREATE_USER_REVIEW_FAILED = `CREATE_USER_REVIEW_FAILED`

export const UPDATE_USER_REVIEW_REQUEST = `UPDATE_USER_REVIEW_REQUEST`
export const UPDATE_USER_REVIEW_SUCCESS = `UPDATE_USER_REVIEW_SUCCESS`
export const UPDATE_USER_REVIEW_FAILED = `UPDATE_USER_REVIEW_FAILED`
