export enum CountryCode {
  Australia = 'au',
  Brazil = 'br',
  Belarus = 'by',
  Canada = 'ca',
  Switzerland = 'ch',
  China = 'cn',
  Chile = 'cl',
  Germany = 'de',
  France = 'fr',
  UnitedKingdom = 'gb',
  HongKong = 'hk',
  Indonesia = 'id',
  Ireland = 'ie',
  Israel = 'il',
  Italy = 'it',
  Japan = 'jp',
  Korea = 'kr',
  Malaysia = 'my',
  Netherlands = 'nl',
  NewZealand = 'nz',
  Philippines = 'ph',
  Poland = 'pl',
  Russia = 'ru',
  Sweden = 'se',
  Spain = 'sp',
  aiwan = 'tw',
  Ukraine = 'ua',
  USA = 'us',
  Vietnam = 'vn',
  Macau = 'mo',
  Thailand = 'th',
  Singapore = 'sg',
}
