import FortunePoint from '@components/common/FortunePoint'
import UserLink from '@components/common/UserLink'
import React from 'react'
import '../styles.css'

interface ProfileProps {
  username: string
  avatar: string
  displayName: string
  fortunePoints: number
}

const Profile: React.SFC<ProfileProps> = ({ username, ...props }) => (
  <div className="media">
    <div className="media-left pr-4">
      <UserLink target="reviews" username={username} className="bd-dark d-block" styleName="circle">
        <img
          className="obj-fit-cover img-circle bd-dark"
          src={props.avatar}
          width="72"
          height="72"
        />
      </UserLink>
    </div>
    <div className="media-body text-left media-middle">
      <UserLink target="reviews" username={username} className="strong fs20">
        {props.displayName}
      </UserLink>

      <div className="fs12 tw-mt-2">
        <FortunePoint points={props.fortunePoints} />
      </div>
    </div>
  </div>
)

export default Profile
