import ProductList from '@components/common/ProductList'
import { Desktop, Mobile, MobileAndTablet, Tablet } from '@components/common/Responsive'
import GlobalHelpersTour from '@components/common/Tour/GlobalHelpersTour'
import { useWhatsHotProducts } from '@hooks/useWhatsHot'
import { combineProductsWithOtherItems } from '@utils/whatsHotUtils'
import React, { useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import NotificationsTour from '@components/common/Tour/NotificationsTour'
import { usePaymentConfig } from '@hooks/usePaymentConfig'
import SignUpModal from '../SignUpModal'
import { Loading } from '@components/common/ProductListWithPagination'
import './styles.css'

const Products = props => {
  const [page, setPage] = useState<number>(props.page)

  const { currency } = usePaymentConfig()

  const {
    loading, products, totalPages, startLoading,
    upvoteReview, downvoteReview
  } = useWhatsHotProducts({
    page,
    country: props.country,
    category: props.category,
    device: props.device,
    ...(props.seller_category && { seller_category: props.seller_category }),
  })

  const handleLoadMore = () => {
    startLoading()
    setPage(page + 1)
  }

  const dataLength = products.length

  const renderProductList = () => (
    <div styleName="wrapper" data-isHKD={currency === 'hkd'}>
      <ProductList
        products={products.map(p => ({
          ...p,
          location: null,
        }))}
        upvoteReview={upvoteReview}
        downvoteReview={downvoteReview}
        renderThumbnails={false}
        prettyUpImage={props.prettyUpImage}
        // blogArticles={blogArticles}
        hideDiscount={props.hideDiscount}
      />
    </div>
  )

  return (
    <div className={props.containerClassName}>
      {!loading && totalPages === 0 ? (
        <div>No records...</div>
      ) : (
        <InfiniteScroll
          dataLength={dataLength}
          next={handleLoadMore}
          hasMore={loading ? false : page < totalPages}
          loader={
            <div>
              <Loading gridSizes={props.gridSizes} />
            </div>
          }
        >
          {renderProductList()}
          {loading && <Loading gridSizes={props.gridSizes} />}
        </InfiniteScroll>
      )}
      {!loading && (
        <>
          {/*<Desktop>*/}
          {/*<NotificationsTour width='400px' />*/}
          {/*</Desktop>*/}
          <MobileAndTablet>
            <NotificationsTour />
          </MobileAndTablet>
          <GlobalHelpersTour />
        </>
      )}

      <SignUpModal scrollTo={props.scrollTo} />
    </div>
  )
}

Products.defaultProps = {
  containerClassName: '',
  page: 1,
  prettyUpImage: true,
  gridSizes: ['col-lg-3', 'col-md-4', 'col-6'],
}

export default Products
