import useAuth from '@hooks/useAuth'
import {ReviewData} from '@interfaces/reviews'
import React, {FC, useEffect, useState, ReactNode, useRef} from "react";
import {Overlay, Popover} from 'react-bootstrap'
import {openSignUpModal} from '@hooks/useSignUpModal'
import './styles.css'

interface DefaultProps {
  review: ReviewData
  userId?: number
  userName?: string
  onUpVote: () => void
  onDownVote: () => void
  renderDownVote?: ReactNode
}

const Vote: FC<DefaultProps> = props => {
  const {review} = props
  const {user} = useAuth()
  const {username, id = -1} = user || {}
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>(null)
  const [show, setShow] = useState<boolean>(true);
  const target = useRef(null);

  useEffect(
    () => {
      if (loading) {
        setLoading(false)
      }
    },
    [review.upvoted, review.downvoted, id]
  )

  const checkAuth = () => {
    if (user) {
      return true
    }

    openSignUpModal()
    return false
  }

  const handleVote = (fn: any) => {
    if (checkAuth()) {
      setLoading(true)

      fn()?.then?.(() => {
        setLoading(false)
      })?.catch?.(e => setError(e.message))
    }
  }

  const handleUpvote = () => handleVote(props.onUpVote)
  const handleDownvote = () => handleVote(props.onDownVote)

  const disabled =
    loading ||
    id === -1 ||
    (props.userId ? id === props.userId : false) ||
    (props.userName ? username === props.userName : false) ||
    (typeof review.isOwner === 'boolean' ? review.isOwner : false)

  const vote = review.upvoted === true ? '1' : review.downvoted === true ? '0' : '-1'

  const renderErrorPopover = (error: string) => {
    return (
      <Overlay show={show} target={target.current} placement="top" containerPadding={20}>
        <Popover id="popover-contained" styleName="popover">
          {error}
          <div styleName="close" onClick={() => setShow(false)}>
            <i className="icon-close-round" />
          </div>
        </Popover>
      </Overlay>
    )
  }

  return (
    <div
      className="flex-center"
      styleName={`vote ${disabled ? 'disabled' : ''}`}
      data-user-vote={vote}
    >
      <div className="tw-mr-4" styleName="vote-up" onClick={handleUpvote}>
        <i className="fa fa-thumbs-up tw-mr-1" />
        {review.upvotesCount}
      </div>

      {props.renderDownVote ?? (
        <div styleName="vote-down" ref={target} onClick={handleDownvote}>
          <i className="fa fa-thumbs-down tw-mr-1" />
          {review.downvotesCount}
        </div>
      )}

      {error && renderErrorPopover(error)}
    </div>
  )
}

export default Vote
