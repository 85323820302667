import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import UserLink from '@components/common/UserLink'

type DefaultProps = {
  anonymous: boolean
  displayName: string
  username: string
}

const ByUser: FC<DefaultProps> = props => (
  <div className="l-h-1 line-max line-max-1">
    <FormattedMessage id="general.by" />
    &nbsp;
    {props.anonymous ? (
      <FormattedMessage id="general.anonymous" />
    ) : (
      <UserLink className="strong" username={props.username}>
        {props.displayName}
      </UserLink>
    )}
  </div>
)

export default ByUser