import PriceWithCurrency from '@components/common/PriceWithCurrency'
import UnstyledList from '@components/common/UnstyledList'
import { totalPrice } from '@utils/product/priceUtils'
import React, { FC } from 'react'
import './styles.css'

const Prices = ({ originalPrice, discountPrice }) => (
  <UnstyledList styleName="price" inline={true} className="strong">
    {discountPrice && <PriceWithCurrency price={totalPrice(discountPrice)} />}
    <PriceWithCurrency price={totalPrice(originalPrice)} />
  </UnstyledList>
)

export default Prices
