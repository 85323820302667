import { WithAuth } from '@components/common/WithAuth'
import useAuth from '@hooks/useAuth'
import React from 'react'
import { NavLink } from 'react-router-dom'
import '../styles.css'

interface UnitProps {
  label: React.ReactNode
  value: string
  authRequired?: boolean
  user: any

  url?: string
}

const Unit: React.SFC<UnitProps> = ({ label, value, ...props }) => {
  const { user } = useAuth()
  const renderInner = (label: React.ReactNode, value: string) => (
    <>
      <div className="fs18 text-truncate">{value}</div>
      <div className="fs12">{label}</div>
    </>
  )

  return (
    <WithAuth onClick={props.authRequired ? props.onClick : null}>
      {onClick => (
        <li styleName="unit" className="fs12 text-truncate text-uppercase strong">
          {!props.target && renderInner(label, value)}

          {props.target && user?.username && (
            <NavLink
              className="black-link"
              to={
                props.target === 'orders'
                  ? '/account/orders'
                  : `/discover/users/${user.username}/${props.target}`
              }
            >
              {renderInner(label, value)}
            </NavLink>
          )}
        </li>
      )}
    </WithAuth>
  )
}

Unit.defaultProps = {
  authRequired: false,
}

export default Unit
