import { Observable, of } from "rxjs";
import ajax from '../api/ajax'
import { pluck } from "rxjs/operators";
import {
  FetchArticlesOpt, FetchCategoriesRespond,
  FetchArticlesRespond, FetchTrendingProductsRespond,
  FetchArticleRespond
} from "@interfaces/blog";

export const fetchBlogCategories = (): Observable<FetchCategoriesRespond> =>
  ajax({
    url: `/blog/categories`,
    method: 'GET',
  }).pipe(pluck('response'))

export const fetchArticles = (opt: FetchArticlesOpt): Observable<FetchArticlesRespond> =>
  ajax({
    url: `/blog/articles`,
    method: 'GET',
    query: { ...opt },
  }).pipe(pluck('response'))

export const fetchTrendingProducts = (): Observable<FetchTrendingProductsRespond> =>
  ajax({
    url: `/blog/trending_products`,
    method: 'GET',
  }).pipe(pluck('response'))

export const fetchArticle = (slug: string): Observable<FetchArticleRespond> =>
  ajax({
    url: `/blog/articles/${slug}`,
    method: 'GET',
  })

