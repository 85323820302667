const list = [
  { id: 16, slug: 'women-tops-dresses'},
  { id: 30, slug: 'men-shirts-coats'},
  { id: 1625, slug: 'women-tops-dresses'},
  { id: 50006843, slug: 'women-shoes'},
  { id: 50011740, slug: 'men-shoes'},
  { id: 50011699, slug: 'women-tops-dresses'},
  { id: 50007068, slug: 'women-shorts-pants-shirts'},
  { id: 50006846, slug: 'women-shoes'},
  { id: 50008165, slug: 'kids-clothes'},
  { id: 162104, slug: 'women-tops-dresses'}
]

const keyWords=[
  '男装', '女装', '童装', '男鞋',
  '女鞋', '童鞋', '内裤', '鞋',
  '袜', '裙', '裤', '手套',
  '帽子', '卫衣', '毛衣', '风衣'
]

export const isInClothingCategoryList = (categories) => list.find(l => categories.map(c => c.id).includes(l.id))
const isInClothingKeyWords = (categories) => keyWords.find(k => categories.map(c => c.titles.zhCn).join(',').includes(k))

export const isClothing = (categories) => {
  if(!categories) return null

  return isInClothingCategoryList(categories) || isInClothingKeyWords(categories)
}
