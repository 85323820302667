import qs from 'qs'
import { Observable } from 'rxjs'
import { map, pluck } from 'rxjs/operators'
import ajax from '../api/ajax'

export const createReviewVote = (reviewId, vote) => {
  return ajax({
    url: `/products/reviews/votes`,
    method: 'POST',
    body: {
      review_id: reviewId,
      upvote: vote,
    },
  }).pipe(pluck('response'))
}

export const updateReviewVote = (reviewId, voteId, vote) => {
  return ajax({
    url: `/products/reviews/${reviewId}/votes/${voteId}`,
    method: 'PUT',
    body: {
      review_id: reviewId,
      upvote: vote,
    },
  }).pipe(pluck('response'))
}

