import { Titles } from './common'
import { Image } from './image'
import { Location } from './product'
import { ShoppingCartType } from './shoppingcart'

export enum Rating {
  BUY = 'buy',
  PASS = 'pass',
}

export interface ReviewData {
  id: number
  productId: string
  userId?: number // only if not anonymous
  rating: Rating
  title: string
  content: string
  anonymous: boolean
  downvotesCount: number
  upvotesCount: number
  boughtOnBaopals: boolean
  pictures: Image[]
  upvoted: boolean
  downvoted: boolean
  createdAt: Date
  isOwner?: boolean
  mostRecent?: boolean
  clothingFit?: string
  sku?: any
}

export interface UserData {
  collectionsCount: number
  affiliateToken: string
  displayName: string
  id: number
  locationCity?: string
  nationality: string
  userName: string
  avatar: Image
}

export interface ProductData {
  banned: boolean
  cover: Image
  deletedFromProvider: boolean
  id: string
  locale: string
  location: Location
  maxPrice: number
  minPrice: number
  multipleOrderQuantity: number
  promotion: { minPrice: number; maxPrice: number }
  sellerId: number
  sold: number
  titles: Titles
}

export interface ReviewForm {
  rating: string
  title: string
  content: string
  anonymous: boolean
  pictures?: Image[]
  deletedPictures?: string[]
  skuId?: string
  clothingFit?: string
}

export interface ActionRespond {
  review: ReviewData
  user?: UserData // unless anonymous
}

export interface MetaData {
  totalCount: number
  totalPages: number
  currentPage: number
  buyCount?: number
}

export enum Sort {
  SHUFFLE = 'shuffle',
  UPVOTED = 'most_upvoted',
}

export enum RenderMethod {
  LIST = 'list',
  GRID = 'grid',
}

export interface FecthOpt {
  sq?: string
  withPictures?: boolean
  boughtOnBaopals?: boolean
  createdAfter?: Date | string
  productId?: number
  username?: string
  target?: ReviewTarget
  sort?: Sort
  page?: number
  perPage?: number
  rating?: Rating
  unavailable?: boolean // if true, show disabled product reviews
  minimumUpvotes?: number
}

export interface FetchRespond {
  meta: MetaData
  data: {
    reviews: ReviewData[]
    users?: UserData[]
    products: ProductData[]
  }
}

export interface SingleRespond {
  review: ReviewData
  user?: UserData
  product?: ProductData
}

export enum ReviewTarget {
  HOME = 'home',
  HOME_SHUFFLE = 'home_shuffle',
  SUPERMARKET = 'supermarket',
  PRODUCT = 'product',
  WHATSHOT = 'hot',
  WHATSHOT_SHUFFLE = 'hot_shuffle',
  DISCOVER = 'discover',
  DISCOVER_SIDEBAR = 'discover_sidebar',
  SEARCH = 'search',
}
