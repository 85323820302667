import React, { Component }     from 'react'
import PropTypes from 'prop-types';
import './styles.css'

const ProgressiveText = ({width, height, display}) => {
  return (
    <span styleName='text-loading' style={{ display, height, width: `${width}%`}}></span>
  )
}

ProgressiveText.defaultProps = {
  width: 100,
  height: 20,
  display: 'inline'
}

ProgressiveText.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  display: PropTypes.string,
}

export default ProgressiveText
