import React from 'react'
import Link from './Link'
import './styles.css'

const CategoryLinks: React.SFC<any> = props => {
  const {links, loading} = props

  return (
    <div
      style={{
        float: "right",
        width: "100%"
      }}
    >
      <div
        className="text-center"
        styleName="wrapper"
      >
        <ul className="list-unstyled tw-mb-0 clearfix center-block">
          {links.map((link, idx) => (
            <React.Fragment key={idx}>
              <Link link={link} loading={loading} />
            </React.Fragment>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default CategoryLinks
