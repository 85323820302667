import useSWRImmutable from 'swr/immutable'
import { fetcher } from './helper'
import { API_URL } from '@constants/'
import { DEFAULT_HTTP_HEADERS } from '@constants/'

export const useTrendingKeywords = () => {
  const { data, error } = useSWRImmutable([`${API_URL}/content/links/trending`, {
    headers: DEFAULT_HTTP_HEADERS
  }], fetcher, { loadingTimeout: 3000, shouldRetryOnError: false, })

  const keywords = (data?.data?.links ?? []).map((link) => ({ ...link, title: link.searchSuggestion || link.title }))

  return { keywords, loading: !error && !data }
}
