import { reviewsCounterAdd } from '@hooks/useReviews'
import React, { useEffect, useState } from 'react'

export const useTotalProductReviews = (productId: number, initialCount: number) => {
  const [totalReviews, setTotalReviews] = useState(initialCount)

  useEffect(() => {
    const sub = reviewsCounterAdd.subscribe(
      res => (res.add ? setTotalReviews(totalReviews + 1) : null)
    )

    return () => sub.unsubscribe()
  }, [])

  useEffect(
    () => {
      setTotalReviews(initialCount)
    },
    [productId]
  )

  return totalReviews
}
