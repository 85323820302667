import React from 'react'
import {ReviewForm} from '@interfaces/reviews'
import './styles.css'

interface ClothingFitBarProps {
  onChange?: (form: ReviewForm) => void
  clothingFit: string
  className?: string
  clickable?: boolean
  error?: string
}

const FIT_SIZES = [
  {value: 'too_small', label: 'Too Small'},
  {value: 'small', label: ''},
  {value: 'good', label: 'Just Right'},
  {value: 'large', label: ''},
  {value: 'too_large', label: 'Too Large'},
]


const ClothingFitBar: React.SFC<ClothingFitBarProps> = ({
  onChange, clothingFit, className, clickable, error
}) => {
  return (
    <>
      <div className='tw-mt-4 tw-mb-2 fs12'>The Fit</div>

      <div styleName="bar" className="tw-mb-2">
        {FIT_SIZES.map((f, index) => (
          <div
            styleName="circle"
            data-active={clothingFit === f.value}
            data-clickable={clickable}
            key={index}
            {...(clickable && {
              onClick: () => onChange({clothingFit: f.value})
            })}
          />
        ))}
      </div>

      <div className={`${className} d-flex justify-content-between`} style={{maxWidth: '498px'}}>
        {FIT_SIZES.map((f, index) => (
          <div data-active={clothingFit === f.value} key={index} className="fs12 text-grey" styleName="label">
            {f.label}
          </div>
        ))}
      </div>

      {error && <div className="text-danger">{error}</div>}
    </>
  )
}

export default ClothingFitBar
