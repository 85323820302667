import { useSuggestions } from '@hooks/useSuggestions'
import { showPath } from '@routing/department/linkRoutes'
import React, { FC } from 'react'
import { Link } from 'react-router-dom'

interface Props {
  keyword: string
}

const Suggestions: FC<Props> = props => {
  const { data: suggestions, isLoading: loading } = useSuggestions(props.keyword)

  if (loading || suggestions.length == 0) {
    return null
  }

  return (
    <div className="tw-mt-4">
      <div>Baopals Suggested Categories:</div>

      <div className="bold">
        {(suggestions || []).map((suggestion, index) => (
          <Link
            key={index}
            to={showPath(suggestion)}
            className={`black-link bold ${index !== 0 && 'tw-ml-2'}`}
          >
            {suggestion.title}
            {index !== suggestions.length - 1 && ','}
          </Link>
        ))}
      </div>
    </div>
  )
}

export default Suggestions
