import { usePaymentConfig } from '@hooks/usePaymentConfig'
import { FC, ReactNode, useEffect } from 'react'
import * as PaymentConfigActionCreator from '../actions/paymentConfig'

interface injectCurrencyProps {
  rate: number
  currency: string
  actions: typeof PaymentConfigActionCreator
}

interface CurrencyProps {
  children: (props: injectCurrencyProps) => ReactNode
}

const Currency: FC<CurrencyProps & injectCurrencyProps> = props => {
  const { children: fn } = props

  const state = usePaymentConfig()

  if (typeof fn === 'function') {
    return <> {fn(state)} </>
  }

  return null
}

export default Currency
