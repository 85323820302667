import React from 'react'
import Gallery from 'react-image-lightbox'

type Prop = {
  previewWidth: number
  previewHeight: number
  images: string[]
  galleryImages?: string[]
}

const modalStyle = {overlay: {zIndex: 3000}}

const LightBox = ({images, ...props}: Prop) => {
  const [open, setOpen] = React.useState(false)
  const [photoIndex, setPhotoIndex] = React.useState(0)
  const galleryImages = props.galleryImages || images
  return (
    <div>
      {(images || []).map((p, index) => (
        <img
          className="tw-mr-2 tw-mb-2 bd-light obj-fit-cover"
          key={index}
          src={p}
          width={props.previewWidth}
          height={props.previewHeight}
          onClick={() => {
            setOpen(true)
            setPhotoIndex(index)
          }}
        />
      ))}

      {open && (
        <Gallery
          mainSrc={galleryImages[photoIndex]}
          nextSrc={galleryImages[(photoIndex + 1) % galleryImages.length]}
          prevSrc={galleryImages[(photoIndex + galleryImages.length - 1) % galleryImages.length]}
          onCloseRequest={() => setOpen(false)}
          onMovePrevRequest={() => setPhotoIndex((photoIndex + galleryImages.length - 1) % galleryImages.length)}
          onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % galleryImages.length)}
          reactModalStyle={modalStyle}
        />
      )}
    </div>
  )
}

export default LightBox
