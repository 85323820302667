import React from 'react'
import './styles.css'

const EmptySkuText = () => (
  <div styleName='wrapper'>
    {`This product doesn't require you to choose any options. You're all set!`}
  </div>
)

export default EmptySkuText
