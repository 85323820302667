import ProductLink from '@components/common/ProductLink'
import {Platform} from '@interfaces/platform'
import React from 'react'
import {FormattedMessage} from 'react-intl'

interface ViewMoreProps {
  id: number
  platform: Platform
}

const ViewMore: React.SFC<ViewMoreProps> = ({id, platform}) => (
  <div className="hidden product-modal-view-more-link">
    <ProductLink id={id} platform={platform} className="bold black-link tw-mt-6 d-block">
      <FormattedMessage id="view.product.details" defaultMessage="VIEW PRODUCT DETAILS" />
      <i className="icon-arrow-right fs10" />
    </ProductLink>
  </div>
)

export default ViewMore
