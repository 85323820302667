import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter, matchPath, Link } from 'react-router-dom'
import { Dropdown, Button, Modal, Nav, NavDropdown, MenuItem } from 'react-bootstrap'
import './styles.css'

class AnimatedDropdown extends PureComponent {
  render() {
    return (
      <NavDropdown
        activeHref="/discover"
        id={this.props.id}
        title={this.props.title}
        noCaret
        styleName="dropdown"
        className={this.props.className}
      >
        {this.props.renderMenuItem ??
          this.props.menuItems.map((menu, index) => (
            <MenuItem
              key={index}
              componentClass={menu.componentClass}
              href={menu.link}
              to={menu.link}
              disabled={menu.disabled === true}
              active={
                !!matchPath(this.props.history.location.pathname, { path: menu.path || menu.link }) ||
                menu.active
              }
              onSelect={(_, e) => this.props.onSelect && this.props.onSelect(index, e)}
              {...(this.props.target ? { target: this.props.target } : {})}
            >
              {menu.renderContent}
            </MenuItem>
          ))}
      </NavDropdown>
    )
  }
}

AnimatedDropdown.defaultProps = {
  className: '',
}

AnimatedDropdown.propTypes = {
  id: PropTypes.string.isRequired,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string.isRequired,
      renderContent: PropTypes.node.isRequired,
    })
  ).isRequired,
}

export default withRouter(AnimatedDropdown)
