import React from 'react'

type HeadProps = {
  text: string | React.ReactNode
  onShuffle: () => void
  className?: string
}

const MobileShuffleHead: React.SFC<HeadProps> = props => (
  <div className={`flex-center-between tw-pb-2 tw-mb-6 bd-b-dark ${props.className}`}>
    <div className="fs16 upper bold l-h-1">{props.text}</div>
    <div className="icon-shuffle-loadMore fs18" onClick={props.onShuffle} />
  </div>
)

MobileShuffleHead.defaultProps = {
  className: 'tw-mt-12',
}

export default MobileShuffleHead
