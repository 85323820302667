import {useAffiliateLink} from '@hooks/useAffiliate'
import {useRef, useState} from 'react'
import {FormattedMessage} from 'react-intl'
import '../styles.css'

const Input = ({value, hideText, shortenValue}) => {
  const [copy, setCopy] = useState(false)
  const inputRef = useRef(null)

  const copyText = () => {
    inputRef.current.select()
    document.execCommand('copy')
  }

  const handleFocus = () => {
    copyText()
    setCopy(true)
  }

  const handleBlur = () => {
    setCopy(false)
  }

  const link = useAffiliateLink(value)

  return (
    <div>
      <div className="d-flex justify-content-between tw-mb-4">
        {!hideText && <FormattedMessage id="product_page.share_modal_mobile.share_url" />}
        <div className="bp-orange-text" styleName="copy" data-copy={copy}>
          <FormattedMessage id="product_page.share_modal_mobile.copied" />
        </div>
      </div>
      <input
        readOnly={true}
        id="auto-copy-input"
        type="text"
        value={shortenValue ? link.replace('https://', '') : link}
        className="tw-w-full tw-py-2 bd-light"
        onFocus={handleFocus}
        onBlur={handleBlur}
        ref={inputRef}
      />
    </div>
  )
}

Input.defaultProps = {
  hideText: false,
  shortenValue: false,
}

export default Input
