import * as ActionCreator from '@actions/wallet'
import { forkJoin, of } from 'rxjs'
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { Epic } from './index'

export const fetchMyWallet: Epic = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(ActionCreator.fetchMyWallet)),
    switchMap(({ payload }) =>
      forkJoin(
        api.fetchMyWallet(payload),
        payload.includePayments ? api.fetchMyWalletPayments(payload) : of(undefined),
        payload.includeWithdraw ? api.fetchMyWithdrawHistory(payload) : of(undefined)
      ).pipe(
        map(([wallet, payments, withdraw]) => ({
          ...wallet,
          ...payments,
          ...withdraw,
        })),
        map(ActionCreator.fetchMyWalletSuccess),
        catchError(e => of(ActionCreator.fetchMyWalletFailure(e)))
      )
    )
  )

export const refundPayment: Epic = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(ActionCreator.refundPayment)),
    switchMap(({ payload }) =>
      api.refundPayment(payload.paymentId, payload.amount).pipe(
        map(ActionCreator.refundPaymentSuccess),
        catchError(e => of(ActionCreator.refundPaymentFailure(e)))
      )
    )
  )
