import React from 'react'
import { useMyCountry } from '@hooks/useAuth'
import './styles.css'

const CNYNotice = () => {
  const { isInternational } = useMyCountry()

  return (
    <div styleName="wrapper">
      {isInternational ? (
        <div>
          <p>
            Dear Global Shoppers,
          </p>
          <p>
            The Year of the Rabbit is nearly upon us! As the whole nation of China gets ready to usher in the New Year, the majority of services in China will also be suspended. This includes deliveries and warehousing within China. Please make note of the following warehouse schedules:
          </p>
          <ul>
            <li>
              Hong Kong/Macau/Malaysia/Singapore/Thailand/Vietnam - Warehouse closed from January 20th through January 25th
            </li>
            <li>
              USA/Canada/Europe/Australia - Warehouse closed from January 17th through January 28th
            </li>
          </ul>
          <p>
            You may still ship items to your global warehouse and complete warehouse checkouts during this time. However, no items will be weighed and no parcels will be packaged until the warehouse re-opens. Any orders placed during the period of closure will be processed as soon as the warehouse re-opens.
          </p>
        </div>
      ) : (
        <div>
          <p>
            Dear Baopals Shoppers,
          </p>
          <p>
            The Year of the Rabbit is nearly upon us! As the whole nation of China gets ready to usher in the New Year, the majority of services in China will also be suspended. This includes many delivery providers and sellers within China. As Baopals is a portal to all of Taobao, Tmall and JD we do not know for certain which sellers will be closed for business or which delivery providers they may be using. Delays for Taobao and Tmall products are to be expected from January 15th through February 1st. Express products are controlled by the JD platform and will be operating with minimal delays throughout the holidays. For faster shipping we recommend searching for Express products during this time.
          </p>
          <p>
            Thank you for your support and understanding. On behalf of everyone here at Baopals we wish you a very Happy Year of the Rabbit!
          </p>
        </div>
      )}

    </div>
)}

export default CNYNotice
