import { FC, useState } from 'react'
import AddingCollectionPopup from '../AddingCollectionPopup'
import Btn from './Btn'

interface AddCollectionBtnProps {
  id: number
  pic: string

  openStyle: 'modal' | 'menu'

  withLabel: boolean

  menuClassName?: string
}

const AddCollectionBtn: FC<AddCollectionBtnProps> = ({ openStyle, ...props }) => {
  const [open, setOpen] = useState<boolean>(false)
  const [addedSlug, setAddedSlug] = useState<string>(null)

  const handleAdded = payload => {
    setAddedSlug(payload.slug)
    setOpen(false)
  }

  const rest = {
    open,
    item: {
      id: props.id,
      pic: props.pic,
      platform: props.platform,
    },
    onAdded: handleAdded,
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Btn onClick={() => setOpen(true)} addedSlug={addedSlug} withLabel={props.withLabel} />
      {open && (
        <AddingCollectionPopup
          openStyle={openStyle}
          {...rest}
          onClose={handleClose}
          menuClassName={props.menuClassName}
        />
      )}
    </>
  )
}

AddCollectionBtn.defaultProps = {
  menuClassName: '',
  withLabel: true,
}

export default AddCollectionBtn
