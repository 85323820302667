import React from 'react'
import { Redirect, Route, Switch } from "react-router";
import AsyncLoader from '@components/common/AsyncLoader'

const LoadAbout = AsyncLoader({ loader: () => import('./About') })
const LoadHome = AsyncLoader({ loader: () => import('./Home') })
const LoadCompany = AsyncLoader({ loader: () => import('./Company') })
const LoadStore = AsyncLoader({ loader: () => import('./Store') })
const LoadOther = AsyncLoader({ loader: () => import('./Other') })
const LoadForm = AsyncLoader({ loader: () => import('./Form') })
const LoadHealth = AsyncLoader({ loader: () => import('./Health') })
const LoadEmployment = AsyncLoader({ loader: () => import('./EmploymentServices') })

const Routes = () => (
  <Switch>
    <Route exact path={aboutPath} component={LoadAbout} />
    <Redirect from={`${indexPath}/*`} to={aboutPath} />
    <Redirect from={indexPath} to={aboutPath} />

    <Route exact path={companyPath} component={LoadCompany} />
    <Route exact path={storePath} component={LoadStore} />
    <Route exact path={healthPath} component={LoadHealth} />
    <Route exact path={otherPath} component={LoadOther} />
    <Route exact path={employmentPath} component={LoadEmployment} />

    {/* mobile form */}
    <Route exact path={formPath} component={LoadForm} />

    <Route path={indexPath} component={LoadHome} />
  </Switch>
)

export default Routes

export const indexPath = '/business-services'
export const companyPath = `${indexPath}/company-setup`
export const storePath = `${indexPath}/store-setup`
export const otherPath = `${indexPath}/other-services`
export const aboutPath = `${indexPath}/about-us`
export const formPath = `${indexPath}/form`
export const healthPath = `${indexPath}/health-insurance`
export const employmentPath = `${indexPath}/employment-services`
