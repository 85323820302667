import { useResponsive, Viewport } from '@hooks/useResponsive'
import React from 'react'

import FollowUs from './FollowUs'
import HelpYou from './HelpYou'
import KnowUs from './KnowUs'
import './styles.css'

const Helps = () => {
  const isSmall = useResponsive([Viewport.SM]).some(v => v)

  return (
    <div className="bg-lightish" styleName="help">
      <div className={isSmall ? 'container-fluid' : 'container'}>
        <div styleName="wrapper">
          <KnowUs />
          <FollowUs />
          <HelpYou />
        </div>
      </div>
    </div>
  )
}

export default Helps
