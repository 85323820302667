import ProductPropertiesCollection from '@components/product/v2/PropertiesCollection'
import React from 'react'
import {FormattedMessage} from 'react-intl'

interface ProductSpecsProps {
  sold?: number
  properties: any[]
}

const ProductSpecs: React.FC<ProductSpecsProps> = ({ sold, properties }) => {
  return (
    <div className="text-center">
      {sold > 0 && (
        <>
          <div className="text-uppercase">
            <FormattedMessage id="product_page.overview_quantity_sold" />
          </div>
          <div className="tw-mt-6 bold text-orange fs20 tw-mb-12">
            {`${sold.toLocaleString()}+`}
          </div>
        </>
      )}

      {properties.length > 0 && (
        <div className="bd-light p-4 d-inline-block text-left text-wrap">
          <ProductPropertiesCollection properties={properties} />
        </div>
      )}
    </div>
  )
}

ProductSpecs.defaultProps = {
  sold: 0,
  properties: [],
}

export default ProductSpecs
