import { NavLink } from 'react-router-dom'
import UserWalletBalance from '@components/common/UserWalletBalance'
import qs from 'qs'
import ROUTING from '@constants/url'

export const Option = ({ link, label, user }) => {
  const renderLabel = () => (
    <NavLink
      className="v4-col-6 tw-mb-2 link-black"
      to={link}
      activeClassName="link-active bold"
      isActive={(match, location) => {
        const query = qs.parse(location.search, { ignoreQueryPrefix: true })
        switch(label) {
          case 'Orders':
            return !query.parcels_only && match && location.pathname !== ROUTING.ACCOUNT_WAREHOUSE
          case 'Parcels':
            return +query.parcels_only === 1
          case 'Profile':
            return query.target === 'profile' && query.tab === 'sellers'
          case 'Collections':
            return !query.tab && match
          case 'Sellers':
            return query.tab === 'sellers' && !query.target
          default:
            return match
        }
      }}
    >
     {label}
    </NavLink>
  )

  switch(label) {
    case 'WareHouse':
      return (
        !!user.warehouseItems ? renderLabel() : null
      )
    case 'Parcels':
      return (
        !!user.parcelsCount ? renderLabel() : null
      )
    case 'Wallet':
      return (
        <UserWalletBalance>
          {({balance, renderPrice, enable}) =>
            enable && (
              <NavLink
                className="v4-col-6 tw-mb-2 link-black"
                to={link}
                activeClassName="link-active bold"
              >
                {label} {'('}{renderPrice}{')'}
              </NavLink>
            )
          }
        </UserWalletBalance>
      )
    default:
      return renderLabel()
  }
}
