import LightBox from '@components/common/LightBox'
import Markdown from '@components/common/Markdown'
import { cloudObjectToURL } from '@utils/'
import { formatDate } from '@utils/dateFormat'
import React from 'react'
import { Media } from 'react-bootstrap'
import './styles.css'

const Answer = props => {
  const images = props.qaImages.map(i => cloudObjectToURL(i))

  return (
    <Media className="qa-wrapper__question--reply">
      <Media.Left>
        <div />
      </Media.Left>

      <Media.Body>
        <div className="qa-wrapper__question__header">
          <a href="/discover/users/Baopals/wishlists" className="bold">
            BAOPALS
          </a>{' '}
          | {formatDate(props.answeredAt)}
        </div>
        <Markdown content={props.answer} />

        <div styleName="qa-images">
          <LightBox previewWidth={150} previewHeight={150} images={images} />
        </div>
      </Media.Body>
    </Media>
  )
}

export default Answer
