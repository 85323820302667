import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { Rating } from '@interfaces/reviews'
import { DefaultProps } from './index'
import { Platform } from '@interfaces/platform'
import './styles.css'

const StatusFilter: FC<DefaultProps> = props => {
  const renderText = (status?: Rating) => {
    switch (status) {
      case Rating.BUY:
        return (
          <>
            <FormattedMessage id="review_form.sort_buy" />
            &nbsp;(
            {props.positiveCount})
          </>
        )

      case Rating.PASS:
        return (
          <>
            <FormattedMessage id="review_form.sort_pass" />
            &nbsp;(
            {props.negativeCount})
          </>
        )

      default:
        return (
          <>
            {props.target === 'taobaoReviews' ? (
              <span>{props.platform === Platform.TAOBAO ? "ALL TAOBAO REVIEWS" : "ALL TMALL REVIEWS"}</span>
              ) : (
              <>
                <span className="visible-xs">
                  <FormattedMessage
                    id="review_form.all_reviews"
                    defaultMessage="ALL REVIEWS"
                  />
                </span>
                <span className="hidden-xs">
                  <FormattedMessage id="review_form.sort_all-baopals" />
                </span>
              </>
            )}

          </>
        )
    }
  }

  const getStatus = (status?: Rating) => (
    <div
      className="fs12 black-link"
      onClick={() => props.onFilterChange(
        status == props.sq.status ? {} : { status }
      )}
      styleName={props.sq.status == status ? 'active' : ''}
    >
      {renderText(status)}
    </div>
  )


  return (
    <div className="flex-center">
      {getStatus()}
      {props.target !== 'taobaoReviews' && (
        <>
          <div styleName="dot" />
          {getStatus(Rating.BUY)}
          <div styleName="dot" />
          {getStatus(Rating.PASS)}
        </>
      )}
    </div>
  )
}

export default StatusFilter
