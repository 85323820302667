import ProductLink from '@components/common/ProductLink'
import Button from '@components/review/ReviewButton'
import { Platform } from '@interfaces/platform'
import { ReviewData, ReviewForm } from '@interfaces/reviews'
import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import '../styles.css'

interface Props {
  title: string

  showCompleteDetails?: boolean
  showTitleRows?: number

  isTitleLink?: boolean
  productId?: number

  showEdit?: boolean
  review?: ReviewData

  enableAffiliate?: boolean
  reviewId: number
  affiliateToken?: string
  handleUpdate?: (id: number, review: ReviewForm) => void

  platform: Platform
  anonymous: boolean
}

const Title: FC<Props> = props => {
  return (
    <div className="flex-center-between tw-mb-2">
      <div
        className={`
          text-capitalize bold ${props.showCompleteDetails ? '' : `line-max line-max-${props.showTitleRows}`
          }`}
        styleName="break"
      >
        {!props.isTitleLink ? (
          props.title
        ) : (
          <ProductLink
            id={props.productId}
            review={true}
            platform={props.platform}
            title=""
            {...props.enableAffiliate &&
            props.affiliateToken && {
              affiliateVia: 'product_review',
              affiliateToken: props.affiliateToken,
              shareableId: props.reviewId,
            }}
          >
            {props.title}
          </ProductLink>
        )}
      </div>

      {props.showEdit && (
        <div className="d-flex align-items-center">
          <Button review={props.review} handleUpdate={props.handleUpdate}>
            <div className="black-link fs12 tw-ml-4">
              <i className="icon-edit mr-2 fs10" />
              <FormattedMessage id="review.edit" defaultMessage="EDIT" />
            </div>
          </Button>

          {props.anonymous && <i className="fs16 icon-private-collection text-grey ml-3 d-block" />}
        </div>
      )}
    </div>
  )
}

export default Title
