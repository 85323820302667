import { Channel, Payment } from '@interfaces/payment'
import { Observable } from 'rxjs'
import { map, pluck, tap } from 'rxjs/operators'
import ajax from '../api/ajax'

export const fetchPaymentConfig = (): Observable<{ rates: number; channels: Channel[] }> =>
  ajax({
    url: '/payments/config',
  }).pipe(pluck('response', 'data'))

export const fetchPaymentDetails = (paymentId: string): Observable<Payment> =>
  ajax({
    url: `/payments/${paymentId}`,
  }).pipe(pluck('response', 'data', 'payment'))

export const fetchMiniAppScheme = (query: string): Observable<Payment> =>
  ajax({
    url: `/payments/miniapp_scheme`,
    method: 'POST',
    body: {
      query,
      env_version: 'release'
    }
  }).pipe(pluck('response'))
