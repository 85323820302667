import React, { Component } from 'react'
import { matchPath, withRouter, Route, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Row, Dropdown, Navbar } from 'react-bootstrap'
import Nav from './Nav'

import Cart from './header/Cart'
import LoginWidget from '../auth/LoginWidget'
import DepartmentMenu from './header/DepartmentMenu'
import { Desktop, MobileAndTablet } from '../common/Responsive'

import { fetchAll } from '../../actions/departmentActions'

import { FormattedMessage } from 'react-intl'

import CurrencySwitcher from '@components/common/CurrencySwitcher'
import DiscoverDropdown from './header/NavDropdowns/DiscoverDropdown'
import NotificationsDropDown from './header/NavDropdowns/NotificationsDropDown'
import ResponsiveNavBar, { DesktopLogo } from './ResponsiveNavBar'
import { isIpad } from '../../utils'

import FestivalLink from '@components/layout/FestivalLink'
import ROUTING from '@constants/url'
import ChinaOnly from '@components/common/ChinaOnly'
import DiscountBanner from './DiscountBanner'
import { isGreaterChina } from '@utils/i18n'
import GetAppBanner from './GetAppBanner'
import './styles.css'

class CustomToggle extends Component {
  constructor(props, context) {
    super(props, context)
    this.handleClick = this.handleClick.bind(this)
    this.handleOutsideClick = this.handleOutsideClick.bind(this)
  }

  handleOutsideClick(e) {
    if (this.node.contains(e.target)) return
    this.handleClick(e)
  }

  handleClick(e) {
    e.preventDefault()

    if (this.props.isDesktop) {
      if (!this.node.parentElement.classList.contains('open')) {
        document.addEventListener('click', this.handleOutsideClick, false)
      } else {
        document.removeEventListener('click', this.handleOutsideClick, false)
      }
    }

    this.props.onClick(e)
  }

  render() {
    return (
      <a
        href="javascript:void(0);"
        onClick={this.handleClick}
        id="department-nav-dropdown"
        className={this.props.className}
        ref={node => {
          this.node = node
        }}
      >
        {this.props.children}
      </a>
    )
  }
}

// header class, used to control the entire header portion of the website, and render sub-components
class Header extends Component {
  constructor(props) {
    super(props)
    this.handleScroll = this.handleScroll.bind(this)
    this.state = {
      scrolled: false,
      isOpen: false,
      width: props.width,
    }

    this.handleClick = this.handleClick.bind(this)
  }

  componentDidMount() {
    const params = { with_categories: true }

    this.setState({ width: window.innerWidth }, () => {
      this.props.fetchDepartments(this.state.width < 992 && params)
    })

    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll(e) {
    const scroll = window.scrollY
    let scrolled = this.state.scrolled
    let discover = false //matchPath(location.pathname, { path: '/discover' })
    let width = this.state.width

    if (scroll > 72 && scrolled === false && !discover) {
      this.setState({ scrolled: true }, () => document.body.classList.add('navbar-scrolled'))
    } else if (scroll <= 72 && scrolled === true && !discover) {
      this.setState({ scrolled: false }, () => document.body.classList.remove('navbar-scrolled'))
    }

    if (scroll > 260 && scrolled === false && discover && width > 980) {
      this.setState({ scrolled: true }, () => document.body.classList.add('navbar-scrolled'))
    } else if (scroll <= 260 && scrolled === true && discover && width > 980) {
      this.setState({ scrolled: false }, () => document.body.classList.remove('navbar-scrolled'))
    }

    // if (scroll > 150 && scrolled === false && discover && width <= 980) {
    //   this.setState({scrolled: true}, () => document.body.classList.add('navbar-scrolled'));
    // } else if (scroll <= 150 && scrolled === true && discover && width <= 980) {
    //   this.setState({scrolled: false}, () => document.body.classList.remove('navbar-scrolled'));
    // }

    // this will keep account sidebar sticky
    let account = matchPath(location.pathname, { path: '/account/:subpath(address|settings)' })
    if (account && width > 992) {
      document.body.classList.add('account')
    } else {
      document.body.classList.remove('account')
    }
  }

  handleClick() {
    document.title = 'The Best Way to Shop in China | Baopals'
  }

  renderDeptLink(isDesktop) {
    const { departments, sections } = this.props
    const { isOpen } = this.state
    return (
      <Dropdown id="dropdown-custom-menu-deps">
        <CustomToggle
          bsRole="toggle"
          onClick={() => this.setState({ isOpen: !isOpen })}
          className="link-black"
          isDesktop={isDesktop}
        >
          <span className="text-uppercase">
            <FormattedMessage id="homepage.departments" />
          </span>
          <i className="icon-arrow-right tw-ml-2" aria-hidden="true" />
        </CustomToggle>
        <DepartmentMenu
          bsRole="menu"
          departments={departments}
          sections={sections}
          isOpen={isOpen}
          isDesktop={isDesktop}
        />
      </Dropdown>
    )
  }

  renderNavbarLink(type) {
    const isDepartment = type == 'department'
    const isFestival = type === 'festival'

    const festivalLink = '/departments/christmas'
    const linkStyleName = isFestival ? 'link-festival' : 'link-home'

    return (
      <div className="navbar-primary__departments pull-left">
        {isDepartment ? (
          isIpad() ? (
            this.renderDeptLink(false)
          ) : (
            this.renderDeptLink(true)
          )
        ) : (
          <Link
            to={festivalLink}
            className="bold text-uppercase"
            styleName={`link ${linkStyleName}`}
          >
            <FormattedMessage id={type} />
          </Link>
        )}
      </div>
    )
  }

  getModuleName() {
    const { pathname } = this.props

    if (!!matchPath(pathname, '/:platform(jd|tb)?/products/:id')) return 'product'

    return 'unknown'
  }

  render() {
    const defaultLang = this.props.i18n.locale

    return (
      <header id="header" styleName="header" data-module={this.getModuleName()}>
        <DiscountBanner />
        {/*<MobileAndTablet>*/}
          {/*<GetAppBanner />*/}
        {/*</MobileAndTablet>*/}
        <Navbar className="navbar navbar-primary navbar-baopals">
          <Row>
            <Desktop>
              {this.renderNavbarLink('department')}

              <div className="navbar-primary__departments pull-left" styleName="dropdown-discover">
                <DiscoverDropdown />
              </div>

              <div className="navbar-primary__departments pull-left">
                <FestivalLink styleName="link" />
              </div>

              {/*<ChinaOnly isChina={isGreaterChina}>*/}
                {/*<div className="navbar-primary__departments pull-left">*/}
                  {/*<FestivalLink styleName="link" />*/}
                {/*</div>*/}
              {/*</ChinaOnly>*/}
            </Desktop>

            <ResponsiveNavBar />

            <Desktop>
              <Cart />
              <NotificationsDropDown />
              <LoginWidget />
              <CurrencySwitcher />
            </Desktop>
          </Row>
        </Navbar>
        {/*<Desktop>*/}
          {/*<Nav scrolled={this.state.scrolled} lang={defaultLang} />*/}
        {/*</Desktop>*/}
      </header>
    )
  }
}

const mapStateToProps = ({ user, departments, intl }) => {
  return {
    user,
    ...departments,
    i18n: intl,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchDepartments: params => {
      dispatch(fetchAll(params))
    },
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Header)
)
