import axios from 'axios'
import { readAuthTokenToLocalStorage } from '@utils/auth'
import { API_URL } from '../constants'

const ajax = axios.create({
  baseURL: API_URL,
  headers: {
    Accept: '*/*',
    'X-Key-Inflection': 'camel',
    'Key-Inflection': 'camel',
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  },
})

ajax.interceptors.request.use(function(config) {
  const tk = readAuthTokenToLocalStorage()
  if (tk) {
    config.headers.Authorization = `Bearer ${tk}`
  }

  return config
})

export default ajax
