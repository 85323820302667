import PreventBodyScroll from '@components/common/PreventBodyScroll'
import { isWeChat } from '@utils/'
import React, { useEffect } from 'react'
import { slide as SlideMenu } from 'react-burger-menu'
import { createPortal } from 'react-dom'
import './styles.css'

const clearClassnames = () => {
  document.body.classList.remove('overflowHidden')
  document.documentElement.classList.remove('overflowHidden')
}

interface AwesomeBurgerMenuProps {
  id: string
  open: boolean
  onMenuStateChange: () => void
  className?: string

  position?: 'left' | 'right'
  width: string | number
  overNavbar?: boolean
  overlayClassName?: string
}

const AwesomeBurgerMenu: React.FC<AwesomeBurgerMenuProps> = ({
  open,
  onMenuStateChange,
  ...props
}) => {
  useEffect(
    () => {
      if (open) {
        document.body.classList.add('overflowHidden')
        document.documentElement.classList.add('overflowHidden')
      } else {
        clearClassnames()
      }

      return clearClassnames
    },
    [open]
  )

  return createPortal(
    <>
      <SlideMenu
        id={props.id}
        left={props.position === 'left'}
        right={props.position === 'right'}
        className={`burger-menu ${open ? 'open' : ''} ${props.overNavbar ? 'override-navbar' : ''
          } ${props.className} ${isWeChat() ? '' : 'with-app-banner'} `}
        styleName="burger-menu"
        customBurgerIcon={false}
        overlayClassName={`burger-menu-overlay ${[props.overlayClassName]}`}
        width={props.width}
        customCrossIcon={false}
        isOpen={open}
        onStateChange={onMenuStateChange}
      >
        {props.children}
      </SlideMenu>
    </>,
    document.querySelector('#burger-menu-container')
  )
}

AwesomeBurgerMenu.defaultProps = {
  open: false,
  position: 'left',
  overlayClassName: '',
  width: '70%',
  className: '',
  overNavbar: false,
}

export default AwesomeBurgerMenu
