import {useLocation} from '@hooks/useRouter'
import React, {useEffect, useState} from 'react'
import MobileNavItem from '../MobileNavItem'
import Menu from './Menu'

const MobileMenuToggler = () => {
  const [open, setOpen] = useState(false)

  const handleMenu = ({isOpen}: {isOpen: boolean}) => setOpen(isOpen)

  const {location} = useLocation()

  useEffect(
    () => {
      if (open) {
        document.body.classList.add('tw-max-h-screen', 'mobile-menu-open')
      } else {
        document.body.classList.remove('tw-max-h-screen', 'mobile-menu-open')
      }
    },
    [open]
  )

  useEffect(
    () => {
      if (open) {
        setOpen(false)
      }
    },
    [location.key]
  )

  return (
    <>
      <MobileNavItem
        active={open}
        icon="mobile_menu"
        disabled={true}
        onClick={() => setOpen(!open)}
      />
      <Menu isOpen={open} onMenuStateChange={handleMenu} />
    </>
  )
}

export default MobileMenuToggler
