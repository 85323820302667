import * as ACTION from '@actions/warehouse'
import { groupBy } from 'lodash'
import { LOCATION_CHANGE } from 'connected-react-router'
import * as ORDERS_ACTION from '@actions/orders'
import qs from 'qs'
import { createReducer } from 'typesafe-actions'

export interface WarehouseItemsState {
  readonly items: any[]
  readonly selected: number[]
  readonly activeCountryAndMethod: string
  readonly formula: any
  readonly dimensions: any[]
  readonly warehouseFees: any[]
  readonly shippingLines: any[]
  readonly bulkDiscount: any[]
  readonly extraFees: any
}
const initialState: WarehouseItemsState = {
  items: [],
  selected: [],
  dimensions: [],
  warehouseFees: [],
  shippingLines: [],
  packageingFormula: {},
  activeCountryAndMethod: null,
  formula: {},
  pendingItems: [],
  bulkDiscount: [],
  warehouseFormulas: [],
  largePackage: {},
  extraFees: {},
  proDiscountPercent: 0
}

export const warehouseItems = createReducer(initialState)
  .handleAction(LOCATION_CHANGE, (state, action) => {
    const query = qs.parse(action.payload.location.search, { ignoreQueryPrefix: true })

    if (query.country) {
      return {
        ...state,
        activeCountryAndMethod: query.country,
        selected: [], //state.items.filter(i => i.countryCode === query.country).map(i => i.id),
      }
    }

    return state
  })
  .handleAction(ACTION.selectItem, (state, action) => {
    return { ...state, selected: state.selected.concat(action.payload) }
  })
  .handleAction(ACTION.deselectItem, (state, action) => ({
    ...state,
    selected: state.selected.filter(v => !action.payload.includes(v)),
  }))
  .handleAction(ACTION.fetchWarehouseItemsSuccess, (state, action) => {
    const items = action.payload.items
    const pendingItems = action.payload.pendingItems

    const groupItems = groupBy(items.concat(pendingItems), 'countryWithMethod')

    const [activeCountryAndMethod] = Object.entries(groupItems).find(
      ([_, arr]) => arr.length === Math.max(...Object.values(groupItems).map(i => i.length))
    ) ?? []

    return {
      ...state,
      items,
      activeCountryAndMethod: state.activeCountryAndMethod || activeCountryAndMethod,
      formula: action.payload.formula,
      pendingItems: action.payload.pendingItems,
      dimensions: action.payload.dimensions,
      warehouseFees: action.payload.warehouseFees || [],
      warehouseFormulas: action.payload.warehouseFormulas || [],
      shippingLines: action.payload.shippingLines || [],
      packageingFormula: action.payload.packageingFormula || {},
      selected: items.filter(i => i.countryWithMethod === activeCountryAndMethod).map(i => i.id).filter((i, idx) => idx <=19),
      bulkDiscount: action.payload.bulkDiscount,
      largePackage: action.payload.largePackage,
      extraFees: action.payload.extraFees || {},
      proDiscountPercent: action.payload.proDiscountPercent || 0
    }
  })
  .handleAction(ORDERS_ACTION.createPaymentSuccess, (state, action) => {
    return {
      ...state,
      selected: state.selected.filter(s => !action.payload.items.includes(s)),
      items: state.items.filter(item => !action.payload.items.includes(item.id)),
    }
  })
