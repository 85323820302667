import UserProfilePopover from '@components/common/UserProfilePopover'
import React from 'react'
import { NavLink } from 'react-router-dom'
import './styles.css'

interface UserLinkProp {
  username: string
  className?: string
  styleName?: string
  highlight?: boolean
  target?: string
  withPopover?: boolean
  withLeftPopover?: boolean
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
  exitRef?: React.RefObject<HTMLElement>
  avatar?: any
  displayName?: string
}

const UserLink: React.SFC<UserLinkProp> = ({ username, ...props }) => {
  const toURL = `/discover/users/${username}${props.target ? `/${props.target}` : ''}`

  return (
    <>
      <NavLink
        exact={true}
        onClick={props.onClick}
        className={`black-link ${props.className} fn-user-link`}
        styleName="link"
        to={toURL}
        data-highlight={props.highlight}
      >
        {props.withPopover || props.withLeftPopover ? (
          <UserProfilePopover
            username={username}
            position={props.withLeftPopover ? 'left' : 'right'}
          >
            <span>
              {props.avatar &&
                props.displayName && (
                  <img
                    src={props.avatar}
                    alt={props.displayName}
                    width={20}
                    height={20}
                    className="img-circle tw-mr-2"
                  />
                )}
              {props.children}
            </span>
          </UserProfilePopover>
        ) : (
          props.children
        )}
      </NavLink>
    </>
  )
}

UserLink.defaultProps = {
  target: 'reviews',
  highlight: false,
  withPopover: false,
}

export default UserLink
