import * as ACTION from '@actions/rewards'
import { createReducer } from 'typesafe-actions'

export interface RewardsState {
  readonly loading: boolean
  readonly history: any[]
  readonly perPage: number
  readonly totalCounts: number
  readonly totalPages: number
  readonly referralHistory: any[]
}

const initialState: RewardsState = {
  loading: true,
  history: [],
  referralHistory: [],
  perPage: 20,
  totalCounts: 0,
  totalPages: 0,
}

export const rewards = createReducer(initialState)
  .handleAction(ACTION.fetchMyEarningsHistory, state => initialState)
  .handleAction(ACTION.fetchMyEarningsHistorySuccess, (state, action) => {
    return {
      ...state,
      loading: false,
      history: action.payload.data,
      totalCounts: action.payload.meta.total,
      perPage: action.payload.perPage,
      totalPages: Math.ceil(action.payload.meta.total / action.payload.perPage),
    }
  })
  .handleAction(ACTION.fetchReferralEarnings, state => initialState)
  .handleAction(ACTION.fetchReferralEarningsSuccess, (state, action) => {
    return {
      ...state,
      loading: false,
      referralHistory: action.payload.data.referrals.map((r) => {
        const { user, ...rest } = r
        return { ...rest, ...user }
      }),
      totalCounts: action.payload.meta.totalCount,
      perPage: action.payload.perPage,
      totalPages: Math.ceil(action.payload.meta.total / action.payload.perPage),
    }
  })
