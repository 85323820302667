import { WithAuth } from '@components/common/WithAuth'
import URL from '@constants/url'
import React from 'react'
import MobileNavItem from '../MobileNavItem'

interface OrderNavItemProps {
  active: boolean
}

const OrderNavItem: React.SFC<OrderNavItemProps> = props => {
  return (
    <WithAuth onClick={props.onClick}>
      {onClick => (
        <MobileNavItem
          active={props.active}
          icon="package-color"
          link={URL.ACCOUNT_ORDERS}
          onClick={onClick}
        />
      )}
    </WithAuth>
  )
}

export default OrderNavItem
