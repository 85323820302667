import UserAuth from '@components/common/UserAuth'
import useAuth from '@hooks/useAuth'
import {Image} from '@interfaces/image'
import {Question} from '@interfaces/question'
import {CommonUser} from '@interfaces/userProfile'
import React, {FC} from 'react'
import {FormattedMessage} from 'react-intl'
import QAndAForm from '../../forms/QAndAForm/QAndAForm'
import Guide from './Guide'
import QuestionList from './QuestionList'

interface Props {
  loading: boolean
  productId: number
  image: Image
  title: string

  questions: Question[]
  users: CommonUser[]
  onCreate: ({question}: {question: string}) => void
}

const ProductQAndA: FC<Props> = props => {
  const {user} = useAuth()

  return (
    <div>
      <Guide />
      <div className="text-center tw-mt-6">
        {!user && (
          <span>
            <UserAuth />
            <FormattedMessage id="product_page.q&a_to-ask-a-question" />
          </span>
        )}
      </div>
      <QuestionList questions={props.questions} users={props.users} />
      <UserAuth
        renderBefore={null}
        renderAfter={<QAndAForm loading={props.loading} errors={[]} onSubmit={props.onCreate} />}
      />
    </div>
  )
}

export default ProductQAndA
