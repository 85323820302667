import React from 'react'
import injectQuery from '@hoc/injectQuery'

/*
 * TODO: integrate with rx stream after order branch was merged
 */
const SearchQueries = props => {
  const query = props.query

  const queries = {
    q: query.q,
    date: query.date,
    isReviews: query.tab === 'reviews',
    reviewWithPictures: query.pic_only === '1',
  }

  return props.children(queries, { ...props })
}

export default injectQuery(SearchQueries)
