import React, { FC } from "react";
import { Link } from 'react-router-dom'
import { cloudObjectToURL } from '@utils/'
import './styles.css'

interface Props {
  article: any
  className?: string
}

const ArticleInProducts: FC<Props>  = ({ article, className }) => (
  <Link to={`/blog/articles/${article.slug}`}>
    <img src={cloudObjectToURL(article.cover, { size: '500x500' })} className={className} />

    <div className={`${className} relative tw-h-full`}>
      <div className="bold fs18 text-center white-text tw-px-4" styleName="titleWrapper">
        {article.title}
      </div>
    </div>
  </Link>
)

export default ArticleInProducts
