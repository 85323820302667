import React, { FC } from 'react'
import { Props } from './interface'
import Items from './Items'
import Label from './Label'

const SubMenu: FC<Props> = ({ items, renderLabel, ...props }) => (
  <div>
    {renderLabel || <Label {...props} />}

    {/* with collapsible menu */}
    {items && items.length > 0 && <Items items={items} open={props.open} label={props.label} />}
  </div>
)

export default SubMenu
