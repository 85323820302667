import useAuth from '@hooks/useAuth'
import { FC, Fragment, MouseEvent } from 'react'
import { NavLink, RouteComponentProps } from 'react-router-dom'
import './styles.css'
import React from 'react'
import { useLocation } from '@hooks/useRouter'

interface NavProps extends RouteComponentProps {
  onMeClick?: (e: MouseEvent<HTMLElement>) => void
}

const Nav: FC<NavProps | any> = props => {
  const { user } = useAuth()
  const { location } = useLocation()

  const isLastItem = (index: number, menus: string[]) =>
    user
      ? index === menus.length - 1 && location.pathname.includes(`/discover/users/${user.username}`)
      : index === menus.length - 1

  const renderMenus = menus => {
    return menus.map((menu, index) => (
      <Fragment key={index}>
        <NavLink
          exact={true}
          styleName="link"
          to={menu.to}
          data-user={!!user}
          data-last={isLastItem(index, menus)}
        >
          {menu.label}
        </NavLink>
        <span styleName="dots" />
      </Fragment>
    ))
  }

  return (
    <nav className="text-uppercase text-center discover-nav-links" styleName="wrapper">
      {renderMenus([
        { label: "what's hot", to: { pathname: '/discover/whatshot' } },
        {
          label: 'reviews',
          to: { pathname: '/discover/reviews', search: 'date=last_week' },
        },
        { label: 'shops', to: { pathname: '/discover/shops', search: 'filter=all' } },
      ])}
    </nav>
  )
}

export default Nav
