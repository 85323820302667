import React from 'react'

class DomUtils {
  // left: 37, up: 38, right: 39, down: 40,
  // spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
  public static keys = { 37: 1, 38: 1, 39: 1, 40: 1 }

  public static preventDefault(e) {
    e = e || window.event
    if (e.preventDefault) {
      e.preventDefault()
    }
    e.returnValue = false
  }

  public static preventDefaultForScrollKeys(e) {
    if (DomUtils.keys[e.keyCode]) {
      DomUtils.preventDefault(e)
      return false
    }
  }

  public static disableScroll() {
    document.addEventListener('wheel', DomUtils.preventDefault, {
      passive: false,
    })
    document.addEventListener('keydown', DomUtils.preventDefaultForScrollKeys, {
      passive: false,
    })
    document.addEventListener('touchmove', DomUtils.preventDefault, { passive: false })
  }

  public static enableScroll() {
    document.removeEventListener('wheel', DomUtils.preventDefault, {
      passive: false,
    })
    document.removeEventListener('keydown', DomUtils.preventDefaultForScrollKeys, {
      passive: false,
    })
    document.removeEventListener('touchmove', DomUtils.preventDefault, { passive: false })
  }
}

interface PreventBodyScrollProps {
  enabled: boolean
}

class PreventBodyScroll extends React.PureComponent<PreventBodyScrollProps> {
  public static defaultProps = {
    enabled: false,
  }

  public componentDidMount() {
    this.doToggleBodyScroll(this.props.enabled)
  }

  public componentWillReceiveProps(nextProps: PreventBodyScrollProps) {
    this.doToggleBodyScroll(nextProps.enabled)
  }

  public componentWillUnmount() {
    DomUtils.enableScroll()
  }

  public doToggleBodyScroll(enabled: boolean) {
    if (enabled) {
      DomUtils.disableScroll()
    } else {
      DomUtils.enableScroll()
    }
  }

  public render() {
    return null
  }
}

export default PreventBodyScroll
