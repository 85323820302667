import PopoverStickOnHover from '@components/common/PopoverStickOnHover'
import React from 'react'
import Inner from './Inner'
import './styles.css'

interface UserProfilePopoverProps {
  user: any
  username: string
  children: React.ReactNode
  position?: 'left' | 'right'
}

const UserProfilePopover: React.SFC<UserProfilePopoverProps> = props => {
  return (
    <PopoverStickOnHover
      styleName="popover"
      placement={props.position}
      component={
        <div onClick={e => e.stopPropagation()}>
          <Inner username={props.username} />
        </div>
      }
      delay={200}
    >
      {props.children}
    </PopoverStickOnHover>
  )
}

UserProfilePopover.defaultProps = {
  position: 'right'
}

export default UserProfilePopover
