import React from 'react'
import { Modal as BSModal } from 'react-bootstrap'
import './styles.css'

interface ModalProps {
  title: string
  open: boolean
  onCancel?: any
  className?: string
  styleName?: string
  backdrop?: string | boolean
}

const Modal: React.SFC<ModalProps> = props => {
  return (
    <BSModal
      show={props.open}
      onHide={props.onCancel}
      bsSize="large"
      className={props.className}
      styleName="modal"
      {...props.backdrop && { backdrop: props.backdrop }}
    >
      <BSModal.Header closeButton={true}>
        <BSModal.Title>
          {props.title && <div className="linethrough strong fs20">{props.title}</div>}
        </BSModal.Title>
      </BSModal.Header>

      <BSModal.Body>
        <div className="text-left">{props.children}</div>
      </BSModal.Body>
    </BSModal>
  )
}

export default Modal
