import React from 'react'
import PropTypes from 'prop-types'
import DottedLine from '@components/common/DottedLine'
import ProductLink from '@components/common/ProductLink'
import ShoppingCartIcon from '@components/common/ShoppingCartIcon'
import { Link } from 'react-router-dom'
import { resizeImage } from '@utils/image'
import SellerLink from './SellerLink'
import { Platform } from '@interfaces/platform'
import './styles.css'

class ProductCard extends React.PureComponent {
  constructor(props) {
    super(props)

    this.card = React.createRef()
  }

  renderOverlay() {
    return (
      <div styleName="overlay" className="tw-p-2">
        {this.props.seller && <SellerLink id={this.props.seller} platform={this.props.platform} />}

        <ProductLink
          styleName="title"
          id={this.props.id}
          title={this.props.title}
          affiliateToken={this.props.affiliateToken}
          affiliateVia={this.props.affiliateVia}
          platform={this.props.platform}
        />
      </div>
    )
  }

  renderMeta() {
    return (
      <div className="clearfix fs12 tw-mt-2" styleName="meta">
        <div className="pull-right" styleName="sold">
          {this.props.renderSold || this.props.sold}
        </div>
      </div>
    )
  }

  renderFooter() {
    return (
      <div className="clearfix" styleName="footer">
        <div className={this.props.shoppingCart && 'pull-left'}>
          <div className="product-card-price" styleName="price">
            {this.props.renderPrice || this.props.price}
          </div>
        </div>

        {this.props.shoppingCart && (
          <div className="pull-right" styleName="shoppingcart">
            <ShoppingCartIcon id={this.props.id} platform={this.props.platform} />
          </div>
        )}
      </div>
    )
  }

  resizeCoverImage(cover) {
    if (!this.card?.current?.offsetWidth || this.props.platform === Platform.JD) return cover

    const size = Math.ceil(this.card.current.offsetWidth / 100) * 100

    return resizeImage(cover, { width: size, height: size })
  }

  render() {
    return (
      <>
        <div
          styleName="card"
          style={{ backgroundImage: `url("${this.resizeCoverImage(this.props.cover)}")` }}
          ref={this.card}
        >
          <div styleName="body">{this.renderOverlay()}</div>
        </div>

        {this.renderMeta()}

        {this.props.renderPrice || (this.props.shoppingCart && <DottedLine className="tw-my-4" />)}

        {this.renderFooter()}
      </>
    )
  }
}

ProductCard.defaultProps = {
  shoppingCart: false,
  affiliateVia: 'community',
}

ProductCard.propTypes = {
  id: PropTypes.number.isRequired,

  affiliateToken: PropTypes.string,
  communityId: PropTypes.number,

  cover: PropTypes.string.isRequired,

  seller: PropTypes.number,

  price: PropTypes.string,
  shoppingCart: PropTypes.bool,

  price: PropTypes.string,
  renderPrice: PropTypes.element,

  sold: PropTypes.string,
  renderSold: PropTypes.element,
}

export default ProductCard
