import React, { FC, useState } from 'react'
import { addSellerToFavorites } from '@services/favoriteSeller'

import { ControlLabel, FormGroup } from 'react-bootstrap'
import CollectionCategorySelector from '@components/common/CollectionCategorySelector'
import { UnprocessableEntityError } from '@errors/UnprocessableEntityError'
import { usePrevious } from '@hooks/usePrevious'
import DeleteMyCollection from '@components/collection/DeleteMyCollection'

const AddToFavoritesForm: FC<{
  private?: boolean
  category?: string
  action?: string
  publicSeller?: string
  platform?: string
  onClose: () => void
  setIsFavoriteSeller: () => void
  sellerId?: number
  onUpdate?: any
  onDelete?: any
}> = props => {
  const [category, setCategory] = useState<string>(props.category || '')
  const [pending, setPending] = useState<boolean>(false)
  const [error, setError] = useState<string>(null)

  const handleChange = value => {
    return setCategory(value)
  }

  const handleSubmit = () => {
    setPending(true)
    const createParams = {
      access: 'public',
      category: category,
      id: +props.publicSeller,
      provider: props.platform,
    }

    const updateParams = {
      access: 'public',
      category: category,
    }

    if (props.action === 'edit') {
      const callBack = () => {
        setPending(false)
        props.onClose()
      }
      return props.onUpdate(props.sellerId, updateParams, callBack)
    }

    return addSellerToFavorites(createParams)
      .toPromise()
      .then(() => {
        setPending(false)
        props.setIsFavoriteSeller()
        props.onClose()
      })
      .catch(err => {
        setPending(false)
        if (err instanceof UnprocessableEntityError) {
          return setError('This shop has already been added.')
        }

        if (err?.code === 22273) {
          return setError('You’ve reached the maximum number of favorite shops. Please remove shops to add more.')
        }

        return setError('Something wrong when adding shop')
      })
  }

  const onDelete = (id: number) => {
    const callBack = () => {
      props.onClose()
    }

    return props.onDelete(id, callBack)
  }

  const onSubmit = e => {
    e.preventDefault()
    handleSubmit()
  }

  const prevCategory = usePrevious(category)
  const btnDisabled = props.action === 'edit' ? prevCategory === category : !category

  const renderDelete = () => {
    return (
      <div className="bold text-uppercase tw-mt-12">
        <DeleteMyCollection showText={true} id={props.sellerId} onDelete={onDelete} type="shop">
          Remove Shop
        </DeleteMyCollection>
      </div>
    )
  }

  return (
    <form onSubmit={onSubmit}>
      <FormGroup
        controlId="categorySelect"
        data-category={category}
        validationState={null}
        className="text-left"
      >
        <ControlLabel>Shop Category</ControlLabel>
        <div className="relative">
          <CollectionCategorySelector
            className="fs14 strong"
            value={props.category}
            onChange={value => handleChange(value)}
          />
        </div>
      </FormGroup>

      <button
        type="submit"
        className="btn-white btn-wide btn btn-primary tw-mt-6"
        disabled={btnDisabled}
      >
        {props.action == 'edit' ? (
          <span>{pending ? 'SAVING...' : 'SAVE'}</span>
        ) : (
          <span>{pending ? 'ADDING...' : 'SAVE'}</span>
        )}
      </button>

      <div className="text-center">{props.action == 'edit' && renderDelete()}</div>

      {error && <div className="fs12 text-danger text-center tw-mt-2" style={{ width: '80%', margin: '0 auto' }}>{error}</div>}
    </form>
  )
}

export default AddToFavoritesForm
