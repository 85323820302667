import SuggestionsContainer from '@components/common/SearchInputWithSuggestions/Suggestions'
import { useSuggestions } from '@hooks/useSuggestions'
import { showPath as showLinkPath } from '@routing/department/linkRoutes'
import React, { FC } from 'react'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { SUGGESTION } from '@interfaces/suggestion'
import './styles.css'

interface Props {
  alias: string
}

export const Hook = ({ onMount }) => {
  useEffect(() => {
    onMount()
  }, [])

  return null
}

const Suggestions: FC<Props> = props => {
  const { data: suggestions, isLoading: loading } = useSuggestions(props.alias)

  if (loading) {
    return null
  }

  const hook = <Hook onMount={props.onSuggestionLoad} />

  if (suggestions.length == 0) {
    return hook
  }

  return (
    <div className="container tw-py-4">
      <div className="line-max" styleName="wrapper">
        <span className="tw-mr-2" styleName="desk">
          Baopals Suggestions:
        </span>
        <span className="tw-mr-2" styleName="mobile">
          Categories:
        </span>

        {(suggestions || []).map((suggestion, index) => (
          <Link key={index} to={showLinkPath(suggestion)} className="black-link bold tw-mr-2">
            {suggestion.type === SUGGESTION.SELLER && (
              <>
                <i className="icon-seller-shop" />{' '}
              </>
            )}
            {suggestion.title}
            {index !== suggestions.length - 1 && ','}
          </Link>
        ))}
      </div>
      {hook}
    </div>
  )
}

export default Suggestions
