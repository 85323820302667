import { Platform } from '@interfaces/platform'
import { Question } from '@interfaces/question'
import { CommonUser } from '@interfaces/userProfile'
import { createProductQandA, fetchProductQandA } from '@services/product'
import { useEffect, useState } from 'react'

export const useProductQA = (
  id: number,
  platform: Platform
): {
  loading: boolean
  questions: Question[]
  users: CommonUser[]
  createQA: (id: number, question: string) => void
} => {
  const [loading, setLoading] = useState(true)
  const [questions, setQuestions] = useState<Question[]>([])
  const [users, setUsers] = useState<CommonUser[]>([])

  const handleCreate = (id: number, question: string, platform: Platform) =>
    new Promise((resolve, reject) => {
      createProductQandA(id, question, platform).subscribe(
        ({ question, user }) => {
          setQuestions([{ ...question }].concat(questions))
          setUsers(users.filter(u => u.id !== (user && user.id)).concat(user || []))
          return resolve(question)
        },
        err => {
          // console.error(err)
          return reject('Sorry pal, you can only ask three product questions each day')
        }
      )
    })

  useEffect(
    () => {
      setLoading(true)
      fetchProductQandA(id, platform).subscribe(
        res => {
          setQuestions(res.questions)
          setUsers(res.users)
          setLoading(false)
        },
        err => {
          // TODO
        }
      )
    },
    [id]
  )

  return { loading, questions, users, createQA: handleCreate }
}
