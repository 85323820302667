import React from 'react'
import './styles.css'

class SearchInput extends React.PureComponent {
  constructor(props) {
    super(props)
    this.input = React.createRef()

    this.state = {
      value: props.value,
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value) {
      this.setState({ value: nextProps.value })
    }
  }

  handleChange = e => {
    const { value } = e.target
    this.setState({ value }, () => this.props.onChange && this.props.onChange(value))
  }

  handleKeyPress = e => {
    if (e.key === 'Enter') {
      this.props.onEnter && this.props.onEnter(e.target.value, e)
    }
  }

  handleIconClick = () => {
    if (this.props.cleanable) {
      this.setState({ value: '' }, this.props.onEnter && this.props.onEnter(''))

    }
  }

  render() {
    return (
      <div
        className={`input-group tw-pl-0 ${this.props.className}`}
        styleName="wrapper"
        data-cleanable={this.props.cleanable}
      >
        <input
          type="search"
          className="form-control fs12"
          placeholder={this.props.placeholder}
          value={this.state.value}
          ref={this.input}
          disabled={this.props.disabled}
          onChange={this.handleChange}
          onKeyPress={this.handleKeyPress}
        />
        <span className="input-group-btn">
          <button className="icon- btn btn-default" type="button" onClick={this.handleIconClick} />
        </span>
      </div>
    )
  }
}

SearchInput.defaultProps = {
  className: '',
  value: '',
  disabled: false,
  cleanable: false,
}

export default SearchInput
