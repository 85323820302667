import ajax from '@services/client'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Platform } from '@interfaces/platform'
import { strWithEmoji } from '@utils/string'

export const useTaobaoReviews = (id: string) => {
  return useQuery({
    queryKey: ['reviews-taobao', { id }],
    retry: false,
    queryFn: ({ queryKey }) => {
      const [_, params] = queryKey

      return ajax
        .get('/taobao_reviews', { params: { product_id: params.id } })
        .then(({ data }) => data.data.reviews)
        .then(res => {
          const newReviews = res.map(r => ({
            ...r,
            rating: r.rating === 'neutral' ? 'pass' : r.rating,
            pictures: r.pictures.map(p => ({
              id: p,
              provider: Platform.TAOBAO,
            })),
            content: strWithEmoji(r.content),
            upvotesCount: r.upvotesCount || 0,
          }))

          const reviewsWithPic = newReviews.filter((r) => r.pictures && r.pictures.length > 0)
          const noCommentReviews = newReviews.filter((r) =>
            r.content.includes('system default') || r.content.includes('did not fill in the text'))
          const normalReviews = newReviews.filter((r) =>
            !(r.pictures?.length > 0) && 
            !(r.content.includes('system default') || r.content.includes('did not fill in the text')))

          return [...reviewsWithPic, ...normalReviews, ...noCommentReviews]
        })
        .catch(() => [])
    },
  })
}

export const useMutateReview = (id: string) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: id => {
      ajax({
        url: `/taobao_reviews/${id}/upvote`,
        method: 'POST',
      })
    },
    // Notice the second argument is the variables object that the `mutate` function receives
    onSuccess: (data, reviewId) => {
      queryClient.setQueryData(['reviews-taobao', { id }], oldData => {
        return oldData.map(
          r => (r.id === reviewId ? { ...r, upvotesCount: r.upvotesCount + 1, upvoted: true } : r)
        )
      })
    },
  })
}
