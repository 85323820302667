import ProductLink from '@components/common/ProductLink'
import ProgressiveSkeleton from '@components/common/ProgressiveSkeleton'
import ProductWithSticker from '@components/home/ProductWithSticker'
import {BLOG_AFFILIATE_TK} from '@constants/'
import {useTrendingProducts} from '@hooks/useBlog'
import {cloudObjectToURL} from '@utils/cloudStorage'
import {evenArrayLength} from '@utils/array'
import './styles.css'

const Layout = ({children}) => <div className="col-md-6 col-sm-3 col-xs-6 tw-mb-4">{children}</div>

const Loading = () => (
  <div className="row">
    {[1, 2, 3, 4].map(v => (
      <Layout key={v}>
        <ProgressiveSkeleton
          width="100%"
          height={140}
          enableMoveAnimation={true}
          className="tw-mb-4 d-block"
        />
      </Layout>
    ))}
  </div>
)

const TrendingProducts: React.FC = () => {
  const {loading, products, trendings} = useTrendingProducts()
  const evenTrendings = evenArrayLength(trendings)

  return (
    <div className={`tw-w-full p-4 tw-mb-12 bd-light`} styleName="wrapper">
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className="text-center fs20 tw-mb-12 bold text-uppercase">This Week's Top Sellers</div>
          <div className="row">
            {evenTrendings.map((t, index) => {
              const product = products.find(p => p.id === t.productId)

              return (
                <Layout key={index}>
                  <ProductLink
                    id={t.productId}
                    affiliateToken={t.affiliateToken || BLOG_AFFILIATE_TK}
                    platform={product.platformType}
                    target="_blank"
                    affiliateVia="blog_article"
                  >
                    <ProductWithSticker
                      image={cloudObjectToURL(product.cover, {size: '300x300'})}
                      styleName="product"
                    />
                  </ProductLink>
                </Layout>
              )
            })}
          </div>
        </>
      )}
    </div>
  )
}

export default TrendingProducts
