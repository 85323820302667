import React, { Component } from 'react'
import { withRouter }       from 'react-router-dom'
import qs                   from 'qs'

const injectQuery = WrappedComponent => {
  class Query extends Component {
    render() {
      const query = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })

      return (
        <WrappedComponent 
          query={{...query}}
          {...this.props}
        />
      )
    }
  }

  return withRouter(Query)
}

export default injectQuery
