import bpInitObject from '../../constants/index'

/**
 *
 * returns a final baopal price for a given raw price and a given quantity
 *
 * an item price has 5% commission + 2 kuai / slice of 30 kuai (capped to 8)
 *
 * @param originalPrice - Original price in cents
 * @param quantity
 * @returns {number}
 */
export const totalPrice = (originalPrice, quantity = 1) => {
  return quantity * baopalsUnitPrice(originalPrice);
};

/**
 *
 * transforms a baopals price back to the original taobao price
 * (tobaoUnitPrice(baopalsUnitPrice(x)) => x)
 *
 * @param price - Baopals price
 * @returns {number} - Original taobao price
 */
const taobaoUnitPrice = price => {
  const bpRate = 1 + bpInitObject.bpRate / 100
  return Math.ceil((price - reversedBaopalsFee(price)) / bpRate);
};

/**
 *
 * returns a minimum quantity according to a price
 *
 * @param price - Price in cents
 * @returns {number}
 */
export const getMinimumQuantity = (price, multiple = 1) => {
  const qty = Math.max(minimumQuantity(price), multiple);
  if (qty % multiple == 0) {
    return qty;
  }
  return Math.ceil(qty / multiple) * multiple;
};

// Private

const reversedBaopalsFee = price => {
  if (price > 10050) return 800;
  return parseInt(price / 3350 + 1) * 200;
};

// /!\ PRICES SHOULD ALWAYS BE IN CENTS!!!

const BAOPALS_RATE = 1.06;

export const baopalsUnitPrice = price => {
  return priceWithFees(price) + baopalsFee(price);
};

const priceWithFees = price => {
  const bpRate = 1 + bpInitObject.bpRate / 100
  return parseInt(price * bpRate);
};

const baopalsFee = price => {
  if (price >= 9000) return 800;
  return parseInt(price / 3000 + 1) * 200;
};

export const minimumQuantity = price => {
  if (price >= 600) return 1;
  if (price >= 500) return 2;
  if (price >= 400) return 3;
  if (price >= 300) return 4;
  if (price >= 200) return 5;
  if (price >= 150) return 10;
  if (price >= 100) return 25;
  if (price >= 50) return 50;

  return 100;
};

export const taobaoPrice = (price) => (
  Math.round(taobaoUnitPrice(parseInt(price, 10) * 100) / 100)
)
