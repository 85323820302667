import React, { useState } from 'react'
import { Dropdown, MenuItem } from 'react-bootstrap'
import './styles.css'

const RenderMenuItem = ({ key, onClick, item, isActive, renderItem }) => (
  <MenuItem
    key={key}
    onClick={() => onClick(item)}
    active={isActive(item)}
  >
    {renderItem(item)}
  </MenuItem>
)

const Selector = ({ renderItem, menuItems, onClick, id, title, isActive, type, disabled }) => {
  const [open, setOpen] = useState(false)
  const onChange = (e) => {
    onClick(e)
    setOpen(false)
  }

  return (
    <div styleName="wrapper" data-type={type}>
      <Dropdown id={id} onToggle={() => setOpen(!open)} open={open} disabled={disabled}>
        <Dropdown.Toggle>
          {title}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {(menuItems || []).map((item, index) => (
            <RenderMenuItem
              key={index}
              onClick={onChange}
              item={item}
              isActive={isActive}
              renderItem={renderItem}
            />
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}

export default Selector
