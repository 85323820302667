import * as ActionCreator from '@actions/notification'
import { of } from 'rxjs'
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { Epic } from './index'

export const fetchNotifications: Epic = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(ActionCreator.fetchNotifications)),
    switchMap(({ payload }) =>
      (payload.preventRefetch ? of(undefined) : api.fetchNotifications(payload.page)).pipe(
        map(({ data, meta }) => ActionCreator.fetchNotificationsSuccess({ data, meta }))
      )
    )
  )

export const markAsRead: Epic = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(ActionCreator.markAsRead)),
    switchMap(({ payload }) =>
      api
        .markAsRead(payload.ids)
        .pipe(map(() => ActionCreator.markAsReadSuccess({ ids: payload.ids })))
    )
  )

export const markAllAsRead: Epic = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(ActionCreator.markAllAsRead)),
    switchMap(() =>
      api
        .markAllAsRead()
        .pipe(map(() => ActionCreator.markAllAsReadSuccess()))
    )
  )
