import React from 'react'
import {FormattedMessage} from 'react-intl'
import {Link} from 'react-router-dom'

interface CancelReviewBtnProps {
  link?: string
  onClick?: () => void
}

const CancelReviewBtn: React.FC<CancelReviewBtnProps> = ({link, onClick}) => {
  const content = <FormattedMessage id="buttons.cancel" defaultMessage="Cancel" />
  const classNames = 'upper bold black-link'

  return (
    <div className="text-center tw-mt-6">
      {link ? (
        <Link to={link} className={classNames}>
          {content}
        </Link>
      ) : (
        <a className={classNames} onClick={onClick}>
          {content}
        </a>
      )}
    </div>
  )
}

export default CancelReviewBtn
