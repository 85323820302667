import React from 'react'
import ProgressiveSkeleton from '@components/common/ProgressiveSkeleton'
import {LoadingProps} from '@components/common/Loading'

interface Props extends LoadingProps {
  row?: number
  direction?: 'left' | 'right'
}

const WIDTHS = ['80%', '60%', '40%']

const Loading: React.SFC<Props> = props => (
  <div className={props.className || ''}>
    {WIDTHS.slice(0, props.row).map((width, index) => {
      const className = ['tw-mt-0 d-block']
      if (index < props.row - 1) {
        className.push('tw-mb-4')
      }
      if (props.direction === 'right') {
        className.push('tw-mr-0 ml-auto')
      }

      return (
        <ProgressiveSkeleton
          key={index}
          width={width}
          height="14px"
          enableMoveAnimation
          className={className.join(' ')}
        />
      )
    })}
  </div>
)

Loading.defaultProps = {
  row: 3,
  direction: 'left'
}

export default Loading
