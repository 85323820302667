import React, { FC, ReactNode, useContext } from 'react'
import data from './data.json'
import Link from './Link'
import WechatModal from './WechatModal'

const componentTypes: { [key: string]: FC } = {
  WechatModal,
}

const SocialMediaGroup = () => {
  return (
    <ul className="share-block__icons list-inline text-center">
      {data.map((d, idx) => {
        const Component = componentTypes[d.renderComponent] || Link

        return (
          <li key={idx} className="px-4">
            <Component url={d.url} icon={d.icon} />
          </li>
        )
      })}
    </ul>
  )
}

export default SocialMediaGroup
