const public_desktop = [
  "https://res.cloudinary.com/baopals/image/upload/v1644997087/sign_up_modal_banners/desktop/sign_up_modal_1.jpg",
  "https://res.cloudinary.com/baopals/image/upload/v1644997087/sign_up_modal_banners/desktop/sign_up_modal_2.jpg",
  "https://res.cloudinary.com/baopals/image/upload/v1644997087/sign_up_modal_banners/desktop/sign_up_modal_4.jpg",
  "https://res.cloudinary.com/baopals/image/upload/v1644997087/sign_up_modal_banners/desktop/sign_up_modal_5.jpg",
  "https://res.cloudinary.com/baopals/image/upload/v1644997087/sign_up_modal_banners/desktop/sign_up_modal_6.jpg",
  "https://res.cloudinary.com/baopals/image/upload/v1644997087/sign_up_modal_banners/desktop/sign_up_modal_7.jpg",
  "https://res.cloudinary.com/baopals/image/upload/v1644997087/sign_up_modal_banners/desktop/sign_up_modal_8.jpg",
  "https://res.cloudinary.com/baopals/image/upload/v1644997087/sign_up_modal_banners/desktop/sign_up_modal_9.jpg",
  "https://res.cloudinary.com/baopals/image/upload/v1644997087/sign_up_modal_banners/desktop/sign_up_modal_10.jpg",
]

const public_mobile = [
  "https://res.cloudinary.com/baopals/image/upload/v1644997820/sign_up_modal_banners/mobile/sign_up_modal_mobile_1.jpg",
  "https://res.cloudinary.com/baopals/image/upload/v1644997820/sign_up_modal_banners/mobile/sign_up_modal_mobile_2.jpg",
  "https://res.cloudinary.com/baopals/image/upload/v1644997820/sign_up_modal_banners/mobile/sign_up_modal_mobile_4.jpg",
  "https://res.cloudinary.com/baopals/image/upload/v1644997820/sign_up_modal_banners/mobile/sign_up_modal_mobile_5.jpg",
  "https://res.cloudinary.com/baopals/image/upload/v1644997820/sign_up_modal_banners/mobile/sign_up_modal_mobile_6.jpg",
  "https://res.cloudinary.com/baopals/image/upload/v1644997820/sign_up_modal_banners/mobile/sign_up_modal_mobile_7.jpg",
  "https://res.cloudinary.com/baopals/image/upload/v1644997820/sign_up_modal_banners/mobile/sign_up_modal_mobile_8.jpg",
  "https://res.cloudinary.com/baopals/image/upload/v1644997820/sign_up_modal_banners/mobile/sign_up_modal_mobile_9.jpg",
  "https://res.cloudinary.com/baopals/image/upload/v1644997820/sign_up_modal_banners/mobile/sign_up_modal_mobile_10.jpg",
]

export const images = {
  desktop_domestic: [
    ... public_desktop,
    "https://res.cloudinary.com/baopals/image/upload/v1644997087/sign_up_modal_banners/desktop/sign_up_modal_3.jpg",
  ],
  mobile_domestic: [
    ... public_mobile,
    "https://res.cloudinary.com/baopals/image/upload/v1644997820/sign_up_modal_banners/mobile/sign_up_modal_mobile_3.jpg",
  ],
  desktop_global: [
    ... public_desktop,
    "https://res.cloudinary.com/baopals/image/upload/v1646732725/sign_up_modal_banners/desktop/sign_up_modal_11.jpg",
  ],
  mobile_global: [
    ... public_mobile,
    "https://res.cloudinary.com/baopals/image/upload/v1646732737/sign_up_modal_banners/mobile/sign_up_modal_mobile_11.jpg",
  ]
}
