import { useCollections } from '@components/common/AddCollectionBtn/useCollections'
import Collections from '@components/common/AddingCollectionPopup/Collections'

export const useOuter = (product, onAdded, selectedCollectionId) => {
  const { error, resetError, item, onAddToCollection } = useCollections(product, onAdded)

  const renderCollections = (
    <Collections
      itemId={item.id}
      pic={item.pic}
      onAddToCollection={onAddToCollection}
      onStateChange={resetError}
      selectedCollectionId={selectedCollectionId}
    />
  )

  return { error, renderCollections }
}
