import ScrollToTopOnMount from '@components/common/ScrollToTopOnMount'
import WithoutFooter from '@components/layout/WithoutFooter'
import Home from '@components/whatshot/Home'
import React, { FC } from 'react'
import HotSEO from './HotSEO'
import './styles.css'

interface Props {
  onNoResult?: boolean
}

const Hot: FC<Props> = props => (
  <WithoutFooter>
    {!props.onNoResult && <HotSEO />}

    <div styleName="wrapper">
      <Home />
    </div>

    <ScrollToTopOnMount />
  </WithoutFooter>
)

export default Hot
