import InfiniteScroll from 'react-infinite-scroll-component'
import ProductList from '@components/common/ProductList'
import { ProductCardLoading } from '@components/common/ProductCard'

import { FC, useState } from 'react'

interface ProductListWithPaginationProps {
  products: any[]

  gridSizes?: string[]

  hasMore: boolean
  onLoadMore: () => void
}

const ARR = new Array(24).fill('').map((_, idx) => idx + 1)

export const Loading = ({ gridSizes }) => {
  const classNames = `${gridSizes.map(size => `v4-${size}`).join(' ')} tw-mb-12 tw-px-2`
  return (
    <div className="v4-row v4-no-gutters">
      {ARR.map(v => (
        <div className={classNames} key={v}>
          <ProductCardLoading />
        </div>
      ))}
    </div>
  )
}

const ProductListWithPagination: FC<ProductListWithPaginationProps> = ({ products, ...props }) => {
  return (
    <InfiniteScroll
      dataLength={products.length}
      next={props.onLoadMore}
      hasMore={props.hasMore}
      loader={
        <div>
          <Loading gridSizes={props.gridSizes} />
        </div>
      }
    >
      <ProductList products={products} gridSizes={props.gridSizes} />
    </InfiniteScroll>
  )
}

ProductListWithPagination.defaultProps = {
  gridSizes: ['col-lg-3', 'col-md-4', 'col-6'],
}

export default ProductListWithPagination
