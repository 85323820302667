import { isChina, isGreaterChina } from "@utils/i18n";

const removedCategoryIds = [35, 36, 38, 39, 59]

export const getFilteredCategories = (categories: any, country: String) => {
  const newCategories = isChina(country) ? categories : categories.filter(c => c.id !== 8)

  return isGreaterChina(country)
    ? newCategories
    : newCategories.filter(c => !removedCategoryIds.includes(c.id))
}
