import {ALL_METHODS} from '@reducers/reducers'
import React, {FC} from 'react'
import './styles.css'

interface Props {
  className?: string
}

const Payments: FC<Props> = props => (
  <div className={`flex-center justify-content-center ${props.className || ''}`}>
    {ALL_METHODS.map((m, idx) => (
      <div key={idx} className={`tw-mx-2 pm pm-${m}`} styleName="unit">
        {' '}
      </div>
    ))}
  </div>
)

export default Payments
