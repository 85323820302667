import ajax from '../api/ajax'
import { of } from 'rxjs'
import { catchError, pluck } from 'rxjs/operators'
import { Platform } from '@interfaces/platform'
import { platformToProvider } from '@utils/platform'

export const fetchSellerDetails = (id, platform) =>
  ajax({
    url: `/sellers/${id}`,
    query: {
      provider: platformToProvider(platform),
    },
  }).pipe(
    pluck('response', 'seller'),
    catchError(err => of(null))
  )
