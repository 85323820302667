import { useProductSku, useSingleProduct } from '@hooks/useProduct'
import { Platform } from '@interfaces/platform'
import SkuOptionCategory from './Category'
import EmptySkuText from './EmptySkuText'
import './styles.css'
import {FC} from 'react'

/*
const renderSku = product => (
  <div styleName="option-container">
    {product.skuCategories.map((category: any) => (
      <SkuOptionCategory
        key={category.id}
        category={category}
        product={product}
        selectedOptions={[]}
        onSelectOption={() => console.log('onselect')}
        onUnselectOption={() => console.log('on unselect')}
      />
    ))}
  </div>
)

const renderJDSku = product => {
  const { categories } = skuToCategories(product.skus)
  const categoryNames = Object.keys(categories)

  return (
    <div styleName="option-container">
      {Object.entries(categories).map(([name, options], index) => {
        return (
          <JDCategory
            key={index}
            categoryNames={categoryNames}
            category={name}
            product={product}
            options={options}
            onSelectOption={() => console.log('onselect')}
            onUnselectOption={() => console.log('on unselect')}
          />
        )
      })}
    </div>
  )
}

const ProductSkus = ({ id }) => {
  const { product } = useSingleProduct(id, Platform.JD)

  if (!product) {
    return null
  }

  const { skuCategories } = product

  if (!skuCategories.length && product.platform !== Platform.JD) {
    return <EmptySkuText />
  }

  if (product.platform === Platform.JD) {
    return renderJDSku(product)
  } else {
    return renderSku(product)
  }

  return null
}
 */

// export default ProductSkus

const ProductSkus: FC<any> = ({ id }) => {
  const { product } = useSingleProduct(id)

  const { selected, onSelect, onUnselect } = useProductSku(id)

  if (product.platform === Platform.JD && selected.length === 0) {
    return <EmptySkuText />
  }

  if (product.skuCategories?.length > 0) {
    return (
      <div styleName="option-container">
        {product.skuCategories.map((category: any) => (
          <SkuOptionCategory
            key={category.id}
            category={category}
            product={product}
            selected={selected}
            onSelectOption={onSelect}
            onUnselectOption={product.platform === Platform.JD ? () => {} : onUnselect}
          />
        ))}
      </div>
    )
  }

  return <EmptySkuText />
}

export default ProductSkus
