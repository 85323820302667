import React, { FC } from 'react'
import Mobile from './Mobile'
import Desktop from './Desktop'

const TranslationToggler: FC<any> = props => (
  <>
    <div className="visible-xs visible-sm">
      <Mobile {...props} />
    </div>
    <div className="visible-md visible-lg">
      <Desktop {...props} />
    </div>
  </>
)

TranslationToggler.defaultProps = {
  toggle: false,
}

export default TranslationToggler
