import { Default, Mobile } from '@components/common/Responsive'
import TranslationText from '@components/common/TranslationText'
import { useSingleProduct } from '@hooks/useProduct'
import { useProductImage } from '@hooks/useProductImages'
import { cloudObjectToURL } from '@utils/'
import { FC, useState } from 'react'
import Button from './Button'
import DesktopPopup from './DesktopPopup'
import MobileModal from './MobileModal'

const ShareProduct: FC<{ product: number }> = props => {
  const [open, setOpen] = useState(false)

  const toggleOpen = () => setOpen(!open)

  const { product } = useSingleProduct(props.product)

  const { selected } = useProductImage(product?.id)

  if (!product) {
    return null
  }

  const pic = cloudObjectToURL(selected)

  return (
    <>
      <Button open={open} toggleOpen={toggleOpen} />

      <>
        <Default>
          <DesktopPopup
            id={product.id}
            pic={pic}
            title={product.titles.en}
            open={open}
            platform={product.platform}
          />
        </Default>

        <Mobile>
          <MobileModal
            id={product.id}
            pic={pic}
            title={product.titles.en}
            open={open}
            onClose={toggleOpen}
            platform={product.platform}
          />
        </Mobile>
      </>
    </>
  )
}

export default ShareProduct
