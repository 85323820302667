import categories from '../data/tmall-categories.json'
import jdCat0 from '../data/jd-cat-0.json'
import jdCat1 from '../data/jd-cat-1.json'
import jdCat2 from '../data/jd-cat-2.json'

const firstAndSecondCatOnJd = jdCat0.concat(jdCat1)

const jdCategories = firstAndSecondCatOnJd.concat(jdCat2)

export const fetchCategoryDetails = id => {
  const result = categories.find(c => +c.id === +id)

  return result
}

export const fetchMainCategory = () => categories.filter(c => c.parent === '50514008')
export const fetchSubCategories = id  => categories.filter(c => c.parent === id)

export const fetchAllCategories = () => categories

export const fetchJdCategories = () => [...jdCategories].filter(c => !c.note).sort((a, b) => `${a.name}`.localeCompare(`${b.name}`))

export const fetchJdCategoryDetails = id => {
  const result = jdCategories.find(c => +c.id === +id)

  return result
}

export const findTop2LevelJdCategory = id => firstAndSecondCatOnJd.find(c => +c.id === +id)
