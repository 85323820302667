import ChinaOnly from '@components/common/ChinaOnly'
import BacktoTop from '@components/layout/BacktoTop'
import React from 'react'

const WithoutFooter = ({ children }) => (
  <>
    {children}
    <ChinaOnly>
      <BacktoTop className="totop-infinite" scroll={true} />
    </ChinaOnly>
  </>
)

export default WithoutFooter
