import React from 'react'
import { Link } from 'react-router-dom'
import TranslationText from '@components/common/TranslationText'
import { totalPrice } from '@utils/product/priceUtils'
import PriceWithCurrency from '@components/common/PriceWithCurrency'
import '../styles.css'
import { cloudObjectToURL } from '@utils/'
import { toProductLinkStr } from '@components/common/ProductLink'

const Product = ({ product, preventClickOnDragging }) => {
  const preventDefault = e => e.preventDefault()
  const promotion = product.promotion && product.promotion.minPrice < product.minPrice

  const cover = product.thumbnails?.[1] ?? product.cover
  const coverUrl = cloudObjectToURL(cover, { size: '200x200' })

  return (
    <Link
      className="black-link text-center d-block"
      to={toProductLinkStr(product.id, { platform: product.platform || product.platformType })}
      onDragStart={preventDefault}
      onClick={preventClickOnDragging}
      data-unavailable={!!product.unavailable}
    >
      <div styleName="product" className="bd-dark relative">
        <div
          styleName="image"
          className="bg-fit-center tw-w-full"
          style={{ backgroundImage: `url("${coverUrl}")` }}
          title={""}
        />
        <div styleName="title">{""}</div>
      </div>

      <span className="fs18 tw-pt-4 d-block blue-link">
        <PriceWithCurrency
          price={totalPrice(promotion ? product.promotion.minPrice : product.minPrice)}
        />
      </span>

      {promotion ? (
        <strike className="fs12 text-grey">
          <PriceWithCurrency price={totalPrice(product.minPrice)} />
        </strike>
      ) : (
        <div style={{ height: '18px' }} />
      )}
    </Link>
  )
}

export default Product
