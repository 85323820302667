import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import Collection from './Collection'
import Loading from './Loading'
import useAuth from '@hooks/useAuth'
import './styles.css'

const Widget = memo(
  ({ loading, cart, onAddItemToCollection, onUpdateItemQuantity, onDeleteItem, onClose }) => {
    return (
      <div styleName="wrapper">
        {!loading && (
          <Collection
            cart={cart}
            onUpdateItemQuantity={onUpdateItemQuantity}
            onDeleteItem={onDeleteItem}
            onAddItemToCollection={onAddItemToCollection}
          />
        )}
        {loading && <Loading size={5} />}

        <div style={{ padding: "1.5rem", borderTop: "1px solid #e2e2e2" }}>
          <Link
            onClick={onClose}
            className="btn btn-block btn-white"
            styleName="link"
            to={`/checkout`}
          >
            <FormattedMessage id="homepage.cart_go-checkout" />
          </Link>
        </div>
      </div>
    )
  }
)

export default Widget
