import Joyride from 'react-joyride'
import { useMyCountry } from '@hooks/useAuth'

const defaultStyles = {
  options: {
    zIndex: 10000,
  },
  buttonNext: {
    backgroundColor: '#3366cc',
  },
  buttonClose: {
    display: 'none',
  },
  tooltipContainer: {
    textAlign: 'left',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
  },
}

let styles = defaultStyles

const Tour = props => {
  const { locationCountry } = useMyCountry()

  if(!locationCountry) return null

  if (props.options) {
    styles = { ...defaultStyles, options: props.options }
  }

  return <Joyride styles={styles} {...props} />
}

export default Tour
