import ProductPrice from '@components/product/v4/ProductDetails/ProductPrice'
import './styles.css'

const Price = ({ id }) => (
  <div styleName="price" className="product-price-range">
    <ProductPrice id={id} />
  </div>
)

export default Price
