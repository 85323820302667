import ProductList from './ProductList'
import SEO from '@components/SEO'
import { shuffle } from 'lodash'
import { taobaoPrice } from '@utils/product/priceUtils'
import { isNumeric } from '@utils/number'
import {
  useInfiniteQuery
} from '@tanstack/react-query'
import { useInView } from 'react-intersection-observer'
import ScrollToTop from '@components/common/ScrollToTop'
import ScrollToTopOnMount from '@components/common/ScrollToTopOnMount'
import Trending from '@components/layout/SearchNavbar/Trending'
import JDCategoryMenu from '@components/product/v4/Layout/WithCategorySidebar/JDCategory/Menu'
import ImageSearchThumbnail from "@components/products/ImageSearchThumbnail"
import SortAndFilters from '@components/products/SortAndFilters'
import { NoResult } from '@components/search/NoResult'
import CategoryToggler from '@components/superMarket/CategoryToggler'
import ProductActiveFiltersContainer from '@containers/ProductActiveFiltersContainer'
import TmallProductPropsContainer from '@containers/TmallProductPropsContainer'
import { useNamedUrl } from '@hooks/useNamedUrl'
import { useQuery } from '@hooks/useRouter'
import { Platform } from '@interfaces/platform'
import { findTop2LevelJdCategory } from '@services/category'
import { platformToProvider, queryToPlatform } from '@utils/platform'
import { Default, Mobile } from 'components/common/Responsive'
import React, { FC, useEffect, useState } from "react";
import DeptsCarousel from './DeptsCarousel'
import MatchResults from './MatchResults'
import Reviews from './ReviewsBySearch'
import SellerDetails from './Seller/Details'
import SellerSwitcher from './SellerSwitcher'
import SellerSwitcherTour from '@components/common/Tour/SellerSwitcherTour'
import BacktoTop from '@components/layout/BacktoTop'
import { openSignUpModal } from '@hooks/useSignUpModal'
import './styles.css'
import { SearchProductsQuery } from '@interfaces/products'
import { useMyCountry } from '@hooks/useAuth'
import { Loading } from './ProductList/Loading'
import { fetchProducts } from 'repositories/product'
import SellerCategoriesMobileMenu from '@components/products/SellerCategoriesMobileMenu'
import { sortBy } from 'lodash'
import PageLayout from '@components/superMarket/SearchProducts/Layout/Layout'
import CategoryList from '@components/products/Seller/Categories'

interface ProductsProps {
  keyword?: string
  category?: number
  image?: string

  sellerCategory?: number
  seller?: number

  platform?: Platform

  innerClassName?: string
}

const description = (title: string) =>
  `Shop a wide selection of ${title} on Baopals.com. Baopals brings you over 1 billion` +
  ' products from China’s shopping platforms from millions of sellers.'

const paramsToQuery = (params: SearchProductsQuery) => {
  return {
    sort: params.sort || 'popularity',
    page: isNumeric(params.page) ? params.page : 1,
    per_page: isNumeric(params.pageSize) ? params.pageSize : 40,
    translate: params.translate,
    image: params.image,
    provider:
      params.platform === Platform.TMALL
        ? Platform.TMALL
        : platformToProvider(params.platform),
    ...(params.prop && { prop: params.prop }),
    ...(params.location && { location: params.location }),
    ...(params.keyword && { query: params.keyword }),
    ...(isNumeric(params.categoryId) && { category_id: params.categoryId }),
    ...(isNumeric(params.sellerCategory) && { cat_id: params.sellerCategory }),
    ...(isNumeric(params.sellerId) && { seller_id: params.sellerId }),
    ...(isNumeric(params.minPrice) && { minPrice: taobaoPrice(params.minPrice) }),
    ...(isNumeric(params.maxPrice) && { maxPrice: taobaoPrice(params.maxPrice) }),
  }
}

const Products: FC<ProductsProps> = props => {
  const query = useQuery()
  const [suggestionsLoading, setSuggestionsLoading] = useState(true)
  const [isRenderCarousel, setIsRenderCarousel] = useState(false)
  const [reviewsLoading, setReviewsLoading] = useState(false)
  const [sellerCategory, setSellerCategory] = useState(null)

  const seller = props.seller || query.seller
  const platform = props.platform || queryToPlatform(query.platform)
  const isTmall = platform === Platform.TMALL
  const isJd = platform === Platform.JD

  const { isInternational } = useMyCountry()
  const withJd = !isInternational && platform == Platform.TAOBAO

  const opt = {
    translate: query.translate !== 'false',
    ...(query.prop && { prop: query.prop }),
    ...(query.sort && { sort: query.sort }),
    ...(query.location && { location: query.location }),
    ...(query.categoryId && { categoryId: query.categoryId }),
    ...(props.category && { categoryId: props.category }),
    ...(query.query && { keyword: query.query }),
    ...(props.keyword && { keyword: props.keyword }),
    ...(props.image && { image: props.image, pageSize: 20 }),
    ...(seller && { sellerId: seller }),
    ...(platform && { platform }),
    ...(props.sellerCategory && { sellerCategory: props.sellerCategory }),
    ...(query.minPrice && { minPrice: query.minPrice }),
    ...(query.maxPrice && { maxPrice: query.maxPrice }),
    ...(isJd && { pageSize: 28 }),
    withJd,
  }

  const {
    fetchNextPage,
    fetchPreviousPage,
    hasNextPage,
    hasPreviousPage,
    isFetching,
    isFetchingNextPage,
    isFetchingPreviousPage,
    error,
    status,
    ...result
  } = useInfiniteQuery({
    queryKey: ['products', opt],
    queryFn: async ({ pageParam = 1, queryKey }) => {
      const [_, params] = queryKey
      const fetchParams = {
        ...paramsToQuery(params),
        page: pageParam,
      }
      if (params.withJd) {
        const tbResp = await fetchProducts(fetchParams)

        const jdResp = await fetchProducts({ ...paramsToQuery({ ...params, platform: Platform.JD, pageSize: 16 }), page: pageParam })

        return { ...tbResp, totalProducts: tbResp.totalProducts + jdResp.totalProducts, products: shuffle(tbResp.products.concat(jdResp.products)) }
      }

      return fetchProducts(fetchParams)
    },

    getNextPageParam: (lastPage, pages) => {
      if (lastPage.totalProducts && !isJd && lastPage.products.length >= 40) {

        return pages.length + 1
      }

      if (lastPage.totalProducts && isJd && lastPage.products.length >= 27) {
        return pages.length + 1
      }

      if (lastPage.imgUrl && lastPage.products.length >= 20) {
        return pages.length + 1
      }
    },
    retry: false,
  })


  const hasEmptyResults = result.data?.pages[0]?.products?.length === 0

  useEffect(() => {
    if (error?.response?.status === 401) {
      openSignUpModal()
    }
  }, [error?.response?.status])

  const namedUrlResult = useNamedUrl()

  const { ref, inView } = useInView()

  React.useEffect(() => {
    if (inView && result.isSuccess) {
      fetchNextPage()
    }
  }, [inView])

  const isFavoriteSeller = result.data?.pages[0]?.favoriteSeller ?? false
  const totalProducts = result.data?.pages[0]?.totalProducts ?? 0
  const sellerCategories = sortBy(result.data?.pages[0]?.taobaoCategories ?? [], (c) => c.titles?.en)
  const defaultThumbnailIdx = result.data?.pages[0]?.defaultThumbnailIdx

  const renderProducts = (
    <>
      <SortAndFilters
        withLayoutToggler={true}
        enableSorting={isJd}
        innerClassName={props.seller && sellerCategories?.length > 0 ? '' : props.innerClassName}
        menuClassName={props.seller ? 'products-hide-sorting-price' : ''}
        seller={seller}
        platform={platform}
        isFavoriteSeller={isFavoriteSeller}
        loading={result.isLoading}
        sellerCategory={sellerCategory}
        sellerCategories={sellerCategories}
      />
      <ProductActiveFiltersContainer />
      <div className={`tw-mt-12 ${props.seller && sellerCategories?.length > 0 ? '' : props.innerClassName}`}>
        <div style={{ paddingBottom: "100px" }}>

          {status === 'loading' ? <Loading /> : (result.data?.pages ?? []).map((page, i) => (
            <ProductList
              key={i}
              products={page.products}
              showSellerWithCategories={props.seller && sellerCategories?.length > 0 }
              defaultThumbnailIdx={defaultThumbnailIdx}
            />
          ))}

          <div>
            {isFetchingNextPage && <Loading />}
            <div ref={ref}>{' '}</div>
          </div>

        </div>

      </div>
    </>
  )

  if (typeof props.children === 'function') {
    return props.children({ loading: result.isLoading, totalProducts, renderProducts, fetchNextPage })
  }

  const renderMatchResults = ({ loading, totalProducts, totalReviews }) => (
    <MatchResults
      loading={loading || namedUrlResult.loading}
      totalProducts={totalProducts}
      totalReviews={totalReviews}
      heading={namedUrlResult.name}
      onSuggestionLoad={() => setSuggestionsLoading(false)}
    />
  )

  const renderReviewsSection = (includeMatchResults = false) => {
    return props.keyword ? (
      <>
        <Default>
          {!namedUrlResult.loading && (
            <Reviews keyword={namedUrlResult.name || props.keyword} setReviewsLoading={setReviewsLoading} >
              {({ loading: loadingReviews, renderReviews, totalReviews }) => (
                <>
                  {includeMatchResults &&
                    renderMatchResults({
                      loading: result.isLoading,
                      totalProducts,
                      totalReviews: loadingReviews ? -1 : totalReviews,
                    })}
                  <div>{renderReviews}</div>
                </>
              )}
            </Reviews>
          )}
        </Default>
        <Mobile>
          {renderMatchResults({
            loading: result.isLoading,
            totalProducts,
            totalReviews: 0,
          })}
        </Mobile>
      </>
    ) : null
  }

  const headingAlias = namedUrlResult.name || query.q || 'The Best Way to Shop in China'

  const renderSEO =
    (headingAlias || props.image) ? (
      <SEO
        image={props.image}
        schema="SearchResultsPage"
        title={headingAlias}
        description={description(headingAlias)}
      />
    ) : null

  if (!result.isLoading && hasEmptyResults) {
    return (
      <div styleName='wrapper' data-image={!!props.image}>
        {renderSEO}
        <div className="tw-pt-1 pt-sm-0">
          {renderReviewsSection()}
          {props.keyword && <SellerSwitcher />}
        </div>
        <div className="tw-mb-6">
          <SortAndFilters
            withLayoutToggler={true}
            enableSorting={false}
            innerClassName={props.innerClassName}
            seller={seller}
            platform={platform}
            isFavoriteSeller={isFavoriteSeller}
            sellerCategory={sellerCategory}
          />
        </div>
        <NoResult contentWrapperClass="container" isTmallSeller={isTmall} alias={namedUrlResult?.name} />
      </div>
    )
  }

  const renderMain = () => (
      <section styleName="products wrapper" data-loading={result.isLoading} data-image={!!props.image} data-platform={platform} data-seller={!!props.seller}>
        <ScrollToTop onBeforeReset={() => isJd && props.category && findTop2LevelJdCategory(props.category) ? false : true}>
          {
            renderSEO
          }
          <DeptsCarousel platform={platform} setIsRenderCarousel={setIsRenderCarousel}>{renderReviewsSection(true)}</DeptsCarousel>
          {props.image && <ImageSearchThumbnail image={props.image} totalProducts={totalProducts} loading={result.isLoading} />}

          {props.keyword && (
            <>
              <SellerSwitcher />
              {!result.isLoading && (!suggestionsLoading || isRenderCarousel) && <SellerSwitcherTour />}
            </>
          )}

          {/*{props.seller && showSeller && !result.isLoading && (*/}
            {/*<SellerDetails*/}
              {/*id={+props.seller}*/}
              {/*totalProducts={result.isLoading ? undefined : totalProducts}*/}
              {/*platform={platform}*/}
              {/*setSellerCategory={setSellerCategory}*/}
            {/*/>*/}
          {/*)}*/}

          {props.category && (
            <>
              {isJd && <CategoryToggler renderMenu={prop => <JDCategoryMenu id={props.category} {...prop} />} />}
              <div className="hidden-xs hidden-sm">
                <div className="container tw-my-6 tw-pt-2">
                  {platform === Platform.TMALL && (
                    <TmallProductPropsContainer category={props.category} />
                  )}
                </div>
              </div>
            </>
          )}

          {props.seller && sellerCategories.length > 0 && (
            <CategoryToggler renderMenu={prop => <SellerCategoriesMobileMenu {...prop} sellerCategories={sellerCategories} />} />
          )}

          {renderProducts}

          <ScrollToTopOnMount />
        </ScrollToTop>
        <BacktoTop className="totop-infinite" scroll={true} />
      </section>
    )

  return (
    <>
      <Default>
        {props.seller && (
          <SellerDetails
            id={+props.seller}
            totalProducts={result.isLoading ? undefined : totalProducts}
            platform={platform}
            setSellerCategory={setSellerCategory}
          />
        )}
        {props.seller && sellerCategories?.length > 0 ? (
          <div styleName="main">
            <PageLayout main={renderMain()} aside={<CategoryList categories={sellerCategories} />} />
          </div>
        ) : (
          <>
            {renderMain()}
          </>
        )}
      </Default>
      <Mobile>
        <Trending />
        {props.seller && (
          <SellerDetails
            id={+props.seller}
            totalProducts={result.isLoading ? undefined : totalProducts}
            platform={platform}
            setSellerCategory={setSellerCategory}
          />
        )}
        {renderMain()}
      </Mobile>
    </>
  )
}

Products.defaultProps = {
  innerClassName: '',
  platform: Platform.TAOBAO
}

export default Products
