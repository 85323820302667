import React from 'react'
import { useSelector } from 'react-redux'

export const useShoppingCartTips = () => {
  const currency = useSelector(state => state.paymentConfig.currency)

  const amount = currency === 'usd' ? '$15' : '¥100'

  return `(Free for Supermarket carts over ${amount}!)`
}
