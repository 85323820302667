import {HttpStatus} from '@constants/httpStatus'
import { HttpError } from './HttpError'

export class RackAttackError extends HttpError {
  public name = 'RackAttackError'

  constructor(message?: string) {
    super(HttpStatus.RACK_ATTACK)
    Object.setPrototypeOf(this, RackAttackError.prototype)

    if (message) {
      this.message = message
    }
  }
}
