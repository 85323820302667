import CatList from '@components/common/CategoryList'
import { useLocation, useQuery } from '@hooks/useRouter'
import { arrayToNestedChildren } from '@utils/'
import qs from 'qs'
import './styles.css'

const CategoryList = (props) => {
  const query = useQuery()
  const {
    location: { pathname },
  } = useLocation()

  const allCategories = (props.categories.length > 0
      ? [{ externalId: 0, titles: { en: 'Everything' } }]
      : []
  ).concat(props.categories).map(c => ({ ...c, parent: -1, name: c.titles.en, id: +c.externalId }))

  const nest = arrayToNestedChildren(allCategories, -1)

  const renderTree = (data) => (
    <>
      <CatList
        nestedCategories={data}
        categories={allCategories}
        renderLink={cat =>
          `${pathname}?${qs.stringify({
            ...query,
            categoryId: cat.id === 0 ? undefined : cat.id,
            page: 1,
          })}`
        }
        activeCategory={+(query.categoryId || 0)}
        rootCategory={-1}
      />
    </>
  )

  return (
    <div styleName="list">
      {renderTree(
        nest.map(n => ({
          ...n,
          children: n.children
            ? [...n.children.sort((a, b) => a.title.localeCompare(b.title))]
            : null,
        }))
      )}
    </div>
  )
}

export default CategoryList
