import React from 'react'

import { Price as IPrice } from '@components/product/v4/ProductDetails'
import { Titles } from '@interfaces/common'
import { Platform } from '@interfaces/platform'
import PriceSection from './PriceSection'
import ShopSection from './ShopSection'

type Props = IPrice & {
  state: Titles
  city: Titles
  shopName: Titles

  sellerId: number

  platform: Platform

  id: number

  productsCount: number
}

const ProductInfo: React.SFC<Props> = props => {
  return (
    <div>
      <PriceSection {...props} />

      <ShopSection
        id={props.id}
        city={props.city}
        state={props.state}
        platform={props.platform}
        sellerId={props.sellerId}
        shopName={props.shopName}
        productsCount={props.productsCount}
      />
    </div>
  )
}

export default ProductInfo
