import React from 'react'
import AwesomeBurgerMenu from '@components/common/AwesomeBurgerMenu'
import { Link } from 'react-router-dom'
import './styles.css'

const CategoryBurgerMenu = ({ categories, open, onMenuStateChange, ...props }) => (
  <AwesomeBurgerMenu
    open={open}
    id="supermarket-cat-menu"
    position="right"
    onMenuStateChange={onMenuStateChange}
  >
    <div>
      {typeof props.renderMenu === 'function'
        ? props.renderMenu({ onMenuStateChange })
        : props.renderMenu}
    </div>
  </AwesomeBurgerMenu>
)

export default CategoryBurgerMenu
