import React, { Component } from 'react'
import Loadable from 'react-loadable'
import AsyncLoading from '../AsyncLoading'

export default function AsyncLoader(opts) {
  return Loadable(
    Object.assign(
      {
        loading: AsyncLoading,
        delay: 200,
        //timeout: 10,
      },
      opts
    )
  )
}
