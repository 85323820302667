import { Platform } from '@interfaces/platform'

export const queryToPlatform = (platform: string) => {
  switch (platform) {
    case 'express':
      return Platform.JD
    case 'taobao':
      return Platform.TAOBAO
    case 'tmall':
      return Platform.TMALL
  }
}

export const platformToQuery = (platform: Platform) => {
  if (platform === Platform.JD) {
    return 'express'
  }

  return platform
}

export const platformToProvider = (platform: Platform) => {
  switch (platform) {
    case Platform.JD:
      return 'jingdong'

    default:
      return Platform.TAOBAO
  }
}
