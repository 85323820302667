import { DATE_OF_MONTH_FORMAT } from '@constants/date'

import { Display } from '@interfaces/notification'
import { formatDate, formatFromNowDate, formatSimpleFromNowNDate } from '@utils/dateFormat'
import { differenceInDays } from 'date-fns'
import React, { FC } from 'react'

interface Props {
  date: string
  daysAgo?: number
  display?: Display
}

const Time: FC<Props> = ({ date, ...props }) => {
  const isBefore = differenceInDays(new Date(), new Date(date)) > props.daysAgo

  // only show month & year on old notification
  // show simple formated time on dropdown
  const time = isBefore
    ? formatDate(date, DATE_OF_MONTH_FORMAT)
    : props.display == Display.dropdown
      ? formatSimpleFromNowNDate(date)
      : null

  if (time) {
    return <React.Fragment>{time}</React.Fragment>
  }

  return (
    <React.Fragment>
      <div className="visible-xs visible-sm">{formatSimpleFromNowNDate(date)}</div>
      <div className="hidden-xs hidden-sm">{formatFromNowDate(date)}</div>
    </React.Fragment>
  )
}

Time.defaultProps = {
  daysAgo: 3,
  display: Display.page,
}

export default Time
