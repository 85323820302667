import url from '@constants/url'
import { Platform } from '@interfaces/platform'
import { isSupermarket } from '@utils/productUtils'
import React, { FC } from 'react'
import SellerLink from '../SellerLink'

const badgeMap = {
  [Platform.JD]: '/v1592370924/badges/express.png',
  [Platform.TAOBAO]: '/v1589510686/badges/Taobao.png',
  [Platform.TMALL]: '/v1592370923/badges/tmall.png',
  supermarket: '/v1589860933/badges/supermarket.png',
}

const SellerBadge: FC<{
  id: number
  platform: Platform
  containerClassName?: string
}> = ({ id, platform, ...props }) => {
  const isSup = isSupermarket({ sellerId: id })

  const key = isSup ? 'supermarket' : platform

  const img = <img src={`${url.CLOUD_DINARY_BASE}${badgeMap[key]}`} alt="" />

  return (
    <div className={props.containerClassName} data-platform={key} data-valid-seller={!!id}>
      {id && !isSup ? (
        <SellerLink id={id} platform={platform}>
          {img}
        </SellerLink>
      ) : (
        img
      )}
    </div>
  )
}

SellerBadge.defaultProps = {
  containerClassName: '',
}

export default SellerBadge
