import { HttpStatus } from '@constants/httpStatus'
import { HttpError } from '@errors/HttpError'

export class NotFoundError extends HttpError {
  public name = 'NotFoundError'

  constructor() {
    super(HttpStatus.NOT_FOUND)
    Object.setPrototypeOf(this, NotFoundError.prototype)
  }
}
