import DottedLine from '@components/common/DottedLine'
import { FC } from 'react'
import ProductTitle from '../../../ProductTitle'
import ShareProductOrAddToCollection from '../ShareProductOrAddToCollection'
import Price from './Price'
import { Default } from '@components/common/Responsive'
import './styles.css'

const PriceSection: FC<any> = ({ id, ...props }) => {
  return (
    <div className="d-flex relative" styleName="wrapper">
      <div className="visible-xs tw-w-full" styleName="title">
        <ProductTitle titles={props.titles} selected={props.selected} platform={props.platform} />
        <DottedLine />
      </div>

      <div className="tw-w-full product-price" styleName="meta">
        <div className="flex-center-between">
          <Default>
            <Price id={id} />
          </Default>
          <ShareProductOrAddToCollection id={id} />
        </div>
        <Default>
          <DottedLine />
        </Default>
      </div>
    </div>
  )
}

export default PriceSection
