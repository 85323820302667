import { useQuery } from '@hooks/useRouter'
import { AuthProvider, WechatAuthState } from '@interfaces/auth'
import { isWeChat } from '@utils/'
import { readOpenIdFromLocalStorage } from '@utils/auth'

const wechatProviders = [WechatAuthState.AUTO_AUTH, WechatAuthState.MANUALLY_AUTH]

export const useAuthProvider = (): AuthProvider => {
  const { code, state } = useQuery()
  const openId = readOpenIdFromLocalStorage()

  const provider = (() => {
    if (!code || !state || (!openId && isWeChat())) {
      return AuthProvider.EMAIL
    }

    if (wechatProviders.includes(state)) {
      return AuthProvider.WECHAT_PUBLIC_ACCOUNT
    }

    if (WechatAuthState.OPEN_PLATFORM_AUTH === state) {
      return AuthProvider.WECHAT_OPEN_PLATFORM
    }

    return AuthProvider.EMAIL
  })()

  return provider
}
