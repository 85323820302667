import React from 'react'
import { useCountries } from '@hooks/useCountries'
import { sortBy } from 'lodash'
import Selector from './selector'

const renderCountry = (country) => (
  <div className="d-flex align-items-center">
    <img
      width={24}
      src={`https://res.cloudinary.com/baopals/image/upload/w_100,f_auto,q_auto/${country?.flagUrl}`}
      alt=""
    />
    <div className="tw-ml-2">{country?.alias}</div>
  </div>
)

const CountrySelector = ({ whiteList, selected, onChange }) => {
  const { countries } = useCountries()
  const sortedCountries = sortBy(whiteList.length > 0 ? countries.filter(c => whiteList.includes(c.abbr)) : countries, 'alias')
  const isActive = (country) => selected === country.abbr

  return (
    <Selector
      id="countries-dropdown"
      title={renderCountry(countries.find(c => c.abbr === selected))}
      menuItems={sortedCountries}
      renderItem={renderCountry}
      onClick={(country) => onChange(country)}
      isActive={isActive}
      type="country"
    />
  )
}

CountrySelector.defaultProps = {
  whiteList: []
}

export default CountrySelector
