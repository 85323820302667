import React from 'react'
import {FormattedMessage} from 'react-intl'
import SocialMediaGroup from '../../SocialMediaGroup'

const FollowUs: React.SFC<{}> = props => {
  return (
    <div className="upper bold align-self-start">
      <div className={`text-center fs20 tw-mb-6 mb-sm-5`}>
        <FormattedMessage id="footer.follow_us" defaultMessage="FOLLOW US" />
      </div>

      <SocialMediaGroup />
    </div>
  )
}

export default FollowUs
