import { AVAILABLE_STOCK_STATE, Platform } from '@interfaces/platform'
import { Product } from '@interfaces/product'
import { Respond } from '@interfaces/question'
import { platformToProvider } from '@utils/platform'
import { SUPERMARKET_SELLERS } from '@utils/productUtils'
import { Observable, of } from 'rxjs'
import { catchError, map, pluck, tap } from 'rxjs/operators'
import ajax from '../api/ajax'

export const fetchProductDetails = (id: number, { country, platform, skus }: { platform: Platform, country: string, skus?: string }): Observable<Product | null> => {
  return ajax({ url: `/products/${id}`, query: { provider: platformToProvider(platform), country } }).pipe(
    pluck('response', 'product'),
    map(({ skus, ...product }) => {
      const newSkus = skus.map(s => ({
        ...s,
        ...(!s.quantity && { quantity: AVAILABLE_STOCK_STATE.includes(s.stockState) && !(product.nick || '').includes('海外') ? 999 : 0 }),
        ...(!s.id && { id: '-1' })
      }))

      return {
        ...product,
        ...(product.platformType === Platform.JD && newSkus.length === 1 && newSkus[0]?.prices?.[0] && product.minPrice !== product.maxPrice && {
          minPrice: newSkus[0].prices[0],
          maxPrice: newSkus[0].prices[0],
        }),
        ...((product.nick || '').includes('旗舰店') && product.platformType === Platform.JD && { sellerId: null }),
        ...(product.skuOptions.length > skus.length && skus.length <= 1 && { nick: '海外' }),
        isSupermarket: SUPERMARKET_SELLERS.includes(+product.sellerId),
        skus: newSkus,
        skuMapping: newSkus.reduce((prev, next) => {
          return {
            ...prev,
            [next.id]: next
          }
        }, {}),
        ...(product.id !== id && {
          id,
          spuId: product.id,
        }),
        platform: product.shopType,
        thumbnails: product.thumbnails || []
      }
    }),
    // catchError(() => of(null))
  )
}

export const fetchProductMeta = (id: number, sku: number): Observable<any> => {
  return ajax({ url: `/products/${id}/details`, query: { sku_id: sku } }).pipe(
    pluck('response', 'data'),
    catchError(() => of(null))
  )
}

export const fetchSimilarProducts = (id: number, page: number): Observable<any> => {
  return ajax({ url: `/products/${id}/related`, query: { page } }).pipe(
    pluck('response', 'products'),
    map(res => res.map(r => ({
      ...r,
      thumbnails: r.thumbnails ?? []
    }))),
    catchError(() => of([]))
  )
}

export const fetchProductShipping = (id: number, country: string, zone: number, platform: Platform): Observable<Product | null> => {
  const url = `/products/${id}/shipping/${zone}`

  return ajax({ url, query: { country, provider: platformToProvider(platform) } }).pipe(
    pluck('response', 'data', 'shipping'),
    map(res => [].concat(res)),
    catchError(() => of(null))
  )
}

export const fetchProductQandA = (id: number, platform: Platform): Observable<Respond> =>
  ajax({
    url: `/products/${id}/questions`,
    query: { provider: platformToProvider(platform) },
  }).pipe(pluck('response', 'data'))

export const createProductQandA = (id: number, question: string, platform: Platform) =>
  ajax({
    url: `/products/${id}/questions`,
    method: 'POST',
    body: {
      provider: platformToProvider(platform),
      question: { title: question },
    },
  }).pipe(pluck('response', 'data'))

export const recordProductShares = (id: string, provider: string, platform: Platform) => {
  return ajax({
    url: `/products/${id}/sharings`,
    method: 'POST',
    query: { provider, product_provider: platformToProvider(platform) },
  }).pipe(pluck('response'))
}

export const ocrProductImage = (id: string, platform: any, image: string) => {
  return ajax({
    url: `/products/${id}/translate_image?url=${image}&provider=${platformToProvider(platform)}`,
    method: 'GET',
  }).pipe(
    pluck('response', 'data', 'values'),
    catchError(() => of([]))
  )
}

export const fetchProductReviewMeta = (id: string, platform: any) => {
  return ajax({
    url: `/products/${id}/meta`,
    method: 'GET',
    query: { provider: platformToProvider(platform) }
  }).pipe(
    pluck('response', 'data'),
    catchError(() => of([]))
  )
}
