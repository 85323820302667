import SignupLink from '@components/common/SignupLink'
import {openLoginModal} from '@hooks/useLoginModal'
import React from 'react'
import {FormattedMessage} from 'react-intl'
import './styles.css'

const Guest = () => (
  <div className="pb-4">
    <div className="bodo" styleName="greetings">
      <FormattedMessage id="nav_mobile.hi_pal" />
    </div>
    <div className="tw-mt-2">
      <FormattedMessage id="nav_mobile.prompt" />
    </div>

    <div styleName="actions">
      <a onClick={openLoginModal}>
        <FormattedMessage id="nav_mobile.sign_in" />
      </a>
      <span className="tw-mx-4">|</span>
      <SignupLink className="text-uppercase" />
    </div>
  </div>
)

export default Guest
