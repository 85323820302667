import { formatDate } from '@utils/dateFormat'
import { useEffect, useState } from 'react'

const getTime = () => new Date().toLocaleString('en-US', { timeZone: 'Asia/Shanghai' })

export const BeijingTime = () => {
  const [dt, setDt] = useState(getTime())

  useEffect(() => {
    const myInterval = setInterval(() => {
      setDt(getTime())
    }, 1000)

    return () => clearInterval(myInterval)
  }, [])

  return <span>China Time: {formatDate(new Date(dt), 'MM/dd h:mm:ss a')}</span>
}
