import ItemsWithLineThrough from '@components/common/ItemsWithLineThrough'
import UrlQuery from '@components/common/UrlQuery'
import { useMyCountry } from '@hooks/useAuth'
import { Platform } from '@interfaces/platform'
import { platformToQuery } from '@utils/platform'
import './styles.css'

const SellerSwitcher = () => {
  const { isInternational } = useMyCountry()

  const platforms = ['', Platform.TMALL]
    .concat(!isInternational ? Platform.JD : [])
    .map(p => p.toLowerCase())

  return (
    <div className="text-center tw-my-12 products-seller-switcher" id="tour-seller-switcher">
      <ItemsWithLineThrough className="d-inline-block tw-ml-0" styleName="switcher">
        {platforms.map((platform, i) => (
          <UrlQuery
            key={platform}
            names={['platform', 'page']}
            optional={!platform}
            values={{ platform: platformToQuery(platform) }}
            className="strong tw-px-2"
          >
            {platform === Platform.TMALL && 'TMALL'}
            {platform === Platform.JD && 'EXPRESS'}
            {!platform && 'ALL SELLERS'}
          </UrlQuery>
        ))}
      </ItemsWithLineThrough>
    </div>
  )
}

export default SellerSwitcher
// .reduce((prev, curr, i) => [prev, <div styleName="line" key={i} />, curr])
