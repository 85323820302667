import React, {FC} from 'react'
import {NavLink} from 'react-router-dom'
import {Item} from '../interface'

const InnerText: FC<Item> = props => {
  const style = `black-link tw-my-6 ${props.withIcon ? 'flex-center-between' : 'd-block'}`

  const renderText = () => (
    <React.Fragment>
      <div>{props.text}</div>
      <div className={`icon-arrow-right ${props.withIcon ? '' : 'hidden'}`} />
    </React.Fragment>
  )

  return props.link ? (
    <NavLink
      exact={true}
      className={style}
      to={props.link}
      target={props.target}
      activeClassName="link-active"
      {...props.isActive && {isActive: (_, location) => props.isActive(location)}}
    >
      {renderText()}
    </NavLink>
  ) : (
    <div className={style} onClick={props.onClick}>
      {renderText()}
    </div>
  )
}

export default InnerText
