import { memo } from 'react'
import './styles.css'

const MinQtyRequired = memo(() => (
  <div className="text-danger" styleName="minqty">
    This item requires a minimum order quantity.
  </div>
))

export default MinQtyRequired
