import { CloudObject } from '@interfaces/cloud'
import { Platform } from '@interfaces/platform'
import { imageToUrl } from './imageUtils'

const S3_REGION = 'cn-north-1'
const S3_HOST_NAME = `baopals.com`

interface Options {
  size?: string
  toJPG?: boolean
}

const cloudObjectToURL = (obj: CloudObject, options?: Options) => {
  if (typeof obj === 'string') return obj
  if (!obj?.provider) { return }

  switch (obj.provider) {
    case 'aws_s3':
      return s3ObjectToURL(obj, options)

    case Platform.JD:
      return jdObjectToURL(obj, options)

    default:
      return imageToUrl(obj, options)
  }
}

const s3ObjectToURL = (obj: CloudObject, options: Options = {}) => {
  const params = []
  const withoutResizeExts = ['video', 'gif']
  const withoutSize = withoutResizeExts.some(ext => obj.mimeType?.includes?.(ext))

  if (options.size && !withoutSize) {
    params.push(`fit-in/${options.size}`)
  }

  if (!withoutSize) {
    params.push(`filters:quality(${options.quality || 80})`)
  }

  if (obj.extension === 'png' && options.toJPG) {
    params.push(`filters:format(jpeg)`)
  }

  return [`//${obj.bucket}.${S3_HOST_NAME}`].concat(params).concat(`${obj.folder}/${obj.id}.${obj.extension}`).join('/')
}

const jdObjectToURL = (obj: CloudObject, options?: Options = {}) => {
  const url = (() => {
    if (obj.id.includes('360buyimg')) {
      return obj.id.replace('http:', '')
    }
    return `//img30.360buyimg.com/popshop/${obj.id}`
  })().replace('/ads/', '/pop/')

  const jfsIndex = url.indexOf('jfs')


  if (jfsIndex > -1) {
    const prefix = []
    if (options.size) {
      prefix.push(`s${options.size}_`)
    }

    const finalUrl = url.slice(0, jfsIndex).concat(prefix.join('')).concat(url.slice(jfsIndex))
    return finalUrl.startsWith('//') ? `https:${finalUrl}` : finalUrl
  }

  return url.startsWith('//') ? `https:${url}` : url
}

export { cloudObjectToURL }
