import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Loading } from '@components/common/ProductListWithPagination'
import ProductList from '@components/common/ProductList'

interface ProductsWithEndlessScrollProps {
  setPage: (page: number) => void
  products: any[]
  isEnd: boolean
  page: number
  loading: boolean
}

const GRID_SIZE = ['col-lg-3', 'col-md-4', 'col-6']

const ProductsWithEndlessScroll: React.SFC<ProductsWithEndlessScrollProps> = ({
  setPage, products, page, loading, isEnd
}) => {
  return (
    <InfiniteScroll
      dataLength={products.length}
      next={() => setPage(page + 1)}
      hasMore={loading ? false : !isEnd}
      loader={
        <div>
          <Loading gridSizes={GRID_SIZE} />
        </div>
      }
    >
      <ProductList
        products={products.map(p => ({ ...p, location: null, }))}
        loading={loading}
        renderThumbnails={false}
        prettyUpImage={true}
      />
      {loading && (
        <div>
          <Loading gridSizes={GRID_SIZE} />
        </div>
      )}
    </InfiniteScroll>
  )
}

export default ProductsWithEndlessScroll
