import * as ActionCreator from '@actions/products'
import { getSelectedOptions } from '@utils/product'
import { Platform, AVAILABLE_STOCK_STATE } from '@interfaces/platform'
import { forkJoin, of } from 'rxjs'
import { catchError, filter, map, switchMap, mergeMap, tap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { Epic } from './index'

export const fetchProductMeta: Epic = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(ActionCreator.fetchSingleProductMeta)),
    mergeMap(({ payload }) =>
      api.fetchProductMeta(payload.id, payload.sku).pipe(
        map(meta => ActionCreator.fetchSingleProductMetaSuccess({ id: payload.sku, spu: payload.id, meta })),
        catchError(err => of(ActionCreator.fetchSingleProductMetaFailure(err)))
      )
    )
  )

export const fetchSingleProduct: Epic = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(ActionCreator.fetchSingleProduct.request)),
    switchMap(({ payload }) => {
      return forkJoin(
        api.fetchProductDetails(payload.id, { platform: payload.platform, skus: payload.skus, country: payload.country }),
        payload.zoneId ? api.fetchProductShipping(payload.id, payload.country, payload.zoneId, payload.platform) : of(null)
      ).pipe(
        map(([product, shippings]) => {
          if (shippings?.length) {
            if (payload.platform === Platform.JD) {
              const skus = product.skus.map(s => {
                const sku = shippings.find(shipping => +shipping.productId === +s.skuId)
                return {
                  ...s,
                  ...(sku && {
                    stockState: sku.stockState,
                    quantity: AVAILABLE_STOCK_STATE.includes(sku.stockState) ? 999 : 0
                  })
                }
              })

              const skuMapping = Object.entries(product.skuMapping).map(mapping => {
                const [key, value] = mapping

                const sku = shippings.find(shipping => +shipping.productId === +value.skuId)

                return {
                  [key]: {
                    ...value,
                    ...(sku && {
                      stockState: sku.stockState,
                      quantity: AVAILABLE_STOCK_STATE.includes(sku.stockState) ? 999 : 0
                    })
                  }
                }
              }).reduce((prev, next) => ({ ...prev, ...next }), {})

              return {
                ...product,
                skus,
                skuMapping
              }
            } else {
              const shipping = shippings.find(s => s.productId === payload.id)

              const skus = (() => {
                if (shipping.stock === 0) {
                  return product.skus.map(s => ({ ...s, quantity: 0 }))
                } else {
                  return product.skus
                }
              })()

              const newP = {
                ...product,
                ...((product.nick?.includes('海外旗舰') || product.nick?.includes('海外专营')) && { unavailable: 'seller_banned' }),
                shipping: {
                  ...product.shipping,
                  price: shipping.available ? shipping.price : -1,
                },
                stock: shipping.available ? shipping.stock : 0,
                skus
              }

              return newP
            }
          }

          return product
        }),
        map(product => {
          const selectedSku = (() => {
            switch (product.platform) {
              case Platform.JD: {
                const sku = product.skus.find(s => (s.id === payload.skus) || (+s.skuId === +(payload.skuId ? payload.skuId : payload.id)))

                if (sku?.quantity === 0) {
                  const inStockSku = product.skus.find(s => s.quantity !== 0)
                  if (inStockSku) {
                    return inStockSku.id

                  }
                }

                return sku?.id
              }

              default:
                return payload.skus
            }
          })()

          const selected = selectedSku === '-1' ? [] : getSelectedOptions(
            product.skus,
            product.skuCategories,
            product.skuOptions,
            selectedSku
          )

          return ActionCreator.fetchSingleProduct.success({
            id: payload.id,
            product: { ...product, country: payload.country },
            selected,
          })
        }),
        catchError(err => of(ActionCreator.fetchSingleProduct.failure(err)))
      )
    })
  )
