import React, {FC, useEffect} from 'react'
import {useFormState} from 'react-use-form-state'
import {FormattedMessage} from 'react-intl'
import './styles.css'

interface DefaultProps {
  withPictures?: boolean
  handleClick: () => void
}

const PicFilter: FC<DefaultProps> = props => {
  const [formState, {checkbox}] = useFormState({
    type: props.withPictures ? ['withPictures'] : [],
  })

  useEffect(
    () => {
      formState.setField('type', props.withPictures ? ['withPictures'] : [])
    },
    [props.withPictures]
  )

  return (
    <div>
      <div
        className="visible-xs"
        onClick={props.handleClick}
        styleName={props.withPictures ? 'active' : ''}
      >
        <i className="fa fa-camera" />
      </div>
      <div className="cursor-default hidden-xs fs12" onClick={props.handleClick}>
        <input className="tw-mt-0 tw-mr-2" {...checkbox('type', 'withPictures')} />
        <FormattedMessage id="review_form.sort_with-pictures" />
      </div>
    </div>
  )
}

export default PicFilter
