const MODULE = 'COLLECTION_ITEMS'

export const FETCH_COLLECTION_ITEMS_REQUEST = `${MODULE}_FETCH_COLLECTION_ITEMS_REQUEST`
export const FETCH_COLLECTION_ITEMS_SUCCESS = `${MODULE}_FETCH_COLLECTION_ITEMS_SUCCESS`
export const FETCH_COLLECTION_ITEMS_FAILED = `${MODULE}_FETCH_COLLECTION_ITEMS_FAILED`

export const SELECT_COLLECTION_ITEM = `${MODULE}_SELECT_COLLECTION_ITEM`
export const TOGGLE_SELECTED_COLLECTION_ITEMS = `${MODULE}_TOGGLE_SELECTED_COLLECTION_ITEMS`
export const DELETE_COLLECTION_ITEMS = `${MODULE}_DELETE_COLLECTION_ITEMS`
export const MOVE_COLLECTION_ITEM = `${MODULE}_MOVE_COLLECTION_ITEM`

export const DELETE_COLLECTION_ITEM_REQUEST = `${MODULE}_DELETE_COLLECTION_ITEM_REQUEST`
export const DELETE_COLLECTION_ITEM_SUCCESS = `${MODULE}_DELETE_COLLECTION_ITEM_SUCCESS`
export const DELETE_COLLECTION_ITEM_FAILED = `${MODULE}_DELETE_COLLECTION_ITEM_FAILED`

export const UPDATE_COLLECTION_ITEMS_POSITION_REQUEST = `${MODULE}_UPDATE_COLLECTION_ITEMS_POSITION_REQUEST`
export const UPDATE_COLLECTION_ITEMS_POSITION_SUCCESS = `${MODULE}_UPDATE_COLLECTION_ITEMS_POSITION_SUCCESS`
export const UPDATE_COLLECTION_ITEMS_POSITION_FAILED = `${MODULE}_UPDATE_COLLECTION_ITEMS_POSITION_FAILED`

export const UPDATE_COLLECTION_ITEM_REQUEST = `${MODULE}UPDATE_COLLECTION_ITEM_REQUEST`
export const UPDATE_COLLECTION_ITEM_SUCCESS = `${MODULE}UPDATE_COLLECTION_ITEM_SUCCESS`
export const UPDATE_COLLECTION_ITEM_FAILED = `${MODULE}UPDATE_COLLECTION_ITEM_FAILED`
