export default {
  FETCH_SINGLE_PRODUCT_REQUEST: '@PRODUCTS/FETCH_SINGLE_PRODUCT_REQUEST',
  FETCH_SINGLE_PRODUCT_SUCCESS: '@PRODUCTS/FETCH_SINGLE_PRODUCT_SUCCESS',
  FETCH_SINGLE_PRODUCT_FAILURE: '@PRODUCTS/FETCH_SINGLE_PRODUCT_FAILURE',

  FETCH_SINGLE_PRODUCT_META_REQUEST: '@PRODUCTS/FETCH_SINGLE_PRODUCT_META_REQUEST',
  FETCH_SINGLE_PRODUCT_META_SUCCESS: '@PRODUCTS/FETCH_SINGLE_PRODUCT_META_SUCCESS',
  FETCH_SINGLE_PRODUCT_META_FAILURE: '@PRODUCTS/FETCH_SINGLE_PRODUCT_META_FAILURE',

  RESET_PRODUCT_QTY: '@PRODUCTS/RESET_PRODUCT_QTY',

  UPDATE_PRODUCT_IMAGE: '@PRODUCTS/UPDATE_PRODUCT_IMAGE',

  UPDATE_PRODUCT_QTY: '@PRODUCTS/UPDATE_PRODUCT_QTY',

  SELECT_PRODUCT_SKU: '@PRODUCTS/SELECT_PRODUCT_SKU',
  UNSELECT_PRODUCT_SKU: '@PRODUCTS/UNSELECT_PRODUCT_SKU',
} as const
