import { useResponsive, Viewport } from '@hooks/useResponsive'
import React from 'react'
import Form from '../Form'
import Menu from '../Menu'
import Modal from '../Modal'

const Widget = ({
 open, review, onSubmit, onFormChange, loading, onCancel, categories, sizeOptions,
 selectedSkuId, selectedSizeOptionId, hasSelectedSku, hasSelected
}) => {
  const isXS = useResponsive([Viewport.XS]).some(v => v)
  const Component = isXS ? Menu : Modal

  return (
    <Component open={open} loading={loading} onCancel={onCancel} isEdit={!!review?.id}>
      <Form
        review={review}
        onSubmit={onSubmit}
        onChange={onFormChange}
        categories={categories}
        sizeOptions={sizeOptions}
        selectedSkuId={selectedSkuId}
        selectedSizeOptionId={selectedSizeOptionId}
        hasSelected={hasSelected}
      />
    </Component>
  )
}

export default Widget
