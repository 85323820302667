import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { ReviewData, UserData, ProductData } from '@interfaces/reviews'
import ReviewPic from '@components/common/ReviewUnit/ReviewPic'
import Bar from 'components/common/ReviewUnit/Bar'
import Details from '@components/common/ReviewUnit/Details'
import './styles.css'
import UserLink from '@components/common/UserLink'

interface DefaultProps {
  loading: boolean
  review: ReviewData
  user?: UserData
  product?: ProductData

  handleUpvote: (id: number) => void
  handleDownvote: (id: number) => void
}

const Review: FC<DefaultProps> = ({ review, user, product, ...props }) => (
  <div id={`review-${review.id}`} className="py-4 bd-b-light" styleName="review">
    <div>
      <ReviewPic showUser={true} product={product} user={user} />

      <div className="bold tw-mt-2 text-center" styleName="text">
        {review.anonymous ? (
          <FormattedMessage id="general.anonymous" />
        ) : (
          <UserLink className="black-link text-truncate d-block" username={user.userName}>
            {user.displayName}
          </UserLink>
        )}
      </div>
    </div>

    <div className="text-left">
      <Bar
        className="bd-b-light tw-pb-2 tw-mb-4 tw-mt-2"
        review={review}
        user={user}
        handleUpvote={() => props.handleUpvote(review.id)}
        handleDownvote={() => props.handleDownvote(review.id)}
      />

      <Details review={review} user={user} product={product} isTitleLink={false} />
    </div>
  </div>
)

export default Review
