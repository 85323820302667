import { Platform } from '@interfaces/platform'
import {
  ActionRespond,
  FecthOpt,
  FetchRespond,
  ReviewForm,
  SingleRespond,
} from '@interfaces/reviews'
import { platformToProvider } from '@utils/platform'
import { Observable, of } from 'rxjs'
import { catchError, pluck } from 'rxjs/operators'
import ajax from '../api/ajax'

export const fetchProductReviews = (opt: FecthOpt): Observable<FetchRespond> =>
  ajax({
    url: '/product_reviews',
    query: { ...opt },
  }).pipe(
    pluck('response'),
    catchError(e => of(FecthErrorData))
  )

export const fetchSingleReview = (id: number): Observable<SingleRespond> =>
  ajax({
    url: `/product_reviews/${id}`,
  }).pipe(pluck('response', 'data'))

export const createReview = (
  productId: number,
  review: ReviewForm,
  platform: Platform
): Observable<ActionRespond> => {
  const formData = formatCreateReview(review)

  formData.append('provider', platformToProvider(platform))

  return ajax({
    url: `/products/${productId}/reviews`,
    method: 'POST',
    body: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }).pipe(pluck('response', 'data'))
}

export const updateReview = (id: number, review: ReviewForm): Observable<ActionRespond> => {
  const formData = formatCreateReview(review)

  if (review.deletedPictures && review.deletedPictures.length) {
    for (let i = 0; i < review.deletedPictures.length; i++) {
      formData.append('review[deletedPictures][]', review.deletedPictures[i])
    }
  }

  return ajax({
    url: `/product_reviews/${id}`,
    method: 'PATCH',
    body: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }).pipe(pluck('response', 'data'))
}

export const upvoteReview = (id: number) =>
  ajax({
    url: `/product_reviews/${id}/upvote`,
    method: 'POST',
  }).pipe(pluck('response'))

export const downvoteReview = (id: number) =>
  ajax({
    url: `/product_reviews/${id}/downvote`,
    method: 'POST',
  }).pipe(pluck('response'))

const formatCreateReview = (review: ReviewForm) => {
  const formData = new FormData()

  formData.append('review[rating]', review.rating)
  formData.append('review[title]', review.title)
  formData.append('review[content]', review.content)
  formData.append('review[anonymous]', review.anonymous)

  if (review.pictures && review.pictures.length) {
    for (let i = 0; i < review.pictures.length; i++) {
      formData.append('review[pictures[][file]]', review.pictures[i])
    }
  }

  if (review.skuId) {
    formData.append('review[skuId]', review.skuId)
  }

  if (review.clothingFit) {
    formData.append('review[clothingFit]', review.clothingFit)
  }

  return formData
}

const FecthErrorData: FetchRespond = {
  data: {
    products: [],
    reviews: [],
    users: [],
  },
  meta: {
    currentPage: 0,
    totalCount: 0,
    totalPages: 0,
  },
}
