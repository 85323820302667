import React from "react"
import './styles.css'

interface TogglerProps {
  toggle: boolean
  onToggle: () => void
}

const Toggler: React.SFC<TogglerProps> = ({ toggle, onToggle }) => (
  <a
    onClick={onToggle}
    className={`black-link fs12 strong ${!toggle ? 'text-orange' : ''}`}
    styleName="toggle"
  >
    SHOW MORE <i className={`fs10 icon-arrow-${toggle ? 'down' : 'up'}`} />
  </a>
)

export default Toggler
