import { UserProfile } from '@interfaces/user'
import { Observable } from 'rxjs'
import { pluck } from 'rxjs/operators'
import ajax from '../api/ajax'

const PREFIX = '/me'

export const updatePreferences = opt =>
  ajax({
    url: `${PREFIX}/preferences`,
    method: 'PATCH',
    body: { preferences: { disabled_notifications: opt.disabledNotifications } },
  }).pipe(pluck('response'))

export const updateMyProfile = (form: Partial<UserProfile>) => {
  const { avatar, locale, locationCity, locationCountry, subscription, ...profile } = form

  const formData = new FormData()

  if (avatar instanceof File) {
    formData.append('me[avatar][file]', avatar)
  }

  if (locationCountry) {
    formData.append('me[location_country]', locationCountry)
  }

  if (locationCity) {
    formData.append('me[location_city]', locationCity)
  }

  if (subscription) {
    formData.append('me[subscription][active]', subscription.active)
  }

  formData.append('me[locale]', locale === 'zh-CN' ? 'zh_cn' : locale)

  for (const field in profile) {
    if (typeof profile[field] !== 'undefined') {
      formData.append(`me[${field}]`, profile[field])
    }
  }

  return ajax({
    url: PREFIX,
    method: 'PUT',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    body: formData,
  }).pipe(pluck('response', 'data', 'user'))
}

export const dismissTooltip = (tooltip: string): Observable<any> =>
  ajax({
    url: '/me/dismiss_tooltip',

    method: 'DELETE',

    body: { tooltip },
  }).pipe(pluck('response'))
