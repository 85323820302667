import { toProductLinkStr } from '@components/common/ProductLink'
import ShareButtonGroup from '@components/common/ShareButtonGroup'
import { Platform } from '@interfaces/platform'
import React from 'react'
import './styles.css'

interface DesktopPopupProps {
  pic: any
  title: any
  id: number
  open: boolean
  platform: Platform
}

const DesktopPopup: React.SFC<DesktopPopupProps> = props => {
  if (!props.open) {
    return null
  }

  return (
    <div styleName="wrapper">
      <ShareButtonGroup
        pic={props.pic}
        title={props.title}
        url={`https://www.baopals.com${toProductLinkStr(props.id, { platform: props.platform })}`}
        id={props.id}
        withUrl={true}
        platform={props.platform}
      />
    </div>
  )
}

export default DesktopPopup
