import { sortByDate } from '@utils/array'
import GravitySpinner from '@components/common/GravitySpinner'
import { useMyCollections } from '@hooks/useMyCollections'
import { FC, useEffect, useState } from 'react'
import CollectionList from '../CollectionList'
import './styles.css'

interface CollectionsProps {
  itemId: number
  pic: string
  onAddToCollection: (id: number, updateCover: boolean) => void
  selectedCollectionId: number
}

const Collections: FC<CollectionsProps> = ({ onAddToCollection, ...props }) => {
  const [loading, setLoading] = useState<boolean>(true)
  const { data: collections, actions } = useMyCollections()

  const sortedCollections = [...collections].sort(sortByDate)

  useEffect(
    () => {
      setLoading(true)
      actions
        .fetchMyCollections(props.itemId)
        .then(() => setLoading(false))
        .catch(err => {
          console.warn('Something wrong during fetch my collection')
          console.warn(err)
        })
    },
    [props.itemId]
  )

  const renderCollections = (
    <CollectionList
      pic={props.pic}
      collections={sortedCollections}
      onAddToCollection={onAddToCollection}
      selectedId={props.selectedCollectionId}
    />
  )

  return (
    <>
      <div className="hidden-xs">
        <div className="col-md-8 col-sm-8 bd-l-dark" styleName="wrapper">
          <>{loading ? <GravitySpinner /> : renderCollections}</>
        </div>
      </div>
      <div className="visible-xs">
        <div styleName="list">
          <div>{renderCollections}</div>
        </div>
      </div>
    </>
  )
}

export default Collections
