import {fetchJdCategories} from '@services/category'
import {arrayToNestedChildren} from '@utils'

export const useJdCategories = () => {
  const categories = fetchJdCategories()

  const nestedCategories = arrayToNestedChildren(categories, 0)

  return  { categories, nestedCategories }
}
