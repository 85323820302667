export const seoData = {
  "main": {
    title: "The Blog",
    description: "The best products, sellers and promotions handpicked by the Baopals team."
  },
  "fashion-and-beauty": {
    title: "Fashion & Beauty",
    description: "The hottest apparel and accessories curated by the Baopals team."
  },
  "mens-fashion": {
    title: "Men's Fashion",
    description: "The hottest men's apparel and accessories curated by the Baopals team."
  },
  "womens-fashion": {
    title: "Women's Fashion",
    description: "The hottest women's apparel and accessories curated by the Baopals team."
  },
  "gifts-and-holidays": {
    title: "Gifts & Holidays ",
    description: "Our favorite gift ideas, holiday decorations, costumes and more on Baopals."
  },
  "birthdays": {
    title: "Birthdays",
    description: "Find the perfect birthday gifts and supplies handpicked by the Baopals team."
  },
  "christmas": {
    title: "Christmas",
    description: "Gift ideas, Christmas decorations and more handpicked by the Baopals team."
  },
  "gift-ideas": {
    title: "Gift Ideas",
    description: "The best gift ideas for him, for her, and for the little ones."
  },
  "mothers-and-fathers-day": {
    title: "Mothers & Father's Day",
    description: "Find your mother or father the perfect gift this year."
  },
  "health-and-home": {
    title: "Health & Home",
    description: "Browse our favorite products on Baopals for taking care of your health and home."
  },
  "education": {
    title: "Education",
    description: "Liven up the classroom with our handpicked educational games and classroom supplies."
  },
  "fitness": {
    title: "Fitness",
    description: "Get your body moving with the best fitness gear on Baopals."
  },
  "tech": {
    title: "Tech",
    description: "The hottest electronics, gadgets and more handpicked by the Baopals team."
  },
  "food-and-grocery": {
    title: "Food & Grocery",
    description: "Healthy snacks, delicious treats, tasty beverages and more."
  },
  "home": {
    title: "Home",
    description: "Make your house a home with interior design inspiration and handpicked items from the Baopals team."
  },
  "kids-and-family": {
    title: "Kids & Family",
    description: "Browse our top picks for kids apparel, toys, and all kinds of family fun."
  },
  "special-interest": {
    title: "Special Interest",
    description: "If you've got a hobby, you can find something for it on Baopals."
  },
  "the-world-series": {
    title: "The World Series",
    description: "Take a tour around the world with handpicked items from a variety of countries."
  },
  "the-ccc": {
    title: "The CCC",
    description: "The Cool, the Cheap & the Crazy."
  },
  "more": {
    title: "More from the Blog",
    description: "News, features, business services and more updates from the Baopals team."
  },
  "careers": {
    title: "Careers",
    description: "Job opportunities with the Baopals team."
  },
  "laowai-life": {
    title: "Laowai Life",
    description: "What's it like to be an expat in China?"
  },
  "news-and-features": {
    title: "News & Features",
    description: "The latest news, promotions and features from Baopals."
  },
  "halloween": {
    title: "Halloween",
    description: "Our favorite Halloween costumes, decorations, treats and more."
  },
  "parties-games": {
    title: "Parties & Games",
    description: "The best board games, card games, party gear and more."
  },
  "pets": {
    title: "Pets",
    description: "Quality pet food, pet toys, outfits and more."
  },
  "sexual-health": {
    title: "Sexual Health",
    description: "Our favorite adult toys and other ways to spice up the bedroom."
  },
  "tech": {
    title: "Tech",
    description: "The coolest gadgets, electronics and accessories in China."
  },
}
