import { ProductData, ReviewData, ReviewForm, UserData } from '@interfaces/reviews'
import React, { FC } from 'react'

import Bar from './Bar'
import Details from './Details'
import ReviewPic from './ReviewPic'
import './styles.css'

interface DefaultProps {
  review: ReviewData
  user?: UserData
  product?: ProductData

  handleUpvote: (id: number) => void
  handleDownvote: (id: number) => void

  showUser?: boolean // show user image or product image
  showByUser?: boolean // show 'by user'
  showImages?: boolean // show images / hide images and shorten title & comment
  isTitleLink?: boolean
  showCompleteDetails?: boolean // show complete title & comment

  showEdit?: boolean
  handleUpdate?: (id: number, review: ReviewForm) => void

  className?: string
  styleName?: string

  isWhatHot?: boolean
  imageWidth?: number
}

const ReviewUnit: FC<DefaultProps> = ({ handleUpvote, handleDownvote, showUser, ...props }) => {
  const imageWidth = props.imageWidth ?? 100
  const leftStyle = { width: imageWidth }
  const rightStyle = {
    marginLeft: imageWidth / 4,
    minHeight: (imageWidth / 4) * 6,
    ...(props.isWhatHot && { height: "100%" })
  }
  const rightDivStyle = { paddingLeft: (imageWidth / 4) * 3 }
  const barStyle = { height: imageWidth / 4 }

  return (
    <div className={`relative ${props.isWhatHot ? 'tw-h-full' : props.className || ''}`}>
      <div styleName="image" style={leftStyle}>
        <ReviewPic
          showUser={showUser}
          product={props.product}
          user={props.user}
          enableAffiliate={props.review.boughtOnBaopals}
          reviewId={props.review.id}
        />
      </div>

      <div styleName="right" style={rightStyle}>
        <div style={{ ...rightDivStyle, ...barStyle }}>
          <Bar
            review={props.review}
            user={props.user}
            handleUpvote={() => handleUpvote(props.review.id)}
            handleDownvote={() => handleDownvote(props.review.id)}
          />
        </div>

        <div className="bd-light bg-white" style={rightDivStyle}>
          <Details
            {...props}
            enableAffiliate={props.review.boughtOnBaopals}
            reviewId={props.review.id}
          />
        </div>
      </div>
    </div>
  )
}

ReviewUnit.defaultProps = {
  showUser: false,
}

export default ReviewUnit
