import { useJdCategories } from '@hooks/useJdCategories'
import { fetchJdCategories, fetchJdCategoryDetails } from '@services/category'
import { arrayToNestedChildren } from '@utils'
import { FC, useState } from 'react'
import { Panel, PanelGroup } from 'react-bootstrap'
import AllCategoryToggler from './AllCategoryToggler'
import Banner from './Banner'
import Item from './Item'
import { Redirect as RedirectTo } from 'react-router-dom'

const findRoot = (categories, id) => {
  let category = fetchJdCategoryDetails(id)
  if (!category) return category

  while (category.parent !== 0) {
    category = categories.find(c => c.id === category.parent)
  }

  return category
}

const findChildren = (categories, id) => categories.find(c => c.parent === id)

const findCategories = (categories, id) => {
  const cat = fetchJdCategoryDetails(id)
  if (!cat) return []

  const categoryList = [].concat(cat)

  if (cat.parent === 0) {
    const secCat = findChildren(categories, cat.id)
    categoryList.push(secCat)
    categoryList.push(findChildren(categories, secCat.id))
  } else {
    const root = categories.find(c => c.id === cat.parent)

    if (root.parent === 0) {
      categoryList.unshift(root)
      categoryList.push(findChildren(categories, cat.id))
    } else {
      const secCat = categories.find(c => c.id === cat.parent)
      categoryList.unshift(secCat)
      categoryList.unshift(categories.find(c => c.id === secCat.parent))
    }
  }

  return categoryList
}

export const Menu: FC<{ id: number }> = ({ id, ...props }) => {
  const { categories, nestedCategories } = useJdCategories()
  const [, middle] = findCategories(categories, id)

  const [activeKey, setActiveKey] = useState(middle?.id)

  const category = findRoot(categories, id)

  if (!category) {
    return <RedirectTo to="/404" />
  }

  const currentCategory = nestedCategories.find(c => c.id === category.id)

  const handleSelect = key => {
    if (key) {
      const [, middle] = findCategories(categories, key)
      setActiveKey(middle.id)
    } else {
      setActiveKey(-1)
    }
  }

  const closeMenu = () => props.onMenuStateChange({ isOpen: false })

  return (
    <>
      <AllCategoryToggler onClick={closeMenu} />
      <Banner id={currentCategory.id} />
      <PanelGroup
        accordion={true}
        id="accordion-controlled-example"
        activeKey={activeKey}
        onSelect={handleSelect}
      >
        {currentCategory.children.map(cat => (
          <Item category={cat} key={cat.id} activeId={id} onClick={closeMenu} />
        ))}
      </PanelGroup>
    </>
  )
}

export default Menu

// <CatList
//  renderHeading={<div className='mt-2'>BAOPALS EXPRESS</div>}
//  nestedCategories={nestedCategories}
//  categories={categories}
//  renderLink={cat => `/express/category/${cat.id}`}
//  activeCategory={id}
/// >
