import { useEffect, useState } from 'react'
import { Festival, selectFestival } from '@components/layout/FestivalLink/json'

export const useFestivalLink = () => {
  const [isHalloween, setIsHalloween] = useState(false)
  const [isChristmas, setIsChristmas] = useState(false)
  const data = selectFestival()

  useEffect(() => {
    if(data && data.id === Festival.halloween) {
      setIsHalloween(true)
    }

    if(data && data.id === Festival.christmas) {
      setIsChristmas(true)
    }

    if(!data) {
      setIsHalloween(false)
      setIsChristmas(false)
    }
  }, [data])

  return { isHalloween, isChristmas }
}
