import React from 'react'
import ProgressiveSkeleton from '@components/common/ProgressiveSkeleton'
import PropTypes from 'prop-types'
import './styles.css'

const Item = () => (
  <div styleName="wrapper" className="tw-my-6">
    <div className="row">
      <div className="col-md-8 col-md-offset-2">
        <div className="media">
          <div className="media-left">
            <ProgressiveSkeleton width={90} height={90} />
          </div>
          <div className="media-body">
            <ProgressiveSkeleton width="100%" height={24} enableMoveAnimation={true} />
            <ProgressiveSkeleton width="75%" height={24} enableMoveAnimation={true} />
            <ProgressiveSkeleton width="50%" height={24} enableMoveAnimation={true} />
          </div>
        </div>
      </div>
    </div>
  </div>
)

const ShoppingCartWidgetItemLoading = ({ size }) => (
  <>
    {Array.from({ length: size }, () => 0).map((data, index) => (
      <Item key={`cart-loading-${index}`} />
    ))}
  </>
)

ShoppingCartWidgetItemLoading.defaultProps = {
  size: 1,
}

ShoppingCartWidgetItemLoading.propTypes = {
  size: PropTypes.oneOf([1, 2, 3, 4, 5]),
}

export default ShoppingCartWidgetItemLoading
