import { Platform } from '@interfaces/platform'
import { queryToPlatform } from '@utils/platform'
import { SkuCategory, SkuOption } from '@interfaces/product'
const SKU_SIZE_IDS = [20549]

export const extractSKU = (categories: SkuCategory[], options: SkuOption[]): string =>
  categories
    .map(cat => {
      const opt = options.find(opt => opt.categoryId === cat.id)
      return opt ? `${cat.id}:${opt.id}` : ''
    })
    .join(';')

export const formatSkuOptions = (skuCategory: number, options: any[]) => {
  return SKU_SIZE_IDS.includes(skuCategory)
    ? options.map(opt => {
      try {
        const chineseTitle = (opt.titles.zhCn.match(/^[0-9]+([.]{1}[0-9]+){0,1}$/) || [])[0]
        return {
          ...opt,
          titles: {
            ...opt.titles,
            ...(chineseTitle && { en: chineseTitle, zhCn: chineseTitle }),
          },
        }
      } catch (err) {
        return { ...opt }
      }
    })
    : options.map(opt => {
      const pattern = /^\d+-\d+\s*.+yards$/gi

      return {
        ...opt,
        titles: {
          ...opt.titles,
          ...(pattern.test(opt.titles.en ?? '') && { en: opt.titles.en.replace(/\s.*yards/gi, '') })
        }
      }

    })
}

export const normalizeSku = (str = '') => str.split(',').join(';')

export const getSingleOptionCategory = (skuCategories, skuOptions) => {
  return skuCategories
    .filter(c => c.optionsCount === 1)
    .map(c => skuOptions.find(o => o.categoryId === c.id))
}

export const skuToIds = (s = '') => s.split(';').map(str => str.split(':').map(v => +v))

export const skuOptionToSkuId = (skuOption: SkuOption) => `${skuOption.categoryId}:${skuOption.id}`

export const getSelectedOptions = (skus, skuCategories, skuOptions, initial) => {
  const singleOptionCategory = getSingleOptionCategory(skuCategories, skuOptions)

  if (!initial) {
    return singleOptionCategory
  }

  const isInitialSkuInSingleOptionCategory = singleOptionCategory.some(
    c => skuOptionToSkuId(c) === initial
  )

  if (isInitialSkuInSingleOptionCategory) {
    return singleOptionCategory
  }

  const initialSku = skus.find(s => s.id.includes(initial))

  if (!initialSku) {
    return singleOptionCategory
  }

  const ids = skuToIds(initialSku.id).filter(
    ([catId, optId]) => !singleOptionCategory.find(c => c.categoryId === catId && c.id === optId)
  )

  const selected = ids.map(([catId, optId]) =>
    skuOptions.find(o => o.categoryId === catId && o.id === optId)
  )

  return selected.concat(singleOptionCategory)
}
