import * as constants from '../constants/departmentConstants'
import { sampleSize } from 'lodash'

const initialState = {
  department: null,
  categories: [],
  links: [],
  featuredSellers: [],
  popularProducts: [],
  blogArticles: []
}

const departmentReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case constants.FETCH_REQUEST:
      return { ...initialState }
    case constants.FETCH_DONE:
      return { ...state, ...payload, blogArticles: sampleSize(payload.blogArticles, 5) }
    case constants.FETCH_FEATURED_SELLERS_DONE:
      return { ...state, ...payload }
    case constants.FETCH_POPULAR_PRODUCTS_DONE:
      return { ...state, ...payload, popularProducts: payload.featuredProducts }
    default:
      return state
  }
}

export default departmentReducer
