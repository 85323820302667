import { ofType } from 'redux-observable'
import { tap, mapTo, filter, mergeMap, catchError, map, switchMap } from 'rxjs/operators'
import { from, empty, pipe, forkJoin, concat, of } from 'rxjs'
import * as MY_COLLECTIONS_ACTION from '@constants/myCollection'
import * as COLLECTION_ITEMS_ACTION from '@constants/collectionItems'

import * as MyCollectionsActionCreator from '@actions/myCollection'
import * as MyCollectionItemsActionCreator from '@actions/collectionItems'
import * as ToastActionCreator from '@actions/toasts'

export const updateCollectionsPosition = (action$, state$, { api }) =>
  action$.pipe(
    ofType(MY_COLLECTIONS_ACTION.UPDATE_COLLECTION_POSITION_REQUEST),
    map(() => state$.value.myCollection),
    map(({ data }) => data),
    map(data => data.map(d => d.id)),
    mergeMap(ids =>
      api.updateCollectionsPosition(ids).pipe(
        map(data => MyCollectionsActionCreator.updateCollectionPositionSuccess(data)),
        catchError(err => [
          ToastActionCreator.showUnableProcessingToast(),
          MyCollectionsActionCreator.updateCollectionPositionFailed(err),
        ])
      )
    )
  )

export const updateCollectionItemsPosition = (action$, state$, { api }) =>
  action$.pipe(
    ofType(COLLECTION_ITEMS_ACTION.UPDATE_COLLECTION_ITEMS_POSITION_REQUEST),
    mergeMap(({ payload }) =>
      api
        .updateCollectionItemsPosition(payload.id, state$.value.collectionList.items.map(i => i.id))
        .pipe(
          map(data => MyCollectionItemsActionCreator.updateItemsPositionSucess(data)),
          catchError(err => [
            ToastActionCreator.showUnableProcessingToast(),
            MyCollectionItemsActionCreator.updateItemsPositionFailed(err),
          ])
        )
    )
  )

export const myCollections = (action$, state$, { api }) =>
  action$.pipe(
    ofType(MY_COLLECTIONS_ACTION.FETCH_MY_COLLECTIONS_REQUEST),
    tap(data => api.AddedCollectionService.next([])),
    switchMap(({ payload }) =>
      api.fetchMyCollections(payload.product).pipe(
        tap(data => api.AddedCollectionService.next(data.addedTo)),
        map(data => MyCollectionsActionCreator.fetchMyCollectionsSuccess(data.collections)),
        catchError(err => of(MyCollectionsActionCreator.fetchMyCollectionsFailed(err)))
      )
    )
  )

export const createCollection = (action$, state$, { api }) =>
  action$.pipe(
    ofType(MY_COLLECTIONS_ACTION.CREATE_COLLECTION_REQUEST),
    switchMap(({ payload }) =>
      api.createCollection(payload.data).pipe(
        map(collection => MyCollectionsActionCreator.createCollectionSuccess(collection)),
        catchError(err => [
          // ToastActionCreator.showUnableProcessingToast(),
          MyCollectionsActionCreator.createCollectionFailed(err),
        ])
      )
    )
  )

export const addProductToCollection = (action$, state$, { api }) =>
  action$.pipe(
    ofType(MY_COLLECTIONS_ACTION.ADD_PRODUCT_TO_COLLECTION_REQUEST),
    switchMap(({ payload }) =>
      api.addProductToCollection(payload.id, payload.product).pipe(
        map(resp => MyCollectionsActionCreator.addProductToCollectionSuccess(resp)),
        catchError(err => [
          // ToastActionCreator.showUnableProcessingToast(),
          MyCollectionsActionCreator.addProductToCollectionFailed(err),
        ])
      )
    )
  )

export const updateCollection = (action$, state$, { api }) =>
  action$.pipe(
    ofType(MY_COLLECTIONS_ACTION.UPDATE_COLLECTION_REQUEST),
    map(data => {
      const { payload } = data
      const collection = state$.value.myCollection.data.find(d => d.id === payload.id)

      return {
        id: payload.id,
        collection: payload.collection,
      }
    }),
    switchMap(({ id, collection, ...payload }) =>
      api.updateCollection(id, collection).pipe(
        switchMap(collection => {
          return from(
            new Promise(resolve => {
              if (collection.picture) {
                const image = new Image()
                image.onload = () => resolve(collection)
                image.src = collection.picture
              } else {
                resolve(collection)
              }
            })
          )
        }),
        map(collection => MyCollectionsActionCreator.updateCollectionSuccess(collection)),
        catchError(err => [
          ToastActionCreator.showUnableProcessingToast(),
          MyCollectionsActionCreator.updateCollectionFailed(err),
        ])
      )
    )
  )

export const fetchMyCollectionDetails = (action$, state$, { api }) =>
  action$.pipe(
    ofType(MY_COLLECTIONS_ACTION.FETCH_MY_SINGLE_COLLECTION_REQUEST),
    switchMap(({ payload }) =>
      api.fetchCollectionDetails(payload.username, payload.slug, true).pipe(
        mergeMap(({ collection, items }) =>
          concat(
            of(
              MyCollectionsActionCreator.fetchMyCollectionDetailsSuccess({
                ...collection,
              })
            ),
            of(MyCollectionItemsActionCreator.fetchCollectionItemsSuccess(items))
            //of(MyCollectionsActionCreator.fetchCollectionTagsSuccess(tags)),
          )
        ),
        catchError(err => of(MyCollectionsActionCreator.fetchMyCollectionDetailsFailed(err)))
      )
    )
  )

export const deleteCollection = (action$, state$, { api }) =>
  action$.pipe(
    ofType(MY_COLLECTIONS_ACTION.DELETE_COLLECTION_REQUEST),
    switchMap(({ payload }) =>
      api.deleteCollection(payload.id).pipe(
        map(() => MyCollectionsActionCreator.deleteCollectionSuccess(payload.id)),
        catchError(err => [
          ToastActionCreator.showUnableProcessingToast(),
          MyCollectionsActionCreator.deleteCollectionFailed(err),
        ])
      )
    )
  )

export const deleteCollectionItem = (action$, state$, { api }) =>
  action$.pipe(
    ofType(COLLECTION_ITEMS_ACTION.DELETE_COLLECTION_ITEM_REQUEST),
    mergeMap(({ payload }) =>
      api.deleteCollectionItem(payload.id).pipe(
        map(() => MyCollectionItemsActionCreator.deleteItemSuccess(payload.id)),
        catchError(err => [
          ToastActionCreator.showUnableProcessingToast(),
          MyCollectionItemsActionCreator.deleteItemFailed(err),
        ])
      )
    )
  )

export const fetchFavorites = (action$, state$, { api }) =>
  action$.pipe(
    ofType(MY_COLLECTIONS_ACTION.FETCH_FAVORITES_REQUSET),
    switchMap(({ payload }) =>
      api.fetchFavorites(payload.page, payload.sort).pipe(
        map(result =>
          MyCollectionsActionCreator.fetchFavoritesSuccess(
            result.data.collections,
            result.data.users,
            result.meta
          )
        ),
        catchError(err => [
          ToastActionCreator.showUnableProcessingToast(),
          MyCollectionsActionCreator.fetchFavoritesFailed(err),
        ])
      )
    )
  )

export const updateCollectionItem = (action$, state$, { api }) =>
  action$.pipe(
    ofType(COLLECTION_ITEMS_ACTION.UPDATE_COLLECTION_ITEM_REQUEST),
    switchMap(({ payload }) =>
      api.updateCollectionItem(payload.id, payload.data).pipe(
        map(data => MyCollectionItemsActionCreator.updateCollectionItemSuccess(data)),
        catchError(err => [
          ToastActionCreator.showUnableProcessingToast(),
          MyCollectionItemsActionCreator.updateCollectionItemFailed(err),
        ])
      )
    )
  )
