export interface Titles {
  ru?: string
  ko?: string
  en?: string
  zhCn: string
}

export interface Meta {
  totalCount: number
  totalPages: number
  currentPage: number
}

export interface PageQuery {
  page?: number
  per?: number
}

export interface DateQuery {
  from?: string
  to?: string
}

export const initMeta: Meta = {
  currentPage: 0,
  totalCount: 0,
  totalPages: 0,
}
