import React, { Component } from 'react'
import { Scrollbars  }      from 'react-custom-scrollbars'
import PropTypes            from 'prop-types'
import { createPortal } from 'react-dom'
import './styles.css'

class FullScreenModal extends Component {
  render() {
    const { open, onClose } = this.props

    return createPortal(
      <div styleName={`wrapper ${open ? 'open' : ''}`} style={{...this.props.containerStyle}} className={this.props.className}>
        <Scrollbars renderTrackHorizontal={props => <div {...props} style={{display: 'none'}} className="track-horizontal"/>}>
          <div className='d-flex' styleName='body'>
            <div styleName='inner' className='modal-inner'>
              {this.props.children}
            </div>
            <div styleName='footer' style={{...this.props.footerStyle}}>
              <i onClick={onClose} className='icon-close'></i>
            </div>
          </div>
        </Scrollbars>
      </div>,
    document.querySelector('#fullscreen-modal-container'))
  }
}

FullScreenModal.defaultProps = {
  className: '',
  containerStyle: {},
  footerStyle: {}
}

export default FullScreenModal
