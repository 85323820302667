import React from 'react'

const Avatar = ({ url }: { url: string }) => (
  <div
    style={{
      width: "80px",
      height: "80px",
      borderRadius: "50%",
      margin: "-10px auto 14px",
      overflow: "hidden"
    }}
  >
    <img src={url.replace(/^http:\/\//i, 'https://')} />
  </div>
)

export default Avatar
