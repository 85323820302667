import ProductLink from '@components/common/ProductLink'
import TranslationText from '@components/common/TranslationText'
import React, { FC, useState } from 'react'
import './styles.css'
import { useLocation } from '@hooks/useRouter'

export const Title = props => {
  const {
    location: { pathname },
  } = useLocation()

  return (
    <ProductLink
      id={props.id}
      className="strong d-block tw-z-10"
      styleName="link"
      platform={props.platform}
      skuId={props.skuId}
      affiliateToken={props.affiliateToken}
      affiliateVia={props.affiliateVia}
    >
      <div styleName="title" className="product-card-title tw-bottom-0" data-show={pathname.includes('/seller')}>
        <div className="line-max line-max-2 text-capitalize">{props.titles?.en}</div>
      </div>
    </ProductLink>
  )
}
