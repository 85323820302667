import React     from 'react'
import PropTypes from 'prop-types'
import './styles.css'

type DottedLineProps = {
  styleName?: string;
  className?: string;
}

const DottedLine: React.SFC<DottedLineProps> = ({ className }) => <div className={`${className} dotted-line`} styleName='dotted'></div>

DottedLine.defaultProps = {
  styleName: '',
  className: ''
}

export default DottedLine
