import React from 'react'

const Link = ({ url, icon, ...props }: { url: string; icon: string }) => (
  <a
    className="black-link d-block"
    {...url && { href: url }}
    target="_blank"
    rel="noopener"
    {...props}
  >
    <i className={`${icon} fa-2x`} />
  </a>
)

export default Link
