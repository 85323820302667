import DottedLine from '@components/common/DottedLine'
import Pluralize from '@components/common/Pluralize'
import React, {FC} from 'react'
import Location from './Location'
import './styles.css'
import Suggestions from './Suggestions'

interface Props {
  totalProducts?: number
  totalReviews?: number
  alias: string
  onSuggestionLoad: any
}

const ResultText: FC<Props> = ({totalProducts, totalReviews, alias, onSuggestionLoad}) => {
  return (
    <>
      <div className="container tw-py-4">
        <span>
          We found {(totalProducts || 0).toLocaleString()} products{' '}
          {totalReviews > 0 && (
            <span styleName="reviews" data-reviews-count={totalReviews}>
              and <Pluralize showCount={true} count={totalReviews} singular="review" />{' '}
            </span>
          )}{' '}
          for "{alias}"
        </span>

        <Location />
      </div>

      <DottedLine />

      <Suggestions alias={alias} onSuggestionLoad={onSuggestionLoad} />
    </>
  )
}

export default ResultText
