import { CategoryData } from '@interfaces/blog'
import React from 'react'
import { sortBy } from 'lodash'
import { NavLink } from 'react-router-dom'
import Dropdown from '../Dropdown'
import './styles.css'

interface Props {
  categories: CategoryData[]
}

const Filters: React.FC<Props> = ({ categories }) => {
  const mainCategories = sortBy(categories.filter(c => !c.parentId), 'position')

  return (
    <div
      className="d-flex justify-content-between text-uppercase align-items-center"
      styleName="dropdown"
    >
      {mainCategories.map(c => {
        const subCategories = categories.filter(s => s.parentId === c.id)

        return (
          <React.Fragment key={c.id}>
            {!subCategories.length ? (
              <NavLink to={`/blog?cat=${c.slug}`} className="black-link">
                {c.title}
              </NavLink>
            ) : (
              <Dropdown
                options={subCategories.map(opt => ({
                  title: opt.title,
                  url: `/blog?cat=${opt.slug}`,
                }))}
                key={c.slug}
                category={c}
              />
            )}
          </React.Fragment>
        )
      })}
    </div>
  )
}

export default Filters
