import React from 'react'
import { Default, Mobile } from '@components/common/Responsive'

const Sku = ({ sku }) => {
  const filteredSkus = sku.filter(s => s.category.en && s.option.en)
  if (!filteredSkus.length) return null

  return (
    <>
      {filteredSkus.map((s, index) => (
        <div className="text-grey text-capitalize" key={index}>
          {s.category.en}: {s.option.en}
        </div>
      ))}
    </>
  )
}

export default Sku
