import { useCurrentReview } from '@hooks/useReviews'
import { Platform } from '@interfaces/platform'
import React, { FC } from 'react'
import Button from './Button'

const ReviewButton: FC<{ platform: Platform }> = props => {
  // can create or edit itself
  if (
    (props.productId && typeof props.handleCreate == 'function') ||
    (props.review && typeof props.handleUpdate == 'function')
  ) {
    return <Button {...props}>{props.children}</Button>
  }

  // pass current review in hooks to get the func & data
  const { review, createReview, updateReview } = useCurrentReview(props.review)

  const rest = {
    loading: false,
    productId: review ? review.productId : props.productId,
    review,
    handleCreate: createReview,
    handleUpdate: updateReview,
    platform: props.platform,
  }

  const { children: fn } = props

  return (
    <Button {...props} {...rest}>
      {typeof fn !== 'function' ? null : fn({ review })}
    </Button>
  )
}

export default ReviewButton
