import React from 'react'
import { Link } from 'react-router-dom'
import { totalPrice } from '../../../../../utils/product/priceUtils'

import PriceWithCurrency from '@components/common/PriceWithCurrency'

import '../styles.css'
import TranslationText from '@components/common/TranslationText'
import { cloudObjectToURL } from '@utils/'
import { toProductLinkStr } from '@components/common/ProductLink'

const Product = ({ product, currentLocale, translations, preventClickOnDragging }) => {
  const preventDefault = e => e.preventDefault()
  const promotion = product.promotion && product.promotion.minPrice < product.minPrice
  const coverUrl = cloudObjectToURL(product.cover, { size: '200x200' })

  return (
    <div className="product-slide-container">
      <Link
        className="slider-product"
        to={toProductLinkStr(product.id, { platform: product.platform || product.platformType })}
        onDragStart={preventDefault}
        onClick={preventClickOnDragging}
      >
        <div className="slider-product__image">
          <img className="img-responsive" src={coverUrl} />
          <div className="slider-product__overlay">
            <span className="slider-product__overlay__title px-3 text-left">
              {/*<TranslationText texts={product.titles} />*/}
              {product.titles?.en}
            </span>
          </div>
        </div>
        <span className="product-block__price">
          <PriceWithCurrency
            price={totalPrice(promotion ? product.promotion.minPrice : product.minPrice <= 0 ? product.maxPrice : product.minPrice )}
          />
        </span>
        {promotion && (
          <del className="product-block__discount">
            <PriceWithCurrency price={totalPrice(product.minPrice)} />
          </del>
        )}
      </Link>
    </div>
  )
}

export default Product
