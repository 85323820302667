import * as ACTIONS from '@actions/selectedAddress'
import * as ActionCreator from '@actions/warehouse'
import { useActions } from '@hooks/useActions'
import { useDispatch, useSelector } from 'react-redux'

export const useSelectedAddr = () => {
  const dispatch = useDispatch()
  const state = useSelector(state => state.selectedAddr)

  const actions = useActions(ACTIONS)

  return {
    id: state,
    updateSelectedAddress: actions.updateSelectedAddress,
  }
}
