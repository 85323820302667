import UrlQuery from '@components/common/UrlQuery'
import { DEFAULT_DATE_FORMAT } from '@constants/date'
import { format, subMonths } from 'date-fns'
import parse from 'date-fns/parse'
import React, { FC, ReactNode } from 'react'

const today = new Date()
const todayStr = format(today, DEFAULT_DATE_FORMAT)

const LastNMonth: FC<{
  pathname?: string
  className?: string
  activeClassName?: strong
  month: number
  onClick?: (url: string) => void
  children?: (isActive: boolean, ts: string) => ReactNode
  exact?: boolean
}> = ({ month, ...props }) => {
  const dt = format(subMonths(today, month), DEFAULT_DATE_FORMAT)

  const tsArr = [format(parse(dt, DEFAULT_DATE_FORMAT, new Date()), DEFAULT_DATE_FORMAT), todayStr]
  const tsStr = tsArr.join('-')

  return (
    <UrlQuery
      names={['ts', 'page']}
      values={{ ...(tsArr[0] !== tsArr[1] && { ts: tsStr }) }}
      className={props.className}
      activeClassName={props.activeClassName}
      pathname={props.pathname}
      exact={props.exact}
      {...props.onClick && { onClick: props.onClick }}
    >
      {props.children || (
        <>
          <div className="radio visible-xs">
            <label>
              <input type="radio" name="sort" checked={props.value === tsStr} onChange={() => {}} />
              {`LAST ${month} MONTHS`}
            </label>
          </div>
          <div className="hidden-xs">{`LAST ${month} MONTHS`}</div>
        </>
      )}
    </UrlQuery>
  )
}

export default LastNMonth
