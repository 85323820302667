import { fileToBlob } from '@utils/'
import React from 'react'
import './styles.css'

interface ImageProps {
  file: File
  blob: Blob

  renderClose: React.ReactNode
}

const Image: React.FC<ImageProps> = props => {
  return (
    <div className="d-inline-block relative bd-light">
      <img styleName="img" className="obj-fit-cover" src={formatImage(props.file)} />
      {props.renderClose}
    </div>
  )
}

const formatImage = (file: string | File) => {
  if (typeof file === 'string') { return file }
  if (file instanceof File) { return fileToBlob(file) }
}

export default Image
