/**
 *
 * returns a given option's category
 *
 * @param option (SkuOption)
 * @param categories (Array[SkuCategory])
 *
 * @return {SkuCategory}
 */

export const getCategory = (option, categories) => {
  return categories.find(c => c.id == option.categoryId)
}

/**
 *
 * transforms a set of options into a sku id
 *
 * @param options (Product/SkuOption)
 * @returns {string}
 */
export const composeSkuKey = options => {
  const sortedOptions = options.sort(compareCategoryId)
  // "typeId1:optionId1;typeId2:optionId2; ..."
  return sortedOptions.map(toSku).join(';')
}

/**
 *
 * returns the sku id corresponding to a given option
 *
 * @param option (Product/SkuOption)
 * @returns {string}
 */
export const toSku = option => idsToSku(option.categoryId, option.id)

// Private

// transforms option ids into sku
const idsToSku = (categoryId, optionId) => [categoryId, optionId].join(':')

// compare 2 options based on their category id
const compareCategoryId = (o1, o2) => Math.sign(o1.categoryId - o2.categoryId)
