import React, { Component } from 'react'
import { Mobile } from '../../common/Responsive'
import Toggler from './Toggler'
import CategoryBurgerMenu from '../CategoryBurgerMenu'

class CategoryToggler extends Component {
  constructor(props) {
    super(props)

    this.state = {
      open: false,
    }
  }

  onToggle = () => {
    this.setState({ open: !this.state.open })
  }

  onMenuStateChange = ({ isOpen }) => {
    this.setState({ open: isOpen })
  }

  render() {
    const { open } = this.state

    return (
      <Mobile>
        <Toggler onToggle={this.onToggle} />
        <CategoryBurgerMenu
          open={open}
          onMenuStateChange={this.onMenuStateChange}
          renderMenu={this.props.renderMenu}
        />
      </Mobile>
    )
  }
}

export default CategoryToggler
