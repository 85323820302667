import * as constants from '../constants/departmentConstants'

const initialState = {
  sections: [],
  departments: [],
  categories: [],
  loading: true,
}

export const departmentsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case constants.FETCH_ALL_DONE:
      return { ...state, ...payload, loading: false }
    default:
      return state
  }
}
