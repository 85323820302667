import { formatSkuOptions } from '@utils/product'
import { getCategory } from '../../../utils/product/skuOptionUtils'
import TranslationText from '@components/common/TranslationText'
import React from "react";

const SkuOption = ({ skuOption, skuCategories }) => {
  const skuCategory = getCategory(skuOption, skuCategories)
  const [formattedOption] = formatSkuOptions(skuCategory.id, [skuOption])

  return (
    <li className="d-flex">
      <div>-</div>
      <div className="text-left text-capitalize tw-ml-1">
        <TranslationText texts={skuCategory.titles} />: <span><TranslationText texts={formattedOption.titles} /></span>
      </div>
    </li>
  )
}

export default SkuOption
