import { Auth } from '@interfaces/auth'
import React, { FC } from 'react'
import { Col, Row } from 'react-bootstrap'
import Form from './Form'
import { useMyCountry } from '@hooks/useAuth'
import { useWelcomeCoupon } from '@hooks/useWelcomeCoupon'
import { formatCouponOptions, formatCurrency } from '@helpers/currencyHelper'
import './styles.css'

interface SignUpFormProps {
  username?: string
  avatar?: string
  auth?: Auth
}

const SignUpForm: FC<SignUpFormProps> = props => {
  const { isInternational } = useMyCountry()
  const {
    welcomeCoupon: { discount, currencyLocale, currency },
  } = useWelcomeCoupon()

  return (
    <>
      <Row styleName="signup_row" data-global={isInternational} data-inModal={props.inModal}>
        <Col md={10} mdOffset={1} styleName="signup">
          <Col lg={6} lgOffset={3} md={8} mdOffset={2} styleName="signup_wrapper">
            <div className="bg-orange text-center tw-p-2 bold white-text fs16">
              Sign up and get{' '}
              <span className="text-blue">
                {formatCurrency(discount, currencyLocale, currency, formatCouponOptions)}
              </span>{' '}
              off your first order.
            </div>
            <div className="signup" id="signup-error">
              <h1
                className="signup__header big-header bodo"
                styleName="signup-header"
              >
                Join Baopals to start shopping
              </h1>
              <Form onSubmitted={props.onSubmitted} />
            </div>
          </Col>
        </Col>
      </Row>
    </>
  )
}

// Tmp disable wechat signup
// auth={props.auth}
// username={props.username}
// avatar={props.avatar}

export default SignUpForm
