import { useLocation } from '@hooks/useRouter'
import { Platform } from '@interfaces/platform'
import { FormattedMessage } from 'react-intl'
import Button from '../Button'

const BuyNowBtn = ({ platform, onClick, supermarket, ...props }) => {
  const { navigate } = useLocation()
  const handleClick = () =>
    onClick().then(() => navigate(`/checkout/${platform === Platform.JD ? 'express' : 'taobao'}`))

  return (
    <Button
      onClick={handleClick}
      className={`btn-block ${supermarket ? 'btn-bp-fresh' : 'btn-bp-orange'}`}
      {...props}
    >
      <FormattedMessage id="product_page.buy_now" defaultMessage="BUY NOW" />
    </Button>
  )
}

export default BuyNowBtn
