import React from 'react'
import Unit from './Unit'
import './styles.css'
import Pluralize from '@components/common/Pluralize'

type DashboardNumsProps = {
  styleName?: string
  className?: string
  review?: number
  collections?: number
  sellers?: number
  children?: React.ReactChild
}

const DashboardNums: React.SFC<DashboardNumsProps> = props => {
  return (
    <ul className={`${props.className} d-flex tw-mt-4 tw-mb-0 list-unstyled`} styleName="wrapper">
      {props.children ? (
        props.children
      ) : (
        <>
          <div className="tw-mx-8">
            <Unit
              label={<Pluralize count={props.review} singular="Review" />}
              value={props.review}
            />
          </div>
          <div className="tw-mx-8">
            <Unit
              label={<Pluralize count={props.sellers} singular="Shop" />}
              value={props.sellers}
            />
          </div>
          <div className="tw-mx-8">
            <Unit
              label={<Pluralize count={props.collections} singular="WISHLIST" />}
              value={props.collections}
            />
          </div>
        </>
      )}
    </ul>
  )
}

DashboardNums.defaultProps = {
  className: '',
  review: 0,
  collections: 0,
}

export default DashboardNums
