import ProductLink from '@components/common/ProductLink'
import { Titles } from '@interfaces/common'
import { Platform } from '@interfaces/platform'
import React, { FC } from 'react'
import Footer from '@components/common/ProductCard/Footer'
import Seller from '@components/common/ProductCard/Seller'
import { Image } from './Image'
import { Title } from './Title'
import './styles.css'
import { Default, Mobile } from '@components/common/Responsive'

interface ProductCardProps {
  id: string
  sellerId: number

  originalPrice: number
  discountPrice?: number

  cover: string
  thumbnails: string[]

  location: Titles[]

  sold?: number
  titles: Titles

  platform: Platform

  skuId?: number

  affiliateToken?: string
  affiliateVia?: string
}

const ProductCard: FC<ProductCardProps> = React.memo(props => {
  const renderTitle = (
    <Title
      id={props.id}
      titles={props.titles}
      platform={props.platform}
      skuId={props.skuId}
      affiliateToken={props.affiliateToken}
      affiliateVia={props.affiliateVia}
    />
  )

  // const imgList = props.cover?.id ? [props.cover, ...props.thumbnails.filter((t) => t.id !== props.cover.id)] : props.thumbnails
  const getImgList = () => {
    const imgList = props.cover?.id ? [props.cover, ...props.thumbnails.filter((t) => t.id !== props.cover.id)] : props.thumbnails

    if (typeof props.defaultThumbnailIdx === "number" && props.defaultThumbnailIdx >= 0) {
      if (props.hasContentCover) {
        return imgList
      } else {
        const cover = props.thumbnails[props.defaultThumbnailIdx]
        if (cover) {
          return [cover, ...props.thumbnails.filter((t) => t.id !== cover.id)]
        } else {
          return imgList
        }
      }
    } else {
      return imgList
    }
  }

  return (
    <div
      styleName="wrapper"
      className="relative"
      data-disabled={!!props.unavailable}
    >
      <Default>
        <Image images={getImgList()}>{renderTitle}</Image>
      </Default>
      <Mobile>
        <Image images={getImgList()}>{renderTitle}</Image>
      </Mobile>

      <ProductLink
        className="d-block bd-x-light relative"
        styleName="inner"
        id={props.id}
        platform={props.platform}
        title=""
        skuId={props.skuId}
        affiliateToken={props.affiliateToken}
        affiliateVia={props.affiliateVia}
      />

      <Seller id={props.sellerId} platform={props.platform} />
      <Footer {...props} unavailable={props.unavailable} hideDiscount={props.hideDiscount} />
    </div>
  )
})

ProductCard.defaultProps = {
  sold: 0,
}

export default ProductCard
