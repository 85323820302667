import { listViewLayoutAtom } from 'atoms/list-view-layout'
import { useAtom } from 'jotai'
import React, { PureComponent } from 'react'

const LayoutToggler = props => {
  const [column, setColumn] = useAtom(listViewLayoutAtom)

  return (
    <ul className="list-inline tw-mb-0 list-unstyled">
      <li>
        <i
          className={`bp-grey fs18 icon-mobile_view2Col ${column === 2 ? 'active' : ''}`}
          onClick={() => setColumn(2)}
        />
      </li>
      <li>
        <i
          className={`bp-grey fs18 icon-mobile_viewSingle ${column === 1 ? 'active' : ''}`}
          onClick={() => setColumn(1)}
        />
      </li>
    </ul>
  )
}

export default LayoutToggler

// class LayoutToggler extends PureComponent {
//   constructor(props) {
//     super(props)
//
//     this.state = {
//       column: props.defaultColumn
//     }
//
//     this.changeColumn = this.changeColumn.bind(this)
//   }
//
//   componentDidMount() {
//     if (localStorage.getItem('multiColumn') === 'false') {
//       this.changeColumn(1)
//     }
//   }
//
//   changeColumn(column) {
//     this.setState({ column }, () => {
//       localStorage.setItem('multiColumn', column === 2)
//       this.props.onChange && this.props.onChange({ column })
//     })
//   }
//
//   render() {
//     const { column } = this.state
//
//   }
// }
//
// LayoutToggler.defaultProps = {
//   defaultColumn: 2
// }
//
// export default LayoutToggler
