import ReviewButton from '@components/review/ReviewButton'
import { ProductData, Rating, ReviewData, ReviewForm, UserData } from '@interfaces/reviews'
import { groupBy } from '@utils'
import React, { FC, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import BtnWithAuthRequired from '@components/common/BtnWithAuthRequired'
import useAuth from '@hooks/useAuth'
import { Platform } from '@interfaces/platform'
import Empty from '../Empty'
import Filters from '../Filters'
import Review from './Review'
import './styles.css'
import DottedLine from '@components/common/DottedLine'

interface DefaultProps {
  productId: number
  loading: boolean
  buyCount: number
  totalCount: number
  reviews: ReviewData[]
  users?: UserData[]
  products?: ProductData[]
  handleUpvote: (id: number) => void
  handleDownvote: (id: number) => void
  handleCreate: (id: number, review: ReviewForm, platform: Platform) => void
  handleUpdate: (id: number, review: ReviewForm) => void

  platform: Platform

  user: any
}

interface SqProps {
  status?: Rating
  withPictures?: boolean
}

const ReviewWrapper: FC<DefaultProps> = ({ reviews, users, products, ...props }) => {
  const [sq, setSq] = useState<SqProps>({})
  const filteredReviews = getReviews(reviews, sq)

  const { buy = [], pass = [] } = groupBy(reviews, 'rating')
  const { user } = useAuth()

  const currentUserReview = user && reviews.find(r => r.userId === user.id || r.isOwner)

  return (
    <div>
      <div className="tw-w-max mx-auto tw-my-12">
        <ReviewButton
          platform={props.platform}
          productId={props.productId}
          review={currentUserReview || {}}
          handleCreate={props.handleCreate}
          handleUpdate={props.handleUpdate}
          categories={props.categories}
          product={props.product}
        >
          <BtnWithAuthRequired className="product-tab__button btn btn-white">
            {currentUserReview ? (
              <FormattedMessage id="review_form.btn_edit" />
            ) : (
              <FormattedMessage id="review_form.btn_create" defaultMessage="Leave a review" />
            )}
          </BtnWithAuthRequired>
        </ReviewButton>
      </div>
      {reviews.length > 0 && (
        <>
          <div className="bd-light tw-p-3">
            <Filters
              sq={sq}
              onFilterChange={(newSq: SqProps) => setSq({ ...sq, ...newSq })}
              positiveCount={props.buyCount}
              negativeCount={props.totalCount - props.buyCount}
            />

            {filteredReviews.length > 0 ? (
              <div styleName="wrapper">
                {filteredReviews.map((review, index) => (
                  <Review
                    key={index}
                    loading={props.loading}
                    review={review}
                    user={!!review.userId && users.find(u => u.id === review.userId)}
                    product={!!review.productId && products.find(p => p.id === review.productId)}
                    handleUpvote={props.handleUpvote}
                    handleDownvote={props.handleDownvote}
                  />
                ))}
              </div>
            ) : (
              <Empty>
                <FormattedMessage id="review_form.no_reviews-filter" />
              </Empty>
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default ReviewWrapper

export const getReviews = (data: ReviewData[], sq: SqProps) => {
  const reviews = data.filter(
    review =>
      (sq.withPictures ? review.pictures.length > 0 : true) &&
      (sq.status ? sq.status === review.rating : true)
  )

  return reviews
}
