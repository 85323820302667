import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { withRouter }                  from 'react-router'
import ProductFilter from '../ProductFilter'

const ProductBrandFilter = ({brands, onFilterChange}) => {
  return (
    <ProductFilter
      title='Brand' 
      type='brand'
      items={brands}
      onFilterChange={onFilterChange}
    />
  )
}

export default withRouter(ProductBrandFilter)
