/**
 *
 * confine a number into a range
 *
 * ex:  confine(3, 2, 5) //=> 3
 *      confine(1, 2, 3) //=> 2
 *      confine(4, 1, 2) //=> 2
 *
 * @param number - The number to confine
 * @param min - Range minimum value
 * @param max - Range maximum value
 * @returns {number}
 *
 */
export const confine = (number, min, max) => {
  return Math.min(max, Math.max(min, number));
};
