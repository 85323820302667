import { useLanguage } from '@hooks/useLanguage'
import { Titles } from '@interfaces/common'
import { translate } from '@services/translation'
import React, { FC, useEffect, useState } from 'react'
import { Subscription } from 'rxjs'
import ProgressiveSkeleton from '../../common/ProgressiveSkeleton'

interface TranslationTextProps {
  sl?: string
  width?: number
  height?: number
  texts: Titles
  chatGptOpts: any
}

const TranslationText: FC<TranslationTextProps> = props => {
  const [text, setText] = useState('')

  const { lang, langKey } = useLanguage()

  useEffect(() => {
    const sub = new Subscription()
    if (props.texts[langKey]) {
      setText(props.texts[langKey])
    } else if (props.texts.zhCn) {
      sub.add(
        translate(props.texts.zhCn, lang, props.sl, props.chatGptOpts).subscribe(text =>
          setText(text)
        )
      )
    }

    return () => sub.unsubscribe()
  }, [lang].concat(props.texts ? props.texts.zhCn : []))

  const { width, height, children: fn } = props

  const loading = <ProgressiveSkeleton width={width} height={height} className="tw-mt-0" />

  if (typeof fn === 'function') {
    return fn(text, loading)
  }

  return !text ? loading : <>{text}</>
}

TranslationText.defaultProps = {
  sl: 'zh-CN',
  width: 40,
  height: 8,
}

export default TranslationText
