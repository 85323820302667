import { mapTo, map, pluck } from 'rxjs/operators'
import ajax from '../api/ajax'
import { of, throwError, Subject, BehaviorSubject } from 'rxjs'
import qs from 'qs'
import { cloudObjectToURL } from '@utils/'
import { sortByDate } from '@utils/array'

export const collectionCover = new BehaviorSubject({ loading: false })
export const AddedCollectionService = new BehaviorSubject([])
export const mobileCollectionProductPic = new BehaviorSubject({ pic: null })

export const deleteCollection = id =>
  ajax({
    url: `/me/collections/${id}`,
    method: 'DELETE',
  }).pipe(pluck('response'))

export const deleteCollectionItem = id =>
  ajax({
    url: `/me/collections/items/${id}`,
    method: 'DELETE',
  }).pipe(pluck('response'))

export const fetchCollectionDetails = (username, slug, isMe = false) =>
  ajax({
    url: isMe ? `/me/collections/by_slug?slug=${slug}` : `/users/${username}/collections/${slug}`,
  }).pipe(
    pluck('response', 'data'),
    map(({ author, collection, items }) => ({
      collection: formatCollection(collection),
      items: sortByPosition(items),
      author,
    }))
  )

export const updateCollectionItem = (id, data) =>
  ajax({
    url: `/me/collections/items/${id}`,
    method: 'PATCH',
    body: {
      item: {
        description: data.desc,
        name: data.title,
      },
    },
  }).pipe(pluck('response', 'data', 'item'))

export const fetchMyCollections = product =>
  ajax({
    url: `/me/collections`,
    query: {
      ...(product ? { product_id: product } : {}),
    },
  }).pipe(
    pluck('response', 'data'),
    map(data => ({
      addedTo: data.addedTo,
      collections: [...data.collections.map(c => formatCollection(c))].sort((a, b) =>
        sortByDate(a, b, 'updatedAt')
      ),
    }))
  )

export const createCollection = model =>
  ajax({
    url: `/me/collections`,
    method: 'POST',
    body: createCollectionForm(model),
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }).pipe(
    pluck('response', 'data', 'collection'),
    map(data => formatCollection(data))
  )

export const addProductToCollection = (id, item) =>
  ajax({
    url: `/me/collections/${id}/items`,
    method: 'POST',
    body: { item },
  }).pipe(pluck('response'))

export const updateCollection = (id, collection) =>
  ajax({
    url: `/me/collections/${id}`,
    method: 'PUT',
    body: createCollectionForm(collection),
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }).pipe(
    pluck('response', 'data', 'collection'),
    map(data => formatCollection(data))
  )

export const updateCollectionsPosition = ids =>
  ajax({
    url: `/me/collections/order`,
    method: 'PATCH',
    body: { ids },
  })

export const updateCollectionItemsPosition = (collectionId, ids) =>
  ajax({
    url: `/me/collections/${collectionId}/items/order`,
    method: 'PATCH',
    body: { ids },
  })

export const fetchFavorites = (page, sort) =>
  ajax({ url: `/me/collections/favorites?${qs.stringify({ page, sort })}` }).pipe(
    pluck('response'),
    map(result => ({ data: result.data, meta: result.meta }))
  )

const createCollectionForm = ({ title, desc, pic, ...model }) => {
  const formData = new FormData()

  if (title) {
    formData.append('collection[name]', title)
  }
  if (pic instanceof File) {
    formData.append('collection[cover][file]', pic)
  }

  return formData
}

const sortByPosition = data => data.sort((a, b) => a.position - b.position)

const formatCollection = collection => ({
  id: collection.id,
  name: collection.name,
  picture:
    cloudObjectToURL(collection.cover, { size: '400x400', crop: 'fill', gravity: 'auto' }) ||
    'https://res.cloudinary.com/baopals/image/upload/v1678861704/app/baopals-app-icon.png',

  slug: collection.slug,

  position: collection.position,
  updatedAt: collection.updatedAt,

  itemImages: collection.itemImages || [],
  itemsCount: collection.itemsCount,

  isDefault: collection.generatedType === 'default',

  people: collection.people || [],
  likesCount: collection.likesCount,
})
