import { CommonCollection, CommonCollectionItem } from './collection'
import { CommonComment } from './comment'
import { CommonCoupon } from './coupon'
import { CommonProduct } from './product'
import { CommonUser } from './userProfile'

// distinguish the notification lists between home dropdown and notification page
export enum Display {
  dropdown,
  page,
}

export enum Action {
  collection_like = 'collection_like',
  comment_reply = 'comment_reply',
  comment_upvote = 'comment_upvote',
  fortune_reward = 'fortune_reward',
  // order_update = 'order_update',
  order_update_combined = 'order_update_combined',
  post_reply = 'post_reply',
  post_upvote = 'post_upvote',
  product_404 = 'product_404',
  q_and_a_answer = 'q_and_a_answer',
  refund_complete = 'refund_complete',
  // request_update = 'request_update',
  review_upvote = 'review_upvote',
  same_comment_thread_reply = 'same_comment_thread_reply',
  unpaid_order = 'unpaid_order',
  user_follow = 'user_follow',
  commission_report = 'commission_report',
  commission = 'commission',
  referral = 'referral',
  // refund_request_approved = 'refund_request_approved',
  parcel_refund_request_approved = 'parcel_refund_request_approved',
  followee_review_create = 'followee_review_create',
  liker_add_to_collection = 'liker_add_to_collection',
  custom = 'custom',
  review_remind = 'review_remind',
  invite_friends = 'invite_friends',
  complete_profile = 'complete_profile',
  order_shipped = 'order_shipped',
  warehouse_item_update_combined = 'warehouse_item_update_combined',
  warehouse_item_received = 'warehouse_item_received',
  // warehouse_item_14_days = 'warehouse_item_14_days',
  // warehouse_item_40_days = 'warehouse_item_40_days',
  // warehouse_item_50_days = 'warehouse_item_50_days',
  unpaid_parcel = 'unpaid_parcel',
  payment_auto_refund_canceled = 'payment_auto_refund_canceled',
  payment_auto_refund_double_paid = 'payment_auto_refund_double_paid',
  welcome_coupon = 'welcome_coupon',
}

export enum Target {
  collection = 'collection',
  collectionItem = 'collectionItem',
  comment = 'comment',
  paymentRefund = 'paymentRefund',
  productReview = 'productReview',
  order = 'order',
  orderItem = 'orderItem',
  questionAndAnswersQuestion = 'questionAndAnswersQuestion',
  supportCase = 'supportCase',
  userMilestoneMessage = 'userMilestoneMessage',
  userEarning = 'userEarning',
  userAffiliate = 'userAffiliate',
  userNotificationCustomisable = 'userNotificationCustomisable',
}

export type Components = { [key in Action]: any }

export interface Notification {
  action: Action
  createdAt: Date
  eventsCount: number
  id: number
  read: boolean
  targetId: number
  targetType: Target
  updatedAt: Date
  userIds: number[]
  usersCount: number
}

export interface ProductReview {
  id: number
  productId: string
}

export interface SupportCase {
  id: number
  orderItemId: number
}

export enum RefundableType {
  orderItem = 'orderItem',
}

export interface Reimbursement {
  refundId: number
  refundableId: number
  refundableType: RefundableType
}

export interface PaymentRefund {
  amount: number
  description: string
  id: number
  paymentId: number
}

export interface Order {
  id: number
  number: string
}

export interface OrderItem {
  id: number
  orderId: number
  productId: string
}

export interface Parcel {
  id: number
  number: string
}

export interface QA {
  id: number
  productId: string
}

export interface UserMilestoneMessage {
  couponId: number
  fortune: number
  id: number
  message: string
}

export interface UserAffiliate {
  id: number
  commission: number
  createdAt: Date
}

export interface CustomNotification {
  action: string
}

export interface Data {
  collectionItems?: CommonCollectionItem[]
  collections?: CommonCollection[]
  comments?: CommonComment[]
  coupons?: CommonCoupon[]
  notifications?: Notification[]
  orderItems?: OrderItem[]
  orders?: Order[]
  paymentRefunds?: PaymentRefund[]
  productReviews?: ProductReview[]
  products?: CommonProduct[]
  qaQuestions?: QA[]
  reimbursements?: Reimbursement[]
  supportCases?: SupportCase[]
  userMilestoneMessages?: UserMilestoneMessage[]
  users?: CommonUser[]
  userEarnings?: UserAffiliate[]
  userAffiliates?: UserAffiliate[]
  userNotificationCustomisables?: CustomNotification[]
  orderTracking?: any[]
  globalParcels: any[]
}

export interface Meta {
  total: number
  unreadCount: number
}

export interface DefaultProps {
  notification: Notification
  display: Display
  handleClick?: () => void
}

// show specific icon or user images or other
export interface LeftProps {
  renderLeft?: React.ReactNode
  icon?: string
  withImage?: boolean
  users?: CommonUser[]
}
