import * as ACTION from '@actions/selectedAddress'
import { createReducer } from 'typesafe-actions'

const initialState = null

export const selectedAddr = createReducer(initialState).handleAction(
  ACTION.updateSelectedAddress,
  (state, action) => {
    return action.payload
  }
)
