import ChinaOnly from '@components/common/ChinaOnly'
import { useLocation, useQuery } from '@hooks/useRouter'
import { useCombobox } from 'downshift'
import qs from 'qs'
import React, { FC, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import provinces from './data.json'

const LocationWidget: FC<{ className?: string; onChange?: any }> = props => {
  const intl = useIntl()

  const { location: loc, ...query } = useQuery()

  const translateItem = (val: string) =>
    intl.formatMessage({
      id: `chinese_provinces.${val || 'all_locations'}`,
      defaultMessage: val,
    })

  const { location, navigate } = useLocation()

  const renderUrl = (loc: string) =>
    `${location.pathname}?${qs.stringify({ ...query, location: loc, page: 1 })}`

  const [url, setUrl] = useState(renderUrl(''))

  const handleChange = (loc: string, refresh = false) => {
    const url = renderUrl(loc)
    setUrl(url)

    if (props.onChange) {
      return props.onChange(url)
    }

    if (refresh) {
      navigate(url)
    }
  }

  const [inputItems, setInputItems] = React.useState(provinces)

  const {
    inputValue,
    isOpen,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    selectedItem,
    selectItem,
  } = useCombobox({
    items: inputItems,

    onSelectedItemChange: ({ selectedItem }) => handleChange(selectedItem, true),

    onStateChange: changes => {
      if (changes.type === useCombobox.stateChangeTypes.InputKeyDownEnter) {
        if (changes.selectedItem) {
          handleChange(changes.selectedItem, true)
        } else {
          handleChange(inputValue, true)
        }
      }
    },

    onInputValueChange: ({ inputValue }) => {
      handleChange(inputValue, false)

      setInputItems(
        provinces.filter(item =>
          translateItem(item)
            .toLowerCase()
            .startsWith(inputValue.toLowerCase())
        )
      )
    },
  })

  return (
    <ChinaOnly>
      <div className={props.className || ''}>
        {props.enableLabel && <div className="tw-mb-2 fs14">Location Filter:</div>}

        <div className="tw-relative">
          <div className="tw-w-full">
            <input
              id="sortLocation"
              placeholder="All Locations"
              className="form-control fs12"
              {...getInputProps()}
              data-testid="combobox-input"
            />
            <i
              className="icon-location fs14"
              aria-hidden="true"
              style={{
                position: "absolute",
                right: "10px",
                top: "50%",
                transform: "translateY(-50%)",
                zIndex: 0,
              }}
            />
          </div>

          <ul
            {...getMenuProps()}
            style={{
              listStyle: "none",
              width: "100%",
              padding: 0,
              position: "absolute",
              backgroundColor: "rgba(255, 255, 255, 0.95)",
              top: "40px",
              zIndex: 9999999,
              left: 0,
              borderRadius: 0,
              border: "1px solid #e2e2e2",
              borderTop: "none",
              overflow: "auto",
              maxHeight: "220px",
            }}
          >
            {isOpen &&
              inputItems.map((item, index) => (
                <li
                  style={{
                    backgroundColor: highlightedIndex === index ? '#f93' : 'transparent',
                    padding: '3px 8px',
                  }}
                  key={`${item}${index}`}
                  {...getItemProps({
                    item,
                    index,
                  })}
                >
                  <FormattedMessage id={`chinese_provinces.${item || 'all_locations'}`} />
                </li>
              ))}
          </ul>
        </div>
      </div>
    </ChinaOnly>
  )
}

LocationWidget.defaultProps = {
  className: '',
}

export default LocationWidget
