import { closeLoginModal, openLoginModal } from '@hooks/useLoginModal'
import { closeSignUpModal } from '@hooks/useSignUpModal'
import React from 'react'
import { FormattedMessage } from 'react-intl'

const AuthModalTrigger = ({ children }: { children?: any }) => {
  if (typeof children === 'function') {
    return <> {children(openLoginModal, closeLoginModal)} </>
  }
  const onClick = () => {
    closeSignUpModal()
    openLoginModal({ cancelable: false })
  }

  return <a onClick={onClick}>{children || <FormattedMessage id="general.sign_in" />}</a>
}

export default AuthModalTrigger
