import {MobileAndTablet} from '@components/common/Responsive'
import React, {FC} from 'react'
import {Link} from 'react-router-dom'
import {Festival, selectFestival} from './json'
import './styles.css'

const AvailableFestivals = [Festival.halloween, Festival.christmas]

interface Props {
  className?: string
}

const FestivalLink: FC<Props> = ({className}) => {
  const data = selectFestival()

  // only show when it's festival
  if (!data) {
    return null
  }

  // only show when it's a available festival
  const isEnabled = AvailableFestivals.includes(data.id)
  if (!isEnabled) {
    return null
  }

  return (
    <Link to={data.url} className={`bold upper ${className || ''}`} style={{color: data.color}}>
      <div
        className="d-flex festival-link"
        styleName="wrapper"
        alt={data.id}
      >
        <img src={data.icon} title={data.id} />
        <MobileAndTablet>
          <div className="tw-ml-4">{data.text}</div>
        </MobileAndTablet>
      </div>
    </Link>
  )
}

export default FestivalLink
