import AwesomeBurgerMenu from '@components/common/AwesomeBurgerMenu'
import React, { ReactElement, ReactNode, useState } from 'react'
import { Subject } from 'rxjs'
import './styles.css'
import { useLocation } from '@hooks/useRouter'

interface Props {
  renderLeft?: React.ReactNode
  renderMenu: React.ReactNode
  children?: ReactNode

  menuId: string

  className?: string
  styleName?: string
  menuClassName?: string
  overNavbar?: boolean
}

const subject = new Subject()

const SortAndFilter = (props: Props) => {
  const [open, setOpen] = useState(false)
  const { location: { pathname } } = useLocation()

  const Menu = props.renderMenu

  const handleClose = () => {
    subject.next()
    setOpen(false)
  }

  const heading = props.children || 'SEARCH & FILTER'

  const isSeller = pathname.includes('/seller')
  
  return (
    <>
      <div className={`${!isSeller && "bd-b-dark tw-pb-2"} clearfix ${props.className}`}>
        <div className="pull-left hide-empty">{props.renderLeft}</div>
        {isSeller ? (
          <div className="pull-right tw-mt-3">
            {props.children}
          </div>
        ) : (
          <div
            className="cursor-pointer strong text-uppercase fs16 pull-right"
            onClick={() => setOpen(true)}
          >
            {heading} <i className="icon-arrow-right fs12" />
          </div>
        )}
      </div>

      <AwesomeBurgerMenu
        overNavbar={props.overNavbar}
        id={props.menuId}
        open={open}
        position="right"
        onMenuStateChange={({ isOpen }: any) => setOpen(isOpen)}
        styleName="menu"
        className={`menu-sort-filter ${props.menuClassName}`}
      >
        <div styleName="inner" className="tw-h-full">
          {props.renderMenuHeading && (
            <div className="strong text-center fs20 py-4 bd-b-light l-h-1">{heading}</div>
          )}

          <div styleName="body">
            <Menu onClose={subject} closeMenu={handleClose} {...props.menuProps} />
          </div>

          {props.renderFooter && (
            <footer
              className="text-center tw-w-full bd-t-light py-4 sort-and-filter-footer"
              styleName="footer"
            >
              <button className="btn px-5 btn-lg btn-default strong" onClick={handleClose}>
                CONFIRM
              </button>
            </footer>
          )}
        </div>
      </AwesomeBurgerMenu>
    </>
  )
}

SortAndFilter.defaultProps = {
  className: '',
  styleName: '',
  menuClassName: '',
  renderFooter: true,
  menuProps: {},
  renderMenuHeading: true,
  overNavbar: true,
}

export default SortAndFilter
