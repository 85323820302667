import { formatSeller } from '@components/common/FormattedSeller'
import { Platform } from '@interfaces/platform'
import { SUGGESTION } from '@interfaces/suggestion'
import { cloudObjectToURL } from '@utils/'
import ajax from '@services/client'
import { useQuery } from '@tanstack/react-query'

export const useSuggestions = (_keyword: string) => {
  const keyword = _keyword ?? ""

  return useQuery({
    retry: false,
    placeholderData: [],
    queryKey: ['search_suggestions', keyword],
    enabled: keyword.length >= 2 && keyword.length <= 30,
    queryFn: () => ajax(`/search`, { params: { q: keyword } }).then(({ data }) => data.data).then(({ links: _links, articles, sellers, topLinks }) => {

      const links = ([
        ...topLinks.map(t => ({
          ...t,
        })),
        ..._links,
      ]
        .concat(
          sellers.map(s => ({
            id: s.id,
            sellerId: s.id,
            term: s.id,
            icon: s.icon && cloudObjectToURL(s.icon),
            provider: s.shopType,
            title: formatSeller(s.titles.en || s.titles.zhCn, { ignoreUppercase: true }),
            type: SUGGESTION.SELLER,
          }))
        )
        .concat(
          articles.map(a => ({
            ...a,
            type: SUGGESTION.ARTICLE,
          }))
        )).map(link => ({
          ...link,
          ...(link.sellerType === 'tmall' && { provider: Platform.TMALL }),
        }))


      return links
        .filter(v => v.type === SUGGESTION.ARTICLE)
        .concat(links.filter(v => v.level === SUGGESTION.TOP))
        .concat(
          links.filter(
            v =>
              ![SUGGESTION.ARTICLE, SUGGESTION.SELLER].includes(v.type) &&
              v.level !== SUGGESTION.TOP
          )
        )
        .concat(links.filter(v => v.type === SUGGESTION.SELLER))
    }).catch(err => []),
  })
}
