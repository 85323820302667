export default {
  // Fetch order list
  FETCH_ORDERS_REQUEST: '@ORDERS/FETCH_ORDERS_REQUEST',
  FETCH_ORDERS_SUCCESS: '@ORDERS/FETCH_ORDERS_SUCCESS',
  FETCH_ORDERS_FAILURE: '@ORDERS/FETCH_ORDERS_FAILURE',

  // Fetch parcel list
  FETCH_PARCELS_REQUEST: '@ORDERS/FETCH_PARCELS_REQUEST',
  FETCH_PARCELS_SUCCESS: '@ORDERS/FETCH_PARCELS_SUCCESS',
  FETCH_PARCELS_FAILURE: '@ORDERS/FETCH_PARCELS_FAILURE',

  // Fetch single order
  FETCH_SINGLE_ORDER_REQUEST: '@ORDERS/FETCH_SINGLE_ORDER_REQUEST',
  FETCH_SINGLE_ORDER_SUCCESS: '@ORDERS/FETCH_SINGLE_ORDER_SUCCESS',
  FETCH_SINGLE_ORDER_FAILURE: '@ORDERS/FETCH_SINGLE_ORDER_FAILURE',

  // Archive
  TOGGLE_ORDER_ARCHIVE_REQUEST: '@ORDERS/TOGGLE_ORDER_ARCHIVE_REQUEST',
  TOGGLE_ORDER_ARCHIVE_SUCCESS: '@ORDERS/TOGGLE_ORDER_ARCHIVE_SUCCESS',
  TOGGLE_ORDER_ARCHIVE_FAILURE: '@ORDERS/TOGGLE_ORDER_ARCHIVE_FAILURE',

  // Delete
  DELETE_ORDER_REQUEST: '@ORDERS/DELETE_ORDER_REQUEST',
  DELETE_ORDER_SUCCESS: '@ORDERS/DELETE_ORDER_SUCCESS',
  DELETE_ORDER_FAILURE: '@ORDERS/DELETE_ORDER_FAILURE',

  // Cancel
  CANCEL_ORDER_REQUEST: '@ORDERS/CANCEL_ORDER_REQUEST',
  CANCEL_ORDER_SUCCESS: '@ORDERS/CANCEL_ORDER_SUCCESS',
  CANCEL_ORDER_FAILURE: '@ORDERS/CANCEL_ORDER_FAILURE',

  // Create payment
  CREATE_PAYMENT_REQUEST: '@ORDERS/CREATE_PAYMENT_REQUEST',
  CREATE_PAYMENT_SUCCESS: '@ORDERS/CREATE_PAYMENT_SUCCESS',
  CREATE_PAYMENT_FAILURE: '@ORDERS/CREATE_PAYMENT_FAILURE',

  // Review
  UPDATE_ORDER_ITEMS_REVIEW: '@ORDERS/UPDATE_ORDER_ITEMS_REVIEW',
} as const
