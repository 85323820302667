import NewUserTooltips from '@components/common/NewUserTooltips'
import { data, tooltips } from '@components/common/NewUserTooltips/json'
import { useMyCountry } from '@hooks/useAuth'
import { Desktop, MobileAndTablet } from '@components/common/Responsive'

const GlobalHelpersTour = () => {
  const { isInternational } = useMyCountry()

  const steps = [
    {
      target: '#helper-links-shipping > a',
      content: data.globalShippingFees.text,
      type: tooltips.GLOBAL_SHIPPING_FEES
    },
    {
      target: '#helper-links-size > a',
      content: data.globalClothingSize.text,
      type: tooltips.GLOBAL_CLOTHING_SIZE
    },
  ]

  const MobileSteps = [
    {
      target: 'body',
      content: data.globalShippingFees.text,
      type: tooltips.GLOBAL_SHIPPING_FEES,
      placement: 'center'
    },
    {
      target: 'body',
      content: data.globalClothingSize.text,
      type: tooltips.GLOBAL_CLOTHING_SIZE,
      placement: 'center'
    },
  ]

  const options = {
    width: 900,
    zIndex: 10000
  }


  return isInternational ? (
    <>
      <Desktop>
        <NewUserTooltips steps={steps} options={options} />
      </Desktop>

      <MobileAndTablet>
        <NewUserTooltips steps={MobileSteps} />
      </MobileAndTablet>
    </>
  ) : null
}

export default GlobalHelpersTour
