import { useEffect, useState } from 'react'
import { selectFestival } from '@components/layout/FestivalLogo/json'

export const useFestivalLogo = () => {
  const [festivalLogo, setFestivalLogo] = useState(null)
  const data = selectFestival()

  useEffect(() => {
    if(data) {
      setFestivalLogo({
        desktopLogoUrl: data.desktopLogoUrl,
        mobileLogoUrl: data.mobileLogoUrl
      })
    }

    if(!data) {
      setFestivalLogo(null)
    }
  }, [data])

  return { festivalLogo }
}
