import { identity, pickBy } from 'lodash'
import qs from 'qs'
import { useContext } from 'react'
import { __RouterContext as RouterContext } from 'react-router'
import { matchPath } from 'react-router-dom'

export const useRouter = () => {
  const route = useContext(RouterContext)
  const query = qs.parse(route.location.search, { ignoreQueryPrefix: true })

  return { ...route, query }
}

export const useQuery = () => {
  const {
    location: { search },
  } = useRouter()

  const query = qs.parse(search, { ignoreQueryPrefix: true })

  return query
}

export const useParams = () => {
  const {
    match: { params },
  } = useRouter()

  return params
}

export const useLocation = () => {
  const { location, history } = useRouter()

  const navigate = (to, { replace = false } = {}) => {
    if (replace) {
      history.replace(to)
    } else {
      history.push(to)
    }
  }

  return { location, navigate }
}

export const useMatchPath = (url: string) => {
  const {
    location: { pathname },
  } = useLocation()
  return !!matchPath(pathname, url)
}

export const useQueryPath = (query: any, path?: string, reset?: boolean) => {
  const queryData = useQuery()
  const { location } = useLocation()

  const nextQuery = pickBy(reset ? query : { ...queryData, ...query }, identity)
  const nextPath = path || location.pathname

  return `${nextPath}?${qs.stringify(nextQuery)}`
}

export const useQueryChange = (path?: string) => {
  const queryData = useQuery()
  const { navigate } = useLocation()

  const handleQueryChange = (query: any, resetPage = false) => {
    const nextQuery = pickBy({ ...query, page: resetPage ? null : query.page }, identity)
    const nextPath = path || location.pathname

    navigate(`${nextPath}?${qs.stringify(nextQuery)}`)
  }

  return { query: queryData, handleQueryChange }
}
