import { ProductSorting } from '@interfaces/products'
import React, { FC } from 'react'
import Price from './Price'
import SortUnit from './SortUnit'
import './styles.css'

interface SortWidgetProps {
  value?: ProductSorting
  onChange?: (url: string) => void
}

const SortWidget: FC<SortWidgetProps> = ({ value, onChange, ...props }) => {
  return (
    <div
      className={`${props.className} fs12 flex-center upper bold products-sort-widget`}
      styleName="sort"
    >
      <SortUnit
        name="popularity"
        optional={true}
        {...value && value === 'popularity' && { active: true }}
        onClick={onChange}
      />
      <SortUnit
        name="sales"
        {...value && value === 'sales' && { active: true }}
        onClick={onChange}
      />

      <div className="hidden-xs products-sorting-price">
        <Price />
      </div>

      <SortUnit
        name="price_asc"
        className="visible-xs"
        onClick={onChange}
        {...value && value === 'price_asc' && { active: true }}
      />
      <SortUnit
        name="price_desc"
        className="visible-xs"
        onClick={onChange}
        {...value && value === 'price_desc' && { active: true }}
      />
    </div>
  )
}

SortWidget.defaultProps = {
  className: '',
}

export default SortWidget
