import ACTION from '@constants/ticket'
import { Item } from '@interfaces/order'
import {
  ExtraActionOpt, ReplyForm, Ticket, TicketForm,
  TicketHistory, TicketsParams
} from "@interfaces/ticket";
import { createStandardAction } from 'typesafe-actions'

export const addTicketToList = createStandardAction(ACTION.ADD_TICKET_TO_LIST)<{ ticket: Ticket }>()
export const removeTicketFromList = createStandardAction(ACTION.REMOVE_TICKET_FROM_LIST)<{
  id: number
}>()

export const createTicket = createStandardAction(ACTION.CREATE_TICKET_REQUEST).map(
  (form: TicketForm) => ({
    payload: { form },
    meta: {
      lifecycle: {
        resolve: ACTION.CREATE_TICKET_SUCCESS,
        reject: ACTION.CREATE_TICKET_FAILURE,
      },
    },
  })
)
export const createTicketSuccess = createStandardAction(ACTION.CREATE_TICKET_SUCCESS)<{
  ticket: Ticket
}>()
export const createTicketFailure = createStandardAction(ACTION.CREATE_TICKET_FAILURE)<Error>()

export const fetchTickets = createStandardAction(ACTION.FETCH_TICKET_REQUEST).map(
  (params: TicketsParams) => ({
    payload: { open: params.openOnly, per: params.per, page: params.page },
    meta: {
      lifecycle: {
        resolve: ACTION.FETCH_TICKET_SUCCESS,
        reject: ACTION.FETCH_TICKET_FAILURE,
      },
    },
  })
)
export const fetchTicketsSuccess = createStandardAction(ACTION.FETCH_TICKET_SUCCESS)<{
  tickets: any[]
  totalCount: number
  totalPages: number
}>()
export const fetchTicketsFailure = createStandardAction(ACTION.FETCH_TICKET_FAILURE)<Error>()

export const fetchTicketHistory = createStandardAction(ACTION.FETCH_TICKET_HISTORY_REQUEST).map(
  (item: number, countries: any[]) => ({
    payload: { item, countries },
    meta: {
      lifecycle: {
        resolve: ACTION.FETCH_TICKET_HISTORY_SUCCESS,
        reject: ACTION.FETCH_TICKET_HISTORY_FAILURE,
      },
    },
  })
)
export const fetchTicketHistorySuccess = createStandardAction(ACTION.FETCH_TICKET_HISTORY_SUCCESS)<{
  item: Item
  history: Ticket[]
}>()
export const fetchTicketHistoryFailure = createStandardAction(ACTION.FETCH_TICKET_HISTORY_FAILURE)<
  Error
>()

export const closeTicket = createStandardAction(ACTION.CLOSE_TICKET_REQUEST).map((id: number) => ({
  payload: { id },
  meta: {
    lifecycle: {
      resolve: ACTION.CLOSE_TICKET_SUCCESS,
      reject: ACTION.CLOSE_TICKET_FAILURE,
    },
  },
}))
export const closeTicketSuccess = createStandardAction(ACTION.CLOSE_TICKET_SUCCESS)<{
  id: number
}>()
export const closeTicketFailure = createStandardAction(ACTION.CLOSE_TICKET_FAILURE)<Error>()

export const replyToTicket = createStandardAction(ACTION.CREATE_TICKET_REPLY_REQUEST).map(
  (id: number, form: ReplyForm) => ({
    payload: { id, form },
    meta: {
      lifecycle: {
        resolve: ACTION.CREATE_TICKET_REPLY_SUCCESS,
        reject: ACTION.CREATE_TICKET_REPLY_FAILURE,
      },
    },
  })
)
export const replyToTicketSuccess = createStandardAction(ACTION.CREATE_TICKET_REPLY_SUCCESS)<{
  history: TicketHistory
}>()
export const replyToTicketFailure = createStandardAction(ACTION.CREATE_TICKET_REPLY_FAILURE)<
  Error
>()

export const createExtraInfoReply = createStandardAction(ACTION.CREATE_EXTRAINFO_REPLY_REQUEST).map(
  (opt: ExtraActionOpt) => ({
    payload: { ...opt },
    meta: {
      lifecycle: {
        resolve: ACTION.CREATE_EXTRAINFO_REPLY_SUCCESS,
        reject: ACTION.CREATE_EXTRAINFO_REPLY_FAILURE,
      },
    },
  })
)
export const createExtraInfoReplySuccess = createStandardAction(
  ACTION.CREATE_EXTRAINFO_REPLY_SUCCESS
)<TicketHistory[]>()
export const createExtraInfoReplyFailure = createStandardAction(
  ACTION.CREATE_EXTRAINFO_REPLY_FAILURE
)<Error>()
