import React, { Component } from 'react'
import './styles.css'

const GravitySpinner = () => {
  return (
    <div className="loading-gravity-spinner" styleName="loader">
      Loading...
    </div>
  )
}

export default GravitySpinner
