import React, {FC} from 'react'
import ProgressiveSkeleton from '@components/common/ProgressiveSkeleton'
import './styles.css'

interface Props {
  width?: string
}

const ImageLoading: FC<Props> = props => (
  <ProgressiveSkeleton
    className="tw-mt-0 d-block"
    styleName="loading"
    {...props}
    enableMoveAnimation
  />
)

ImageLoading.defaultProps = {
  width: '100%'
}

export default ImageLoading
