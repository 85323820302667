import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../styles.css'

class CategoryItemUnit extends Component {
  shouldComponentUpdate(nextProps) {
    if (
      nextProps.isActive !== this.props.isActive ||
      this.props.name !== nextProps.name ||
      this.props.link !== nextProps.link
    ) {
      return true
    }

    return false
  }

  render() {
    const { id, name, hasChild, isActive } = this.props

    return (
      <Link
        key={id}
        to={this.props.link}
        styleName={hasChild ? 'link has-child' : 'link'}
        className={`text-truncate text-capitalize ${isActive ? 'active' : ''}`}
        title={name}
        data-active={isActive}
      >
        {name}
      </Link>
    )
  }
}

export default CategoryItemUnit
