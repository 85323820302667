import useNotification from '@hooks/useNotification'
import React from 'react'
import InnerWrapper from './InnerWrapper'
import List from './List'

interface InnerProps {
  toggleDropdown: () => void
}

interface NotificationProps {
  loading: boolean
  data: Data
  meta: {
    page: number
    totalpages: number
    unreadCount: number
  }
  actions: any
}

const DropdownInner: React.SFC<InnerProps> = props => {
  const { loading, data, actions, meta } = useNotification()

  return (
    <InnerWrapper meta={meta} actions={actions} toggleDropdown={props.toggleDropdown}>
      <List loading={loading} data={data} actions={actions} />
    </InnerWrapper>
  )
}

export default DropdownInner

