import RedirectTo from '@hoc/RedirectTo'
import { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

interface SignupLinkProps {
  redirectTo?: string
  className?: string
  onClick?: () => void
}

const SignupLink: FC<SignupLinkProps> = ({ redirectTo, ...props }) =>
  typeof props.children === 'function' ? (
    props.children(`/signup?${redirectTo}`)
  ) : (
    <Link to={`/signup?${redirectTo}`} className={props.className} onClick={props.onClick}>
      <FormattedMessage id="login_modal.sign_up" />
    </Link>
  )

SignupLink.defaultProps = {
  className: '',
}

export default RedirectTo(SignupLink)
