import React from 'react'
import {FormattedMessage} from 'react-intl'
import {Link} from 'react-router-dom'

const Guide = () => (
  <div className="tw-px-6 tw-mb-12">
    <p className="tw-mb-0 mr-auto ml-auto text-center" style={{ maxWidth: "500px" }}>
      <FormattedMessage id="product_page.q&a_description_1" />
      <Link to="/help/6?ref=33" className="bold" target="_blank">
        <FormattedMessage id="product_page.q&a_description_2" />
      </Link>
      <FormattedMessage id="product_page.q&a_description_3" />
    </p>
    <p className="text-center tw-mt-4">
      <strong>For clothing sizes, please provide us with your height and weight</strong>.
    </p>
  </div>
)

export default Guide
