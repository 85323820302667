import { connectRouter } from 'connected-react-router'
import { intlReducer } from 'react-intl-redux'
import { combineReducers } from 'redux'
import cartReducerV2 from './cartReducer'
import collectionItems from './collectionItems'
import departmentReducer from './departmentReducer'
import { departmentsReducer } from './departmentsReducer'
import deptCategory from './deptCategory'
import deptPopularProduct from './deptPopularProduct'
import myCollection from './myCollection'
import productProps from './productProps'
import reviewDetails from './reviewDetails'
import toasts from './toasts'
import tracking from './tracking'
import trendingSearch from './trendingSearch'

import * as tsReducers from './reducers'

type ReducerState = { [K in keyof typeof tsReducers]: ReturnType<typeof tsReducers[K]> }

export type RootState = ReducerState

export default history =>
  combineReducers({
    router: connectRouter(history),
    departments: departmentsReducer,
    productProps,
    toasts,
    intl: intlReducer,
    reviewDetails,
    deptCategory,
    deptPopularProduct,
    myCollection,
    collectionList: collectionItems,
    trendingSearch,

    // Rewritten
    department: departmentReducer,
    cartV2: cartReducerV2,
    tracking,

    ...tsReducers,
  })
