import { AccountAlreadyBindError } from '@errors/AuthError'
import TagManager from 'react-gtm-module'
import { BadRequestError } from '@errors/BadRequestError'
import * as Sentry from '@sentry/react'
import { InvalidTokenError } from '@errors/InvalidTokenError'
import { UnauthorizedError } from '@errors/UnauthorizedError'
import { Address, Zone } from '@interfaces/address'
import {
  Auth as IAuth,
  AuthProvider,
  AuthUser,
  LoginForm,
  SignUpForm,
  WechatLoginForm,
} from '@interfaces/auth'
import { AuthContext as IAuthContext } from '@interfaces/context'
import { User } from '@interfaces/user'
import { resetPassword as doResetPassword } from '@services/auth'
import {
  bindAccount as doBindAccount,
  login as doLogin,
  me,
  signup as doSignUp,
  unbindAccount as doUnbindAccount,
} from '@services/auth'
import { isWeChat } from '@utils/'
import { saveOpenIdToLocalStorage } from '@utils/auth'
import {
  readAuthTokenToLocalStorage,
  removeAuthTokenToLocalStorage,
  saveAuthTokenToLocalStorage,
} from '@utils/auth'
import React, { FC, ReactNode, useEffect, useState } from 'react'
import { of, Subject } from 'rxjs'
import { map, mergeMap } from 'rxjs/operators'

export const AuthContext = React.createContext<IAuthContext>(null)

export const onLoginSuccessful$ = new Subject()

interface AuthProps {
  provider: AuthProvider
  wechatCode?: string
  children: ReactNode
}

const Auth: FC<AuthProps> = ({ children, ...props }) => {
  const [loading, setLoading] = useState(true)
  const [user, setUser] = useState<User | null>(null)
  const [auth, setAuth] = useState<{ user: AuthUser; auth: IAuth } | null>(null)
  const [address, setAddress] = useState<Address[]>([])

  const [zones, setZones] = useState([])

  const handleFetchMeSuccess = (data: any) => {
    if (data && data.user) {
      Sentry.setUser({ email: data.user.email, id: data.user.id, username: data.user.username })

      TagManager.dataLayer({ dataLayer: { user_id: data.user.id } }) // Clear the previous ecommerce object.

      setUser({
        ...data.user,
        collectionsCount: data.user.publicCollectionsCount + data.user.privateCollectionsCount,
      })

      if (data.addresses) {
        setAddress(
          (data.addresses || []).map(addr => ({
            ...addr,
            default: addr.id === data.user.defaultAddressId,
          }))
        )
      }

      if (data.zones) {
        setZones(data.zones)
      }
    }

    if (data && data.tk) {
      saveAuthTokenToLocalStorage(data.tk)
    }
  }

  const handleFetchMeFailed = (err: any) => {
    if (err instanceof InvalidTokenError) {
      console.warn('Invalid token...')
    }

    if (err instanceof UnauthorizedError) {
      if (err.auth && err.auth.extras && err.auth.extras.publicAccountOpenid) {
        saveOpenIdToLocalStorage(err.auth.extras.publicAccountOpenid)
      }

      setAuth({ auth: err.auth, user: err.user })
      setLoading(false)
    } else {
      setUser(null)
      setLoading(false)
    }
  }

  useEffect(() => {
    const sub = fetchMe(props.provider, props.wechatCode).subscribe(
      data => {
        handleFetchMeSuccess(data)
        setLoading(false)
      },
      err => handleFetchMeFailed(err)
    )

    return () => sub.unsubscribe()
  }, [])

  const resetPassword = (token: string, passwd: string) => {
    return new Promise((resolve, reject) => {
      doResetPassword(token, passwd)
        .pipe(
          map(tk => {
            if (tk) {
              return saveAuthTokenToLocalStorage(tk)
            }
          }),
          mergeMap(() => fetchMe(props.provider, props.wechatCode))
        )
        .subscribe(
          data => {
            handleFetchMeSuccess(data)
            resolve(data)
          },
          err => {
            handleFetchMeFailed(err)
            reject(err)
          }
        )
    })
  }

  const login = (provider: AuthProvider, form: LoginForm | WechatLoginForm) =>
    new Promise((resolve, reject) => {
      doLogin(provider, form).subscribe(
        ({ tk, user, addresses, zones }) => {
          saveAuthTokenToLocalStorage(tk)
          setUser({
            ...user,
            collectionsCount: user.publicCollectionsCount + user.privateCollectionsCount,
          })

          if (addresses) {
            setAddress(
              (addresses || []).map(addr => ({
                ...addr,
                default: addr.id === user.defaultAddressId,
              }))
            )
          }

          if (zones) {
            setZones(zones)
          }

          resolve(user)

          Sentry.setUser({ email: user.email, id: user.id, username: user.username })
          TagManager.dataLayer({ dataLayer: { user_id: user.id } }) // Clear the previous ecommerce object.

          onLoginSuccessful$.next(user)
        },
        err => {
          reject(err)
        }
      )
    })

  // Reset user to initial state
  // Remove auth meta
  // Clear `tk` from local storage
  const logout = () => {
    Sentry.setUser(null)

    setUser(null)
    setAuth(null)
    removeAuthTokenToLocalStorage()
  }

  const unbindAccount = () => {
    doUnbindAccount().subscribe(() => {
      setUser({ ...user, boundProviders: user.boundProviders.filter(p => p !== 'wechat') })
    })
  }

  const bindAccount = (code: string): Promise<User> => {
    return new Promise((resolve, reject) => {
      doBindAccount(
        isWeChat() ? AuthProvider.WECHAT_PUBLIC_ACCOUNT : AuthProvider.WECHAT_OPEN_PLATFORM,
        { code }
      ).subscribe(
        ({ user }) => {
          setUser({
            ...user,
            collectionsCount: user.publicCollectionsCount + user.privateCollectionsCount,
          })
          resolve(user)
        },
        err => {
          if (err instanceof BadRequestError) {
            return reject(new AccountAlreadyBindError())
          }

          reject(err)
        }
      )
    })
  }

  const getAuth = () => {
    const value = auth
    setAuth(null)
    return value
  }

  const signup = (form: SignUpForm) =>
    new Promise((resolve, reject) => doSignUp(form).subscribe(resolve, reject))

  const value = {
    user,
    loading,
    login,
    signup,
    logout,
    getAuth,

    bindAccount,
    unbindAccount,

    resetPassword,

    // Address & Zones
    address,
    zones,

    __setUser: setUser,
    __setAddress: setAddress,

    // Observables
    onLoginSuccessful: onLoginSuccessful$,

    // Address
    updateAddress: setAddress,

    // Zones
    updateZones: (newZones: Zone[]) => setZones(zones.concat(newZones)),
  }

  return <AuthContext.Provider value={value}>{loading ? null : children}</AuthContext.Provider>
}

const fetchMe = (provider: AuthProvider, code?: string) => {
  switch (provider) {
    case AuthProvider.WECHAT_PUBLIC_ACCOUNT:
      return doLogin(AuthProvider.WECHAT_PUBLIC_ACCOUNT, { code })

    case AuthProvider.WECHAT_OPEN_PLATFORM:
      return doLogin(AuthProvider.WECHAT_OPEN_PLATFORM, { code })

    //  Send `/me` only when the tk exists on local storage
    default:
      return of(readAuthTokenToLocalStorage()).pipe(mergeMap(tk => (tk ? me() : of(null))))
  }
}

export default Auth
