import React, { Component } from 'react'
import MediaQuery from 'react-responsive'

//big tablet & desktop
const Desktop = props => <MediaQuery {...props} minWidth={992} />
//small tablet
const Tablet = props => <MediaQuery {...props} minWidth={768} maxWidth={991} />
//mobile & small tablet
const MobileAndTablet = props => <MediaQuery {...props} maxWidth={991} />
//mobile
const Mobile = props => <MediaQuery {...props} maxWidth={767} />
//tablet & desktop
const Default = props => <MediaQuery {...props} minWidth={768} />

export { Desktop, Tablet, MobileAndTablet, Mobile, Default }
