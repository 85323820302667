import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ProductActiveFilter from '../../product/ProductActiveFilter'
import './styles.css'

const ProductActiveFilters = ({ disabled, filters, ...props }) => {
  return (
    <div className="container tw-mt-6">
      {filters.map(filter => (
        <div styleName="unit" key={filter.id}>
          <ProductActiveFilter id={filter.id} text={filter.text} onClick={filter.onClick} />
        </div>
      ))}
    </div>
  )
}

export default ProductActiveFilters
