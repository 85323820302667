import { Image } from '@interfaces/image'

export interface LoginForm {
  usernameOrEmail: string
  password: string
}

export interface WechatLoginForm {
  code: string
}

export interface SignUpForm {
  avatar?: File
  affiliateToken?: string
  firstName: string
  lastName: string
  username: string
  email: string
  password: string
  auth: Auth
}

export interface Auth {
  provider: AuthProvider
  uid?: string
  extras: AuthExtra
}

export interface AuthUser {
  username?: string
  avatar?: Image
}

interface AuthExtra {
  publicAccountOpenid?: string
  openPlatformOpenid?: string
}

export enum AuthProvider {
  EMAIL = 'email',
  WECHAT_PUBLIC_ACCOUNT = 'publicAccount',
  WECHAT_OPEN_PLATFORM = 'openPlatform',
}

export enum WechatAuthState {
  AUTO_AUTH = '0',
  MANUALLY_AUTH = '1',
  OPEN_PLATFORM_AUTH = '4',
  BIND_ACCOUNT = '2',
  UNBIND_ACCOUNT = '3',
}

export enum BoundProvider {
  WECHAT = 'wechat',
  EMAIL = 'email',
  USERNAME = 'username',
}

export interface ValidateUserOpt {
  type: string,
  sq: string
}

export enum ShowModalPositions {
  DESKTOP = 2343,
  TABLET = 1725,
  MOBILE = 3120,
}
