// import SearchBar from '@components/superMarket/SearchBar'
import CatList from '@components/common/CategoryList'
import {useJdCategories} from '@hooks/useJdCategories'

const JDCategory = props => {
  const {categories, nestedCategories} = useJdCategories()
  return (
    <>
      {/*<Route path='/express/category/:id' render={() => <SearchBar displayStyle='aside' placeholder='Search Express' />} />*/}
      <CatList
        renderHeading={<div className="tw-mt-2">BAOPALS EXPRESS</div>}
        nestedCategories={nestedCategories}
        categories={categories}
        renderLink={cat => `/express/category/${cat.id}`}
        activeCategory={props.activeCategory}
        rootCategory={0}
      />
    </>
  )
}

export default JDCategory
