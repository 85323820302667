import PriceWithCurrency from '@components/common/PriceWithCurrency'
import { useProductPrice } from '@hooks/useProduct'
import { FC } from 'react'
import './styles.css'

const Price: FC<{ id: number }> = ({ id }) => {
  const classNames = ['price-wrapper']
  const prices = useProductPrice(id)

  if (prices.minOriginalPrice && prices.maxOriginalPrice) {
    classNames.push('has-promotion')
  }

  return (
    <div styleName={classNames.join(' ')} className="flex-center">
      {prices.minPrice > 0 &&
        prices.maxPrice > 0 && (
          <div data-price-type="promotion" styleName="price" className="bold" data-single-price={prices.minPrice === prices.maxPrice}>
            <PriceWithCurrency price={[prices.minPrice, prices.maxPrice]} />
          </div>
        )}
      {prices.minOriginalPrice && prices.maxOriginalPrice ? (
        <div data-price-type="original" styleName="price" className="bold">
          <PriceWithCurrency price={[prices.minOriginalPrice, prices.maxOriginalPrice]} />
        </div>
      ) : null}
    </div>
  )
}

export default Price
