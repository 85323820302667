import * as ACTION from '@actions/products'
import { Platform } from '@interfaces/platform'
import { createReducer } from 'typesafe-actions'

export interface ProductMeta {
  [key: string]: {
    desc: string
    sold: number,
  }
}

const initialState: ProductMeta = {}

export const productMeta = createReducer(initialState)
  .handleAction(ACTION.fetchSingleProduct.success, (state, { payload }) => {
    return {
      ...state,
      ...(payload.product.platform === Platform.JD
        ? Object.assign({}, { [payload.id]: { sold: payload.product.sold || 0 }  }, payload.product.properties.reduce((prev, next) => {
            return {
              ...prev,
              [next.skuId]: {
                desc: next.desc || '',
                properties: next.values,
                sold: payload.product.sold || 0,
              },
            }
          }, {}))
        : {
            [payload.id]: {
              desc: payload.product.desc || '',
              properties: payload.product.properties,
              sold: payload.product.sold || 0,
            },
          }),
    }
  })
  .handleAction(ACTION.fetchSingleProductMetaSuccess, (state, { payload }) => {
    return {
      ...state,
      [payload.id]: {
        ...(state[payload.spu] || {}),
        desc: payload.meta?.desc || '',
        properties: payload.meta?.properties || [],
      },
    }
  })
