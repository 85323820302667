import { cloudObjectToURL } from '@utils/'
import { imageToObject } from '@utils/taobaoUtils'
import React, { useState } from 'react'
import Waypoint from 'react-waypoint'
import { OCR } from './OCR'
import './styles.css'

interface DescProps {
  node: any
}

const SingleDesc: React.SFC<DescProps> = ({ node, id, platform }) => {
  const [loading, setLoading] = React.useState(false)
  const img = React.useRef(null)

  const handleImageLoad = (element: any, pic: string) => {
    setLoading(false)
    element.src = pic
  }

  const handleEnter = (pic: string) => {
    const element = img.current

    if (element.src == '') {
      setLoading(true)
    }

    const image = new global.Image()
    pic = (() => {
      if (pic.startsWith('http')) {
        return pic
      } else if (pic.startsWith('//')) {
        return `https:${pic}`
      }
      return cloudObjectToURL(imageToObject(pic))
    })()

    image.onload = () => handleImageLoad(element, pic)
    image.src = pic
  }

  return node.attribs.src ? (
    <Waypoint onEnter={() => handleEnter(node.attribs.src)}>
      <div className="text-center">
        {loading && <div styleName="loader" />}
        <OCR image={node.attribs.src} id={id} platform={platform}>
          <img ref={img} />
        </OCR>
      </div>
    </Waypoint>
  ) : null
}

export default SingleDesc
