//import { FETCH_USER_PROFILE } from '../constants'
import * as ACTION from '../constants/deptCategory'

const initialState = {
  status: null, // Pending: 0, Success: 1, Error: -1
  error: null,

  cats: null,
}

export default function deptCategory(state = initialState, { type, payload = {} }) {
  if (!payload) return state
  const { status, error, cats, ...data } = payload

  switch (type) {
    case ACTION.FETCH_DEPT_CATEGORY:
      return {
        ...state,
        status,
        error,
        cats,
      }

    default:
      return state
  }
}
