import React, { useState } from 'react'
import WechatFollowModal from '../../WechatFollowModal'
import Link from '../Link'

const WechatModal = props => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <Link {...props} onClick={() => setOpen(true)} />

      <WechatFollowModal show={open} onHide={() => setOpen(false)} />
    </>
  )
}

export default WechatModal
