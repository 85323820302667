import PriceWithCurrency from '@components/common/PriceWithCurrency'
import useAuth from '@hooks/useAuth'

const UserWalletBalance = ({ children }) => {
  const { user } = useAuth()

  const balance = user?.walletBalance ?? 0

  const enable = balance > 0 || user.walletUsed === true

  const renderPrice = <PriceWithCurrency price={balance} />

  return typeof children === 'function' ? children({ renderPrice, balance, enable }) : renderPrice
}

export default UserWalletBalance
