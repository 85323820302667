import { useLanguage } from '@hooks/useLanguage'
import React from 'react'
import { MenuItem, Nav, NavDropdown } from 'react-bootstrap'
import './styles.css'

const languages: { [key: string]: string } = {
  en: 'EN',
  zh: '中文',
}

const LangDropdown = props => {
  const { locale, updateLang } = useLanguage()

  const LangFa = (
    <span className="upper">
      <i className="fa fa-fw fa-globe" />
      {languages[locale]}
    </span>
  )

  return (
    <Nav
      pullRight={true}
      className="lang-dropdown"
      activeKey={locale === 'zh' ? 'zh-CN' : locale}
      onSelect={lang => updateLang(lang)}
    >
      <NavDropdown
        pullRight={true}
        dropup={true}
        title={LangFa}
        noCaret={true}
        id="lang"
        styleName="dropdown"
      >
        <MenuItem eventKey="en">English</MenuItem>
        <MenuItem eventKey="zh-CN">中文</MenuItem>
      </NavDropdown>
    </Nav>
  )
}

export default LangDropdown
