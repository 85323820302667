import React, { Component } from 'react'
import WeChatShareModal from './modal'

class WeChatShareButton extends Component {
  constructor(props) {
    super(props)

    this.state = {
      open: false,
    }
  }

  handleClick = () => {
    const { recordWechatShares } = this.props
    this.setState({ open: true }, () => recordWechatShares('wechat'))
  }

  handleClose = e => {
    this.setState({ open: false })
  }

  render() {
    const { url } = this.props

    return (
      <>
        <a className="black-link" title="Share by Wechat" onClick={this.handleClick}>
          <i className="icon-wechat-share" />
        </a>
        <WeChatShareModal open={this.state.open === true} url={url} onHide={this.handleClose} />
      </>
    )
  }
}

export default WeChatShareButton
