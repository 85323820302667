import DottedLine from '@components/common/DottedLine'
import FlexBetween from '@components/common/FlexBetween'
import { cloudObjectToURL, getRandomInt } from '@utils/'
import React, { FC } from 'react'
import Actions from './Actions'
import Location from './Location'
import Prices from './Prices'

const renderSold = (sold) => {
  if (!sold || +sold === 0) {
    return getRandomInt(50, 99)
  }

  if (+sold >= 100) {
    if (+sold % 100 === 0) {
      return `${+sold}+`
    }
  }

  return +sold
}

const Footer = props => (
  <div className="tw-mt-2">
    <FlexBetween
      className="fs12"
      renderLeft={props.location?.length > 0 ? <Location location={props.location} /> : null}
      renderRight={<div>({renderSold(props.sold)} Sold)</div>}
    />

    <DottedLine className="tw-my-2" />
    <FlexBetween
      renderLeft={
        <Prices
          originalPrice={props.originalPrice}
          {...!props.hideDiscount && (props.discountPrice < props.originalPrice) && { discountPrice: props.discountPrice }}
        />
      }
      renderRight={<Actions {...props} cover={cloudObjectToURL(props.cover)} />}
    />
  </div>
)

Footer.defaultProps = {
  sold: 0,
}

export default Footer
