import useAuth from '@hooks/useAuth'
import React from 'react'
import { Redirect, RouteComponentProps } from 'react-router-dom'

interface RedirectAccountProps
  extends RouteComponentProps<{ id?: string; username?: string; slug?: string }> {
  target: 'user' | 'shops' | 'wishlists'
}

const RedirectAccount: React.SFC<RedirectAccountProps> = props => {
  const {
    match: { path, params },
  } = props

  const { user } = useAuth()

  const baseURL = `/discover/users/${user ? user.username : ''}`

  switch (props.target) {
    case 'user':
      return <Redirect to={`/discover/users/${params.username}/reviews`} />

    case 'wishlists':
      return params.slug ? (
        <Redirect to={`${baseURL}/wishlists/${params.slug}`} />
      ) : (
        <Redirect to={`${baseURL}/wishlists`} />
      )

    case 'shops':
      return <Redirect to={`${baseURL}/shops`} />

    default:
      return <Redirect to="/404" />
  }
}

export default RedirectAccount
