import React from 'react'
import Slider from 'react-slick'
import LazyLoadImage from '@components/common/LazyLoadImage'
import { mobileCollectionProductPic } from '@services/collection'
import './styles.css'
import { cloudObjectToURL } from '@utils/'
import { DEFAULT_PRODUCT_PLACEHOLDER } from '../../../../../../../constants'

const Arrow = ({ cName, stName, onClick, className }) => (
  <div styleName={`arrow ${stName}`} onClick={onClick} data-arrow={stName}>
    <i className={`icon-arrow-${cName}`} />
  </div>
)

const settings = {
  lazyLoad: true,
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
  swipeToSlide: false,
  swipe: false,
  draggable: false,
  className: 'slide-inner',
  scrolling: false,
  swiping: false,
  touchMove: false,
  prevArrow: <Arrow stName="prev" cName="left" className="product-carousel-prev" />,
  nextArrow: <Arrow stName="next" cName="right" className="product-carousel-next" />,
}

class ProductCarousel extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      slideIndex: 0,
      pictures:
        props.thumbnails?.[0]?.id === props.selectedImage?.id
          ? props.thumbnails
          : [props.selectedImage, ...props.thumbnails],
    }
  }

  componentWillReceiveProps(nextProps) {
    const { thumbnails, selectedImage } = this.props
    if (selectedImage?.id !== nextProps.selectedImage?.id) {
      const { slideIndex } = this.state
      this.setState(
        {
          pictures: thumbnails
            .slice(0, slideIndex)
            .concat(nextProps.selectedImage)
            .concat(thumbnails.slice(slideIndex)),
        },
        () => {
          mobileCollectionProductPic.next({
            pic: this.state.pictures[this.state.slideIndex],
          })
        }
      )
    }
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.selectedImage?.id !== nextProps.selectedImage?.id) {
      return true
    }

    return false
  }

  beforeChange = (current, next) => {
    this.setState({ slideIndex: next }, () => {
      mobileCollectionProductPic.next({
        pic: this.state.pictures[this.state.slideIndex],
      })
    })
  }

  render() {
    const body = (
      <div styleName="wrapper" className={this.props.className}>
        <Slider {...settings} beforeChange={this.beforeChange}>
          {this.state.pictures.map((picture, index) => (
            <LazyLoadImage
              square={true}
              key={index}
              image={cloudObjectToURL(picture, { size: '400x400' }) || DEFAULT_PRODUCT_PLACEHOLDER}
            />
          ))}
        </Slider>
      </div>
    )
    return typeof this.props.children === 'function' ? this.props.children(body) : body
  }
}

ProductCarousel.defaultProps = {
  className: '',
}

export default ProductCarousel
