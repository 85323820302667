import ACTION from '@constants/products'
import { confine } from '@utils/numberUtils'
import { truthyOrZero } from '@utils/objectUtils'
import { getAvailableStock, getMinimumQuantity } from '@utils/productUtils'

export interface ProductState {
  readonly loading: boolean

  qty: number
}

const initialState: ProductState = {
  loading: true,
  error: null,

  qty: 1,

  products: [],
  selected: {},
}

export const product = (state = initialState, { type, payload = {} }) => {
  switch (type) {
    case ACTION.RESET_PRODUCT_QTY: {
      const product = state.products.find(p => p.id === payload)
      const selected = state.selected[payload]

      if (!product) {
        return state
      }

      return { ...state, qty: getMinimumQuantity(product, selected) }
    }

    case ACTION.FETCH_SINGLE_PRODUCT_REQUEST: {
      return { ...state, loading: true, qty: 1, error: null }
    }

    case ACTION.UPDATE_PRODUCT_QTY: {
      const product = state.products.find(p => p.id === payload.id)
      const selected = state.selected[payload.id] || []

      const minimumQuantity = getMinimumQuantity(product, selected)
      const availableStock = getAvailableStock(product, selected)

      const qty = truthyOrZero(availableStock)
        ? confine(payload.qty, minimumQuantity, availableStock)
        : Math.max(payload.qty, minimumQuantity)

      return { ...state, qty }
    }

    case ACTION.SELECT_PRODUCT_SKU: {
      const selectedCategories = payload.option.map(o => o.categoryId)

      const selected = []
        .concat(payload.option)
        .concat(
          (state.selected[payload.id] || []).filter(
            opt => !selectedCategories.includes(+opt.categoryId)
          )
        )

      const product = state.products.find(p => p.id === payload.id)

      const availableStock = getAvailableStock(product, selected)

      const qty = truthyOrZero(availableStock) ? Math.min(availableStock, state.qty) : state.qty

      const prevMin = getMinimumQuantity(product, state.selected)
      const nextMin = getMinimumQuantity(product, selected)
      const tmpQty = prevMin !== nextMin && nextMin

      return {
        ...state,
        qty: tmpQty || Math.max(nextMin, qty),
        selected: {
          ...state.selected,
          [payload.id]: selected,
        },
      }
    }

    case ACTION.UNSELECT_PRODUCT_SKU:
      const selected = (state.selected[payload.id] || []).filter(
        o => o.categoryId !== payload.option.categoryId
      )

      const product = state.products.find(p => p.id === payload.id)

      const pMin = getMinimumQuantity(product, state.selected)
      const nMin = getMinimumQuantity(product, selected)
      const tQty = pMin !== nMin && nMin

      return {
        ...state,
        selected: {
          ...state.selected,
          [payload.id]: selected,
        },
        qty: tQty || state.qty,
      }

    case ACTION.FETCH_SINGLE_PRODUCT_FAILURE:
      return { ...state, loading: false, error: payload }

    case ACTION.FETCH_SINGLE_PRODUCT_SUCCESS: {
      const { id, product, selected } = payload

      const newProduct = {
        ...product,
        desc: '',
        spec: [],
      }

      const availableStock = getAvailableStock(product, selected)
      const minimumQuantity = getMinimumQuantity(product, selected)

      const quantity = truthyOrZero(availableStock)
        ? confine(state.qty, minimumQuantity, availableStock)
        : state.qty

      return {
        ...state,
        products: [newProduct].concat(state.products),
        loading: false,
        selected: {
          ...state.selected,
          [id]: selected,
        },
        qty: quantity,
      }
    }

    default:
      return state
  }
}
