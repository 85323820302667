import React, { ReactNode, useEffect, useRef, useState } from 'react'
import { Overlay, Popover } from 'react-bootstrap'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { FormattedMessage } from 'react-intl'
import './styles.css'

interface ClipboardProps {
  text: string
  onCopy?: () => void

  resetCopyState?: boolean

  renderCopied?: ReactNode
}

const Clipboard: React.SFC<ClipboardProps> = props => {
  const [copy, setCopy] = useState<boolean>(null)

  useEffect(
    () => {
      const id = (() => {
        if (copy && props.resetCopyState) {
          return setTimeout(() => setCopy(false), 2000)
        }
      })()

      return () => {
        id && clearTimeout(id)
      }
    },
    [copy]
  )

  const onCopy = () => {
    setCopy(true)
    props.onCopy && props.onCopy()
  }

  const ref = useRef(null)

  const renderCopiedText = (copiedText: string) => (
    <Overlay show={true} target={ref.current} placement="top">
      <Popover id="popover-contained" styleName="popover">
        {props.renderCopied}
      </Popover>
    </Overlay>
  )

  return (
    <CopyToClipboard text={props.text} onCopy={onCopy}>
      <span>
        <div className="relative" ref={ref}>
          {typeof props.children === 'function' ? props.children({ copied: copy }) : props.children}
        </div>

        {copy && renderCopiedText(props.text)}
      </span>
    </CopyToClipboard>
  )
}

Clipboard.defaultProps = {
  resetCopyState: true,
}

export default Clipboard
