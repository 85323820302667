import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import useAuth from '@hooks/useAuth'
import { useMyIp } from '@hooks/useMyIp'
import './styles.css'

const LINK = '/privacy-policy'

const CookiePolicy = () => {
  const { user } = useAuth()
  const { data: ip } = useMyIp()
  const [hideCookiePolicy, setHideCookiePolicy] = useState(localStorage.getItem('hide_cookie_policy'))
  const countryGroup = ip?.countryGroup
  const onClick = () => {
    localStorage.setItem('hide_cookie_policy', 'true'),
    setHideCookiePolicy('true')
  }

  return (
    <>
      {(!user && countryGroup === 'eu' && !hideCookiePolicy) ? (
        <div styleName="wrapper" id="cookie-policy">
          <div className="tw-mr-6">
            By using our site you are agreeing that we can use cookies in accordance with our <Link to={LINK} className="blue-link bold" target="_blank">Cookie Policy</Link>
          </div>
          <div styleName="btn" onClick={onClick}>
            Got it
          </div>
        </div>
      ) : null}
    </>
  )
}

export default CookiePolicy
