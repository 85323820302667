import { HttpStatus } from '@constants/httpStatus'
import { HttpError } from '@errors/HttpError'

export class InvalidTokenError extends HttpError {
  public name = 'InvalidTokenError'

  constructor() {
    super(HttpStatus.INVALID_TOKEN)
    Object.setPrototypeOf(this, InvalidTokenError.prototype)
  }
}
