import ProductLink from '@components/common/ProductLink'
import TranslationText from '@components/common/TranslationText'
import ProductImages from '@components/product/v4/ProductDetails/ProductMain/ProductImages'
import { Titles } from '@interfaces/common'
import React, { FC, useState } from 'react'
import './styles.css'

const ImagesWithTitle: FC<{
  skuId?: number
  id: number
  titles: Titles
  thumbnails: string[]
  cover: string
  affiliateToken?: string
  affiliateVia?: string
}> = ({ id, thumbnails, titles, cover, platform, skuId, ...props }) => {
  const [selected, setSelected] = useState(cover || thumbnails[0])

  const title = (
    <ProductLink
      id={id}
      className="strong d-block"
      styleName="link"
      platform={platform}
      skuId={skuId}
      affiliateToken={props.affiliateToken}
      affiliateVia={props.affiliateVia}
    >
      <div styleName="title" className="product-card-title" />
    </ProductLink>
  )

  return (
    <div styleName="wrapper" data-with-thumbnails={props.renderThumbnails && thumbnails.length > 0}>
      <ProductImages
        badge={false}
        className="tw-mb-0 product-card-images"
        thumbnails={thumbnails}
        selectedImage={selected}
        onSelectImage={setSelected}
        renderOnMobile="gallery"
        renderOnTablet="gallery"
        renderOnDesktop="carousel"
        prettyUpImage={props.prettyUpImage}
        sellerId={props.sellerId}
        {...props.sellerId === '890482188' && { prettyUpImageIndex: 3 }}
        platform={platform}
      >
        {(main, thumbnails) => (
          <>
            <div className="relative">
              {main}
              {title}
            </div>
            {props.renderThumbnails ? thumbnails : null}
          </>
        )}
      </ProductImages>
    </div>
  )
}

ImagesWithTitle.defaultProps = {
  renderThumbnails: true,
  prettyUpImage: true,
}

export default ImagesWithTitle
