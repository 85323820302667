import MarkdownIt from 'markdown-it'
import React from 'react'

const md = new MarkdownIt({
  html: true,
  linkify: true,
})

type MarkdownProps = {
  content: string
}

const Markdown: React.FC<MarkdownProps> = props => (
  <div
    dangerouslySetInnerHTML={{
      __html: md.render(props.content),
    }}
  />
)

export default Markdown
