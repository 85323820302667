/**
 *
 * returns all options from a set that belongs to a given category
 *
 * @param category (Product/SkuCategory)
 * @param options (Array[Product/SkuOption)
 * @returns {Array[Product/SkuOption]}
 */
export const getOptions = (category, options) =>
  options.filter(o => o.categoryId === category.id);
