import * as ActionCreator from '@actions/products'
import { useProductSku, useSingleProduct } from '@hooks/useProduct'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

export const useProductImage = (id: number) => {
  const dispatch = useDispatch()

  const { product } = useSingleProduct(id)
  const { selected, thumbnails } = useSelector(state => state.productImages)

  const { selected: sku } = useProductSku(id)
  const skuHasThumbnail = sku.find(s => s.thumbnail)
  const thumbnailId = skuHasThumbnail?.thumbnail?.id

  const handleSelect = (image: any, thumbnails?: any[]) => dispatch(ActionCreator.updateProductImage({ image, thumbnails }))

  const images = product?.thumbnails || []

  useEffect(() => {
    handleSelect(images[0], images)
  }, [id])


  useEffect(() => {
    if (thumbnailId) {
      handleSelect(skuHasThumbnail.thumbnail)
    }

  }, [thumbnailId])

  return { selected, thumbnails, onChange: handleSelect }
}
