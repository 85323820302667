import ACTION from '@constants/notification'
import { Data, Meta } from '@interfaces/notification'
import { createStandardAction } from 'typesafe-actions'

// fetch notifications
export const fetchNotifications = createStandardAction(ACTION.FETCH_NOTIFICATIONS_REQUEST).map(
  (opt: { page: number; preventRefetch?: boolean }) => ({
    payload: {
      page: opt.page,
      preventRefetch: opt.preventRefetch,
    },
    meta: {
      lifecycle: {
        resolve: ACTION.FETCH_NOTIFICATIONS_SUCCESS,
        reject: ACTION.FETCH_NOTIFICATIONS_FAILURE,
      },
    },
  })
)

export const fetchNotificationsSuccess = createStandardAction(ACTION.FETCH_NOTIFICATIONS_SUCCESS)<{
  data: Data
  meta: Meta
}>()

export const fetchNotificationsFailure = createStandardAction(ACTION.FETCH_NOTIFICATIONS_FAILURE)<
  Error
>()

// mark notifications as read
export const markAsRead = createStandardAction(ACTION.MARK_AS_READ_REQUEST).map(
  (ids: number[]) => ({
    payload: { ids },
  })
)

export const markAsReadSuccess = createStandardAction(ACTION.MARK_AS_READ_SUCCESS)<{
  ids: number[]
}>()

export const markAsReadFailure = createStandardAction(ACTION.MARK_AS_READ_FAILURE)<Error>()

// mark all notifications as read
export const markAllAsRead = createStandardAction(ACTION.MARK_ALL_AS_READ_REQUEST)()

export const markAllAsReadSuccess = createStandardAction(ACTION.MARK_ALL_AS_READ_SUCCESS)()

export const markAllAsReadFailure = createStandardAction(ACTION.MARK_ALL_AS_READ_FAILURE)<Error>()

// real time notification
export const receive = createStandardAction(ACTION.RECEIVE_DONE)<{ payload: any }>()
