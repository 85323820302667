import { Platform } from '@interfaces/platform'
import qs from 'qs'
import React, { FC } from 'react'
import { Link } from 'react-router-dom'

interface ProductLinkProps {
  id: string
  review?: boolean
  qa?: boolean

  target?: '_blank' | '_self'
  className?: string
  styleName?: string
  title?: string
  sku?: string

  skuId?: number

  platform?: Platform

  // affiliate props
  shareableId?: number
  affiliateToken?: string
  affiliateVia?: string
}

export const toProductLinkObj = (id: string, options: any = {}) => {
  const pathname = []
    .concat(options.platform === Platform.JD ? '/express/products' : ['/products'])
    .concat(id)
    .join('/')

  const search = qs.stringify({
    ...(options.review && { tab: 'reviews' }),
    ...(options.qa && { tab: 'qa' }),
    ...(options.sku && { skus: options.sku }),
    ...(options.skuId && { skuId: options.skuId }),
    ...(options.shareableId && { at_sid: options.shareableId }),
    ...(options.affiliateToken && { at: options.affiliateToken }),
    ...(options.affiliateVia && { via: options.affiliateVia }),
  })

  return { pathname, search }
}

export const toProductLinkStr = (id: string, options: any = {}) => {
  const { pathname, search } = toProductLinkObj(id, options)
  return `${pathname}${search && '?' + search}`
}

const ProductLink: FC<ProductLinkProps> = ({
  className,
  id,
  title,
  review,
  qa,
  sku,
  skuId,
  shareableId,
  styleName,
  affiliateToken,
  affiliateVia,
  platform,
  ...props
}) => (
  <Link
    target={props.target}
    className={`black-link ${className}`}
    to={{
      ...toProductLinkObj(id, {
        platform,
        review,
        qa,
        sku,
        skuId,
        shareableId,
        affiliateToken,
        affiliateVia,
      }),
      ...(review && {
        state: { fromHome: 1 },
      }),
    }}
    title={title}
    data-title={title}
    {...props}
    // onClick={() => window.scroll(0, 0)}
  >
    {props.children}
  </Link>
)

ProductLink.defaultProps = {
  className: '',
  styleName: '',
  review: false,
  qa: false,
  platform: Platform.TAOBAO,
  target: '_self',
}

export default ProductLink
