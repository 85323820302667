import DottedLine from '@components/common/DottedLine'
import LineThroughText from '@components/common/LineThroughText'
import { useSimilarProducts } from '@hooks/useProduct'
import { Platform } from '@interfaces/platform'
import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import ProductCarousel from './ProductCarousel'
import { Default, Mobile } from '@components/common/Responsive'
import ProductsWithEndlessScroll from './ProductsWithEndlessScroll'
import './styles.css'

interface SimilarProductsProps {
  id: number
  platform: Platform
  supermarket?: boolean
  setHasSimilar: (hasSimilar: boolean) => void
}

const SimilarProducts: React.SFC<SimilarProductsProps> = ({ id, ...props }) => {
  const [page, setPage] = useState(1)
  const { loading, products, isEnd } = useSimilarProducts(id, page)

  useEffect(() => {
    if (products?.length > 0) {
      props.setHasSimilar(true)
    } else {
      props.setHasSimilar(false)
    }
  }, [products?.length])

  return (
    <>
      <Default>
        {!loading && products?.length > 0 && (
          <div className="product-similar-products tw-w-full">
            <DottedLine className="tw-my-12" />

            <div className="text-center">
              <LineThroughText className="fs16 tw-mb-12 text-uppercase">
                <FormattedMessage id="product_page.similar_products" />
              </LineThroughText>
            </div>

            <div styleName="wrapper">
              <ProductCarousel {...(props.supermarket ? { slideCount: 5 } : {})} products={products} />
            </div>
            <DottedLine className="tw-my-12" />
          </div>
        )}
      </Default>
      <Mobile>
        <div className="product-similar-products tw-w-full tw-px-3">
          <DottedLine className="tw-my-12" styleName="dottedLine" />
          <div id="similar_products">
            {products?.length > 0 && (
              <div className="text-center">
                <LineThroughText className="fs16 tw-mb-12 text-uppercase">
                  <FormattedMessage id="product_page.similar_products" />
                </LineThroughText>
              </div>
            )}
            <ProductsWithEndlessScroll
              loading={loading}
              products={products}
              isEnd={isEnd}
              page={page}
              setPage={setPage}
            />
          </div>
        </div>
      </Mobile>
    </>
  )
}

export default SimilarProducts
