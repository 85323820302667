import React from "react";
import {useHotCategories} from '@hooks/useWhatsHot'
import {cloudObjectToURL} from '@utils/'
import qs from 'qs'
import ProgressiveSkeleton from "@components/common/ProgressiveSkeleton";
import {Link} from 'react-router-dom'
import { useFestivalLink } from '@hooks/useFestivalLink'
import './styles.css'

const CategorySection = () => {
  const {categories, loading} = useHotCategories()
  const { isHalloween, isChristmas } = useFestivalLink()
  const halloweenCategory = categories.find(c => c.slug === 'costumes')
  const christmasCategory = categories.find(c => c.slug === 'christmas')
  const restCategories = categories.filter(c => c.slug !== (isHalloween ? 'costumes' : 'christmas'))
  const hotCategories = isHalloween ? [{ ...halloweenCategory, parentId: null }, ...restCategories] :
    isChristmas ? [{ ...christmasCategory, parentId: null }, ...restCategories] : categories


  if (loading) {
    return (
      <div className="v4-row v4-no-gutters">
        {[1, 2, 3, 4, 5, 6].map(num => (
          <div key={num} className="text-center v4-col-6 tw-p-2">
            <ProgressiveSkeleton width="100%" height={180} enableMoveAnimation={true} />
          </div>
        ))}
      </div>
    )
  }

  return (
    <div className="v4-row v4-no-gutters">
      {hotCategories.filter(v => !v.parentId).map(c => (
        <div key={c.slug} className="text-center v4-col-6 tw-p-2">
          <Link
            styleName="unit"
            className="d-block link-black"
            to={`${window.location.pathname}?${qs.stringify({
              category: c.slug,
              page: 1,
              tab: 'categories',
              ...(c.sellerCategory && {seller_category: c.sellerCategory}),
            })}`}
          >
            <img
              src={cloudObjectToURL(c.cover) || 'https://via.placeholder.com/200'}
              className="img-circle obj-fit-cover"
            />
            <div className="text-uppercase tw-mt-6 tw-pt-2">{c.title}</div>
          </Link>
        </div>
      ))}
    </div>
  )
}

export default CategorySection
