import React from 'react'
import { Breadcrumb } from 'react-bootstrap'
import { Link } from "react-router-dom";

import './styles.css'

interface Props {
  links: {
    link?: string
    title: string
  }[]
}

const BreadCrumb: React.FC<Props> = ({ links }) => {
  return (
    <div styleName="wrapper">
      <Breadcrumb>
        {links.map((l, index) => {
          const opts = {
            ...(l.link && { href: l.link, componentClass: Link, to: l.link }),
            active: !l.link,
            key: index
          }
          return (
            <Breadcrumb.Item {...opts}>
              {l.title}
            </Breadcrumb.Item>
        )})}

      </Breadcrumb>
    </div>
  )
}

export default BreadCrumb
