import { HttpStatus } from '@constants/httpStatus'
import { ConflictError } from '@errors/ConflictError'
import { HttpError } from '@errors/HttpError'
import { NotFoundError } from '@errors/NotFoundError'
import { UnauthorizedError } from '@errors/UnauthorizedError'
import qs from 'qs'
import { of } from 'rxjs'

import { BadRequestError } from '@errors/BadRequestError'
import { ForbiddenError } from '@errors/ForbiddenError'
import { InvalidTokenError } from '@errors/InvalidTokenError'
import { readAuthTokenToLocalStorage } from '@utils/auth'
import { Observable } from 'rxjs'
import { ajax as _ajax, AjaxRequest as _AjaxRequest, AjaxResponse } from 'rxjs/ajax'
import { catchError } from 'rxjs/operators'
import { API_URL } from '../constants'
import {formDataToObject} from '@utils/'
import {UnprocessableEntityError} from '@errors/UnprocessableEntityError'
import { RackAttackError } from '@errors/RackAttackError'

const buildURL = (url: string) => `${API_URL}${url}`

interface AjaxRequest extends _AjaxRequest {
  query?: { [key: string]: any }
}

const createRequest = (method: string, url: string, opts: AjaxRequest) =>
  new Ajax({ method, url, ...opts })

const ajaxGet = (url: string, opts: AjaxRequest) => createRequest('GET', url, opts)
const ajaxPost = (url: string, opts: AjaxRequest) => createRequest('POST', url, opts)
const ajaxPut = (url: string, opts: AjaxRequest) => createRequest('PUT', url, opts)
const ajaxPatch = (url: string, opts: AjaxRequest) => createRequest('PATCH', url, opts)
const ajaxDelete = (url: string, opts: AjaxRequest) => createRequest('DELETE', url, opts)

export const getAuthHeader = () => {
  const tk = readAuthTokenToLocalStorage()

  if (tk) {
    return { Authorization: `Bearer ${tk}` }
  }

  return {}
}

const commonHeaders = {
  Accept: '*/*',
  'X-Key-Inflection': 'camel',
  'Key-Inflection': 'camel',
  'Content-Type': 'application/json',
}

const commonOpts = {
  withCredentials: true,
  crossDomain: true,
  responseType: 'json',
}

class Ajax extends Observable<AjaxResponse> {
  public static create = (() => {
    const create = (opts: AjaxRequest) => new Ajax(opts)

    create.get = ajaxGet
    create.post = ajaxPost
    create.put = ajaxPut
    create.patch = ajaxPatch
    create.delete = ajaxDelete

    return create
  })()

  constructor(urlOrRequest: AjaxRequest) {
    super()
    // default headers
    const headers = {
      ...getAuthHeader(),
      ...commonHeaders,
    }

    const request: any = {
      ...commonOpts,
      headers,
    }

    if (typeof urlOrRequest === 'string') {
      request.url = buildURL(urlOrRequest)
    } else {
      for (const prop in urlOrRequest) {
        if (urlOrRequest.hasOwnProperty(prop)) {
          if (prop === 'headers' && typeof urlOrRequest.headers === 'object') {
            request.headers = { ...request.headers, ...urlOrRequest.headers }
          } else if (prop === 'url' && urlOrRequest.url) {
            request.url = buildURL(urlOrRequest.url)
          } else {
            request[prop] = (urlOrRequest as any)[prop]
          }
        }
      }
    }

    if (urlOrRequest.query && typeof urlOrRequest.query === 'object') {
      request.url = `${request.url}${qs.stringify(urlOrRequest.query, { addQueryPrefix: true })}`
    }

    if (request.headers['Content-Type'] !== 'application/json') {
      delete request.headers['Content-Type']
    }

    return _ajax(request).pipe(catchError(handleError))
  }
}

const handleError = (err: any) => {
  switch (err.status) {
    case HttpStatus.UNAUTHORIZED: {
      const body = typeof err.response?.data === 'string' ? {} : err.response?.data || {}

      const { user, auth } = (() => {
        if (!body) {
          return {}
        }

        const { avatar, username, auth } = body

        return { user: { avatar, username }, auth }
      })()

      const message = (() => {
        if (err.response?.data === 'not_confirmed') {
          return err.response.data
        }

        return err.message
      })()

      throw new UnauthorizedError(message, user, auth, err.response?.response ?? '')
    }

    case HttpStatus.UNPROCESSABLE_ENTITY:
      throw new UnprocessableEntityError(err.response.errors)

    case HttpStatus.INVALID_TOKEN:
      throw new InvalidTokenError()

    case HttpStatus.FORBIDDEN:
      throw new ForbiddenError()

    case HttpStatus.BAD_REQUEST: {
      const body = formDataToObject(err.request.body ||  new FormData())
      throw new BadRequestError({ ...err.response.error, ...body })
    }

    case HttpStatus.CONFLICT:
      throw new ConflictError()

    case HttpStatus.RACK_ATTACK:
      throw new RackAttackError()

    case HttpStatus.NOT_FOUND:
      throw new NotFoundError()

    default: {
      console.warn(err)
      if (err.response && (err.response.errors || err.response.error)) {
        throw new HttpError(500, err.response.errors || err.response.error)
      } else {
        throw new HttpError(500, '')
      }
    }
  }

  return of(null)
}

export default Ajax.create
