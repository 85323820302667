import { FormattedMessage } from 'react-intl'

const ToggleContent = ({ count }) => (
  <>
    <span className="fs16 icon-cart" />
    <span>
      &nbsp;
      <FormattedMessage id="homepage.cart" />
      &nbsp; ({count})
    </span>
  </>
)

export default ToggleContent
