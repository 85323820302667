import React, { useEffect, useState } from 'react'
import HomePageSignUpModal from '@components/common/HomePageSignUpModal'
import { useScroll } from '@hooks/useScroll'
import useAuth from '@hooks/useAuth'
import { useRouter } from '@hooks/useRouter'

const SignUpModal = (props) => {
  const [openSignUp, setOpenSignUp] = useState(null)
  const { scrollY, scrollDirection } = useScroll()
  const { user } = useAuth()
  const { location: { pathname } } = useRouter()

  useEffect(() => {
    const isShow = scrollY > props.scrollTo && scrollDirection === 'up' && !openSignUp

    if(isShow) { setOpenSignUp('open') }
  }, [scrollY])

  const openSignUpModal = !user && pathname === '/' && openSignUp === 'open'

  return (
    <>
     {openSignUpModal ? <HomePageSignUpModal open={openSignUp} onClose={() => setOpenSignUp('close')} /> : null}
    </>
  )

}

export default SignUpModal
