import useAuth from '@hooks/useAuth'
import ajax from '@services/client'
import { useQuery } from '@tanstack/react-query'


export const useUserProfile = (username: string) => {
  const { user } = useAuth()

  const { isLoading, error, data } = useQuery({
    queryKey: ['users', username],
    queryFn: () => ajax(`/users/${username}`).then(resp => resp.data.data?.user),
  })
  const isMe = data ? data.username === user?.username : false

  return {
    loading: isLoading,
    profile: data ? {
      ...data,
      isMe,
      collectionsCount: isMe ? user?.collectionsCount : data.collectionsCount,
      reviewsCount: isMe ? user?.reviewsCount : data.reviewsCount,
      sellersCount: isMe ? user?.sellersCount + user?.privateSellersCount : data.sellersCount,
    } : null,
  }
}
