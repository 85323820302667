import TrendingAnchor, { TrendingAnchorOpt } from '@components/common/TrendingAnchor'
import { Desktop, MobileAndTablet } from '@components/common/Responsive'
import { matchPath, Route, Switch } from 'react-router-dom'
import { useLocation, useQuery } from '@hooks/useRouter'
import { useAtom } from 'jotai'
import { blurActiveElement } from '@utils/browser'
import qs from 'qs'
import React, { FC, useEffect, useState } from 'react'
import { showPath as showLinkPath } from '../../../routing/department/linkRoutes'
import SearchInput from './SearchInput'
import './styles.css'
import TranslationToggler from './TranslationToggler'
import TranslateSearchTour from '@components/common/Tour/TranslateSearchTour'
import { SUGGESTION } from '@interfaces/suggestion'
import { uniqBy } from 'lodash'
import { recentSearchAtom } from '@atoms/recent-search'
import TextInputWithDropdown from './TextInputWithDropdown'

interface SearchBarProps { }

const SearchBar: FC<SearchBarProps> = props => {
  const { location } = useLocation()
  const query = useQuery()

  const [redirectToOpt, setRedirectToOpt] = useState<TrendingAnchorOpt | null>(null)
  const [translate, setTranslate] = useState<boolean>(query.translate !== 'false')
  const [openTour, setOpenTour] = useState<boolean>(false)
  const [recentSearch, setRecentSearch] = useAtom(recentSearchAtom)

  useEffect(
    () => {
      setRedirectToOpt(null)
    },
    [location.key]
  )

  useEffect(
    () => {
      setTranslate(query.translate !== 'false')
    },
    [query.translate]
  )

  const handleEnter = suggestion => {
    const url = showLinkPath(suggestion)

    setRecentSearch(uniqBy([suggestion, ...recentSearch], 'term').slice(0, 20))

    if (suggestion.id >= 0) {
      document.title = `Search ${suggestion.title} | Baopals.com`
      setRedirectToOpt(
        suggestion.type === SUGGESTION.ARTICLE
          ? {
            redirectTo: `/blog/articles/${suggestion.slug}`,
          }
          : {
            redirectTo: url,
            trendingUrl: url,
          }
      )
    } else {
      setRedirectToOpt({
        redirectTo: `${url}`.concat(
          suggestion.type === SUGGESTION.SEARCH ? `&translate=${true}` : ''
        ),
      })
    }

    blurActiveElement()
  }

  const handleToggle = () => {
    const enableTranslate = !translate

    if (matchPath(location.pathname, { path: '/search', exact: true })) {
      setRedirectToOpt({
        redirectTo: `/search?${qs.stringify({
          ...query,
          translate: enableTranslate,
          page: 1,
        })}`,
      })
    }

    setTranslate(enableTranslate)
    setOpenTour(true)
  }

  const child = (
    <div className="tw-h-full">
      <div className="input-group tw-h-full tw-w-full">
        <TextInputWithDropdown value={query.title || query.q} translate={translate} onEnter={handleEnter} />

        <Switch>
          <Route exact={true} path="/search">
            <span className="input-group-addon" id="tour-search-input-step-2">
              <TranslationToggler toggle={!translate} onToggle={handleToggle} />
            </span>
          </Route>
        </Switch>
      </div>
    </div>
  )

  return (
    <>
      <Desktop>
        <Switch>
          <Route exact={true} path="/search">
            <div styleName="wrapper" className="tw-w-full">
              {child}
            </div>
          </Route>
          <Route>
            <div styleName="wrapper" className="tw-w-9/12 tw-mx-auto">
              {child}
            </div>
          </Route>
        </Switch>
      </Desktop>
      <MobileAndTablet>
        <div styleName="wrapper" className="tw-w-full">
          {child}
        </div>
      </MobileAndTablet>
      <TrendingAnchor redirectOnMount={true} {...redirectToOpt} />
      {openTour && <TranslateSearchTour translate={translate} onClose={() => setOpenTour(false)} />}
    </>
  )
}

export default SearchBar
