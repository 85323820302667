import { useState, useEffect } from 'react'
import { useMedia as useMediaHook } from 'use-media'

export enum Viewport {
  XS = '(max-width: 767px)',
  SM = '(min-width: 768px) and (max-width: 991px)',
  MD = '(min-width: 992px) and (max-width: 1199px)',
  LG = '(min-width: 1200px)',
}

export const useResponsive = (viewports: Viewport[]) => viewports.map(v => useMediaHook(v))

export const useMedia = (queries: string[], values: any[], defaultValue: any) => {
  const mediaQueryLists = queries.map(q => window.matchMedia(q))

  const getValue = () => {
    const index = mediaQueryLists.findIndex(mql => mql.matches)
    return typeof values[index] !== 'undefined' ? values[index] : defaultValue
  }

  const [value, setValue] = useState(getValue)

  useEffect(() => {
    const handler = () => setValue(getValue)
    mediaQueryLists.forEach(mql => mql.addListener(handler))

    return () => mediaQueryLists.forEach(mql => mql.removeListener(handler))
  }, [])

  return value
}
