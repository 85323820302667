import React from 'react'
import './styles.css'

const Toggler = ({ onToggle }) => (
  <div onClick={onToggle} styleName="toggle" className="bold clearfix">
    CATEGORIES
    <span className="pull-right">
      <i className="icon-arrow-right tw-ml-2" />
    </span>
  </div>
)

export default Toggler
