import React from 'react'
import ProgressiveSkeleton from '@components/common/ProgressiveSkeleton'
import {LoadingProps} from '@components/common/Loading'
import './styles.css'

const Loading: React.SFC<LoadingProps> = ({className}) => (
  <div className={`relative ${className || ''}`} styleName="wrapper">
    <ProgressiveSkeleton width="100%" height="0" {...rest} />
    <ProgressiveSkeleton width="70%" height="20%" {...rest} />
  </div>
)

export default Loading

const rest = {
  enableMoveAnimation: true,
  className: "tw-mt-0 d-block"
}
