import React from 'react'
import { FormattedMessage } from 'react-intl'
import Help, { LinkProps } from './Help'
import { indexPath as businessIndexPath, aboutPath } from '@components/business/routes'
import { useMyCountry } from '@hooks/useAuth'

const localCustomerServiceLink = 'https://www.baopals.com/help/cn-your-orders?ref=orders-help'
const internationalCustomerServiceLink = 'https://www.baopals.com/help/your-orders?ref=get-help'

const HelpYou = () => {
  const { isInternational } = useMyCountry()

  const links: LinkProps[] = [
    {
      url: '/help',
      text: <FormattedMessage id="footer.faq" defaultMessage="HELP CENTER" />,
    },
    {
      url: isInternational ? internationalCustomerServiceLink : localCustomerServiceLink,
      text: <FormattedMessage id="header.customer_service" />,
    },
    // {
    //   url: businessIndexPath,
    //   text: <span>Business Services</span>,
    //   openNewTab: true,
    // },
    {
      url: aboutPath,
      text: <span>About Us</span>,
      openNewTab: true,
    },
  ]

  return <Help links={links} title={title} className="text-right" />
}

export default HelpYou

const title = <FormattedMessage id="footer.let_us_help_you" defaultMessage="LET US HELP YOU" />
