import Currency from '@hoc/Currency'
import useAuth from '@hooks/useAuth'
import { useMyCountry } from '@hooks/useAuth'
import { useCountries } from '@hooks/useCountries'
import { FC } from 'react'
import { matchPath } from 'react-router'
import Dropdown from './Dropdown'
import Menu from './Menu'
import { useQueryClient } from '@tanstack/react-query'

interface CurrencySwitcherProps {
  display: 'menu' | 'dropdown'
}

interface MenuItem {
  label: string
  value: string
  active: boolean
}

export interface CurrencyItemProps {
  selected: string
  menuItems: MenuItem[]
  onChange: (currency: string) => void
}

const CurrencySwitcher: FC<CurrencySwitcherProps> = props => {
  const isCheckout = matchPath(location.pathname, {
    path: '/:checkoutType(orders|bundles|parcels)/:id/checkout',
  })

  const isTopup = matchPath(location.pathname, { path: '/account/topup' })

  const { user, updateMyProfile } = useAuth()
  const { locationCountryCode } = useMyCountry()

  const { countries, isLoading } = useCountries()

  const queryClient = useQueryClient()

  if (isLoading) {
    return null
  }

  const handleChange = (params, meta) => {
    if (params.currency) {
      if (!user) {
        return Promise.resolve({ currency: params.currency })
      }
    }

    if (user) {
      return updateMyProfile(params)
    } else {
      queryClient.setQueryData(['sessionSettings'], prev => {
        const newCountry = countries.find(c => c.alias == params.locationCountry)

        return { ...prev, countryCode: newCountry.abbr }
      })
    }
  }

  if (countries.length == 0) {
    return null
  }

  return (
    <Currency>
      {(data: any) => {
        const currencies = Array.from(new Set(countries.map(c => c.currency)))

        const currentCurrency = currencies.find(c => c === data.currency)

        const menuItems = currencies.map(c => ({
          label: c.toUpperCase(),
          value: c,
          // ...m,
          active: c === currentCurrency,
          ...((isCheckout || isTopup) && { disabled: true }),
        }))

        const rest = {
          disabled: !!(isCheckout || isTopup),
          selectedCurrency: currentCurrency.toUpperCase(),
          selectedCountry: locationCountryCode,
          menuItems,
          onChange: handleChange,
          onCurrencyChange: data.actions.updateDefaultCurrency,
        }

        return props.display === 'menu' ? <Menu {...rest} /> : <Dropdown {...rest} />
      }}
    </Currency>
  )
}

export default CurrencySwitcher
