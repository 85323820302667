export const readAuthTokenToLocalStorage = () => localStorage.getItem('tk')
export const saveAuthTokenToLocalStorage = (tk: string) => localStorage.setItem('tk', tk)
export const removeAuthTokenToLocalStorage = () => {
  localStorage.removeItem('tk')
}

export const saveOpenIdToLocalStorage = (openid: string) => localStorage.setItem('openid', openid)
export const readOpenIdFromLocalStorage = () => localStorage.getItem('openid')

export const parseTokenFromHttpHeader = (
  tk: string,
  schema: string = 'Bearer'
): string | undefined => {
  const regex = new RegExp(`^${schema} (.*)$`, 'gi')
  const [, token] = regex.exec(tk) || ['', '']

  if (token) {
    return token
  }
}
