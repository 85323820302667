import React, { useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { FormattedMessage } from 'react-intl'
import Previewer from './Previewer'
import './styles.css'

interface FileUploaderProps {
  preview?: boolean
  accept?: string | string[]
  placeholder?: React.ReactNode
  disabled?: boolean
  multiple?: boolean

  onDelete?: (index: number) => void

  files?: File[]

  validateFiles?: (files: File[]) => Promise<void[]>
  onUpload: (files: File[]) => void
}

const FileUploader: React.FC<FileUploaderProps> = props => {
  const [disabled, setDisabled] = useState<boolean>(props.disabled)
  const [error, setError] = useState(null)
  const [errorFileIndex, setErrorFileIndex] = useState(null)

  const onDrop = useCallback(
    (acceptedFiles, rejectFiles) => props.onUpload(acceptedFiles, rejectFiles),
    []
  )

  useEffect(
    () => {
      props.validateFiles &&
        props
          .validateFiles(props.files)
          .then(() => {
            setDisabled(false)
            setError(null)
          })
          .catch(err => {
            setDisabled(true)
            setError(err?.error)
            const errorFileIndex = props.files.findIndex(f => f === err?.file)

            if (errorFileIndex > -1) {
              setErrorFileIndex(errorFileIndex)
            }
          })
    },
    [props.files]
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple: props.multiple,
    onDrop,
    accept: props.accept,
  })

  return (
    <div>
      <div {...getRootProps()} data-disabled={disabled} styleName="file" className="fs12">
        <input {...getInputProps()} />
        <span className="file-uploader-placeholder">{props.placeholder}</span>
      </div>

      {props.preview && (
        <Previewer files={props.files} onDelete={props.onDelete} errorFileIndex={errorFileIndex} />
      )}

      {error && <div className="text-danger">{error}</div>}
    </div>
  )
}

FileUploader.defaultProps = {
  preview: false,
  multiple: true,
  disabled: false,
  accept: ['image/*', 'video/*', 'application/pdf'],
  placeholder: (
    <a className="strong text-uppercase black-link">
      <span className="icon-add-photo" /> <FormattedMessage id="review_form.photos_add" />
    </a>
  ),
}

export default FileUploader
