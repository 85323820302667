import { Image } from './image'

export interface ExtraInfo {
  cancelButtonText: string
  confirmButtonText: string
  replyPlaceholder: string
  requiresReply: boolean
}

export interface TicketsParams {
  openOnly?: boolean
  per?: number
  page?: number
}

export interface TicketHistory {
  id: number
  caseId: number
  description: string
  createdAt: string
  submitter: string
  attachments: Image[]
  additionalInformation?: ExtraInfo
}

export interface Ticket {
  updatedAt: string
  id: number
  itemId: number
  itemImage: string
  // repliesCount: number
  status: TicketStatus

  history: TicketHistory[]
}

export interface TicketsRes {
  tickets: Ticket[]
  totalCount: number
  totalPages: number
}

export interface TicketForm {
  item: number
  requestType: TicketRequestType
  description: string
  files?: Blob[]
}

export interface ReplyForm {
  message: string
  files?: Blob[]
}

export enum TicketRequestType {
  NO_TRACKING = 'no_tracking',
  ITEM_NOT_RECEIVED = 'item_not_received',
  INCORRECT_TRACKING = 'incorrect_tracking',
  WAREHOUSE_RETURN_REQUEST = 'warehouse_return_request',
  RETURN = 'return_request',
  GENERAL_INQUIRY = 'general_inquiry',
  WAREHOUSE_GENERAL_INQUIRY = 'warehouse_general_inquiry',
}

export enum TicketStatus {
  OPEN = 'open',
  PENDING = 'pending',
  RESOLVED = 'resolved',
}

export enum TicketSubmitter {
  ADMIN = 'baopals',
  CUSTOMER = 'customer',
  UNKNOWN = 'unknown',
}

export enum Choice {
  process = 'process',
  refund = 'refund',
}

export interface ExtraOpt {
  choice: Choice
  reply?: string
}

export interface ExtraActionOpt {
  caseId: number
  msgId: number
  opt: ExtraOpt
}
