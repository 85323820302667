import { Display, LeftProps } from '@interfaces/notification'
import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import Inner from './Inner'

interface Props extends LeftProps {
  date: Date
  display?: Display
  link?: string
  onClick?: () => void
}

const Main: FC<Props> = ({ link, onClick, ...props }) => {
  if (!link) {
    return <Inner onClick={onClick} {...props} />
  }

  return link.startsWith('http') ? (
    <a href={link} target="_blank" onClick={onClick}>
      <Inner {...props} />
    </a>
  ) : (
    <Link to={link} onClick={onClick}>
      <Inner {...props} />
    </Link>
  )
}

export default Main
