import Loading from '@components/notifications/Accessories/Loading'
import NotifList from '@components/notifications/List'
import {MAX_NOTIFICATIONS_RENDER_NUM} from '@constants/'
import {Data, Display} from '@interfaces/notification'
import React from 'react'
import {FormattedMessage} from 'react-intl'

interface ListProps {
  loading: boolean
  data: Data
  actions: any
}

const List: React.SFC<ListProps> = props => {
  const {loading, data, actions} = props

  if (loading) {
    return <Loading number={3} />
  }

  if (!loading && data && data.notifications && data.notifications.length > 0) {
    return (
      <NotifList
        display={Display.dropdown}
        maxShowNotifications={MAX_NOTIFICATIONS_RENDER_NUM}
        data={data}
        markAsRead={actions.markAsRead}
      />
    )
  }

  return (
    <li className="d-block tw-p-4 text-center">
      <FormattedMessage id="notifications.none_dropdown" />
    </li>
  )
}

export default List
