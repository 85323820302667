import BacktoTop from '@components/layout/BacktoTop'
import React, { FC, useState } from 'react'
import Guides from './Guides'
import Helps from './Helps'
import License from './License'

const Footer: FC = () => {
  return (
    <footer id="footer" className="footer">
      <Guides />

      <BacktoTop className="hidden-xs hidden-sm" />

      <Helps />

      <License />
    </footer>
  )
}

export default Footer
