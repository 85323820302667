import { data, tooltips } from '@components/common/NewUserTooltips/json'
import useAuth, { useMyCountry } from "@hooks/useAuth";
import { dismissTooltip } from '@services/me'
import { difference, intersection } from 'lodash'
import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import CNYNotice from '@components/common/CNYNotice'
import { useCountries } from '@hooks/useCountries'
import getYear from 'date-fns/getYear'
import isAfter from 'date-fns/isAfter'
import isBefore from 'date-fns/isBefore'
import './styles.css'

interface ModalProps {
  tooltip?: tooltips
  target: string
}

export const showCNYNotice = (expireDate) => {
  const currentDate = new Date()
  const currentYear = getYear(currentDate)
  const getDate = (time: string) => new Date(`${time}, ${currentYear}`)
  const startDate = getDate('Jan 17')
  const endDate = getDate(expireDate)
  const after = isAfter(currentDate, startDate)
  const before = isBefore(currentDate, endDate)

  return after && before
}

const CnyNotificationModal: React.SFC<ModalProps> = ({ target, tooltip }) => {
  const { updateToolTips, user } = useAuth()
  const { isLoading } = useCountries()
  const { isInternational } = useMyCountry()

  const [open, setOpen] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const expireDate = isInternational ? 'Jan 28' : 'Feb 1'
  const isShow = showCNYNotice(expireDate)

  useEffect(
    () => {
      if (
        user && !isLoading && isShow && (tooltip ? user.tooltips.includes(tooltip) : true)
      ) {
        return setOpen(true)
      }

      return setOpen(false)
    },
    [target, tooltip, isLoading, isShow]
  )

  const dismiss = () => {
    setSubmitting(true)

    const sub = dismissTooltip(tooltip).subscribe(
      () => {
        const userTooltips = difference(user.tooltips, intersection(user.tooltips, [tooltip]))
        updateToolTips(userTooltips)
        setSubmitting(false)
        setOpen(false)
      },
      err => {
        console.warn(err)
      }
    )

    return () => sub.unsubscribe()
  }

  const onClose = () => {
    setOpen(false)
  }

  return (
    <Modal bsSize="sm" show={open} styleName="modal" onHide={onClose}>
      <Modal.Header closeButton={target !== 'homepage'}>
        <Modal.Title>CNY Notice</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <CNYNotice />
      </Modal.Body>

      {
        target === 'homepage' && (
          <Modal.Footer>
            <Button onClick={dismiss} styleName="btn">
              {submitting ? <i className="fa fa-spinner fa-pulse fa-fw" /> : 'Got It'}
            </Button>
          </Modal.Footer>
        )
      }
    </Modal>
  )
}

export default CnyNotificationModal
