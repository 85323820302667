import React, {useState} from "react";
import {Col, Row} from 'react-bootstrap'
import ProgressiveSkeleton from "@components/common/ProgressiveSkeleton"
import {Link} from "react-router-dom"
import Filters from "./Filters"
import Search from "./Search"
import {CategoryData} from "@interfaces/blog"
import {useResponsive, Viewport} from "@hooks/useResponsive"
import CategoriesMenu from "./CategoriesMenu"
import "./styles.css"

interface Props {
  categories: CategoryData[]
  loading: boolean
}

const SearchAndFilters: React.FC<Props> = ({loading, categories}) => {
  const isLG = useResponsive([Viewport.LG]).some(v => v)
  const [open, setOpen] = useState<boolean>(false)
  const classNames = "d-flex align-items-center"

  return (
    <div styleName="container">
      <div className="container">
        {loading ? (
          <ProgressiveSkeleton width="100%" height="50" enableMoveAnimation className="tw-mt-0 d-block" />
        ) : (
          <Row styleName="wrapper">
            <Col md={1} sm={12} xs={12} styleName="title" className="fs18 bold">
              <Link to='/blog' className={`black-link ${isLG && classNames}`}>
                {isLG && (
                  <div styleName="blogLogo">
                    <i className="icon-blog" />
                  </div>
                )}
                BLOG
              </Link>
            </Col>
            {isLG ? (
              <>
                <Col md={7}>
                  <Filters categories={categories} />
                </Col>
                <Col md={1} />
              </>
            ) : (
              <Col sm={5} xs={10}>
                <Search />
              </Col>
            )}
            <Col md={3} sm={6} xs={2}>
              {isLG ? (
                <Search />
              ) : (
                <div className='text-right fs20' onClick={() => setOpen(true)}>
                  <i className="icon-mobile_menu" />
                </div>
              )}
            </Col>
          </Row>
        )}
      </div>

      <CategoriesMenu open={open} setOpen={setOpen} categories={categories} />
    </div>
  )
}

export default SearchAndFilters
