import { useAddress } from '@hooks/useAddress'
import { getSelectedOptions } from '@utils/product'
import { useSingleProduct, useProductSku } from '@hooks/useProduct'
import useAuth from '@hooks/useAuth'
import { Platform } from '@interfaces/platform'
import React, { useMemo, useEffect } from 'react'
import ProductDetailsModal from '../ProductDetailsModal'
import { openSignUpModal } from '@hooks/useSignUpModal'

interface ShoppingCartIconProps {
  id: number
  platform: Platform
  skus?: string
  skuId?: number
  modalClassName?: string
}

const ShoppingCartIcon: React.FC<ShoppingCartIconProps> = ({ id, skus, skuId, ...props }) => {
  const [open, setOpen] = React.useState<boolean>(false)

  const { user } = useAuth()
  const { defaultAddress } = useAddress()

  const { loading, product, selected } = useSingleProduct(id, {
    zoneId: defaultAddress && defaultAddress.zoneId,
    platform: props.platform,
    skus: skus,
    skuId,
    refetch: user?.id && open ? true : false,
  })

  const { onSelect } = useProductSku(id)

  useEffect(() => {
    if (skuId && open && props.platform === Platform.JD && product?.skus) {
      const selectedSku = (product.skus || []).find(s => (+s.skuId === +skuId))?.id

      if (selectedSku && selectedSku !== -1) {
        const selected = getSelectedOptions(
          product.skus,
          product.skuCategories,
          product.skuOptions,
          selectedSku
        )

        if (selectedSku?.length) {
          onSelect(selected)
        }
      }
    }
  }, [skuId, open])

  return (
    <>
      <i
        className="icon-cart cursor-pointer"
        onClick={user ? () => setOpen(true) : openSignUpModal}
      />
      {open && (
        <ProductDetailsModal
          selected={selected}
          className={props.modalClassName}
          open={open}
          onClose={() => setOpen(false)}
          loading={loading}
          product={product}
        />
      )}
    </>
  )
}

ShoppingCartIcon.defaultProps = {
  modalClassName: '',
}

export default ShoppingCartIcon
