import DottedLine from '@components/common/DottedLine'
import { ArticleData } from '@interfaces/blog'
import { cloudObjectToURL } from '@utils/cloudStorage'
import React from 'react'
import { Link } from 'react-router-dom'
import './styles.css'

interface Props {
  article: ArticleData
}

const Unit: React.FC<Props> = ({ article, isLast }) => {
  return (
    <Link className="d-block black-link" to={`/blog/articles/${article.slug}`}>
      <div className="relative" styleName="wrapper">
        <img
          src={cloudObjectToURL(article.cover, { size: '500x500', toJPG: true })}
          className="tw-w-full"
        />
        <div styleName="mask" className="bold fs16 text-center text-uppercase">
          {article.title}
        </div>
      </div>

      <DottedLine className="tw-my-6" />
    </Link>
  )
}

export default Unit
