import TranslationText from '@components/common/TranslationText'
import { DEFAULT_DATE_FORMAT } from '@constants/date'
import { format } from 'date-fns'

import React from 'react'

const maxShowNumber = 30
const regex = /@\w+\s/

const truncate = (text: string, withQuot?: boolean) => (
  <span className="bold text-capitalize">
    {withQuot && '"'}
    {text.slice(0, maxShowNumber).trim() + (text.length >= maxShowNumber ? '...' : '')}
    {withQuot && '"'}
  </span>
)

export const bold = (text: string, withQuot?: boolean) => (
  <span className="bold">
    {withQuot && '"'}
    {text}
    {withQuot && '"'}
  </span>
)

export const preview = (text?: string) => {
  const script = (text || '').replace(regex, '')

  return script.length > maxShowNumber ? truncate(script, true) : bold(script, true)
}

export const translate = (texts: any) => {
  if (!texts) {
    return null
  }
  if (typeof texts === 'string') {
    return truncate(texts, false)
  }

  return <TranslationText texts={texts}>{(text: string) => truncate(text, false)}</TranslationText>
}

const today = new Date()
const todayStr = format(today, DEFAULT_DATE_FORMAT)

export const phase = (createdAt?: Date, endAt?: Date) => {
  const start = createdAt ? format(createdAt, DEFAULT_DATE_FORMAT) : todayStr
  const end = endAt ? format(endAt, DEFAULT_DATE_FORMAT) : todayStr

  return [start, end].join('-')
}
