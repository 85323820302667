import React from 'react'
import useAuth from '@hooks/useAuth'
import { openSignUpModal } from '@hooks/useSignUpModal'

const MyReviewLink = () => {
  const { user } = useAuth()
  if (!user)
    return (
      <div className="strong black-link" onClick={openSignUpModal}>
        MY REVIEWS
      </div>
    )

  return (
    <a
      className="strong black-link"
      href={`/discover/users/${user.username}/reviews`}
      target="_blank"
    >
      MY REVIEWS
    </a>
  )
}

export default MyReviewLink
