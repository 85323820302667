import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import ActionButton from '@components/common/ActionButton'
import './styles.css'

const ConfirmModal = ({ ...props }) => {
  const { open } = props

  return (
    <Modal
      backdropClassName="modal-confirm-backdrop"
      dialogClassName={`delete-modal modal-confirm ${props.className}`}
      bsSize={props.size}
      show={open}
      animation={props.animation}
      onHide={props.onCancel}
    >
      <Modal.Body>
        <h5>{props.children}</h5>

        <div className="delete-modal__buttons">
          {!props.disableYes && (
            <ActionButton
              className="bold"
              styleName="button"
              onClick={props.onConfirm}
              onSuccess={props.onCancel}
            >
              {props.renderYes || <FormattedMessage id="buttons.confirm" />}
            </ActionButton>
          )}
          {!props.disableNo && (
            <ActionButton className="bold" styleName="button" onClick={props.onCancel}>
              {props.renderNo || <FormattedMessage id="buttons.cancel" />}
            </ActionButton>
          )}
        </div>

        {props.renderUnderButton && props.renderUnderButton}
      </Modal.Body>
    </Modal>
  )
}

ConfirmModal.defaultProps = {
  size: 'sm',
  className: '',
  animation: true,
  disableYes: false,
  disableNo: false,
}

export default ConfirmModal
