const MODULE = 'MY_COLLECTION'

export const DELETE_COLLECTION_REQUEST = `${MODULE}_DELETE_COLLECTION_REQUEST`
export const DELETE_COLLECTION_SUCCESS = `${MODULE}_DELETE_COLLECTION_SUCCESS`
export const DELETE_COLLECTION_FAILED = `${MODULE}_DELETE_COLLECTION_FAILED`

export const MOVE_COLLECTION = `${MODULE}_MOVE_COLLECTION`
export const UPDATE_COLLECTION_POSITION_REQUEST = `${MODULE}_UPDATE_COLLECTION_POSITION_REQUEST`
export const UPDATE_COLLECTION_POSITION_SUCCESS = `${MODULE}_UPDATE_COLLECTION_POSITION_SUCCESS`
export const UPDATE_COLLECTION_POSITION_FAILED = `${MODULE}_UPDATE_COLLECTION_POSITION_FAILED`

export const FETCH_MY_COLLECTIONS_REQUEST = `${MODULE}_FETCH_MY_COLLECTIONS_REQUEST`
export const FETCH_MY_COLLECTIONS_SUCCESS = `${MODULE}_FETCH_MY_COLLECTIONS_SUCCESS`
export const FETCH_MY_COLLECTIONS_FAILED = `${MODULE}_FETCH_MY_COLLECTIONS_FAILED`

export const CREATE_COLLECTION_REQUEST = `${MODULE}_CREATE_COLLECTION_REQUEST`
export const CREATE_COLLECTION_SUCCESS = `${MODULE}_CREATE_COLLECTION_SUCCESS`
export const CREATE_COLLECTION_FAILED = `${MODULE}_CREATE_COLLECTION_FAILED`

export const ADD_PRODUCT_TO_COLLECTION_REQUEST = `${MODULE}_ADD_PRODUCT_TO_COLLECTION_REQUEST`
export const ADD_PRODUCT_TO_COLLECTION_SUCCESS = `${MODULE}_ADD_PRODUCT_TO_COLLECTION_SUCCESS`
export const ADD_PRODUCT_TO_COLLECTION_FAILED = `${MODULE}_ADD_PRODUCT_TO_COLLECTION_FAILED`

export const UPDATE_COLLECTION_REQUEST = `${MODULE}_UPDATE_COLLECTION_REQUEST`
export const UPDATE_COLLECTION_SUCCESS = `${MODULE}_UPDATE_COLLECTION_SUCCESS`
export const UPDATE_COLLECTION_FAILED = `${MODULE}_UPDATE_COLLECTION_FAILED`

export const FETCH_MY_SINGLE_COLLECTION_REQUEST = `${MODULE}_FETCH_MY_SINGLE_COLLECTION_REQUEST`
export const FETCH_MY_SINGLE_COLLECTION_SUCCESS = `${MODULE}_FETCH_MY_SINGLE_COLLECTION_SUCCESS`
export const FETCH_MY_SINGLE_COLLECTION_FAILED = `${MODULE}_FETCH_MY_SINGLE_COLLECTION_FAILED`

export const FETCH_FAVORITES_REQUSET = `${MODULE}_FETCH_FAVORITES_REQUSET`
export const FETCH_FAVORITES_SUCCESS = `${MODULE}_FETCH_FAVORITES_SUCCESS`
export const FETCH_FAVORITES_FAILED = `${MODULE}_FETCH_FAVORITES_FAILED`
