import { EarningHistoryParams, RewardCategory } from '@interfaces/rewards'
import { formatDate } from '@utils/dateFormat'
import { DEFAULT_DATE_FORMAT } from '@constants/date'
import { sortByDate } from '@utils/array'
import { Observable } from 'rxjs'
import { map, pluck } from 'rxjs/operators'
import ajax from '../api/ajax'

const ITEMS_PER_PAGE = 20

export const fetchEarningCredits = (): Observable<{
  content: number
  commissions: number
  referrals: number
}> =>
  ajax({
    url: `/me/rewards/earnings`,
  }).pipe(
    pluck('response', 'data'),
    map(({ reviews, ...rest }) => ({ ...rest, content: reviews }))
  )

export const fetchEarningHistory = (params: EarningHistoryParams) =>
  ajax({
    url: `/me/rewards/earnings/history`,
    query: {
      view: params.category,
      ...(params.status && { status: params.status }),
      ...(params.from && { from: params.from }),
      ...(params.to && { to: params.to }),
      page: params.page || 1,
      per: params.perPage || ITEMS_PER_PAGE,
    },
  }).pipe(
    pluck('response'),
    map(res => ({ ...res, perPage: params.perPage || ITEMS_PER_PAGE }))
  )

export const fetchReferralEarnings = (params: EarningHistoryParams) =>
  ajax({
    url: `/me/rewards/earnings/referrals`,
    query: {
      ...(params.status && { status: params.status }),
      page: params.page || 1,
      per: params.perPage || ITEMS_PER_PAGE,
    },
  }).pipe(
    pluck('response'),
    map(res => ({ ...res, perPage: params.perPage || ITEMS_PER_PAGE }))
  )

export const fetchEarningStatistics = (category: RewardCategory, from = '', to = '') =>
  ajax({
    url: `/me/rewards/earnings/daily_totals`,
    query: {
      view: category,
      from,
      to,
    },
  }).pipe(
    pluck('response', 'data'),
    map(dates => [...dates].sort((a, b) => sortByDate(b, a, 'date'))),
    map(dates => {
      const [fDay, fMonth, fYear] = from ? from.split('/') : formatDate(dates[0]?.date || new Date(), DEFAULT_DATE_FORMAT).split('/')
      const fromStr =  `${fYear}-${fMonth}-${fDay}`

      const [tDay, tMonth, tYear] = to ? to.split('/') : formatDate(dates[dates.length - 1]?.date || new Date(), DEFAULT_DATE_FORMAT).split('/')
      const toStr =  `${tYear}-${tMonth}-${tDay}`

      return [].concat(dates[0]?.date === fromStr ? [] : { date: fromStr, commissions: 0, referrals: 0, reviews: 0 })
        .concat(dates)
        .concat(dates[dates.length - 1]?.date === toStr ? [] : { date: toStr, commissions: 0, referrals: 0, reviews: 0 })
    }),
    map(array => array.map(arr => ({ ...arr, date: new Date(arr.date).getTime() })))
  )
