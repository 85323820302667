import { useQuery } from '@hooks/useRouter'
import { Platform } from '@interfaces/platform'
import { FC } from 'react'
import ProductMain from './ProductMain'
import ProductTitle from './ProductTitle'

interface ProductDetailsProps { }

const ProductDetails: FC<ProductDetailsProps> = props => {
  const query = useQuery()
  const link = (() => {
    switch (props.product.platform) {
      case Platform.JD:
        return `https://item.jd.com/${query.skuId ?? props.product.id}.html`

      default:
        return props.product.id.includes('-')
          ? `https://uland.taobao.com/item/edetail?id=${props.product.id}`
          : `https://item.taobao.com/item.htm?id=${props.product.id}`
    }
  })()

  return (
    <div>
      {/* Product title will be shown under product image on XS screen */}
      <div className="hidden-xs">
        <ProductTitle titles={props.product.titles} selected={props.selected} platform={props.product.platform} />
      </div>

      <ProductMain {...props} />

      {/* Shortcut for open product page on taobao */}
      <a target="_blank" className="hidden" accessKey="x" href={link} />
    </div>
  )
}

export default ProductDetails
