import ajax from '@api/ajax'
import { CheckedCoupon } from '@interfaces/coupon'
import { Observable, of } from 'rxjs'
import { pluck } from 'rxjs/operators'

const coupons = [
  {
    title: 'Hard work pays off in the future, laziness pays off now.',
    desc:
      'You have received a coupon for ¥5 off your next order. Coupon expires on July 31st, 2019.',
    code: '28EHID2910',
  },
  {
    title: 'Hard work pays off in the future, laziness pays off now.',
    desc:
      'You have received a coupon for ¥5 off your next order. Coupon expires on July 31st, 2019.',
    code: 'BIRTHDAY123',
  },
]

export const fetchMyCoupons = () => of(coupons)

export const useCoupon = (code?: string): Observable<CheckedCoupon> =>
  ajax({
    url: `/coupons/${code}`,
    method: 'GET',
  }).pipe(pluck('response', 'data', 'coupon'))
