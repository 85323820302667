export interface SearchSuggestion {
  id: number

  categoryId?: number
  categoryIds?: number[]

  prop?: string

  score?: number
  sellerType?: 'tmall' | 'taobao'

  sort?: string
  term?: string | number
  title?: string

  type?: string

  brandId?: number
  minPrice?: number
  maxPrice?: number
}

export enum SUGGESTION {
  SELLER = 'seller',
  ARTICLE = 'article',
  SEARCH = 'search',
  CATEGORY = 'category',
  TOP = 'top',
}
