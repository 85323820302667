import React     from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import './styles.css'

const renderImage = (url, width, height) => (
  <img 
    className='img-circle'
    src={url} 
    width={width} 
    height={height} 
  />
)

const handleClick = fn => e => fn && fn()

const ImageList = ({ images, ...props }) => {
  return (
    <ul styleName='wrapper' className='list-unstyled clearfix tw-mb-0 text-truncate' onClick={handleClick(props.onClick)}>
      {
        images.map((image, index) => (
          <li key={index} style={{ marginRight: props.gutter }}>
            {
              image.link ? (
                <Link to={image.link} className='black-link'>
                  { renderImage(image.url, props.width, props.height) }
                  { image.label }
                </Link>
              ) : renderImage(image.url, props.width, props.height)
            }
          </li>
        ))
      }
    </ul>
  )
}

ImageList.defaultProps = {
  images: [],
  width: 50,
  height: 50,
  gutter: 5,
}

ImageList.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  gutter: PropTypes.number,
}

export default ImageList
