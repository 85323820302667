import { Desktop, MobileAndTablet } from '@components/common/Responsive'
import SearchInputWithSuggestions from '@components/common/SearchInputWithSuggestions'
import React from 'react'
import { Navbar } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useFestivalLogo } from '@hooks/useFestivalLogo'
import '../styles.css'

const defaultDesktopLogo = 'https://res.cloudinary.com/baopals/image/upload/v1529644590/Logo.png'
const defaultMobileLogo =
  'https://res.cloudinary.com/baopals/image/upload/v1529644657/logo_on_wechat.png'

export const DesktopLogo = ({ festivalLogo }) => (
  <Link to="/">
    <img
      styleName="logo"
      src={festivalLogo ? festivalLogo.desktopLogoUrl : defaultDesktopLogo}
      alt="Baopals"
      data-festival={!!festivalLogo}
    />
  </Link>
)

const ResponsiveNavBar = () => {
  const { festivalLogo } = useFestivalLogo()

  return (
    <>
      <MobileAndTablet>
        <div className="d-flex">
          <div styleName="mobile-logo-wrapper" className="d-flex bd-r-light pr-4">
            <Link to="/">
              <img
                src={festivalLogo ? festivalLogo.mobileLogoUrl : defaultMobileLogo}
                alt="Baopals"
                className="tw-w-full"
              />
            </Link>
          </div>
          <SearchInputWithSuggestions />
        </div>
      </MobileAndTablet>

      <Desktop>
        <Navbar.Header id="logo-container">
          <DesktopLogo festivalLogo={festivalLogo} />
        </Navbar.Header>
      </Desktop>
    </>
  )
}

export default ResponsiveNavBar
