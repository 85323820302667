import React from 'react'
import { FormattedMessage } from 'react-intl'
import CurrencySwitcher from '@components/common/CurrencySwitcher'
import LangSwithcer from './LangSwitcher'
import './styles.css'
import { BeijingTime } from '@components/layout/NavLinks/BeijingTime'
import ChinaOnly from '../ChinaOnly'
import { isGreaterChina } from '@utils/i18n'

const MobileMenuPanel = ({ ...props }) => {
  let languages = {
    en: 'EN',
    zh: 'header.lang_chinese',
  }

  return (
    <div styleName="wrapper" className="bd-b-light px-3 pb-3 tw-pt-0 text-center">
      <div className="bold d-flex justify-content-between align-items-end">
        <div>
          <LangSwithcer />
        </div>

        <ChinaOnly
          otherwiseRender={
            <span
              className="fs12"
              style={{ fontWeight: 400 }}
            >
              <BeijingTime />
            </span>
          }
          isChina={isGreaterChina}
        />
        <div>
          <CurrencySwitcher display="menu" />
        </div>
      </div>

      {props.children}
    </div>
  )
}

export default MobileMenuPanel
