import ajax from '@api/ajax'
import { ConflictError } from '@errors/ConflictError'
import { AuthProvider, LoginForm, SignUpForm, ValidateUserOpt, WechatLoginForm } from "@interfaces/auth";
import { parseTokenFromHttpHeader } from '@utils/auth'
import { Observable, of, throwError } from 'rxjs'
import { switchMap, catchError, map, pluck } from 'rxjs/operators'

const extractAuthHeader = xhr => parseTokenFromHttpHeader(xhr.getResponseHeader('authorization'))

export const me = () =>
  ajax({
    url: '/me',
  }).pipe(
    pluck('response', 'data'),
    map(({ user, ...rest }) => ({
      ...rest,
      user: {
        ...user,
        tooltips: user.tooltips ?? [],
        ...(user.locationCountry === null && { locationCountry: 'China' })
      },
    }))
  )

export const bindAccount = (provider: AuthProvider, form: WechatLoginForm) => {
  return ajax({
    url: `/wechat/bind`,
    method: 'POST',
    body: {
      credentials: {
        profile: provider,
        code: form.code,
      },
    },
  }).pipe(pluck('response', 'data'))
}

export const unbindAccount = () => {
  return ajax({
    url: `/wechat/bind`,
    method: 'DELETE',
  })
}

export const login = (provider: AuthProvider, form: LoginForm | WechatLoginForm) => {
  const path = (() => {
    switch (provider) {
      case AuthProvider.WECHAT_OPEN_PLATFORM:
      case AuthProvider.WECHAT_PUBLIC_ACCOUNT:
        return 'wechat'

      default:
        return 'email'
    }
  })()

  return ajax({
    url: `/${path}/sign_in`,
    method: 'POST',
    body: {
      credentials: {
        ...(form.code && {
          profile: provider,
          code: form.code,
        }),
        ...(form.usernameOrEmail &&
          form.password && {
            email: form.usernameOrEmail,
            password: form.password,
          }),
      },
    },
  }).pipe(
    map(res => ({
      tk: extractAuthHeader(res.xhr),
      ...res.response.data,
    }))
  )
}

export const signup = (form: SignUpForm) => {
  const formData = new FormData()

  if (form.avatar instanceof File) {
    formData.append('user[avatar]', form.avatar)
  }

  if (form.affiliateToken) {
    formData.append('user[affiliate_token]', form.affiliateToken)
  }

  for (const key of ['firstName', 'lastName', 'username', 'email', 'password', 'country']) {
    formData.append(`user[${key}]`, form[key])
  }

  if (form.auth) {
    const { provider, uid, extras } = form.auth
    formData.append('user[auth][provider]', provider)
    formData.append('user[auth][uid]', uid)

    if (extras) {
      for (const field in extras) {
        formData.append(`user[auth][extras][${field}]`, extras[field])
      }
    }
  }

  return ajax({
    url: '/sign_up',
    method: 'POST',
    body: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }).pipe(
    catchError(err => {
      if (err.error) {
        if (validateValueTaken(err.error.username)) {
          throw new ConflictError('Username has been taken')
        } else if (validateValueTaken(err.error.email)) {
          throw new ConflictError('Email has been taken')
        } else if (validateValueTaken(err.error.affiliateToken)) {
          throw new ConflictError('Affiliate_token has been taken')
        }
      }

      throw err
    })
  )
}

const validateValueTaken = (errors: any[]) => (errors || []).some(v => v.error === 'taken')

// Password - forgot
export const forgotPassword = (email: string) =>
  ajax({
    url: `/reset_password`,
    method: 'POST',
    body: {
      credentials: {
        email,
      },
    },
  })

// Password - update
export const updatePassword = (currentPassword: string, newPassword: string): Observable<string> =>
  ajax({
    url: `/me/password`,
    method: 'PUT',
    body: {
      credentials: {
        currentPassword,
        newPassword,
      },
    },
  })

// Password - reset
export const resetPassword = (token: string, password: string): Observable<string> =>
  ajax({
    url: `/reset_password`,
    method: 'PUT',
    body: {
      credentials: {
        token,
        password,
      },
    },
  }).pipe(map(res => extractAuthHeader(res.xhr)))

// Email - update
export const updateEmail = (email: string): Observable<string> =>
  ajax({
    url: `/me/email`,
    method: 'PUT',
    body: {
      credentials: {
        email,
      },
    },
  })

export const fetchOpenIdByCode = (code: string): Observable<string> =>
  ajax({
    url: '/wechat/openid',
    query: { code },
  }).pipe(pluck('response', 'data', 'openid'))

// Confirm email
export const confirmEmail = (token: string): Observable<string> =>
  ajax({
    url: `/confirm`,
    method: 'PUT',
    body: {
      token,
    },
  }).pipe(
    map(res => ({
      tk: extractAuthHeader(res.xhr),
      ...res.response.data,
    }))
  )

export const sendConfirmEmail = (email: string): Observable<string> =>
  ajax({
    url: `/resend_confirmation`,
    method: 'PUT',
    body: {
      email,
    },
  })

export const validateUser = (opt: ValidateUserOpt): Observable<string> =>
  ajax({
    url: `/users/exists`,
    method: 'GET',
    query: { ...opt },
  }).pipe(
    pluck('response', 'data', 'exists'),
    switchMap(isExists => {
      if(opt.type === 'affiliate_token') {
        return isExists === true ? of(undefined) : throwError({ duplicated: `This affiliate token doesn't exist` })
      }

      return isExists === true ? throwError({ duplicated: `This ${opt.type} has been taken` }) : of(undefined)
    })
  )
