import { OrderType } from '@interfaces/order'
import { PaymentMethodCode } from '@interfaces/payment'
import { FetchMyWalletQuery, Operation, Payment, Wallet } from '@interfaces/wallet'
import { readOpenIdFromLocalStorage } from '@utils/auth'
import qs from 'qs'
import { Observable, of } from 'rxjs'
import { concatMap, map, pluck, switchMap } from 'rxjs/operators'
import ajax from '../api/ajax'
import { createPaymentCharge } from './order'

const BASE = '/me/wallet'

const commonQueryToParams = (query: FetchMyWalletQuery) => ({
  page: query.page,
  per: query.per || 20,
  from: query.from,
  to: query.to,
})

const withdrawalsQueryToParams = (query: FetchMyWalletQuery) => ({
  ...commonQueryToParams(query),
  payment_method: query.paymentMehtod,
})

export const fetchMyWallet = (
  query: FetchMyWalletQuery
): Observable<{
  wallet: Wallet
  operations: Operation[]
}> =>
  ajax({
    url: `${BASE}?${qs.stringify(
      {
        ...commonQueryToParams(query),
        operations: query.operations,
      },
      {
        arrayFormat: 'brackets',
      }
    )}`,
    method: 'GET',
  }).pipe(
    pluck('response'),
    map(({ meta, data }) => ({
      ...data,

      operations: data.operations.map(o => {
        const { id, ...rest } = o.extra || {}
        return { ...o, ...rest }
      }),
      // operations: data.operations.map(({ extra: { id, ...extra }, ...o }) => ({
      //  ...o,
      //  ...extra,
      // })),
      ...meta,
    }))
  )

export const fetchMyWalletPayments = (
  query: FetchMyWalletQuery
): Observable<{
  payments: Payment[]
}> =>
  ajax({
    url: `${BASE}/payments?${qs.stringify(withdrawalsQueryToParams(query))}`,
    method: 'GET',
  }).pipe(
    pluck('response'),
    map(({ meta, data }) => ({ ...data, ...meta }))
  )

export const fetchMyWithdrawHistory = (
  query: FetchMyWalletQuery
): Observable<{
  operations: Operation[]
}> =>
  ajax({
    url: `${BASE}/withdrawals?${qs.stringify(withdrawalsQueryToParams(query))}`,
    method: 'GET',
  }).pipe(
    pluck('response'),
    map(({ meta, data }) => ({ ...data, ...meta }))
  )

export const topup = (amount: string, currency: string): Observable<Topup> =>
  ajax({
    url: `${BASE}/top_ups`,
    method: 'POST',
    body: {
      amount,
      currency,
    },
  }).pipe(pluck('response', 'data', 'topUp'))

export const topupWallet = (amount: string, currency: string, channel: PaymentMethodCode) =>
  of({ amount, currency }).pipe(
    switchMap(({ amount, currency }) =>
      topup(amount, currency).pipe(
        concatMap(transaction =>
          createPaymentCharge(transaction.id, {
            type: OrderType.Wallet,
            channel: [PaymentMethodCode.AliPayHkPC, PaymentMethodCode.AliPayHkWap].includes(channel) ? PaymentMethodCode.AirwallexCard : channel,
            openid: readOpenIdFromLocalStorage(),
            isAlipayHkPc: channel === PaymentMethodCode.AliPayHkPC,
            isAlipayHkWap: channel === PaymentMethodCode.AliPayHkWap,
          }).pipe(
            map(payment => ({
              payment,
              transaction,
            }))
          )
        )
      )
    )
  )

export const createSubscription = (params) => ajax({
  url: `/me/subscriptions`,
  method: 'POST',
  body: {
    ...params
  },
}).pipe(pluck('response', 'data'))

export const fetchSubscriptionPlans = () => ajax({
  url: `/subscription/plans`,
  method: 'GET',
}).pipe(pluck('response', 'data'))

export const fetchSubscriptionPaymentCharge = () => ajax({
  url: `/me/client_secret`,
  method: 'GET',
}).pipe(pluck('response', 'data'))


export const refundPayment = (
  paymentId: number,
  amount: number
): Observable<{ operation: Operation; payment: Payment; refund: Refund }> =>
  ajax({
    url: `${BASE}/refunds`,
    method: 'POST',
    body: {
      refund: {
        payment_id: paymentId,
        amount,
      },
    },
  }).pipe(pluck('response', 'data'))
