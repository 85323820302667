import ROUTING from '@constants/url'
import { AccountAlreadyBindError } from '@errors/AuthError'
import useAuth from '@hooks/useAuth'
import { useLocation, useQuery } from '@hooks/useRouter'
import { WechatAuthState } from '@interfaces/auth'
import { fetchOpenIdByCode } from '@services/auth'
import { isWeChat } from '@utils/'
import {
  readAuthTokenToLocalStorage,
  readOpenIdFromLocalStorage,
  saveOpenIdToLocalStorage,
} from '@utils/auth'
import { getWechatOAuthUrl } from '@utils/wechat'
import qs from 'qs'
import React, { FC, ReactNode, useEffect, useState } from 'react'
import { Subscription } from 'rxjs'

// Component for doing WeChat auth in WeChat environment(wechat browser)
// 1. When there's no `openid` exist, force redirection and ask the code from wechat to get the `openid` and save it to the localStorage.
// 2. When tehre's `openid` found, force redirection and ask the code from wechat and do the login automacially.
const WechatAuth: FC<{ children: ReactNode }> = ({ children }) => {
  const { bindAccount } = useAuth()
  const { code, state } = useQuery()
  const { location, navigate } = useLocation()
  const openId = readOpenIdFromLocalStorage()

  const tk = readAuthTokenToLocalStorage()

  const [isDoneWithWechatAutoSignIn, setIsDoneWithWechatAutoSignIn] = useState(
    isWeChat() && (!tk || !openId) ? false : true
  )

  // Do auto login in the wechat browser
  useEffect(() => {
    const sub = new Subscription()
    // if (!tk && state !== WechatAuthState.MANUALLY_AUTH && isWeChat() && !isMiniProgram()) {
    // Do the auth only in the wechat environment(wechat browser)
    if (!tk && isWeChat()) {
      // Force redirection to get the code for different cases
      if ((!openId && state !== WechatAuthState.AUTO_AUTH) || (openId && !state && !code)) {
        const url = getWechatOAuthUrl(WechatAuthState.AUTO_AUTH)
        console.warn('Wechat auth url')
        console.warn(url)
        window.location.href = url
      } else if (state === WechatAuthState.AUTO_AUTH && code && !openId) {
        // Fetch and save the `openid` to the localStorage
        sub.add(
          fetchOpenIdByCode(code).subscribe(
            id => {
              saveOpenIdToLocalStorage(id)
              navigate(location.pathname, { replace: true })
            },
            err => {
              console.warn('Error during fetch openid', err)
              navigate(location.pathname, { replace: true })
            },
            () => {
              setIsDoneWithWechatAutoSignIn(true)
            }
          )
        )
      }
    }

    return () => sub.unsubscribe()
  }, [])

  // Update history after finish wechat auth
  useEffect(
    () => {
      if (code) {
        if (state === WechatAuthState.AUTO_AUTH && (tk || openId)) {
          const { state, code, redirect_to, ...query } = qs.parse(
            decodeURIComponent(location.search),
            { ignoreQueryPrefix: true }
          )

          setIsDoneWithWechatAutoSignIn(true)

          navigate(
            {
              pathname: redirect_to || location.pathname,
              search: qs.stringify(query),
            },
            { replace: true }
          )

          return
        }

        if (state === WechatAuthState.BIND_ACCOUNT) {
          bindAccount(code)
            .then(user => {
              console.log('Bind with WeChat successfully', user)
              navigate(ROUTING.WECHAT_BIND_SUCCESS, { replace: true })
            })
            .catch(err => {
              if (err instanceof AccountAlreadyBindError) {
                console.warn('Account bind with WeChat already...')
                navigate(location.pathname, { replace: true })
              } else {
                console.error('Something wrong when trying bind account', err)
              }
            })
        }

        setIsDoneWithWechatAutoSignIn(true)
      }
    },
    [code, state]
  )

  return isDoneWithWechatAutoSignIn ? <>{children}</> : <div>Loading...</div>
}

export default WechatAuth
