import TagManager from 'react-gtm-module'
import { useProductPrice } from './useProduct'

export const useEcommerceItem = id => {
  const prices = useProductPrice(id)

  const price = prices.minPrice / 100

  return {
    recordAddToCart: product => {
      TagManager.dataLayer({ dataLayer: { ecommerce: null } }) // Clear the previous ecommerce object.
      TagManager.dataLayer({
        dataLayer: {
          event: 'add_to_cart',
          ecommerce: {
            currency: 'CNY',
            items: [{ ...product, price: price.toFixed(2), currency: 'CNY' }],
          },
        },
      })
    },
  }
}

export const useBeginCheckout = () => {
  return {
    record: ({ items, coupon, orderTotal }) => {
      const list = items.map(item => {
        const p = item.price / 100
        return { ...item, price: p.toFixed(2), currency: 'CNY' }
      })

      TagManager.dataLayer({ dataLayer: { ecommerce: null } }) // Clear the previous ecommerce object.

      TagManager.dataLayer({
        dataLayer: {
          event: 'begin_checkout',
          ecommerce: {
            currency: 'CNY',
            value: (orderTotal / 100).toFixed(2),
            coupon: coupon?.code?.toUpperCase(),
            items: list,
          },
        },
      })
    },
  }
}
