import React, { useState } from "react"
import { CategoryData } from "@interfaces/blog"
import SubMenu from "@components/common/MobileNav/MobileMenuToggler/Menu/Menus/SubMenu";
import { useLocation } from "@hooks/useRouter";

interface Props {
  category: CategoryData
  label: string
  items: {
    text: string,
    link: string
  }[]
  pathname: string
  stringifyQuery: any
}

const SubCategories: React.FC<Props> = ({ label, category, items, pathname, stringifyQuery }) =>{
  const { navigate } = useLocation()

  const handleToggle = (slug: string) => {
    return navigate({
      pathname: pathname,
      search: stringifyQuery(slug),
    })
  }

  const getProps = (slug: string) => ({
    label: label,
    ...(!!items.length && {
      items: items,
      open: true,
      onToggleLabel: () => handleToggle(slug)
    }),
    ...(!items.length && {
      labelLink: `${pathname}?${stringifyQuery(slug)}`
    }),
  })

  return (
    <SubMenu
      label={label}
      items={items}
      {...getProps(category.slug)}
    />
  )
}

export default SubCategories
