import {useResponsive, Viewport} from '@hooks/useResponsive'
import React from 'react'
import {Link} from 'react-router-dom'

import './styles.css'

export interface LinkProps {
  url: string
  text: React.ReactNode
  openNewTab?: boolean
}

interface HelpProps {
  title: React.ReactNode
  links: LinkProps[]
  styleName?: string
  className?: string
}

const Help: React.SFC<HelpProps> = props => {
  const isXS = useResponsive([Viewport.XS]).some(v => v)

  const styleName = isXS ? [''] : ['dot']
  if (props.className === 'text-left') {
    styleName.push('left')
  }
  if (props.className === 'text-right') {
    styleName.push('right')
  }

  const className = ['tw-mt-4 upper flex-center']
  if (props.className === 'text-right') {
    className.push('justify-content-end')
  }
  if (isXS) {
    className.push('bold fs12')
  }

  return (
    <div className={props.className || ''}>
      {!isXS && <div className="fs20 upper bold l-h-1 tw-mb-8">{props.title}</div>}

      {props.links.map((link: LinkProps) => {
        if (!link) {
          return null
        }

        return (
          <div
            key={`help-${link.url}`}
            styleName={styleName.join(' ')}
            className={className.join(' ')}
          >
            {link.openNewTab || !link.url.startsWith('/') ? (
              <a className="black-link" target="_blank" href={link.url}>
                {link.text}
              </a>
            ) : (
              <Link to={link.url} className="black-link">
                {link.text}
              </Link>
            )}
          </div>
        )
      })}
    </div>
  )
}

Help.defaultProps = {
  styleName: '',
  className: '',
}

export default Help
