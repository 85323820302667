export default {
  CLOUD_DINARY_BASE: 'https://res.cloudinary.com/baopals/image/upload',

  // Account
  ACCOUNT: '/account',
  ACCOUNT_ADDRESS: '/account/address',
  ACCOUNT_SETTINGS: '/account/settings',

  // Auth
  ACCOUNT_VERIFICATION: '/account/verify',

  // Wallet
  ACCOUNT_MY_WALLET: '/account/wallet',
  ACCOUNT_WITH_DRAW: '/account/withdraw',
  ACCOUNT_TOP_UP: '/account/topup',
  ACCOUNT_BAOPALS_PRO: '/account/baopals_pro',

  // Rewards
  ACCOUNT_EARNINGS: '/account/earnings',
  ACCOUNT_EARNINGS_COMMISSIONS: '/account/earnings/commissions',
  ACCOUNT_EARNINGS_CONTENT: '/account/earnings/content',
  ACCOUNT_EARNINGS_REFERRALS: '/account/earnings/referrals',

  // Orders
  ACCOUNT_ORDERS: `/account/orders`,
  ACCOUNT_PARCELS: `/account/orders?parcels_only=1`,

  ACCOUNT_WAREHOUSE: `/account/orders/in-warehouse`,

  ACCOUNT_TICKETS: `/account/tickets`,
  ACCOUNT_PROFILE: `/account/profile`,

  ACCOUNT_COUPONS: '/account/coupons',
  ACCOUNT_REFERRALS: '/account/referrals',

  NOTIFICATION: '/notifications',
  NOTIFICATION_SETTINGS: `/notifications/settings`,

  WECHAT_BIND_SUCCESS: '/bind-wechat/success',

  CLOTHING_SIZE_GUIDE: '/clothing-size-guide',

  GET_THE_APP: '/get-the-app',

  ACCOUNT_GET_BAOPALS_PRO: '/account/get-baopals-pro',

  HOT: '/discover/whatshot',
} as const
