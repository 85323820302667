import ProgressiveSkeleton from '@components/common/ProgressiveSkeleton'
import Head from '@components/home/MobileShuffleHead'
import {Department} from '@interfaces/home'
import {showPath} from '@routing/departmentRoutes'
import {sampleSize} from 'lodash'
import React, {useEffect, useState} from 'react'
import {FormattedMessage} from 'react-intl'
import {Link} from 'react-router-dom'
import {renderCoverUrl} from '../utils'
import './styles.css'

// show 4 dept each row
const showNumber = 16

const HeadText = (
  <FormattedMessage id="home_department_head_mobile" defaultMessage="DEPARTMENTS SPOTLIGHT" />
)

const Loading = () => (
  <>
    {new Array(showNumber).fill('0').map((_, index) => (
      <div key={index} className="p-4 bg-white">
        <ProgressiveSkeleton
          width="100%"
          height="0"
          className="d-block tw-mt-0"
          enableMoveAnimation={true}
          styleName="load"
        />
      </div>
    ))}
  </>
)

const MobileCategories: React.SFC<any> = props => {
  const [data, setData] = useState<Department[]>([])

  const setShowData = () => setData(sampleSize(props.departments, showNumber))

  useEffect(
    () => {
      setShowData()
    },
    [props.departments]
  )

  return (
    <div className="container">
      <Head text={HeadText} onShuffle={setShowData} />

      <div className="text-center" styleName="wrapper">
        {data && data.length > 0 ? (
          data.map((dept: Department) => (
            <div
              key={`dept-${dept.slug}`}
              className="tw-py-2 bg-white tw-h-full"
              data-dept-name={dept.slug}
            >
              <Link className="black-link" to={showPath(dept.slug)}>
                <div styleName="cover">
                  <img src={renderCoverUrl(dept.slug)} />
                </div>
                <div className="fs10 tw-mt-2">{dept.shortName}</div>
              </Link>
            </div>
          ))
        ) : (
          <Loading />
        )}
      </div>
    </div>
  )
}

export default MobileCategories
