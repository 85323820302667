import { ImageWithSticker } from '@components/common/Loading'
import ProductLink from '@components/common/ProductLink'
import { CommonProduct } from '@interfaces/product'
import { cloudObjectToURL } from '@utils/'
import { orderBy } from 'lodash'
import React, { FC } from 'react'
import { DEFAULT_PRODUCT_PLACEHOLDER } from '@constants/index';
import './styles.css'

const imagePlaceHolder = DEFAULT_PRODUCT_PLACEHOLDER

interface Props {
  covers: {
    cover: {
      id: string
      provider: string
    }
    position: number
    id: string
    query?: any
  }[]
  isWhatsHot?: boolean
}

const Images: FC<Props> = props => {
  const covers = orderBy(props.covers, ['position'], ['asc'])

  const data =
    covers.length > 4
      ? covers.slice(0, 5)
      : covers.concat(new Array(5 - covers.length).fill(null))

  return (
    <div styleName="images">
      {data.map((d, index) => {
        const key = `product-${index}`
        if (!d) {
          return (
            <div
              className="bg-fit-center"
              styleName="image"
              style={{ backgroundImage: `url(${imagePlaceHolder})` }}
              data-whatsHot={props.isWhatsHot}
            />
          )
        }

        const size = index == 0 ? '300x300' : '150x150'
        const image = cloudObjectToURL(d.cover, { size })

        return (
          <div key={key}>
            <ProductLink
              id={d.id}
              platform={d.cover.provider}
              {...(props.query && {
                affiliateToken: props.query.at,
                affiliateVia: props.query.via
              })}
            >
              <div
                className="bg-fit-center"
                styleName="image"
                style={{ backgroundImage: `url("${image}")` }}
                data-whatsHot={props.isWhatsHot}
              />
            </ProductLink>
          </div>
        )
      })}
    </div>
  )
}

export default Images
