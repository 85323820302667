import React from 'react'
import { SUGGESTION } from '@interfaces/suggestion'

export const SuggestionItem = ({ item }) => (
  <>
    {item.type === SUGGESTION.SELLER && (
      <>
        <i className="icon-seller-shop" />{' '}
      </>
    )}
    {item.type === SUGGESTION.ARTICLE && (
      <>
        <i className="icon-blog" />{' '}
      </>
    )}
    {item.level === SUGGESTION.TOP && (
      <>
        <i className="icon-hot text-danger" />{' '}
      </>
    )}
    <span className={item.type === SUGGESTION.SELLER ? 'text-capitalize' : ''}>{item.title}</span>
  </>
)
