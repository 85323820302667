import * as CartActionCreator from '@actions/cart'
import { titlesToString } from '@utils/string'
import * as ActionCreator from '@actions/products'
import AlreadyInCartError from '@errors/Cart/Item/AlreadyInCartError'
import { useProductImage } from '@hooks/useProductImages'
import { Platform } from '@interfaces/platform'
import { ShippingStatus } from '@interfaces/product'
import { fetchProductShipping } from '@services/'
import { fetchSimilarProducts } from '@services/product'
import { getAvailableStock, getMinimumQuantity, getUnavailability } from '@utils'
import { isSkuComplete } from '@utils/'
import { platformToProvider } from '@utils/platform'
import { totalPrice } from '@utils/product/priceUtils'
import { composeSkuKey } from '@utils/product/skuOptionUtils'
import { getPrices } from '@utils/productUtils'
import { isSupermarket } from '@utils/productUtils'
import { isValidAffiliateVia } from '@utils/reward'
import { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useAddress } from './useAddress'
import { useMyCountry } from './useAuth'
import { DEFAULT_COUNTRY_CODE } from '@constants/'
import { useCountries } from '@hooks/useCountries'
import { useEcommerceItem } from './useGTM'

export const useProductPrice = (id: number) => {
  const { qty, product } = useSingleProduct(id)
  const supermarket = isSupermarket(product)
  const { selected } = useProductSku(id)

  const [minPrice, maxPrice] = product.platform === Platform.JD && product.skuCategories.length > 0 && selected.length === 0 ? [-100, -100] : getPrices(product, selected, qty || 1)

  const subTotal = Math.min(minPrice, maxPrice)
  const shippingPrice =
    supermarket && subTotal > 10000
      ? 0
      : (product.shipping?.price > 0 ? product.shipping.price : 0)

  return {
    subTotal,
    shippingPrice,

    minPrice,
    maxPrice,
    minOriginalPrice: product.promotion && !isSkuComplete(product, selected) ? totalPrice(product.minPrice) : null,
    maxOriginalPrice: product.promotion && !isSkuComplete(product, selected) ? totalPrice(product.maxPrice) : null,
  }
}

export const useSimilarProducts = (id: number, page: number) => {
  const [loading, setLoading] = useState(true)
  const [products, setProducts] = useState([])
  const [isEnd, setIsEnd] = useState(false)

  useEffect(() => {
    setLoading(true)

    const sub = fetchSimilarProducts(id, page).subscribe(res => {
      setProducts(page === 1 ? res : products.concat(res))
      setLoading(false)
      if (res.length === 0) {
        setIsEnd(true)
      }
    })

    return () => sub.unsubscribe()
  }, [id, page])

  return { loading, products, isEnd }
}

export const useResetProductMinQty = () => {
  const dispatch = useDispatch()

  return (id: number) => dispatch(ActionCreator.resetProductQty(id))
}

export const useShippingStatus = ({ id, platform, shippingChecker = true }) => {
  const { defaultAddress } = useAddress()
  const { countries } = useCountries()
  const { isSignedIn, isInternational, code, locationCountryCode, defaultZone } = useMyCountry()
  const [status, setStatus] = useState(ShippingStatus.UNKNOWN)

  const countryCode = code ?? locationCountryCode

  useEffect(() => {
    const currentCountry = countries.find(c => c.abbr === countryCode)

    const sub = (() => {
      if (shippingChecker && isInternational && platformToProvider(platform) === Platform.TAOBAO && countryCode !== DEFAULT_COUNTRY_CODE && isSignedIn && currentCountry?.warehouse !== 'xingji') {
        return fetchProductShipping(id, countryCode, defaultAddress?.zoneId ?? defaultZone, Platform.TAOBAO, true).subscribe(
          (res) => {
            const data = res?.[0]

            if (data && data.available === true && data.stock > 0) {
              setStatus(ShippingStatus.GLOBAL_SHIPPING)
            } else {
              setStatus(ShippingStatus.CHINA_ONLY)
            }
          })
      }
    })()

    return () => sub?.unsubscribe?.()
  }, [isSignedIn, countryCode])

  return status
}

export const useSingleProduct = (id: number, customOptions?: { skus?: string, skuId?: number, zoneId?: number, platform?: Platform, refetch?: boolean }) => {
  const dispatch = useDispatch()
  const defOptions = { id, zoneId: 310106, platform: Platform.TAOBAO, refetch: false }
  const options = { ...defOptions, ...customOptions }

  const { code, locationCountryCode, isLoading } = useMyCountry()
  const country = code ?? locationCountryCode

  const { loading, error, products, qty, selected } = useSelector(state => state.product)

  const product = products.find(p => (p.id === id) && (p.country === country))

  useEffect(() => {
    if (options.refetch && !product && !isLoading) {
      console.warn('refetch product data...', options)

      dispatch(ActionCreator.fetchSingleProduct.request({
        id,
        platform: options.platform,
        skus: options.skus,
        skuId: options.skuId,
        country,
        ...(options.zoneId && { zoneId: options.zoneId }),
      }))
    }
  }, [...Object.values(options), country, isLoading])

  return { loading, product, qty, selected: (selected[id] || []), error }
}

export const useProductCategory = (id: number) => {
  const { product } = useSingleProduct(id)

  return product?.categories || []
}

export const useProductSku = (id: number) => {
  const dispatch = useDispatch()

  const { qty, selected } = useSingleProduct(id)

  const onSelect = option => {
    dispatch(ActionCreator.selectProductSku({ id, option: [].concat(option) }))
  }

  const onUnselect = option => {
    dispatch(ActionCreator.unselectProductSku({ id, option }))
  }

  return { qty, selected, onSelect, onUnselect }
}

export const useProductQty = (id: number) => {
  const dispatch = useDispatch()
  const { qty } = useProductSku(id)

  const handleQtyChange = val => dispatch(ActionCreator.updateProductQty({ id, qty: val }))

  return { quantity: qty, onChange: handleQtyChange }
}

export const useProductAffiliate = () => useSelector(state => state.productAffiliate)

export const useProductUnavailability = (id: number) => {
  const { loading, product, qty } = useSingleProduct(id)
  const { selected } = useProductSku(id)

  if (loading) {
    return 'loading'
  }

  if (product.shipping?.price === -1) {
    return isSupermarket(product) ? 'area_supermarket' : 'area'
  }

  const result = getUnavailability(
    product,
    selected,
    qty,
  )

  return result
}

export const formatEcommerceItem = (product) => {

  const variant = product.selectedSku.map(s => titlesToString(s.titles))

  const catsObj = {}

  const categoryNames = product.categories.map(c => titlesToString(c.titles))

  for (let i = 0; i < categoryNames.length; i++) {
    if (i === 0) {
      catsObj['item_category'] = categoryNames[i]
    } else {
      catsObj[`item_category_${i + 1}`] = categoryNames[i]
    }
  }

  return {
    item_name: titlesToString(product.titles),
    item_id: product.id,
    price: product.price,
    // price: '33.75',
    quantity: product.quantity,
    ...catsObj,
    // item_brand: "Google",

    ...(product.shop?.titles?.zhCn && { affiliation: titlesToString(product.shop?.titles) }),
    ...(variant.length > 0 && { item_variant: variant.join(',') }),
  }
}

export const useProductAction = (id: number) => {
  const dispatch = useDispatch()

  const { defaultAddress } = useAddress()
  const country = defaultAddress?.country ?? DEFAULT_COUNTRY_CODE

  const doAdd = item => dispatch(CartActionCreator.addItemToCart(item))
  const updateCartItemQty = item => dispatch(CartActionCreator.updateCartItemQty(item))

  const { via, ...affiliate } = useProductAffiliate()

  const { product, qty } = useSingleProduct(id)
  const { selected } = useProductSku(id)
  const { selected: selectedImage } = useProductImage(id)


  const { recordAddToCart } = useEcommerceItem(id)


  const handleAdd = () => {
    if (!isSkuComplete(product, selected)) {
      return Promise.reject(<FormattedMessage id="errors.cart.incomplete_sku_id" />)
    }

    const sku = product.skuMapping[composeSkuKey(selected)]

    const payload = {
      productId: product.id,
      image: selectedImage,
      quantity: qty,

      ...(sku && {
        skuId: sku.id,
        sku: sku.skuId,
      }),

      shippingZoneId: defaultAddress?.zoneId ?? 310106,

      platform: product.platform,
      country,
      // shippingZoneId: shipping.zoneId,

      ...(affiliate.affiliate && { affiliate_token: affiliate.affiliate }),
      ...(affiliate.affiliateSId && { affiliate_shareable_id: affiliate.affiliateSId }),
      ...(isValidAffiliateVia(via) && { affiliate_via: via }),
    }


    return doAdd(payload).then(resp => {
      recordAddToCart(formatEcommerceItem({ ...product, quantity: qty, selectedSku: selected || [] }))

      return resp

    }).catch(({ payload: err }) => {
      if (err.error instanceof AlreadyInCartError) {
        const error = err.error

        recordAddToCart(formatEcommerceItem({ ...product, quantity: payload.quantity, selectedSku: selected || [] }))

        return updateCartItemQty({
          id: error.cartItem.id,
          qty: error.cartItem.quantity + payload.quantity,
          opts: {
            affiliate: payload.affiliate_token,
            affiliateSId: payload.affiliate_shareable_id,
            via: payload.affiliate_via,
          },
        }).catch(e => {
          return Promise.reject(formatErrorMessage(e.payload, { product, selectedOptions: selected, shipping: 10 }))

        })
      } else {
        return Promise.reject(formatErrorMessage(err, { product, selectedOptions: selected, shipping: 10 }))
      }
    })
  }

  return { onAddToCart: handleAdd }
}

function formatErrorMessage(e, args) {
  if (!e?.message) {
    return null
  }


  const values = {
    limit: e.limit,
    minimum: getMinimumQuantity.apply(null, Object.values(args)),
    maximum: getAvailableStock.apply(null, Object.values(args)),
  }

  if (typeof e.message === 'function') {
    return e.message(values)
  }

  return e.message || 'An unexpected error happened. Please try again.'
}
