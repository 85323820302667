import * as ActionCreator from '@actions/warehouse'
import { of } from 'rxjs'
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { Epic } from './index'

export const fetchWarehouseItems: Epic = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(ActionCreator.fetchWarehouseItems)),
    switchMap(({ payload }) =>
      api.fetchWarehouseItems().pipe(
        map(
          ({
            shippingLines,
            dimensions,
            items,
            formula,
            warehouseFees,
            selection,
            pendingItems,
            bulkDiscount,
            warehouseFormulas,
            largePackage,
            extraFees,
            proDiscountPercent
          }) =>
            ActionCreator.fetchWarehouseItemsSuccess({
              items,
              formula,
              dimensions,
              warehouseFees,
              pendingItems,
              packageingFormula: selection,
              shippingLines,
              bulkDiscount,
              warehouseFormulas,
              largePackage,
              extraFees,
              proDiscountPercent
            })
        ),
        catchError(() => of(ActionCreator.fetchWarehouseItemsFailure(new Error('error'))))
      )
    )
  )
