import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'

import Product from './Product'
import './styles.css'

class Collection extends Component {
  _preventClickOnDragging = e => {
    // If mouse released while still dragging - do nothing
    if (this.slider.innerSlider.state.animating) {
      e.preventDefault()
      return
    }
  }

  render() {
    const { products, currentLocale, translations, sliderSettings } = this.props
    const slider = { ...settings, ...(sliderSettings || {}) }

    settings.slidesToShow = this.props.slidesCount
    settings.slidesToScroll = this.props.slidesCount

    return (
      <Slider {...slider} ref={e => (this.slider = e)}>
        {products.map(product => (
          <Product
            key={product.id}
            product={product}
            currentLocale={currentLocale}
            translations={translations}
            preventClickOnDragging={this._preventClickOnDragging}
          />
        ))}
      </Slider>
    )
  }
}

Collection.defaultProps = {
  slidesCount: 7,
}

Collection.propTypes = {
  slidesCount: PropTypes.number,
}

export default Collection

const Arrow = ({ side, className, style, onClick }) => (
  <div className={className} style={style} onClick={onClick} styleName="slider">
    <span className={`slick-divider ${side}`} />
    <i className={`fa icon-arrow-${side}`} />
  </div>
)

const settings = {
  className: 'responsive-slider',
  dots: true,
  infinite: false,
  slidesToShow: 7,
  slidesToScroll: 7,
  nextArrow: <Arrow side="right" />,
  prevArrow: <Arrow side="left" />,
  lazyLoad: true,
  responsive: [
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 5,
      },
    },
  ],
}
