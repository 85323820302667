export default {
  // fetch notifications
  FETCH_NOTIFICATIONS_REQUEST: '@NOTIFICATION/FETCH_NOTIFICATIONS_REQUEST',
  FETCH_NOTIFICATIONS_SUCCESS: '@NOTIFICATION/FETCH_NOTIFICATIONS_SUCCESS',
  FETCH_NOTIFICATIONS_FAILURE: '@NOTIFICATION/FETCH_NOTIFICATIONS_FAILURE',

  // mark notification as read
  MARK_AS_READ_REQUEST: '@NOTIFICATION/MARK_AS_READ_REQUEST',
  MARK_AS_READ_SUCCESS: '@NOTIFICATION/MARK_AS_READ_SUCCESS',
  MARK_AS_READ_FAILURE: '@NOTIFICATION/MARK_AS_READ_FAILURE',

  // mark all notification as read
  MARK_ALL_AS_READ_REQUEST: '@NOTIFICATION/MARK_ALL_AS_READ_REQUEST',
  MARK_ALL_AS_READ_SUCCESS: '@NOTIFICATION/MARK_ALL_AS_READ_SUCCESS',
  MARK_ALL_AS_READ_FAILURE: '@NOTIFICATION/MARK_ALL_AS_READ_FAILURE',

  // real time notifications
  RECEIVE_DONE: '@NOTIFICATION/RECEIVE_DONE',
} as const
