import SEO from '@components/SEO'
import { Route, Switch } from 'react-router-dom'
import data from './data.json'

const Meta = () => (
  <Switch>
    {data.map(({ path, title, desc }, index) => (
      <Route path={path} key={index}>
        <SEO title={title} description={desc} />
      </Route>
    ))}
  </Switch>
)

export default Meta
