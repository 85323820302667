import FullScreenModal from '@components/common/FullScreenModal'
import LineThroughText from '@components/common/LineThroughText'
import { FC, useState } from 'react'
import { CurrencyItemProps } from './'
import './styles.css'
import CurrencySelector from '@components/common/CurrencySelector'

const CurrencyMenu: FC<CurrencyItemProps> = props => {
  const [open, setOpen] = useState(false)

  const handleClick = (currency: any) => {
    props.onChange({ currency: currency.value, locale: 'en' }).then(({ currency }) => {
      props.onCurrencyChange(currency)
      setOpen(false)
    })
  }

  return (
    <>
      <div
        styleName="selected"
        onClick={() => setOpen(true)}
        data-disabled={props.menuItems.some(m => m.disabled)}
      >
        {props.selectedCurrency}
      </div>

      <FullScreenModal open={open} onClose={() => setOpen(false)} styleName="menu">
        <LineThroughText styleName="heading">SELECT YOUR CURRENCY</LineThroughText>
        <div styleName="selectWrapper">
          <CurrencySelector
            selected={props.selectedCurrency}
            disabled={props.disabled}
            onChange={handleClick}
            currencies={props.menuItems}
          />
        </div>
      </FullScreenModal>
    </>
  )
}

export default CurrencyMenu
