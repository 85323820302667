import { listViewLayoutAtom } from 'atoms/list-view-layout'
import { ProductCardLoading } from '@components/common/ProductCard'
import { useAtomValue } from 'jotai'

const ARR = new Array(24).fill('').map((_, idx) => idx + 1)

export const Loading = () => {
  const count = useAtomValue(listViewLayoutAtom)

  const classNames = count == 1 ? 'tw-basis-full' : 'tw-basis-1/2 md:tw-basis-1/3 lg:tw-basis-1/4'

  return (
    <div className="tw-flex tw-flex-wrap">
      {ARR.map(v => (
        <div className={classNames} key={v}>
          <div className="tw-m-3">
            <ProductCardLoading />
          </div>
        </div>
      ))}
    </div>
  )
}
