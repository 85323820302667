import React from 'react'
import AnimatedDropdown from '@components/common/AnimatedDropdown'
import { Nav } from 'react-bootstrap'
import { Link, matchPath } from 'react-router-dom'
import './styles.css'
import { useRouter } from '@hooks/useRouter'
import { useMyCountry } from '../../../../../hooks/useAuth'

const MENUS = [
  { link: '/discover/whatshot', renderContent: "What's Hot", componentClass: Link, path: '/discover/whatshot' },
  {
    link: '/discover/reviews?date=last_week',
    renderContent: 'Reviews',
    componentClass: Link,
    path: '/discover/reviews'
  },
  { link: '/discover/shops?filter=all', renderContent: 'Shops', componentClass: Link, path: '/discover/shops' },
]

const BLOG = { link: '/blog', renderContent: 'Blog', componentClass: Link }

const DiscoverDropdown = () => {
  const { isInternational } = useMyCountry()
  const { location } = useRouter()
  const { pathname } = location

  const menus = isInternational ? MENUS.concat(BLOG) : MENUS

  return (
    <Nav pullRight className="hidden-md">
      <AnimatedDropdown
        className="fs14"
        styleName={!!matchPath(pathname, { path: '/discover' }) ? 'active' : ''}
        title="DISCOVER"
        id="dropdown-discover"
        menuItems={menus}
      />
    </Nav>
  )
}

export default DiscoverDropdown
