import AddCollectionBtn from '@components/common/AddCollectionBtn'
import { Default, Mobile } from '@components/common/Responsive'
import ShoppingCartIcon from '@components/common/ShoppingCartIcon'
import UnstyledList from '@components/common/UnstyledList'
import React, { FC } from 'react'
import './styles.css'

const Actions: FC<{ id: number; cover: string; skuId?: number }> = ({
  id,
  cover,
  skuId,
  ...props
}) => {
  const rest = {
    id,
    pic: cover,
    platform: props.platform,
    withLabel: false,
  }

  return (
    <UnstyledList inline={true}>
      <span styleName="collection">
        <Default>
          <AddCollectionBtn openStyle="modal" {...rest} />
        </Default>
        <Mobile>
          <AddCollectionBtn openStyle="menu" {...rest} />
        </Mobile>
      </span>
      <span className="fs18">
        <ShoppingCartIcon id={id} platform={props.platform} skuId={skuId} />
      </span>
    </UnstyledList>
  )
}

export default Actions
