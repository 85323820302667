import DottedLine from '@components/common/DottedLine'
import ViewMore from '@components/common/ProductDetailsModal/ViewMore'
import ProductShipping from '@components/product/v4/ProductDetails/ProductShipping'
import React from 'react'
import { Route } from 'react-router-dom'
import ButtonGroup from '../ButtonGroup'
import ProductSkus from '../ProductSkus'
import { ProductImagesForProduct } from './ProductImages'
import ProductInfo from './ProductInfo'

import ProductPageTour from '@components/common/Tour/ProductPageTour'
import { Platform } from '@interfaces/platform'
import './styles.css'
import { ClothingSizeGuide } from './ClothingSizeGuide'

interface ProductMainProps { }

type Props = ProductMainProps

const ProductMain: React.SFC<Props | any> = props => {
  const { product } = props

  const isTmallProduct = product.platform === Platform.TMALL

  return (
    <div styleName="main" className="row" data-supermarket={product.isSupermarket}>
      <div className="col-sm-4 col-md-5">
        <div styleName="images" className="product-carousel-wrapper">
          <ProductImagesForProduct
            id={product.id}
            tmallBadge={isTmallProduct}
            supermarketBadge={product.isSupermarket}
            prettyUpImage={[Platform.TMALL, Platform.JD].includes(product.platform) ? true : false}
            platform={product.platform}
            sellerId={product.sellerId}
            showCamera={true}
          />
        </div>
      </div>
      <div className="col-sm-8 col-md-7">
        <div className="product-main-wrapper">
          <ProductInfo
            selected={props.selected}
            sellerId={product.sellerId}
            titles={product.titles}
            shopName={(product.seller?.titles?.en && product.seller.titles) || (product.shop && product.shop.titles)}
            productsCount={product.seller?.productsCount ?? 0}
            {...props.product.location}
            platform={product.platform}
            id={product.id}
          />

          <ClothingSizeGuide categories={product.categories} />

          {/* Product SKU */}
          <ProductSkus id={product.id} />
          <ProductShipping id={product.id} />

          {/* Visible on product modal */}
          <DottedLine className="tw-mt-4 product-btn-separator hidden" />

          <ButtonGroup
            id={product.id}
            styleName="button"
            supermarket={product.isSupermarket}
            platform={product.platform}
            onAddedToCart={props.onAddedToCart}
          />

          <ViewMore id={product.id} platform={product.platform} />
        </div>
      </div>
      <Route path="/:platform(express|tb)?/products/:id" component={ProductPageTour} />
    </div>
  )
}

export default ProductMain
