import * as ACTION from '../constants/productProps'
import { fetchProductPropsByCategory } from '@services/products'

export const resetProps = () => {
  return {
    type: ACTION.RESET_PROPS,
  }
}

const fetchProductPropsRequest = () => {
  return {
    type: ACTION.FETCH_PRODUCT_PROPS,
    payload: {
      status: 0,
    },
  }
}

const fetchProductPropsSuccess = props => {
  return {
    type: ACTION.FETCH_PRODUCT_PROPS,
    payload: {
      status: 1,
      props,
    },
  }
}

const fetchProductPropsFailed = error => {
  return {
    type: ACTION.FETCH_PRODUCT_PROPS,
    payload: {
      status: -1,
      error,
    },
  }
}

export const fetchProductProps = category => async (dispatch, getState) => {
  dispatch(fetchProductPropsRequest())

  try {
    const selectedProps = getState().productProps.selectedProps

    const props = await fetchProductPropsByCategory(category).toPromise()

    const newProps = props.map(prop => ({
      ...prop,
      values: prop.valueList,
    }))

    dispatch(fetchProductPropsSuccess(newProps))
  } catch (err) {
    dispatch(fetchProductPropsFailed(err))
  }
}

// Init product brands when fetching products data
export const fetchProductBrands = brands => {
  return {
    type: ACTION.FETCH_PRODUCT_BRANDS,
    payload: {
      brands,
    },
  }
}

// Update selected brands
export const updateSelectedBrands = brand => {
  return {
    type: ACTION.UPDATE_SELECTED_BRANDS,
    payload: {
      brand,
    },
  }
}

// Update selected props
export const updateSelectedProps = (propId, propVal) => {
  return {
    type: ACTION.UPDATE_SELECTED_PROPS,
    payload: {
      propId,
      propVal,
    },
  }
}

export const updateSelectedBrandsAndProps = (brands, props) => {
  return {
    type: ACTION.UPDATE_SELECTED_BRANDS_AND_PROPS,
    payload: {
      brands,
      props,
    },
  }
}

export const togglePropSection = toggle => {
  return {
    type: ACTION.TOGGLE_PROPS_SECTION,
    payload: {
      toggle,
    },
  }
}

export const initSelectedProps = prop => {
  const props = prop.split(';')
  const selectedProps = props.map(prop => {
    const [id, value] = prop.split(':')
    return {
      id: +id,
      value: value.split(',').map(v => +v),
    }
  })

  return {
    type: ACTION.INIT_SELECTED_PROPS,
    payload: {
      selectedProps,
    },
  }
}
