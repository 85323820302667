import { FC } from 'react'
import CollectionInfo from './CollectionInfo'
import './styles.css'

interface CollectionUnitProps {
  pic: string
  itemsCount: number

  className?: string

  onSelect: () => void
}

const CollectionUnit: FC<CollectionUnitProps> = props => (
  <div
    styleName="media"
    className={`v4-media px-4 pb-3 tw-my-6 cursor-pointer ${props.className}`}
    onClick={props.onSelect}
  >
    <img src={props.pic} alt="" className="img-circle tw-mr-4" styleName="img" />

    <div className="v4-media-body text-left">
      <CollectionInfo itemsCount={props.itemsCount} id={props.id} name={props.name} />
    </div>
  </div>
)

CollectionUnit.defaultProps = {
  className: '',
  itemsCount: 0,
}

export default CollectionUnit
