import AwesomeBurgerMenu from '@components/common/AwesomeBurgerMenu'
import React, { useEffect } from 'react'
import { CategoryData } from '@interfaces/blog'
import { sortBy } from 'lodash'
import SubCategories from '../SubCategories'
import { useLocation, useQuery } from '@hooks/useRouter'
import { capitalized } from '@utils/'
import qs from 'qs'

interface Props {
  categories: CategoryData[]
  open: boolean
  setOpen: () => void
}

const CategoriesMenu: React.FC<Props> = ({ categories, open, setOpen }) => {
  const mainCategories = sortBy(categories.filter(c => !c.parentId), 'position')
  const query = useQuery()
  const { location } = useLocation()
  const pathname = query.tab === 'blog' ? location.pathname : '/blog'
  const stringifyQuery = (slug: string) =>
    qs.stringify({ ...(query.tab === 'blog' && { tab: query.tab }), cat: slug })

  useEffect(
    () => {
      if (open) {
        setOpen(false)
      }
    },
    [query.cat]
  )

  return (
    <AwesomeBurgerMenu
      overNavbar={true}
      id="blog-categories-menu"
      open={open}
      position="right"
      onMenuStateChange={({ isOpen }: any) => setOpen(isOpen)}
      className={`menu-sort-filter`}
      width="60%"
    >
      <>
        <div className="strong text-center fs20 py-6 bd-b-light">Categories</div>

        {mainCategories.map(c => {
          const subCategories = categories.filter(s => s.parentId === c.id)

          return (
            <SubCategories
              key={c.id}
              label={c.title}
              items={subCategories.map(opt => ({
                text: capitalized(opt.title),
                link: `${pathname}?${stringifyQuery(opt.slug)}`,
              }))}
              category={c}
              pathname={pathname}
              stringifyQuery={stringifyQuery}
            />
          )
        })}
      </>
    </AwesomeBurgerMenu>
  )
}

export default CategoriesMenu
