import ACTION from '@constants/named-url'
import { HttpError } from '@errors/'
import { createAsyncAction } from 'typesafe-actions'

export const fetchNamedUrl = createAsyncAction(
  ACTION.FETCH_NAMED_URL_REQUEST,
  ACTION.FETCH_NAMED_URL_SUCCESS,
  ACTION.FETCH_NAMED_URL_FAILURE
)<null, string, HttpError>()

