import React, {FC, ReactNode} from 'react'
import {FormControl} from 'react-bootstrap'
import './styles.css'

interface SelectorProps {
  name: string
  value: string

  placeholder?: string

  wrapperClassName?: string
  className?: string

  onChange: (val: string) => void

  renderOptions: ReactNode

  arrowSize?: 'sm' | 'md'
}

const Selector: FC<SelectorProps> = ({value, ...props}) => {
  const handleChange = e => props.onChange(e.target.value)

  const isEmptyValue = typeof value === 'boolean' ? false : !value

  return (
    <span
      styleName="wrapper"
      className={`relative tw-w-full selector-wrapper d-inline-block ${props.wrapperClassName} arrow-${props.arrowSize
        }`}
      data-value-empty={isEmptyValue}
    >
      <FormControl
        data-value-empty={isEmptyValue}
        styleName="select"
        componentClass="select"
        name={props.name}
        className={`selector ${props.className}`}
        value={value}
        onChange={handleChange}
      >
        {props.placeholder && <option disabled={true} value={``}>{props.placeholder}</option>}
        {props.renderOptions}
      </FormControl>
    </span>
  )
}

Selector.defaultProps = {
  placeholder: '',
  className: '',
  wrapperClassName: '',
  arrowSize: 'sm',
  onChange: () => {},
}

export default Selector
