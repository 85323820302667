import * as ACTION from '../constants/collectionItems'
import { LOCATION_CHANGE } from 'connected-react-router'
import queryString from 'qs'
import { findCollectionItem } from '../actions/collectionItems'
import update from 'immutability-helper'

const initialState = {
  status: null,
  error: null,

  items: [],
  curPage: 1,
  total: 0,

  itemsPerPage: 16,

  selectAll: false,
}

export default function collectionItems(state = initialState, { type, payload = {} }) {
  if (!payload) return state
  const { status, error, ...data } = payload

  switch (type) {
    case LOCATION_CHANGE: {
      const query = queryString.parse(data.location.search, { ignoreQueryPrefix: true })
      const { page = 1 } = query

      return {
        ...state,
        curPage: isNaN(page) ? 1 : +page,
      }
    }

    case ACTION.DELETE_COLLECTION_ITEM_SUCCESS:
      return {
        ...state,
        items: state.items.filter(item => item.id !== payload.id),
      }

    case ACTION.TOGGLE_SELECTED_COLLECTION_ITEMS: {
      const selected = !state.selectAll
      return {
        ...state,
        selectAll: selected,
        items: state.items.map(i => ({ ...i, selected })),
      }
    }

    case ACTION.SELECT_COLLECTION_ITEM: {
      const items = state.items
      const curItemIndex = items.findIndex(i => i.id === data.id)

      return {
        ...state,
        items:
          curItemIndex > -1
            ? items
              .slice(0, curItemIndex)
              .concat({
                ...items[curItemIndex],
                selected: !items[curItemIndex].selected,
              })
              .concat(items.slice(curItemIndex + 1))
            : items,
      }
    }

    case ACTION.FETCH_COLLECTION_ITEMS_SUCCESS:
      return {
        ...state,
        items: payload.data,
      }
    //const currentItems = state.items || []
    //const newItems = +payload.page === 1 ? payload.products : currentItems.concat(payload.products)

    //return {
    //  ...state,
    //  status,
    //  error,
    //  items: newItems,
    //  curPage: +payload.page,
    //  total: payload.total,
    //  totalPage: Math.min(Math.floor((payload.total + payload.itemsPerPage - 1) / payload.itemsPerPage), 100),
    //  selectAll: false,
    //  itemsPerPage: payload.itemsPerPage
    //}

    case ACTION.MOVE_COLLECTION_ITEM: {
      const { id, index: atIndex } = payload

      const { items } = state

      const card = items.filter(c => c.id === id)[0]

      const { item: data, index } = findCollectionItem(id, items)

      const newItems = update(items, {
        $splice: [[index, 1], [atIndex, 0, data]],
      })

      return {
        ...state,
        items: newItems,
      }
    }

    case ACTION.UPDATE_COLLECTION_ITEM_SUCCESS: {
      const { data } = payload
      const index = state.items.findIndex(i => i.id === data.id)

      if (index === -1) return state

      return {
        ...state,
        items: state.items
          .slice(0, index)
          .concat(data)
          .concat(state.items.slice(index + 1)),
      }
    }

    default:
      return state
  }
}
