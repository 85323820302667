import React      from 'react';
import DottedLine from '@components/common/DottedLine';
import './styles.css'

type SectionProps = {
  renderLeft: React.ReactNode;
  renderRight: React.ReactNode;
}

const Section: React.SFC<SectionProps> = props => {
  return (
    <>
      <div className='d-flex align-items-center' styleName='wrapper'>
        <div className='section-left'>{ props.renderLeft }</div>
        <div className='text-right' styleName='right'>{ props.renderRight }</div>
      </div>
      <DottedLine />
    </>
  )
}

export default Section
