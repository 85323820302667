import AddCollectionBtn from '@components/common/AddCollectionBtn'
import { Default, Mobile } from '@components/common/Responsive'
import { useSingleProduct } from '@hooks/useProduct'
import { useProductImage } from '@hooks/useProductImages'
import { cloudObjectToURL } from '@utils/'
import { FC } from 'react'
import './styles.css'

interface AddtoCollectionProps {
  product: number
}

const AddToCollection: FC<AddtoCollectionProps> = props => {
  const { product } = useSingleProduct(props.product)
  const { selected } = useProductImage(props.product)

  const pic = cloudObjectToURL(selected)

  const rest = {
    id: product.id,
    pic,
    thumbnails: product.thumbnails,
    platform: product.platform,
  }

  return (
    <>
      <Default>
        <div styleName="wrapper">
          <AddCollectionBtn openStyle="modal" {...rest} />
        </div>
      </Default>
      <Mobile>
        <AddCollectionBtn openStyle="menu" {...rest} />
      </Mobile>
    </>
  )
}

export default AddToCollection
