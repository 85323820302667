import SearchInput from '@components/common/SearchInput'
import SearchQueries from '@components/common/SearchQueries'
import qs from 'qs'
import React from 'react'
import { useMatchPath, useQuery } from "@hooks/useRouter";

const Search = () => (
  <SearchQueries>
    {({q}, { history, location }) => {
      const isArticle = useMatchPath('/blog/articles')
      const query = useQuery()

      return (
        <SearchInput
          cleanable={true}
          value={q}
          onEnter={text =>
            history.push({
              pathname:  isArticle ? "/blog" : location.pathname,
              search: qs.stringify({
                ...(query.tab === 'blog' && { tab: query.tab }),
                ...(text && { q: text }),
              }),
            })
          }
          placeholder="Search Articles"
        />
      )
    }}
  </SearchQueries>
)

Search.defaultProps = {
  submitOnEnter: false,
}

export default Search
