import ProfileDetails from '@components/common/ProfileDetails'
import { cloudObjectToURL } from '@utils/'
import MediaLoading from '@components/common/MediaLoading'
import { useLanguage } from '@hooks/useLanguage'
import useAuth from '@hooks/useAuth'
import { UserProfile as IUserProfile } from '@interfaces/userProfile'
import React from 'react'
import { useUserProfile } from '@hooks/useUserProfile'
import { useQueryClient } from '@tanstack/react-query'

interface UserProfileProps {
  profile: IUserProfile

  onChange: any

  sellersCount: number
}

const UserProfile = (props: UserProfileProps) => {
  const { profile } = useUserProfile(props.username)
  const { updateMyProfile } = useAuth()
  const { locale } = useLanguage()

  const queryClient = useQueryClient()

  if (!profile) {
    return (
      <div className="container tw-my-8">
        <MediaLoading />
      </div>
    )
  }

  const defaultData = {
    displayName: profile.displayName || undefined,
    firstName: profile.firstName || undefined,
    lastName: profile.lastName || undefined,
  }

  const handleAvatarChange = (fn: any) => (avatarFile: any) =>
    fn(defaultData, avatarFile).then(p => {
      queryClient.setQueryData(['users', props.username], oldData => ({
        ...oldData,
        avatar: p.avatar,
      }))
    })

  const handleUpdateProfile = async (profile: any, avatar: any) => {
    return updateMyProfile({ ...profile, avatar, locale }).catch((e: any) => Promise.reject(e))
  }

  return (
    <ProfileDetails
      editable={profile.isMe}
      sellersCount={profile.sellersCount}
      avatar={profile.avatar}
      username={profile.username}
      displayName={profile.displayName}
      ordersCount={profile.ordersCount}
      reviewsCount={profile.reviewsCount}
      itemsCount={profile.itemsCount}
      collectionsCount={profile.collectionsCount}
      fortunePoints={profile.fortunePoints}
      onAvatarChange={handleAvatarChange(handleUpdateProfile)}
    />
  )
}

export default UserProfile
