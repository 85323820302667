import React from 'react'
import {GridColumn} from '@components/superMarket/MainCategory/Grid'
import {FC} from 'react'
import data from '../../../../../../../../data/jd-cat-0.json'
import {Desktop, Default, Mobile} from '@components/common/Responsive'
import WhyShopExpress from '../../WhyShopExpress'
import DottedLine from '@components/common/DottedLine'
import PopularOnExpress from '@components/product/JD/Popular'
import SEO from '@components/SEO'
import './styles.css'

const categories = [
  ...data.filter(d => !d.note).map(c => ({
    ...c,
    cssClass: `https://res.cloudinary.com/baopals/image/upload/v1710396066/categories/jd/newBg/jd_bg_${c.id
      }.jpg`,
    link: `/express/category/${c.id}`,
  })),
].sort((a, b) => `${a.name}`.localeCompare(`${b.name}`))

const CategoryList: FC<{onClick?: () => void}> = ({onClick}) => {
  return (
    <>
      <SEO
        title="Baopals Express"
        description="Baopals Express brings you fast delivery and quality assurance from China's largest single retailer. "
      />

      <div className="bodo text-center tw-my-6" styleName="heading">
        <img src="https://res.cloudinary.com/baopals/image/upload/v1593739689/express_logo.jpg" />
        {onClick && <i styleName="close" className="icon-close" onClick={onClick} />}
      </div>

      <Desktop>
        <DottedLine />
        <WhyShopExpress />
      </Desktop>

      <PopularOnExpress />

      <Default>
        <div className="department__sub-header">
          <h2 className="department__sub-header__featured dotted-text dotted-text--h2">
            Featured Categories
          </h2>
          <div className="line" />
        </div>
      </Default>

      <Mobile>
        <div styleName="featured-categories-header">
          <h3>Featured Categories</h3>
        </div>
      </Mobile>

      <div className="container">
        <div styleName="inner" className="category-jd-all">
          <div className="v4-row v4-no-gutters" styleName="wrapper" onClick={onClick}>
            {categories.map(c => (
              <GridColumn key={c.id} category={c} className="v4-col-4" />
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default CategoryList
