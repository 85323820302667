import React from 'react'
import { Link } from 'react-router-dom'

const TermsOfUse = () => (
  <small>
    By signing up, you agree to our{' '}
    <Link to="/terms-conditions" target="_blank">
      Terms of Service
    </Link>
    {' and '}
    <Link to="/privacy-policy" target="_blank">
      Privacy Policy
    </Link>
    .
  </small>
)

export default TermsOfUse
