import React, { FC } from 'react'
import SubMenu from '../SubMenu'

const DiscoverMenu: FC<any> = props => {
  const items = [
    {
      text: "WHAT'S HOT",
      link: `/discover/whatshot`,
    },
    {
      text: 'REVIEWS',
      link: '/discover/reviews?date=last_week',
    },
    {
      text: 'SHOPS',
      link: '/discover/shops?filter=all',
    },
  ]

  return <SubMenu label="Discover" items={items} {...props} />
}

export default DiscoverMenu
