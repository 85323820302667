import React from 'react'

const CategoryPic = ({ name, pic }) => (
  <img
    className={`tmall-cats ${pic}`}
    alt={name}
    {...pic.startsWith('http') && { style: { backgroundImage: `url("${pic}")` } }}
    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAlgAAAJYAQMAAACEqAqfAAAAA1BMVEX///+nxBvIAAAAAXRSTlMAQObYZgAAAENJREFUeNrtwQENAAAAwqD3T20ON6AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAL4NsiAAAVub+pEAAAAASUVORK5CYII="
  />
)

export default CategoryPic
