import ChinaOnly from '@components/common/ChinaOnly'
import {Link} from 'react-router-dom'

const Express = ({onHover}) => {
  return (
    <ChinaOnly>
      <li id="menu-express" className="dropdown-submenu" onClick={onHover} onMouseOver={onHover}>
        <Link to="/express/category">
          <i className="icon-dep-express fs14 tw-ml-1 tw-mr-4" />
          Baopals Express
        </Link>
      </li>
    </ChinaOnly>
  )
}

export default Express
