import getYear from 'date-fns/getYear'
import isAfter from 'date-fns/isAfter'
import isBefore from 'date-fns/isBefore'
import { useMyCountry } from '@hooks/useAuth'
import { isWestCountry, isChina, isGreaterChina } from '@utils/i18n'

export enum Festival {
  halloween = 'Halloween',
  christmas = 'Christmas',
}

interface Data {
  id: Festival
  url: string
  text: React.ReactNode
  color: string
  startDate: Date
  endDate: Date
  icon: string
}

export const selectFestival = () => {
  const currentDate = new Date()
  const currentYear = getYear(currentDate)
  const getDate = (time: string) => new Date(`${time}, ${currentYear}`)
  const { locationCountryCode } = useMyCountry()
  const halloweenStartDate = 'Aug 28'
  const halloweenEndDate = isChina(locationCountryCode) ? 'Oct 29' : 'Oct 25'
  const christmasStartDate = 'Oct 29'
  const christmasEndDate = isGreaterChina(locationCountryCode) ? 'Dec 23' :  'Dec 12'

  const FestivalsData: Data[] = [
    {
      id: Festival.halloween,
      url: `/departments/halloween`,
      text: Festival.halloween,
      color: '#da0808',
      startDate: getDate(halloweenStartDate),
      endDate: getDate(halloweenEndDate),
      icon:
        'https://res.cloudinary.com/baopals/image/upload/v1600739039/halloween/halloween_icon.png',
    },
    {
      id: Festival.christmas,
      url: `/departments/christmas`,
      text: Festival.christmas,
      color: '#cd152c',
      startDate: getDate(christmasStartDate),
      endDate: getDate(christmasEndDate),
      icon: 'https://res.cloudinary.com/baopals/image/upload/v1603851300/christmas/christmas.png',
    },
  ]

  const currentFestival = FestivalsData.find(d => {
    const after = isAfter(currentDate, d.startDate)
    const before = isBefore(currentDate, d.endDate)

    return after && before && d
  })

  return currentFestival
}
