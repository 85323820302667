export const isValidAffiliateVia = (via: string = '') => {
  if (!via) {
    return false
  }

  return [
    'facebook',
    'twitter',
    'pinterest',
    'vk',
    'email',
    'community',
    'collection',
    'product_review',
    'blog_article',
  ].includes(via.toLowerCase())
}
