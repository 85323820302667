import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import { baopalsScore } from  '../../../utils/product/ratingUtils'

import { tipsBaopalsScoreUrl } from  '../../../routing/blogRoutes'

import './styles.css'

class BaopalsScore extends Component {
  shouldComponentUpdate(nextProps) {
    if (
      nextProps.product !== this.props.product ||
      nextProps.delivery !== this.props.delivery ||
      nextProps.service !== this.props.service
    ) {
      return true
    }

    return false
  }

  renderTextStyle(score) {
    const style = {}
    const { color } = this.props
    if (color) {
      style.color = color
    }

    return (
      <div>
        <FormattedMessage id='product_page.bp_score' />: <span styleName='ratings' className='bold' style={style}>{score}/5</span>
      </div>
    )
  }

  renderCircleStyle(score) {
    const { width, height } = this.props
    const style = { width, height }
    const className = score <= 2.5 ? 'score--low' : ''

    return (
      <a href={tipsBaopalsScoreUrl()} target="_blank" rel="noopener" styleName='wrapper' style={style}>
        <div className="bp-score--img" data-score={score}></div>
        <span className={className} styleName='inner'>
          <span className="bp-score--title tw-my-0">
            <FormattedMessage id='product_page.bp_score' />
          </span>
          {score ? `${score}/5` : 'NA'}
        </span>
      </a>
    )
  }

  render() {
    const { display, product, delivery, service, sold, shop, children: fn } = this.props
    const score = baopalsScore({ delivery, service, product, sold, shop })

    if (typeof fn == 'function') {
      return fn(score)
    } else if (display === 'circle') {
      return this.renderCircleStyle(score)
    } else if (display === 'text') {
      return this.renderTextStyle(score)
    }

    return null
  }
}

BaopalsScore.propTypes = {
  delivery: PropTypes.number,
  product: PropTypes.number,
  service: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  display: PropTypes.oneOf(['circle', 'text']),
}

BaopalsScore.defaultProps = {
  width: 110,
  height: 110,
  delivery: 0,
  service: 0,
  product: 0,
  display: 'circle'
}

export default BaopalsScore
