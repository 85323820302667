import { Currency, CurrencySymbol } from '@interfaces/currency'
import { round } from '@utils/number'
import { DEFAULT_CURRENCY } from '@constants/index'

/**
 *  returns a formatted string fitting a range of prices
 *  with a given currency ('¥' by default)
 *
 *  ex:
 *
 *  - [1, 2] => "¥1 - 2"
 *  - [1, 1] => "¥1"
 *  - [1] => "¥1"
 **/
// export const CurrencyToSymbol: { [key in Currency]?: CurrencySymbol } = {
//   [Currency.CNY]: CurrencySymbol.CNY,
//   [Currency.USD]: CurrencySymbol.USD,
//   [Currency.AUD]: CurrencySymbol.AUD,
//   [Currency.HKD]: CurrencySymbol.HKD,
//   [Currency.SGD]: CurrencySymbol.SGD,
//   [Currency.CAD]: CurrencySymbol.CAD,
// }

export const formatPrice = (
  price: number,
  { exchangeRate, rounding, inverse } = { exchangeRate: 1, rounding: true, inverse: false }
) => {
  const priceWithRate = inverse ? price / exchangeRate : price * exchangeRate
  const result = Math.floor(priceWithRate) / 100

  return (rounding ? round(result) : result).toFixed(2)
}

export const rangeToString = (
  [minPrice, maxPrice]: number[],
  currency: string = DEFAULT_CURRENCY,
  exchangeRate: number = 1,
  currencyLocale: string,
  noPoint?: boolean
) => {
  const formatOpt = { exchangeRate, rounding: true, inverse: false }
  const prices = [formatPrice(minPrice, formatOpt)]

  if (maxPrice && maxPrice !== minPrice) {
    prices.push(formatPrice(maxPrice, formatOpt))
  }

  return formatCurrency(
    prices,
    currencyLocale,
    currency,
    noPoint ? formatCouponOptions : null
  )
}

export const formatCouponOptions = {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
}

export const formatCurrency = (
  prices: any,
  currencyLocale: string,
  currency: string,
  options?: any
) => {
  if (!currencyLocale || !currency) return ''

  const nf = (currencyDisplay: string, currencyOptions?: any) =>
    new Intl.NumberFormat(currencyLocale, {
      style: 'currency',
      currency: currency.toUpperCase(),
      currencyDisplay: currencyDisplay,
      ...(options && options),
      ...(currencyOptions && currencyOptions),
    })

  const formatPriceWithCurrency = price => {
    if (currency === 'ron') {
      return nf('name')
        .format(price)
        .replace('românești', '')
        .trimRight()
    }
    if (currency === 'sgd') {
      return nf('symbol')
        .format(price)
        .replace('$', 'S$')
    }
    if (currency === 'cad') {
      return nf('symbol')
        .format(price)
        .replace('CA$', 'C$')
    }
    if (currency === 'huf') {
      return nf('symbol', formatCouponOptions).format(Math.ceil(price))
    }

    return nf('symbol').format(price)
  }

  if (!Array.isArray(prices)) {
    return formatPriceWithCurrency(prices)
  } else {
    if (prices.length === 1) {
      return formatPriceWithCurrency(prices[0])
    }

    if (prices.length === 2) {
      return `${formatPriceWithCurrency(prices[0])}-${formatPriceWithCurrency(prices[1])}`
    }
  }
}
