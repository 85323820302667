import { WechatAuthState } from '@interfaces/auth'
import { getRedirectUri } from '@utils/wechat'
import React, { FC, useEffect } from 'react'
import CONFIG from '../../../../../../src/config.json'

interface QRCodeProps {
  bind?: boolean
}

const PREFIX = 'data:text/css;base64,'

const DEFAULT_PLACE_HOLDER =
  'LmltcG93ZXJCb3ggLnRpdGxlIHtkaXNwbGF5OiBub25lO30KI3d4X2RlZmF1bHRfdGlwID4gcCB7IGRpc3BsYXk6IG5vbmU7IH0KI3d4X2RlZmF1bHRfdGlwOmJlZm9yZSB7IGNvbnRlbnQ6ICdTY2FuIGluIFdlQ2hhdCB0byBzaWduIGluJzsgfQ=='

const ACCOUNT_BINDING_PLACE_HOLDER =
  'LmltcG93ZXJCb3ggLnRpdGxlIHtkaXNwbGF5OiBub25lO30KI3d4X2RlZmF1bHRfdGlwID4gcCB7IGRpc3BsYXk6IG5vbmU7IH0KI3d4X2RlZmF1bHRfdGlwOmJlZm9yZSB7IGNvbnRlbnQ6ICdTY2FuIGluIFdlQ2hhdCB0byBiaW5kIHlvdXIgYWNjb3VudCc7IH0='

const QRCode: FC<QRCodeProps> = ({ bind }) => {
  useEffect(() => {
    const obj = new WxLogin({
      self_redirect: false,
      id: 'login_container',
      appid: CONFIG.wechat.openPlatform.appId,
      scope: 'snsapi_login',
      redirect_uri: getRedirectUri(),
      // redirect_uri: `${origin}${encodeURIComponent(location.search)}`,
      state: bind ? WechatAuthState.BIND_ACCOUNT : WechatAuthState.OPEN_PLATFORM_AUTH,
      href: bind ? `${PREFIX}${ACCOUNT_BINDING_PLACE_HOLDER}` : `${PREFIX}${DEFAULT_PLACE_HOLDER}`,
    })
  }, [])

  return (
    <div>
      <div id="login_container" />
    </div>
  )
}

export default QRCode
