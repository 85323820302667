import ProductCard from '@components/common/ProductCard'
import React, { FC, useRef } from "react";
import { Link } from 'react-router-dom'
import ArticleInProducts from '../ArticleInProducts'
import ParcelInProducts from '@components/whatshot/ParcelInProduct'
import { useQuery } from '@hooks/useRouter'
import DepartmentInProducts from '@components/whatshot/DepartmentInProducts'
import ReviewUnit from '@components/common/ReviewUnit'
import SellerInProducts from '@components/whatshot/SellerInProducts'
import './styles.css'

interface ProductListProps {
  products: any[]

  gridSizes?: string[]
}

const ProductList: FC<ProductListProps> = ({ products, ...props }) => {
  const ref = useRef(null)
  const query = useQuery()

  const className = `${props.gridSizes.map(size => `v4-${size}`).join(' ')} tw-mb-12 tw-px-2`

  return (
    <div className="v4-row v4-no-gutters" styleName="wrapper">
      {products.map((product, index) => (
        <React.Fragment key={index}>
          {
            typeof product.minPrice !== 'undefined' && product.minPrice !== null ? (
              <div className={className}>
                <ProductCard
                  id={product.id}
                  skuId={product.skuId}
                  titles={product.titles}
                  cover={product.cover}
                  thumbnails={product.thumbnails}
                  sold={product.sold}
                  originalPrice={product.minPrice}
                  platform={product.platform}
                  sellerId={product.seller?.id}
                  unavailable={product.unavailable}
                  prettyUpImage={props.prettyUpImage}
                  renderThumbnails={props.renderThumbnails}
                  hideDiscount={props.hideDiscount}
                  ref={ref}
                  {...product.location && {
                    location: [product.location.state, product.location.city],
                  }}
                  {...product.promotion &&
                  product.promotion.minPrice && {
                    discountPrice: product.promotion.minPrice,
                  }}
                  {...query.at && { affiliateToken: query.at }}
                  {...query.via && { affiliateVia: query.via }}
                />
              </div>
            ) : (
              <>
                {product.slug && !product.shortName && (
                  <div
                    styleName="article"
                    className={`v4-col-lg-6 v4-col-md-8 v4-col-12 tw-mb-12 tw-px-2`}
                    style={{ height: ref.current?.height }}
                  >
                    <ArticleInProducts article={product} className="tw-px-2" />
                  </div>
                )}
                {product.zone && (
                  <div
                    className={`v4-col-lg-6 v4-col-md-8 v4-col-12 tw-mb-12 tw-px-2`}
                    style={{ height: ref.current?.height }}
                    styleName="parcel"
                  >
                    <ParcelInProducts parcel={product} />
                  </div>
                )}
                {product.slug && product.shortName && (
                  <div
                    styleName="article"
                    className={`v4-col-lg-6 v4-col-md-8 v4-col-12 tw-mb-12 tw-px-2`}
                    style={{ height: ref.current?.height }}
                  >
                    <DepartmentInProducts department={product} />
                  </div>
                )}
                {product.rating && (
                  <div
                    styleName="review"
                    className={`v4-col-lg-6 v4-col-md-8 v4-col-12 tw-mb-12 tw-px-2 relative`}
                    style={{ height: ref.current?.height }}
                  >
                    <ReviewUnit
                      review={product}
                      user={!!product.user && product.user}
                      product={!!product.product && product.product }
                      showByUser={true}
                      showCompleteDetails={false}
                      showImages={false}
                      isWhatHot={true}
                      showContentRows={5}
                      handleUpvote={props.upvoteReview}
                      handleDownvote={props.downvoteReview}
                      imageWidth={150}
                    />
                    <Link
                      to={`/discover/reviews?cat_id=${query.cat_id ? query.cat_id : 0}&page=1&category=${query.category ? query.category : ''}`}
                      target="_blank"
                      styleName="button position_absolute"
                    >
                      More Reviews
                    </Link>
                  </div>
                )}
                {product.productSeller && (
                  <div
                    styleName="seller"
                    className={`v4-col-lg-6 v4-col-md-8 v4-col-12 tw-mb-12 tw-px-2 `}
                    style={{ height: ref.current?.height }}
                  >
                    <SellerInProducts seller={product} />
                  </div>
                )}
              </>
            )
          }
        </React.Fragment>
      ))}
    </div>
  )
}

ProductList.defaultProps = {
  products: [],

  renderThumbnails: true,
  prettyUpImage: true,

  gridSizes: ['col-lg-3', 'col-md-4', 'col-6'],
}

export default ProductList
