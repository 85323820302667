import RedirectAccount from '@components/account/RedirectAccount'
import Affiliate from '@components/common/Affiliate'
import AfterLoginHook from '@components/common/AfterLoginHook'
import AsyncLoader from '@components/common/AsyncLoader'
import LoginModal from '@components/common/LoginModal'
import SignUpModal from '@components/common/SignUpModal'
import LoginRequiredHook from '@components/common/LoginRequiredHook'
import MobileNav from '@components/common/MobileNav'
import NotificationsProvider from '@components/common/Notifications'
import PrivateRoute from '@components/common/PrivateRoute'
import { Default, Mobile, MobileAndTablet } from '@components/common/Responsive'
import ScrollToTopOnMount from '@components/common/ScrollToTopOnMount'
import UserCurrencyHook from '@components/common/UserCurrencyHook'
import HomePage from '@components/home'
import Footer from '@components/layout/Footer'
import JDCategory from '@components/product/v4/Layout/WithCategorySidebar/JDCategory'
import WithSidebar from '@components/products/Layout/WithSidebar'
import ROUTING from '@constants/url'
import { useQuery, useRouter } from '@hooks/useRouter'
import { WechatAuthState } from '@interfaces/auth'
import { Platform } from '@interfaces/platform'
import { isNumeric } from '@utils/'
import { queryToPlatform } from '@utils/platform'
import qs from 'qs'
import { matchPath, Redirect, Route, Switch } from 'react-router-dom'
import ChinaOnly from './common/ChinaOnly'
import JDCategoryDetails from './common/JdCategoryDetails'
import Meta from './common/Meta'
import { Desktop } from './common/Responsive'
import { SelectedAddressHook } from './common/SelectedAddressHook'
import Lang from './Lang'
import Header from './layout/header'
import IntlNav from './layout/IntlNav'
import { NewProducts } from './NewProducts'
import { useEffect } from 'react'
import CookiePolicy from '@components/layout/Footer/CookiePolicy'
import { isWeChat } from '@utils'
import { SearchInputPage } from './common/SearchInputWithSuggestions/SearchInputPage'
import DiscoverUsers from './discover/Users'
import { usePaymentConfig } from "@hooks/usePaymentConfig";
import { useAddress } from '@hooks/useAddress'
import { isGreaterChina } from '@utils/i18n'
import { DEFAULT_COUNTRY_CODE } from '@constants/index'
import { useWarehouseItems } from '@hooks/useWarehouseItems'

const DynamicHomePage = () => {
  return (
    <ChinaOnly otherwiseRender={<WhatsHotHome />}>
      {/*<HomePage />*/}
      <WhatsHotHome />
    </ChinaOnly>
  )
}

const AllCategories = AsyncLoader({
  loader: () => import('@components/categories/JD/categories'),
})

const DepartmentsIndex = AsyncLoader({
  loader: () => import('@components/common/DepartmentsIndex'),
})

const ToastsContainer = AsyncLoader({
  loader: () => import('@containers/Toasts'),
})

const BacktoTop = AsyncLoader({
  loader: () => import('@components/layout/BacktoTop'),
})

const WhatsHotHome = AsyncLoader({
  loader: () => import('@components/whatshot/Home'),
})

const AsyncLoadNotFound = AsyncLoader({ loader: () => import('../components/NotFound') })

const AsyncBaopalsProCancelled = AsyncLoader({
  loader: () => import('@components/account/BaopalsPro/CancellationSuccess')
})

const AsyncLoadCheckoutSuccess = AsyncLoader({
  loader: () => import('@components/checkout/CheckoutSuccess'),
})
const AsyncLoadGetBaopalsProSuccess = AsyncLoader({
  loader: () => import('@components/account/BaopalsPro/SuccessPage'),
})
const AsyncLoadAccount = AsyncLoader({ loader: () => import('@components/account') })
const AsyncLoadSignup = AsyncLoader({ loader: () => import('@components/auth/SignUp') })
const AsyncLoadPrivacyPolicy = AsyncLoader({
  loader: () => import('../containers/static/PrivacyPolicyContainer'),
})
const AsyncLoadTermsConditions = AsyncLoader({
  loader: () => import('../containers/static/TermsConditionsContainer'),
})

const CheckoutContainer = AsyncLoader({
  loader: () => import('../containers/v2/Checkout/IndexContainer'),
})
const OrderCheckoutContainer = AsyncLoader({
  loader: () => import('../containers/OrderCheckoutContainer'),
})
const DepartmentContainer = AsyncLoader({
  loader: () => import('../containers/v2/Department/ShowContainer'),
})
const AsyncLoadHelpCenter = AsyncLoader({ loader: () => import('@components/helpCenter') })
const AsyncLoadDiscover = AsyncLoader({ loader: () => import('@components/discover') })
const BindWechatSuccessPage = AsyncLoader({
  loader: () => import('../containers/BindWechatSuccess'),
})
const TopupSuccess = AsyncLoader({
  loader: () => import('@components/account/Wallet/Topup/TopupSuccess'),
})
const Notifications = AsyncLoader({
  loader: () => import('@components/notifications'),
})

const GlobalShippingCalc = AsyncLoader({
  loader: () => import('@components/helpCenter/GlobalShippingCalc'),
})

const ProductPageWithAuth = AsyncLoader({
  loader: () => import('@components/product/ProductPage/ProductPageWithAuth'),
})

const SuperMarket = AsyncLoader({
  loader: () => import('@components/superMarket'),
})

const Business = AsyncLoader({
  loader: () => import('@components/business'),
})

const InitialCollection = AsyncLoader({
  loader: () => import('@components/discover/Shops/InitialCollection'),
})

const InstagramContent = AsyncLoader({
  loader: () => import('@components/instagramContent'),
})

const Products = AsyncLoader({
  loader: () => import('@components/products'),
})

const ClothingSizeGuide = AsyncLoader({
  loader: () => import('@components/clothingSizeGuide'),
})

const Blog = AsyncLoader({
  loader: () => import('@components/blog'),
})

const AppIntro = AsyncLoader({
  loader: () => import('@components/appIntro'),
})

const ProductsPage = ({
  match: {
    params: { id_or_keyword, platform, path },
  },
  location,
}) => {
  const rest: {
    platform: Platform
    category?: number
    seller?: number
    id?: number
  } = {
    platform: queryToPlatform(platform),
  }

  const isSingleProductPath = path === 'products'

  const isNumer = isNumeric(id_or_keyword)

  if (path === 'category') {
    rest.category = +id_or_keyword
  } else if (path === 'seller') {
    rest.seller = +id_or_keyword
  } else if (isSingleProductPath) {
    rest.id = id_or_keyword
  }

  if (!platform && ['category', 'seller'].includes(path)) {
    rest.platform = Platform.TMALL
  }

  if (!isNumer && ['category', 'seller'].includes(path)) {
    return <Redirect to="/404" />
  }

  const renderProducts = <Products key={location.key} innerClassName="container" {...rest} />

  return isSingleProductPath ? (
    <ProductPageWithAuth {...rest} />
  ) : (
    <>
      {rest.platform === Platform.JD && rest.category ? (
        <>
          <Default>
            <WithSidebar
              main={
                <>
                  <JDCategoryDetails id={rest.category} />
                  <Products key={location.key} {...rest} />
                </>
              }
              aside={<JDCategory activeCategory={rest.category} />}
            />
          </Default>
          <Mobile>{renderProducts}</Mobile>
        </>
      ) : (
        renderProducts
      )}
    </>
  )
}

const Inner = () => {
  const state = usePaymentConfig()

  useEffect(() => {
    state.actions.fetchPaymentConfig()
    const couponBannerStartAt = localStorage.getItem('couponBannerStartAt')
    if (!couponBannerStartAt) {
      localStorage.setItem('couponBannerStartAt', new Date())
    }
  }, [])

  const { defaultAddress } = useAddress()
  const _isGreatChina = isGreaterChina(defaultAddress?.country || DEFAULT_COUNTRY_CODE)
  const { actions } = useWarehouseItems()

  useEffect(() => {
    if (!_isGreatChina) {
       actions.fetchWarehouseItems().catch(console.error)
    }
  }, [_isGreatChina])


  const {
    location: { pathname, search },
  } = useRouter()

  const query = useQuery()

  if (
    query.code &&
    [WechatAuthState.MANUALLY_AUTH, WechatAuthState.OPEN_PLATFORM_AUTH].includes(query.state)
  ) {
    return <Redirect to="/signup" />
  }

  const referrer = `${pathname}${search}`

  const excludeBacktoTop = matchPath(pathname, {
    path: '/:platform(express|tb)?/:path(products|ig)',
  })
  const isBusinessPage = matchPath(pathname, { path: '/business-services' })

  if (isBusinessPage) {
    return <Business />
  }

  if (matchPath(pathname, { path: '/get-the-app' })) {
    return <AppIntro />
  }
  const sectionClassName = []
    .concat(!!matchPath(pathname, { path: '/discover' }) ? 'discover' : [])
    .join(' ')

  if(!state.bpRate) return null

  return (
    <div>
      <NotificationsProvider>
        <MobileAndTablet>
          {!excludeBacktoTop && <BacktoTop scroll={true} />}
          <MobileNav />
        </MobileAndTablet>

        <div id="page-wrap" data-url={pathname} data-referrer={referrer} data-wechat={isWeChat()}>
          <Desktop>{referrer.includes('supermarket') ? null : <IntlNav />}</Desktop>

          <Header />
          <section id="content" className={sectionClassName}>
            <Switch>
              <Route path="/" exact={true} component={DynamicHomePage} />
              <Route path="/search_input" component={SearchInputPage} />
              {/* Discover */}
              <Route path="/discover/collections/new" component={InitialCollection} />
              <Route path="/discover/users/:username" component={DiscoverUsers} />
              <Route path="/discover" component={AsyncLoadDiscover} />
              <Route path="/blog" component={Blog} />
              <PrivateRoute path="/notifications" component={Notifications} />
              {/* Super Market */}
              <Route path="/whatshot" component={WhatsHotHome} />
              <Route path="/supermarket" component={SuperMarket} />
              {/* User Auth */}
              <Route path="/signup" component={AsyncLoadSignup} />
              {/* Redirect supmarket sellers page to landing page */}
              <Redirect from="/seller/725677994" to="/supermarket" />
              <Redirect
                from="/seller/1910146537"
                to="/supermarket/search?seller=1910146537&category=52598012"
              />
              <Redirect from="/seller/2136152958" to="/supermarket" />
              <Route path="/express/category" exact={true} component={AllCategories} />
              <Redirect from="/express/category/1713" to="/express/category/4855" />

              {/* Departments */}
              <Redirect from="/department/:id_or_slug" to="/departments/:id_or_slug" />
              <Route path="/departments/:slug(\D+)" component={DepartmentContainer} />
              <Route path="/departments/:id(\d+)?" component={DepartmentsIndex} />
              <Route exact={true} path="/search" component={NewProducts} />

              {/* This route should be after the redirection above */}
              <Route
                path="/:platform(express|tb)?/:path(seller|category|products)/:id_or_keyword"
                exact={true}
                component={ProductsPage}
              />

              <Route
                path="/search/:id_or_keyword"
                render={props => {
                  return (
                    <Redirect
                      to={{
                        pathname: '/search',
                        search: qs.stringify({
                          ...qs.parse(props.location.search, { ignoreQueryPrefix: true }),
                          q: props.match.params.id_or_keyword,
                        }),
                      }}
                    />
                  )
                }}
              />

              <Route path="/account" component={AsyncLoadAccount} />
              <Redirect from="/reset-password" to={`/account/reset-password${location.search}`} />
              <Redirect from="/forgot" to={`/account/forgot-password${location.search}`} />
              {/* Checkout */}
              <PrivateRoute path="/checkout/success" component={AsyncLoadCheckoutSuccess} />
              <PrivateRoute path="/get-baopals-pro/success" component={AsyncLoadGetBaopalsProSuccess} />
              <Redirect from="/checkout/*" to="/checkout" />
              <PrivateRoute path="/checkout" component={CheckoutContainer} />
              <PrivateRoute
                path="/:checkoutType(orders|bundles|parcels)/:id/checkout"
                component={OrderCheckoutContainer}
              />
              <Route path="/404" component={AsyncLoadNotFound} />
              <Route path="/baopals-pro-cancelled" component={AsyncBaopalsProCancelled} />
              <Route path="/privacy-policy" component={AsyncLoadPrivacyPolicy} />
              <Route path="/terms-conditions" component={AsyncLoadTermsConditions} />
              <Route path="/help" component={AsyncLoadHelpCenter} />
              <Route path="/bind-wechat/success" component={BindWechatSuccessPage} />
              <Route path="/top_up/success" component={TopupSuccess} />
              <Route path="/ig" component={InstagramContent} />
              {/* Redirect old user account page to new discover page */}
              <Route
                path="/users/:username"
                render={prop => <RedirectAccount target="user" {...prop} />}
              />
              <Route path="/overseas-shipping-rates" component={GlobalShippingCalc} />
              <Route path={ROUTING.CLOTHING_SIZE_GUIDE} component={ClothingSizeGuide} />
              <Redirect to={`/404${referrer}`} />
            </Switch>
          </section>
          <Footer />
          <CookiePolicy />
        </div>

        <ToastsContainer />

        <ScrollToTopOnMount />
        <LoginModal />
        <SignUpModal />
      </NotificationsProvider>
      <LoginRequiredHook />
      <UserCurrencyHook />
      <AfterLoginHook />
      <SelectedAddressHook />
      <Lang />
      <Affiliate />
      <Meta />
    </div>
  )
}

export default Inner
