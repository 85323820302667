import { ADD_TOAST, REMOVE_TOAST } from '../constants/toasts'

const defaultOptions = {
  color: '#F03434'
}

export function showUnableProcessingToast() {
  return {
    type: ADD_TOAST,
    payload: createToast({
      text: 'Sorry, we\'re unable to process your request. Please try again later.'
    })
  }
}

export function addToast(options = {}) {
  return {
    payload: createToast(options),
    type: ADD_TOAST
  }
}

export function removeToast(id) {
  return {
    payload: id,
    type: REMOVE_TOAST
  }
}

function createToast(options) {
  return {
    ...defaultOptions,
    ...options,
  }
}

