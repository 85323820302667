import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useIntl } from 'react-intl'

const data = [
  'women_fashion',
  'men_fashion',
  'kids',
  'cosmetics',
  'home',
  'electronics',
  'office_school',
  'food_beverage',
  'other',
  'fitness',
  'entertainment',
  'pets'
]

const CollectionCategories = ({ ...props }) => {
  const { children: fn } = props

  const intl = useIntl()

  if (typeof fn == 'function') {
    return fn({
      data: []
        .concat(data)
        .map(d => ({ value: d, name: intl.formatMessage({ id: `collections.category_${d}` }) })),
    })
  }

  return null
}

export default CollectionCategories
