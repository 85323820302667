import { DEFAULT_DATE_FORMAT } from '@constants/date'
import { differenceInDays, differenceInMilliseconds, formatDistanceToNow, parseISO, subDays } from "date-fns";
import endOfDay from 'date-fns/endOfDay'
import format from 'date-fns/format'
import formatDistanceStrict from 'date-fns/formatDistanceStrict'
import _isWithinInterval from 'date-fns/isWithinInterval'
import parse from 'date-fns/parse'
import startOfDay from 'date-fns/startOfDay'

const locales: any = {
  en: require('date-fns/locale/en-US').default,
  'zh-CN': require('date-fns/locale/zh-CN').default,
}

export const formatDate = (date: string | Date, fm = 'MMM do, yyyy') =>
  format(typeof date === 'string' ? parseISO(date) : date, fm, {
    locale: locales[localStorage.getItem('preferredLanguage') || 'en'],
  })

export const formatFromNowDate = (dt, addSuffix = true, locale = 'en') =>
  formatDistanceToNow(typeof dt === 'string' ? parseISO(dt) : dt, { addSuffix })

export const formatSimpleFromNowNDate = (dt, locale = 'en') =>
  formatDistanceStrict(new Date(), typeof dt === 'string' ? parseISO(dt) : dt, { addSuffix: false })
    .replace(' minutes', 'm')
    .replace(' hours', 'h')
    .replace(' days', 'd')
    .replace(' seconds', 's')

export const formatDynamicFromNowDate = (dt: string) => {
  const date = parseISO(dt)
  const diff = differenceInDays(new Date(), date)

  if (diff === 1) {
    return 'Yesterday'
  }

  if (diff > 1) {
    return formatDate(date)
  }

  const str = formatFromNowDate(date, false)
    .replace('about', '')
    .replace('less than a', '1')
    .replace('minutes', 'minutes ago')
    .replace('hours', 'hours ago')

  return str.endsWith('minute') || str.endsWith('hour') ? `${str} ago` : str
}

export const isWithinInterval = (dt: string, from: string, to: string) => {
  return _isWithinInterval(parseISO(dt), {
    start: startOfDay(parse(from, DEFAULT_DATE_FORMAT, new Date())),
    end: endOfDay(parse(to, DEFAULT_DATE_FORMAT, new Date())),
  })
}

export const renderMessage = () => {
  const today = new Date()
  const end = new Date(2021, 1, 20)

  if (differenceInDays(end, today) >= 0) {
    return {
      checkoutMsg:
        'Please note that due to the Chinese New Year, many items will be delivered after February 22nd. Express items are shipping normally.',
      trackingMsg:
        "Hey Pal, it's Chinese New Year! The seller of your item is most likely on holiday and your item will ship out once the holiday is over. We kindly ask that you wait until February 22nd before requesting help on this item.",
    }
  }

  return { checkoutMsg: '', trackingMsg: '' }
}

export const formatCountdown = (end: Date) => {
  const restTime = differenceInMilliseconds(end, new Date())

  // const d = restTime > 1000 ? Math.floor(restTime/1000/60/60/24) : 0
  const h = restTime > 1000 ? Math.floor(restTime/1000/60/60) : 0
  const m = restTime > 1000 ? Math.floor(restTime/1000/60%60) : 0
  const s = restTime > 1000 ? Math.floor(restTime/1000%60) : 0

  return {
    hour: h >= 10 ? h.toString() : `0${h.toString()}`,
    minute: m >= 10 ? m.toString() : `0${m.toString()}`,
    second: s >= 10 ? s.toString() : `0${s.toString()}`
  }
}

export const formatTimestamp = (timestamp: number) => {
  if (timestamp) {
    return formatDate(new Date(timestamp * 1000))
  }
  return formatDate(new Date())
}
