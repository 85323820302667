import { useState } from 'react'

export const useLoading = (initialLoading = true) => {
  const [loading, setLoading] = useState<boolean>(initialLoading)

  const onStart = () => setLoading(true)
  const onStop = () => setLoading(false)

  return {
    loading,
    onStart,
    onStop,
  }
}
