import useAuth, { useMyCountry } from '@hooks/useAuth'
import { DEFAULT_COUNTRY_CODE } from '@constants/'

const ChinaOnly = props => {
  const { user } = useAuth()

  const { locationCountryCode } = useMyCountry()

  const isChina = locationCountryCode === DEFAULT_COUNTRY_CODE

  if (typeof props.isChina === 'function' ? props.isChina(locationCountryCode) : isChina) {
    return props.children
  }

  return props.otherwiseRender
}

ChinaOnly.defaultProps = {
  otherwiseRender: null,
  children: null,
}

export default ChinaOnly
