import React, { useState, useEffect } from "react"
import { Link } from 'react-router-dom'
import { DropdownButton, MenuItem } from 'react-bootstrap'
import { CategoryData } from "@interfaces/blog";
import qs from "qs";
import { useLocation, useQuery } from "@hooks/useRouter";

interface Props {
  options: {
    title: string
    url: string
  }[]
  category: CategoryData
}

const Dropdown:  React.FC<Props> = ({ options, category }) => {
  const [open, setOpen] = useState<boolean>(false)
  const { navigate } = useLocation()
  const query = useQuery()
  let setTimeoutConst: any

  const handleMouseEnter = () => {
    clearTimeout(setTimeoutConst)

    return setOpen(true)
  }

  const handleMouseLeave = () => {
    return setTimeoutConst = setTimeout(() => {
      setOpen(false)
    }, 100)
  }

  const handleClick = (slug: string) => {
    return navigate({
      pathname: '/blog',
      search: qs.stringify({ cat: slug }),
    })
  }

  useEffect(() => {
    setOpen(false)
  }, [query.cat])

  return(
    <DropdownButton
      id={`blog-categories-${category.slug}`}
      title={category.title}
      className="bd-none"
      onMouseEnter={() => handleMouseEnter()}
      onMouseLeave={() => handleMouseLeave()}
      onClick={() => handleClick(category.slug)}
      open={open}
      noCaret={true}
    >
      {options.map((opt, i) => (
        <MenuItem
          key={i}
          eventKey={opt.title}
          componentClass={Link}
          href={opt.url}
          to={opt.url}
        >
          {opt.title}
        </MenuItem>
      ))}
    </DropdownButton>
)}

export default Dropdown
