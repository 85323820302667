import React from 'react'
import SellerCard from '@components/discover/Users/FavoriteSellers/SellerCard'
import { useMyCountry } from '@hooks/useAuth'

const SellerInProducts = ({ seller }) => {
  const { locationCountryCode } = useMyCountry()
  return (
    <>
      <SellerCard
        seller={seller}
        country={locationCountryCode}
        isWhatsHot={true}
      />
    </>
  )
}

export default SellerInProducts
