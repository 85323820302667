import React from 'react'
import BaoPalsFlag from '@components/common/BaoPalsFlag'
import {FormattedMessage} from 'react-intl'

type ReviewRatingProps = {
  rating: boolean
}

const ReviewRating: React.SFC<ReviewRatingProps> = props => renderRating(props.rating ? 'buy' : 'pass')

export default ReviewRating

const getColor = (type: 'buy' | 'pass') => type == 'buy' ? 'text-blue' : 'text-grey'

const renderRating = (type: 'buy' | 'pass') => (
  <div className={`bold flex-center ${getColor(type)}`}>
    <BaoPalsFlag type={type} active={true} width={12} height={12} />
    <div className="tw-ml-1 fs16">
      <FormattedMessage id={`general.${type}`} />
    </div>
  </div>
)
