import {useQuery} from '@hooks/useRouter'
import { useMemo } from 'react'
import MySEO from 'components/SEO'
import {Switch, Route} from 'react-router'

const SEO = ({ categories }) => {
  const query = useQuery()

  const title = categories.find(c => c.slug === query.category)?.title

  const render = title ? <MySEO title={title} /> : null

  return useMemo(() => {
    return (
      <Switch>
        <Route
          path='/whatshot'
          exact
          render={() => render}
        />
        <Route
          path='/discover/whatshot'
          render={() => render}
        />
      </Switch>

    )
  }, [title])

}

export default SEO
