import FormInput from '@components/common/FormInput'
import TextareaWithFileUpload from '@components/common/TextareaWithFileUpload'
import {Image} from '@interfaces/image'
import {ReviewForm} from '@interfaces/reviews'
import React, {useState} from 'react'
import {ControlLabel} from 'react-bootstrap'
import {FormattedMessage} from 'react-intl'
import Submit from './Submit'
import {isClothing} from '@utils/product/clothingUtils'
import {BadRequestError} from '@errors/BadRequestError'
import SizePurchased from '@components/common/SizePurchased'
import ClothingFitBar from '@components/common/ClothingFitBar'
import './styles.css'

interface DefaultProps {
  anonymous?: boolean
  title?: string
  content?: string
  clothingFit?: string
  skuId?: string

  pictures: Image[]

  onChange?: (form: ReviewForm) => void
  onSubmit: () => void

  disabled: boolean
}

const MIN_TEXT_LENGTH = 3

const initErrorState = {
  title: '', content: '', rating: '',
  fortune: '', rest: '', clothingFit: '',
  size: ''
}


const MainForm: React.FC<DefaultProps> = ({
  title, rating, content, anonymous, pictures, clothingFit,
  skuId, sizeOptions, selectedSizeOptionId, hasSelected, ...props
}) => {
  const [error, setError] = useState(null)

  const handleFilesChange = pictures => {
    setError(null)
    props.onChange({pictures})
  }

  const [formError, setFormError] = useState(initErrorState)

  const current = isClothing(props.categories)

  const handleSubmit = (e) => {
    setFormError(initErrorState)
    const reject = Promise.reject

    if (!title || title?.length < MIN_TEXT_LENGTH) {
      setFormError({title: 'Review title needs to be at least 3 characters long'})
      return reject()
    } else if (!content || content?.length < MIN_TEXT_LENGTH) {
      setFormError({content: 'Review comment needs to be at least 3 characters long'})
      return reject()
    } else if (typeof rating === 'undefined') {
      setFormError({rating: 'Please select “BUY” or “PASS” at the top.'})
      return reject()
    } else if (current && !!props.categories.length && skuId && !clothingFit) {
      setFormError({clothingFit: 'Please select The Fit'})
      return reject()
    } else if (current && !!props.categories.length && clothingFit && !skuId) {
      setFormError({size: 'Please select Size Purchased'})
      return reject()
    }

    return props.onSubmit().catch(e => {
      if (e instanceof BadRequestError && e.message) {
        setFormError({rest: e.message})
        return Promise.reject()
      } else if (e.message) {
        return Promise.reject(e.message)
      }
    })
  }

  return (
    <div styleName="form" className="text-left">
      {current && sizeOptions && sizeOptions.length > 0 && (
        <>
          <SizePurchased
            className='tw-mb-2 fs12'
            sizeOptions={sizeOptions}
            skuId={skuId}
            onChange={props.onChange}
            clickable={!skuId || !hasSelected}
            error={formError.size ? formError.size : null}
          />

          <ClothingFitBar
            className='tw-mb-4'
            onChange={props.onChange}
            clothingFit={clothingFit}
            clickable={true}
            error={formError.clothingFit ? formError.clothingFit : null}
          />
        </>
      )}
      <FormInput
        name="title"
        value={title}
        placeholder='Enter Review Title'
        maxLength={50}
        validationState={formError.title ? 'error' : null}
        renderLabel={<span className='black-text'>Review Title</span>}
        renderHelpBlock={
          <div styleName="error">
            Review title needs to be at least 3 characters long
          </div>
        }
        onChange={e => props.onChange({title: e.target.value})}
      />

      <div
        styleName="group"
        className={`has-feedback ${formError.content ? 'has-error' : ''}`}
      >
        <ControlLabel className="black-text">Review Content</ControlLabel>
        <TextareaWithFileUpload
          placeholder="Tell us about the product and anything that might help other customers. Thanks!"
          className="tw-w-full bd-light"
          files={pictures}
          value={content}
          minLength={3}
          maxLength={2000}
          onChange={e => props.onChange({content: e.target.value})}
          onFilesChange={handleFilesChange}
          onFileError={err => setError(err)}
          uploadPlaceholder={
            <a className="strong text-uppercase black-link">
              <span className="icon-add-photo" /> <FormattedMessage id="review_form.photos_add" />
            </a>
          }
          required={true}
        >
          {({uploader}: {uploader: React.ReactNode}) => (
            <div>
              <div styleName="error">
                {formError.content}
              </div>

              <div className="d-flex align-items-start justify-content-between tw-mt-2">
                <div>{uploader}</div>

                <div>
                  <input
                    id="anonymous"
                    type="checkbox"
                    name="anonymous"
                    onChange={() => props.onChange({anonymous: !anonymous})}
                    checked={!!anonymous}
                    className="tw-mt-0"
                  />
                  <label htmlFor="anonymous" className="font-reg cursor-pointer fs12 tw-mb-0">
                    <span className="tw-ml-2">
                      <FormattedMessage id="review_form.anonymous" />
                    </span>
                  </label>
                </div>
              </div>
            </div>
          )}
        </TextareaWithFileUpload>
      </div>

      <Submit
        handleSubmit={handleSubmit}
        disabled={!!error}
      />

      {formError.rating && (
        <div className="text-danger text-center tw-mt-4">
          {formError.rating}
        </div>
      )}

      {formError.rest && (
        <div className="text-danger text-center tw-mt-4">
          {formError.rest}
        </div>
      )}
    </div>
  )
}

MainForm.defaultProps = {
  anonymous: false,
  pictures: [],
}

export default MainForm
