export const Option = ({disabled, label, active, onChange}) => (
  <a
    className={`v4-col-6 tw-mb-2 link-black ${active ? 'link-active strong' : ''}`}
    data-disabled={disabled}
    onClick={e => {
      e.stopPropagation()
      onChange()
    }}
  >
    {label}
  </a>
)
