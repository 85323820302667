import React, { MouseEvent, useState, FC, useEffect } from 'react'
import { createTrendingTerm, createTrendingSuggestion } from '@services/trending'
import { of, Subscription } from 'rxjs'
import { filter, map, tap, catchError, pluck, switchMap } from 'rxjs/operators'
import { Link, Redirect } from 'react-router-dom'

export interface TrendingAnchorOpt {
  redirectTo: string
  trendingUrl?: string

  redirectOnMount?: boolean
}

type TrendingAnchorProps = TrendingAnchorOpt

const TrendingAnchor: FC<TrendingAnchorProps> = props => {
  const [forceRedirect, setForceRedirect] = useState<boolean>(props.redirectOnMount)

  // useEffect(
  //   () => {
  //     of(forceRedirect).pipe(
  //       filter(value => value),
  //       switchMap(() => {
  //         if (props.trendingUrl) {
  //           return createTrendingSuggestion(props.trendingUrl)
  //         } else if (props.trendingKeyword) {
  //           return createTrendingTerm(props.trendingKeyword, props.trendingTranslated)
  //         }
  //         return of(null)
  //       })
  //     ).subscribe(() => {})
  //   },
  //   [props.redirectTo, forceRedirect]
  // )

  const handleClick = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault()
    e.stopPropagation()
    setForceRedirect(true)
  }

  if (forceRedirect && props.redirectTo) {
    return <Redirect push to={props.redirectTo} />
  }

  return props.redirectTo ? (
    <Link className="black-link" to={props.redirectTo} onClick={handleClick}>
      {props.children}
    </Link>
  ) : null
}

TrendingAnchor.defaultProps = {
  redirectOnMount: false,
}

export default TrendingAnchor
