import ajax from '../api/ajax'
import { of } from 'rxjs'
import { map, catchError, pluck } from 'rxjs/operators'

export const fetchDiscoveryCollections = opts =>
  ajax({
    url: '/discover/collections',
    query: { ...opts },
  }).pipe(
    pluck('response'),
    map(({ data, meta }) => ({ collections: data.collections, total: meta.total })),
    catchError(err => of(null))
  )

export const fetchDiscoveryReviews = (opts = { page: 1, review: 'last_week' }) =>
  ajax({
    url: '/discover/reviews',
    query: {
      ...opts,
    },
  }).pipe(
    pluck('response'),
    map(({ data, meta }) => ({ reviews: data.reviews, total: meta.total })),
    catchError(err => of(null))
  )

export const fetchDiscoveryProducts = (opts = { page: 1 }) =>
  ajax({
    url: '/discover/products',
    query: {
      ...opts,
    },
  }).pipe(
    pluck('response'),
    map(({ data, meta }) => ({ products: data.products, total: meta.total })),
    catchError(err => of(null))
  )
