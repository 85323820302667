import { ofType } from 'redux-observable'
import { tap, catchError, map, switchMap } from 'rxjs/operators'
import * as ActionCreator from '@actions/userReviews'
import * as ACTION from '@constants/userReviews'
import * as ToastActionCreator from '@actions/toasts'
import { of } from 'rxjs'

export const fetchReviewDetails = (action$, state$, { api }) =>
  action$.pipe(
    ofType(ACTION.FETCH_REVIEW_DETAILS_REQUEST),
    switchMap(({ payload }) =>
      api.fetchReviewDetails(payload.id).pipe(
        map(result => ActionCreator.fetchReviewDetailsSuccess(result)),
        catchError(err => of(ActionCreator.fetchReviewDetailsFailed(err)))
      )
    )
  )

export const fetchUserReviews = (action$, state$, { api }) =>
  action$.pipe(
    ofType(ACTION.FETCH_USER_REVIEWS_REQUEST),
    switchMap(({ payload }) =>
      api.fetchUserReviews(payload.username, payload.opt).pipe(
        map(result =>
          ActionCreator.fetchUserReviewsSuccess({ reviews: result.reviews, meta: result.meta })
        ),
        catchError(err => of(ActionCreator.fetchUserReviewsFailed(err)))
      )
    )
  )

// TODO: FIX DUPLICATE NAME
export const createUserProductReview = (action$, state$, { api }) =>
  action$.pipe(
    ofType(ACTION.CREATE_PRODUCT_REVIEW_REQUEST),
    switchMap(({ payload: { product, review, ...meta } }) =>
      api.createProductReview(product, review).pipe(
        map(result => ActionCreator.createProductReviewSuccess(result, meta)),
        catchError(err => [
          ToastActionCreator.showUnableProcessingToast(),
          ActionCreator.createProductReviewFailed(err),
        ])
      )
    )
  )

// TODO: FIX DUPLICATE NAME
export const updateMyUserReview = (action$, state$, { api }) =>
  action$.pipe(
    ofType(ACTION.EDIT_MY_REVIEW_REQUEST),
    switchMap(({ payload: { id, review, ...meta } }) =>
      api.updateMyReview(id, review).pipe(
        map(result => ActionCreator.updateMyReviewSuccess(id, result, meta)),
        catchError(err => [
          ToastActionCreator.showUnableProcessingToast(),
          ActionCreator.updateMyReviewFailed(err),
        ])
      )
    )
  )

export const deleteReview = (action$, state$, { api }) =>
  action$.pipe(
    ofType(ACTION.DELETE_REVIEW_REQUEST),
    switchMap(({ payload }) =>
      api.deleteReview(payload.id).pipe(
        map(() => ActionCreator.deleteReviewSuccess(payload.id, payload.meta)),
        catchError(err => [
          ToastActionCreator.showUnableProcessingToast(),
          ActionCreator.deleteReviewFailed(err),
        ])
      )
    )
  )

export const createUserReviewVote = (action$, state$, { api }) =>
  action$.pipe(
    ofType(ACTION.CREATE_USER_REVIEW_REQUEST),
    switchMap(({ payload }) =>
      api.createReviewVote(payload.id, payload.vote).pipe(
        map(res => ActionCreator.createUserReviewVoteSuccess(res)),
        catchError(err => [
          ToastActionCreator.showUnableProcessingToast(),
          ActionCreator.createUserReviewVoteFailed(err),
        ])
      )
    )
  )

export const updateUserReviewVote = (action$, state$, { api }) =>
  action$.pipe(
    ofType(ACTION.UPDATE_USER_REVIEW_REQUEST),
    switchMap(({ payload }) =>
      api.updateReviewVote(payload.id, payload.voteId, payload.vote).pipe(
        map(res => ActionCreator.updateUserReviewVoteSuccess(res)),
        catchError(err => [
          ToastActionCreator.showUnableProcessingToast(),
          ActionCreator.updateUserReviewVoteFailed(err),
        ])
      )
    )
  )
