import { useLocation, useQuery } from '@hooks/useRouter'
import qs from 'qs'
import React, { ReactNode, useRef, useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { fromPairs } from 'lodash'

interface UrlQuery {
  names: string | string[]
  values: { [key: string]: string }

  disabled?: boolean

  pathname?: string
  className?: string
  activeClassName?: string

  children: (isActive: boolean) => ReactNode | ReactNode

  optional?: boolean

  onClick?: () => void
  exact?: boolean
}

const UrlQuery = props => {
  const excludeKeys = [].concat(props.names)

  const query = fromPairs(
    Object.entries(useQuery()).filter(([key, value]) => !excludeKeys.includes(key))
  )

  const { location, navigate } = useLocation()

  const ref = useRef(null)

  const [isActive, setIsActive] = useState<boolean>(false)

  useEffect(() => {
    setIsActive(ref.current?.classList?.contains('link-active'))
  }, [])

  const toUri = `${props.pathname || location.pathname}?${qs.stringify({
    ...query,
    ...fromPairs(Object.entries(props.values).filter(v => v[1])),
  })}`

  return (
    <NavLink
      to={toUri}
      disabled={props.disabled}
      data-disabled={props.disabled}
      className={`link-black ${props.className}`}
      activeClassName={`link-active ${props.activeClassName}`}
      innerRef={ref}
      isActive={(_, location) => {
        const q = qs.parse(location.search, { ignoreQueryPrefix: true })
        return Object.entries(props.values).every(
          ([key]) => q[key] === props.values[key] || (props.optional ? !q[key] : false)
        ) && !props.pathname
          ? true
          : props.exact
            ? location.pathname === props.pathname
            : location.pathname.includes(props.pathname)
      }}
      onClick={e => {
        if (props.onClick) {
          e.preventDefault()
          props.onClick(toUri)
        }
      }}
    >
      {typeof props.children === 'function' ? (
        props.children(isActive)
      ) : (
        <>{props.children}</>
      )}
    </NavLink>
  )
}

UrlQuery.defaultProps = {
  className: '',
  activeClassName: '',
  disabled: false,
  optional: false,
  exact: false,
  values: {},
}

export default UrlQuery
