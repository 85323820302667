import FortunePoint from '@components/common/FortunePoint'
import React from 'react'
import './styles.css'

interface UserCard {
  username: string
  displayName: string
  avatar?: string
  birthday?: string
  location?: string
  nationality?: string
  city?: string
  fortunePoints: number
}

const UserCard: React.SFC<UserCard> = props => {
  return (
    <div className="media">
      <div className="media-left">
        <img
          width={90}
          height={90}
          className="img-circle obj-fit-cover media-object"
          src={props.avatar}
          alt="..."
        />
      </div>
      <div className="media-body media-middle">
        <h4 styleName="displayName" className="strong media-heading">
          {props.displayName}
        </h4>

        <div className="tw-my-2">
          <FortunePoint points={props.fortunePoints} />
        </div>

        <div className="clearfix">
          <div className="pull-left" />
        </div>
      </div>
    </div>
  )
}

UserCard.defaultProps = {
  avatar: BAOPALS.IMAGE_PLACEHOLDER,
}

export default UserCard
