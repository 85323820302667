import { useActions } from '@hooks/useActions'
import { useLanguage } from '@hooks/useLanguage'
import { useQuery } from '@hooks/useRouter'
import { parseLangToLocale } from '@utils/i18n'
import React, { useEffect } from 'react'
import { updateIntl } from 'react-intl-redux'

const Lang = props => {
  const { lang } = useLanguage()
  const query = useQuery()

  const actions = useActions({ updateIntl })

  const activeLang = query.lang || localStorage.getItem('preferredLanguage') || lang

  useEffect(
    () => {
      const key = parseLangToLocale(activeLang)
      const messages = require(`../../assets/i18n/${key}.json`)

      actions.updateIntl({
        locale: key,
        messages,
      })
    },
    [activeLang]
  )

  return null
}

export default Lang
