import { sleep } from '@utils'
import React, { FC, useEffect, useRef, useState } from 'react'
import { Overlay, Popover } from 'react-bootstrap'
import './styles.css'

interface ButtonProps {
  className?: string
  styleName?: string

  defaultColor?: 'white' | 'blue' | 'orange'
  animated?: boolean
  onlyText?: boolean
  disabled?: boolean

  successTransition?: number
  errorTransition?: number

  onClick: () => void
  onSuccess?: () => void
  onError?: () => void
}

const initialState = {
  loading: false,
  success: false,
  failed: false,
  error: '',
}

const Button: FC<ButtonProps> = ({
  successTransition,
  onSuccess,
  errorTransition,
  onError,
  onClick,
  defaultColor,
  ...props
}) => {
  const [color, setColor] = useState(defaultColor)

  useEffect(
    () => {
      setTimeout(() => setColor(defaultColor), successTransition)
    },
    [defaultColor]
  )

  const [state, setState] = useState(initialState)

  const resetStateWithTimeout = (ms: number, cb: () => void) =>
    setTimeout(() => {
      setState(initialState)
      typeof cb == 'function' && cb()
    }, ms)

  const handleButtonClick = () =>
    Promise.all([onClick(), sleep(successTransition)])
      .then(() => {
        setState(nowState => ({ ...nowState, loading: false, success: true }))
        resetStateWithTimeout(successTransition, onSuccess)
      })
      .catch((error: any) => {
        setState(nowState => ({ ...nowState, loading: false, failed: true, error }))
        resetStateWithTimeout(errorTransition, onError)
      })

  const handleClick = () => {
    if (props.animated) {
      new Promise<void>(resolve =>
        resolve(setState(nowState => ({ ...nowState, loading: true })))
      ).then(() => handleButtonClick())
    } else {
      onClick()
    }
  }

  const ref = useRef(null)

  const renderError = () => (
    <Overlay show={true} target={ref.current} placement="top">
      <Popover id="popover-contained" styleName="popover">
        {state.error}
      </Popover>
    </Overlay>
  )

  const type = props.onlyText ? 'link' : 'button'
  const styleNames = [type]
  if (color) {
    styleNames.push(`${color}-${type}`)
  }

  if (props.disabled) {
    styleNames.push('disabled')
  }
  if (state.loading) {
    styleNames.push('loading')
  } else if (state.success) {
    styleNames.push('success')
  } else if (state.failed) {
    styleNames.push('failed')
  }

  const renderAction = (Component: any) => (
    <Component
      ref={ref}
      className={props.className}
      styleName={styleNames.join(' ')}
      onClick={handleClick}
    >
      <span className="bold l-h-1" styleName="text">
        {props.children}
      </span>
    </Component>
  )

  return (
    <>
      {renderAction(type == 'button' ? 'button' : 'div')}

      {typeof state.error === 'string' && state.failed && renderError()}
    </>
  )
}

Button.defaultProps = {
  defaultColor: 'white',
  animated: true,
  onlyText: false,
  disabled: false,

  successTransition: 650,
  errorTransition: 2000,
}

export default Button
