import useAuth from '@hooks/useAuth'
import { AddressForm } from '@interfaces/address'
import * as api from '@services/address'
import { findZonesByLastZoneId } from '@utils/address'
import { map } from 'rxjs/operators'
import { useSelectedAddr } from '@hooks/useSelectedAddress'

export const useAddress = () => {
  const { address, zones, updateCountry, updateAddress, updateZones } = useAuth()

  const defAddr = address.find(addr => addr.default === true)

  const { updateSelectedAddress } = useSelectedAddr()

  const onCreate = (addr: AddressForm, opt = { reselect: true }) => {
    return new Promise((resolve, reject) => {
      api.createAddress(addr).subscribe(({ address: addr, zones }) => {
        const newAddr = {
          ...addr,
          default: address.length === 0,
        }

        if (newAddr.default) {
          // const addr = data[newAddr.country]
          // if (addr?.alias) {
          //   updateCountry(addr.alias)
          // }
        }

        updateAddress(address.concat(newAddr))
        updateZones(zones)

        if (opt.reselect) {
          updateSelectedAddress(newAddr.id)
        }

        resolve(addr)
      }, reject)
    })
  }

  const onDelete = (id: number) => {
    return new Promise((resolve, reject) => {
      return api
        .deleteAddress(id)
        .pipe(
          map(result => ({
            id: result.id,
            defaultAddress: result.defaultAddress,
          })),
          map(payload => {
            const newAddrs = address
              .filter(addr => addr.id !== id)
              .map(addr => ({ ...addr, default: addr.id === payload.defaultAddress }))

            const nextDefAddr = address.find(addr => addr.id === payload.defaultAddress)

            // const addr = data[nextDefAddr?.country || CountryCode.China]

            // if (addr?.alias) {
            //   updateCountry(addr.alias)
            // }

            updateAddress(newAddrs)

            updateSelectedAddress(nextDefAddr && nextDefAddr.id)

            return nextDefAddr
          })
        )
        .subscribe(resolve, reject)
    })
  }

  const onUpdate = (id: number, form: AddressForm) => {
    return new Promise((resolve, reject) => {
      api.updateAddress(id, form).subscribe(({ address: updatedAddr, zones: updatedZones }) => {
        const index = address.findIndex(({ id }) => id === updatedAddr.id)

        const newAddrs = address
          .slice(0, index)
          .concat({ ...address[index], ...updatedAddr })
          .concat(address.slice(index + 1))

        updateAddress(newAddrs)
        updateZones(updatedZones)
      })
    })
  }

  const onToggleDefault = (id: number) => {
    return api
      .updateDefaultAddress(id)
      .toPromise()
      .then(() => {
        const newAddrs = address.map(addr => ({
          ...addr,
          default: addr.id === id,
        }))
        updateAddress(newAddrs)
        updateSelectedAddress(id)
      })
    // return api.updateDefaultAddress(id).subscribe(() => {
    //   // const countryCode = newAddrs.find(a => a.id === id)?.country
    //   // const addr = data[countryCode]
    //
    //   updateAddress(newAddrs)
    //   updateSelectedAddress(id)
    //
    //   //if (addr?.alias) {
    //   //  updateCountry(addr.alias)
    //   //}
    //
    //   // resolve({ country: { code: countryCode, ...addr } })
    // })
  }

  return {
    // For some reason the address has missing zones, we'll exlcude them here simply
    // address: address.filter(addr => findZonesByLastZoneId(addr.zoneId, zones).length >= 3),
    address, // address.filter(addr => findZonesByLastZoneId(addr.zoneId, zones).length >= 3),

    defaultAddress: defAddr,
    zones,

    onCreate,
    onDelete,
    onUpdate,
    onToggleDefault,
    findZonesByLastZoneId: (zoneId: number) => findZonesByLastZoneId(zoneId, zones),
  }
}
