export default {
  FETCH_MY_EARNINGS_NUM_REQUEST: '@EARNINGS/FETCH_MY_EARNINGS_NUM_REQUEST',
  FETCH_MY_EARNINGS_NUM_SUCCESS: '@EARNINGS/FETCH_MY_EARNINGS_NUM_SUCCESS',
  FETCH_MY_EARNINGS_NUM_FAILURE: '@EARNINGS/FETCH_MY_EARNINGS_NUM_FAILURE',

  FETCH_MY_EARNINGS_HISTORY_REQUEST: '@EARNINGS/FETCH_MY_EARNINGS_HISTORY_REQUEST',
  FETCH_MY_EARNINGS_HISTORY_SUCCESS: '@EARNINGS/FETCH_MY_EARNINGS_HISTORY_SUCCESS',
  FETCH_MY_EARNINGS_HISTORY_FAILURE: '@EARNINGS/FETCH_MY_EARNINGS_HISTORY_FAILURE',

  FETCH_MY_REFERRAL_EARNINGS_REQUEST: '@EARNINGS/FETCH_MY_REFERRAL_EARNINGS_REQUEST',
  FETCH_MY_REFERRAL_EARNINGS_SUCCESS: '@EARNINGS/FETCH_MY_REFERRAL_EARNINGS_SUCCESS',
  FETCH_MY_REFERRAL_EARNINGS_FAILURE: '@EARNINGS/FETCH_MY_REFERRAL_EARNINGS_FAILURE',
} as const
