import { routerMiddleware } from 'connected-react-router'
import createHistory from 'history/createBrowserHistory'
import { applyMiddleware, compose, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import { BehaviorSubject } from 'rxjs'
import { switchMap } from 'rxjs/operators'
import epics, { epicMiddleware } from '../epics'
import observableToPromise from '../middlewares/observableToPromise'
import createRootReducer from '../reducers'

const history = createHistory()

const middlewares = [thunk, epicMiddleware, observableToPromise, routerMiddleware(history)]

function configureStore(initialState: any) {
  const store = createStore(
    createRootReducer(history),
    initialState,
    compose(applyMiddleware(...middlewares))
  )

  epicMiddleware.run(epics)

  return store
}

export default configureStore
export { history }
