import FlexBetween from '@components/common/FlexBetween'
import React, { FC, ReactNode } from 'react'
import { Link } from 'react-router-dom'

interface NavProps {
  className?: string
  url?: string
  renderLeft?: ReactNode
  renderMiddle?: ReactNode

  onLeftClick: () => void
}

const Arrow = props => <i className="icon-arrow-left" {...props} />

const Nav: FC<NavProps> = ({ url, ...props }) => (
  <>
    <div className={`p-4 strong relative l-h-1 ${props.className}`}>
      <>
        <span
          style={{
            position: "absolute",
            top: "50%",
            left: 0,
            right: 0,
            textAlign: "center",
            transform: "translateY(-50%)"
          }}
        >
          {props.renderMiddle}
        </span>
        <FlexBetween
          renderLeft={
            <span style={{ position: "relative", zIndex: 10 }}>
              {url ? (
                <Link to={url} className="black-link">
                  <Arrow />
                </Link>
              ) : (
                <span onClick={props.onLeftClick}>
                  <Arrow />
                  {props.renderLeft}
                </span>
              )}
            </span>
          }
        />
      </>
    </div>
    {props.children}
  </>
)

Nav.defaultProps = {
  className: '',
}

export default Nav
