import React from 'react'

const List = props => (
  <>
    {props.options.map((opt, i) => (
      <div className="radio" key={i}>
        <label>
          <input
            type="radio"
            name={props.name}
            id={opt.value}
            value={opt.value}
            checked={props.value === opt.value && !props.disabled}
            onChange={() => props.onChange(opt.value)}
          />
          {opt.label}
        </label>
      </div>
    ))}
  </>
)

export default List
