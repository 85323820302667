import { Platform } from '@interfaces/platform'
import ajax from '@services/client'
import { combineProductsWithModules } from '@utils/whatsHotUtils'

export const fetchProductDesc = async (params: {
  id: string
  platform: Platform
  sku?: string
}) => {
  if (params.platform === Platform.JD) {
    return ajax
      .get(`/products/${params.id}/details`, {
        params: { sku_id: params.sku },
      })
      .then(({ data }) => ({ desc: data.data?.desc, properties: data.data?.properties ?? [] }))
  }
  return ajax
    .get(`/products/${params.id}/description`, {
      params: { provider: 'taobao' },
    })
    .then(({ data }) => {
      const resultString = (data?.data?.description || "").replace(/<img[^>]*>(.*?)<\/img>/g, (match, url) => {
        // Check if the URL is missing the protocol, and if so, add "https:" to it
        if (url.startsWith("//")) {
          url = "https:" + url;
        }
        return `<img src="${url}" />`;
      });

      return {desc: resultString};
    })
}

export const formatProducts = (products: any[]) =>
  products.map(p => ({
    ...p,
    skuId: p.jdProductId,
    platform: p.shopType,
    unavailable: p.unavailable,
    ...(p.sellerId &&
      !p.seller && {
      seller: {
        id: p.sellerId,
      },
    }),
  }))

const formatData = (reviews, products) => {
  const modules = {
    blogArticles: [],
    globalParcels: [],
    sellers: [],
    departments: [],
    reviews: reviews ?? []
  }

  return combineProductsWithModules(modules, products, 2, 5)
}

export const fetchProducts = (params) => {
  const url = params.image ? '/products/image_search' : '/products'
  return ajax.get(url, {
    params: params
  }).then(({ data }) => {
    const { reviews, products } = data;
    const newProducts = (reviews.length === 1 && products.length ===40) ? [...products, products[1], products[2]] : [...products]
    return { ...data, products: formatData(reviews, formatProducts(newProducts)) }
  })
}
