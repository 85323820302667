import React, { useEffect } from 'react'
import { useActions } from '@hooks/useActions'
import * as ACTIONS from '@actions/paymentConfig'
import { useMyIp } from '@hooks/useMyIp'
import useAuth from '@hooks/useAuth'

const CountryCurrencySettings = ({ children }) => {
  const actions = useActions(ACTIONS)
  const { user } = useAuth()

  const { isLoading, data } = useMyIp()

  useEffect(() => {
    if (data?.currency && !user) {
      actions.updateDefaultCurrency(data.currency)
    }

  }, [data?.currency])

  return isLoading ? null : <>{children}</>
}

export default CountryCurrencySettings
