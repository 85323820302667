import React from 'react'
import ProgressiveSkeleton from '@components/common/ProgressiveSkeleton'
import {TextsLoading, LoadingProps} from '@components/common/Loading'
import './styles.css'

interface defaultProps extends LoadingProps {
  height?: string | number
  picWidth?: string | number
  noBorder?: boolean
}

const Loading: React.SFC<defaultProps> = ({className, height, picWidth, noBorder}) => (
  <div
    className={`${noBorder ? 'bd-none' : 'p-4 bd-light'} ${className || ''}`}
    styleName="wrapper"
    style={{
      gridTemplateColumns: `${typeof picWidth == 'string' ? picWidth : `${picWidth}px`} auto`,
      height: height ? (typeof height == 'string' ? height : `${height}px`) : 'auto',
    }}
  >
    <ProgressiveSkeleton width="100%" height="0" enableMoveAnimation className="tw-mt-0 d-block" />

    <TextsLoading className="tw-ml-6" />
  </div>
)

Loading.defaultProps = {
  picWidth: '30%',
  noBorder: false,
}

export default Loading
