import { ImageWithDetails } from '@components/common/Loading'
import ReviewUnit from '@components/common/ReviewUnit'
import { ProductData, ReviewData, UserData } from '@interfaces/reviews'
import React, { FC } from 'react'
import './styles.css'

interface DefaultProps {
  loading: boolean
  reviews: ReviewData[]
  users?: UserData[]
  products?: ProductData[]

  handleUpvote: (id: number) => void
  handleDownvote: (id: number) => void
}

const Inner = ({ children }) => (
  <div className="v4-col-xs-12 v4-col-sm-4 v4-col-md-4 tw-mb-6" styleName="review-unit">
    {children}
  </div>
)

const Loading = () => (
  <>
    {[1, 2, 3, 4, 5, 6].map(i => (
      <Inner key={i}>
        <ImageWithDetails />
      </Inner>
    ))}
  </>
)

const ReviewList: FC<DefaultProps> = ({ loading, reviews, users, products, ...props }) => {
  return (
    <div className="v4-row">
      {loading ? (
        <Loading />
      ) : (
        reviews.map((review, index) => (
          <Inner key={index}>
            {review.id < 0 ? (
              <ImageWithDetails />
            ) : (
              <ReviewUnit
                review={review}
                user={!!review.userId && users.find(u => u.id === review.userId)}
                product={!!review.productId && products.find(p => p.id === review.productId)}
                handleUpvote={props.handleUpvote}
                handleDownvote={props.handleDownvote}
                showByUser={true}
                showCompleteDetails={false}
                showImages={false}
              />
            )}
          </Inner>
        ))
      )}
    </div>
  )
}

export default ReviewList
