import React from 'react'
import { useResponsive, Viewport } from '@hooks/useResponsive'
import Collection from '../../../v2/RelatedProducts/Desktop/Collection'
import './styles.css'

const PopularProducts = ({ popularProducts }) => {
  const isXS = useResponsive([Viewport.XS]).some(v => v)
  const className = isXS ? '' : 'clearfix department__slider'

  const sliderSettings = isXS
    ? {
        className: 'slide-inner',
        slidesToShow: 3,
        slidesToScroll: 3,
        nextArrow: null,
        prevArrow: null,
      }
    : {
        className: 'responsive-slider responsive-slider--departments',
        slidesToShow: 6,
        slidesToScroll: 6,
      }

  return (
    <div className={className} styleName="inner">
      <Collection sliderSettings={sliderSettings} products={popularProducts.filter(p => p.id)} />
    </div>
  )
}

export default PopularProducts
