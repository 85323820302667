import { useQuery } from '@hooks/useRouter'
import React, { FC } from 'react'
import Loading from './Loading'
import ResultText from './ResultText'

interface Props {
  loading: boolean
  totalProducts?: number
  totalReviews?: number

  heading?: string
  onSuggestionLoad: any
}

const MatchResults: FC<Props> = ({ heading, ...props }) => {
  const query = useQuery()

  return props.loading ? (
    <Loading />
  ) : (
    <>
      {heading ? (
        <h1 className="categoryPage__header bodo">{heading}</h1>
      ) : (
        <ResultText
          totalProducts={props.totalProducts}
          totalReviews={props.totalReviews}
          alias={query.q}
          onSuggestionLoad={props.onSuggestionLoad}
        />
      )}
    </>
  )
}

export default MatchResults
