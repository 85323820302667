import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import FullScreenModal from '../../../../common/FullScreenModal'
import BaopalsScore from '../../../../common/BaopalsScore'
import ProductProperties from '../../PropertiesCollection'
import DottedLine from '@components/common/DottedLine'

import './styles.css'
import { cloudObjectToURL } from '@utils/'

const MobileProperties = ({ onModalOpen, onModalClose, shouldModalOpen, ...props }) => {
  return (
    <div>
      {/*<div className="text-center tw-mt-6">*/}
        {/*<div className="text-uppercase">*/}
          {/*<FormattedMessage id="product_page.overview_quantity_sold" />*/}
        {/*</div>*/}
        {/*<div styleName="qty" className="text-orange">*/}
          {/*{props.sold ? `${props.sold}+` : 0}*/}
        {/*</div>*/}
      {/*</div>*/}
      <div className="tw-my-6 pb-3 text-center">
        <button className="btn btn-white" onClick={onModalOpen}>
          <FormattedMessage id="product_page.specs_info" />
        </button>
        <FullScreenModal open={shouldModalOpen} onClose={onModalClose}>
          <div className="text-left">
            <ProductProperties properties={props.properties} />
          </div>
        </FullScreenModal>
      </div>
    </div>
  )
}

export default MobileProperties
