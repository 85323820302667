import { Epic as EpicType } from 'redux-observable'
import { combineEpics, createEpicMiddleware } from 'redux-observable'

import { RootState } from '../reducers'
import { Services } from '../services'
import * as api from '../services'
import { RootAction } from '../store/root-action'
import * as epics from './epics'

const allEpics = Object.entries(epics).map(([, value]) => value)
const rootEpic = combineEpics(...allEpics)

export type Epic = EpicType<RootAction, RootAction, RootState, { api: Services }>

export const epicMiddleware = createEpicMiddleware<
  RootAction,
  RootAction,
  RootState,
  { api: Services }
>({
  dependencies: { api },
})

export default rootEpic
