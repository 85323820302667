import * as ActionCreator from '@actions/ticket'
import { of } from 'rxjs'
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { Epic } from './index'

export const closeTicket: Epic = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(ActionCreator.closeTicket)),
    switchMap(({ payload }) =>
      api.closeTicket(payload.id).pipe(
        map(id => ActionCreator.closeTicketSuccess({ id })),
        catchError(err => of(ActionCreator.closeTicketFailure(err)))
      )
    )
  )

export const createTicket: Epic = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(ActionCreator.createTicket)),
    switchMap(({ payload }) =>
      api.createTicket(payload.form).pipe(
        map(ticket => ActionCreator.createTicketSuccess({ ticket })),
        catchError(err => of(ActionCreator.createTicketFailure(err)))
      )
    )
  )

export const fetchTickets: Epic = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(ActionCreator.fetchTickets)),
    switchMap(({ payload }) =>
      api.fetchTickets(payload.open, payload.per, payload.page).pipe(
        map(res => ActionCreator.fetchTicketsSuccess(res)),
        catchError(err => of(ActionCreator.fetchTicketsFailure(err)))
      )
    )
  )

export const fetchTicketHistory: Epic = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(ActionCreator.fetchTicketHistory)),
    switchMap(({ payload }) =>
      api.fetchTicketHistory(payload.item, payload.countries).pipe(
        map(({ history, items, ticketTypes, ticket }) =>
          ActionCreator.fetchTicketHistorySuccess({ history, items, ticketTypes, ticket })
        ),
        catchError(err => of(ActionCreator.fetchTicketHistoryFailure(err)))
      )
    )
  )

export const createTicketReply: Epic = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(ActionCreator.replyToTicket)),
    switchMap(({ payload }) =>
      api.createTicketReply(payload.id, payload.form).pipe(
        map(history => ActionCreator.replyToTicketSuccess({ history })),
        catchError(err => of(ActionCreator.replyToTicketFailure(err)))
      )
    )
  )

export const createExtraInfoReply: Epic = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(ActionCreator.createExtraInfoReply)),
    switchMap(({ payload }) =>
      api.createExtraInfoReply(payload.caseId, payload.msgId, payload.opt).pipe(
        map(res => {
          return ActionCreator.createExtraInfoReplySuccess(res)
        }),
        catchError(err => of(ActionCreator.createExtraInfoReplyFailure(err)))
      )
    )
  )
