import BacktoTop from '@components/layout/BacktoTop'
import Price from '@components/product/v4/ProductDetails/ProductMain/ProductInfo/PriceSection/Price'
import ProductShipping from '@components/product/v4/ProductDetails/ProductShipping/ProductShipping'
import './styles.css'

const StickyProductPrice = ({ id }) => {
  return (
    <section id="f-total-shipping" className="bg-white bd-t-light visible-xs" styleName="sticky">
      <BacktoTop className="totop-product" scroll={true} />

      <div className="d-flex align-items-center justify-content-between ">
        <Price id={id} />
        <div className="text-right tw-pl-5">
          <ProductShipping id={id} />
        </div>
      </div>
    </section>
  )
}

export default StickyProductPrice
