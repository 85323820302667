import React                from 'react'
import {Modal}              from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import QRCode               from 'qrcode.react'
import './styles.css'

class WeChatShareModal extends React.Component {
  render() {
    const { open, url, onHide } = this.props
    const imgUrl = "https://res.cloudinary.com/baopals/image/upload/f_auto/v1529644657/logo_on_wechat.png"
    return (
      <Modal bsSize="sm" show={open} onHide={onHide} styleName="modal">
        <Modal.Header className="tw-pb-0 tw-mt-2 tw-mb-12" closeButton>
          <h3 className="text-center bold linethrough tw-mt-12 tw-mb-0">
            <FormattedMessage id='general.wechat_link'/>
          </h3>
        </Modal.Header>
        <Modal.Body>
          <div className="relative tw-w-full" styleName="wechat-qrcode">
            <QRCode value={url} />
            <img src={imgUrl} />
          </div>
        </Modal.Body>
      </Modal>
    )
  }
}
export default WeChatShareModal
