import ROUTING from '@constants/url'
import React from 'react'
import { FormattedMessage } from 'react-intl'

export const links = [
  {
    label: <FormattedMessage id="myAccount.sidebar_address" defaultMessage="My Addresses" />,
    url: ROUTING.ACCOUNT_ADDRESS,
  },
  {
    label: 'Get Baopals Pro',
    url: ROUTING.ACCOUNT_GET_BAOPALS_PRO,
  },
  {
    label: <FormattedMessage id="myAccount.sidebar_settings" defaultMessage="My Settings" />,
    url: ROUTING.ACCOUNT_SETTINGS,
  },
]

export const walletLinks = [
  {
    label: 'Wallet',
    url: ROUTING.ACCOUNT_MY_WALLET,
  },
  {
    label: 'Withdraw',
    url: ROUTING.ACCOUNT_WITH_DRAW,
  },
  {
    label: 'Top Up',
    url: ROUTING.ACCOUNT_TOP_UP,
  },
]

export const rewardLinks = [
  // {
  //  label: 'My Earnings',
  //  url: ROUTING.ACCOUNT_EARNINGS,
  // },
  // {
  //  label: 'My Coupons',
  //  url: ROUTING.ACCOUNT_COUPONS,
  // },
  // {
  //  label: 'My Fortune Points',
  //  url: ROUTING.ACCOUNT_FORTUNE_POINTS,
  // },
  {
    label: 'My Referrals',
    url: ROUTING.ACCOUNT_REFERRALS,
  },
]
