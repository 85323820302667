import React from 'react'
import { FormattedMessage } from 'react-intl'
import Help, { LinkProps } from './Help'

const KnowUs = () => {
  const links: LinkProps[] = [
    {
      url: 'https://www.baopals.com/blog?cat=careers',
      text: <FormattedMessage id="footer.careers" defaultMessage="CAREERS" />,
    },
    {
      url: `https://www.baopals.com/blog`,
      text: <FormattedMessage id="footer.our_blog" defaultMessage="OUR BLOG" />,
    },
    {
      url: `/privacy-policy`,
      text: (
        <FormattedMessage
          id="footer.privacy_policy"
          defaultMessage="Legal Statement and Privacy Policy"
        />
      ),
    },
    {
      url: `/terms-conditions`,
      text: <FormattedMessage id="footer.terms_conditions" defaultMessage="Terms of Use" />,
    },
  ]

  return <Help links={links} title={title} className="text-left" />
}

export default KnowUs

const title = <FormattedMessage id="footer.get_to_know_us" defaultMessage="GET TO KNOW US" />
