import React from 'react'
import Slider from 'react-slick'
import DottedLine from '@components/common/DottedLine'
import Slide from './Slide'
import './styles.css'

const CategorySlider: React.SFC<any> = props => {
  const { category, prevCategory, nextCategory, links } = props

  const settings = {
    draggable: false,
    initialSlide: 1,
    arrows: false,
  }

  return (
    <>
      <DottedLine styleName="line top" className="relative" />
      <div styleName="inner">
        <Slider {...settings}>
          <Slide
            loading={props.loading}
            key={category.id}
            category={category}
            nextCategory={nextCategory}
            prevCategory={prevCategory}
            links={links}
            onPrev={props.onPrev}
            onNext={props.onNext}
          />
        </Slider>
      </div>
      <DottedLine styleName="line bottom" className="relative" />
    </>
  )
}

export default CategorySlider
