import DottedLine from '@components/common/DottedLine'
import React from 'react'
import {FormattedMessage} from 'react-intl'
import {useMyCountry} from '@hooks/useAuth'
import './styles.css'

const Guides = () => {
  const {isInternational} = useMyCountry()
  const prefix = isInternational ? '' : 'cn-'
  const getUrl = (slug: string) => `/help/${prefix}${slug}`

  const links: LinkProps[] = [
    {
      url: getUrl('getting-started'),
      icon: 'icon-how-to-1',
      text: <FormattedMessage id="footer.how_baopals_works" defaultMessage="HOW BAOPALS WORKS" />,
    },
    {
      url: getUrl('shopping'),
      icon: 'icon-how-to-2',
      text: (
        <FormattedMessage
          id="footer.searching_for_products"
          defaultMessage="SEARCHING FOR PRODUCTS"
        />
      ),
    },
    {
      url: getUrl('your-orders'),
      icon: 'icon-package-color',
      text: <FormattedMessage id="footer.your_orders" defaultMessage="YOUR ORDERS" />,
    },
    {
      url: getUrl('deliveries'),
      icon: 'icon-how-to-4',
      text: (
        <FormattedMessage id="footer.handling_deliveries" defaultMessage="HANDLING DELIVERIES" />
      ),
    },
    {
      url: getUrl('checkout-payments'),
      icon: 'icon-how-to-5',
      text: <FormattedMessage id="footer.payment_options" defaultMessage="PAYMENT OPTIONS" />,
    },
  ]

  return (
    <div className="footer__guides hidden-xs text-center tw-my-20">
      <DottedLine />

      <div className="flex-center-center l-h-1 tw-my-20" styleName="head">
        {renderHead()}
      </div>

      <div className="flex-center-center">
        {links.map((link: LinkProps) => (
          <React.Fragment key={`footer-guide-${link.icon}`}>
            {renderUnit(link)}
            {link.icon !== 'icon-how-to-5' && renderDot()}
          </React.Fragment>
        ))}
      </div>
    </div>
  )
}

export default Guides

interface LinkProps {
  url: string
  icon: string
  text: React.ReactNode
}

const renderHead = () => (
  <a href={`/help/2`} target="_blank" className="black-link fs30 upper bold">
    <FormattedMessage id="footer.how_to_baopals" defaultMessage="HOW TO BAOPALS" />
  </a>
)

const renderUnit = (link: LinkProps) => (
  <a className="flex-center-center" styleName="unit" href={link.url} target="_blank" rel="noopener">
    <div>
      <i className={`${link.icon} d-block`} />
      <div className="tw-pt-4 upper">{link.text}</div>
    </div>
  </a>
)

const renderDot = () => (
  <div className="visible-lg" styleName="dot">
    ●●●
  </div>
)
