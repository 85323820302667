import { NavLink } from 'react-router-dom'
import { Nav, NavItem } from 'react-bootstrap'
import useAuth from '@hooks/useAuth'
import './styles.css'

export const DiscoverUserNav = ({ username }) => {
  const { user } = useAuth()

  const isMe = user?.username === username

  return (
    <Nav
      justified
      bsStyle="pills"
      styleName="wrapper"
    >
      {[
        {
          key: 'reviews',
          label: isMe ? (
            <>
              <span className="hidden-xs">MY </span>
              REVIEWS
            </>
          ) : (
            'REVIEWS'
          ),
          url: `/discover/users/${username}/reviews`,
        },
        {
          key: 'shops',
          label: isMe ? (
            <>
              <span className="hidden-xs">MY </span>
              SHOPS
            </>
          ) : (
            'FAVORITE SHOPS'
          ),
          url: `/discover/users/${username}/shops`,
        },
      ]
        .concat(
          isMe
            ? {
              key: 'list',
              label: (
                <>
                  <span className="hidden-xs">MY </span>
                  WISHLISTS
                </>
              ),
              url: `/discover/users/${username}/wishlists`,
            }
            : []
        )
        .map(data => {
          return (
            <NavItem eventKey={data.key} componentClass={NavLink} href={data.url} to={data.url} key={data.url}>
              {data.label}
            </NavItem>
          )
        })}
    </Nav>
  )
}
