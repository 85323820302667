import { AuthProvider, WechatAuthState } from '@interfaces/auth'
import { isWeChat } from '@utils'
import qs from 'qs'
import config from '../config.json'

const BASE_NAME = 'https://open.weixin.qq.com/connect/oauth2/authorize'

export const getRedirectUri = () => {
  const { code, state: _, ...query } = qs.parse(location.search, { ignoreQueryPrefix: true })

  const { origin, pathname } = location

  // For test
  // const origin = `https://www.baopals.com`
  // const pathname = '/wechat.html'

  return `${origin}${pathname}?${encodeURIComponent(qs.stringify(query, { encode: false }))}`
}

export const getWechatOAuthUrl = (state: WechatAuthState) => {
  const profile = isWeChat()
    ? AuthProvider.WECHAT_PUBLIC_ACCOUNT
    : AuthProvider.WECHAT_OPEN_PLATFORM

  const appid = config.wechat[profile].appId

  const wechatUrlParams = {
    appid,
    redirect_uri: getRedirectUri(),
    response_type: 'code',
    scope: 'snsapi_userinfo',
    state,
  }

  const url = [[BASE_NAME, qs.stringify(wechatUrlParams)].join('?'), 'wechat_redirect'].join('#')

  return url
}
