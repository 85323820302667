import ACTION from '@constants/wallet'
import { HttpError } from '@errors/HttpError'
import { FetchMyWalletQuery, Operation, Payment, Wallet } from '@interfaces/wallet'
import { createStandardAction } from 'typesafe-actions'

// Update wallet balance
export const updateWalletBalance = createStandardAction(ACTION.UPDATE_WALLET_BALANCE)<number>()

// Fetch my wallet
export const fetchMyWallet = createStandardAction(ACTION.FETCH_MY_WALLET_REQUEST)<
  FetchMyWalletQuery
>()

export const fetchMyWalletSuccess = createStandardAction(ACTION.FETCH_MY_WALLET_SUCCESS)<{
  wallet: Wallet
  payments: Payment[]
}>()
export const fetchMyWalletFailure = createStandardAction(ACTION.FETCH_MY_WALLET_FAILURE)<
  HttpError
>()

// Refund a payment
export const refundPayment = createStandardAction(ACTION.REFUND_PAYMENT_REQUEST).map(
  (paymentId: number, amount: string) => ({
    payload: { paymentId, amount },
    meta: {
      lifecycle: {
        resolve: ACTION.REFUND_PAYMENT_SUCCESS,
        reject: ACTION.REFUND_PAYMENT_FAILURE,
      },
    },
  })
)

export const refundPaymentSuccess = createStandardAction(ACTION.REFUND_PAYMENT_SUCCESS)<{
  operation: Operation
  payment: Payment
  refund: Refund
}>()
export const refundPaymentFailure = createStandardAction(ACTION.REFUND_PAYMENT_FAILURE)<HttpError>()
