import { useScroll } from '@hooks/useScroll'
import React, { useRef, useState, useEffect } from 'react'
import './styles.css'

const Layout = props => {
  const ref = useRef()
  const [sidebarStyle, setSidebarStyle] = useState(null);

  useEffect(() => {
    const handleScroll = () => {
      const sidebar = document.getElementById("sidebar");
      const footer = document.getElementById("footer");
      const top = ref?.current?.getBoundingClientRect().top
      const fixed = top <= 135
      const footerStyle =  window.getComputedStyle(footer);
      const footerShow = footerStyle.display !== 'none'

      if (sidebar && footer && footerShow && fixed) {
        const stopScrollPosition = footer.offsetTop - sidebar.offsetHeight;

        if (window.scrollY >= stopScrollPosition - 200) {
          setSidebarStyle({ position: "absolute", top: `${stopScrollPosition}px` });
        } else {
          setSidebarStyle({ position: "fixed", top: "158px" });
        }
      } else {
        if (fixed) {
          setSidebarStyle({ position: "fixed", top: "158px" });
        } else {
          setSidebarStyle(null);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="container" ref={ref}>
      <div styleName="inner" className="inner">
        <div styleName="main" className="main">
          <div styleName="content">{props.main}</div>
        </div>
        <div id="sidebar" styleName="sidebar" className="aside" style={sidebarStyle}>
          {props.aside}
        </div>
        <div className="clearfix" />
      </div>
    </div>
)}

export default Layout
