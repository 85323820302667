import * as ActionCreator from '@actions/orders'
import { sortByDate } from '@utils/array'
import { forkJoin, of } from 'rxjs'
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { Epic } from './index'

export const cancelOrder: Epic = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(ActionCreator.cancelOrder)),
    switchMap(({ payload }) =>
      api
        .cancelOrder(payload.id, { isParcel: payload.isParcel })
        .pipe(map(({ id }) => ActionCreator.cancelOrderSuccess({ id })))
    )
  )

export const deleteOrder: Epic = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(ActionCreator.deleteOrder)),
    switchMap(({ payload }) =>
      api.deleteOrder(payload.id, payload.isBundle).pipe(
        map(({ id }) => ActionCreator.deleteOrderSuccess({ id })),
        catchError(err => of(ActionCreator.deleteOrderFailure(err)))
      )
    )
  )

export const fetchSingleOrder: Epic = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(ActionCreator.fetchSingleOrder)),
    switchMap(({ payload }) =>
      api
        .fetchSingleOrder(payload.id, { isBundle: payload.isBundle, isParcel: payload.isParcel, countries: payload.countries })
        .pipe(
          map(({ parcelItems, order, items, dimensions, selection, warehouseFees }) =>
            ActionCreator.fetchSingleOrderSuccess({
              order,
              items,
              dimensions,
              packageingFormula: selection,
              parcelItems,
              warehouseFees,
            })
          ),
          catchError(() => of(ActionCreator.fetchSingleOrderFailure(new Error('error'))))
        )
    )
  )

export const fetchOrders: Epic = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(ActionCreator.fetchOrders)),
    switchMap(({ payload }) =>
      api.fetchOrders(payload.params).pipe(
        map(ActionCreator.fetchOrdersSuccess),
        catchError(e => of(ActionCreator.fetchOrdersFailure(new Error('error'))))
      )
    )
  )

export const fetchParcels: Epic = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(ActionCreator.fetchParcels)),
    switchMap(({ payload }) =>
      api.fetchOrders({ ...payload.params, isParcel: true }).pipe(
        map(res => {
          const orders = res.orders.sort(sortByDate)
          const items = res.items
          const reviews = res.reviews
          return { orders, items, reviews, meta: res.meta }
        }),
        map(ActionCreator.fetchParcelsSuccess),
        catchError(e => {
          console.log(e)
          return of(ActionCreator.fetchParcelsFailure(new Error('error')))
        })
      )
    )
  )

export const archiveUnarchiveOrder: Epic = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(ActionCreator.archiveUnarchiveOrder)),
    switchMap(({ payload }) =>
      api
        .archiveUnarchiveOrder(payload.id, payload.archive, payload.isBundle)
        .pipe(map(() => ActionCreator.archiveUnarchiveOrderSuccess(payload)))
    )
  )

export const createPayment: Epic = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(ActionCreator.createPayment)),
    switchMap(({ payload }) =>
      api.createPayment(payload).pipe(
        map(({ id }) => ActionCreator.createPaymentSuccess({ id, items: payload.items })),
        catchError(() => of(ActionCreator.createPaymentFailure(new Error('error'))))
      )
    )
  )
