import { SYSTEM_UNAVAILABLE } from '@constants/'
import { platformToProvider } from '@utils/platform'
import { ReactNode, useState } from 'react'
import { resetImageSize } from '@utils/image'
import { useMyCollections } from '@hooks/useMyCollections'
import { GW_TAOBAO_CDN } from '@utils/imageUtils'
import { BadRequestError } from '@errors/BadRequestError'

export const useCollections = (item, cb) => {
  const { actions, data } = useMyCollections()

  const [error, setError] = useState<ReactNode>('')

  const handleAddToCollection = async (collectionId: number, updateCover: boolean) => {
    const url = new URL(resetImageSize(item.pic))

    const picId = (() => {
      const index = item.pic.indexOf('jfs')
      if (index > -1) {
        return item.pic.slice(index)
      } else if (url.hostname === GW_TAOBAO_CDN) {
        return url.pathname.slice(1)
      }

      return item.pic
    })()


    const form = {
      productId: item.id,
      cover: { id: picId },
      name: item.title,
      description: item.desc,
      provider: platformToProvider(item.platform),
    }

    return actions.addProductToCollection(collectionId, form).then(() => {

      if (updateCover) {
        return fetch(url.toString())
          .then(resp => resp.arrayBuffer())
          .then(buf => new File([buf], 'cover.jpg', { type: 'image/jpeg' }))
          .then(pic =>
            actions.updateCollection(collectionId, { pic })
          )
      }

    }).then(() => {
      const target = data.find(d => d.id === collectionId)

      cb(target)
    }).catch(({ payload }) => {
      if (payload?.error instanceof BadRequestError) {
        const message = payload.error.message

        setError(message)
        return Promise.reject()
      }

      return Promise.reject(SYSTEM_UNAVAILABLE)
    })
  }

  return { onAddToCollection: handleAddToCollection, error, item, resetError: () => setError('') }
}
