import * as constants from '@constants/tracking'

const tracking = (state = null, { type, payload }) => {
  switch (type) {
    case constants.FETCH_TRACKING_SUCCESS:
      return payload.data

    case constants.FETCH_TRACKING_FAILED:
      return null

    default:
      return state
  }
}

export default tracking
