import ACTION from '@constants/warehouse'
import { createStandardAction } from 'typesafe-actions'

export const selectItem = createStandardAction(ACTION.SELECT_ITEM)<number[]>()
export const deselectItem = createStandardAction(ACTION.DESELECT_ITEM)<number[]>()

export const fetchWarehouseItems = createStandardAction(ACTION.FETCH_WAREHOUSE_ITEMS_REQUEST).map(
  () => ({
    meta: {
      lifecycle: {
        resolve: ACTION.FETCH_WAREHOUSE_ITEMS_SUCCESS,
        reject: ACTION.FETCH_WAREHOUSE_ITEMS_FAILED,
      },
    },
  })
)

export const fetchWarehouseItemsSuccess = createStandardAction(
  ACTION.FETCH_WAREHOUSE_ITEMS_SUCCESS
)<{
  items: any[]
}>()
export const fetchWarehouseItemsFailure = createStandardAction(ACTION.FETCH_WAREHOUSE_ITEMS_FAILED)<
  Error
>()

