import ProductCard from './ProductCard'
import React, { FC, useRef, useState } from "react";
import { useQuery } from '@hooks/useRouter'
import { useAtomValue } from 'jotai';
import { listViewLayoutAtom } from 'atoms/list-view-layout';
import ReviewUnit from '@components/common/ReviewUnit'
import { Link } from 'react-router-dom'
import './styles.css'
import { upvote, downvote } from '@hooks/useReviews'
import { updateVote } from '@utils/useReviews'

interface ProductListProps {
  products: any[]
  showSellerWithCategories: boolean
}

const ProductList: FC<ProductListProps> = ({ products, showSellerWithCategories, ...props }) => {
  const ref = useRef(null)
  const query = useQuery()
  const [productsWithReviews, setProductsWithReviews] = useState(products)

  const count = useAtomValue(listViewLayoutAtom)

  const className = `${['col-lg-3', 'col-md-4', `${ count == 1 ? 'col-12' : 'col-6' }`].map(size => `v4-${size}`).join(' ')} tw-mb-12 tw-px-2`

  const  upvoteReview = (id: number) =>
    upvote(id).then(() => {
      const review = productsWithReviews.find((p) => !!p.rating && p.id === id);
      const index = productsWithReviews.findIndex((p) => !!p.rating && p.id === id);
      const newReview = updateVote(review, true);
      let data = [...productsWithReviews];
      data.splice(index, 1, newReview)

      setProductsWithReviews(data);
    }).catch((e) => console.log(e))

  const  downvoteReview = (id: number) =>
    downvote(id).then(() => {
      const review = productsWithReviews.find((p) => !!p.rating && p.id === id);
      const index = productsWithReviews.findIndex((p) => !!p.rating && p.id === id);
      const newReview = updateVote(review, false);
      let data = [...productsWithReviews];
      data.splice(index, 1, newReview)

      setProductsWithReviews(data);
    }).catch((e) => console.log(e))


  return (
    <div className="v4-row v4-no-gutters" styleName="wrapper">
      {productsWithReviews.map((product, index) => (
        <React.Fragment key={index}>
          {
            typeof product.minPrice !== 'undefined' && product.minPrice !== null ? (
              <div className={className}>
                <ProductCard
                  id={product.id}
                  skuId={product.skuId}
                  titles={product.titles}
                  cover={product.platform === 'tmall' ? (product.thumbnails[1] ?? product.cover) : product.cover}
                  thumbnails={product.thumbnails}
                  sold={product.sold}
                  originalPrice={product.minPrice}
                  platform={product.platform}
                  sellerId={product.seller?.id}
                  unavailable={product.unavailable}
                  prettyUpImage={props.prettyUpImage}
                  renderThumbnails={props.renderThumbnails}
                  hideDiscount={props.hideDiscount}
                  hasContentCover={product.hasContentCover}
                  defaultThumbnailIdx={props.defaultThumbnailIdx}
                  ref={ref}
                  {...product.location && {
                    location: [product.location.state, product.location.city],
                  }}
                  {...product.promotion &&
                  product.promotion.minPrice && {
                    discountPrice: product.promotion.minPrice,
                  }}
                  {...query.at && { affiliateToken: query.at }}
                  {...query.via && { affiliateVia: query.via }}
                />
              </div>
            ) : (
              <>
                {product.rating && (
                  <div
                    styleName="review"
                    className={`v4-col-lg-6 v4-col-md-8 v4-col-12 tw-mb-12 tw-px-2 relative`}
                    style={{ height: ref.current?.height }}
                  >
                    <ReviewUnit
                      review={product}
                      user={!!product.user && product.user}
                      product={!!product.product && product.product }
                      showByUser={true}
                      showCompleteDetails={false}
                      showImages={false}
                      isWhatHot={true}
                      showContentRows={5}
                      handleUpvote={upvoteReview}
                      handleDownvote={downvoteReview}
                      imageWidth={150}
                    />
                    <Link
                      to={`/discover/reviews`}
                      target="_blank"
                      styleName="button position_absolute"
                    >
                      More Reviews
                    </Link>
                  </div>
                )}
              </>
            )
          }
        </React.Fragment>
      ))}
    </div>
  )
}

ProductList.defaultProps = {
  products: [],

  renderThumbnails: true,
  prettyUpImage: true,
}

export default ProductList
