import { useQuery } from '@hooks/useRouter'
import { saveAffiliateFromToStorage } from '@utils/storage'

const Affiliate = () => {
  const { at } = useQuery()

  if (at) {
    saveAffiliateFromToStorage(`${at};${Date.now()}`)
  }

  return null
}

export default Affiliate
