import AnimatedButton from '@components/common/AnimatedButton'
import { SYSTEM_UNAVAILABLE } from '@constants/'
import { UnauthorizedError } from '@errors/UnauthorizedError'
import useAuth from '@hooks/useAuth'
import { useEnter } from '@hooks/useKeyCode'
import { closeLoginModal } from '@hooks/useLoginModal'
import { AuthProvider, LoginForm as ILoginForm } from '@interfaces/auth'
import React, { FC, ReactNode, useEffect, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { useFormState } from 'react-use-form-state'
import FormInput from './FormInput'
import Links from './Links'
import './styles.css'

interface LoginFormProps { }

const LoginForm: FC<LoginFormProps> = () => {
  const [error, setError] = useState<string | ReactNode | null>(null)

  const [formState, { text, password }] = useFormState()

  const hasFormValue = Object.values(formState.values).every(v => v)

  const { login } = useAuth()

  const handleSubmit = e => {
    e && e.preventDefault()

    const {
      values: { usernameOrEmail, password },
    } = formState

    if (hasFormValue) {
      return login(AuthProvider.EMAIL, { usernameOrEmail, password })
        .then(closeLoginModal)
        .catch(err => {
          // console.warn(formState)
          console.warn(err)
          if (err instanceof UnauthorizedError) {
            if (err.message === 'not_confirmed') {
              setError(
                <span>
                  Please verify your email address.{' '}
                  <Link
                    to={{
                      pathname: '/account/verify',
                      state: { email: formState.values.usernameOrEmail },
                    }}
                    onClick={closeLoginModal}
                  >
                    Resend verification email
                  </Link>
                  .
                </span>
              )
            } else {
              setError('Invalid login credentials. Please try again.')
            }
          } else {
            setError(SYSTEM_UNAVAILABLE)
          }

          return Promise.reject()
        })
    }
  }

  const btnRef = useRef(null)
  const enter = useEnter()

  if (enter) {
    btnRef.current.click()
  }

  const reset_input_handler = () => {
    window.scrollBy(0, -1)
    setTimeout(() => {
      window.scrollBy(0, 1)
    }, 10)
  }

  useEffect(() => {
    document.addEventListener('focusout', reset_input_handler)
    document.addEventListener('blur', reset_input_handler)

    return () => {
      document.removeEventListener('focusout', reset_input_handler)
      document.removeEventListener('blur', reset_input_handler)
    }
  }, [])

  return (
    <form autoComplete="off" id="loginForm" styleName="form">
      {error ? <p className="text-danger">{error}</p> : ''}

      <FormattedMessage id="login_modal.placeholder_name">
        {txt => <FormInput placeholder={txt} {...text('usernameOrEmail')} />}
      </FormattedMessage>

      <FormattedMessage id="login_modal.placeholder_password">
        {txt => <FormInput placeholder={txt} {...password('password')} />}
      </FormattedMessage>

      <Links />

      <div className="text-center">
        <AnimatedButton
          styleName="submit"
          forwardRef={btnRef}
          {...!hasFormValue && { successTransition: 0 }}
          className="btn btn-white btn-white--modal strong"
          type="button"
          onClick={handleSubmit}
        >
          <FormattedMessage id="login_modal.btn_log-in" />
        </AnimatedButton>
      </div>
    </form>
  )
}

export default LoginForm
