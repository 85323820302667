import { ContentType, Data, Opt, Respond } from '@interfaces/content'
import { fetchContent } from '@services/content'

type ApiProps = { [key in ContentType]: string }

const Api: ApiProps = {
  [ContentType.ccc]: `/cccs`,
  [ContentType.daily]: `/daily_deals`,
  [ContentType.trendings]: `/trending_products`,
}

export const fetch = (opt: Opt, type: ContentType) => fetchContent(opt, Api[type]).toPromise()

export const initData = (type: ContentType): Data => ({
  [type]: [],
  products: [],
})

export const initMeta = {
  currentPage: 0,
  totalCount: 0,
  totalPages: 0,
}

export const initRespond = (type: ContentType): Respond => ({
  data: initData(type),
  meta: initMeta,
})
