import React, {useState} from "react";
import CircleSpinner from '@components/common/CircleSpinner'
import './styles.css'

type LazyLoadImageProps = {
  image: string;
  square?: boolean;

  className?: string
}

const LazyLoadImage: React.SFC<LazyLoadImageProps> = props => {
  const [loading, setLoading] = useState(true)
  const [isSquare, setIsSquare] = useState(true)

  const handleOnLoad = (img: string) => {
    setLoading(true)

    const image = new Image()
    image.onload = () => {
      setLoading(false)

      if (image.width !== image.height) {
        setIsSquare(false)
      }
    }
    image.src = img
  }

  React.useEffect(() => {
    handleOnLoad(props.image);
  }, [props.image])

  const styleName = []

  if (props.square) {
    styleName.push('square')
  }
  else {
    styleName.push('default')
  }

  return (
    <div
      className={`relative ${props.className}`}
      styleName={styleName.join(' ')}
      data-square={isSquare}
    >
      {loading ? renderLoader() : renderImage(props.image)}
    </div>
  )
}

LazyLoadImage.defaultProps = {
  className: ''
}

export default LazyLoadImage

const renderLoader = () => <div styleName="loader"><CircleSpinner /></div>
const renderImage = (image: string) => <img className='tw-h-full tw-w-full' styleName='image' src={image} />
