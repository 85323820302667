import * as ACTION from '@actions/products'
import { createReducer } from 'typesafe-actions'

export interface ProductImages {
  selected: string
  thumbnails: any[]
}

const initialState: ProductImages = {
  thumbnails: [],
  selected: null,
}

export const productImages = createReducer(initialState)
  .handleAction(ACTION.fetchSingleProduct.success, (state, action) => {
    return {
      ...state,
      selected: action.payload.product?.cover || action.payload.product?.thumbnails?.[0],
      thumbnails: action.payload.product?.thumbnails || [],
    }
  })
  .handleAction(ACTION.updateProductImage, (state, action) => {
    return {
      ...state,
      ...(action.payload.image && { selected: action.payload.image }),
      ...(action.payload.thumbnails?.length && { thumbnails: action.payload.thumbnails })
    }
  })
