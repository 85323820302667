import { usePrevious } from '@hooks/usePrevious'
import { useLocation } from '@hooks/useRouter'
import { useEffect } from 'react'

const CartOnCheckoutHook = ({ onLoadCheckout }) => {
  const { location } = useLocation()

  const currentPathname = location.pathname
  const prevPathname = usePrevious(location.pathname)

  useEffect(
    () => {
      if (prevPathname && currentPathname === '/checkout' && prevPathname !== currentPathname) {
        onLoadCheckout()
      }
    },
    [prevPathname, currentPathname]
  )

  return null
}

export default CartOnCheckoutHook
