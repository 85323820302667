import React from 'react'
import { PageSection } from './PageSection'
import { useAtom } from 'jotai'
import { recentSearchAtom } from '@atoms/recent-search'
import { showPath } from '@routing/department/linkRoutes'
import { RESET } from 'jotai/utils'
import { Link } from 'react-router-dom'
import ErrorBoundary from '@components/common/ErrorBoundary'

const SearchHistory = () => {
  const [recentHistory, setRecentHistory] = useAtom(recentSearchAtom)

  if (recentHistory.length > 0) {
    return (
      <ErrorBoundary content="">
        <PageSection heading="SEARCH HISTORY" onAction={() => setRecentHistory(RESET)}>
          <div className="tw-flex tw-flex-wrap tw-gap-4 tw-pb-3 tw-pt-2 tw-px-4">
            {recentHistory.map((s, index) => (
              <div key={index} className="bd-light tw-px-5 tw-py-2">
                <Link to={showPath(s)} className="black-link">
                  {decodeURIComponent(s.title || s.term)}
                </Link>
              </div>
            ))}
          </div>
        </PageSection>
      </ErrorBoundary>
    )
  }

  return null
}

export default SearchHistory
