import ProgressiveSkeleton from '@components/common/ProgressiveSkeleton'
import TranslationText from '@components/common/TranslationText'
import SEO from '@components/SEO'
import {useSellerDetails} from '@hooks/useSellerDetails'
import React, {FC, useEffect} from "react";
import {Redirect} from 'react-router-dom'
import FormattedSeller, {formatSeller} from '@components/common/FormattedSeller'

const description = (q: string) =>
  `Shop a wide selection of ${q} on Baopals.com. Baopals brings you over 1 billion products from China's shopping platforms with great low prices, fast delivery and friendly service from your pals`

interface Props {
  id: number
  totalProducts: number | undefined
  platform: string
  setSellerCategory: any
}

const WrapperClassName = 'text-center tw-my-12 px-4'

const Details: FC<Props> = ({id, totalProducts, platform, setSellerCategory}) => {
  const {loading, data} = useSellerDetails(id, platform)

  useEffect(() => {
    if (data) {
      setSellerCategory(data.category)
    }
  }, [data])

  if (loading) {
    return (
      <div className={WrapperClassName}>
        <ProgressiveSkeleton
          width="50%"
          height={57}
          enableMoveAnimation={true}
          className="tw-mt-0 d-block mx-auto"
        />
      </div>
    )
  }

  if (!data && totalProducts === 0) {
    return <Redirect to="/404" />
  }

  const renderHead = (title: string) => (
    <React.Fragment>
      <SEO
        title={formatSeller(title, {ignoreUppercase: true})}
        description={description(title)}
      />
      <div className={WrapperClassName}>
        <div className="fs40 italic text-capitalize"><FormattedSeller name={title} /></div>
      </div>
    </React.Fragment>
  )

  const titles = (data?.titles || {zhCn: ''})

  return (
    <TranslationText texts={titles}>
      {(title: string) => renderHead(title)}
    </TranslationText>
  )
}

export default Details
