const NS = 'departments'

export const FETCH_ALL_REQUEST = `${NS}/FETCH_ALL_REQUEST`
export const FETCH_ALL_DONE = `${NS}/FETCH_ALL_DONE`
export const FETCH_REQUEST = `${NS}/FETCH_REQUEST`
export const FETCH_DONE = `${NS}/FETCH_DONE`
export const FETCH_POPULAR_PRODUCTS_REQUEST = `${NS}/FETCH_POPULAR_PRODUCTS_REQUEST`
export const FETCH_POPULAR_PRODUCTS_DONE = `${NS}/FETCH_POPULAR_PRODUCTS_DONE`
export const FETCH_FEATURED_SELLERS_REQUEST = `${NS}/FETCH_FEATURED_SELLERS_REQUEST`
export const FETCH_FEATURED_SELLERS_DONE = `${NS}/FETCH_FEATURED_SELLERS_DONE`
