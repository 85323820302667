import { round } from './number'

function arrayToNestedChildren(arr, parent, parentKey = 'parent', childrenKey = 'children') {
  const out = []
  for (var i in arr) {
    if (arr[i][parentKey] == parent) {
      var children = arrayToNestedChildren(arr, arr[i].id, parentKey, childrenKey)

      if (children.length) {
        arr[i][childrenKey] = children
      }
      out.push(arr[i])
    }
  }
  return out
}

function paginate(array, pageSize, pageNumber) {
  --pageNumber
  return array.slice(pageNumber * pageSize, (pageNumber + 1) * pageSize)
}

function concat(arr, data, index) {
  return arr
    .slice(0, index)
    .concat(data)
    .concat(arr.slice(index + 1))
}

function findBy(arr, field, value) {
  const index = arr.findIndex(obj => obj[field] == value)
  return [arr[index], index]
}

const chunk = (arr, size) =>
  arr.reduce(
    (chunks, el, i) =>
      (i % size ? chunks[chunks.length - 1].push(el) : chunks.push([el])) && chunks,
    []
  )

const groupBy = (xs, key) =>
  xs.reduce((rv, x) => {
    ;(rv[x[key]] = rv[x[key]] || []).push(x)
    return rv
  }, {})

const evenArrayLength = arr => {
  if (arr.length % 2 === 0) {
    return arr
  }

  return arr.slice(0, -1)
}

export const sortByDate = (a, b, field = 'createdAt') =>
  new Date(b[field]).getTime() - new Date(a[field]).getTime()

export function sum(items = [], name, precision = 2) {
  return round(round(items.reduce((prev, next) => prev + +next[name], 0), 4), precision)
}

export { groupBy, arrayToNestedChildren, paginate, chunk, concat, findBy, evenArrayLength }
