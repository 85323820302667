import React, { FC } from 'react'
import './styles.css'

const TranslationToggler: FC<any> = ({toggle, onToggle}) => (
  <div className={`${!toggle ? 'text-orange' : ''}`} onClick={onToggle} id="tour-translate-search">
    <i className="icon-translate" />
    {false && (
      <i className="fa fa-times" styleName="clean" onClick={() => console.log('clear..')} />
    )}
  </div>
)

TranslationToggler.defaultProps = {
  toggle: false
}

export default TranslationToggler
