import { Image } from '@interfaces/image'
import { cloudObjectToURL } from './cloudStorage'

export const getAvatar = (image?: Image) =>
  image ? cloudObjectToURL(image) : BAOPALS.IMAGE_PLACEHOLDER

export const getImages = (images: Image[], size?: string) =>
  images.map(img => cloudObjectToURL(img, { size }))

export const formatImages = (images: Image[]) =>
  images.map(image => ({ ...image, url: cloudObjectToURL(image) }))
