export enum Currency {
  MOP = 'mop',
  AUD = 'aud',
  CAD = 'cad',
  CHF = 'chf',
  EUR = 'eur',
  HKD = 'hkd',
  HUF = 'huf',
  NZD = 'nzd',
  PLN = 'pln',
  RUB = 'rub',
  USD = 'usd',
  CNY = 'cny',
  SGD = 'sgd',
  GBP = 'gbp',
}

export type ExchangeRate = {
  [key in Currency]?: number
  // [key in keyof typeof Currency]: number;
}

export enum CurrencySymbol {
  CNY = '¥',
  USD = '$',
  AUD = 'A$',
  HKD = 'HK$',
  SGD = 'S$',
  CAD = 'C$',
}

export enum CurrencyRate {
  DEFAULT = 1
}

export interface PriceWithCurrency {
  currency: string
  currencyRate: number
}
