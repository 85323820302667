import DottedLine from '@components/common/DottedLine'
import FlexBetween from '@components/common/FlexBetween'
import SortAndFilter from '@components/common/SortAndFilter'
import UnstyledList from '@components/common/UnstyledList'
import { ReviewFilter } from '@components/discover/Activity'
import { useQuery, useLocation } from '@hooks/useRouter'
import { useHotCategories } from '@hooks/useWhatsHot'
import { arrayToNestedChildren } from '@utils/'
import { cloudObjectToURL } from '@utils/'
import qs from 'qs'
import { Breadcrumb } from 'react-bootstrap'
import { Nav, NavItem, Tab } from 'react-bootstrap'
import { Link, NavLink } from 'react-router-dom'
import { Reviews } from '../Home'
import Products from '../Products'
import { Index as Blog } from '@components/blog'
import CategorySection from './CategorySection'
import ROUTING from "@constants/url";
import './styles.css'
import { getFilteredCategories } from "@helpers/contentCategoriesHelpers";
import { useMyCountry } from "@hooks/useAuth";
import { ShowModalPositions } from "@interfaces/auth";
import useScroll from '@hooks/useScroll'
import React, { useRef } from 'react'

// const NAVS = [
//   { key: 'home', label: "What's Hot" },
//   { key: 'categories', label: 'Categories' },
//   // { key: 'blog', label: 'Blog' },
// ]
const NAV_WHATS_HOT = { key: 'home', label: "What's Hot" }
const NAV_HOT_PRODUCTS = { key: 'home', label: "Hot Products" }
const NAV_CATEGORIES = { key: 'categories', label: 'Categories' }
const NAV_REVIEW = { key: 'reviews', label: 'Reviews' }
const NAV_BLOG = { key: 'blog', label: 'BLog' }

const menu = ({ name, cover, slug, categories, sellerCategory, ...props }) => (
  <>
    <Link styleName='cover' onClick={props.closeMenu} className='bd-light p-4 text-center d-block link-black'
      to={`${window.location.pathname}?${qs.stringify({
        category: slug,
        page: 1,
        tab: 'categories',
        ...(sellerCategory && {seller_category: sellerCategory}),
      })}`}
    >
      <img styleName='main' className='mx-auto img-responsive img-circle' src={cloudObjectToURL(cover, { size: '100x100', quality: 100 })} alt={name} />
      <div className='strong text-uppercase tw-mt-6 tw-pt-4'>{name}</div>
    </Link>

    <UnstyledList className="px-4" style={{ paddingBottom: "150px" }}>
      {[...categories].sort((a, b) => a.title.localeCompare(b.title)).map(c => (
        <Link key={c.id}
          onClick={props.closeMenu}
          className="d-block link-black"
          to={`${window.location.pathname}?${qs.stringify({
            category: c.slug,
            page: 1,
            tab: 'categories',
            ...(c.sellerCategory && {seller_category: c.sellerCategory}),
          })}`}
        >
          <FlexBetween
            renderLeft={
              <div className="py-4">
                <img
                  className="img-responsive img-circle"
                  styleName="sub"
                  src={cloudObjectToURL(c.cover, { size: '100x100', quality: 100 })}
                />
              </div>
            }
            renderRight={<div className="strong text-uppercase">{c.title}</div>}
          />
          <DottedLine />
        </Link>
      ))}
    </UnstyledList>
  </>
)

const findAncestors = ({ categories, id, rootCategory }) => {
  let parentId = id
  const ancestors = []

  while (parentId && parentId !== rootCategory) {
    const parentCat = categories.find(c => c.id === parentId)

    if (parentCat) {
      ancestors.push(parentCat.id)
      parentId = parentCat.parent
    } else {
      parentId = undefined
    }
  }

  return ancestors
}

const toPath = (id?: number, sellerCategory?: string) => `${window.location.pathname}?${qs.stringify({
  ...(id && { category: id }),
  ...(sellerCategory && { seller_category: sellerCategory }),
  page: 1,
  tab: 'categories',
})}`

const CategoryBreadcrumb = ({ slug }) => {
  const { categories } = useHotCategories()
  const { locationCountryCode } = useMyCountry()

  const category = categories.find(c => c.slug === slug)
  const filteredCategories = getFilteredCategories(categories, locationCountryCode)

  const allCategories = filteredCategories.map(c => ({ ...c, parent: c.parentId || -1, name: c.title }))
  const nest = arrayToNestedChildren(allCategories, -1)

  const ans = category
    ? findAncestors({ categories: allCategories, id: category.id, rootCategory: -1 })
    : []

  const isSub = category ? !!category.parentId : false

  const subCategories = ans.map(id => allCategories.find(c => c.id === id)).reverse()
  const subs = subCategories.map(c => c.children || []).flat()

  const breadcrumbCats = subCategories.filter(v => !v.parentId)

  return (
    <SortAndFilter
      className="tw-py-4 px-3 fs12"
      styleName='sort'
      renderMenu={menu}
      renderFooter={null}
      menuProps={{
        slug: subCategories[0]?.slug,
        name: subCategories[0]?.name,
        cover: subCategories[0]?.cover,
        categories: subs,
        sellerCategory: subCategories[0]?. sellerCategory
      }}
      renderMenuHeading={false}
      renderLeft={
        <Breadcrumb className="tw-py-0 tw-mt-2">
          <Breadcrumb.Item href={toPath()} componentClass={Link} to={toPath()}>
            Everything
          </Breadcrumb.Item>
          {breadcrumbCats.length > 0
            ? breadcrumbCats.map(c => <Breadcrumb.Item key={c.id} href={toPath(c.slug, c.sellerCategory)} componentClass={Link} to={toPath(c.slug, c.sellerCategory)}>{c.title}</Breadcrumb.Item>)
            : null}
        </Breadcrumb>
      }
      menuId="hot-categories"
    >
      <span className="fs12 text-uppercase" data-has-children={subs.length > 0}>{isSub ? `${category.title}` : 'GO DEEPER'}</span>
    </SortAndFilter>
  )
}


const NavItems = ({ country }) => {
  const query = useQuery()
  const { scrollY } = useScroll()

  const ref = useRef()
  const top = ref?.current?.getBoundingClientRect().top
  const fixed = top <= 92

  const {
    location
  } = useLocation()

  const pathname = (query: any) => {
    const url = `${location.pathname}?${qs.stringify(query)}`
    return { href: url, to: url, componentClass: NavLink }
  }

  const isHotProducts = location.pathname === ROUTING.HOT || location.pathname === '/'
  const navs = [isHotProducts ? NAV_HOT_PRODUCTS : NAV_WHATS_HOT, NAV_CATEGORIES]
  const filteredNavs = navs.concat(isHotProducts ? [] : [NAV_REVIEW, NAV_BLOG])

  const activeKey = query.cat_id ? NAV_CATEGORIES.key : filteredNavs.map(n => n.key).includes(query.tab) ? query.tab : NAV_WHATS_HOT.key

  const rest = {
    key: `${query.category}:${country}`,
    country,
    prettyUpImage: false,
    device: 'mobile',
    page: +(query.page || 1),
    ...(query.seller_category && { seller_category: query.seller_category })
  }

  return (
    <div>
      <Tab.Container
        id="tabs-with-dropdown"
        defaultActiveKey={activeKey}
        styleName="nav"
        activeKey={activeKey}
        onSelect={() => { }}
        data-navsLength={filteredNavs.length}
      >
        <div>
          <div data-fixed={fixed} styleName="tabWrapper">
            <Nav bsStyle="tabs" className="bg-white d-flex justify-content-between">
              {filteredNavs.map(nav => (
                <NavItem
                  eventKey={nav.key}
                  key={nav.key}
                  {...pathname({ tab: nav.key })}
                  className="text-uppercase bold"
                >
                  {nav.label}
                </NavItem>
              ))}
            </Nav>
          </div>
          <div ref={ref}>
            <Tab.Content animation={true} mountOnEnter={true} unmountOnExit={true}>
              <Tab.Pane eventKey="home">
                <div className="pt-4">
                  <div className="container">
                    <Products {...rest} scrollTo={ShowModalPositions.MOBILE} />
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="categories">
                {query.category ? (
                  <div>
                    <CategoryBreadcrumb slug={query.category} />
                    <div className='p-4'>
                      <Products {...rest} scrollTo={ShowModalPositions.MOBILE} category={query.category} />
                    </div>
                  </div>
                ) : (
                  <div className="p-4">
                    <CategorySection />
                  </div>
                )}
              </Tab.Pane>
              <Tab.Pane eventKey="reviews">
                <ReviewFilter menuProps={{ renderViewFilter: false }} />
                <Reviews className="container tw-mt-6" />
              </Tab.Pane>
              <Tab.Pane eventKey="blog">
                <Blog />
              </Tab.Pane>
            </Tab.Content>
          </div>
        </div>
      </Tab.Container>
    </div>
  )
}

export default NavItems
