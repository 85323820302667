import MobileDetect from 'mobile-detect'
import * as currency from '../helpers/currencyHelper'

const mobileDetect = new MobileDetect(window.navigator.userAgent)

const sleep = (ms: number = 0) => new Promise(r => setTimeout(r, ms))

const isWeChat = () => /micromessenger/.test(navigator.userAgent.toLowerCase())

const isReactNative = () => window.__REACT_NATIVE__ === true

// const isMiniProgram = () =>
//   window.__wxjs_environment === 'miniprogram' || window.__IS_MINI_PROGRAM__ === true

const isIpad = () => /ipad/.test(navigator.userAgent.toLowerCase())

const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);

const isTouchDevice = () =>
  'ontouchstart' in window ||
  ((window as any).DocumentTouch && document instanceof (window as any).DocumentTouch) ||
  navigator.maxTouchPoints > 0 ||
  navigator.msMaxTouchPoints > 0

const isTouchableMobileDevices = () => isTouchDevice() && !!mobileDetect.mobile()

const getScreenTouchType = window.navigator.pointerEnabled
  ? 'pointer'
  : 'ontouchstart' in window
    ? 'touch'
    : 'mouse'

const formatBytes = (a: number, b: number) => {
  if (0 === a) {
    return '0 Bytes'
  }
  const c = 1024,
    d = b || 2,
    e = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    f = Math.floor(Math.log(a) / Math.log(c))

  return parseFloat((a / Math.pow(c, f)).toFixed(d)) + ' ' + e[f]
}

const fileToBlob = (file: File) => {
  if (window.webkitURL && window.webkitURL.createObjectURL) {
    return window.webkitURL.createObjectURL(file)
  } else {
    return window.URL.createObjectURL(file)
  }
}

export * from './object'
export * from './productUtils'
export * from './array'
export * from './image'
export * from './string'
export * from './lang'
export * from './number'
export * from './cloudStorage'

export {
  currency,
  sleep,
  fileToBlob,
  formatBytes,
  // isMiniProgram,
  isWeChat,
  isIpad,
  isIOS,
  isTouchDevice,
  isReactNative,
  getScreenTouchType,
  mobileDetect,
  isTouchableMobileDevices,
}
