import DottedLine from '@components/common/DottedLine'
import Guest from '@components/common/MobileNav/MobileMenuToggler/Menu/Guest'
import useAuth from '@hooks/useAuth'
import { cloudObjectToURL } from '@utils/'
import React from 'react'
import Nums from './Nums'
import Profile from './Profile'
import './styles.css'

const MobileProfile = () => {
  const { user } = useAuth()

  return user ? (
    <div className="text-center tw-w-full">
      <div className="tw-mt-8">
        <Profile
          username={user.username}
          avatar={cloudObjectToURL(user.avatar) || BAOPALS.IMAGE_PLACEHOLDER}
          displayName={user.displayName}
          fortunePoints={user.fortunePoints}
        />
      </div>
      <DottedLine className="tw-mt-6" />
      <Nums username={user.username} />
    </div>
  ) : (
    <Guest />
  )
}

export default MobileProfile
