import ACTION from '@constants/rewards'
import { HttpError } from '@errors/HttpError'
import { EarningHistoryParams } from '@interfaces/rewards'
import { createStandardAction } from 'typesafe-actions'

export const fetchMyEarningsNum = createStandardAction(ACTION.FETCH_MY_EARNINGS_NUM_REQUEST)()

export const fetchMyEarningsNumSuccess = createStandardAction(
  ACTION.FETCH_MY_EARNINGS_NUM_SUCCESS
)<{
  nums: any
}>()
export const fetchMyEarningsNumFailure = createStandardAction(ACTION.FETCH_MY_EARNINGS_NUM_FAILURE)<
  HttpError
>()

export const fetchMyEarningsHistory = createStandardAction(
  ACTION.FETCH_MY_EARNINGS_HISTORY_REQUEST
).map((payload: EarningHistoryParams) => ({
  payload,
  meta: {
    lifecycle: {
      resolve: ACTION.FETCH_MY_EARNINGS_HISTORY_SUCCESS,
      reject: ACTION.FETCH_MY_EARNINGS_HISTORY_FAILURE,
    },
  },
}))

export const fetchMyEarningsHistorySuccess = createStandardAction(
  ACTION.FETCH_MY_EARNINGS_HISTORY_SUCCESS
)<{
  data: any
}>()
export const fetchMyEarningsHistoryFailure = createStandardAction(
  ACTION.FETCH_MY_EARNINGS_HISTORY_FAILURE
)<HttpError>()

export const fetchReferralEarnings = createStandardAction(
  ACTION.FETCH_MY_REFERRAL_EARNINGS_REQUEST
).map((payload: EarningHistoryParams) => ({
  payload,
  meta: {
    lifecycle: {
      resolve: ACTION.FETCH_MY_REFERRAL_EARNINGS_SUCCESS,
      reject: ACTION.FETCH_MY_REFERRAL_EARNINGS_FAILURE,
    },
  },
}))
export const fetchReferralEarningsSuccess = createStandardAction(
  ACTION.FETCH_MY_REFERRAL_EARNINGS_SUCCESS
)<{
  data: any
}>()
export const fetchReferralEarningsFailure = createStandardAction(
  ACTION.FETCH_MY_REFERRAL_EARNINGS_FAILURE
)<HttpError>()
