export const data = {
  searchBar: {
    text: [
      "Search in any language and we'll bring you the best results from China's largest shopping platforms. Use our dropdown of search suggestions to access our handpicked categories.",
      'If you wish to search an exact brand or product name, uncheck this box.',
      'Click the camera icon to upload a photo of a product and search for matches.',
    ],
    links: {
      domestic: [
        'https://www.baopals.com/help/cn-shopping?ref=find-products',
        'https://www.baopals.com/help/cn-shopping?ref=tips-searching-products',
      ],
      global: [
        'https://www.baopals.com/help/shopping?ref=how-to-find-products',
        'https://www.baopals.com/help/shopping?ref=shopping-quick-tips',
      ],
    },
  },

  allSellersDomestic: {
    text: (
      <>
        <div className="tw-mb-2">All Sellers includes Taobao, Tmall and Express products.</div>
        <div className="tw-mb-2">
          <a href="https://www.baopals.com/help/cn-shopping?ref=tmall-sellers" target="_blank">
            What are Taobao and Tmall?
          </a>
        </div>
        <div>
          <a href="https://www.baopals.com/help/cn-shopping?ref=baopals-express" target="_blank">
            What is Express?
          </a>
        </div>
      </>
    ),
  },

  allSellersInternational: {
    text: (
      <>
        <div className="tw-mb-2">All Sellers includes Taobao and Tmall products.</div>
        <div className="tw-mb-2">
          <a href="https://www.baopals.com/help/shopping?ref=what-is-tmall" target="_blank">
            What are Taobao and Tmall?
          </a>
        </div>
      </>
    ),
  },

  baopalsExpress: {
    text: 'Express products come from Jingdong (JD) and most orders are delivered within 48 hours.',
    link: 'https://www.baopals.com/help/cn-shopping?ref=baopals-express',
  },

  productSeller: {
    text: 'Click to view all products from this seller.',
  },

  sellerShipping: {
    text: 'Purchase more items from this seller to save on shipping.',
  },

  globalShippingFees: {
    text: (
      <>
        <p>
          Let’s quickly explain how we ship over 1 billion products to you from China.
        </p>
        <p>
          Phase 1 - Your order ships from Chinese sellers to the China warehouse. This shipping is typically free of charge.
        </p>
        <p>
          Phase 2 - Your global parcel ships from the warehouse to your final address. Global shipping fee is paid for this phase.
        </p>
        <p>
          To learn more about overseas shipping,{' '}
          <a href="https://www.baopals.com/overseas-shipping-rates" className="blue-link">
            click here
          </a>
          .{' '}
        </p>
      </>
    ),
  },

  globalClothingSize: {
    text: (
      <>
        We highly recommend that you review our{' '}
        <a
          href="https://www.baopals.com/clothing-size-guide/women-tops-dresses"
          className="blue-link"
        >
          Clothing Size Guide
        </a>{' '}
        before purchasing clothes on Baopals. Chinese clothing sizes are not the same as US or
        European sizes.
      </>
    ),
  },

  order: {
    text: [
      <>
        The <b>All Orders</b> page contains every order you've placed on Baopals, with the most
        recent at the top. Hover over a product for more details, including tracking updates for
        deliveries.
      </>,
      <>
        When overseas orders reach the China warehouse, they'll be added to the <b>In Warehouse</b>{' '}
        tab. There, you can consolidate items into one parcel and complete payment for overseas
        shipping.
      </>,
      "If you ever need help with an item you've purchased, simply click here, submit a help request and a Baopals agent will be on the case.",
    ],
  },

  translate_search: {
    text: [
      'When this icon is active, your search terms will be translated to Chinese. This is the default setting and is recommended for the majority of searches.',
      'When this icon is switched off, your search terms will not be translated to Chinese. This is useful when searching for a specific brand or product name.',
    ],
  },

  feb_14_us_shipping_fee: {
    text: (
      <>
        <div className="tw-mb-4">Dear US shoppers,</div>
        <div>
          Please note that on February 14th, US shipping prices will be updated to the following:
        </div>
        <div>
          A. Standard Line: $16.67 for the first 500 grams. Each additional 500 grams is $8.73.
        </div>
        <div>
          B. Sensitive Line: $24.61 for the first 500 grams. Each additional 500 grams is $11.90.
        </div>
        <div className="tw-mt-4">
          Items that are commonly considered "sensitive" and must be shipped via the sensitive cargo
          line include food, liquids, wooden objects, magnets, battery items, cosmetics and
          flammables.
        </div>
      </>
    ),
  },

  notifications: {
    text: "Welcome to Baopals! Here are your notifications. You will receive notifications regarding site activity, your orders and promos. Be sure to check out this first notification for a special gift from Baopals!"
  },

  product_image_search: {
    text: "Click the camera icon here to use the image to find more products like this one!"
  }
}

export enum tooltips {
  ALL_ORDER = 'all_orders',
  WAREHOUSE = 'warehouse',
  PARCELS = 'parcels',
  NEED_HELP = 'need_help',
  ALL_SELLERS_DOMESTIC = 'all_sellers_domestic',
  ALL_SELLERS_INTERNATIONAL = 'all_sellers_international',
  BAOPALS_EXPRESS = 'baopals_express',
  TRANSLATE_MY_SEARCH = 'translate_my_search',
  SEARCH_BAR = 'search_bar',
  IMAGE_SEARCH = 'image_search',
  PRODUCT_SELLER = 'product_seller',
  SELLER_SHIPPING = 'seller_shipping',
  WAREHOUSE_PACKAGE_FEE = 'warehouse_package_fee',
  WAREHOUSE_LARGE_EXTRA_FEE = 'warehouse_large_extra_fee',
  GLOBAL_SHIPPING_FEES = 'global_shipping_fees',
  GLOBAL_CLOTHING_SIZE = 'global_clothing_size',
  TRANSLATE_SEARCH = 'translate_search',
  FEB_14_US_SHIPPING_FEE = 'feb_14_us_shipping_fee',
  NOTIFICATIONS = 'notifications',
  CURRENCY_EXCHANGE_FEES = 'currency_exchange_fees',
  PRODUCT_IMAGE_SEARCH = 'search_with_image',
  GLOBAL_CNY_NOTICE = 'global_cny_notice_1',
  DOMESTIC_CNY_NOTICE = 'domestic_cny_notice'
}
