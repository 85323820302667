type PageSectionProps = {
  heading: string
  actionLabel?: string
  onAction?: Function
}

export const PageSection: React.FC<PageSectionProps> = props => {
  return (
    <div className="tw-py-4 bd-b-light">
      <div className="d-flex justify-content-between align-items-center">
        <h4 className="tw-pl-4 bold">{props.heading}</h4>
        {props.onAction && (
          <div className="text-grey fs12 tw-pr-4" onClick={() => props.onAction()}>
            {props.actionLabel}
          </div>
        )}
      </div>
      {props.children}
    </div>
  )
}

PageSection.defaultProps = {
  actionLabel: 'Clear all',
}
