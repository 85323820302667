import React from 'react'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'

const TextWithTooltip = ({ id, children, tooltip }) => (
  <OverlayTrigger
    overlay={<Tooltip id={id}>{tooltip}</Tooltip>}
    placement="top"
    delayShow={300}
    delayHide={150}
  >
    { children }
  </OverlayTrigger>
)

export default TextWithTooltip
