import NewUserTooltips from '@components/common/NewUserTooltips'
import { data, tooltips } from '@components/common/NewUserTooltips/json'

const TranslateSearchTour = ({ translate, onClose }) => {
  const { translate_search: { text } } = data

  const steps = [
    {
      target: '#tour-translate-search',
      content: translate ? text[0] : text[1],
      type: tooltips.TRANSLATE_SEARCH,
      disableBeacon: true,
      hideFooter: true,
      placement: 'bottom',
      styles: {
        options: {
          backgroundColor: '#3366cc',
          textColor: '#ffffff',
          arrowColor: '#3366cc',
        },
        tooltip: {
          padding: 5,
          right: 12
        }
      }
    }
  ]

  return (
    <NewUserTooltips
      steps={steps}
      disableScrolling={true}
      disableOverlayClose={false}
      callback={onClose}
      alwaysShow={true}
    />
  )
}

export default TranslateSearchTour
