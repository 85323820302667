import * as ACTION from '@actions/namedUrl'
import { createReducer } from 'typesafe-actions'

interface NamedUrlState {
  readonly loading: boolean
  readonly name: string
}

const initialState: NamedUrlState = {
  loading: true,
  name: '',
}

export const namedUrl = createReducer(initialState)
  .handleAction(ACTION.fetchNamedUrl.request, (state, action) => ({
    ...state,
    loading: true,
    name: '',
  }))
  .handleAction(ACTION.fetchNamedUrl.success, (state, action) => {
    return {
      ...state,
      loading: false,
      name: action.payload,
    }
  })
