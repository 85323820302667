import getYear from 'date-fns/getYear'
import isAfter from 'date-fns/isAfter'
import isBefore from 'date-fns/isBefore'
import { Festival } from "@components/layout/FestivalLink/json";

export const selectFestival = () => {
  const currentDate = new Date()
  const currentYear = getYear(currentDate)
  const getDate = (time: string) => new Date(`${time}, ${currentYear}`)
  const christmasStartDate = 'Dec 19'
  const christmasEndDate = 'Dec 26'

  const FestivalsData: {
    id: Festival;
    desktopLogoUrl: string;
    mobileLogoUrl: string;
    startDate: any;
    endDate: any
  }[] = [
    {
      id: Festival.christmas,
      desktopLogoUrl: 'https://res.cloudinary.com/baopals/image/upload/v1671499787/logo/christmas_logo_desktop.png',
      mobileLogoUrl: 'https://res.cloudinary.com/baopals/image/upload/v1671499868/logo/christmas_logo_mobile.png',
      startDate: getDate(christmasStartDate),
      endDate: getDate(christmasEndDate),
    }
  ]

  const currentFestivalLogo = FestivalsData.find(d => {
    const after = isAfter(currentDate, d.startDate)
    const before = isBefore(currentDate, d.endDate)

    return after && before && d
  })

  return currentFestivalLogo
}
