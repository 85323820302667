import * as ACTION from '@actions/shipping-formula'
import { createReducer } from 'typesafe-actions'

export interface Formula {
  additional: number
  base: number
  dimensionsWeight: number
  minHeight: number

  deliveryTime: number
}

export interface ShippingFormulaState {
  [country: string]: Formula
}

const initialState: ShippingFormulaState = {}

export const shippingFormula = createReducer(initialState).handleAction(
  ACTION.fetchShippingFormulaSuccess,
  (state, action) => {
    return {
      ...state,
      ...action.payload,
    }
  }
)
