import AwesomePagination from "@components/common/AwesomePagination"
import ProgressiveSkeleton from "@components/common/ProgressiveSkeleton"
import {useArticles} from "@hooks/useBlog"
import {useQuery} from "@hooks/useRouter"
import React, {FC} from "react"
import ArticleUnit from "../ArticleUnit"
import SuggestedPosts from "../SuggestedPosts"
import TrendingProducts from "../TrendingProducts"
import ScrollToTop from "@components/common/ScrollToTop"
import BreadCrumb from "@components/blog/Breadcrumb";
import {CategoryData} from "@interfaces/blog";
import {getLinks} from "@utils/blog";

interface Props {
  categories: CategoryData[]
}

const PER_PAGE = 12

const Loading = () => [1, 2, 3].map(v => (
  <ProgressiveSkeleton width="100%" height={400} enableMoveAnimation={true} className="tw-mb-12 d-block" key={v} />
))

const Main: FC<Props> = ({categories}) => {
  const query = useQuery()

  const opts = {
    page: query.page ?? 1,
    per: PER_PAGE,
    ...(query.q && {sq: query.q}),
    ...(query.cat && {slug: query.cat})
  }

  const {
    loading, data: {articles, products, covers}, meta
  } = useArticles(opts)

  const links = getLinks(categories, query.cat)

  return (
    <div className="container tw-mt-12">
      <ScrollToTop>
        {!!links.length && !loading && <BreadCrumb links={links} />}
        <div className='row'>
          <div className='col-md-8'>
            {loading ? (
              <Loading />
            ) : (
              <>
                {query.q && !articles.length && (
                  <div className="text-center text-grey tw-mb-12">
                    {`Sorry, we couldn't find any results on the blog for "${query.q}"`}.
                  </div>
                )}
                {articles.map(a =>
                  <ArticleUnit
                    key={a.id}
                    article={a}
                    products={products.find(p => p.articleId === a.id)?.data}
                    covers={covers}
                    className='tw-mb-12'
                    cat={query.cat}
                  />
                )}

                {meta.totalPages > 1 && (
                  <div className="text-center">
                    <AwesomePagination
                      summary={true}
                      totalCount={meta.articlesCount}
                      itemsPerPage={PER_PAGE}
                    />
                  </div>
                )}
              </>
            )}
          </div>
          <div className='col-md-4'>
            <TrendingProducts />
            <div className='visible-lg'>
              <SuggestedPosts />
            </div>
          </div>
        </div>
      </ScrollToTop>
    </div>
  )
}

export default Main
