import {fetchJdCategoryDetails} from '@services/category'
import './styles.css'

const JDCategoryDetails = ({id}) => {
  const cat = fetchJdCategoryDetails(id)

  return cat ? (
    <div className='bold text-truncate text-uppercase tw-mb-6' styleName='heading'>
      {cat.name}
    </div>
  ) : null
}
export default JDCategoryDetails
