import { of } from 'rxjs'
import { catchError, map, pluck } from 'rxjs/operators'
import ajax from '../api/ajax'

export const fetchUrlName = (query: string) =>
  ajax({
    method: 'GET',
    url: '/products/url_matches',
    query: { query },
  }).pipe(
    pluck('response'),
    catchError(err => of(''))
  )
