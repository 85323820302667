import React from 'react'
import ProgressiveSkeleton from '@components/common/ProgressiveSkeleton'
import { Mobile, Default } from '@components/common/Responsive'
import SEO from '@components/SEO'
import './styles.css'

const renderSkeleton = (width, height, className) => (
  <ProgressiveSkeleton width={width} height={height} className={className} enableMoveAnimation />
)

const Loading = () => (
  <>
    <SEO title="Loading..." />

    <Default>
      {renderSkeleton('100%', '32px', 'd-block tw-mt-0')}

      <div className="media">
        <div className="media-left">{renderSkeleton('250px', '250px', 'd-block tw-mt-0 tw-mr-6')}</div>
        <div className="media-body">
          {['80%', '60%', '40%'].map((v, i) => (
            <React.Fragment key={i}>
              {renderSkeleton(v, '32px', 'd-block tw-mt-0 tw-mb-4')}
            </React.Fragment>
          ))}
        </div>
      </div>
    </Default>

    <Mobile>
      <div styleName="wrapper">
        {renderSkeleton('100%', '250px', 'd-block tw-mt-0 tw-mb-4')}

        {['80%', '60%', '40%'].map((v, i) => (
          <React.Fragment key={i}>{renderSkeleton(v, '32px', 'd-block tw-mt-0 tw-mb-4')}</React.Fragment>
        ))}
      </div>
    </Mobile>
  </>
)

export default Loading
