import React from 'react'
import { FormattedMessage } from 'react-intl'
import './styles.css'
import { openLoginModal } from '@hooks/useLoginModal';

const Username = ({ username }: { username: string }) => (
  <div styleName="displayName">
    {username}
    <div>
      <FormattedMessage id="general.sign_up_wechat1" />
      <span styleName="click" onClick={openLoginModal}>
        <FormattedMessage id="general.sign_in" />
      </span>
      <FormattedMessage id="general.sign_up_wechat2" />
    </div>
  </div>
)

export default Username
