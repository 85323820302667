import SortAndFilter from '@components/common/SortAndFilter'
import SortAndFilterMenu from './SortAndFilterMenu'
import Search from './SortAndFilterMenu/Search'
import './styles.css'

export const ReviewFilter = props => (
  <SortAndFilter
    className="px-4 tw-py-4"
    renderMenu={SortAndFilterMenu}
    menuProps={props.menuProps}
    renderLeft={<Search submitOnEnter={true} />}
    menuId="discover-sidebar"
    styleName="bar"
  >
    Filter
  </SortAndFilter>
)
