import { FecthOpt, FetchRespond, ProductData, ReviewData, UserData } from '@interfaces/reviews'
import { concat, findBy } from '@utils/array'
import { uniqBy } from 'lodash'

export interface DataProps {
  reviews: ReviewData[]
  users?: UserData[]
  products?: ProductData[]
}

export interface OptProps extends FecthOpt {
  reviewId?: number
}

const getUniq = (arry: any[]) => uniqBy(arry, 'id')

export const updateData = (data: DataProps, res: FetchRespond) => ({
  products: getUniq([...data.products, ...res.data.products]),
  reviews: getUniq([...data.reviews, ...res.data.reviews]),
  users: getUniq([...data.users, ...res.data.users]),
})

export const updateReviews = (reviews: ReviewData[], newReview: ReviewData) => {
  const [, currentReviewIndex] = findBy(reviews, 'id', newReview.id)
  const reviewList = concat(reviews, newReview, currentReviewIndex)
  return reviewList
}

export const updateUsers = (users: UserData[], newUser?: UserData) => {
  if (newUser && users.find(u => u.id === newUser.id)) {
    return users
  }
  return [...users, ...(newUser ? [newUser] : [])]
}

export const updateVote = (review: ReviewData, vote: boolean) => {
  const voteData =
    review.downvoted && vote
      ? {
          upvoted: true,
          downvoted: false,
          upvotesCount: review.upvotesCount + 1,
          downvotesCount: review.downvotesCount - 1,
        }
      : review.upvoted && !vote
        ? {
            upvoted: false,
            downvoted: true,
            upvotesCount: review.upvotesCount - 1,
            downvotesCount: review.downvotesCount + 1,
          }
        : {
            upvoted: vote === true,
            downvoted: vote === false,
            upvotesCount: vote === true ? review.upvotesCount + 1 : review.upvotesCount,
            downvotesCount: vote === false ? review.downvotesCount + 1 : review.downvotesCount,
          }

  const newReview = { ...review, ...voteData }
  return newReview
}

export const updateVotes = (reviews: ReviewData[], id: number, vote: boolean) => {
  const [review, currentReviewIndex] = findBy(reviews, 'id', id)

  if (!review) {
    return reviews
  }

  const newReview = updateVote(review, vote)
  const reviewList = concat(reviews, newReview, currentReviewIndex)
  return reviewList
}
