import {formatDate} from '@utils/dateFormat'
import React, {FC, useEffect, useRef, useState} from "react";
import {Media} from 'react-bootstrap'

import {Link} from 'react-router-dom'
import Answer from './Answer'
import './styles.css'

import {Question} from '@interfaces/question'
import {CommonUser} from '@interfaces/userProfile'
import {cloudObjectToURL} from '@utils/'
import {useShowText} from '@hooks/useShowText'

interface Props extends Question {
  user: CommonUser
}

const MAX_ROWS = 10;

const QuestionUnit: FC<Props> = ({user, ...props}) => {
  const {handleToggle, showMore, expandToggle, ref} = useShowText(MAX_ROWS)

  return (
    <Media className="qa-wrapper__question" key={props.id}>
      <Media.Left className="tw-pr-0">
        <Link to={`/users/${user.username}/reviews`}>
          <img
            styleName="image"
            className="obj-fit-cover"
            width={75}
            height={75}
            src={
              cloudObjectToURL(user.avatar && user.avatar, {size: '75x75'}) ||
              'https://res.cloudinary.com/baopals/image/upload/f_auto/v1529644971/Baopals_profilepic.jpg'
            }
            alt={user.username}
          />
          <div className="tw-mt-2 fs-12 bold text-center black-link" styleName="username">
            {user.displayName}
          </div>
        </Link>
      </Media.Left>

      <Media.Body className="pl-3">
        <div className="qa-wrapper__question__header">{formatDate(props.askedAt)}</div>
        <p
          className={`qa-wrapper__question__body ${expandToggle ? '' : `line-max line-max-${MAX_ROWS}`}`}
          styleName="title"
          ref={ref}
        >
          {props.title}
        </p>
        {showMore && (
          <a href="#" onClick={handleToggle} className="d-block tw-mt-2">
            {expandToggle ? 'Collapse' : 'Read More'}
          </a>
        )}
      </Media.Body>

      {props.answer && (
        <Answer answer={props.answer} qaImages={props.pictures} answeredAt={props.answeredAt} />
      )}
    </Media>
  )
}

export default QuestionUnit
