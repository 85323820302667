import React, { FC } from "react";
import { useCountries } from '@hooks/useCountries'
import { Link } from 'react-router-dom'
import { formatFromNowDate } from '@utils/dateFormat'
import Slider from 'react-slick'
import ProductLink from '@components/common/ProductLink'
import { cloudObjectToURL } from '@utils/cloudStorage'
import DottedLine from '@components/common/DottedLine'
import PriceWithCurrency from '@components/common/PriceWithCurrency'
import { differenceInDays, parseISO } from 'date-fns'
import './styles.css'

interface Props {
  parcel?: any
}

const ParcelInProducts: FC<Props> = ({ parcel }) => {
  if (!parcel) return null

  const slider = React.useRef(null)

  const preventClickOnDragging = (e: any) => {
    if (slider.current.innerSlider.state.animating) {
      e.preventDefault()
      return
    }
  }

  const arrow = (side: string) =>
    <div>
      <i className={`icon-arrow-${side}`} />
    </div>

  const sliderSettings = {
    className: "responsive-slider",
    infinite: false,
    slidesToShow: 2,
    slidesToScroll: 2,
    nextArrow: arrow('right'),
    prevArrow: arrow('left'),
  }

  const dayDiff = differenceInDays(parseISO(parcel.receivedAt), parseISO(parcel.paidAt)) + 1

const { countries } = useCountries();

  return (
    <div styleName="wrapper">
      <div className="d-flex justify-content-between">
        <div className="fs14 bold">
          Global Parcel
        </div>

        <div className="fs12">
          Delivered {formatFromNowDate(parcel.receivedAt)}
        </div>
      </div>

      <div styleName="slider">
        <Slider {...sliderSettings} ref={slider}>
          {parcel.items.map((item, index) => (
            <ProductLink
              id={item.productId}
              platform={item.image?.provider || 'taobao'}
              className="black-link text-center d-block"
              onClick={preventClickOnDragging}
              key={index}
            >
              <div styleName='product' className="bd-light" style={{ backgroundImage: `url(${cloudObjectToURL(item.image, { size: '300x300' })})` }} />
            </ProductLink>
          ))}
        </Slider>
      </div>

      <DottedLine className="tw-my-4" />
      <div className='d-flex justify-content-between fs12'>
        <div>
          Parcel Details: <span className="bold">{parcel.items.length} {parcel.items.length > 1 ? 'items' : 'item'} | {parcel.weight} kg</span>
        </div>
        <div>
          Shipping Fee: <span className="bold"> <PriceWithCurrency price={parcel.shippingFee} /></span>
        </div>
      </div>
      <div className='d-flex justify-content-between tw-mt-2 fs12'>
        <div>
          Destination: <span className="bold text-capitalize"> {parcel.zone.name}, {countries.find(c => c.abbr === parcel.zone.country)?.alias}</span>
        </div>
        <div>
          Speed:{' '}
          <span className="bold">{dayDiff} {dayDiff > 1 ? 'days' : 'day'}
            {/*parcel.zone.country === CountryCode.Australia && (
              <>
                {' '}
                <span className="text-uppercase">({parcel.zone.country}</span>
                {' '}
                <span className="text-capitalize">{parcel.zone.shippingMethod})</span>
              </>
            )*/}
          </span>
        </div>
      </div>
    </div>
  )
}

export default ParcelInProducts
