import SignupLink from '@components/common/SignupLink'
import React from 'react'
import {MenuItem, NavDropdown} from 'react-bootstrap'
import {FormattedMessage} from 'react-intl'
import {Link} from 'react-router-dom'
import '../styles.css'


interface GuestDropdownProps {
  onLogin: () => void
}

const GuestDropdown: React.FC<GuestDropdownProps> = props => {
  return (
    <NavDropdown
      eventKey={3}
      title={<FormattedMessage id="general.sign_in" />}
      id="basic-nav-dropdown-signin"
      noCaret={true}
      styleName="dropdown"
    >
      <MenuItem onSelect={props.onLogin}>
        <FormattedMessage id="general.sign_in" />
      </MenuItem>
      <SignupLink>
        {url => (
          <MenuItem componentClass={Link} href={url} to={url}>
            <FormattedMessage id="homepage.create_account" />
          </MenuItem>
        )}
      </SignupLink>
    </NavDropdown>
  )
}

export default GuestDropdown
