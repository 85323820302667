import React from "react"

export const Intro = (text: string, link?: string) => {
  return (
    <div>
      <div>{text}</div>
      {!!link ? (
        <a 
          href={link} 
          className="tw-mt-6 d-block" 
          target="_blank" 
          style={{
            position: "absolute",
            right: "120px",
            bottom: "20px",
          }}
        >
          Learn More
        </a>
      ) : (
        ''
      )}
    </div>
  )
}
