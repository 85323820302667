import ProgressiveSkeleton from '@components/common/ProgressiveSkeleton'
import React from 'react'
import './styles.css'

const ProductCardLoading = () => (
  <div>
    <ProgressiveSkeleton
      width="100%"
      height="0"
      className="tw-mt-0 d-block"
      styleName="image"
      enableMoveAnimation={true}
    />

    <ProgressiveSkeleton
      width="80%"
      height="14px"
      className="tw-my-2 d-block"
      enableMoveAnimation={true}
    />

    <ProgressiveSkeleton
      width="60%"
      height="14px"
      className="tw-mt-0 d-block"
      enableMoveAnimation={true}
    />
  </div>
)

export default ProductCardLoading
