import { DEFAULT_COUNTRY_CODE } from '../constants'
import { omit } from 'lodash'

export function parseLangToLocale(lang) {
  switch (lang) {
    case 'en':
      return 'en'

    case 'zh-CN':
      return 'zh'

    default:
      return 'en'
  }
}

export function parseLocaleToLang(locale) {
  switch (locale) {
    case 'en':
      return 'en'

    case 'zh':
      return 'zh-CN'

    default:
      return 'en'
  }
}

export function parseLocaleToKey(locale) {
  switch (locale) {
    case 'zh':
      return 'zhCn'

    default:
      return locale
  }
}

export const getGlobalShippingInfo = (countries) => {
  return countries.map(c => {
    return {
      iso: c.abbr,
      alias: c.alias,
      phone: c.phoneCode,
      postalCodeRegex: c.zipCodeRegex,
      phoneRegex: c.phoneRegex,
      validation: {
        msg: c.phoneValidationMsg,
        zipCode: c.zipCodeValidationMsg
      },
      defaultZone: c.defaultZoneId,
      flag: c.flagUrl,
      code: c.abbr,
      methods: c.shippingMethods.map(s => s.method).join('_'),
      name: c.alias
    }
  })
}

export const isGreaterChina = country => ['cn', 'hk', 'mo'].includes(country)

export function isSmallChina(country) {
  return ['hk', 'mo'].includes(country)
}

export function isSoutheastAsia(country) {
  return ['sg', 'my', 'vn'].includes(country)
}

export function isGreaterBayArea(country) {
  return ['hk', 'mo'].includes(country)
}

export function isWestCountry(country) {
  return ['au', 'us', 'ca'].includes(country)
}

export function isChina(country) {
  return country === DEFAULT_COUNTRY_CODE
}

export function isAUAndUS(country) {
  return ['au', 'us'].includes(country)
}

export function isUS(country) {
  return country === 'us'
}

export function isHK(country) {
  return country === 'hk'
}

export function isCA(country) {
  return country === 'ca'
}

export function isAU(country) {
  return country === 'au'
}

export function isUSCAAndHK(country) {
  return ['us', 'ca', 'hk'].includes(country)
}

export function isUSAndCA(country) {
  return ['us', 'ca'].includes(country)
}
