import * as ActionCreator from '@actions/cart'
import { NotFoundError } from '@errors/NotFoundError'
import { of } from 'rxjs'
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { Epic } from './index'

export const addItemToCart: Epic = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(ActionCreator.addItemToCart)),
    switchMap(({ payload }) =>
      api.addItemToCart(payload).pipe(
        map(data => ActionCreator.addItemToCartSuccess({ data })),
        catchError(err => of(ActionCreator.addItemToCartFailure(err)))
      )
    )
  )

export const fetchCart: Epic = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(ActionCreator.fetchCart)),
    switchMap(({ payload }) =>
      api.fetchCart(payload).pipe(
        map(
          ({
            shippingMethods,
            items,
            products,
            baseShipping,
            additionalShipping,
            minBaseWeight,
            dimensionFactor,
            salesTaxPercent,
          }) =>
            ActionCreator.fetchCartSuccess({
              products,
              items,
              baseShipping,
              additionalShipping,
              minBaseWeight,
              dimensionFactor,
              shippingMethods,
              salesTaxPercent,
            })
        ),
        catchError(err => of(ActionCreator.fetchCartFailure(err)))
      )
    )
  )

export const removeCartItem: Epic = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(ActionCreator.removeCartItem)),
    switchMap(({ payload }) =>
      api.removeCartItem(payload.itemId).pipe(
        map(() => ActionCreator.removeCartItemSuccess({ id: payload.itemId })),
        catchError(err => {
          if (err instanceof NotFoundError) {
            return of(ActionCreator.removeCartItemSuccess({ id: payload.itemId }))
          }
          return of(ActionCreator.removeCartItemFailure(new Error('error')))
        })
      )
    )
  )

export const updateCartItemQty: Epic = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(ActionCreator.updateCartItemQty)),
    switchMap(({ payload }) =>
      api.updateCartItemQty(payload.id, payload.qty, payload.opts).pipe(
        map(({ id, quantity }) => ActionCreator.updateCartItemQtySuccess({ id, qty: quantity })),
        catchError(err => of(ActionCreator.updateCartItemQtyFailure(err)))
      )
    )
  )
