import React from 'react'
import ReactDOM from 'react-dom'
import App from './components/App'
import { createRoot } from 'react-dom/client'
import { HelmetProvider } from 'react-helmet-async'
import configureStore, { history } from './store/configureStore'
import { Provider, intlReducer } from 'react-intl-redux'
import { ConnectedRouter } from 'connected-react-router'
import initialState from './reducers/initialState'
import TagManager from 'react-gtm-module'
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import './thirdparty'
import '../assets/js/wxlogin'

const store = configureStore(initialState)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      // suspense: true,
      retry: false,
      // refetchOnReconnect: false,
      // refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  },
})

const tagManagerArgs = {
  gtmId: 'GTM-P583648',
}

if (__IS_PROD__) {
  TagManager.initialize(tagManagerArgs)
}

function startApp() {
  const container = document.getElementById('app')
  const root = createRoot(container)

  root.render(
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <HelmetProvider>
            <App />
          </HelmetProvider>
        </ConnectedRouter>
      </Provider>
    </QueryClientProvider>
  )
}

if (global.Intl) {
  startApp()
} else {
  require.ensure(['intl', 'intl/locale-data/jsonp/en.js', 'intl/locale-data/jsonp/zh.js'], function(
    require
  ) {
    require('intl')
    require('intl/locale-data/jsonp/en')
    require('intl/locale-data/jsonp/zh')

    startApp()
  })
}
