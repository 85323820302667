import PropTypes from 'prop-types'
import { PinterestShareButton, TwitterShareButton, VKShareButton } from 'react-share'
import FacebookShareButton from '@components/common/FacebookShareButton'
import { NaverBlogButton } from 'react-social-kr'
import { recordProductShares } from '@services/product'
import WeChatShareButton from '../WeChatShareButton'
import './styles.css'
import { useAffiliateLink } from '@hooks/useAffiliate'
import Input from '@components/product/v4/ProductDetails/ShareProduct/MobileModal/Input'

const ShareButtonGroup = ({ url, title, pic, id, withUrl, platform }) => {
  const handleClick = provider => recordProductShares(id, provider, platform).subscribe(() => {})

  return (
    <div className="action-container__share">
      <ul className="list-inline tw-m-0 tw-p-0 text-left" styleName="group">
        <li title="Share by Facebook" onClick={() => handleClick('facebook')}>
          <FacebookShareButton url={useAffiliateLink(url, 'facebook')} quote={title}>
            <i className="icon-facebook-share black-link" />
          </FacebookShareButton>
        </li>

        <li title="Share by Pinterest" onClick={() => handleClick('pinterest')}>
          <PinterestShareButton
            url={useAffiliateLink(url, 'pinterest')}
            media={pic}
            description={title}
          >
            <i className="icon-pinterest-share black-link" />
          </PinterestShareButton>
        </li>

        <li>
          <WeChatShareButton
            url={useAffiliateLink(url, 'wechat')}
            recordWechatShares={handleClick}
          />
        </li>

        <li title="Share by Twitter" onClick={() => handleClick('twitter')}>
          <TwitterShareButton url={useAffiliateLink(url, 'twitter')} title={title}>
            <i className="icon-twitter-share black-link" />
          </TwitterShareButton>
        </li>

        <li title="Share by Naver" onClick={() => handleClick('naver')}>
          <NaverBlogButton pathname={url.replace('https://www.baopals.com', '')}>
            <i className="icon-naver-share black-link" />
          </NaverBlogButton>
        </li>
      </ul>
      {withUrl && <Input value={url} hideText={true} shortenValue={true} />}
    </div>
  )
}

ShareButtonGroup.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  pic: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
}

export default ShareButtonGroup
