import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Panel, FormGroup, Checkbox } from 'react-bootstrap'
import { withRouter } from 'react-router'
import { Link } from 'react-router'
import ProgressiveText from '../../common/ProgressiveText'
import { FormattedMessage } from 'react-intl'
import ProgressiveSkeleton from '@components/common/ProgressiveSkeleton'
import './styles.css'
import TranslationText from '@components/common/TranslationText'

class BrandFilter extends Component {
  render() {
    const { items = [], selectedItems, onFilterChange } = this.props

    return (
      <ul styleName="filter-brand-items">
        {items.map(item => {
          return (
            <li
              key={item.id}
              data-id={item.id}
              styleName={
                selectedItems.indexOf(item.id) > -1
                  ? 'filter-brand-items-unit-active'
                  : 'filter-brand-items-unit'
              }
              title={item.name}
            >
              <a onClick={() => onFilterChange({ brand: item.id })} data-id={item.id}>
                {item.logo ? <img src={item.logo + '_100x100.jpg'} alt={item.name} /> : item.name}
              </a>
            </li>
          )
        })}
      </ul>
    )
  }
}

const PropFilter = ({ items = [], id, selectedItems, onFilterChange, ...props }) => {
  const currentProp = selectedItems.find(prop => prop.id == id)
  return (
    <ul styleName="filter-items" className="clearfix">
      {items.map((item, index) => {
        return (
          <li
            styleName={
              index > 5 && !props.showAll ? 'filter-items-unit-collapsed' : 'filter-items-unit'
            }
            key={+item.id}
            onClick={() => onFilterChange(item.id)}
            className={`search-filter__item ${
              currentProp && currentProp.value.indexOf(item.id) > -1 ? 'active' : ''
            }`}
          >
            {index > 5 && !props.showAll ? (
              <ProgressiveSkeleton width={25} height={8} />
            ) : (
              <TranslationText texts={{ zhCn: item.name }}>
                {(text, loading) =>
                  text ? (
                    <a title={text} data-translation={item.name} data-id={item.id}>
                      {text}
                    </a>
                  ) : (
                    loading
                  )
                }
              </TranslationText>
            )}
          </li>
        )
      })}
    </ul>
  )
}

class ProductFilter extends Component {
  constructor(...args) {
    super(...args)
    this.state = {
      open: false,
    }
  }

  handleToggle = () => {
    this.setState({ open: !this.state.open })
  }

  render() {
    const { type, title, items, selectedItems, onFilterChange, ...props } = this.props

    return (
      <div>
        {type === 'brand' && (
          <div styleName={this.state.open ? 'unit-toggle-expanded' : 'brand unit-toggle-collapsed'}>
            <BrandFilter {...{ items, selectedItems, onFilterChange }} />
          </div>
        )}
        {type !== 'brand' && (
          <div styleName="filter-inner">
            <div styleName="filter-detail">
              <div styleName={this.state.open ? 'unit-toggle-expanded' : 'unit-toggle-collapsed'}>
                {type === 'prop' && (
                  <PropFilter
                    {...{
                      items,
                      selectedItems,
                      onFilterChange,
                      showAll: this.state.open,
                      ...props,
                    }}
                  />
                )}
              </div>
            </div>
            {items &&
              items.length > 0 && (
                <aside styleName="filter-type">
                  <TranslationText texts={{ zhCn: title }}>
                    {(text, loading) => (text ? <strong>{text}</strong> : loading)}
                  </TranslationText>
                </aside>
              )}
          </div>
        )}

        <div styleName="footer">
          {(type === 'brand' ? items && items.length > 20 : items && items.length > 6) && (
            <a onClick={() => this.handleToggle()}>
              {this.state.open ? (
                <FormattedMessage id="search_category_page.show_less" />
              ) : (
                <FormattedMessage id="search_category_page.show_more" />
              )}{' '}
              <i
                className={`fa  ${this.state.open ? 'fa-chevron-up' : 'fa-chevron-down'}`}
                aria-hidden="true"
              />
            </a>
          )}
          <div className="dotted-line" styleName="dot" />
        </div>
      </div>
    )
  }
}

export default ProductFilter
