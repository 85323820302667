export const formatDecimal = (num: number, digits: number = 2) =>
  parseFloat(`${Math.round(num * 100) / 100}`).toFixed(digits)

export function round(num: number, precision = 2) {
  return +(Math.round(num + 'e+' + precision) + 'e-' + precision)
}

export const isNumeric = (n: string | number) => !isNaN(parseFloat(n)) && isFinite(n)

export const setRandomInterval = (min: number, max: number) => {
  let last
  if (min >= max) {
    throw new Error(
      'Selected interval [' + min + ', ' + max + ') does not work for random numbers.'
    )
  }
  return function() {
    let random
    do {
      random = Math.floor(Math.random() * (max - min)) + min
    } while (random === last)
    last = random
    return random
  }
}

export const fixedPrecision = (value: string) => {
  const dotIndex = value.indexOf('.')

  if (dotIndex === -1) {
    return `${value}.00`
  }

  const [v, precision] = value.split('.')

  if (precision.length === 1) {
    return `${value}0`
  } else if (precision.length > 2) {
    return value.substr(0, dotIndex) + value.substr(dotIndex, 3)
  }

  return value
}

export const decimalToCent = (val: string, precision: number = 2) => {
  return val.includes('.')
    ? `${+val.replace('.', '')}`
    : `${val}${new Array(precision).fill(0).join('')}`
}

export const toFixed = (num: number | string, precision: number = 2) => {
  const re = new RegExp('^-?\\d+(?:.\\d{0,' + (precision || -1) + '})?')
  return +num.toString().match(re)[0]
}

export const getRndInteger = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min

export const getRandPagination = (paginationNum: number[]) => {
  let current: number[] = []
  const rand = n => (Math.random() * n) | 0

  return () => {
    if (!current.length) {
      current = paginationNum.slice()
    }
    return current.splice(rand(current.length), 1)[0]
  }
}

export const ceilNearest = (value, nearest = 1.0) => {
  const wholeNumber = Math.ceil(value)
  const fraction = value - wholeNumber
  const factor = Math.ceil(fraction / nearest)
  return wholeNumber + nearest * factor
}

export const toLetters = (num: number) => {
  const mod = num % 26,
    pow = (num / 26) | 0,
    out = mod ? String.fromCharCode(64 + mod) : (--pow, 'Z')

  return pow ? toLetters(pow) + out : out
}

export const getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const formattedNumber = (number) => new Intl.NumberFormat('en-US').format(number);
