import { useNamedUrl } from '@hooks/useNamedUrl'
import { Platform } from '@interfaces/platform'
import { FC } from 'react'
import CategoryDetails from '../Category/CategoryDetails'

const Category: FC<{ platform: Platform }> = ({ platform }) => {
  const { name, loading } = useNamedUrl({ refetch: false })

  return loading ? null : <CategoryDetails platform={platform} alias={name} />
}

export default Category
