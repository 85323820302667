import CART_ACTION from '@constants/cart'
import ACTION from '@constants/paymentConfig'
import { Channel, PaymentMethods } from '@interfaces/payment'
import { LOCATION_CHANGE } from 'connected-react-router'
import qs from 'qs'
import { Reducer } from 'redux'
import { RootAction } from '../store/root-action'
import { DEFAULT_CURRENCY } from '@constants/'
import bpInitObject from '../constants/index'

export interface PaymentConfigState {
  readonly currency: string
  readonly rate: number
  readonly channels: Channel[]
  readonly methods: PaymentMethods[]
  readonly selectedPaymentMethod: PaymentMethods
  readonly symbols: any
  readonly currencyLocale: any
}

const CNY_PAYMENT_METHODS = [
  PaymentMethods.WechatPay,
  PaymentMethods.AliPay,
  PaymentMethods.UnionPay,
]

const MULTI_CURRENCY_PAYMENT_METHODS = [
  PaymentMethods.PayPal,
  PaymentMethods.VisaCard,
  PaymentMethods.MasterCard,
  PaymentMethods.Fps,
  PaymentMethods.AliPayHk
]

export const ALL_METHODS = [...CNY_PAYMENT_METHODS, ...MULTI_CURRENCY_PAYMENT_METHODS]

const initialState: PaymentConfigState = {
  channels: [],
  currency: DEFAULT_CURRENCY,
  rate: {
    [DEFAULT_CURRENCY]: 1,
  },
  methods: ALL_METHODS,
  selectedPaymentMethod: null,
  symbols: {},
  currencyLocale: {},
  // currency === Currency.CNY ? CNY_PAYMENT_METHODS[0] : NON_CNY_PAYMENT_METHODS,
}

export const paymentConfig: Reducer<PaymentConfigState, RootAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case LOCATION_CHANGE: {
      const { paymentMethod } = qs.parse(action.payload.location.search, {
        ignoreQueryPrefix: true,
      })

      const method = ALL_METHODS.find(m => m === paymentMethod)

      if (method) {
        return {
          ...state,
          selectedPaymentMethod: method,
        }
      }

      return state
    }

    case CART_ACTION.ADD_ITEM_TO_CART_SUCCESS:
    case CART_ACTION.FETCH_CART_SUCCESS: {
      return {
        ...state,
        selectedPaymentMethod: null,
      }
    }

    case ACTION.FETCH_PAYMENT_CONFIG_SUCCESS:
      bpInitObject.bpRate = action.payload.rate ?? 6
      Object.freeze(bpInitObject)

      return {
        ...state,
        rate: action.payload.rates,
        bpRate: action.payload.rate ?? 6,
        channels: action.payload.channels,
        symbols: action.payload.symbols,
        currencyLocale: action.payload.formatting,
      }

    case ACTION.UPDATE_SELECTED_PAYMENT_METHOD: {
      return {
        ...state,
        selectedPaymentMethod: action.payload.method,
        ...(action.payload.method !== PaymentMethods.BaopalsWallet && {
          currency: CNY_PAYMENT_METHODS.includes(action.payload.method)
            ? DEFAULT_CURRENCY
            : state.currency,
        }),
      }
    }

    case ACTION.UPDATE_DEFAULT_CURRENCY: {
      const selectedPaymentMethod = (() => {
        if (
          action.payload === DEFAULT_CURRENCY &&
          PaymentMethods.PayPal === state.selectedPaymentMethod
        ) {
          return CNY_PAYMENT_METHODS[0]
        } else if (
          action.payload !== DEFAULT_CURRENCY &&
          CNY_PAYMENT_METHODS.includes(state.selectedPaymentMethod)
        ) {
          return MULTI_CURRENCY_PAYMENT_METHODS[0]
        }
      })()

      const methods = (() => {
        if (action.payload !== DEFAULT_CURRENCY) {
          return ALL_METHODS.filter(
            m =>
              !CNY_PAYMENT_METHODS.includes(m)
          )
        } else if (action.payload === DEFAULT_CURRENCY) {
          return ALL_METHODS.filter(m => ![PaymentMethods.PayPal, PaymentMethods.Fps, PaymentMethods.AliPayHk].includes(m))
        }

        return ALL_METHODS
      })()

      return {
        ...state,
        methods,
        currency: action.payload,
        ...(selectedPaymentMethod && { selectedPaymentMethod }),
      }
    }

    default:
      return state
  }
}
