import qs from 'qs'
import { history } from '../store/configureStore'
import { parseLangToLocale } from '../utils/i18n'

const initialState = {
  intl: (() => {
    const langList = ['en', 'zh-CN']
    const {
      location: { search },
    } = history
    const query = qs.parse(search, { ignoreQueryPrefix: true })

    const lang = (() => {
      if (query.lang) {
        return langList.includes(query.lang) ? query.lang : 'en'
      } else {
        const langOnStorage = localStorage.getItem('preferredLanguage')
        return langList.includes(langOnStorage) ? langOnStorage : 'en'
      }
    })()

    const locale = parseLangToLocale(lang)
    const messages = require(`../../assets/i18n/${locale}.json`)

    localStorage.setItem('preferredLanguage', lang)

    return {
      defaultLocale: locale,
      locale,
      messages,
    }
  })(),
}

export default initialState
