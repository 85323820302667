import React from 'react'
import { FormattedMessage } from 'react-intl'
import BaoPalsFlag from '@components/common/BaoPalsFlag'
import './styles.css'

type RatingProps = {
  type: 'buy' | 'pass'
  actived?: boolean
  onSelect: () => void
}

const Rating: React.FC<RatingProps> = ({ type, ...props }) => (
  <div
    className="d-inline-block"
    styleName={props.actived ? `buy-pass-unit-disabled` : `buy-pass-unit`}
    onClick={props.onSelect}
  >
    <BaoPalsFlag type={type} width={40} height={40} selected={props.actived} />
    <div className="bold black-link" styleName={`buy-pass-val ${props.actived ? 'active' : ''}`}>
      <FormattedMessage id={type === 'buy' ? 'review_form.vote_good' : 'review_form.vote_bad'} />
    </div>
  </div>
)

Rating.defaultProps = {
  actived: false
}

export default Rating
