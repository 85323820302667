import React, { useEffect, useState } from 'react'
import { ReviewForm } from '@interfaces/reviews'
import TranslationText from '@components/common/TranslationText'
import { Collapse } from 'react-bootstrap'
import './styles.css'

interface SizePurchasedProps {
  sizeOptions: any[]
  onChange?: (form: ReviewForm) => void
  skuId: string
  className?: string
  clickable?: boolean
  error?: string
  selectedSizeOptionId: number
}

const SizePurchased: React.SFC<SizePurchasedProps> = ({
   sizeOptions, onChange, skuId, className, clickable, error
}) => {
  const [open, setOpen] = useState<boolean>(false)
  useEffect(() => {
    if(skuId && onChange) {
      onChange({ skuId })
    }
  }, [])
  const [sizeCategoryId, sizeOptionId] = (skuId || '').split(':')
  const topSizeOption = sizeOptions.find(s => s.id === +sizeOptionId)
  const restSizeOptions = sizeOptions.filter(s => s.id !== +sizeOptionId)

  const renderSizeOption = (option) => (
    <>
      {option && (
        <TranslationText texts={option.titles} key={option.id}>
          {text => {
            return(
              <li
                styleName='sku-unit'
                data-active={skuId === `${option.categoryId}:${option.id}`}
                data-clickable={clickable}
              >
                <div
                  styleName="sku-unit-item"
                  title={text}
                  data-clickable={clickable}
                  {...(clickable && {
                    onClick: () => onChange({ skuId: `${option.categoryId}:${option.id}` })
                  })}
                >
                  <span>{text}</span>
                </div>
              </li>
            )}}
        </TranslationText>
      )}
    </>
  )
  return (
    <>
      <div className={className}>Size Purchased</div>
      {clickable ? (
        <ul>
          {sizeOptions.map((option) => (
            renderSizeOption(option)
          ))}
        </ul>
      ) : (
        <>
          <ul>
            {renderSizeOption(topSizeOption)}

            {restSizeOptions.length > 0 && (
              <li onClick={() => setOpen(!open)} styleName='showOthers'>
                Show Other Size Options <i className={`icon-arrow-${open ? 'down' : 'right'} fs10`} />
              </li>
            )}
          </ul>
          <Collapse in={open}>
            <ul>
              {restSizeOptions.map((option) => (
                renderSizeOption(option)
              ))}
            </ul>
          </Collapse>
        </>
      )}

      {error && <div className="text-danger">{error}</div>}
    </>
  )
}

export default SizePurchased
