import React, { useEffect, useMemo } from 'react'
import TranslationText from '@components/common/TranslationText'
import { getOptions } from '@utils/product/skuCategoryUtils'
import SkuOption from '../Option'
import { formatSkuOptions, skuOptionToSkuId } from '@utils/product'
import './styles.css'

const SkuOptionCategory = ({ product, category, selected, onSelectOption, onUnselectOption }) => {
  const options = getOptions(category, product.skuOptions)
  const currentOption = selected.find(o => o.categoryId == options[0].categoryId)

  return (
    <div styleName="skus-container">
      <div styleName="title">
        <TranslationText texts={category.titles} />
      </div>

      {currentOption &&
        currentOption.thumbnail && (
          <div className="fs12 tw-mb-2 bp-orange-text">
            <TranslationText texts={currentOption.titles} />
          </div>
        )}

      <ul styleName="skus">
        {formatSkuOptions(category.id, options).map((option, index) => (
          <SkuOption
            key={index}
            option={option}
            skuCategoryId={category.id}
            selected={selected}
            onSelect={onSelectOption}
            onUnselect={onUnselectOption}
            product={product}
          />
        ))}
      </ul>
    </div>
  )
}

export default SkuOptionCategory
