import React from 'react'
import { Link } from 'react-router-dom'
import URL from '@constants/url'
import useAuth from '@hooks/useAuth'

const GetBaopalsProButton = () => {
  const { user } = useAuth()

  if(user?.subscription?.active) return null

  return (
    <Link to={URL.ACCOUNT_GET_BAOPALS_PRO} className="blue-link d-flex bold align-items-center">
      Get Baopals <div className="fs30 tw-mt-2 tw-ml-2"><i className="icon-pro" /></div>
    </Link>
  )
}

export default GetBaopalsProButton
