import { abort } from '@hooks/useReviews'
import React from 'react'
import Btn from './Btn'
import Widget from './Widget'
import { cloudObjectToURL } from '@utils/'
import { getOptions } from '@utils/product/skuCategoryUtils'
import { formatSkuOptions, skuToIds } from '@utils/product'
import { fetchProductReviewMeta } from '@services/product'

class Button extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      review: {
        ...props.review,
        ...(props.review.sku && { skuId: props.review.sku.id })
      },
    }
  }

  public componentWillReceiveProps(nextProps) {
    const curReview = this.props.review
    const nextReview = nextProps.review

    if (curReview.id !== nextReview.id) {
      this.setState({ review: nextReview })
    } else {
      if (curReview.title !== nextReview.title ||
          curReview.content !== nextReview.content ||
          curReview.rating !== nextProps.rating ||
          curReview.clothingFit !== nextReview.clothingFit ||
          curReview.sku !== nextReview.sku ||
          curReview.anonymous !== nextReview.anonymous ||
          curReview.pictures?.length !== nextReview.pictures?.length
         ) {
        this.setState({
          review: {
            ...this.state.review,
            title: nextReview.title,
            content: nextReview.content,
            clothingFit: nextReview.clothingFit,
            sku: nextReview.sku,
            ...(nextReview.sku && { skuId: nextReview.sku.id }),
            anonymous: nextReview.anonymous,
            rating: nextReview.rating,
            pictures: nextReview.pictures || []
          }
        })
      }
    }
  }

  public handleFormChange = form => {
    this.setState({ review: { ...this.state.review, ...form } })
  }

  public handleCancel = () => {
    this.setState({ open: false })
    abort()
  }

  public handleOpen = (id, platform) => {
    if(this.props.target === 'order') {
      this.props.setLoading(true)
       return fetchProductReviewMeta(id, platform).toPromise().then((res) => {
         this.props.setLoading(false)

         this.setState({
           productMeta: res,
           open: true
         })
       })
    }

    return this.setState({ open: true })
  }

  handleSubmit = () => {
    const { title, rating, content, anonymous, pictures, clothingFit, skuId } = this.state.review
    const isNewReview = typeof this.state.review.id === 'undefined'

    const form = {
      anonymous: !!anonymous,
      content: content,
      ...(clothingFit && { clothingFit }),
      ...(skuId && { skuId }),
      rating,
      title,
      pictures: pictures?.filter(p => p instanceof File) || [],
      deletedPictures: this.props.review.pictures?.filter(image => pictures.find(img => img.id === image.id || img.url?.includes(image.id)) === undefined)
        .map(p => p.id) || [],
    }

    const promise = isNewReview
      ? this.props.handleCreate(this.props.productId, form, this.props.platform)
      : this.props.handleUpdate(this.state.review.id, form)

      return promise.then(v => this.setState({ open: false }, () => this.props.onSubmitted?.(v)))
  }


  public render() {
    const { open, review, productMeta } = this.state
    const { product, selectedSkus } = this.props
    const newProduct = product || productMeta

    const skuIds = selectedSkus ? skuToIds(selectedSkus) : []
    const sizeCategory =  newProduct?.skuCategories?.find(c => c.titles.en?.includes('size') || c.titles.en === 'The reference height')
    const selectedSizeSku = skuIds.find(s => s[0] === sizeCategory?.id)
    const selectedSizeOptionId = selectedSizeSku && selectedSizeSku[1]
    const sizeOptions = sizeCategory ? getOptions(sizeCategory, newProduct.skuOptions) : []
    const formattedSizeOptions = formatSkuOptions(sizeCategory, sizeOptions)
    const selectedSkuId = selectedSizeOptionId && `${sizeCategory?.id}:${selectedSizeOptionId}`

    return (
      <>
        <Btn onClick={() => this.handleOpen(this.props.productId, this.props.platform)}>{this.props.children}</Btn>

        <Widget open={open} loading={this.props.loading} onCancel={this.handleCancel}
          review={{
            ...review,
            pictures: (review.pictures || []).map(
              p => (p.provider ? { url: cloudObjectToURL(p) } : p)
            ),
          }}
          onSubmit={this.handleSubmit}
          onFormChange={this.handleFormChange}
          categories={this.props.categories || productMeta?.categories || []}
          sizeOptions={formattedSizeOptions}
          selectedSkuId={selectedSkuId}
          hasSelected={!!selectedSkus}
        />
      </>
    )
  }
}

Button.defaultProps = {
 review: {},
 loading: false,
}

export default Button
