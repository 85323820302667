import React from 'react'
import './styles.css'

type LineThroughTextProps = {
  styleName?: string;
  className?: string;
  lineWidth?: number;
  linePadding?: number;
}

const LineThroughText: React.SFC<LineThroughTextProps> = props => {
  const style = {
    '--line-width': `${props.lineWidth}px`,
    '--line-padding': `-${props.lineWidth + props.linePadding}px`
  } as React.CSSProperties

  return (
    <div styleName='inner' style={style} className={props.className}>
      { props.children }
    </div>
  )
}

LineThroughText.defaultProps = {
  styleName: '',
  className: '',
  lineWidth: 15,
  linePadding: 10
}

export default LineThroughText
