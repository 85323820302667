import { fetchBonappAffiliatesTable } from '@services/affiliates'
import qs from 'qs'
import { useEffect, useState } from 'react'
import useAuth from '@hooks/useAuth'

const itemPerPage = 25

export const useBonappAffiliatesTable = (page: number) => {
  const [loading, setLoading] = useState(true)
  const [affiliates, setAffiliates] = useState([])
  const [totalItems, setTotalItems] = useState(1)

  useEffect(
    () => {
      setLoading(true)

      const sub = fetchBonappAffiliatesTable(page).subscribe(
        ({ data, meta }: any) => {
          if (data) {
            setLoading(false)
            setAffiliates(data.affiliates)
            setTotalItems(meta.total)
          }
        },
        err => console.warn(err)
      )

      return () => sub.unsubscribe()
    },
    [page]
  )

  return {
    loading,
    affiliates,
    meta: { totalItems, itemPerPage },
  }
}

export const useAffiliateLink = (url: string, via?: string) => {
  const { user } = useAuth()

  const u = new URL(url)

  const uri = `${u.origin}${u.pathname}?${qs.stringify({
      ...qs.parse(u.search),
      ...(via && { via }),
      ...(user?.affiliateToken && { at: user.affiliateToken }),
    })}`

  return uri
}
