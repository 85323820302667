import UrlQuery from '@components/common/UrlQuery'
import React, {FC} from 'react'
import {FormattedMessage} from 'react-intl'

interface SortUnitProps {
  name: string
  className?: string
  optional?: boolean

  active?: boolean
  onClick?: () => void
}

const SortUnit: FC<SortUnitProps> = ({name, className, optional, ...props}) => (
  <UrlQuery
    className={`tw-mx-6 ${className}`}
    names={['sort', 'page']}
    values={{sort: name}}
    optional={optional}
    {...props.onClick && {onClick: props.onClick}}
  >
    <>
      <div className="radio visible-xs">
        <label>
          <input type="radio" name="sort" checked={props.active} onChange={() => {}} />
          <FormattedMessage id={`search_category_page.sort_${name}`} />
        </label>
      </div>
      <div className="hidden-xs">
        <FormattedMessage id={`search_category_page.sort_${name}`} />
      </div>
    </>
  </UrlQuery>
)

SortUnit.defaultProps = {
  active: false,
  optional: false,
}

export default SortUnit
