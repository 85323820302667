import { useLocation } from '@hooks/useRouter'
import qs from 'qs'
import React, { FC, useEffect, useState } from 'react'
import { Subject } from 'rxjs'

interface InnerMenuProps {
  onClose: Subject<any>
}

const InnerMenu: FC<InnerMenuProps> = props => {
  const [url, setUrl] = useState<string>(`${location.pathname}${location.search}`)
  const [confirm, setConfirm] = useState<boolean>()
  const { navigate } = useLocation()

  useEffect(() => {
    const sub = props.onClose.subscribe(() => {
      setConfirm(true)
    })

    return () => sub.unsubscribe()
  }, [])

  const u = new URL(`${location.origin}${url}`)
  const query = qs.parse(u.search, { ignoreQueryPrefix: true })

  useEffect(
    () => {
      setUrl(`${location.pathname}${location.search}`)
    },
    [location.search]
  )

  useEffect(
    () => {
      if (confirm && url) {
        navigate(url)
        setConfirm(false)
      }
    },
    [confirm]
  )

  return <div className="p-4">{props.children({ url, query, onChange: setUrl })}</div>
}

export default InnerMenu
