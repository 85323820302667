import React, {FC} from 'react'
import {FormattedMessage} from 'react-intl'
import {Link} from 'react-router-dom'

import TranslationText from '@components/common/TranslationText'
import ProductWithSticker from '@components/home/ProductWithSticker'
import {CCC} from '@interfaces/content'
import {CommonProduct} from '@interfaces/product'

import ProductLink from '@components/common/ProductLink'
import {cloudObjectToURL} from '@utils/'
import './styles.css'

interface Props {
  ccc: CCC
  product: CommonProduct
}

const sticker = (
  <div className="upper bold fs16 tw-w-max bg-orange white-text tw-py-4 px-4" styleName="sticker">
    <FormattedMessage id="general.view_product" />
  </div>
)

const Product: FC<Props> = ({ccc, product}) => {
  const image = cloudObjectToURL(ccc.cover || product.cover, {size: '300x300'})

  return (
    <div className={`ccc__${ccc.target}`}>
      <div className="bd-light" styleName="wrapper">
        <ProductLink id={ccc.productId} platform={product.platformType}>
          <ProductWithSticker
            className="tw-mx-12"
            styleName="product"
            image={image}
            renderSticker={sticker}
          />

          <div className="tw-mx-12 tw-mt-4" styleName="body">
            <h4 className="bold upper" styleName="title">
              <TranslationText texts={product.titles} />
            </h4>

            <p className="black-text">{ccc.description}</p>
          </div>
        </ProductLink>
      </div>
    </div>
  )
}

export default Product
