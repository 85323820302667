import useAuth from '@hooks/useAuth'
import { useUserProfile } from '@hooks/useUserProfile'
import React from 'react'
import Body from './Body'

const Inner = ({ username, ...props }) => {
  const { user } = useAuth()

  const { loading, profile } = useUserProfile(username)

  return loading ? <div style={{ height: 300 }}>Loading...</div> : <Body profile={profile} />
}

export default Inner
