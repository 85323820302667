import { PaymentMethods } from '@interfaces/payment'
import { Titles } from './common'
import { Currency } from './currency'
import { Platform } from './platform'
import { SkuCategory, SKUGroup, SkuOption } from './product'

export interface ShopItem {
  sellerName?: string
  sellerId?: number

  shippingFee: number
  titles: Titles
  image: string

  platform: Platform
}

export interface PopularSeller {
  cn: string
  en: string
  productsCount: number
}

export interface FetchOrdersParam {
  page: number

  sq?: string

  from?: string
  to?: string
  archived?: boolean
  unreviewed?: boolean
  status?: string[]
}

export enum OrderStatus {
  UNPAID = 'void',
  PENDING = 'paying',
  PAID = 'paid',
  REFUNDING = 'refunding',
  REFUNDED = 'refunded',
  CANCELD = 'canceled',
  UNKNOWN = 'unknown',
  IN_WAREHOUSE = 'in_warehouse',
  IN_GLOBAL_PARCEL = 'in_global_parcel',
  EXPIRED = 'expired',
}

export enum OrderDateRangeFilter {
  ALL_TIME = 'ALL',
  LAST_30_DAYS = '30_days',
  LAST_3_MONTHS = '3_months',
  LAST_6_MONTHS = '6_months',
  YEAR_2016 = '2016',
  YEAR_2017 = '2017',
  YEAR_2018 = '2018',
}

export interface Item extends SKUGroup {
  id: number
  sellerName: string
  sellerId: number
  shopType: 'tmall' | 'taobao'
  titles: Titles
  price: number
  quantity: number
  reviewId?: any
  image: string
  productId: string
  sku?: string

  orderId?: number

  paidAt?: string
}

export interface Order {
  // server
  id: number
  number: string
  createdAt: string
  status: OrderStatus
  total: number
  currency: Currency
  currencyRate: string
  shipping: number
  items: Item[]
  country: string

  paymentMethod: PaymentMethods | undefined

  // coupon
  discount: number
  coupon: string

  // client
  archived: boolean
  unreviewed: boolean

  receiver: string
  phone: string
  address: string
}

export interface DeliveryInfo {
  address: string

  receiver: string
  phone: string
}

export enum OrderType {
  Order = 'orders',
  Bundle = 'bundles',
  Parcel = 'parcels',
  Wallet = 'wallet',
  AdditionalPayment = 'additional_payment',
}

export enum CargoType {
  SENSITIVE = 'sensitive',
  STANDARD = 'standard',
}
