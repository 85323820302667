import { useReviews } from '@hooks/useReviews'
import { ReviewTarget } from '@interfaces/reviews'
import React, { FC } from 'react'
import List from './List'

const Reviews = props => {
  const initOpt = {
    target: props.target,
  }

  const { loading, data, upvote, downvote, fetchRandomReview } = useReviews({
    ...initOpt,
  })

  return (
    <div ref={props.reviewsRef}>
      <List
        loading={loading}
        reviews={data.reviews}
        users={data.users}
        products={data.products}
        handleShuffle={() => fetchRandomReview(initOpt)}
        handleUpvote={upvote}
        handleDownvote={downvote}
      />
    </div>
  )
}

Reviews.defaultProps = {
  target: ReviewTarget.HOME,
}

export default Reviews
