import React, { Component } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import ConfirmModal from '@components/common/ConfirmModal'

class DeleteMyCollection extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showModal: false,
    }
  }

  closeModal = () => {
    return new Promise(resolve => {
      this.setState({ showModal: false }, resolve)
    })
  }

  openModal = () => {
    this.setState({ showModal: true })
  }

  handleDelete = async () => {
    await this.props.onDelete(this.props.id)
    this.closeModal()
  }

  render() {
    const { showModal } = this.state
    const { type, showText } = this.props

    const label = type ? type : 'wishlist'

    return (
      <span>
        <a className="black-link" onClick={this.openModal}>
          <i className="icon-delete text-danger" aria-hidden="true" />
          {showText && (
            <span className="tw-ml-2 text-danger">{this.props.children || 'DELETE WISHLIST'}</span>
          )}
        </a>
        <ConfirmModal open={showModal} onConfirm={this.handleDelete} onCancel={this.closeModal}>
          Are you sure you want to remove this {label}?
        </ConfirmModal>
      </span>
    )
  }
}

DeleteMyCollection.defaultProps = {
  showText: false,
}

DeleteMyCollection.propTypes = {
  showText: PropTypes.bool,
}

export default DeleteMyCollection
