import React from 'react'
import TranslationText from '@components/common/TranslationText'

import './styles.css'

const ProductPropertiesCollection = props => {
  const properties = props.properties.filter(({ value }) => !(value?.zhCn || '').includes('jd.com'))
  const firstEndIndex = Math.ceil(properties.length / 2)
  const otherStartIndex = firstEndIndex + 1

  if (properties.length > 15)
    return (
      <div styleName="gird">
        <div>{renderNoScore(properties.slice(0, firstEndIndex))}</div>

        <div>{renderNoScore(properties.slice(otherStartIndex, properties.length))}</div>
      </div>
    )

  return renderNoScore(properties)
}

const renderNoScore = properties =>
  properties.map((prop, index) => (
    <div key={index} styleName="noScore">
      <span className="bold text-capitalize">
        <TranslationText texts={prop.titles} />
      </span>
      : <TranslationText texts={prop.value} />
    </div>
  ))

ProductPropertiesCollection.defaultProps = {
  properties: [],
}

export default ProductPropertiesCollection
