import {CommonUser} from '@interfaces/userProfile'
import {cloudObjectToURL} from '@utils/'
import React, {FC} from 'react'
import './styles.css'

interface Props {
  users: CommonUser[]
  maxPIC?: number
  PICwidth?: number
}

const ImagesList: FC<Props> = ({users, PICwidth, maxPIC}) => {
  const maxUsers = Math.min(users.length, maxPIC)
  const limitedUsers = users.slice(0, maxUsers)

  return (
    <div className="relative tw-w-max mx-auto">
      {limitedUsers.map((user: CommonUser, index: number) => {
        const avatar = user.avatar
          ? cloudObjectToURL(user.avatar, {size: '30x30'})
          : BAOPALS.IMAGE_PLACEHOLDER

        const style = {
          width: PICwidth,
          zIndex: maxPIC - index,
          marginLeft: 7 * index,
        }

        return (
          <div key={user.displayName} styleName="avatar" style={style}>
            <div styleName="cover" style={{backgroundImage: `url("${avatar}")`}} />
          </div>
        )
      })}
    </div>
  )
}

ImagesList.defaultProps = {
  maxPIC: 3,
  PICwidth: 30,
}

export default ImagesList
