import addDays from 'date-fns/addDays'
import React, { FC, useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { RouteComponentProps } from 'react-router-dom'
import Masonry from 'react-masonry-css'

import { useReviews } from '@hooks/useReviews'
import { useQuery } from '@hooks/useRouter'

import { ImageWithDetails } from '@components/common/Loading'
import ReviewUnit from '@components/common/ReviewUnit'
import useAuth from '@hooks/useAuth'
import { usePrevious } from '@hooks/usePrevious'
import { Rating, RenderMethod, Sort } from '@interfaces/reviews'
import { ReviewTarget } from '@interfaces/reviews'
import './styles.css'

interface Props extends RouteComponentProps {
  query: any
  username?: string
  loadingText: string | React.ReactNode
  showByUser?: boolean
  enableEdit?: boolean
  showAnonymous?: boolean
  renderMethod: RenderMethod
}

const LOADING_PLACE_HOLDER = new Array(9).fill('').map(() => 'loading')

const shouldResetPage = (prev = {}, next = {}) => {
  return ['sort', 'pic_only', 'rating', 'q', 'date', 'category'].some(
    key => prev[key] !== next[key]
  )
}

const ReviewList: FC<Props> = props => {
  const query = useQuery()
  const { user } = useAuth()

  const prevQuery = usePrevious(query)

  const opt = getOpt(query, props.username)

  const [page, setPage] = useState<number>(1)

  const resetPage = shouldResetPage(prevQuery, query)

  useEffect(
    () => {
      if (resetPage) {
        setPage(1)
      }
    },
    [query]
  )

  const {
    loading,
    data: { reviews, users, products },
    meta,
    upvote,
    downvote,
    updateReview,
  } = useReviews({
    page,
    ...(resetPage && { page: 1 }),
    ...opt,
    target: props.target,
  })

  const renderLoading = () =>
    LOADING_PLACE_HOLDER.map((_, index) => (
      <ImageWithDetails key={index} picWidth={100} className="tw-mb-6" />
    ))

  if (loading && page === 1) {
    return props.renderMethod === RenderMethod.GRID ? (
      <div styleName="gridWrapper">
        <Masonry
          breakpointCols={{ default: 3, 1199: 2, 991: 1, 767: 2, 499: 1 }}
          className="masonry-grid"
          columnClassName="masonry-grid_column"
        >
          {renderLoading()}
        </Masonry>
      </div>
    ) : (
      <>{renderLoading()}</>
    )
  }

  if (reviews.length === 0) {
    return <div>{props.loadingText}</div>
  }

  const sortedReviews = props.showAnonymous ? reviews : reviews.filter(r => !r.anonymous)

  const loadMore = () => {
    if (loading) {
      return
    }

    setPage(page + 1)
  }

  const renderReviewUnit = () => {
    const data = loading ? sortedReviews.concat(LOADING_PLACE_HOLDER) : sortedReviews

    return data.map((review, index) => {
      if (review === 'loading') {
        return <ImageWithDetails key={index} picWidth={100} className="tw-mb-6" />
      }

      const currentUser = !!review.userId && users.find(u => u.id === review.userId)
      const product = !!review.productId && products.find(p => p.id === review.productId)

      return (
        <ReviewUnit
          className="tw-mb-6"
          styleName="wrapper"
          key={index}
          review={review}
          user={currentUser}
          product={product}
          handleUpvote={upvote}
          handleDownvote={downvote}
          showByUser={props.showByUser}
          showEdit={props.enableEdit && (currentUser.id === user.id || review.isOwner)}
          handleUpdate={updateReview}
        />
      )
    })
  }

  return (
    <InfiniteScroll
      pageStart={1}
      loadMore={loadMore}
      hasMore={page < meta.totalPages}
      initialLoad={false}
      useWindow={true}
      // loader={<ImageWithDetails picWidth={100} className="mb-4" />}
      threshold={0}
      className="tw-mt-6"
    >
      {props.renderMethod === RenderMethod.GRID ? (
        <div styleName="gridWrapper">
          <Masonry
            breakpointCols={{ default: 3, 1199: 2, 991: 1, 767: 2, 499: 1 }}
            className="masonry-grid"
            columnClassName="masonry-grid_column"
          >
            {renderReviewUnit()}
          </Masonry>
        </div>
      ) : (
        <>{renderReviewUnit()}</>
      )}
    </InfiniteScroll>
  )
}

ReviewList.defaultProps = {
  target: ReviewTarget.DISCOVER,
  showByUser: false,
  enableEdit: false,
  showAnonymous: false,
}

export default ReviewList

const getDateString = (date: Date) => date.toISOString().substring(0, 10)

const getOpt = (query: any, userName?: string) => {
  const today = new Date()
  const lastWeek = addDays(today, -7)
  const lastMonth = addDays(today, -30)

  const opt = {
    ...(userName ? { username: userName } : {}),
    ...(query.sort === 'most_upvoted' ? { sort: Sort.UPVOTED } : {}),
    ...(query.with_images === 'true' || +query.pic_only === 1 ? { withPictures: true } : {}),
    ...(query.rating === 'true' ? { rating: Rating.BUY } : {}),
    ...(query.rating === 'false' ? { rating: Rating.PASS } : {}),
    ...(query.q && query.q !== '' ? { sq: query.q } : {}),
    ...(query.date === 'last_week'
      ? { createdAfter: getDateString(lastWeek), sort: Sort.UPVOTED }
      : {}),
    ...(query.date === 'last_30_days'
      ? { createdAfter: getDateString(lastMonth), sort: Sort.UPVOTED }
      : {}),
    ...(query.date === 'all' ? { sort: Sort.UPVOTED } : {}),
    ...(query.category && { category: query.category }),
    ...(query.anonymous && { anonymous: query.anonymous }),
  }

  return opt
}
