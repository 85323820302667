import UrlQuery from '@components/common/UrlQuery'
import {useQuery} from '@hooks/useRouter'
import React from 'react'

const Price = () => {
  const {sort} = useQuery()

  const isAsc = sort === 'price_asc'
  const isDesc = sort === 'price_desc'

  return (
    <UrlQuery
      names={['sort', 'page']}
      values={{sort: `price_${isAsc ? 'desc' : 'asc'}`}}
      className={`tw-mx-6 ${isAsc || isDesc ? 'link-active' : ''}`}
    >
      price
      <i className={`fs10 tw-ml-2 icon-sort-${isDesc ? 'up' : 'down'}`} />
    </UrlQuery>
  )
}

export default Price
