import { ImageWithDetails } from '@components/common/Loading'
import DottedLine from '@components/common/DottedLine'
import { useInView } from 'react-intersection-observer'
import { useReviews } from '@hooks/useReviews'
import { Platform } from '@interfaces/platform'
import { ReviewTarget } from '@interfaces/reviews'
import React, { FC, useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import ReviewWrapper from '../ReviewWrapper'
import TaoBaoReviews from '../TaobaoReviews'
import Empty from '../Empty'

interface DefaultProps {
  id: number
  platform: Platform
}

const ProductReviews: FC<DefaultProps> = props => {
  const { ref, inView } = useInView()
  const [page, setPage] = useState<number>(1)

  const [enableTaobaoReviews, setEnableTaobaoReviews] = useState(false)

  useEffect(
    () => {
      if (inView) {
        setEnableTaobaoReviews(true)
      }
    },
    [inView]
  )

  const opt = {
    // productId: props.id,
    productId: props.reviewId,
    page,
    target: ReviewTarget.PRODUCT,
  }

  const { loading, data, meta, upvote, downvote, createReview, updateReview } = useReviews(opt)

  if (loading && page === 1) {
    return (
      <>
        {[0, 1, 2].map((_, index) => (
          <ImageWithDetails key={index} className="tw-mb-8" picWidth={100} noBorder={true} />
        ))}
      </>
    )
  }

  const loadMore = () => {
    if (loading) {
      return
    }

    setPage(page + 1)
  }

  return (
    <>
      <InfiniteScroll
        pageStart={1}
        loadMore={loadMore}
        hasMore={page < meta.totalPages}
        initialLoad={false}
        useWindow={true}
      // loader={<ImageWithDetails key={0} picWidth={100} className="mb-4" />}
      // threshold={100}
      >
        <ReviewWrapper
          productId={props.id}
          loading={loading}
          platform={props.platform}
          {...data}
          handleUpvote={upvote}
          handleDownvote={downvote}
          handleCreate={(id, form) => createReview(id, form, props.platform)}
          handleUpdate={updateReview}
          buyCount={meta.buyCount}
          totalCount={meta.totalCount}
          categories={props.categories}
          product={props.product}
        />

        {loading && page > 1 && <ImageWithDetails key={0} picWidth={100} className="tw-mb-6" />}
      </InfiniteScroll>

      <div ref={ref} />

      {!loading &&
        meta.totalPages < 2 &&
        props.platform !== Platform.JD &&
        enableTaobaoReviews && (
          <TaoBaoReviews id={props.id} platform={props.platform}>
            {meta.totalCount > 0 && <DottedLine className="tw-my-5" />}
          </TaoBaoReviews>
        )}
      {meta.totalCount === 0 && <Empty />}
    </>
  )
}

export default ProductReviews
