import ProgressiveSkeleton from '@components/common/ProgressiveSkeleton'
import React from 'react'
import {NavLink} from 'react-router-dom'
import {showPath} from '../../../../../../routing/department/linkRoutes'
import {useQuery} from '@hooks/useRouter'
import './styles.css'

const normalizeTitle = (link: any) => link.title.replace(/^\[\d+\]/, '')

const Link: React.SFC<any> = props => {
  const {link} = props
  const query = useQuery()

  return (
    <li styleName="link" className="tw-mb-4 text-truncate" title={link.title}>
      {props.loading ? (
        <ProgressiveSkeleton enableMoveAnimation={true} height={20} width="80%" />
      ) : (
        <NavLink
          className="black-link relative d-inline-block pull-left text-truncate text-capitalize"
          to={showPath(link, {slug: query.department})}
          isActive={() => link.active}
        >
          {normalizeTitle(link)}
        </NavLink>
      )}
    </li>
  )
}

export default Link
