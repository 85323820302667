import LazyLoadImage from '@components/common/LazyLoadImage'
import { cloudObjectToURL } from '@utils/'
import React from 'react'
import './styles.css'
import { DEFAULT_PRODUCT_PLACEHOLDER } from "@constants/index";

interface GalleryProps {
  selectedImage: any
  thumbnails: any
  onSelectImage: any
}

const Gallery: React.SFC<GalleryProps> = ({
  selectedImage,
  thumbnails,
  onSelectImage,
  ...props
}) => {
  const selectedIndex = thumbnails.findIndex(
    (t: any) => t.id === (selectedImage && selectedImage.id)
  )
  const selectedPic = cloudObjectToURL(selectedImage, { size: '400x400' }) || DEFAULT_PRODUCT_PLACEHOLDER

  const main = (
    <div className="bd-light">
      <LazyLoadImage square={true} image={selectedPic} />
    </div>
  )

  const thumbnail = (
    <>
      {thumbnails.length > 0 && (
        <div
          styleName="wrapper"
          data-index={selectedIndex}
          data-number={thumbnails.length}
          className="product-gallery-thumbnails"
        >
          <div styleName="thumbnail-container">
            {thumbnails.map((thumbnail: any, index: number) => (
              <ThumbnailImage
                key={thumbnail.id}
                thumbnail={thumbnail}
                isActive={selectedIndex === index}
                setSelected={onSelectImage}
              />
            ))}
          </div>
        </div>
      )}
    </>
  )

  return (
    <div onClick={e => e.preventDefault()}>
      {typeof props.children === 'function' ? (
        props.children(main, thumbnail)
      ) : (
        <>
          {main}
          {thumbnail}
        </>
      )}
    </div>
  )
}

const ThumbnailImage = ({ setSelected, isActive, thumbnail }: any) => {
  const onClick = () => setSelected(thumbnail)
  const pic = cloudObjectToURL(thumbnail, { size: '80x80' }) || DEFAULT_PRODUCT_PLACEHOLDER

  return (
    <div styleName="thumbnail">
      <div
        styleName={`image ${isActive ? 'active' : ''}`}
        style={{ backgroundImage: `url("${pic}")` }}
        onClick={onClick}
      />
    </div>
  )
}

export default Gallery
