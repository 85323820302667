import DottedLine from '@components/common/DottedLine'
import DashboardNums from '@components/common/ProfileDetails/DashboardNums'
import { UserProfile } from '@interfaces/userProfile'
import { cloudObjectToURL } from '@utils/'
import React from 'react'
import UserCard from './UserCard'

interface BodyProps {
  profile: UserProfile
}

const Body: React.SFC<BodyProps> = ({ profile, ...props }) => {
  return (
    <>
      <UserCard
        avatar={cloudObjectToURL(profile.avatar, { crop: 'fill', size: '150x150' }) || undefined}
        username={profile.username}
        birthday={profile.birthdate}
        displayName={profile.displayName}
        nationality={profile.nationality}
        city={profile.locationCity}
        fortunePoints={profile.fortunePoints}
      />

      <DottedLine className="tw-mt-4" />

      <DashboardNums
        username={profile.username}
        review={profile.reviewsCount}
        collections={profile.collectionsCount}
        sellers={profile.sellersCount}
      />

      <DottedLine className="tw-mb-4" />
    </>
  )
}

export default Body
