import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.pathnameOnly && this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0)
    }

    if (!this.props.pathnameOnly && this.props.location !== prevProps.location) {
      if (this.props.location.pathname !== prevProps.location.pathname && typeof this.props.onBeforeReset === 'function') {
        const result = this.props.onBeforeReset()
        if (!result) {
          return false
        }
      }

      window.scrollTo(0, 0)
    }
  }

  render() {
    return this.props.children
  }
}

ScrollToTop.defaultProps = {
  pathnameOnly: false,
}

export default withRouter(ScrollToTop)
