import {useMatchPath} from '@hooks/useRouter'
import React from 'react'
import ChinaOnly from '../ChinaOnly'
import MobileMenuToggler from './MobileMenuToggler'
import MobileNavItem from './MobileNavItem'
import NotificationNavItem from './NotificationNavItem'
import OrderNavItem from './OrderNavItem'
import ShoppingCartNavItem from './ShoppingCartNavItem'
import './styles.css'

interface MobileNavBarProps {}

const MobileNavBar: React.SFC<MobileNavBarProps> = props => {
  return (
    <nav
      id="mobileNav"
      styleName="wrapper"
      className="bd-t-light justify-content-between tw-w-full tw-py-4 px-4"
      data-ig={useMatchPath('/ig')}
    >
      <MobileMenuToggler />

      {/*<ChinaOnly*/}
      {/*otherwiseRender={*/}
      {/*<MobileNavItem icon="hot" link="/whatshot" active={useMatchPath('/whatshot')} />*/}
      {/*}*/}
      {/*>*/}
      {/*<MobileNavItem icon="hot" link="/discover/whatshot" active={useMatchPath('/discover')} />*/}
      {/*</ChinaOnly>*/}

      <MobileNavItem icon="hot" link="/discover/whatshot" active={useMatchPath('/discover')} />

      <NotificationNavItem active={useMatchPath('/notifications')} />
      <OrderNavItem active={useMatchPath('/account/orders')} />
      {/* Shopping Cart */}
      <ShoppingCartNavItem active={useMatchPath('/checkout/baopals')} />
    </nav>
  )
}

export default MobileNavBar
