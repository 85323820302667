import { AVAILABLE_STOCK_STATE, Platform } from '@interfaces/platform'
import { platformToProvider } from '@utils/platform'
import { isValidAffiliateVia } from '@utils/reward'
import { map, pluck, tap } from 'rxjs/operators'
import ajax from '../api/ajax'

const formatCartItems = ({ shipping, products, items, stockState }) => {
  return {
    products: products.map(p => ({
      ...p,
      seller: p.sellerId,
      platform: p.shopType,
    })),
    items: items.map(i => {
      const stock = (stockState || []).find(
        s => s.productId === i.skuId || s.productId === i.productId
      )

      const product = products.find(p => [i.skuId, i.productId].includes(p.id));

      return {
        ...i,
        ...(i.target === Platform.JD && { stock: i.price < 0 ? 0 : 999 }),
        ...(stock && !AVAILABLE_STOCK_STATE.includes(stock.stockState) && { stock: 0, unavailable: 'stock' }),
        ...(!!(product?.unavailable) && { stock: 0, unavailable: product.unavailable || 'location' }),
        ...(shipping?.unavailable === true && { stock: 0, unavailable: 'location_ban' }),
      }
    }),
    baseShipping: (shipping?.values?.base || 0) / 100,
    additionalShipping: (shipping?.values?.additional || 0) / 100,
    dimensionFactor: (shipping?.values?.dimensionsWeight || 0)/ 100,
    minBaseWeight: (shipping?.values?.minWeight || 0) / 100
  }
}

export const addItemToCart = ({ platform, ...payload }: any) =>
  ajax({
    url: '/cart/items',
    method: 'POST',
    body: {
      cartItem: {
        ...payload,
        provider: platformToProvider(platform),
      },
    },
  }).pipe(
    pluck('response', 'data'),
    map(({ shipping, item, product, ...rest }) => ({
      ...rest,
      ...formatCartItems({ shipping, products: [product], items: [item] }),
    })),
    map(({ items, products, ...rest }) => ({
      ...rest,
      item: items[0],
      product: products[0],
    }))
  )

export const fetchCart = ({ zone, country }: {zone: number, country: string}) =>
  ajax({
    url: '/cart/items',
    query: {
      shippingZoneId: zone,
      country,
    },
  }).pipe(
    pluck('response', 'data'),
    map(({ shipping, items, products, stockState, overseasFormulas, ...rest }) => ({
      ...rest,
      shippingMethods: (overseasFormulas?.deliveries ?? []).map(d => d.shippingMethod),
      ...formatCartItems({ shipping, products, items, stockState }),
    }))
  )

export const removeCartItem = (id: number) =>
  ajax({
    url: `/cart/items/${id}`,
    method: 'DELETE',
  })

export const updateCartItemQty = (
  id: number,
  qty: number,
  opts: {
    affiliate?: string
    via?: string
    affiliateSId?: string
  } = {}
) =>
  ajax({
    url: `/cart/items/${id}/quantity`,
    method: 'PATCH',
    body: {
      cartItem: {
        quantity: qty,
        ...(opts.affiliate && { affiliate_token: opts.affiliate }),
        ...(opts.affiliateSId && { affiliate_shareable_id: opts.affiliateSId }),
        ...(isValidAffiliateVia(opts.via) && { affiliate_via: opts.via }),
      },
    },
  }).pipe(pluck('response', 'data'))
