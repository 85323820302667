import React from 'react'
import { useTrendingKeywords } from '@hooks/useTrending'
import { Link } from 'react-router-dom'
import { showPath } from '@routing/department/linkRoutes'
import { PageSection } from './PageSection'

const TrendingSearches = () => {
  const { keywords } = useTrendingKeywords()

  return keywords.length > 0 ? (
    <PageSection heading="TRENDING SEARCHES">
      <div className="tw-flex tw-flex-wrap tw-gap-4 tw-pb-3 tw-pt-2 tw-px-4">
        {keywords.map((k, i) => (
          <Link
            to={showPath(k)}
            key={i}
            className="black-link tw-rounded-full bd-light bg-grey tw-px-4 tw-py-2"
          >
            {k.title}
          </Link>
        ))}
      </div>
    </PageSection>
  ) : null
}

export default TrendingSearches
