import BuyNowBtn from '@components/product/v4/ProductDetails/ButtonGroup/BuyNowBtn'
import {useProductAction, useProductUnavailability} from '@hooks/useProduct'
import {Platform} from '@interfaces/platform'
import {FC} from 'react'
import {FormattedMessage} from 'react-intl'
import Button from './Button'
import './styles.css'
import { useAddress } from '@hooks/useAddress'
import { isGreaterChina } from '@utils/i18n'
import { DEFAULT_COUNTRY_CODE } from '@constants/index'
import { useWarehouseItems } from '@hooks/useWarehouseItems'

interface ButtonGroupProps {
  id: number
  platform: Platform
  supermarket: boolean
}

const ButtonGroup: FC<ButtonGroupProps> = props => {
  const {onAddToCart} = useProductAction(props.id)
  const { defaultAddress } = useAddress()

  const _isGreatChina = isGreaterChina(defaultAddress?.country || DEFAULT_COUNTRY_CODE)
  const { items, pendingItems } = useWarehouseItems()

  const unavailable = !!useProductUnavailability(props.id)

  const rest = {
    disabled: unavailable || (!_isGreatChina && (items.length === 0 && pendingItems.length === 0)),
    errorTransition: 2000,
  }

  return (
    <div styleName="btn-group" className={`my-md-4 product-btn-group ${props.className}`}>
      <BuyNowBtn
        platform={props.platform}
        supermarket={props.supermarket}
        onClick={onAddToCart}
        {...rest}
      />
      <div className="tw-mx-2 hidden-xs" />
      <Button onClick={() => onAddToCart().then(() => setTimeout(props?.onAddedToCart, 850))} className="btn-block btn-bp" {...rest}>
        <i className="icon-cart fs16" /> {' '}
        <FormattedMessage id="product_page.add_to_cart" defaultMessage="ADD TO CART" />
      </Button>
    </div>
  )
}

ButtonGroup.defaultProps = {
  className: '',
}

export default ButtonGroup
