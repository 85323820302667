import ProgressiveSkeleton from '@components/common/ProgressiveSkeleton'
import {Default, Mobile} from '@components/common/Responsive'
import {useWindowWidth} from '@hooks/useWindowWidth'
import {sortBy} from 'lodash'
import React from 'react'
import {Collapse, Fade} from 'react-bootstrap'
import Arrow from '../Arrow'
import CategoryLinks from '../CategoryLinks'
import Toggler from './Toggler'

import {cloudObjectToURL} from '@utils/'
import './styles.css'

const Slide: React.SFC<any> = props => {
  const {links, category, loading} = props

  const [toggle, setToggle] = React.useState(true)

  const ref = React.useRef(null)

  return (
    <div styleName="inner" className="relative">
      {props.prevCategory &&
        props.prevCategory.title && (
          <Arrow
            loading={loading}
            direction="left"
            text={props.prevCategory.title}
            bgPic={cloudObjectToURL(props.prevCategory.cover)}
            onClick={props.onPrev}
          />
        )}

      <div styleName="wrapper" className="py-4">
        <div className="text-center strong text-uppercase tw-mb-6 fs20" styleName="heading">
          {loading ? <ProgressiveSkeleton width="60%" height={20} /> : category.title}
        </div>

        <div styleName="collapse" data-collapse={toggle} ref={ref}>
          <div>
            <CategoryLinks loading={loading} links={links} />
          </div>
        </div>
      </div>

      {!loading && (
        <>
          <Default>
            {links &&
              links.length > 15 && <Toggler toggle={toggle} onToggle={() => setToggle(!toggle)} />}
          </Default>
          <Mobile>
            <Toggler toggle={toggle} onToggle={() => setToggle(!toggle)} />
          </Mobile>
        </>
      )}

      {props.nextCategory &&
        props.nextCategory.title && (
          <Arrow
            loading={loading}
            direction="right"
            text={props.nextCategory.title}
            bgPic={cloudObjectToURL(props.nextCategory.cover)}
            onClick={props.onNext}
          />
        )}
    </div>
  )
}

Slide.defaultProps = {
  loading: false,
}

export default Slide
