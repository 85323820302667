import ProgressiveSkeleton from '@components/common/ProgressiveSkeleton'
import UnstyledList from '@components/common/UnstyledList'
import {useArticles} from '@hooks/useBlog'
import {sortByDate} from '@utils/array'
import React from 'react'
import './styles.css'
import Unit from './Unit'

const Loading = () => (
  <>
    {[1, 2, 3].map(v => (
      <ProgressiveSkeleton
        width="100%"
        height={180}
        enableMoveAnimation={true}
        className="tw-mb-4 d-block"
        key={v}
      />
    ))}
  </>
)

const SuggestedPosts: React.FC = () => {
  const {
    loading,
    data: {articles},
  } = useArticles({
    per: 3,
    target: "suggested"
  })

  const sortedArticles = [...articles].sort(sortByDate)

  return (
    <div className="tw-w-full bd-light p-4" styleName="wrapper">
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className="text-center fs20 tw-mb-12 bold text-uppercase">Suggested Posts</div>
          <UnstyledList>
            {sortedArticles.map((article) => (
              <Unit article={article} key={article.id} />
            ))}
          </UnstyledList>
        </>
      )}
    </div>
  )
}

export default SuggestedPosts
