import { fetchDeptLinks } from '@services/dept'
import React from 'react'
import { BehaviorSubject, Subscription } from 'rxjs'
import { catchError, map, switchMap, tap } from 'rxjs/operators'
import { Platform } from "@interfaces/platform";
import { platformToProvider } from '@utils/platform'

interface DeptLinksProps {
  type: 'search' | 'category'
  keyword?: string
  category?: number
  sort?: string
  props?: string
  sellerType?: string
  platform: Platform
  children: (data: DeptLinksState & Func) => React.ReactNode
  department: string
}

interface Func {
  onFetchCategory: (category: number) => void
}

interface DeptLinksState {
  loading: boolean

  category: any
  previousCategory: any
  nextCategory: any

  links: any[]

  selectedLink: number
}

const initialState = {
  category: null,
  previousCategory: null,
  nextCategory: null,

  links: [],
  selectedLink: -1,
}

class DeptLinks extends React.Component<DeptLinksProps, DeptLinksState> {
  public onFetch$: BehaviorSubject<any>
  public sub: Subscription

  constructor(props: DeptLinksProps) {
    super(props)

    this.state = {
      loading: true,
      ...initialState,
    }

    this.onFetch$ = new BehaviorSubject({
      keyword: props.keyword,
      category: props.category,
      sort: props.sort,
      props: props.props,
      sellerType: props.sellerType,
      kind: props.type,
      provider: platformToProvider(props.platform),
      department: props.department
    })
  }

  public componentDidMount() {
    const fetch$ = this.onFetch$.pipe(
      tap(() => this.handleLoading(true)),
      switchMap(params => fetchDeptLinks(params))
    )

    this.sub = fetch$.subscribe(result => {
      if (!result) {
        this.setState({ loading: false, ...initialState })
        return
      }

      this.setState({
        ...result,
        links: [...result.links].sort(
          (a, b) => (a.title.toLowerCase().startsWith('all') ? -1 : b.title > a.title ? -1 : 0)
        ),
        loading: false,
      })
    })
  }

  public componentWillReceiveProps(nextProps: DeptLinksProps) {
    if (this.props.keyword !== nextProps.keyword || this.props.category !== nextProps.category) {
      this.onFetch$.next({
        keyword: nextProps.keyword,
        category: nextProps.category,
        sort: nextProps.sort,
        props: nextProps.props,
        sellerType: nextProps.sellerType,
        kind: nextProps.type,
        provider: platformToProvider(nextProps.platform),
        department: nextProps.department
      })
    }
  }

  public handleLoading(loading: boolean) {
    this.setState({ loading })
  }

  public componentWillUnmount() {
    this.sub.unsubscribe()
  }

  public handleCategory = (category: number) => {
    this.onFetch$.next({ category })
  }

  public render() {
    return this.props.children({
      ...this.state,
      onFetchCategory: this.handleCategory,
    })
  }
}

export default DeptLinks
