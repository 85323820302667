import React, { Component } from 'react'
import PropTypes from 'prop-types';
import ProductFilter from '../ProductFilter'
import { withRouter }                  from 'react-router'

class ProductPropFilter extends Component {
  render() {
    const { id, index, title, items, onFilterChange } = this.props

    return (
      <ProductFilter 
        type='prop' 
        title={title} 
        items={items} 
        onFilterChange={value => onFilterChange(id, value)}
      />
    )
  }
}

export default withRouter(ProductPropFilter)
