import { useState, useEffect } from 'react'

export const useImageLoading = (image: string) => {
  const [loading, setLoading] = useState<boolean>(true)

  const handleOnLoad = (img: string) => {
    setLoading(true)

    const image = new Image()
    image.onload = () => setLoading(false)
    image.src = img
  }

  useEffect(() => handleOnLoad(image), [image])

  return { loading }
}
