import React, {Component, useEffect, useState} from "react";
import {Grid} from 'react-bootstrap'
import ProgressiveSkeleton from '@components/common/ProgressiveSkeleton'
import ProductSlider from "./ProductSlider";
import {useWhatsHotProducts} from "@hooks/useWhatsHot";
import {Platform} from '@interfaces/platform'
import {getRandPagination} from "@utils/number";
import {Default, Mobile} from '@components/common/Responsive'
import {sampleSize} from 'lodash'
import './styles.css'

const PAGINATION_NUMS = [1, 2, 3]

const PopularOnExpress = () => {
  const [page, setPage] = useState<number>(getRandPagination(PAGINATION_NUMS))

  const opt = {
    provider: Platform.JD,
    range: 30,
    page: page,
    perPage: 22
  }

  const {loading, products} = useWhatsHotProducts(opt)
  const filteredProducts = products.filter((p) => typeof p.minPrice !== 'undefined' && p.minPrice !== null)

  return (
    <div styleName="wrapper">
      <Grid>
        <h4 className="text-center linethrough upper tw-mt-0">
          Popular on Express
        </h4>

        {loading ? (
          <ProgressiveSkeleton
            width="100%"
            height="130px"
            enableMoveAnimation={true}
            className="tw-mt-12"
          />
        ) : (
          <>
            <Default>
              <ProductSlider popularProducts={filteredProducts} />
            </Default>
            <Mobile>
              <ProductSlider popularProducts={sampleSize(filteredProducts, opt.perPage)} />
            </Mobile>
          </>
        )
        }
      </Grid>
    </div>
  )
}

export default PopularOnExpress
