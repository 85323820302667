import { DEFAULT_HTTP_HEADERS } from '@constants/'
import { API_URL } from '@constants/'
import useSWRImmutable from 'swr/immutable'
import { fetcher } from './helper'

export function useCountries() {
  const { data, error } = useSWRImmutable([`${API_URL}/geo_zones/config`, {
    headers: DEFAULT_HTTP_HEADERS
  }], fetcher, { loadingTimeout: 5000 })

  const countries = data?.data?.zones ?? []
  const welcomeCoupon = data?.data?.welcomeCoupon
  const referrals = data?.data?.referrals

  const cn = countries.filter(c => c.abbr === 'cn')
  const notCN = countries.filter(c => c.abbr !== 'cn')

  return {
    countries: [...cn, ...notCN].sort((a, b) => a.abbr.localeCompare(b.abbr)),
    isLoading: !error && !data,
    isError: error,
    welcomeCoupon,
    referrals
  }
}
