import React, { useState } from 'react'
import { Col } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import TextboxWithCounter from '@components/common/TextboxWithCounter'
import AnimatedButton from '@components/common/AnimatedButton'
import './styles.css'

const QAndAForm = props => {
  const [value, setValue] = useState('')
  const { errors } = props || []

  const handleSubmit = question => props.onSubmit({ question }).then(() => setValue(''))

  return (
    <Col md={6} mdOffset={3}>
      <div className="tw-mt-12 pt-4 text-center" styleName="wrapper">
        <h3 className="linethrough tw-m-0">
          <FormattedMessage id="q&a_form.header" />
        </h3>
        <div className="tw-mt-6">
          <form id="qandaForm">
            <FormattedMessage id="q&a_form.placeholder_question">
              {text => (
                <TextboxWithCounter
                  textarea
                  value={value}
                  placeholder={text}
                  minRows={6}
                  maxLength={1000}
                  onChange={e => setValue(e.target.value)}
                />
              )}
            </FormattedMessage>
            {errors.length > 0 ? <p className="text-danger tw-mt-4">{errors}</p> : ''}

            <AnimatedButton
              className="btn-white tw-mt-6"
              loading={props.loading}
              onClick={() => handleSubmit(value)}
            >
              <FormattedMessage id="q&a_form.btn_submit" />
            </AnimatedButton>
          </form>
        </div>
      </div>
    </Col>
  )
}

export default QAndAForm
