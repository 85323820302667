import React from 'react'
import { HelpBlock, FormGroup, ControlLabel, FormControl, Checkbox } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { OverlayTrigger } from 'react-bootstrap'
import Textarea from 'react-textarea-autosize'
import './styles.css'

const FormInput = ({ name, value, ...props }) => {
  const renderLabel = () => {
    if (props.renderLabel) {
      const label = <ControlLabel>{props.renderLabel}</ControlLabel>

      return props.labelTooltips ? (
        <OverlayTrigger placement="top" overlay={props.labelTooltips}>
          {label}
        </OverlayTrigger>
      ) : (
        label
      )
    }

    return null
  }

  return (
    <FormGroup
      controlId={name}
      className={props.className}
      validationState={props.validationState}
      styleName="group"
    >
      {renderLabel()}
      {props.renderInput !== undefined && props.renderInput}
      {props.renderInput == undefined &&
        props.autosize !== true && (
          <>
            <FormControl
              name={name}
              disabled={props.disabled}
              type={props.type}
              value={value}
              onChange={props.onChange}
              placeholder={props.placeholder}
              {...props.min && { min: props.min }}
              {...(props.type === 'textarea' ? { componentClass: 'textarea' } : {})}
              {...(props.maxLength ? { maxLength: props.maxLength } : {})}
            />
            <HelpBlock>{props.renderHelpBlock}</HelpBlock>
          </>
        )}
      {props.renderInput == undefined &&
        props.autosize == true && (
          <>
            <Textarea
              name={name}
              disabled={props.disabled}
              type={props.type}
              value={value}
              onChange={props.onChange}
              placeholder={props.placeholder}
              {...(props.maxLength ? { maxLength: props.maxLength } : {})}
            />
          </>
        )}
    </FormGroup>
  )
}

FormInput.defaultProps = {
  type: 'text',
  value: '',
  onChange: () => {},
  className: '',
}

FormInput.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  renderLabel: PropTypes.element,
  maxLength: PropTypes.number,
  className: PropTypes.string,
}

export default FormInput
