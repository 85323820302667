import React, { Component, useState } from 'react'
import { getMinimumQuantity } from '@utils/cart/itemUtils'
import { canBeCheckedOut } from '@utils/cart/itemUtils'
import { matchPath, Link } from 'react-router-dom'

import Quantity from '../../Quantity'
import SkuOption from '../../SkuOption'

import { totalPrice, getProduct } from '../../../../utils/cart/itemUtils'

import PriceWithCurrency from '@components/common/PriceWithCurrency'
import './styles.css'
import TranslationText from '@components/common/TranslationText'
import { useLocation, useQuery } from '@hooks/useRouter'
import { cloudObjectToURL } from '@utils/'
import ProductLink from '@components/common/ProductLink'

const Item = props => {
  const { location } = useLocation()
  const query = useQuery()
  const match = matchPath(location.pathname, { path: '/:platform(jd|tb)?/products/:id' })

  const productId = match && match.params && +match.params.id ? +match.params.id : 0

  const { item, products, onDelete, onUpdateQuantity } = props
  const [deleting, setDeleting] = useState(false)

  // Affiliate
  const [affiliate, via, atId] = productId ? [query.at, query.via, query.at_sid] : []
  const isSameProduct = item.productId === productId

  const onUpdateQty = qty =>
    onUpdateQuantity(
      item.id,
      qty,
      // Pass affiliate & via only when they exist in the url, plus when the open product is same with the current cart item
      isSameProduct && {
        affiliate,
        via,
        affiliateSId: atId,
      }
    )

  const product = getProduct(item, products)

  const itemWithProduct = {
    ...item,
    disabled: !canBeCheckedOut(item),
    multipleOrderQuantity: product.multipleOrderQuantity,
    platform: product.platform,
    minQty: getMinimumQuantity(item.price, product.multipleOrderQuantity),
  }

  const backgroundImage = cloudObjectToURL(item.image, { size: '120x120' })
  const linkStyle = { backgroundImage: `url("${backgroundImage}")` }

  const onDeleteClick = () => {
    if (deleting) {
      return
    }

    setDeleting(true)
    onDelete(item.id)
      .then(() => setDeleting(false))
      .catch(() => setDeleting(false))
  }

  const Link = ({ style, styleName = '', className = '', children }) => (
    <ProductLink
      styleName={styleName}
      className={className}
      style={style}
      id={product.id}
      sku={item.skuId}
      platform={product.platform}
    >
      {children}
    </ProductLink>
  )

  return (
    <div styleName="item" className="tw-mb-6">
      <div className="v4-row">
        <div className="v4-col-4">
          <Link styleName="pic" style={linkStyle} id={product.id} />
        </div>
        <div className="v4-col-8">
          <div styleName="body">
            <Link>
              <TranslationText texts={product.titles}>
                {text => (
                  <div className="text-truncate" styleName="title unit" title={text}>
                    {text}
                  </div>
                )}
              </TranslationText>
            </Link>
            <div styleName="price unit">
              <PriceWithCurrency price={totalPrice(item)} />
            </div>
            <ul styleName="opts">
              {item.skuOptions &&
                item.skuOptions
                  .slice(0, 3)
                  .map((skuOption, idx) => (
                    <SkuOption key={idx} skuOption={skuOption} skuCategories={item.skuCategories} />
                  ))}
            </ul>
          </div>
        </div>
      </div>
      <div styleName="footer" className="tw-mt-2">
        <div styleName="qtyctrl">
          <Quantity
            disabled={itemWithProduct.disabled}
            value={itemWithProduct.quantity}
            step={itemWithProduct.multipleOrderQuantity}
            {...itemWithProduct.minQty && { min: itemWithProduct.minQty }}
            max={itemWithProduct.stock || 1}
            product={product}
            onUpdate={onUpdateQty}
          />
        </div>
        <div styleName="cartdel">
          <i className="icon-delete" onClick={onDeleteClick} />
        </div>
      </div>
      <div className="dotted-line" />
    </div>
  )
}

export default Item
