import Hot from '@components/whatshot'
import React, { FC } from 'react'
import Content from './Content'

export const isEmpty = (loading: boolean, data: any) =>
  !loading && data && data.products && data.products.length == 0 && data.totalProducts == 0

interface Props {
  contentWrapperClass?: string
  isTmallSeller?: boolean
  alias?: string
}

export const NoResult: FC<Props> = props => (
  <div>
    <Content
      isTmallSeller={props.isTmallSeller}
      wrapperClass={props.contentWrapperClass}
      alias={props.alias}
    />

    {!props.isTmallSeller && <Hot onNoResult={true} contentClassName="container" />}
  </div>
)

NoResult.defaultProps = {
  contentWrapperClass: '',
  alias: '',
}
