import ItemsWithLineThrough from '@components/common/ItemsWithLineThrough'
import Search from '@components/discover/Activity/SortAndFilterMenu/Search'
import Sortings from '@components/discover/Activity/SortAndFilterMenu/Sortings'
import {useQuery} from '@hooks/useRouter'
import qs from 'qs'
import React from 'react'
import {NavLink} from 'react-router-dom'
import MyReviewLink from '../MyReviewLink'
import { RenderMethod } from '@interfaces/reviews'
import TextWithTooltip from '@components/common/TextWithTooltip'
import './styles.css'

const renderLink = (text: string, active: boolean, params: any) => (
  <NavLink
    className="strong black-link"
    to={{
      pathname: window.location.pathname,
      search: qs.stringify(params),
    }}
    isActive={() => active}
    activeClassName="link-active"
  >
    {text}
  </NavLink>
)

const renderMethod = (renderMethod: string, setRenderMethod: (method: RenderMethod) => void) => (
  <div className="black-link fs20">
    {renderMethod === RenderMethod.GRID &&
      <TextWithTooltip tooltip={<div className="text-center">Switch to list view</div>} id="reviews-method-toolTip">
        <i className="fa-solid fa-list" onClick={() => setRenderMethod(RenderMethod.LIST)} />
      </TextWithTooltip>
    }
    {renderMethod === RenderMethod.LIST &&
      <TextWithTooltip tooltip={<div className="text-center">Switch to grid view</div>} id="reviews-method-toolTip">
        <i className="fa-solid fa-border-all" onClick={() => setRenderMethod(RenderMethod.GRID)} />
      </TextWithTooltip>
    }
  </div>
)

const Filters: React.FC = (props) => {
  const query = useQuery()
  const {date, pic_only, ...restQuery} = query

  return (
    <>
      <ItemsWithLineThrough styleName="list" className="tw-mb-6 fs12">
        {renderMethod(props.renderMethod, props.setRenderMethod)}
        <Sortings display="dropdown" />
        {renderLink('MOST RECENT', !date, {...restQuery, tab: 'reviews'})}

        {renderLink('WITH PICTURE', pic_only === '1', {
          ...restQuery,
          pic_only: pic_only === '1' ? '0' : '1',
        })}

        <MyReviewLink />

        <Search submitOnEnter={true} />
      </ItemsWithLineThrough>
    </>
  )
}

export default Filters
