import SellerBadge from '@components/common/SellerBadge'
import SellerLink from '@components/common/SellerLink'
import { Platform } from '@interfaces/platform'
import { isSupermarket } from '@utils/productUtils'
import React, { FC } from 'react'
import './styles.css'

const Seller: FC<{ id: number; platform: Platform }> = ({ id, platform }) => {
  const styleNames = ['link']

  if (platform === Platform.JD) {
    styleNames.push('jdLink')
  }

  return (
    <div styleName="seller" className="product-card-seller tw-z-20">
      <SellerBadge id={id} platform={platform} />

      {!!id &&
        !isSupermarket({ sellerId: id }) && (
          <SellerLink
            platform={platform}
            id={id}
            styleName={styleNames.join(' ')}
            className="d-block bd-light bd-circle bg-white flex-center-center black-link product-card-seller-link"
          >
            <i className="icon-seller-shop fs20" />
          </SellerLink>
        )}
    </div>
  )
}

Seller.defaultProps = {}

export default Seller
