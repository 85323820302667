import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import useAuth from '@hooks/useAuth'
import qs from 'qs'

const PrivateRoute = props => {
  const { user } = useAuth()

  const { render, component: Component, ...rest } = props

  return (
    <Route
      {...rest}
      render={props =>
        user ? (
          render ? (
            render(props)
          ) : (
            <Component {...props} />
          )
        ) : (
          <Redirect
            to={{
              pathname: `/`,
              search: qs.stringify({
                redirect_to: `${props.location.pathname}${props.location.search}`
              }),
              state: { from: props.location },
            }}
          />
        )
      }
    />
  )
}

export default PrivateRoute
