import ChinaOnly from '@components/common/ChinaOnly'
import React, { FC } from 'react'
import SubMenu from '../SubMenu'
import { ModalProps } from '../SubMenu/interface'
import qs from "qs";

const isActive = (location: any, cat: string) => {
  const query = qs.parse(location.search, { ignoreQueryPrefix: true })

  return query.cat === cat
}

const links = [
  {
    text: 'ALL',
    link: '/blog',
    isActive: (location) =>  isActive(location, undefined)
  },
  {
    text: 'FASHION & BEAUTY',
    link: '/blog?cat=fashion-and-beauty',
    isActive: (location) =>  isActive(location, 'fashion-and-beauty')
  },
  {
    text: 'GIFTS & HOLIDAYS ',
    link: '/blog?cat=gifts-and-holidays',
    isActive: (location) =>  isActive(location, 'gifts-and-holidays')
  },
  {
    text: 'HEALTH & HOME',
    link: '/blog?cat=health-and-home',
    isActive: (location) =>  isActive(location, 'health-and-home')
  },
  {
    text: 'THE CCC',
    link: '/blog?cat=the-ccc',
    isActive: (location) =>  isActive(location, 'the-ccc')
  },
  {
    text: 'MORE',
    link: '/blog?cat=more',
    isActive: (location) =>  isActive(location, 'more')
  }
]

const BlogMenu: FC<ModalProps> = props => {
  return (
    <ChinaOnly otherwiseRender={<SubMenu label="Blog" items={links} {...props} />}>
      <SubMenu label="Blog" items={links} {...props} />
    </ChinaOnly>
  )
}

export default BlogMenu
