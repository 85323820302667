import React, { useLayoutEffect, Component } from 'react'

const ScrollToTopOnMount = () => {
  useLayoutEffect(() => {
    const id = setTimeout(() => {
      window.scrollTo(0, 0)
    })

    return () => {
      clearTimeout(id)
    }
  }, [])

  return null
}

export default ScrollToTopOnMount
