import React, { Component } from 'react'
import { getMinimumQuantity } from '@utils/cart/itemUtils'
import Counter from '@components/product/v4/ProductDetails/ProductShipping/Counter'

let timeoutId

class Quantity extends Component {
  constructor(props) {
    super(props)

    this.state = {
      minusLoading: false,
      plusLoading: false,
      quantityLoading: false,
      value: props.value,
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value != this.state.value) {
      this.setState({ value: nextProps.value })
    }
  }

  render() {
    const { minusLoading, plusLoading, value } = this.state
    const { disabled } = this.props
    const isLoading = minusLoading || plusLoading

    const onChange = ({ target: { value } }) => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }

      const quantity = parseInt(value, 10)

      this.setState({ value: quantity })
      timeoutId = setTimeout(() => this._updateQuantity(quantity), 600)
    }
    const onFocus = ({ target }) => target.select()

    return (
      <Counter
        disabled={disabled || isLoading}
        quantity={value}
        min={this.props.min}
        max={this.props.max}
        increaseQuantity={this.increaseQuantity}
        decreaseQuantity={this.decreaseQuantity}
        updateQuantity={onChange}
        onFocus={onFocus}
        {...minusLoading && { renderMinus: <i className="fa fa-spinner" /> }}
        {...plusLoading && { renderPlus: <i className="fa fa-spinner" /> }}
      />
    )
  }

  decreaseQuantity = () => {
    const { onUpdate } = this.props

    const value = (() => {
      const newQuantity = this.props.value - this.props.step

      if (newQuantity < this.props.min) return this.props.min

      return newQuantity
    })()

    this.setState({ value, minusLoading: true })
    onUpdate(value).then(() => this.setState({ minusLoading: false }))
  }

  increaseQuantity = () => {
    const { onUpdate } = this.props

    const value = (() => {
      const newQuantity = this.props.value + this.props.step

      if (newQuantity > this.props.max) return this.props.max

      return newQuantity
    })()

    this.setState({ value, plusLoading: true })
    onUpdate(value).then(() => this.setState({ plusLoading: false }))
  }

  _updateQuantity(quantity) {
    const { onUpdate } = this.props
    let newQuantity = quantity

    if (quantity == this.props.value) {
      return
    }

    if (quantity < this.props.min) {
      newQuantity = this.props.min
    } else if (quantity > this.props.max) {
      newQuantity = this.props.max
    }

    const key = quantity > this.props.value ? 'plusLoading' : 'minusLoading'

    this.setState({ [key]: true })
    return onUpdate(newQuantity).then(() => this.setState({ [key]: false }))
  }
}

Quantity.defaultProps = {
  step: 1,
}

export default Quantity
