import * as ActionCreator from '@actions/notification'
import * as actions from '@actions/notification'
import useAuth from '@hooks/useAuth'
// import { sleep } from '@utils'
import { Data, Meta, Notification } from '@interfaces/notification'
import React, { useEffect, useState } from 'react'
import { getClient } from '../subscriptions/client'
import {useActions} from '@hooks/useActions'
import {useSelector} from 'react-redux'

interface NotificationProps extends Data {
  meta: Meta
  actions: typeof actions
}

interface ResultProps {
  data: Data
  meta: Meta
}

const NotificationContainer: React.SFC<NotificationProps> = props => {
  const { children: fn  } = props


  const { meta, ...main }  = useSelector(state => state.notification)

  const actions = useActions(ActionCreator)

  const { user } = useAuth()

  const disabledList = user?.preferences?.disabledNotifications ??  []

  const isSignedIn = !!user

  // real time notification
  useEffect(
    () => {
      if (user) {
        const client = getClient()

        const channel = client.subscribe(`private-notifications-${user.id}`)

        channel.bind('NOTIFICATION/NEW', (res: ResultProps) => actions.receive(res))

        return () => channel.unsubscribe(`private-notifications-${user.id}`)
      }
    },
    [isSignedIn]
  )

  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)

  useEffect(
    () => {
      if (isSignedIn) {
        setLoading(true)

        Promise.all([
          actions.fetchNotifications({ page }),
          // sleep(450),
        ]).then(() => setLoading(false))
      }
    },
    [page, isSignedIn]
  )

  const markAsRead = (notifications?: Notification[]) => {
    console.log('start reading', notifications)
    const target = notifications ? notifications : main.notifications
    const ids = getNotificationsIds(target)

    console.log('target', target, 'ids', ids)
    return actions.markAsRead(ids)
  }

  const markAllAsRead = () => {
    return actions.markAllAsRead()
  }

  const handleLoadMore = (page: number) => {
    setLoading(true)
    setPage(page)
  }

  if (typeof fn === 'function') {
    return fn({
      loading,
      data: main,
      meta: {
        page,
        unreadCount: meta.unreadCount,
        totalpages: meta.total,
      },
      actions: {
        markAsRead,
        markAllAsRead,
        handleLoadMore,
      },
    })
  }

  return null
}

export default NotificationContainer

const getNotificationsIds = (notifications: Notification[]) => {
  const unreadNotifications = notifications.filter(
    (notification: Notification) => !notification.read
  )

  const ids = unreadNotifications.map((notification: Notification) => notification.id)

  return ids
}
