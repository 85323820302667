import React, {FC} from 'react'
import {Rating} from '@interfaces/reviews'
import DottedLine from '@components/common/DottedLine'
import StatusFilter from './StatusFilter'
import PicFilter from './PicFilter'
import './styles.css'

type SqProps = {
  status?: Rating
  withPictures?: boolean
}

export type DefaultProps = {
  sq: SqProps
  positiveCount: number
  negativeCount: number
  onFilterChange: (sq: SqProps) => void
}

const Filters: FC<DefaultProps> = props => (
  <>
    <div className="flex-center-between">
      <StatusFilter {...props} />

      <PicFilter
        withPictures={props.sq.withPictures}
        handleClick={() => props.onFilterChange({
          withPictures: !props.sq.withPictures
        })}
      />
    </div>

    <DottedLine className='tw-mt-2' styleName='line' />
  </>
)

export default Filters
