import WechatAuth from '@components/common/WechatAuth'
import { useAuthProvider } from '@hooks/useAuthProvider'
import { useQuery } from '@hooks/useRouter'
import AuthContext from '@providers/Auth'
import CountryCurrencySettings from '@containers/CountryCurrencySettings'
import React from 'react'
import '../styles/application.scss'
import Inner from './Inner'

const App = () => {
  const { code } = useQuery()
  const provider = useAuthProvider()

  return (
    <div id="outer-container">
      <AuthContext provider={provider} wechatCode={code}>
        <WechatAuth>
          <CountryCurrencySettings>
            <Inner />
          </CountryCurrencySettings>
        </WechatAuth>
      </AuthContext>
    </div>
  )
}

export default App
