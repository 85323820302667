import React, { FC } from 'react'
import { Collapse } from 'react-bootstrap'
import { ItemsProps } from '../interface'
import InnerText from './InnerText'
import './styles.css'

const Items: FC<ItemsProps> = props => (
  <Collapse in={props.open}>
    <div className="p-4 bd-b-light" styleName="items">
      {props.items.map((item, index) => (
        <InnerText key={`${props.label}-${index}`} {...item} />
      ))}
    </div>
  </Collapse>
)

export default Items
