import React from 'react'
import PropTypes from 'prop-types'
import DashboardNums from './DashboardNums'
import DottedLine from '@components/common/DottedLine'
import FortunePoint from '@components/common/FortunePoint'
import ImagePlaceholder from '@components/common/ImagePlaceholder'
import './styles.css'
import { cloudObjectToURL } from '@utils/'
import Nums from '../MobileProfile/Nums'
import Avatar from '@components/discover/Profile/EditMyProfile/ProfileEditingForm/Avatar'

const ProfileDetails = props => {
  const avatar =
    cloudObjectToURL(props.avatar, { crop: 'fill', size: '200x200' }) ??
    'https://res.cloudinary.com/baopals/image/upload/c_scale,f_auto,w_auto/v1529482288/anonymous.jpg'

  return (
    <>
      <div className="container tw-my-8">
        <div className="text-center">
          <div className="tw-flex">
            <div className="v4-media tw-mr-8 text-left">
              <div className="text-center">
                {props.editable === true ? (
                  <Avatar
                    avatar={avatar}
                    onChange={props.onAvatarChange}
                    onError={console.log}
                    placeholder="EDIT"
                  >
                    {({ error, render }) => (
                      <>
                        {error && <div className="text-danger text-center tw-mb-2">{error}</div>}
                        <div styleName="pic" className="obj-fit-cover img-circle bd-dark tw-p-1">
                          {render}
                        </div>
                      </>
                    )}
                  </Avatar>
                ) : (
                  <img
                    src={avatar}
                    styleName="pic"
                    className="obj-fit-cover img-circle bd-dark tw-p-1"
                  />
                )}
              </div>

              <div className="v4-media-body align-self-center tw-ml-4">
                {props.displayName && (
                  <div styleName="name" className="strong tw-mb-4">
                    {props.displayName}
                  </div>
                )}

                <div className="tw-mt-2">
                  <FortunePoint points={props.fortunePoints} />
                </div>
              </div>
            </div>
            <div className="hidden-xs">
              <DashboardNums
                username={props.username}
                review={props.reviewsCount}
                collections={props.collectionsCount}
                sellers={props.sellersCount}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="visible-xs">
        <DottedLine />
        <div className="container text-center tw-mb-4">
          <Nums username={props.username} />
        </div>
      </div>
    </>
  )
}

ProfileDetails.propTypes = {
  username: PropTypes.string,
  editabel: PropTypes.bool,
  displayName: PropTypes.string,
  sellersCount: PropTypes.number,
}

export default ProfileDetails
