import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import PriceWithCurrency from '@components/common/PriceWithCurrency'
import { useShoppingCartTips } from '@hooks/useShoppingCartTips'
import { Link } from 'react-router-dom'

const ProductShippingPrice = ({ price, ...props }) => {
  return (
    <>
      <div className={props.className}>
        {props.renderLabel}
        {price ? (
          <>
            <PriceWithCurrency
              id="tour-shipping"
              price={price}
              {...(props.currency ? { currency: props.currency } : {})}
            />
            {props.showFreeTips && <div className="fs12"> {useShoppingCartTips()}</div>}
          </>
        ) : (
          <FormattedMessage id="product_page.shipping_free" />
        )}

        {props.showGlobalShippingFeesTips && (
          <Link to='/overseas-shipping-rates' className="blue-link d-block fs12" target="_blank">
            Check Global Shipping Fees
          </Link>
        )}
      </div>
    </>
  )
}

ProductShippingPrice.defaultProps = {
  className: '',
  showFreeTips: false,
  renderLabel: <FormattedMessage id="checkout.item_shipping" />,
}

ProductShippingPrice.propTypes = {
  showFreeTips: PropTypes.bool,
  renderLabel: PropTypes.element,
}

export default ProductShippingPrice
