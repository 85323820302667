import {Display, LeftProps} from '@interfaces/notification'
import React, {FC} from 'react'
import ImagesList from './ImagesList'
import './styles.css'
import Time from './Time'

interface Props extends LeftProps {
  date: Date
  display?: Display
  onClick?: () => void
}

const Inner: FC<Props> = ({onClick, ...props}) => (
  <div className="flex-center-between" onClick={onClick}>
    <div className="flex-center black-text">
      {(props.icon || props.users || props.renderLeft) && (
        <div className="text-center tw-mr-4" styleName="left">
          {props.icon ? (
            <div className={`fs16 ${props.icon}`} />
          ) : props.users ? (
            <ImagesList users={props.users} />
          ) : (
            props.renderLeft
          )}
        </div>
      )}

      <div styleName="middle">{props.children}</div>
    </div>

    {props.date && (
      <div
        className="tw-ml-4 text-right bp-grey-text"
        styleName={`right ${props.display == Display.dropdown ? 'dropdown-time' : ''}`}
      >
        <Time {...props} />
      </div>
    )}
  </div>
)

export default Inner
