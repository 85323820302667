import { Hook } from '@components/products/MatchResults/Suggestions'
import DeptLinks from '@hoc/DeptLinks'
import { useQuery } from '@hooks/useRouter'
import { Platform } from '@interfaces/platform'
import React from 'react'
import Carousel from './Carousel'

interface DeptCarouselProps {
  type: 'search' | 'category'
  category?: number
  keyword?: string
  platform: Platform
  setIsRenderCarousel?: any
}

const DeptCarousel: React.SFC<DeptCarouselProps> = props => {
  const query = useQuery()

  if (!props.keyword && !props.category) { return null }

  return (
    <div>
      <DeptLinks
        type={props.type}
        keyword={props.keyword}
        category={props.category}
        sort={query.sort}
        props={query.prop}
        sellerType={query.sellerType}
        platform={props.platform}
        department={query.department}
      >
        {({ onFetchCategory, ...data }) => {
          if (!data.category) { return props.children }

          return (
            <div>
              <Carousel
                loading={data.loading}
                links={data.links.map(link => ({
                  ...link,
                  active: data.selectedLink === link.id,
                }))}
                category={data.category}
                prevCategory={data.previousCategory}
                nextCategory={data.nextCategory}
                selectedLinkId={data.selectedLink}
                onPrev={() => onFetchCategory(data.previousCategory.id)}
                onNext={() => onFetchCategory(data.nextCategory.id)}
              />
              <Hook onMount={() => props.setIsRenderCarousel?.(true)} />
            </div>
          )
        }}
      </DeptLinks>
    </div>
  )
}

export default DeptCarousel
