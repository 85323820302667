import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import qs from 'qs'
import { NavItem, Nav } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { reviewsCounterAdd } from '@hooks/useReviews'
import './styles.css'
import { useTotalProductReviews } from '@hooks/useTotalProductReviews'
import { useLocation, useQuery } from '@hooks/useRouter'

const tabs = [{ name: 'details' }, { name: 'reviews' }]

const Navs = props => {
  const totalReviews = useTotalProductReviews(
    props.productId,
    props.reviewsCount + (props.taoabaoReviewsCount ?? 0)
  )

  const query = useQuery()
  const { location } = useLocation()

  return (
    <Nav bsStyle={null} styleName="tabs">
      {tabs.map((tab, index) => {
        const url = `${location.pathname}?${qs.stringify({ ...query, tab: tab.name })}`
        return (
          <NavItem
            key={index}
            href={url}
            to={url}
            componentClass={Link}
            eventKey={tab.name}
            styleName="tabs-item"
          >
            {tab.renderChild ? (
              tab.renderChild(props)
            ) : (
              <>
                {tab.name === "reviews" ? totalReviews === 0 ? "Reviews" : `Reviews (${totalReviews})` : "Product Details"}
              </>
            )}
          </NavItem>
        )
      })}

      <NavItem
        href="javascript:void(0)"
        eventKey={'qa'}
        href={`${location.pathname}?${qs.stringify({ ...query, tab: 'qa' })}`}
        to={`${location.pathname}?${qs.stringify({ ...query, tab: 'qa' })}`}
        componentClass={Link}
        styleName="tabs-item"
      >
        <FormattedMessage id="product_page.tab_q&a" /> {props.qaCount > 0 && `(${props.qaCount})`}
      </NavItem>
    </Nav>
  )
}

Navs.defaultProps = {
  qaCount: 0,
  reviewsCount: 0,
  taoabaoReviewsCount: 0,
}

export default Navs
