import * as CartActionCreator from '@actions/cart'
import { useAddress } from '@hooks/useAddress'
import useAuth, { useMyCountry } from '@hooks/useAuth'
import React, { useEffect, useState } from 'react'
import CartOnCheckoutHook from './CartOnCheckoutHook'
import { useActions } from '@hooks/useActions'
import { useSelector } from 'react-redux'
import { useSelectedAddr } from '@hooks/useSelectedAddress'
import { DEFAULT_COUNTRY_CODE } from '@constants/'

const DEFAULT_ZONE = 310106 // Jingan, Shanghai

interface injectCartProps { }

interface CurrencyProps {
  children: (props: injectCartProps) => React.ReactNode
}

const Cart: React.SFC<CurrencyProps & injectCartProps> = props => {
  const { user } = useAuth()
  const { address, defaultAddress } = useAddress()
  const { defaultZone, locationCountryCode } = useMyCountry()

  const actions = useActions(CartActionCreator)
  const { items, ...state } = useSelector(state => state.cartV2)

  const { id: selectedAddrId } = useSelectedAddr()

  const currentAddr = address.find(addr => addr.id === selectedAddrId) ?? defaultAddress

  const currentZone = currentAddr?.zoneId ?? defaultZone
  const country = currentAddr?.country || locationCountryCode

  const doFetch = (user, currentZone) => {
    actions.fetchCart({ zone: currentZone, country })
      .catch(err => {
        console.error(err)
        console.error(user)
      })
  }

  useEffect(
    () => {
      if (user && currentZone) {
        doFetch(user, currentZone)
      }
    },
    [user?.id, currentZone]
  )

  const handleLoadCheckout = () => {
    if (user && currentZone) {
      doFetch(user, currentZone)
    }
  }

  const { children: fn, loading, ...prop } = props

  if (typeof fn === 'function') {
    return <> {fn({ ...prop, ...state, actions, totalCounts: items.length, loading })} <CartOnCheckoutHook onLoadCheckout={handleLoadCheckout} /> </>
  }

  return null
}

export default Cart
