import React        from 'react'
import Slider       from 'react-slick'
import Product      from './Product'
import { useMedia } from 'use-media';
import Arrow from './Arrow'

type ProductCarouselProps = {
  slideCount?: number;
  products: any;
}

const ProductCarousel: React.SFC<ProductCarouselProps> = props => {
  const slider = React.useRef(null)

  const preventClickOnDragging = (e: any) => {
    // If mouse released while still dragging - do nothing
    if(slider.current.innerSlider.state.animating) {
      e.preventDefault()
      return
    }
  }

  const isXS = useMedia({ maxWidth: 767 });
  const isSM = useMedia({ maxWidth: 768 });
  const { products } = props

  const slideCount = (() => {
    if (isXS) return 2
    if (isSM) return 5

    return props.slideCount || 7
  })()

  const settings = {
    className: "responsive-slider",
    dots: true,
    infinite: false,
    slidesToShow: slideCount,
    slidesToScroll: slideCount,
    nextArrow: <Arrow side="right" />,
    prevArrow: <Arrow side="left" />,
    lazyLoad: true,
  }

  return (
    <Slider {...settings} ref={slider}>
      {products.map((product: any) => (
        <Product
          key={product.id}
          product={product}
          preventClickOnDragging={preventClickOnDragging} />
      ))}
    </Slider>
  )
}

export default ProductCarousel
