import FileUploader from '@components/common/FileUploader'
import Tooltip from '@components/common/Tooltip'
import { useLocation } from '@hooks/useRouter'
import { formatBytes } from '@utils'
import React, { FC, useState } from 'react'
import './styles.css'
import useAuth from '@hooks/useAuth'
import { openSignUpModal } from '@hooks/useSignUpModal'
import { useMutation } from '@tanstack/react-query'
import ajax from '@services/client'

const MAX = 7340032

interface ImageUploaderProps {
  onError: (error: string) => void
}

const ImageUploader: FC<ImageUploaderProps> = props => {
  const [error, setError] = useState(null)

  const { navigate } = useLocation()

  const { user } = useAuth()

  const uploadImage = async (file: File) => {
    const formData = new FormData();
    formData.append('image', file);

    const { data } = await ajax.post('/products', formData, {
      timeout: 18000,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    return data;
  };


  const { mutate, isLoading, status, reset } = useMutation(uploadImage, {
    onSuccess: data =>
      navigate(`/search?img=${data.imgUrl}`),
  });

  const hasError = status === 'error'

  const handleUpload = (files: File[], e) => {
    const [file] = files
    setError(null)

    if (!file) {
      setError('Only JPG, PNG files are allowed')
      return
    }

    if (!['image/jpeg', 'image/png'].includes(file.type)) {
      setError('Only JPG, PNG files are allowed')
      return
    }

    if (file.size <= MAX) {
      mutate(file)
    } else {
      setError(`Please upload a image below ${formatBytes(MAX)}`)
    }
  }

  const content = (
    <div>
      <i
        className='icon-close fs12 cursor-pointer'
        style={{
          color: "#e2e2e2",
          position: "absolute",
          right: 0,
          top: 0,
          padding: "10px",
        }}
        onClick={(e) => {
          e.stopPropagation()
        }}
      />
      <div className='text-danger tw-pt-4'>
        Sorry, we couldn't load results for your image at the moment. Please try again later.
      </div>
    </div>
  )

  const renderCamera = (
    <i
      className="icon-add-photo cursor-pointer fs20"
      title="Upload a JPG, PNG to search by image"
    />
  )

  const renderUploader = (
    <>
      <div styleName="uploader" className='search-input-uploader' id='tour-search-input-step-3'>
        {
          user ? (
            <>
              <FileUploader
                placeholder={
                  isLoading ? (
                    <i className="fa fa-spinner fa-spin fs20 tw-mb-2" />
                  ) : (
                    <>
                      {renderCamera}
                    </>
                  )
                }
                preview={false}
                multiple={false}
                accept={'image/*'}
                onUpload={handleUpload}
              />
              <Tooltip
                styleName="tooltip"
                placement="bottom"
                trigger={'click'}
                inline={true}
                tooltip={content}
                delayHide={120}
                tooltipShown={hasError}
                onVisibilityChange={show => {
                  if (!show) {
                    reset()
                  }
                }}
              >
              </Tooltip>
            </>
          ) : <span onClick={openSignUpModal}>{renderCamera}</span>
        }
      </div>
      <div styleName='error' className='search-input-uploader-error text-danger'>
        {error}
      </div>
    </>
  )

  return props.children?.({ renderUploader, onResetError: () => setError('') }) ?? null
}

export default ImageUploader
