import * as ACTION from '../constants/userReviews'

const initialState = {
  data: null,
}

export default function reviewDetails(state = initialState, { type, payload = {} }) {
  switch (type) {
    case ACTION.FETCH_REVIEW_DETAILS_SUCCESS:
      return { ...state, data: payload.data }

    default:
      return state
  }
}
