import useAuth from '@hooks/useAuth'
import React, { FC, ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'

interface BtnWithAuthRequiredProps {
  className?: string
  renderAuthContent?: ReactNode
}

const BtnWithAuthRequired: FC<BtnWithAuthRequiredProps> = ({
  renderAuthContent,
  className,
  children,
}) => {
  const { user } = useAuth()

  return <button className={className || ''}>{!user ? renderAuthContent : children}</button>
}

BtnWithAuthRequired.defaultProps = {
  renderAuthContent: <FormattedMessage id="review_form.sign_in" defaultMessage="Sign In" />,
}

export default BtnWithAuthRequired
