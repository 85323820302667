import * as ACTIONS from '@actions/myCollection'
import { useActions } from '@hooks/useActions'
import { AddedCollectionService } from '@services/collection'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

export const useMyCollections = () => {
  const state = useSelector(state => state.myCollection)
  const actions = useActions(ACTIONS)

  return { ...state, actions }
}

export const useMyAddedCollections = productIds => {
  const [ids, setIds] = useState([])

  useEffect(() => {
    const sub = AddedCollectionService.subscribe(ids => setIds(ids ?? []))

    return () => sub.unsubscribe()
  }, [])

  return ids
}
