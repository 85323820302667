import React       from 'react'
import { NavLink } from 'react-router-dom'
import './styles.css'

const TrendingSearch = ({ trending, titles, ...props }) => {
  return (
    <div styleName='wrapper'>
      <ul styleName='trending'>
        <li styleName='icon'><i className='icon-trending'></i></li>
        {props.children}
      </ul>
    </div>
  )
}

export default TrendingSearch
