import React from 'react'
import './styles.css'
import { Default, Mobile } from '@components/common/Responsive'

type ButtonProps = {
  open: boolean;
  toggleOpen: any;
}

const Button: React.SFC<ButtonProps> = props => (
  <div
    styleName={`wrapper ${props.open ? 'active' : ''}`}
    onClick={props.toggleOpen}
  >
    <Default>
      <i className='icon-share' />

      <span className='upper bold'>
        share
      </span>
    </Default>
    <Mobile>
      <i className="icon-share-v2 fs30" />
    </Mobile>
  </div>
)

export default Button
