import React from 'react'
import Tooltip from '@components/common/Tooltip'
import { cloudObjectToURL } from '@utils/cloudStorage'
import { useLocation } from '@hooks/useRouter'
import './styles.css'

interface ImageSearchProps {
  selectedImage: any
}

const ImageSearch: React.SFC<ImageSearchProps> = (props) => {
  const { navigate } = useLocation()

  return (
    <Tooltip
      styleName="tooltip"
      placement="top"
      inline={true}
      tooltip="Search for similar products using this image"
      delayHide={120}
      className="max-lg:tw-hidden"
    >
      <i
        className="icon-add-photo cursor-pointer fs20"
        onClick={() => navigate(`/search?img=${cloudObjectToURL(props.selectedImage)}`)}
      />
    </Tooltip>
  )
}

export default ImageSearch
