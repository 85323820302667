import { isWeChat } from '@utils'
import { history } from './store/configureStore'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

const wx = require('weixin-js-sdk')

const loadHotJar = () => {
  const { hotjar } = require('react-hotjar')
  const hjid = '1712882'
  const hjsv = '6'

  hotjar.initialize(hjid, hjsv)
}

if (__IS_PROD__ || __IS_TEST__) {
  Sentry.init({
    dsn: 'https://35181a31db4e4949ab7962a67fc0f8ea@o1426789.ingest.sentry.io/6775775',
    environment: 'production',
    integrations: [new BrowserTracing(), new Sentry.Replay()],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })

  if (!isWeChat()) {
    loadHotJar()
  } else {
    wx.miniProgram.getEnv(res => {
      if (res.miniprogram) {
        window.__IS_MINI_PROGRAM__ = true
      } else {
        loadHotJar()
      }
    })
  }
}
