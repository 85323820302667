import React, {FC} from 'react'
import {NavLink} from 'react-router-dom'
import {LabelProps} from '../interface'
import './styles.css'

const Label: FC<LabelProps> = props => {
  const style = 'tw-py-4 px-4 bold upper bd-b-light black-link'

  return props.labelLink ? (
    <NavLink
      className={`d-block ${style}`}
      to={props.labelLink}
      styleName="text"
      exact={true}
      activeClassName="link-active"
    >
      {props.label}
    </NavLink>
  ) : (
    <div
      className={`flex-center-between ${style}`}
      onClick={props.onToggleLabel}
      styleName="text"
      data-active={props.open}
    >
      {props.label}
      <div className="icon-arrow-right fs10" />
    </div>
  )
}

export default Label
