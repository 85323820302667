import React from 'react'
import { Link } from 'react-router-dom'
import qs from 'qs'
import CategoryPic from '../../CategoryPic'
import './styles.css'

const pathname = '/supermarket/search'

const CategoryUnit = ({ id, name, seller, ...props }) => (
  <div styleName="wrapper" className="text-center category-grid-unit">
    <Link
      className="d-block black-link"
      title={name}
      to={props.link || {
        pathname,
        search: qs.stringify({ seller, category: id }),
      }}
    >
      <div styleName="heading" className="text-truncate">
        {name}
      </div>
      <CategoryPic pic={props.pic} name={name} />
    </Link>
  </div>
)

export default CategoryUnit
