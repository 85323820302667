import React, { createContext } from 'react'
import Notification from '@containers/Notification'
import useAuth from '@hooks/useAuth'

export const NotificationsContext = new createContext()

const NotificationsProvider = props => {
  const { user } = useAuth()

  const disabledList = (user && user.preferences && user.preferences.disabledNotifications) || []

  return (
    <Notification>
      {({ loading, data, meta, actions }) => {
        // hide disabled notification
        const sortedNotification =
          data &&
          data.notifications &&
          data.notifications.filter(notification => !disabledList.includes(notification.action))

        // const unreadCount =
        //   (sortedNotification &&
        //     sortedNotification.filter(notification => !notification.read).length) ||
        //   0

        return (
          <NotificationsContext.Provider
            value={{
              loading,
              data: {
                ...data,
                notifications: sortedNotification,
              },
              meta,
              //meta: {
              //  ...meta,
              //  unreadCount,
              //},
              actions,
            }}
          >
            {props.children}
          </NotificationsContext.Provider>
        )
      }}
    </Notification>
  )
}

export default NotificationsProvider

export const NotificationsConsumer = NotificationsContext.Consumer
