import CnyNotificationModal from '@components/common/CnyNotificationModal'
import DottedLine from '@components/common/DottedLine'
import { tooltips } from '@components/common/NewUserTooltips/json'
import { Default, Desktop, Mobile, MobileAndTablet, Tablet } from '@components/common/Responsive'
import ScrollToTopOnMount from '@components/common/ScrollToTopOnMount'
import Nav from '@components/discover/Nav'
import ReviewList from '@components/discover/ReviewList'
import Filters from '@components/discover/Reviews/Filters'
import Banner from '@components/layout/Nav/Intl/Banner'
import { DesktopLogo } from '@components/layout/ResponsiveNavBar'
import WithoutFooter from '@components/layout/WithoutFooter'
import SEO from '@components/SEO'
import { useMyCountry } from '@hooks/useAuth'
import qs from 'qs'
import { useEffect } from 'react'
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Layout from './Layout'
import NavItems from './NavItems'
import Products from './Products'
import { ShowModalPositions } from '@interfaces/auth'
import './styles.css'
import ScrollToTop from '@components/common/ScrollToTop'

const Hook = ({ country }) => {
  useEffect(
    () => {
      window.scrollTo(0, 0)
    },
    [country]
  )

  return null
}

export const Reviews = ({ className }) => {
  return (
    <div className={`${className} hot-reviews`}>
      <div className="hidden-xs">
        <Filters />
      </div>
      <ReviewList
        loadingText={<div>No review found.</div>}
        showByUser={true}
        showAnonymous={true}
      />
    </div>
  )
}

Reviews.defaultProps = {
  className: '',
}

// const Blog = () => 'blog..'

const Home = () => {
  const { locationCountryCode: country, isInternational } = useMyCountry()

  const nav = (
    <>
      <MobileAndTablet>
        <Banner />
      </MobileAndTablet>
      <div className="container" styleName="nav">
        <Nav />
      </div>
      <Default>
        <DottedLine className="tw-my-6" />
      </Default>
      <Mobile>
        <DottedLine />
        <NavItems country={country} />
      </Mobile>
      <CnyNotificationModal target="homepage" tooltip={tooltips.GLOBAL_CNY_NOTICE} />
    </>
  )

  return (
    <WithoutFooter>
      <ScrollToTop>
      <SEO
        title={`The Best Way to Shop ${isInternational ? 'from' : 'in'} China`}
        description="Baopals is the simplest and most trusted way to shop from Taobao, Tmall and JD in English. Powered by expats in China, shipping worldwide."
      />
        <Mobile>
          <Switch>
            <Route path="/" exact={true} render={() => nav} />
            <Route
              path="/discover/whatshot"
              exact={true}
              render={() => <NavItems country={country} />}
            />
          </Switch>
        </Mobile>

        <Default>
          <Route path="/" exact={true} render={() => nav} />

          <Layout
            renderMain={
              <Switch>
                <Route
                  path={'*'}
                  exact={true}
                  render={prop => {
                    const query = qs.parse(prop.location.search, { ignoreQueryPrefix: true })
                    const rest = {
                      prettyUpImage: false,
                      containerClassName: '',
                      category: query.category,
                      page: +(query.page || 1),
                      country,
                      key: `${query.category}:${country}`,
                      ...(query.seller_category && { seller_category: query.seller_category }),
                    }
                    return (
                      <>
                        <Hook country={country} />
                        <Desktop>
                          <Products
                            {...rest}
                            scrollTo={ShowModalPositions.DESKTOP}
                            device="desktop"
                          />
                        </Desktop>
                        <Tablet>
                          <Products
                            {...rest}
                            scrollTo={ShowModalPositions.TABLET}
                            device="tablet"
                            hideDiscount
                          />
                        </Tablet>
                      </>
                    )
                  }}
                />
              </Switch>
            }
          />
        </Default>

        <ScrollToTopOnMount />
      </ScrollToTop>
    </WithoutFooter>
  )
}

export default Home
