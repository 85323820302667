import React from 'react'
import CategoryItem from './CategoryItem'
import './styles.css'

export const CategoryList = ({ categories, nestedCategories, activeCategory, ...props }) => {
  const height = Math.floor((document.documentElement.clientHeight - 170) / 39) * 39;

  const renderCategoryTree = cats => {
    return (
      <CategoryItem
        rootCategory={props.rootCategory}
        activeCategory={activeCategory}
        main={true}
        categories={categories}
        nestedCategories={cats}
        renderLink={props.renderLink}
      />
    )
  }

  return (
    <div styleName="tree general" className="category-list" style={{ height: `${height}px`, overflowY: 'scroll'}}>
      {props.renderHeading && (
        <div styleName="heading" className="strong">
          {props.renderHeading}
        </div>
      )}
      {renderCategoryTree(nestedCategories)}
    </div>
  )
}

// class CategoryList extends React.Component {
//  shouldComponentUpdate(nextProps, nextState) {
//    if (
//      this.props.activeCategory != nextProps.activeCategory
//      || shallowCompare(this.state, nextState) ) {
//      return true
//    }
//
//    return false
//  }
//
//  renderCategoryTree(cats) {
//    const { categories } = this.state
//    const { activeCategory } = this.props
//
//    return (
//      <CategoryItem
//        activeCategory={activeCategory}
//        main={true}
//        categories={categories}
//        nestedCategories={cats}
//        lang={'en'}
//      />
//    )
//  }
//
//  render() {
//    const { categories, nestedCategories } = this.state
//
//    const nestedCategories4GeneralMarket = (nestedCategories || []).filter(c => +c.seller !== +FRESH_MARKET_SELLER)
//
//    if (categories) {
//      return (
//        <>
//          {/*<SearchBar displayStyle='aside' />*/}
//          <div styleName='tree general' className='mt-5'>
//            <div styleName='heading' className='strong'>
//              <Link className='black-link' to={`/supermarket/search?seller=${GENERAL_MARKET_SELLER}`}>SUPERMARKET</Link>
//            </div>
//            { this.renderCategoryTree(nestedCategories4GeneralMarket) }
//          </div>
//        </>
//      )
//    }
//
//    return null
//  }
// }
//

export default CategoryList
