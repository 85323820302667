import { uniqBy } from 'lodash'

import AUTH_CONSTANTS from '@constants/auth'
import ACTION from '@constants/cart'
import ORDERS_ACTION from '@constants/orders'

const initialState = {
  items: [],
  products: [],
  loading: true,
  baseShipping: 0,
  additionalShipping: 0,
  dimensionFactor: 5,
  minBaseWeight: 1,
  shippingMethods: [],
  salesTaxPercent: 0,
}

const toggleItems = (items, ids, selected) =>
  items.map(i => ({ ...i, ...(ids.includes(i.id) && { selected }) }))

const cartReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ACTION.FETCH_CART_REQUEST:
      return { ...state, loading: true }

    case ACTION.FETCH_CART_SUCCESS:
      return {
        ...state,
        loading: false,
        items: payload.items.map(i => ({ ...i, selected: true })),
        products: payload.products,
        baseShipping: payload.baseShipping,
        additionalShipping: payload.additionalShipping,
        dimensionFactor: payload.dimensionFactor,
        minBaseWeight: payload.minBaseWeight,
        shippingMethods: payload.shippingMethods,
        salesTaxPercent: payload.salesTaxPercent,
      }

    case ACTION.SELECT_CART_ITEMS:
      return {
        ...state,
        items: toggleItems(state.items, payload, true),
      }

    case ACTION.DESELECT_CART_ITEMS:
      return {
        ...state,
        items: toggleItems(state.items, payload, false),
      }

    case ACTION.ADD_ITEM_TO_CART_SUCCESS: {
      const { item, product } = payload.data

      return {
        ...state,
        items: [{ ...item, selected: true }, ...state.items],
        products: uniqBy([...state.products, product], 'id'),
      }
    }

    case ACTION.UPDATE_CART_ITEM_QTY_SUCCESS: {
      const { id, qty: quantity } = payload
      const items = state.items
      const index = items.findIndex(i => i.id === id)

      return index > -1
        ? {
            ...state,
            items: items
              .slice(0, index)
              .concat({ ...items[index], quantity })
              .concat(items.slice(index + 1)),
          }
        : state
    }

    case ACTION.REMOVE_CART_ITEM_SUCCESS: {
      const item = state.items.find(i => i.id === payload.id)

      return item
        ? {
            ...state,
            items: state.items.filter(i => i.id !== item.id),
            /*products: state.products.filter(p => p.id !== item.productId),*/
          }
        : state
    }

    case ORDERS_ACTION.CREATE_PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        items: state.items.filter(item => !payload.items.includes(item.id)),
      }

    case AUTH_CONSTANTS.USER_SIGN_OUT:
      return { ...initialState }

    default:
      return state
  }
}

export default cartReducer
