import InfiniteScroll from 'react-infinite-scroll-component'
import Loading from '@components/cart/Widget/Loading'
import Item from '../Item'
import './styles.css'

const Collection = props => {
  const {
    cart: { items, products },
    translations,
    onUpdateItemQuantity,
    onDeleteItem,
  } = props
  const style = { maxHeight: '388px' }

  return (
    <InfiniteScroll
      dataLength={items.length}
      scrollThreshold={0.9}
      height={620}
      next={() => {}}
      hasMore={false}
      style={style}
      loader={<Loading />}
      styleName="wrapper"
    >
      <div styleName="inner">
        {!!items.length &&
          items.map(item => (
            <Item
              key={item.id}
              item={item}
              products={products}
              onUpdateQuantity={onUpdateItemQuantity}
              onDelete={onDeleteItem}
            />
          ))}
      </div>
    </InfiniteScroll>
  )
}

export default Collection
