import HtmlToReact from 'html-to-react'
import { useQuery as useReactQuery, useInfiniteQuery } from '@tanstack/react-query'
import { FormattedMessage } from 'react-intl'
import SingleDesc from './SingleDesc'
import ProductSpecs from '@components/product/ProductSpecs'
import { useQuery } from '@hooks/useRouter'
import { Platform } from '@interfaces/platform'
import Markdown from '@components/common/Markdown'
import MobileProperties from '@components/product/v2/Tabs/MobileProperties'
import { useState, useEffect } from 'react'
import ajax from '@services/client'
import { useInView } from 'react-intersection-observer'
import { fetchProductDesc } from 'repositories/product'
import './styles.css'

const { Parser } = HtmlToReact

const parser = new Parser()
const isValidNode = () => true
const processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions(React)

const NoDesc = () => (
  <div className="text-center" styleName="empty">
    <FormattedMessage id="product_page.no_details" />
  </div>
)

const processImageNode = (node, id, platform) => (
  <SingleDesc node={node} id={id} platform={platform} />
)
const processLinkNode = (node, children) => <span>{children}</span>

const processingInstructions = (id, platform) => [
  {
    shouldProcessNode: node => node.name === 'img',
    processNode: node => processImageNode(node, id, platform),
  },
  {
    shouldProcessNode: node => node.name === 'a' && node.attribs?.href,
    processNode: processLinkNode,
  },
  {
    // remove br
    shouldProcessNode: node => node.name === 'br',
    processNode: () => null,
  },
  {
    // Anything else
    shouldProcessNode: () => true,
    processNode: processNodeDefinitions.processDefaultNode,
  },
]

const RefreshButton = ({ onRefresh }) => (
  <div className="text-center">
    <button className="btn btn-bp" onClick={() => onRefresh()}>
      Refresh
    </button>
  </div>
)

const renderComponent = (error, desc, id, platform, onRefresh) =>
  desc ? (
    parser.parseWithInstructions(desc, isValidNode, processingInstructions(id, platform))
  ) : error ? (
    <RefreshButton onRefresh={onRefresh} />
  ) : (
    <NoDesc />
  )

const ProductDescription = ({ id, platform, ...props }) => {
  const query = useQuery()

  const { ref, inView } = useInView()

  const params = {
    id,
    ...(platform === Platform.JD && { sku: query.skuId }),
    platform,
  }

  const { data, error, refetch, ...rest } = useReactQuery({
    queryKey: ['productDesc', params],
    queryFn: async ({ queryKey: [_, params] }) => fetchProductDesc(params),
    retry: 3,
    enabled: inView,
  })

  const desc = data?.desc
  const properties = platform === Platform.JD ? data?.properties ?? [] : props.properties

  return (
    <div styleName="wrapper">
      <div className="tw-mb-12">
        <ProductSpecs properties={properties} sold={props.sold} />
      </div>
      {/*<div className="visible-xs">*/}
        {/*<MobileProperties*/}
          {/*properties={properties}*/}
          {/*id={id}*/}
          {/*sold={props.sold}*/}
          {/*shouldModalOpen={props.shouldModalOpen}*/}
          {/*onModalOpen={props.onModalOpen}*/}
          {/*onModalClose={props.onModalClose}*/}
        {/*/>*/}
      {/*</div>*/}
      <div ref={ref} />
      {rest.isLoading ? (
        <div className="text-center">Loading...</div>
      ) : (
        <>
          <div className="visible-xs">
            <MobileProperties
              properties={properties}
              id={id}
              sold={props.sold}
              shouldModalOpen={props.shouldModalOpen}
              onModalOpen={props.onModalOpen}
              onModalClose={props.onModalClose}
            />
          </div>
          {rest.isLoading ? (
            <div className="text-center">Loading...</div>
          ) : (
            <>
              {renderComponent(error, desc?.replace?.('<img<img', '<img'), id, platform, refetch)}
            </>
          )}
        </>
      )}
    </div>
  )
}

ProductDescription.defaultProps = {
  sold: 0,
  properties: [],
}

export default ProductDescription
