import { useLocation, useQuery } from '@hooks/useRouter'
import { updateMyProfile } from '@services/me'
import { readAuthTokenToLocalStorage } from '@utils/auth'
import { parseLocaleToKey, parseLocaleToLang } from '@utils/i18n'
import qs from 'qs'
import { useIntl } from 'react-intl'
import { matchPath } from 'react-router-dom'

const paths = ['/products', '/search', '/seller', '/category']

export const useLanguage = () => {
  const {
    navigate,
    location: { pathname },
  } = useLocation()

  const query = useQuery()

  const { locale } = useIntl()

  const shouldForceRefresh = () => paths.some(url => matchPath(pathname, { path: url }) !== null)

  const updateLang = (lang: string, cb?: any) => {
    localStorage.setItem('preferredLanguage', lang)

    if (readAuthTokenToLocalStorage()) {
      updateMyProfile({ locale: lang }).subscribe(() => {}, () => {})
    }

    navigate(`${pathname}?${qs.stringify({ ...query, lang })}`)

    const shouldRefresh = shouldForceRefresh()

    if (shouldRefresh) {
      // window.location.reload()
    } else {
      cb && cb()
    }
  }

  return {
    locale,
    lang: parseLocaleToLang(locale),
    langKey: parseLocaleToKey(locale),
    updateLang,
  }
}
