import * as ActionCreator from '@actions/shipping-formula'
import { of } from 'rxjs'
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { Epic } from './index'

export const fetchShippingFormula: Epic = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(ActionCreator.fetchShippingFormula)),
    switchMap(({ payload }) =>
      api.fetchShippingFormula(payload.country).pipe(
        map(ActionCreator.fetchShippingFormulaSuccess),
        catchError(e => of(ActionCreator.fetchShippingFormulaFailure(e)))
      )
    )
  )
