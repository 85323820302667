import React from 'react'
import TooltipTrigger from 'react-popper-tooltip'
import './styles.css'

const Tooltip = ({ children, tooltip, hideArrow, ...props }) => (
  <TooltipTrigger
    {...props}
    tooltip={({ arrowRef, tooltipRef, getArrowProps, getTooltipProps, placement }) => (
      <div
        {...getTooltipProps({
          ref: tooltipRef,
          className: `tooltip-container ${props.className}`,
        })}
      >
        {!hideArrow && (
          <div
            {...getArrowProps({
              ref: arrowRef,
              className: 'tooltip-arrow',
              'data-placement': placement,
            })}
          />
        )}
        {tooltip}
      </div>
    )}
  >
    {({ getTriggerProps, triggerRef }) => (
      <span
        {...getTriggerProps({
          ref: triggerRef,
          className: `trigger ${props.inline ? 'd-inline-block' : 'd-block'}`,
        })}
      >
        {children}
      </span>
    )}
  </TooltipTrigger>
)

Tooltip.defaultProps = {
  className: '',
  inline: false,
}

export default Tooltip
