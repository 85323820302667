import AnimatedButton from '@components/common/AnimatedButton'
import { WithAuth } from '@components/common/WithAuth'
import React from 'react'

const Button: React.SFC<any> = props => {
  return (
    <WithAuth onClick={props.onClick}>
      {onClick => (
        <AnimatedButton {...props} onClick={onClick}>
          {props.children}
        </AnimatedButton>
      )}
    </WithAuth>
  )
}

export default Button
