import * as ActionCreator from '@actions/rewards'
import { of } from 'rxjs'
import { catchError, filter, map, switchMap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { Epic } from './index'

export const fetchEarningsHistory: Epic = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(ActionCreator.fetchMyEarningsHistory)),
    switchMap(({ payload }) =>
      api.fetchEarningHistory(payload).pipe(
        map(ActionCreator.fetchMyEarningsHistorySuccess),
        catchError(e => of(ActionCreator.fetchMyEarningsHistoryFailure(e)))
      )
    )
  )

export const fetchReferralEarnings: Epic = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(ActionCreator.fetchReferralEarnings)),
    switchMap(({ payload }) =>
      api.fetchReferralEarnings(payload).pipe(
        map(ActionCreator.fetchReferralEarningsSuccess),
        catchError(e => of(ActionCreator.fetchReferralEarningsFailure(e)))
      )
    )
  )

