import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Popover, Overlay } from 'react-bootstrap'
import { sleep } from '../../../utils'
import './styles.css'

const initialState = {
  loading: false,
  success: false,
  failed: false,
  error: null,
}

class AnimatedButton extends Component {
  constructor(props) {
    super(props)

    this.state = {
      ...initialState,
    }

    this.resetState = this.resetState.bind(this)
    this.resetStateWithTimeout = this.resetStateWithTimeout.bind(this)
  }

  handleClick = async e => {
    const { successTransition, errorTransition } = this.props
    this.setState({ loading: true })
    try {
      await Promise.all([this.props.onClick(), sleep(successTransition)])
      this.setState(
        { loading: false, success: true },
        this.resetStateWithTimeout(successTransition, this.props.onSuccess)
      )
    } catch (error) {
      this.setState(
        {
          loading: false,
          failed: true,
          ...(typeof error === 'string' || React.isValidElement(error) ? { error } : {}),
        },
        this.resetStateWithTimeout(errorTransition, this.props.onError && this.props.onError(error))
      )
    }
  }

  resetStateWithTimeout(ms, cb) {
    setTimeout(() => {
      this.resetState()
      cb && cb()
    }, ms)
  }

  resetState() {
    this.setState({ ...initialState })
  }

  render() {
    const { error, failed } = this.state

    return (
      <>
        {this.renderButton()}
        {error && failed && this.renderPopover(error)}
      </>
    )
  }

  renderButton() {
    const { text, ...props } = this.props

    const { loading, success, failed } = this.state

    const styleNames = (() => {
      const styleNames = ['btn']

      if (props.disabled) styleNames.push('disabled')

      if (loading) {
        styleNames.push('loading')
      } else if (success) {
        styleNames.push('success')
      } else if (failed) {
        styleNames.push('failed')
      }

      return styleNames
    })().join(' ')

    return (
      <button
        className={`btn ${props.className}`}
        styleName={styleNames}
        type={this.props.type}
        onClick={this.handleClick}
        data-loading={loading}
        data-success={success}
        data-failed={failed}
        form={props.form}
        disabled={props.disabled}
        {...this.props.forwardRef && { ref: this.props.forwardRef }}
      >
        <span styleName="text">{props.children ? props.children : text}</span>
      </button>
    )
  }

  renderPopover(content) {
    return (
      <Overlay show={true} target={this} placement="top" containerPadding={20}>
        <Popover id="popover-contained" styleName="popover">
          {content}
        </Popover>
      </Overlay>
    )
  }
}

AnimatedButton.propTypes = {
  id: PropTypes.element,
  type: PropTypes.string,
  successTransition: PropTypes.number,
  errorTransition: PropTypes.number,
  disabled: PropTypes.bool,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
}

AnimatedButton.defaultProps = {
  form: '',
  successTransition: 650,
  errorTransition: 2000,
  disabled: false,
  text: 'Button',
  type: 'button',
}

export default AnimatedButton
