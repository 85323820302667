import NewUserTooltips from '@components/common/NewUserTooltips'
import { data, tooltips } from '@components/common/NewUserTooltips/json'
import { useMyCountry } from '@hooks/useAuth'

const SellerSwitcherTour = () => {
  const { isInternational } = useMyCountry()

  const target = '#tour-seller-switcher > ul > li:first-child'

  return (
    <NewUserTooltips
      steps={[
        isInternational
          ? {
              target,
              content: data.allSellersInternational.text,
              type: tooltips.ALL_SELLERS_INTERNATIONAL,
              placement: 'top'
            }
          : {
              target,
              content: data.allSellersDomestic.text,
              type: tooltips.ALL_SELLERS_DOMESTIC,
              placement: 'top'
            },
      ]}
    />
  )
}

export default SellerSwitcherTour
