import { useEffect, useRef, useState } from "react";

export const useShowText = (rows: number) => {
  const ref = useRef(null);
  const [expandToggle, setExpandToggle] = useState<boolean>(true)
  const [showMore, setShowMore] = useState<boolean>(false);

  useEffect(() => {
    const height = parseInt(getComputedStyle(ref.current).height);
    const lineHeight = parseInt(getComputedStyle(ref.current).lineHeight);
    if (height > (lineHeight * rows)) {
      setShowMore(true)
    }

    setExpandToggle(false)
  }, [])

  const handleToggle = (e) => {
    e.preventDefault();

    return setExpandToggle(!expandToggle)
  }

  return {
    handleToggle,
    showMore,
    expandToggle,
    ref
  }
}
