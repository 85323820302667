import * as ShoppingCartActionCreator from '@actions/cart'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import { showUnableProcessingToast } from '../actions/toasts'

import { updateCartItemQty } from '@actions/cart'
import { Platform } from '@interfaces/platform'

export const useCart = () => {
  const dispatch = useDispatch()

  const actions = bindActionCreators(
    {
      ...ShoppingCartActionCreator,
      showUnableProcessingToast,
      updateCartItemQty,
    },
    dispatch
  )

  return { actions }
}

export const useCartItems = () => {
  const { loading, items, products } = useSelector(state => state.cartV2)
  return {
    items,
    totalCounts: items.length,
  }
}
