import ProductLink from '@components/common/ProductLink'
import { Titles } from '@interfaces/common'
import { Platform } from '@interfaces/platform'
import React, { FC } from 'react'
import Footer from './Footer'
import ImagesWithTitle from './ImagesWithTitle'
import Seller from './Seller'
import './styles.css'

export { default as ProductCardLoading } from './ProductCardLoading'
export { default as SimpleProductCard } from './SimpleProductCard'

interface ProductCardProps {
  id: number
  sellerId: number

  originalPrice: number
  discountPrice?: number

  cover: string
  thumbnails: string[]

  location: Titles[]

  sold?: number
  titles: Titles

  platform: Platform

  skuId?: number

  affiliateToken?: string
  affiliateVia?: string
}

const ProductCard: FC<ProductCardProps> = React.memo(props => {
  return (
    <div styleName="wrapper" className="relative" data-disabled={!!props.unavailable}>
      <ProductLink
        className="d-block bd-x-light relative"
        styleName="inner"
        id={props.id}
        platform={props.platform}
        title=""
        skuId={props.skuId}
        affiliateToken={props.affiliateToken}
        affiliateVia={props.affiliateVia}
      >
        <ImagesWithTitle
          id={props.id}
          sellerId={props.sellerId}
          prettyUpImage={props.prettyUpImage}
          titles={props.titles}
          cover={props.cover}
          thumbnails={props.thumbnails}
          platform={props.platform}
          skuId={props.skuId}
          renderThumbnails={props.renderThumbnails}
          affiliateToken={props.affiliateToken}
          affiliateVia={props.affiliateVia}
        />
      </ProductLink>

      <Seller id={props.sellerId} platform={props.platform} />
      <Footer {...props} unavailable={props.unavailable} hideDiscount={props.hideDiscount} />
    </div>
  )
})

ProductCard.defaultProps = {
  sold: 0,
  renderThumbnails: true,
  prettyUpImage: true,
}

export default ProductCard
