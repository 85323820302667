import { initMeta, Meta } from '@interfaces/common'
import { Opt, ProductsRes, SellerData, SellersRes } from '@interfaces/whatshot'
import { fetchHotCategories, fetchHotProducts, fetchHotSellers } from '@services/whatshot'
import { combineProductsWithModules } from '@utils/whatsHotUtils'
import { useEffect, useMemo, useState } from 'react'
import { singletonHook } from 'react-singleton-hook'
import { useLoading } from './useLoading'
import { downvote, upvote } from '@hooks/useReviews'
import { concat, findBy } from '@utils/array'
import { updateVote } from '@utils/useReviews'
import { sellersWithLikedUser } from '@hooks/useFavoriteSellers'

const init = { loading: true, categories: [] }

const combineSellerCategory = (categories) => {
  const newCategories = categories.map((c) => {
    if (c.id === 1 || c.parentId === 1) {
      return { ...c, sellerCategory: c.id === 4 ? 'cosmetics' : 'women_fashion'}
    }
    if (c.id === 2 || c.parentId === 2) {
      return { ...c, sellerCategory: 'men_fashion'}
    }
    if (c.id === 3 || c.parentId === 3) {
      return { ...c, sellerCategory: 'kids'}
    }
    if (c.id === 5 || c.parentId === 5) {
      return { ...c, sellerCategory: c.id === 38 ? 'office_school' : 'home'}
    }
    if (c.id === 7 || c.parentId === 7) {
      return { ...c, sellerCategory: 'electronics'}
    }
    if (c.id === 8 || c.parentId === 8) {
      return { ...c, sellerCategory: 'food_beverage'}
    }
    if (c.id === 9 || c.parentId === 9) {
      return { ...c, sellerCategory: 'other'}
    }
    if (c.id === 16 || c.parentId === 16) {
      return { ...c, sellerCategory: 'fitness'}
    }
    if (c.id === 11 || c.parentId === 11) {
      return { ...c, sellerCategory: 'entertainment'}
    }
    if (c.id === 13 || c.parentId === 13) {
      return { ...c, sellerCategory: 'pets'}
    }
  })

  return newCategories
}


const useHotCategoriesImpl = () => {
  const [categories, setCategories] = useState([])
  const { loading, onStop, onStart } = useLoading()

  useEffect(() => {
    onStart()

    const sub = fetchHotCategories().subscribe(
      res => {
        if (res.length) {
          const categories = combineSellerCategory(res)
          setCategories([...categories].sort((a, b) => a.position - b.position))
        }

        onStop()
      },
      err => console.log('err', err)
    )

    return () => sub.unsubscribe()
  }, [])

  return { loading, categories }
}

export const useHotCategories = singletonHook(init, useHotCategoriesImpl)

const initial: ProductsRes = {
  products: [],
  currentPage: 1,
  totalPages: 0,
  blogArticles: [],
}

const combineSellers = (sellers) => {
  return sellersWithLikedUser(sellers.sellers, sellers.likes, sellers.users).map(s => {
    if(sellers.liked.includes(s.internalId)) {
      return { category: s.category,  productSeller: { ...s, liked: true } }
    }

    return { category: s.category, productSeller: { ...s } }
  })
}

export const useWhatsHotProducts = (opt: Opt) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [data, setData] = useState<ProductsRes>(initial)
  const { device, ...rest } = opt
  const department_ids = data.products.filter((p) => !!p.slug && !!p.shortName).map((p) => p.id)
  const seller_ids = data.products.filter((p) => !!p.productSeller).map((s) => s.productSeller.internalId)

  const params = {
    ...rest,
    ...(opt.page > 1 && department_ids.length > 0 && { exclude_department_ids: `${department_ids.join(',')}` }),
    ...(opt.page > 1 && seller_ids.length > 0 && { exclude_seller_ids: `${seller_ids.join(',')}` })
  }

  useEffect(
    () => {
      setLoading(true)

      const sub = fetchHotProducts(params).subscribe(
        (res: ProductsRes) => {
          const { blogArticles, globalParcels, reviews, sellers, departments, ...restRes } = res
          const modules = {
            blogArticles: blogArticles??[],
            globalParcels: globalParcels??[],
            reviews: (reviews??[]).filter((r) => !data.products.find((p) => p.rating && p.id === r.id)),
            sellers: sellers ? combineSellers(sellers) : [],
            departments: departments??[]
          }

          const combineProductsModules = () => {
            if (device === 'desktop') {
              return combineProductsWithModules(modules, res.products, 2, 5)
            }
            if (device === 'mobile') {
              return combineProductsWithModules(modules, res.products, 2, 5)
            }

            return combineProductsWithModules(modules, res.products, 1, 4)
          }


          const result = {
            ...restRes,
            products:
              opt.page === 1
                ? combineProductsModules()
                : data.products.concat(combineProductsModules()),
          }

          setData(result)
          setLoading(false)
        },
        err => console.log('err', err)
      )

      return () => sub.unsubscribe()
    },
    [opt.page]
  )

  const upvoteReview = (id: number) =>
    upvote(id)
      .then(() =>
        setData({
          ...data,
          products: updateVotes(data.products, id, true),
        })
      )
      .catch(console.error)

  const downvoteReview = (id: number) =>
    downvote(id)
      .then(() =>
        setData({
          ...data,
          products: updateVotes(data.products, id, false),
        })
      )
      .catch(e => {
        throw new Error(e.message)
      })

  const startLoading = () => setLoading(true)

  return { loading, ...data, upvoteReview, downvoteReview, startLoading }
}

const updateVotes = (products, id: number, vote: boolean) => {
  const review = products.find((p) => p.rating && p.id === id);
  const currentReviewIndex = products.findIndex((p) => p.rating && p.id === id);

  if (!review) {
    return products
  }

  const newReview = updateVote(review, vote)
  const reviewList = concat(products, newReview, currentReviewIndex)
  return reviewList
}


const initSellers: SellerData = {
  sellers: [],
}

export const useWhatsHotSellers = (opt: Opt) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [data, setData] = useState<SellerData>(initSellers)
  const [meta, setMeta] = useState<Meta>(initMeta)

  useEffect(
    () => {
      setLoading(true)

      const sub = fetchHotSellers(opt).subscribe(
        (res: SellersRes) => {
          const nextSellers = (opt.page > 1 ? data.sellers : []).concat(res.data.sellers)
          setData({ sellers: nextSellers })
          setMeta(res.meta)
          setLoading(false)
        },
        err => console.log('err', err)
      )

      return () => sub.unsubscribe()
    },
    [opt.page, opt.categoryId]
  )

  const startLoading = () => setLoading(true)

  return { loading, data, meta, startLoading }
}
