import ajax from "../api/ajax";
import { map, mapTo, pluck } from "rxjs/operators";

interface AddSellerToFavoritesOpts {
  access: string,
  category: string,
  id: number,
  provider:string
}

interface UpdateSellerOpts {
  access?: string,
  category?: string,
}

interface fetchFavoriteSellerOpts {
  date_filter?: string
  sq?: string
  category?: string
  page: number
  userName?: string
}

export const addSellerToFavorites = (opts: AddSellerToFavoritesOpts) =>{
  const formData = new FormData()

  formData.append('seller[access]', opts.access)
  formData.append('seller[category]', opts.category)
  formData.append('product_seller[id]', opts.id)
  formData.append('product_seller[provider]', opts.provider)

  return ajax({
    url: `/me/sellers`,
    method: 'POST',
    body: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }).pipe(
    pluck('response', 'data')
  )
}

export const fetchFavoriteSellers = (opts: fetchFavoriteSellerOpts) =>{
  return ajax({
    url: '/discover/sellers',
    method: 'GET',
    query: opts,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }).pipe(
    pluck('response'),
    map(({ data, meta }) => ({
      sellers: data.sellers,
      liked: data.liked,
      likes: data.likes,
      users: data.users,
      ...meta,
    }))
  )
}

export const fetchOwnFavoriteSellers = (opts: fetchFavoriteSellerOpts) =>{
  return ajax({
    url: `/me/sellers`,
    method: 'GET',
    query: opts,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }).pipe(
    pluck('response'),
    map(({ data, meta }) => ({
      sellers: data.sellers,
      ...meta,
    }))
  )
}

export const updateFavoriteSeller = (id: number, opts: UpdateSellerOpts) =>{
  const formData = new FormData()

  if(opts.access) {
    formData.append('seller[access]', opts.access)
  }

  if(opts.category) {
    formData.append('seller[category]', opts.category)
  }

  return ajax({
    url: `/me/sellers/${id}`,
    method: 'PATCH',
    body: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }).pipe(
    pluck('response', 'data', 'seller')
  )
}

export const updateFavoriteSellerType = (id: number, access: string) =>
  ajax({
    url: `/me/sellers/${id}/mark_as_${access}`,
    method: 'PATCH',
  }).pipe(mapTo({ id, access }))

export const deleteFavoriteSeller = (id: number) =>{
  return ajax({
    url: `/me/sellers/${id}`,
    method: 'DELETE',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }).pipe(
    pluck('response')
  )
}

