import FileUploader from '@components/common/FileUploader'
import { formatBytes } from '@utils'
import React from 'react'
import Textarea from 'react-textarea-autosize'
import TextboxWithCounter from '../TextboxWithCounter'
import './styles.css'

interface TextareaWithFileUploadProps {
  id: string
  name: string
  className?: string
  minRows?: number
  placeholder?: string
  uploadPlaceholder?: React.ReactNode

  value?: string
  files?: File[]

  onFilesChange: (files: File[]) => void
  onFileError?: (error: any) => void

  minLength?: number
  maxLength?: number
  required?: boolean
}

interface TextareaWithFileUploadState {
  files: File[]
}

const MAX_FILE_NUM = 5

const validateFiles = (files: File[]) => {
  const promises = files.filter(file => file instanceof File).map(file => {
    const { type } = file

    if (type.includes('image')) {
      if (!['image/jpeg', 'image/png', 'image/gif'].includes(file.type)) {
        return Promise.reject({ file, error: 'Only JPG, PNG, GIF files are allowed.' })
      }
      return validateFileSize(file, 20971520, 'JPEG, PNG, or GIF')
    } else if (type.includes('video')) {
      return validateFileSize(file, 20971520, 'WMV, MP4, or AVI')
    } else {
      return validateFileSize(file, 3145728, 'PDF')
    }
  })

  return Promise.all(promises)
}

const validateFileSize = (file: File, limitedSize: number, type: string) => {
  if (file.size > limitedSize) {
    if (file.type.includes('pdf')) {
      return Promise.reject({
        file,
        error: `Please upload a ${type} file below ${formatBytes(
          limitedSize
        )} or an image file below 20 MB.`,
      })
    }
    return Promise.reject({
      file,
      error: `Please upload a ${type} file below ${formatBytes(limitedSize)}`,
    })
  }

  return Promise.resolve()
}

class TextareaWithFileUpload extends React.PureComponent<
  TextareaWithFileUploadProps,
  TextareaWithFileUploadState
> {
  constructor(props: TextareaWithFileUploadProps) {
    super(props)

    this.state = {
      files: props.files || [],
    }
  }

  public componentWillReceiveProps(nextProps) {
    if (nextProps.files.length === 0) {
      this.setState({ files: [] })
    }
  }

  public render() {
    const { children: fn, uploadPlaceholder, onFilesChange, onFileError, ...props } = this.props

    const uploader = (
      <FileUploader
        placeholder={uploadPlaceholder}
        files={this.state.files}
        validateFiles={files => {
          if (files.length >= MAX_FILE_NUM) {
            return Promise.reject()
          }
          return validateFiles(files).catch(err => {
            onFileError && onFileError(err)
            return Promise.reject(err)
          })
        }}
        onUpload={uploadFiles => {
          const newFiles = this.state.files.concat(uploadFiles)
          this.setState({ files: newFiles.slice(0, MAX_FILE_NUM) }, () => {
            onFilesChange(newFiles)
          })
        }}
        onDelete={index => {
          const files = this.state.files.slice(0, index).concat(this.state.files.slice(index + 1))
          this.setState({ files }, () => {
            onFilesChange(files)
          })
        }}
        disabled={this.state.files.length >= MAX_FILE_NUM}
        preview={true}
      />
    )

    return (
      <>
        <div className="relative textarea-with-uploader" styleName="textarea-wrapper">
          <TextboxWithCounter className="tw-w-full bd-light tw-mt-2" textarea={true} {...props} />
        </div>
        {typeof fn === 'function'
          ? fn({
            uploader,
          })
          : uploader}
      </>
    )
  }
}

TextareaWithFileUpload.defaultProps = {
  files: [],
  className: '',
  value: '',
  minRows: 3,
  placeholder: '',
  uploadPlaceholder: (
    <a className="black-link">
      <i className="icon-upload-img" />{' '}
      <strong className="text-uppercase">Add Photos (max. {MAX_FILE_NUM})</strong>
    </a>
  ),
}

export default TextareaWithFileUpload
