import {FormattedMessage} from 'react-intl'
import './styles.css'

const ErrorPage = ({error}) => {
  const {status, message} = error

  const isRackAttackError = status === 429;

  return (
    <div className="container" styleName="wrapper">
      <div className={`error-page error-page--${status}`}>
        <div className="d-flex align-items-center">
          <h1 className="bodo tw-mr-12 d-block">
            {isRackAttackError ? 'Uh-Oh!' : <FormattedMessage id="error_page.oops" />}
          </h1>
          <div>
            {isRackAttackError ? (
              <>
                Unusual traffic detected.
                <br />
                You appear to be shopping faster than humanly possible! Please take a 5-minute break and try again.
              </>
            ) : (
              <>
                <FormattedMessage id="error_page.text_1" />
                <br />
                <FormattedMessage id="error_page.text_2" />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ErrorPage
