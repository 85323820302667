import { useResponsive, Viewport } from '@hooks/useResponsive'
import { FC } from 'react'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import './styles.css'

interface FortunePointProps {
  points?: number
}

const FortunePoint: FC<FortunePointProps> = props => {
  const points = props.points || 0
  const isSmall = useResponsive([Viewport.XS, Viewport.SM]).some(v => v)

  return (
    <OverlayTrigger
      trigger={isSmall ? 'click' : ['hover', 'focus']}
      placement="bottom"
      overlay={renderPopover()}
      rootClose={isSmall}
    >
      <a
        className={`black-link ${points < 0 ? 'bp-grey-text' : ''}`}
        styleName="fortune"
        href="/help/8"
        target="_blank"
      >
        <i className="icon-fortune fs16 tw-mr-2" />
        {points > 0 && <span>+</span>}
        <span>{points}</span>
      </a>
    </OverlayTrigger>
  )
}

FortunePoint.defaultProps = {
  points: 0,
}

export default FortunePoint

const renderPopover = () => (
  <Popover id="fortune-points-info" styleName="popover">
    <div>Earn Fortune by shopping, reviewing your purchases, and getting upvotes!</div>
  </Popover>
)
