import AddToCollection from '@components/product/v4/ProductDetails/AddToCollection'
import ShareProduct from '@components/product/v4/ProductDetails/ShareProduct'
import {FC, memo} from 'react'

const ShareProductOrAddToCollection: FC<{id: number}> = memo(({id}) => (
  <div className="fs12 product-share-collection">
    <span className="d-md-inline-block d-sm-inline-block product-collection">
      <AddToCollection product={id} />
    </span>
    <span className="tw-px-2 separator">{' | '}</span>
    <span className="d-inline-block product-share">
      <ShareProduct product={id} />
    </span>
  </div>
))

export default ShareProductOrAddToCollection
