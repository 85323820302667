import React, { useState } from 'react'
import { useMutateReview, useTaobaoReviews } from '@hooks/useTaobaoReviews'
import { Rating } from '@interfaces/reviews'
import StatusFilter from '@components/product/v2/Reviews/Filters/StatusFilter'
import DottedLine from '@components/common/DottedLine'
import ReviewRating from '@components/common/ReviewUnit/ReviewRating'
import ReviewPic from '@components/common/ReviewUnit/ReviewPic'
import LightBox from '@components/common/LightBox'
import { getImages } from '@utils/review'
import PicFilter from '@components/product/v2/Reviews/Filters/PicFilter'
import { getReviews } from '@components/product/v2/Reviews/ReviewWrapper'
import Empty from '@components/product/v2/Reviews/Empty'
import { Platform } from '@interfaces/platform'
import Sku from './Sku'
import './styles.css'
import { FormattedMessage } from 'react-intl'
import ImageWithDetails from "@components/common/Loading/ImageWithDetails";
import { Vote } from './Vote'

type SqProps = {
  status?: Rating
  withPictures?: boolean
}

const TAOBAO_PROFILE_IMAGE = "https://res.cloudinary.com/baopals/image/upload/v1680232165/user_profile/taobao/tb-profile.png"
const TMALL_PROFILE_IMAGE = "https://res.cloudinary.com/baopals/image/upload/v1680232165/user_profile/taobao/tmall-profile.png"

const TaoBaoReviews = ({ id, platform, ...props }) => {
  const [sq, setSq] = useState<SqProps>({})
  const onFilterChange = (newSq: SqProps) => setSq({ ...sq, ...newSq })
  const { isLoading, ...resp } = useTaobaoReviews(id)
  const reviews = resp.data ?? []
  const filteredReviews = getReviews(reviews, sq)

  const positiveCount = reviews.filter(r => r.rating === Rating.BUY).length
  const negativeCount = reviews.length - positiveCount
  const avatar = platform === Platform.TAOBAO ? TAOBAO_PROFILE_IMAGE : TMALL_PROFILE_IMAGE

  const mutation = useMutateReview(id)

  if (isLoading) {
    return (
      <div styleName="container">
        {props.children}
        {[0, 1, 2].map((_, index) => (
          <ImageWithDetails key={index} className="tw-mb-8" picWidth={100} noBorder={true} />
        ))}
      </div>
    )
  }

  if (reviews.length === 0) return null

  return (
    <>
      {props.children}
      <div className="bd-light tw-p-3" styleName="container">
        <div className="flex-center-between">
          <StatusFilter
            positiveCount={positiveCount}
            negativeCount={negativeCount}
            sq={sq}
            onFilterChange={onFilterChange}
            target="taobaoReviews"
            platform={platform}
          />

          <PicFilter
            withPictures={sq.withPictures}
            handleClick={() =>
              onFilterChange({
                withPictures: !sq.withPictures,
              })
            }
          />
        </div>
        <DottedLine className="tw-mt-2" />

        {
          filteredReviews.length > 0 ? (
            <div styleName="wrapper">
              {filteredReviews.map((r, index) => (
                <div styleName="review" className="bd-b-light tw-py-6" key={index}>
                  <div>
                    <ReviewPic showUser={true} user={{ avatar }} withoutLink />

                    <div className="bold tw-mt-2 text-center text-truncate" styleName="text">
                      {r.username}
                    </div>
                  </div>

                  <div className="tw-ml-4">
                    {/*<div className="flex-center-between tw-pb-2 review-bar bd-b-light">*/}
                      {/*<div className="d-flex align-items-center">*/}
                        {/*<ReviewRating rating={r.rating == Rating.BUY} />*/}
                      {/*</div>*/}
                      {/*<div>*/}
                        {/*<Vote review={r} onVote={() => !r.upvoted && mutation.mutate(r.id)} />*/}
                      {/*</div>*/}
                    {/*</div>*/}

                    <div>
                      <div className="text-left fs14 tw-pb-4">{r.content}</div>
                      <div className="tw-mb-4 text-left">
                        <Sku sku={r.sku} />
                      </div>
                      {r.pictures.length > 0 && (
                        <div className="tw-mt-4 text-left">
                          <div styleName="images">
                            <LightBox
                              images={getImages(r.pictures, '100x100')}
                              galleryImages={getImages(r.pictures, '800x800')}
                              previewWidth={100}
                              previewHeight={100}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) :
            <Empty>
              <FormattedMessage id="review_form.no_reviews-filter" />
            </Empty>
        }
      </div>
    </>

  )
}

export default TaoBaoReviews
