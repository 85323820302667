import React from 'react'
import ReactSVG from 'react-svg'
import PropTypes from 'prop-types'
import buySvg from '../../../images/buy.svg'
import passSvg from '../../../images/pass.svg'
import './styles.css'

const BaoPalsFlag = ({ type, width, height, active, selected }) => {
  const styleNames = ['svg', `${type}`]

  if (active) {
    styleNames.push('active')
  }

  if (selected) {
    styleNames.push('selected')
  }

  return (
    <ReactSVG
      data-type={type}
      className={type}
      styleName={styleNames.join(' ')}
      src={type == 'buy' ? buySvg : passSvg}
      beforeInjection={svg => {
        svg.setAttribute('style', `width: ${width}px; height: ${height}px;`)
      }}
    />
  )
}

BaoPalsFlag.defaultProps = {
  type: PropTypes.oneOf(['pass', 'buy']),
  width: 14,
  height: 14,
  active: false,
  selected: false,
}

BaoPalsFlag.propTypes = {
  type: PropTypes.string.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  active: PropTypes.bool,
  selected: PropTypes.bool,
}

export default BaoPalsFlag
