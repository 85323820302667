
import useAuth from '@hooks/useAuth'
import React, { FC } from 'react'
import SubMenu from '../SubMenu'
import { ModalProps } from '../SubMenu/interface'

const ProfileMenu: FC<ModalProps> = props => {
  const { user } = useAuth()
  if (!user) {
    return null
  }

  const base = '/discover/users'

  const items = [
    {
      text: `MY PROFILE`,
      link: `${base}/${user?.username}`,
    },
    {
      text: 'MY REVIEWS',
      link: `${base}/${user?.username}/reviews`,
    },
    {
      text: 'MY WISHLISTS',
      link: `${base}/${user?.username}/wishlists`,
    },
    {
      text: 'MY SHOPS',
      link: `${base}/${user?.username}/shops`,
    }
  ]

  return <SubMenu label="Profile & Favorites" items={items} {...props} />
}

export default ProfileMenu
