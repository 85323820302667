import * as Pusher from 'pusher-js'
import { readAuthTokenToLocalStorage } from '@utils/auth'

import { API_URL } from '../constants'

const AUTH_URL = [API_URL, 'auth', 'pusher'].join('/')

const getHeaders = () => {
  const tk = readAuthTokenToLocalStorage()

  if (tk) {
    return { Authorization: `Bearer ${tk}` }
  }

  return {}
}

export const getClient = () => new Pusher(BAOPALS.PUSHER_APP_KEY, {
  cluster: BAOPALS.PUSHER_CLUSTER,
  authEndpoint: AUTH_URL,
  auth: { headers: getHeaders() },
  forceTLS: true
})
