import SearchInput from '@components/common/SearchInput'
import { useLocation, useQuery } from '@hooks/useRouter'
import qs from 'qs'
import React, { FC, useState } from 'react'
import { Route } from 'react-router-dom'

const Input = ({ onChange, onEnter, className, ...props }) => {
  const { location, navigate } = useLocation()

  const { query: q, ...query } = useQuery()

  const renderUrl = (q: string) =>
    `${location.pathname}?${qs.stringify({ ...query, query: q, page: 1 })}`

  const [url, setUrl] = useState(renderUrl(''))

  const handleChange = (loc: string) => {
    const u = renderUrl(loc)
    setUrl(u)

    if (onChange) {
      return onChange(u)
    }
  }

  const handleEnter = (q) => {
    if (typeof q === 'string') {
      const u = renderUrl(q)
      navigate(u)
    } else {
      navigate(url)
    }
  }

  return (
    <SearchInput
      className={className}
      value={q || ''}
      onChange={handleChange}
      onEnter={handleEnter}
      cleanable={true}
      placeholder={`Search within ${props.path}`}
    />
  )
}

const KeywordsFilter: FC<{
  onChange?: (val: string) => void
  onEnter?: (val: string) => void
  className?: string
  path?: string
}> = ({ onChange, className, onEnter, path }) => (
  <Route
    path="/:path(seller|category)/:id"
    render={prop => (
      <Input
        onChange={onChange}
        className={className}
        onEnter={onEnter}
        path={path ?? prop.match.params.path}
      />
    )}
  />
)

KeywordsFilter.defaultProps = {
  className: '',
}

export default KeywordsFilter
