const MODULE = 'PRODUCT_REVIEW';

export const FETCH_PRODUCT_REVIEWS_REQUEST = `${MODULE}_FETCH_PRODUCT_REVIEWS_REQUEST`
export const FETCH_PRODUCT_REVIEWS_SUCCESS = `${MODULE}_FETCH_PRODUCT_REVIEWS_SUCCESS`
export const FETCH_PRODUCT_REVIEWS_FAILED  = `${MODULE}_FETCH_PRODUCT_REVIEWS_FAILED`

export const UPDATE_PRODUCT_REVIEW_REQUEST = `${MODULE}_UPDATE_PRODUCT_REVIEW_REQUEST`
export const UPDATE_PRODUCT_REVIEW_SUCCESS = `${MODULE}_UPDATE_PRODUCT_REVIEW_SUCCESS`
export const UPDATE_PRODUCT_REVIEW_FAILED  = `${MODULE}_UPDATE_PRODUCT_REVIEW_FAILED`

export const CREATE_PRODUCT_REVIEW_REQUEST = `${MODULE}_CREATE_PRODUCT_REVIEW_REQUEST`
export const CREATE_PRODUCT_REVIEW_SUCCESS = `${MODULE}_CREATE_PRODUCT_REVIEW_SUCCESS`
export const CREATE_PRODUCT_REVIEW_FAILED  = `${MODULE}_CREATE_PRODUCT_REVIEW_FAILED`

export const UPDATE_REVIEW_VOTE_REQUEST = `${MODULE}_UPDATE_REVIEW_VOTE_REQUEST`
export const UPDATE_REVIEW_VOTE_SUCCESS = `${MODULE}_UPDATE_REVIEW_VOTE_SUCCESS`
export const UPDATE_REVIEW_VOTE_FAILED  = `${MODULE}_UPDATE_REVIEW_VOTE_FAILED`

export const FETCH_MY_PRODUCT_REVIEW_REQUEST = `${MODULE}_FETCH_MY_PRODUCT_REVIEW_REQUEST`
export const FETCH_MY_PRODUCT_REVIEW_SUCCESS = `${MODULE}_FETCH_MY_PRODUCT_REVIEW_SUCCESS`
export const FETCH_MY_PRODUCT_REVIEW_FAILED  = `${MODULE}_FETCH_MY_PRODUCT_REVIEW_FAILED`

export const SET_REVIEW_RATING_COUNT = `${MODULE}_SET_REVIEW_RATING_COUNT`

export const INCREASE_PRODUCT_RATING = `${MODULE}/INCREASE_PRODUCT_RATING`
export const DECREASE_PRODUCT_RATING = `${MODULE}/DECREASE_PRODUCT_RATING`
