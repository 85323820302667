import React from 'react'
import TranslationText from '@components/common/TranslationText'
import './styles.css'
import { cloudObjectToURL } from '@utils/cloudStorage'
import { composeSkuKey } from '@utils/product/skuOptionUtils'

const SkuOption = ({ option, selected, onSelect, onUnselect, ...props }) => {
  const backgroundUrl = option.thumbnail && cloudObjectToURL(option.thumbnail, { size: '50x50' })
  const style = backgroundUrl && { backgroundImage: `url("${backgroundUrl}")` }

  const isSelectedFn = opt => opt.categoryId === option.categoryId && opt.id === option.id

  const currentOption = []
    .concat(option)
    .concat(selected.filter(opt => opt.categoryId != option.categoryId))

  const currentSku = props.product.skuMapping[composeSkuKey(currentOption)]

  const isSelected = selected.findIndex(isSelectedFn) > -1

  const classNames = ['sku-unit']
  if (option.thumbnail) classNames.push('thumbnail')

  //if (!active) classNames.push('disabled')
  if (
    props.product.id.includes('-') &&
    props.product.skuCategories.length == currentOption.length &&
    (!currentSku || currentSku.quantity <= 0)
  ) {
    classNames.push('disabled')
  } else if (!props.product.id.includes('-') && (!currentSku || currentSku.quantity <= 0)) {
    classNames.push('disabled')
  }
  if (isSelected) classNames.push('actived')

  const onClick = isSelected ? () => onUnselect(option) : () => onSelect(option)

  return (
    <li styleName={classNames.join(' ')}>
      <TranslationText texts={option.titles}>
        {text => (
          <a styleName="sku-unit-item" style={style} onClick={onClick} title={text}>
            <span styleName="text">{text}</span>
          </a>
        )}
      </TranslationText>
    </li>
  )
}

export default SkuOption
