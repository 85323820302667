import React, { Component }   from 'react'
import PropTypes              from 'prop-types'
import { connect }            from 'react-redux'
import { bindActionCreators } from 'redux'
import qs from 'qs'

const mapStateToProps = state => ({ 
  router: state.router
})

const RedirectTo = WrappedComponent => {
  class Redirect extends Component {
    render() {
      const {router, ...props} = this.props

      const {
        location: { pathname, search }
      } = router

      const query = qs.parse(search, { ignoreQueryPrefix: true })

      const redirectTo = `${pathname}${search}`

      const result = {
        ...query,
        ...(pathname !== "/" &&
        pathname !== "/signup" &&
        !query["redirect_to"]
          ? { redirect_to: redirectTo }
          : {})
      }

      return (
        <WrappedComponent 
          redirectTo={qs.stringify(result)}
          { ...props }
        />
      )
    }
  }

  return connect(mapStateToProps, null)(Redirect)
}

export default RedirectTo
