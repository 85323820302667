export default {
  SELECT_ITEM: '@WAREHOUSE/SELECT_ITEM',
  DESELECT_ITEM: '@WAREHOUSE/DESELECT_ITEM',

  FETCH_WAREHOUSE_ITEMS_REQUEST: '@WAREHOUSE/FETCH_WAREHOUSE_ITEMS_REQUEST',
  FETCH_WAREHOUSE_ITEMS_SUCCESS: '@WAREHOUSE/FETCH_WAREHOUSE_ITEMS_SUCCESS',
  FETCH_WAREHOUSE_ITEMS_FAILED: '@WAREHOUSE/FETCH_WAREHOUSE_ITEMS_FAILED',

  CREATE_PARCEL_REQUEST: '@WAREHOUSE/CREATE_PARCEL_REQUEST',
  CREATE_PARCEL_SUCCESS: '@WAREHOUSE/CREATE_PARCEL_SUCCESS',
  CREATE_PARCEL_FAILED: '@WAREHOUSE/CREATE_PARCEL_FAILED',
} as const
