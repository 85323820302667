import { loginModal$ } from '@hooks/useLoginModal'
import { closeLoginModal } from '@hooks/useLoginModal'
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import Heading from './Heading'
import LoginForm from './LoginForm'
import './styles.css'

const LoginModal = () => {
  const [state, setState] = useState({})

  useEffect(() => {
    const sub = loginModal$.subscribe(s => {
      setState(s)
    })

    return () => sub.unsubscribe()
  }, [])

  const open = state.open

  const opt = state.opt ?? {}

  return (
    <Modal
      bsSize="sm"
      show={open}
      backdrop="static"
      onHide={closeLoginModal}
      className="login-modal-container"
      backdropClassName="login-modal-backdrop"
      styleName="modal"
    >
      <Modal.Body>
        <Heading cancelable={opt.cancelable} onClose={closeLoginModal} />
        <LoginForm />
      </Modal.Body>
    </Modal>
  )
}

export default LoginModal
