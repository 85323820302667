import React from 'react'

const Copyright = () => (
  <div className="text-grey">
    © 2015-
    {new Date().getFullYear()}, BAOPALS.COM |{' '}
    <a href="http://beian.miit.gov.cn" target="_blank" rel="noopener" className="current-color">
      沪ICP备15044417号-1
    </a>
  </div>
)

export default Copyright
