import * as ACTION from '@actions/notification'
import AUTH_CONSTANTS from '@constants/auth'
import { Data, Meta } from '@interfaces/notification'
import { concat, findBy } from '@utils/array'
import { uniqBy } from 'lodash'
import { Reducer } from 'redux'
import { RootAction } from 'store/root-action'
import { getType } from 'typesafe-actions'

export interface NotificationState extends Data {
  meta: Meta
}

const initialState: NotificationState = {
  notifications: [],
  meta: { total: 0, unreadCount: 0 },
}

export const notification: Reducer<NotificationState, RootAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case AUTH_CONSTANTS.USER_SIGN_OUT:
      return { ...initialState }

    // real time notification
    case getType(ACTION.receive): {
      const {
        payload: { data },
      } = action
      const { notifications: prev } = state
      const { notification: nextOne } = data

      // Notification is already here, we do nothing
      if (prev.find(n => n.id == nextOne.id)) {
        return state
      }

      // Add new notification data
      return { ...state, notifications: [nextOne, ...prev] }
    }

    case getType(ACTION.fetchNotificationsSuccess): {
      const {
        payload: { meta, data },
      } = action
      const { notifications: prev } = state
      const { notifications: next } = data

      // remove same-id-notification
      const notifications = uniqBy([...prev, ...next], 'id')

      return { ...state, notifications, meta }
    }

    case getType(ACTION.markAsReadSuccess): {
      const {
        payload: { ids },
      } = action

      const [prevNotific, index] = findBy(state.notifications, 'id', ids[0])

      const nextNotific = { ...prevNotific, read: true }
      const notifications = concat(state.notifications, nextNotific, index)

      return {
        ...state,
        notifications,
        meta: { ...state.meta, unreadCount: state.meta.unreadCount - 1 },
      }
    }

    case getType(ACTION.markAllAsReadSuccess): {
      const notifications = state.notifications.map(n => ({ ...n, read: true }))

      return {
        ...state,
        notifications,
        meta: { ...state.meta, unreadCount: 0 },
      }
    }

    default:
      return state
  }
}
