export default {
  CREATE_TICKET_REQUEST: '@TICKET/CREATE_TICKET_REQUEST',
  CREATE_TICKET_SUCCESS: '@TICKET/CREATE_TICKET_SUCCESS',
  CREATE_TICKET_FAILURE: '@TICKET/CREATE_TICKET_FAILURE',

  FETCH_TICKET_REQUEST: '@TICKET/FETCH_TICKET_REQUEST',
  FETCH_TICKET_SUCCESS: '@TICKET/FETCH_TICKET_SUCCESS',
  FETCH_TICKET_FAILURE: '@TICKET/FETCH_TICKET_FAILURE',

  FETCH_TICKET_HISTORY_REQUEST: '@TICKET/FETCH_TICKET_HISTORY_REQUEST',
  FETCH_TICKET_HISTORY_SUCCESS: '@TICKET/FETCH_TICKET_HISTORY_SUCCESS',
  FETCH_TICKET_HISTORY_FAILURE: '@TICKET/FETCH_TICKET_HISTORY_FAILURE',

  CLOSE_TICKET_REQUEST: '@TICKET/CLOSE_TICKET_REQUEST',
  CLOSE_TICKET_SUCCESS: '@TICKET/CLOSE_TICKET_SUCCESS',
  CLOSE_TICKET_FAILURE: '@TICKET/CLOSE_TICKET_FAILURE',

  CREATE_TICKET_REPLY_REQUEST: '@TICKET/CREATE_TICKET_REPLY_REQUEST',
  CREATE_TICKET_REPLY_SUCCESS: '@TICKET/CREATE_TICKET_REPLY_SUCCESS',
  CREATE_TICKET_REPLY_FAILURE: '@TICKET/CREATE_TICKET_REPLY_FAILURE',

  ADD_TICKET_TO_LIST: '@TICKET/ADD_TICKET_TO_LIST',
  REMOVE_TICKET_FROM_LIST: '@TICKET/REMOVE_TICKET_FROM_LIST',

  CREATE_EXTRAINFO_REPLY_REQUEST: '@TICKET/CREATE_EXTRAINFO_REPLY_REQUEST',
  CREATE_EXTRAINFO_REPLY_SUCCESS: '@TICKET/CREATE_EXTRAINFO_REPLY_SUCCESS',
  CREATE_EXTRAINFO_REPLY_FAILURE: '@TICKET/CREATE_EXTRAINFO_REPLY_FAILURE',
  UPDATE_EXTRAINFO_REPLY_REDUCER: '@TICKET/UPDATE_EXTRAINFO_REPLY_REDUCER',
} as const
