import * as ACTION from '../constants/userReviews'

export const createProductReview = (product, review, meta) => ({
  type: ACTION.CREATE_PRODUCT_REVIEW_REQUEST,
  payload: {
    product,
    review,
    ...meta,
  },
  meta: {
    lifecycle: {
      resolve: ACTION.CREATE_PRODUCT_REVIEW_SUCCESS,
      reject: ACTION.CREATE_PRODUCT_REVIEW_FAILED,
    },
  },
})
export const createProductReviewSuccess = (review, meta) => ({
  type: ACTION.CREATE_PRODUCT_REVIEW_SUCCESS,
  payload: {
    review,
    ...meta,
  },
})
export const createProductReviewFailed = error => ({
  type: ACTION.CREATE_PRODUCT_REVIEW_FAILED,
  payload: {
    error,
  },
})

export const updateMyReview = (id, review, meta) => ({
  type: ACTION.EDIT_MY_REVIEW_REQUEST,
  payload: {
    id,
    review,
    ...meta,
  },
  meta: {
    lifecycle: {
      resolve: ACTION.EDIT_MY_REVIEW_SUCCESS,
      reject: ACTION.EDIT_MY_REVIEW_FAILED,
    },
  },
})
export const updateMyReviewSuccess = (id, review, meta) => ({
  type: ACTION.EDIT_MY_REVIEW_SUCCESS,
  payload: {
    id,
    review,
    ...meta,
  },
})
export const updateMyReviewFailed = error => ({
  type: ACTION.EDIT_MY_REVIEW_FAILED,
  payload: {
    error,
  },
})

export const fetchUserReviews = (username, opt) => {
  return {
    type: ACTION.FETCH_USER_REVIEWS_REQUEST,
    payload: {
      username,
      opt,
    },
    meta: {
      lifecycle: {
        resolve: ACTION.FETCH_USER_REVIEWS_SUCCESS,
        reject: ACTION.FETCH_USER_REVIEWS_FAILED,
      },
    },
  }
}

export const fetchUserReviewsSuccess = ({ reviews, meta }) => ({
  type: ACTION.FETCH_USER_REVIEWS_SUCCESS,
  payload: {
    reviews,
    meta,
  },
})

export const fetchUserReviewsFailed = error => ({
  type: ACTION.FETCH_USER_REVIEWS_FAILED,
  payload: {
    error,
  },
})

export const fetchReviewDetails = id => ({
  type: ACTION.FETCH_REVIEW_DETAILS_REQUEST,
  payload: {
    id,
  },
  meta: {
    lifecycle: {
      resolve: ACTION.FETCH_REVIEW_DETAILS_SUCCESS,
      reject: ACTION.FETCH_REVIEW_DETAILS_FAILED,
    },
  },
})
export const fetchReviewDetailsSuccess = data => ({
  type: ACTION.FETCH_REVIEW_DETAILS_SUCCESS,
  payload: {
    data,
  },
})
export const fetchReviewDetailsFailed = error => ({
  type: ACTION.FETCH_REVIEW_DETAILS_FAILED,
  payload: {
    error,
  },
})

export const deleteReview = (id, meta) => ({
  type: ACTION.DELETE_REVIEW_REQUEST,
  payload: { id, meta },
  meta: {
    lifecycle: {
      resolve: ACTION.DELETE_REVIEW_SUCCESS,
      reject: ACTION.DELETE_REVIEW_FAILED,
    },
  },
})
export const deleteReviewSuccess = (id, meta) => ({
  type: ACTION.DELETE_REVIEW_SUCCESS,
  payload: { id, meta },
})
export const deleteReviewFailed = id => ({
  type: ACTION.DELETE_REVIEW_FAILED,
  payload: { error },
})

export const createUserReviewVote = (id, vote) => ({
  type: ACTION.CREATE_USER_REVIEW_REQUEST,
  payload: { id, vote },
})
export const createUserReviewVoteSuccess = res => ({
  type: ACTION.CREATE_USER_REVIEW_SUCCESS,
  payload: { res },
})
export const createUserReviewVoteFailed = (id, vote) => ({
  type: ACTION.CREATE_USER_REVIEW_FAILED,
  payload: { error },
})

export const updateUserReviewVote = (id, voteId, vote) => ({
  type: ACTION.UPDATE_USER_REVIEW_REQUEST,
  payload: { id, voteId, vote },
})
export const updateUserReviewVoteSuccess = res => ({
  type: ACTION.UPDATE_USER_REVIEW_SUCCESS,
  payload: { res },
})
export const updateUserReviewVoteFailed = (id, voteId, vote) => ({
  type: ACTION.UPDATE_USER_REVIEW_FAILED,
  payload: { error },
})
