import { usePaymentConfig } from '@hooks/usePaymentConfig'
import React, { ReactNode } from 'react'
import { rangeToString } from '../../../helpers/currencyHelper'

interface Children {
  children: (v: string) => ReactNode
}

interface PriceWithCurrencyProps {
  price: number | [number, number]
  currency?: string
  rate?: number
  currencyRate?: number
  className?: string
  currencyLocale?: string

  children?: ReactNode | Children
  noPoint?: boolean
}

const PriceWithCurrency: React.SFC<PriceWithCurrencyProps> = props => {
  const state = usePaymentConfig()
  if(!state.currencyLocale) return null
  const currency = props.currency || state.currency
  const currencyRate = props.currencyRate || state.rate[currency]
  const currencyLocale = props.currencyLocale || state.currencyLocale

  const [startPrice, endPrice] = Array.isArray(props.price)
    ? props.price
    : [props.price, props.price]

  const priceStr = currencyRate
    ? rangeToString([startPrice, endPrice], currency, currencyRate, currencyLocale, props.noPoint)
    : 'Calculating...'

  if (typeof props.children === 'function') {
    return props.children(priceStr, currency)
  }

  return (
    <span className={props.className} {...props.id && { id: props.id }}>
      {priceStr
        .split('-')
        .map((e, idx) => (
          <span key={idx} data-index={idx}>
            {e}
          </span>
        )).reduce((prev, curr) => (
          <>
            {prev}
            <span key="separator" data-index={-1}>
              -
            </span>
            {curr}
          </>
        ))
      }
    </span>
  )
}

PriceWithCurrency.defaultProps = {
  className: '',
}

export default PriceWithCurrency
