import ajax from '@api/ajax'
import { Zone } from '@interfaces/address'
import { Address, AddressForm } from '@interfaces/address'
import { Observable } from 'rxjs'
import { map, pluck } from 'rxjs/operators'

export const updateDefaultAddress = (address: number): Observable<boolean> =>
  ajax({
    url: `/me/addresses/${address}/mark_as_default`,
    method: 'PATCH',
  }).pipe(map(() => true))

export const createAddress = (
  address: AddressForm
): Observable<{ address: Address; zones: Zone[] }> =>
  ajax({
    url: `/me/addresses`,
    method: 'POST',
    body: { address },
  }).pipe(pluck('response', 'data'))

export const updateAddress = (
  id: number,
  address: AddressForm
): Observable<{ address: Address; zones: Zone[] }> =>
  ajax({
    url: `/me/addresses/${id}`,
    method: 'PATCH',
    body: { address },
  }).pipe(pluck('response', 'data'))

export const deleteAddress = (
  address: number
): Observable<{ id: number; defaultAddress: number }> =>
  ajax({
    url: `/me/addresses/${address}`,
    method: 'DELETE',
  }).pipe(
    pluck('response', 'data', 'defaultAddressId'),
    map(defaultAddress => ({ id: address, defaultAddress: +defaultAddress }))
  )

export const fetchZones = (parentZone?: number, country?: string): Observable<Zone[]> =>
  ajax({
    url: `/geo_zones`,
    method: 'GET',
    query: {
      ...(parentZone && { parentId: parentZone }),
      ...(country && { country }),
    },
  }).pipe(pluck('response', 'data', 'zones'))
