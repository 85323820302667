import {HttpStatus} from '@constants/httpStatus'
import { HttpError } from './HttpError'

export class ForbiddenError extends HttpError {
  public name = 'ForbiddenError'

  constructor() {
    super(HttpStatus.FORBIDDEN)
    Object.setPrototypeOf(this, ForbiddenError.prototype)
  }
}
