import { Default } from '@components/common/Responsive'
import MinQtyRequired from '@components/product/v4/ProductDetails/ProductShipping/MinQtyRequired'
import {
  useProductQty,
  useProductSku,
  useProductUnavailability,
  useSingleProduct,
} from '@hooks/useProduct'
import { getAvailableStock, getMinimumQuantity } from '@utils/productUtils'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import Available from './Available'
import Counter from './Counter'
import Shipping from './ProductShipping'
import './styles.css'

interface ProductShippingProps {
  id: number
}

// TODO: rerender
const ProductShipping: React.SFC<ProductShippingProps> = ({ id }) => {
  const { selected } = useProductSku(id)
  const { quantity, onChange } = useProductQty(id)
  const { product } = useSingleProduct(id)

  const unavailability = useProductUnavailability(id)

  const min = getMinimumQuantity(product, selected)
  const max = getAvailableStock(product, selected)

  const increaseQuantity = () => onChange(quantity + (product.multipleOrderQuantity || 1))
  const decreaseQuantity = () => onChange(quantity - (product.multipleOrderQuantity || 1))

  const handleFocus = ({ target }: any) => target.select()
  const updateQuantity = ({ target: { value } }: any) => {
    let quantity = parseInt(value, 10)
    if (isNaN(quantity)) {
      quantity = 1
    }

    onChange(quantity)
  }

  return (
    <>
      <div styleName="wrapper">
        <div styleName="text">
          <FormattedMessage id="product_page.quantity" />
          &#58;&nbsp;
        </div>
        <div
          styleName="available"
          className="product-stock-value"
          data-unavailability={!!unavailability}
        >
          <Available
            unable={unavailability}
            min={min}
            max={max}
            withBrackets={true}
            platform={product.platform}
          />
        </div>
        <div styleName="counter">
          <Counter
            disabled={['stock', 'area_supermarket', 'location'].includes(unavailability)}
            quantity={quantity}
            min={min}
            max={max}
            decreaseQuantity={decreaseQuantity}
            increaseQuantity={increaseQuantity}
            updateQuantity={updateQuantity}
            onFocus={handleFocus}
          />
        </div>
      </div>
      {min > 1 && <MinQtyRequired />}
      <Default>
        <div className="product-shippingfee">
          <Shipping id={id} />
        </div>
      </Default>
    </>
  )
}

export default ProductShipping
