import AsyncLoader from '@components/common/AsyncLoader'
import ScrollToTopOnMount from '@components/common/ScrollToTopOnMount'
import SEO from '@components/SEO'
import { useBlogCategories } from '@hooks/useBlog'
import { Route, Switch } from 'react-router-dom'
import Main from './Main'
import SearchAndFilters from './SearchAndFilters'
import { useQuery } from "@hooks/useRouter";
import { seoData } from './json'

const Article = AsyncLoader({
  loader: () => import('@components/blog/SingleArticle'),
})

export const Index = () => {
  const { loading, categories } = useBlogCategories()
  const { cat, ...query } = useQuery()
  const name = Object.keys(seoData).includes(cat) ? cat : "main"

  return (
    <>
      <SEO
        title={seoData[name].title}
        description={seoData[name].description}
      />

      <SearchAndFilters loading={loading} categories={categories} />

      <Main categories={categories} />
      <ScrollToTopOnMount />
    </>
  )
}

const Blog = props => {
  const { url } = props.match
  return (
    <Switch>
      <Route path={`${url}/articles/:slug`} component={Article} />
      <Route path={`${url}`} component={Index} />
    </Switch>
  )
}

export default Blog
