import React from 'react'
import './styles.css'

const Layout = props => {
  return (
    <>
      {props.heading}
      <div className="container">
        <div styleName="inner">
          <div styleName="main">
            <div styleName="content">{props.main}</div>
          </div>
          <div styleName="sidebar">{props.aside}</div>
          <div className="clearfix" />
        </div>
      </div>
    </>
  )
}

export default Layout
