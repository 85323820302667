import ACTION from '@constants/orders'
import { FetchOrdersParam, Item, Order } from '@interfaces/order'
import { ReviewData } from '@interfaces/reviews'
import { createStandardAction } from 'typesafe-actions'

// Fetch single order
export const fetchSingleOrder = createStandardAction(ACTION.FETCH_SINGLE_ORDER_REQUEST).map(
  (id: number, { isBundle, isParcel, countries }) => ({
    payload: { id, isBundle, isParcel, countries },
    meta: {
      lifecycle: {
        resolve: ACTION.FETCH_SINGLE_ORDER_SUCCESS,
        reject: ACTION.FETCH_SINGLE_ORDER_FAILURE,
      },
    },
  })
)

export const fetchSingleOrderSuccess = createStandardAction(ACTION.FETCH_SINGLE_ORDER_SUCCESS)<{
  order: Order
  items: Item[]
}>()
export const fetchSingleOrderFailure = createStandardAction(ACTION.FETCH_SINGLE_ORDER_FAILURE)<
  Error
>()

// Fetch orders
export const fetchOrders = createStandardAction(ACTION.FETCH_ORDERS_REQUEST).map(
  (params: FetchOrdersParam) => ({
    payload: { params },
    meta: {
      lifecycle: {
        resolve: ACTION.FETCH_ORDERS_SUCCESS,
        reject: ACTION.FETCH_ORDERS_FAILURE,
      },
    },
  })
)

export const fetchParcels = createStandardAction(ACTION.FETCH_PARCELS_REQUEST).map(
  (params: FetchOrdersParam) => ({
    payload: { params },
    meta: {
      lifecycle: {
        resolve: ACTION.FETCH_PARCELS_SUCCESS,
        reject: ACTION.FETCH_PARCELS_FAILURE,
      },
    },
  })
)

export const fetchParcelsSuccess = createStandardAction(ACTION.FETCH_PARCELS_SUCCESS)<{
  orders: Order[]
  items: Item[]
  totalRecords: number
  reviews: ReviewData[]
}>()
export const fetchParcelsFailure = createStandardAction(ACTION.FETCH_PARCELS_FAILURE)<Error>()

export const fetchOrdersSuccess = createStandardAction(ACTION.FETCH_ORDERS_SUCCESS)<{
  orders: Order[]
  items: Item[]
  totalRecords: number
  reviews: ReviewData[]
}>()
export const fetchOrdersFailure = createStandardAction(ACTION.FETCH_ORDERS_FAILURE)<Error>()

// Archive/Unarchive order
export const archiveUnarchiveOrder = createStandardAction(ACTION.TOGGLE_ORDER_ARCHIVE_REQUEST).map(
  (id: number, { archive, isBundle }: { archive: boolean; isBundle: boolean }) => ({
    payload: { id, archive, isBundle },
    meta: {
      lifecycle: {
        resolve: ACTION.TOGGLE_ORDER_ARCHIVE_SUCCESS,
        reject: ACTION.TOGGLE_ORDER_ARCHIVE_FAILURE,
      },
    },
  })
)
export const archiveUnarchiveOrderSuccess = createStandardAction(
  ACTION.TOGGLE_ORDER_ARCHIVE_SUCCESS
)<{
  id: number
  archive: boolean
}>()
export const archiveUnarchiveOrderFailure = createStandardAction(
  ACTION.TOGGLE_ORDER_ARCHIVE_FAILURE
)<Error>()

// Cancel orders
export const cancelOrder = createStandardAction(ACTION.CANCEL_ORDER_REQUEST).map(
  (id: number, { isBundle, isParcel } = { isBundle: false, isParcel: false }) => ({
    payload: { id, isBundle, isParcel },
    meta: {
      lifecycle: {
        resolve: ACTION.CANCEL_ORDER_SUCCESS,
        reject: ACTION.CANCEL_ORDER_FAILURE,
      },
    },
  })
)

export const cancelOrderSuccess = createStandardAction(ACTION.CANCEL_ORDER_SUCCESS)<{
  id: number
}>()
export const cancelOrderFailure = createStandardAction(ACTION.CANCEL_ORDER_FAILURE)<Error>()

export const deleteOrder = createStandardAction(ACTION.DELETE_ORDER_REQUEST).map(
  (id: number, { isBundle } = { isBundle: false }) => ({
    payload: { id, isBundle },
    meta: {
      lifecycle: {
        resolve: ACTION.DELETE_ORDER_SUCCESS,
        reject: ACTION.DELETE_ORDER_FAILURE,
      },
    },
  })
)

export const deleteOrderSuccess = createStandardAction(ACTION.DELETE_ORDER_SUCCESS)<{
  id: number
}>()
export const deleteOrderFailure = createStandardAction(ACTION.DELETE_ORDER_FAILURE)<Error>()

export const updateOrderItemsReview = createStandardAction(ACTION.UPDATE_ORDER_ITEMS_REVIEW)<{
  review: any
}>()

export const createPayment = createStandardAction(ACTION.CREATE_PAYMENT_REQUEST).map(
  ({
    addressId,
    items,
    currency,
    couponId,
    isParcel,
    shippingOption,
  }: {
    shippingOption?: string
    couponId: any
    addressId: number
    items: number[]
    currency: string
    isParcel?: false
  }) => ({
    payload: { addressId, items, currency, couponId, isParcel, shippingOption },
    meta: {
      lifecycle: {
        resolve: ACTION.CREATE_PAYMENT_SUCCESS,
        reject: ACTION.CREATE_PAYMENT_FAILURE,
      },
    },
  })
)

export const createPaymentSuccess = createStandardAction(ACTION.CREATE_PAYMENT_SUCCESS)<{
  id: number
  items: number[]
}>()
export const createPaymentFailure = createStandardAction(ACTION.CREATE_PAYMENT_FAILURE)<Error>()
