import Signout from '@components/common/Signout'
import { useEffect, useState } from 'react'
import ROUTING from '@constants/url'
import URL from '@constants/url'
import useAuth from '@hooks/useAuth'
import React, { FC } from 'react'
import SubMenu from '../SubMenu'
import { ModalProps } from '../SubMenu/interface'

const AccountMenu: FC<ModalProps> = props => {
  const { user } = useAuth()
  if (!user) {
    return null
  }

  const warehouseText = !!user.warehouseItems ? `WAREHOUSE (${user.warehouseItems})` : 'WAREHOUSE'

  const defaultItems = [
    {
      text: `ORDERS`,
      link: URL.ACCOUNT_ORDERS,
    },
    {
      text: warehouseText,
      link: URL.ACCOUNT_WAREHOUSE,
    },
    {
      text: 'ADDRESSES',
      link: ROUTING.ACCOUNT_ADDRESS,
    },
    {
      text: 'SETTINGS',
      link: ROUTING.ACCOUNT_SETTINGS,
    },
    {
      text: 'MY BAOPALS PRO',
      link: URL.ACCOUNT_BAOPALS_PRO,
    },
    {
      text: (
        <span className="upper link-black">
          <Signout />
        </span>
      ),
      link: '/',
    },
  ]

  const parcels =  { text: 'PARCELS', link: URL.ACCOUNT_PARCELS }

  const [items, setItems] = useState(defaultItems)
  const isBaopalsPro = user?.subscription?.active

  useEffect(() => {
    let data = [...items]
    if(!!user.parcelsCount) {
      data = data.filter(i => i.text !== 'PARCELS')
      data.splice(2, 0, parcels)
    }

    if(!isBaopalsPro) {
      data = data.filter(i => i.text !== 'MY BAOPALS PRO')
    }

    setItems(data)

  }, [user.parcelsCount, isBaopalsPro])

  return <SubMenu label="Account" items={items} {...props} />
}

export default AccountMenu
