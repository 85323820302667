import React, { FC, useEffect, useState } from 'react'
import AddToFavoritesModal from '@components/products/AddToFavorites/Modal'
import { Link } from 'react-router-dom'
import useAuth from '@hooks/useAuth'
import { openSignUpModal } from '@hooks/useSignUpModal'

const AddToFavorites: FC<{
  seller: string
  platform: string
  isFavoriteSeller?: boolean
  sellerCategory: string
}> = props => {
  const [open, setOpen] = useState<boolean>(false)
  const [isFavoriteSeller, setIsFavoriteSeller] = useState<boolean>(props.isFavoriteSeller)
  const { user } = useAuth()

  useEffect(
    () => {
      setIsFavoriteSeller(props.isFavoriteSeller)
    },
    [props.isFavoriteSeller]
  )

  const handleClick = () => {
    if (!user) {
      return openSignUpModal()
    }
    if (isFavoriteSeller) return
    return setOpen(true)
  }

  return (
    <>
      <div
        className={`text-uppercase fs12 bold d-flex align-items-center ${isFavoriteSeller ? '' : 'black-link cursor-pointer'
          }`}
        onClick={handleClick}
      >
        <div className="fs20">
          <i className={isFavoriteSeller ? 'icon-like text-danger' : 'icon-heart-addToList'} />
        </div>
        <div className="tw-mr-4 hidden-xs hidden-sm">
          {isFavoriteSeller ? (
            <Link to={`/discover/users/${user.username}/shops`} className="black-link">
              In Shops
            </Link>
          ) : (
            'Add to Favorites'
          )}
        </div>
      </div>

      {open && (
        <AddToFavoritesModal
          open={open}
          onCloseModal={() => setOpen(false)}
          publicSeller={props.seller}
          platform={props.platform}
          setIsFavoriteSeller={() => setIsFavoriteSeller(true)}
          category={props.sellerCategory}
        />
      )}
    </>
  )
}

export default AddToFavorites
