import React, { useEffect, useState, useRef } from "react";
import { useEnter } from '@hooks/useKeyCode'
import { Modal } from 'react-bootstrap'
import { Default, Mobile } from '@components/common/Responsive'
import qs from "qs";
import { useRouter } from '@hooks/useRouter'
import { openLoginModal } from '@hooks/useLoginModal'
import { images } from './json'
import './styles.css'
import { useWelcomeCoupon } from '@hooks/useWelcomeCoupon'
import ChinaOnly from '@components/common/ChinaOnly'
import { formatCurrency, formatCouponOptions } from '@helpers/currencyHelper'

const HomePageSignUpModal = (props) => {
  const { history } = useRouter()
  const [email, setEmail] = useState(null)
  const [error, setError] = useState(null)
  const [index, setIndex] = useState(null)
  const { welcomeCoupon: { discount, minOrder, currencyLocale, currency } } = useWelcomeCoupon()

  useEffect(() => setIndex(Math.floor(Math.random() * images.desktop_domestic.length)), [])

  const emailRef = useRef(null)

  const onSubmit = () => {
    if (!email?.trim()) {
      return setError('blank')
    }

    if (emailRef.current.validity.typeMismatch) {
      return setError('wrong_format')
    }

    return history.push({
      pathname: "/signup",
      search: qs.stringify({ email }),
    })
  }

  const onSignIn = () => {
    props.onClose()
    openLoginModal()
  }

  const enter = useEnter()
  const btnRef = useRef()

  if (enter && btnRef.current) {
    btnRef.current.click()
  }

  const renderForm = (
    <>
      <h1 className="bold">
        Take <span className="text-orange">{formatCurrency(discount, currencyLocale, currency, formatCouponOptions)}</span> off your first order
      </h1>

      <div className="input-group" styleName="form">
        <input
          className="fs12 form-control"
          placeholder="Email address"
          ref={emailRef}
          required={true}
          type='email'
          onChange={(e) => {
            setError(null)
            setEmail(e.target.value)
          }}
        />
        <span className="input-group-addon strong fs12 bd-none" onClick={onSubmit} ref={btnRef}>
          Claim
        </span>
      </div>
      {error === 'blank' && <div className="text-danger fs12 tw-mt-2">Email can't be blank</div>}
      {error === 'wrong_format' && <div className="text-danger fs12 tw-mt-2">Please include an '@' in the email address.</div>}

      <div className="tw-mt-4">
        <span className="text-grey">Already a Baopals shopper?</span> <span className="blue-link bold" onClick={onSignIn}>Sign in</span>
      </div>

      <div className="fs12 text-grey" styleName="tips">
        *First-time customers only, on order over <span className="bold">{formatCurrency(minOrder, currencyLocale, currency)}</span>
      </div>
    </>
  )

  const renderDefaultImage = (url: string) => <div styleName="left" style={{ backgroundImage: `url("${url}")` }} />
  const renderMobileImage = (url: string) => (
    <div styleName="top" style={{ backgroundImage: `url("${url}")` }}>
      <Modal.Header closeButton={true} />
    </div>
  )

  return (
    <Modal
      bsSize="sm"
      show={props.open}
      onHide={props.onClose}
      styleName="modal"
    >
      <Modal.Body>
        <Default>
          <div className="d-flex tw-h-full">
            <ChinaOnly
              otherwiseRender={renderDefaultImage(images.desktop_global[index])}
            >
              {renderDefaultImage(images.desktop_domestic[index])}
            </ChinaOnly>
            <div styleName="right">
              <Modal.Header closeButton={true} />
              {renderForm}
            </div>
          </div>
        </Default>
        <Mobile>
          <div className="tw-h-full">
            <ChinaOnly
              otherwiseRender={renderMobileImage(images.mobile_global[index])}
            >
              {renderMobileImage(images.mobile_domestic[index])}
            </ChinaOnly>
            <div styleName="bottom">
              {renderForm}
            </div>
          </div>
        </Mobile>
      </Modal.Body>
    </Modal>
  )
}
export default HomePageSignUpModal
