import InnerMenu from '@components/common/InnerMenu'
import PriceWidget from '@components/search/PriceWidget'
import Sort from '@components/search/SortWidget'
import React from 'react'
import KeywordsFilter from './KeywordsFilter'
import Location from './Location'
import './styles.css'

const Menu = props => (
  <InnerMenu {...props}>
    {({onChange, url, query}) => {
      return (
        <div className="fs12">
          <KeywordsFilter className="tw-mb-6" onChange={onChange} />
          <Location onChange={onChange} />
          <div className="tw-mb-2">Price Filter:</div>
          <PriceWidget styleName="price" onChange={onChange} />
          <div styleName="sort" className="products-sorting tw-mt-6 tw-pt-2">
            Sort by:
            <Sort value={query.sort || 'popularity'} onChange={onChange} />
          </div>
        </div>
      )
    }}
  </InnerMenu>
)

export default Menu
