import TranslationText from '@components/common/TranslationText'
import { ocrProductImage } from '@services/'
import { useEffect, useState } from 'react'

const map = {}

export const OCRContent = ({ id, platform, image }) => {
  const [loading, setLoading] = useState(true)
  const [text, setText] = useState([])

  useEffect(
    () => {
      if (image) {
        setLoading(true)
        setText([])

        if (id) {
          const url = image.startsWith('http') ? image : `https:${image}`

          if (map[url]) {
            setText(map[url])
            setLoading(false)
          } else {
            ocrProductImage(id, platform, url)
              .toPromise()
              .then(texts => {
                setText(texts)
                map[url] = texts
                setLoading(false)
              })
          }
        } else {
          setLoading(false)
        }
      }
    },
    [image]
  )

  return (
    <div>
      {loading
        ? 'Loading...'
        : text.length > 0
          ? text.map((txt, idx) => (
              <div key={idx}>
                <p>
                  {txt.cn}
                  <span className="d-block">{txt.en}</span>
                </p>
              </div>
            ))
          : 'No content found.'}
    </div>
  )
}
