import ACTION from '@constants/products'
import { HttpError } from '@errors/'
import { Platform } from '@interfaces/platform'
import { SearchProductsQuery, SearchProductsResponse } from '@interfaces/products'
import { createAsyncAction, createStandardAction } from 'typesafe-actions'

interface ChangeSkuPayload {
  id: number
  option: any
}

export const resetProductQty = createStandardAction(ACTION.RESET_PRODUCT_QTY)<number>()

export const updateProductQty = createStandardAction(ACTION.UPDATE_PRODUCT_QTY)<{
  id: number
  qty: number
}>()
export const updateProductImage = createStandardAction(ACTION.UPDATE_PRODUCT_IMAGE)<{
  image: any
  thumbnails: any
}>()
export const selectProductSku = createStandardAction(ACTION.SELECT_PRODUCT_SKU)<ChangeSkuPayload>()
export const unselectProductSku = createStandardAction(ACTION.UNSELECT_PRODUCT_SKU)<
  ChangeSkuPayload
>()

export const fetchSingleProduct = createAsyncAction(
  ACTION.FETCH_SINGLE_PRODUCT_REQUEST,
  ACTION.FETCH_SINGLE_PRODUCT_SUCCESS,
  ACTION.FETCH_SINGLE_PRODUCT_FAILURE
)<
  {
    id: number
    platform?: Platform
    skus?: string
    skuId?: number
    zoneId?: number
    country?: string
  },
  { id: number; product: any },
  HttpError
>()

export const fetchSingleProductMeta = createStandardAction(
  ACTION.FETCH_SINGLE_PRODUCT_META_REQUEST
).map((id: number, sku: number) => {
  return {
    payload: { id, sku },
    meta: {
      lifecycle: {
        resolve: ACTION.FETCH_SINGLE_PRODUCT_META_SUCCESS,
        reject: ACTION.FETCH_SINGLE_PRODUCT_META_FAILURE,
      },
    },
  }
})

export const fetchSingleProductMetaSuccess = createStandardAction(
  ACTION.FETCH_SINGLE_PRODUCT_META_SUCCESS
)<{
  id: number
  meta: any
}>()
export const fetchSingleProductMetaFailure = createStandardAction(
  ACTION.FETCH_SINGLE_PRODUCT_META_FAILURE
)<HttpError>()
