import * as ACTION from '../constants/productProps'
import { LOCATION_CHANGE } from 'connected-react-router'
import queryString from 'qs'

const initialState = {
  status: null, // Pending: 0, Success: 1, Error: -1
  error: null,

  brands: [],
  props: [],

  selectedBrands: [],
  selectedProps: [],

  toggle: false,
}

export default function productProps(state = initialState, { type, payload = {} }) {
  if (!payload) return state
  const { status, error, ...data } = payload

  switch (type) {
    case LOCATION_CHANGE: {
      const result = { selectedBrands: [], selectedProps: [] }
      const query = queryString.parse(data.location.search, { ignoreQueryPrefix: true })

      if (query.brand) result.selectedBrands = query.brand.split(',').map(id => +id)
      if (query.prop) {
        const props = query.prop.split(';')
        result.selectedProps = props.map(prop => {
          const [id, value] = prop.split(':')
          return {
            id: +id,
            value: value.split(',').map(val => +val),
          }
        })
      }

      return {
        ...state,
        ...result,
      }
    }

    case ACTION.RESET_PROPS:
      return initialState

    case ACTION.TOGGLE_PROPS_SECTION:
      return {
        ...state,
        toggle: data.toggle,
      }

    case ACTION.FETCH_PRODUCT_BRANDS:
      return {
        ...state,
        brands: data.brands,
      }

    case ACTION.FETCH_PRODUCT_PROPS:
      return {
        ...state,
        status,
        error,
        ...data,
      }

    case ACTION.TRANSLATE_PRODUCT_PROPS: {
      return {
        ...state,
        props: data.props,
      }
    }

    case ACTION.UPDATE_SELECTED_BRANDS: {
      const { brand } = data
      const index = state.selectedBrands.indexOf(brand)

      if (index === -1) {
        return { ...state, selectedBrands: state.selectedBrands.concat([brand]) }
      } else {
        return {
          ...state,
          selectedBrands: state.selectedBrands
            .slice(0, index)
            .concat(state.selectedBrands.slice(index + 1)),
        }
      }
    }

    case ACTION.INIT_SELECTED_PROPS:
      return {
        ...state,
        selectedProps: data.selectedProps,
      }

    case ACTION.UPDATE_SELECTED_PROPS: {
      const { propId, propVal } = data

      const currentPropIndex = state.selectedProps.findIndex(prop => prop.id == propId)

      // Add new active prop
      if (currentPropIndex === -1) {
        const selectedProps = state.selectedProps.concat([{ id: propId, value: [propVal] }])
        return { ...state, selectedProps }
      } else {
        // Update values in current active prop
        const currentProp = state.selectedProps[currentPropIndex]

        const valueIndex = currentProp.value.findIndex(val => val == propVal)

        const newProp = { ...currentProp }
        // Add new prop value into current active prop
        if (valueIndex == -1) {
          const value = currentProp.value.concat([propVal])
          newProp.value = value
        } else {
          // Remove prop value from current active prop
          const value = currentProp.value
            .slice(0, valueIndex)
            .concat(currentProp.value.slice(valueIndex + 1))
          newProp.value = value
        }

        // Remove current prop when the value is empty
        if (!newProp.value.length) {
          return {
            ...state,
            selectedProps: state.selectedProps
              .slice(0, currentPropIndex)
              .concat(state.selectedProps.slice(currentPropIndex + 1)),
          }
        }

        const selectedProps = state.selectedProps
          .slice(0, currentPropIndex)
          .concat([newProp])
          .concat(state.selectedProps.slice(currentPropIndex + 1))
        return { ...state, selectedProps }
      }
    }

    case ACTION.UPDATE_SELECTED_BRANDS_AND_PROPS: {
      const { brands, props } = data
      return {
        ...state,
        selectedBrands: brands,
        selectedProps: props,
      }
    }

    default:
      return state
  }
}
