import useAuth from '@hooks/useAuth'
import useNotification from '@hooks/useNotification'
import React, {FC} from 'react'
import './styles.css'
import NotificationsTour from '@components/common/Tour/NotificationsTour'

interface NotificationProps {
  meta: {
    page: number
    totalpages: number
    unreadCount: number
  }
}

const DropdownTitle = () => {
  const {meta, loading} = useNotification()
  const {user} = useAuth()

  const showCount = user && meta.unreadCount > 0

  return (
    <>
      <div styleName="head" id="tour-notifications">
        <i className="icon-notifications1 tw-mr-2" />
        {showCount && <Count value={meta.unreadCount} />}
      </div>
      {!loading && <NotificationsTour width="400px" />}
    </>
  )
}

export default DropdownTitle

const Count = ({value}: {value: number}) => (
  <>
    {'('}
    <span className="bp-blue-text">{value}</span>
    {')'}
  </>
)

