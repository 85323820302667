import UnknownError from '../errors/UnkownError'
import CartItemIncompleteSkuIdError from '../errors/Cart/Item/IncompleteSkuError'
import CartItemInvalidSkuIdError from '../errors/Cart/Item/InvalidSkuIdError'
import CartItemAlreadyInCartError from '../errors/Cart/Item/AlreadyInCartError'
import CartItemQuantityMultipleError from '../errors/Cart/Item/QuantityMultipleError'
import CartItemUnavailableProductError from '../errors/Cart/Item/UnavailableProductError'
import CartItemBannedSellerError from '../errors/Cart/Item/BannedSellerError'
import CollectionUnavailableProductError from '../errors/Collection/UnavailableProductError'
import CartItemBannedProductError from '../errors/Cart/Item/BannedProductError'
import CouponInvalidError from '../errors/Cart/Coupon/InvalidCodeError'
import DeleteSupportedOrderError from '../errors/Order/DeleteSupportedOrderError'
import DeletePendingOrderError from '../errors/Order/DeletePendingOrderError'
import DeleteProcessedOrderError from '../errors/Order/DeleteProcessedOrderError'
import RequiresDescriptionError from '../errors/Support/RequiresDescriptionError'
import NotScheduledError from '../errors/Support/NotScheduledError'
import NotPaid from '../errors/Support/NotPaid'
import LessThen48Hours from '../errors/Support/LessThen48Hours'
import HasTrackingData from '../errors/Support/HasTrackingData'
import NotReceived from '../errors/Support/NotReceived'
import Received5Days from '../errors/Support/Received5Days'
import Received7Days from '../errors/Support/Received7Days'
import StandardError from '../errors/StandardError'

const MAPPING = {
  [0x1705]: CollectionUnavailableProductError,
  [0x1002]: CartItemIncompleteSkuIdError,
  [0x1004]: CartItemInvalidSkuIdError,
  [0x1005]: CartItemAlreadyInCartError,
  [0x1007]: CartItemQuantityMultipleError,
  [0x1008]: CartItemUnavailableProductError,
  [0x1009]: CartItemBannedSellerError,
  [0x100a]: CartItemBannedProductError,
  [0x1101]: CouponInvalidError,
  [0x1118]: DeleteSupportedOrderError,
  [0x1119]: DeletePendingOrderError,
  [0x1120]: DeleteProcessedOrderError,
  [0x3101]: RequiresDescriptionError,
  [0x1107]: NotPaid,
  [0x1108]: LessThen48Hours,
  [0x2502]: RequiresDescriptionError,
  [0x2503]: NotScheduledError,
  [0x2901]: HasTrackingData,
  [0x2903]: NotReceived,
  [0x2904]: Received5Days,
  [0x2905]: Received7Days,
}

export const map = error => {
  let exClass = MAPPING[error.code]
  if (!exClass) {
    exClass = UnknownError
  }
  return exClass
}
