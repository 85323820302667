import { useQuery } from '@hooks/useRouter'
import { isAllowedFileTypeForSearch } from '@utils/'
import React, { FC } from 'react'
import { Link, Route, Switch } from 'react-router-dom'
import Suggestions from './Suggestions'
import './styles.css'

const Image = `https://res.cloudinary.com/baopals/image/upload/v1573701719/404%20and%20no%20result/no-result.png`

interface Props {
  isTmallSeller?: boolean
  wrapperClass?: string
  alias?: string
}

const renderSearchTips = () => {
  return (
    <div className="tw-mt-2">
      <Link to="/help/3?ref=64">Click here</Link> for some handy search tips.
    </div>
  )
}

const Content: FC<Props> = props => {
  const { query, img, ...restQuery } = useQuery()
  const keyword = props.isTmallSeller ? query : restQuery.q

  const withImage = isAllowedFileTypeForSearch(keyword)

  return (
    <div className={props.wrapperClass}>
      <div styleName="wrapper">
        <img src={Image} />

        <div styleName="desc">
          <div className="flex-center" styleName="head">
            <span className="bodo tw-mr-6" styleName="title">
              Sorry pal
            </span>
            <span styleName="said">:(</span>
          </div>

          {!!img && (
            <div className="tw-my-8">
              <div styleName="search_image" style={{ backgroundImage: `url("${img}")` }} />

              <div className="tw-mt-6">
                We found 0 products for the image you uploaded.
                <br />
                Please try another image or view our{' '}
                <Link to="/help/cn-shopping?ref=tips-searching-products">handy search tips</Link>.
              </div>
            </div>
          )}

          {keyword || props.alias ? (
            <div className="tw-my-8">
              <Switch>
                <Route
                  path="/:path(seller|category)/:id"
                  render={prop => (
                    <>
                      We found 0 products for that search.{' '}
                      <Link to={`${prop.location.pathname}`}>Clear your search</Link>.
                      {renderSearchTips()}
                    </>
                  )}
                />
                <Route
                  path="*"
                  render={() => (
                    <>
                      <div>
                        We found 0 products for {props.alias || withImage ? 'this image' : keyword}
                      </div>
                      {!withImage && <Suggestions keyword={keyword} />}
                      {renderSearchTips()}
                    </>
                  )}
                />
              </Switch>
            </div>
          ) : (
            <Route
              path="/seller/:id"
              render={prop => (
                <div>
                  This seller appears to have closed or relocated their shop. Apologies for the
                  inconvenience!
                </div>
              )}
            />
          )}
        </div>
      </div>
    </div>
  )
}

Content.defaultProps = {
  wrapperClass: '',
  alias: '',
}

export default Content
