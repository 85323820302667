export const shallowCompare = (newObj, prevObj) => {
  for (let key in newObj) {
    if (newObj[key] !== prevObj[key]) return true
  }

  return false
}

export function formDataToObject(formData) {
  const form = formData instanceof FormData ? formData : new FormData()

  return Array.from(form.entries()).reduce(
    (old, pair) => ({
      ...old,
      [pair[0]]: pair[1],
    }),
    {}
  )
}
