import { WithAuth } from '@components/common/WithAuth'
import ROUTING from '@constants/url'
import useNotification from '@hooks/useNotification'
import React, { FC } from 'react'
import MobileNavItem from '../MobileNavItem'
import { Link } from "react-router-dom";

const NotificationNavItem: FC<{ active: boolean }> = props => {
  const { meta } = useNotification()
  return (
    <WithAuth onClick={props.onClick}>
      {onClick => (
        <MobileNavItem
          bubbleValue={meta && meta.unreadCount}
          icon="notifications1"
          link={ROUTING.NOTIFICATION}
          active={props.active}
          onClick={onClick}
          id="tour-notifications"
        />
      )}
    </WithAuth>
  )
}

export default NotificationNavItem
