import SearchQueries from '@components/common/SearchQueries'
import injectQuery from '@hoc/injectQuery'
import {RouteComponentProps} from '@interfaces/route'
import qs from 'qs'
import React, {useEffect} from 'react'
import {Checkbox} from 'react-bootstrap'
import Categories from './Categories'
import ReviewsWithPhotosFilter from './ReviewsWithPhotosFilter'
import Sortings from './Sortings'

interface MenuProps extends RouteComponentProps {
  onClose: () => void
}

class Menu extends React.Component<MenuProps> {
  public state = {
    date: '',
    isReviews: null,
    withPhoto: false,
    category: '',
  }

  public componentDidMount() {
    this.props.onClose.subscribe(() => {
      const {isReviews} = this.state
      const {category, date, pic_only, tab, q, ...rest} = this.props.query

      this.props.history.push({
        pathname: this.props.location.pathname,
        search: qs.stringify({
          ...rest,
          ...(isReviews && {
            tab: 'reviews',
            ...(q && {q}),
            ...(this.state.category && {category: this.state.category}),
            ...(this.state.date && {date: this.state.date}),
            ...{pic_only: this.state.withPhoto ? 1 : 0},
          }),
        }),
      })
    })
  }

  public render() {
    return (
      <div className="p-4">
        <SearchQueries>
          {({isReviews}) => {
            useEffect(
              () => {
                this.setState({isReviews})
              },
              [isReviews]
            )

            return this.props.renderViewFilter ? (
              <div className="tw-my-4">
                <div>View:</div>
                <Checkbox
                  className="tw-my-0"
                  checked={this.state.isReviews || false}
                  onChange={() => this.setState({isReviews: !this.state.isReviews})}
                >
                  All Reviews
                </Checkbox>
              </div>
            ) : null
          }}
        </SearchQueries>

        <div
          data-disabled={!this.state.isReviews}
          className={`${!this.state.isReviews ? 'opacity-3' : ''}`}
        >
          <div>Sort by:</div>
          <Sortings
            display="list"
            onChange={date => this.setState({date})}
            disabled={!this.state.isReviews}
          />

          <div className="tw-mb-2">Filter reviews:</div>
          <ReviewsWithPhotosFilter
            disabled={this.state.isReviews === false}
            onChange={withPhoto => this.setState({withPhoto})}
            onClick={e => e.preventDefault()}
          />
          <div className="tw-mb-2">Filter by category:</div>
          <Categories onChange={category => this.setState({category})} />
        </div>
      </div>
    )
  }
}

Menu.defaultProps = {
  renderViewFilter: true,
}

export default injectQuery(Menu)
