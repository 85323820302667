import { useQuery } from '@hooks/useRouter'
import React from 'react'
import { FormattedMessage } from 'react-intl'

const Location = () => {
  const { location } = useQuery()

  if (location) {
    return (
      <>
        {' in '}
        <span className="text-capitalize">
          <FormattedMessage id={`chinese_provinces.${location}`} defaultMessage={location} />
        </span>
      </>
    )
  }

  return null
}

export default Location
