import { Address, Zone } from '@interfaces/address'
import { capitalized } from '@utils/string'

export const findZonesByLastZoneId = (lastZoneId: number, zones: Zone[]): Zone[] => {
  const result = []

  const zone = zones.find(zone => zone.id === lastZoneId)

  while (zone && zone.parentId) {
    result.push(zone)
    return findZonesByLastZoneId(zone.parentId, zones).concat(result)
  }

  if (zone) {
    result.push(zone)
  }

  return result
}

const zonesToName = (zones: Zone[]) => zones.map(zone => zone.name)

export const zonesToString = (zones: Zone[], separator: string = ',') =>
  zonesToName(zones).join(`${separator} `)

export const formatAddressWithZones = (addr: Address, zones: Zone[], countries: any[]) => {
  if (addr.alias) {
    return addr.alias
  }

  const curCountry = countries.find(c => c.abbr === addr.country)
  const isShowZoneInfo = !!curCountry?.locationHierarchy.length

  return `${(isShowZoneInfo ? zonesToName(zones) : [])
    .map(capitalized)
    .concat([addr.street, addr.details])
    .filter(v => v)
    .join(', ')}`
}

export function getCompatibleAddress(address: string | Address): Partial<Address> {
  if (typeof address === 'string') {
    return { alias: address }
  }

  return address
}

export const calcAdditionalShippingFee = (id: number) => {
  switch (+id) {
    case 11101593: // tung chung
    case 1819374: // ma wan
      return [20, 150]

    case 1819968: // cheung chau
    case 1819894: // discovery bay
    case 1819333: // mui wo
    case 1819112: // peng chau
    case 1818684: // tai o
    case 1818540: // tong fuk
    case 1819072: // pui o
      return [40, 200]

    default:
      return [0, 0]
  }
}
