import {HttpStatus} from '@constants/httpStatus'
import { HttpError } from './HttpError'

export class ConflictError extends HttpError {
  public name = 'ConflictError'

  constructor(message?: string) {
    super(HttpStatus.CONFLICT)
    Object.setPrototypeOf(this, ConflictError.prototype)

    if (message) {
      this.message = message
    }
  }
}
