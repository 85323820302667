import ajax from '@api/ajax'
import { Item } from '@interfaces/order'
import {
  ExtraOpt,
  ReplyForm,
  Ticket,
  TicketForm,
  TicketHistory,
  TicketRequestType,
  TicketsRes,
  TicketStatus,
} from '@interfaces/ticket'
import { SELLER_PLATFORMS } from '@utils/'
import { formatAddressWithZones, getCompatibleAddress } from '@utils/address'
import { Observable } from 'rxjs'
import { map, pluck } from 'rxjs/operators'
import { formatImage, formatOrderStatus } from './order'

const TICKET_WITH_TRACKING_RELATED = [
  TicketRequestType.ITEM_NOT_RECEIVED,
  TicketRequestType.INCORRECT_TRACKING,
]

export const createTicket = (form: TicketForm) => {
  const formData = new FormData()

  formData.append('support_message[description]', form.description)

  if (form.files && form.files.length) {
    for (let i = 0; i < form.files.length; i++) {
      formData.append('support_message[attachments[][file]]', form.files[i])
    }
  }


  if (form.titleAlias) {
    formData.append(
      'support_case[title]', form.titleAlias
    )
  } else {

    formData.append(
      'support_case[title]',
      (() => {
        switch (form.requestType) {
          case TicketRequestType.NO_TRACKING:
            return 'Tracking Request'
          case TicketRequestType.RETURN:
          case TicketRequestType.WAREHOUSE_RETURN_REQUEST:
            return 'Return Request'
          case TicketRequestType.GENERAL_INQUIRY:
          case TicketRequestType.WAREHOUSE_GENERAL_INQUIRY:
            return 'General Inquiry'
          case TicketRequestType.INCORRECT_TRACKING:
            return 'Incorrect Tracking'
          case TicketRequestType.ITEM_NOT_RECEIVED:
            return 'Item Not Received'
          default:
            return 'Your Ticket'
        }
      })()
    )
  }

  formData.append('support_case[status]', TicketStatus.OPEN)
  formData.append('support_case[request_type]', form.requestType)

  if (form.country) {
    formData.append('support_case[country]', form.country)
  }

  return ajax({
    url: `/me/orders/items/${form.item}/support/cases`,
    method: 'POST',
    body: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }).pipe(
    pluck('response', 'data'),
    map(({ supportCase: { item, ...rest }, messages: history }) => ({
      ...rest,
      status: history[0].resolved === false ? TicketStatus.OPEN : TicketStatus.RESOLVED,
      createdAt: rest.updatedAt,
      itemId: item.id,
      itemImage: formatImage(item.imageUrl, item.image),
      history
    }))
  )
}

export const closeTicket = (id: number): Observable<number> =>
  ajax({
    url: `/me/orders/support/cases/${id}/close`,
    method: 'PATCH',
  }).pipe(map(() => id))

export const createTicketReply = (id: number, form: ReplyForm): Observable<TicketHistory> => {
  const formData = new FormData()

  formData.append('support_message[description]', form.message)

  if (form.files && form.files.length) {
    for (let i = 0; i < form.files.length; i++) {
      formData.append('support_message[attachments[][file]]', form.files[i])
    }
  }

  return ajax({
    url: `/me/orders/support/cases/${id}/messages`,
    method: 'POST',
    body: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }).pipe(pluck('response', 'data', 'message'))
}

export const fetchTickets = (
  isOpenOnly: boolean = false,
  per: number,
  page: number
): Observable<TicketsRes> =>
  ajax({
    url: `/me/orders/support/cases`,
    query: {
      ...(isOpenOnly && { filter: 'open' }),
      ...(per && { per }),
      ...(page && { page }),
    },
    method: 'GET',
  }).pipe(
    pluck('response'),
    map(({ data, meta }) => ({
      tickets: data.supportCases.map(({ item, ...c }) => ({
        ...c,
        status: c.resolved === false ? TicketStatus.OPEN : TicketStatus.RESOLVED,
        itemId: item.id,
        itemImage: formatImage(item.imageUrl, item.image),
      })),
      totalCount: meta.totalCount,
      totalPages: meta.totalPages,
    }))
  )

const formatItem = item => ({
  ...item,
  status: formatOrderStatus(item.status, item.inWarehouse),
  image: formatImage(item.imageUrl, item.image),
  sellerId: SELLER_PLATFORMS.includes(item.shopType) ? +item.sellerId : undefined,
  platform: item.shopType,
})

const getAvailableTicketTypes = (item, isChina, hasParcelTracking = false) => {
  if (!isChina) {
    if (item.inGlobalParcel) {
      if (hasParcelTracking) {
        return [TicketRequestType.ITEM_NOT_RECEIVED, TicketRequestType.WAREHOUSE_GENERAL_INQUIRY]
      }

      return [...TICKET_WITH_TRACKING_RELATED, TicketRequestType.WAREHOUSE_GENERAL_INQUIRY]
    }

    if (!item.inWarehouse) {
      return [TicketRequestType.NO_TRACKING, TicketRequestType.RETURN, TicketRequestType.GENERAL_INQUIRY]
    }

    if (item.inWarehouse) {
      return [TicketRequestType.WAREHOUSE_RETURN_REQUEST, TicketRequestType.WAREHOUSE_GENERAL_INQUIRY]
    }
  }

  return [
    TicketRequestType.NO_TRACKING,
    ...TICKET_WITH_TRACKING_RELATED,
    TicketRequestType.RETURN,
    TicketRequestType.GENERAL_INQUIRY,
  ]
}

export const fetchTicketHistory = (item: number, countries = []): Observable<{ history: Ticket[]; item: Item }> =>
  ajax({
    url: `/me/orders/items/${item}/support/messages`,
    method: 'GET',
  }).pipe(
    pluck('response', 'data'),
    map(({ orderItems, ticket: { address, ...ticket }, ...res }: any) => ({
      ticketTypes: getAvailableTicketTypes(orderItems[0], address.country === 'cn', ticket.isParcelTracking),
      ticket: {
        ...ticket,
        isChina: address.country === 'cn',
        attachments: address.country === 'cn' && orderItems[0]?.processed === true,
        address: {
          country: address.country,
          receiver: address.name,
          phone: address.phone,
          details: formatAddressWithZones(
            getCompatibleAddress(address.zoneId ? address : address.address || ''),
            address.zones, countries
          ),
        },
      },
      items: orderItems.map(formatItem),
      history: res.supportCases.map(t => ({
        ...t,
        status: t.resolved === false ? TicketStatus.OPEN : TicketStatus.RESOLVED,
        history: [...res.messages.filter(m => m.caseId === t.id)],
      })),
    }))
  )

export const createExtraInfoReply = (
  caseId: number,
  msgId: number,
  opt: ExtraOpt
): Observable<TicketHistory[]> =>
  ajax({
    url: `/me/orders/support/cases/${caseId}/messages/${msgId}/resolve`,
    method: 'POST',
    body: { ...opt },
  }).pipe(pluck('response', 'data', 'messages'))
