import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import './styles.css'
import { cloudObjectToURL } from '@utils/'
import ProgressiveSkeleton from '@components/common/ProgressiveSkeleton'

export default class Department extends Component {
  render() {
    const { department } = this.props
    const alt = department.name.replace(/\s/g, '-')
    const imgUrl =
      cloudObjectToURL(department.thumbnail, {
        size: '254x73',
        crop: 'fill',
        gravity: 'east',
        extension: 'jpg',
      }) || ''

    // Hack for being able to use CTRL + Click shortcut to open link
    function ctrlClick(e) {
      const evt = e ? e : window.event
      if (evt.ctrlKey) {
        const win = window.open(`/departments/${department.slug}`, '_blank')
        win.focus()
      }
    }

    return (
      <li>
        <NavLink onClick={ctrlClick} to={`/departments/${department.slug}`}>
          <span styleName="unit">{department.name}</span>
          <div className="slide-in-left" />
          <img alt={alt} src={imgUrl} />
        </NavLink>
      </li>
    )
  }
}
