import { Action, Data, Display, Notification } from '@interfaces/notification'
import React, { FC, memo } from 'react'
import { Element } from 'react-scroll'
import { Wrapper } from '../Accessories'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import CopyToClipboard from '@components/common/CopyToClipboard'
import { Desktop, MobileAndTablet } from '@components/common/Responsive'
import './styles.css'

interface Props {
  data: Data
  display: Display
  markAsRead: (notifications?: Notification[]) => void
  maxShowNotifications?: number
}

const List: FC<Props> = ({ display, maxShowNotifications, data, markAsRead }) => {
  if (!data && !data.notifications) {
    return null
  }

  const { notifications } = data
  const limitedNotifications = maxShowNotifications
    ? notifications.slice(0, maxShowNotifications)
    : notifications

  return (
    <React.Fragment>
      {limitedNotifications.map((notification: Notification) => {
        const id = `notification-${notification.id}`

        const styleNames = []
        if (display == Display.dropdown) {
          styleNames.push('dropdown')
        }
        if (display == Display.page) {
          styleNames.push('page')
        }
        if (notification.read == false) {
          styleNames.push('unread')
        }
        if (notification.action == Action.fortune_reward) {
          styleNames.push('reward')
        }

        const handleClick = notification.read ? null : () => markAsRead([notification])

        const renderNotification = (link) => {
          const renderText = () => {
            const text = (
              <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                {!!notification.extras?.fullText && display !== Display.dropdown ? notification.extras.fullText : notification.text}
              </ReactMarkdown>
            )

            if(!!notification.extras?.couponCode && display !== Display.dropdown) {
              return (
                <CopyToClipboard text={notification.extras.couponCode} renderCopied="Coupon Code Copied!">
                  {text}
                </CopyToClipboard>
              )
            }

            return text
          }

          return (
            <Wrapper
              icon={notification.thumbnail}
              createdAt={notification.createdAt}
              display={display}
              handleClick={handleClick}
              link={link}
              withImage={!!notification.extras?.avatars}
              users={notification.extras?.avatars?.map(avatar => ({ avatar }))}
            >
              {renderText()}
            </Wrapper>
          )
        }

        return (
          <Element key={id} name={id}>
            <div styleName={styleNames.join(' ')}>
              <Desktop>
                {renderNotification(notification.link)}
              </Desktop>
              <MobileAndTablet>
                {renderNotification(notification.extras?.mobileLink ?? notification.link)}
              </MobileAndTablet>
            </div>
          </Element>
        )
      })}
    </React.Fragment>
  )
}

export default memo(List)
