import AwesomeBurgerMenu from '@components/common/AwesomeBurgerMenu'
import { useState } from 'react'
import CategoryList from './CategoryList'
import './styles.css'

const AllCategoryToggler = ({ onClick, withoutJdCategories,  }) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <div
        className="strong tw-py-4 px-4"
        onClick={() => {
          onClick()
          setOpen(true)
        }}
      >
        <i className="icon-arrow-left fs12 tw-mr-2" />
        ALL CATEGORIES
      </div>
      {!withoutJdCategories && (
        <AwesomeBurgerMenu id="menu-express-categories" open={open} width={'100%'} styleName="menu">
          <CategoryList onClick={() => setOpen(false)} />
        </AwesomeBurgerMenu>
      )}
    </>
  )
}

export default AllCategoryToggler
