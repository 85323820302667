import { HttpStatus } from '@constants/httpStatus'
import { HttpError } from './HttpError'
import { map } from "../api/ErrorsMapping";
import UnknownError from "@errors/UnkownError";

export class UnprocessableEntityError extends HttpError {
  public name = 'UnprocessableEntityError'
  public error: any
  public message: string

  constructor(error?: any) {
    super(HttpStatus.UNPROCESSABLE_ENTITY)
    Object.setPrototypeOf(this, UnprocessableEntityError.prototype)

    const Err = map(error)

    const err = new Err(error)

    if (Err === UnknownError && err.code) {
      this.error = err.code
    } else if (error) {
      this.error = err
    }
  }
}
