import { WithAuth } from '@components/common/WithAuth'
import Cart from '@hoc/Cart'
import React from 'react'
import MobileNavItem from '../MobileNavItem'

interface ShoppingCartNavItemProps {
  active: boolean
}

const ShoppingCartNavItem: React.SFC<ShoppingCartNavItemProps> = props => {
  return (
    <WithAuth onClick={props.onClick}>
      {onClick => (
        <Cart>
          {({ totalCounts }) => {
            return (
              <MobileNavItem
                active={props.active}
                icon="cart"
                link="/checkout/baopals"
                bubbleValue={totalCounts}
                onClick={onClick}
              />
            )
          }}
        </Cart>
      )}
    </WithAuth>
  )
}

export default ShoppingCartNavItem
