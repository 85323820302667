import useAuth from '@hooks/useAuth'
import { useSelectedAddr } from '@hooks/useSelectedAddress'
import { useEffect } from 'react'

export const SelectedAddressHook = () => {
  const { user, address } = useAuth()
  const { updateSelectedAddress } = useSelectedAddr()

  const hasMultiRegions = new Set(address.map(c => c.country)).size > 1

  useEffect(() => {
    if (user && !hasMultiRegions) {
      updateSelectedAddress(user.defaultAddressId)
    }
  }, [user?.id])

  return null
}
