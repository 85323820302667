import { fetchUrlName } from '@services/other'
import { fetchNamedUrl } from 'actions/namedUrl'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from './useRouter'

export const useNamedUrl = ({ refetch } = { refetch: true }) => {
  const { location } = useLocation()
  const { pathname, search } = location

  const dispatch = useDispatch()

  const url = `${pathname}${search}`

  const state = useSelector(state => state.namedUrl)

  useEffect(
    () => {
      const sub = (() => {
        if (refetch && !pathname.includes('seller')) {
          dispatch(fetchNamedUrl.request())

          return fetchUrlName(url).subscribe(name => {
            dispatch(fetchNamedUrl.success(name))
          })
        }
      })()

      return () => sub?.unsubscribe?.()
    },
    [url]
  )

  return state
}
