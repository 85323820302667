import React from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { DropdownButton, MenuItem } from 'react-bootstrap'

const Dropdown = props => (
  <FormattedMessage
    id={`discover_review_sorting_heading_${props.value || 'undefined'}`}
    defaultMessage="MOST POPULAR"
  >
    {title => (
      <DropdownButton
        id={`discover-review-sorting`}
        title={title}
        className={`bd-none ${props.className} ${props.value ? 'text-orange' : ''}`}
      >
        {props.options.map((opt, i) => (
          <MenuItem
            key={i}
            eventKey={opt.value}
            active={opt.value === props.value}
            componentClass={Link}
            href={opt.url}
            to={opt.url}
          >
            <FormattedMessage id={`discover_review_sorting_${opt.value || ''}`} />
          </MenuItem>
        ))}
      </DropdownButton>
    )}
  </FormattedMessage>
)

Dropdown.defaultProps = {
  className: '',
}

export default Dropdown
