import React, {FC} from 'react'

interface Props {
  image: string
  className?: string
  styleName?: string
}

const PureImage: FC<Props> = props => (
  <div
    className={`bg-fit-center tw-w-full ${props.className || ''}`}
    style={{
      paddingBottom: '100%',
      backgroundImage: `url("${props.image}")`,
    }}
  />
)

export default PureImage
