import LineThroughText from '@components/common/LineThroughText'
import React from 'react'
import {Modal} from 'react-bootstrap'
import './styles.css'

const ReviewModal: React.FC<any> = props => (
  <Modal
    show={props.open}
    onHide={props.onCancel}
    bsSize="large"
    styleName="wrapper"
    backdrop="static"
  >
    <Modal.Header closeButton={true}>
      <Modal.Title>
        <LineThroughText className="tw-mt-12">
          <strong className="review-modal-heading fs20">
            {props.isEdit ? 'EDIT A REVIEW' : 'WRITE A REVIEW'}
          </strong>
        </LineThroughText>
      </Modal.Title>
    </Modal.Header>

    <Modal.Body>{props.loading ? <div>Loading...</div> : props.children}</Modal.Body>
  </Modal>
)

export default ReviewModal
