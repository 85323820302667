import React from 'react'

const FlexBetween: React.FC<any> = props => (
  <div className={`d-flex align-items-center justify-content-between ${props.className}`}>
    <div className="flex-between-left">{props.renderLeft}</div>
    <div className="flex-between-right">{props.renderRight}</div>
  </div>
)

FlexBetween.defaultProps = {
  className: '',
}

export default FlexBetween
