import React, {useState} from "react";
import {Collapse} from 'react-bootstrap'
import './styles.css'
import Body from "@components/orders/MyOrders/OrderPanel/Body";

const TextBox = ({error, forwardRef, id, label: l, placeholder, value, required, ...rest}) => {
  return (
    <div className={`form-group ${!!error ? 'has-error' : value ? 'success' : ''}`} styleName="wrapper">
      {l && (
        <label htmlFor={id} className="control-label">
          {l}
        </label>
      )}
      <div className="relative">
        <input
          className="form-control input-outline outline-0 form-error"
          placeholder={placeholder}
          required={required}
          value={value}
          {...forwardRef && {ref: forwardRef}}
          {...rest}
        />

        <span className="focus-border">
          <i />
        </span>
      </div>
      <Collapse in={!!error}>
        <div className="text-danger text-left fs12 tw-mt-2">{error}</div>
      </Collapse>
    </div>
  )
}

TextBox.defaultProps = {
  required: true,
}

export default TextBox
