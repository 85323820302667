import React from 'react'
import LangDropdown from '@components/layout/header/LangDropdown'
import Copyright from '@components/common/Copyright'
import Payments from './Payments'
import './styles.css'

const License = () => (
  <div className="bg-black bp-grey">
    <div className="container">
      <div className="flex-center-between tw-my-8 fs12">
        <div styleName='wrapper'>
          <Copyright />

          <Payments />
        </div>

        <div className='hidden-xs hidden-sm'>
          <LangDropdown />
        </div>
      </div>
    </div>
  </div>
)

export default License
