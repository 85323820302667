import React from 'react'
import './styles.css'

class UnstyledList extends React.PureComponent {
  render() {
    const classNames = []
    if (this.props.inline) {
      classNames.push('list-inline')
    }

    const styles = []

    if (this.props.separator) {
      styles.push('list')
    }

    return (
      <ul
        className={`${classNames.join(' ')} list-unstyled tw-mb-0 relative ${this.props.className}`}
        styleName={styles.join(' ')}
      >
        {[]
          .concat(this.props.children)
          .filter(e => e)
          .map((item, index) => (
            <li key={index} className={this.props.listClassName}>
              {item}
            </li>
          ))}
      </ul>
    )
  }
}

UnstyledList.defaultProps = {
  className: '',
  listClassName: '',
  separator: false,
}

export default UnstyledList
