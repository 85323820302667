import React from 'react'
import PropTypes from 'prop-types'
import FileUploader from '@components/common/FileUploader'
import './styles.css'

const PictureUploader = ({ pic, ...props }) => {
  const handleUpload = acceptedFiles => {
    if (acceptedFiles && acceptedFiles.length) {
      props.onUpload(acceptedFiles[0])
    }
  }
  const styleName = ['drop']

  if (props.uploading) {
    styleName.push('loading')
  }
  if (!pic) {
    styleName.push('empty')
  }
  if (props.hideShadow) {
    styleName.push('hideShadow')
  }

  return (
    <div
      styleName={styleName.join(' ')}
      className="bp-uploader"
      data-circle={props.circle}
      style={{ backgroundImage: `url("${pic || BAOPALS.IMAGE_PLACEHOLDER}")` }}
    >
      <FileUploader onUpload={handleUpload} multiple={false} placeholder={props.placeholder} />
    </div>
  )
}

PictureUploader.defaultProps = {
  hideShadow: false,
}

PictureUploader.propTypes = {
  hideShadow: PropTypes.bool,
}

export default PictureUploader
