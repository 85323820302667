import { Platform } from '@interfaces/platform'
import qs from 'qs'
import React from 'react'
import { Link } from 'react-router-dom'

interface SellerLinkProps {
  id: number

  iconClassName?: string
  withIcon?: boolean

  target?: '_blank' | '_self'
  className?: string
  platform: Platform

  query?: any
}

const SellerLink: React.FC<SellerLinkProps> = ({ id, platform, ...props }) => (
  <>
    {props.withIcon && (
      <>
        <i className={`icon-seller-shop text-grey ${props.iconClassName}`} />{' '}
      </>
    )}
    <Link
      target={props.target}
      to={`${[]
        .concat(platform === Platform.JD ? '/express/seller' : '/seller')
        .concat(id)
        .join('/')}${qs.stringify({ ...props.query }, { addQueryPrefix: true })}`}
      className={`${props.className || 'black-link'}`}
    >
      {props.children}
    </Link>
  </>
)

SellerLink.defaultProps = {
  className: '',
  iconClassName: '',
  withIcon: false,
  target: '_self',
  platform: Platform.TMALL,
}

export default SellerLink
