import * as ACTION from '../constants/collectionItems'
import { addToast } from './toasts'
//import * as service from '../services/collection'

export const deleteItem = id => ({
  type: ACTION.DELETE_COLLECTION_ITEM_REQUEST,
  payload: { id },
})
export const deleteItemSuccess = id => ({
  type: ACTION.DELETE_COLLECTION_ITEM_SUCCESS,
  payload: { id },
})
export const deleteItemFailed = error => ({
  type: ACTION.DELETE_COLLECTION_ITEM_FAILED,
  payload: { error },
})

export const updateItemsPosition = id => ({
  type: ACTION.UPDATE_COLLECTION_ITEMS_POSITION_REQUEST,
  payload: {
    id,
  },
  meta: {
    lifecycle: {
      resolve: ACTION.UPDATE_COLLECTION_ITEMS_POSITION_SUCCESS,
      reject: ACTION.UPDATE_COLLECTION_ITEMS_POSITION_FAILED,
    },
  },
})
export const updateItemsPositionSucess = () => ({
  type: ACTION.UPDATE_COLLECTION_ITEMS_POSITION_SUCCESS,
})
export const updateItemsPositionFailed = () => ({
  type: ACTION.UPDATE_COLLECTION_ITEMS_POSITION_FAILED,
})

export const toggleSelectAll = () => ({
  type: ACTION.TOGGLE_SELECTED_COLLECTION_ITEMS,
})

export const toggleSelectItem = (id, selected) => {
  return {
    type: ACTION.SELECT_COLLECTION_ITEM,
    payload: {
      id,
      selected,
    },
  }
}

export function fetchCollectionItemsSuccess(data) {
  return {
    type: ACTION.FETCH_COLLECTION_ITEMS_SUCCESS,
    payload: {
      data,
    },
    //payload: {
    //  //status: 1,
    //  //products: data.products,
    //  //total: data.total,
    //  //page: data.page,
    //  //itemsPerPage: data.itemsPerPage
    //}
  }
}

function fetchCollectionItemsFailed(error) {
  return {
    type: ACTION.FETCH_COLLECTION_ITEMS_FAILED,
    payload: {
      status: -1,
      error,
    },
  }
}

export const findCollectionItem = (id, items) => {
  const index = items.findIndex(item => item.id === id)

  return {
    index,
    item: items[index],
  }
}

export const moveCollectionItem = (id, index) => {
  return {
    type: ACTION.MOVE_COLLECTION_ITEM,
    payload: {
      id,
      index,
    },
  }
}

export const updateCollectionItem = (id, data) => ({
  type: ACTION.UPDATE_COLLECTION_ITEM_REQUEST,
  payload: {
    id,
    data,
  },
  meta: {
    lifecycle: {
      resolve: ACTION.UPDATE_COLLECTION_ITEM_SUCCESS,
      reject: ACTION.UPDATE_COLLECTION_ITEM_FAILED,
    },
  },
})
export const updateCollectionItemSuccess = data => ({
  type: ACTION.UPDATE_COLLECTION_ITEM_SUCCESS,
  payload: {
    data,
  },
})
export const updateCollectionItemFailed = error => ({
  type: ACTION.UPDATE_COLLECTION_ITEM_FAILED,
  payload: {
    error,
  },
})
