import React, { Component } from 'react'
import { PanelGroup, Panel } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { scroller } from 'react-scroll'
import qs from 'qs'

import ProductDescription from '../../Tabs/Description'
import ProductQAndA from '../../../ProductQAndA'
import MobileProperties from '../../Tabs/MobileProperties'
import Reviews from './Reviews'
import injectQuery from '@hoc/injectQuery'
import QA from './QA'
import './styles.css'
import DottedLine from '../../../../common/DottedLine'

class MobileOverview extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showInfoModal: false,
      activeKey: null,
    }
  }

  componentDidMount() {
    const {
      query: { tab },
    } = this.props

    if (tab == 'reviews') {
      // TODO
      //this.setState({ activeKey: 'review' }, () => this.handleScroll('review'))
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState({ activeKey: nextProps.query.tab || 'details' })
  }

  render() {
    const { product } = this.props
    const { showInfoModal, activeKey } = this.state

    return (
      <>
        <div id="product_reviews">
          <Reviews product={product} />
        </div>
        <div id="product_qa">
          <DottedLine className="tw-my-12" />
          <QA product={product} />
        </div>
        <div id="product_details">
          <DottedLine className="tw-my-12" />
          <ProductDescription
            shouldModalOpen={showInfoModal}
            onModalOpen={this.openInfoModal}
            onModalClose={this.closeInfoModal}
            allowFetchDesc={activeKey === 'details'}
            id={product.id}
            platform={product.platform}
            sold={product.sold}
            properties={product.properties}
          />
        </div>
      </>
    )
  }

  openInfoModal = () => {
    this.setState({ showInfoModal: true })
  }

  closeInfoModal = () => {
    this.setState({ showInfoModal: false })
  }

  handleSelect = tab => {
    if (tab) {
      this.props.history.push({
        pathname: this.props.location.pathname,
        search: qs.stringify({ ...this.props.query, tab }),
      })
      this.handleScroll()
    } else {
      this.setState({ activeKey: tab })
    }
  }

  handleScroll = () => {
    scroller.scrollTo(`accordion-product-overview-heading-reviews`, {
      duration: 0,
      delay: 0,
      smooth: true,
      offset: -250,
    })
  }
}

export default injectQuery(MobileOverview)
