import { sum } from "lodash";
import { confine } from "../numberUtils";

const WEIGHTS = {
  product: 0.5,
  service: 0.2,
  delivery: 0.3
};

const PROPS = Object.keys(WEIGHTS);

// score ∈ [0, 5]
const MIN_SCORE = 0;
const MAX_SCORE = 5;

/**
 *
 * returns a score for a given product ratings
 *
 * @param ratings (Product/Ratings)
 * @returns {number}
 */
export const baopalsScore = ratings => {
  if (ratings.shop === "tmall") {
    return confine(ratingsScore(ratings), MIN_SCORE, MAX_SCORE);
  } else {
    return confine(ratingsScore(ratings), MIN_SCORE, MAX_SCORE) == 5 &&
      ratings.sold < 250
      ? 4
      : confine(ratingsScore(ratings), MIN_SCORE, MAX_SCORE);
  }
};

// Private

const itemScore = (item, weight) => {
  return roundToHalf((parseFloat(item) - 3.9) * 5) * weight;
};

const ratingsScore = ratings => {
  return roundToHalf(
    sum(PROPS.map(property => itemScore(ratings[property], WEIGHTS[property])))
  );
};

// This is not in number utils because it's not a "real" round to half
// A "real" one should round and not floor
const roundToHalf = num => Math.floor(num * 2) / 2;
