import React from 'react'
import Label from '../SubMenu/Label'
import ROUTING from '@constants/url'
import { useReferals } from "@hooks/useReferrals";

const ReferralLabel = () => {
  const data = useReferals()

  return (
    <Label
      labelLink={ROUTING.ACCOUNT_REFERRALS}
      label={`refer a pal for ${data.amountWithCurrency}`}
    />
  )
}

export default ReferralLabel
