import React from 'react'
import { RESET } from 'jotai/utils'
import { useAtom } from 'jotai'
import { recentProductsAtom } from '@atoms/recent-products'
import ProductList from '@components/products/ProductList'
import { PageSection } from '../SearchInputPage/PageSection'
import ErrorBoundary from '@components/common/ErrorBoundary'

export const RecentView = () => {
  const [recentProducts, setRecentProducts] = useAtom(recentProductsAtom)

  return (
    <ErrorBoundary content="">
      {recentProducts.length > 0 ? (
        <PageSection
          heading="RECENTLY VIEWED"
          onAction={recentProducts.length > 0 ? () => setRecentProducts(RESET) : null}
        >
          <div className="tw-flex tw-flex-wrap">
            <ProductList products={recentProducts} />
          </div>
        </PageSection>
      ) : null}
    </ErrorBoundary>
  )
}
