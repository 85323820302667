import React, { useContext, useState, useEffect } from 'react'
import { CarouselContext, Slide } from 'pure-react-carousel'
import { cloudObjectToURL } from '@utils/cloudStorage'
import { DEFAULT_PRODUCT_PLACEHOLDER } from '@constants/index'

const SlideImage = ({ img, index }) => {
  const carouselContext = useContext(CarouselContext);
  const [currentSlide, setCurrentSlide] = useState(carouselContext.state.currentSlide);
  useEffect(() => {
    function onChange() {
      setCurrentSlide(carouselContext.state.currentSlide);
    }
    carouselContext.subscribe(onChange);
    return () => carouselContext.unsubscribe(onChange);
  }, [carouselContext]);

  return (
    <Slide index={img.id} key={index}>
      <img className="tw-h-full" src={currentSlide === index ? (cloudObjectToURL(img) || DEFAULT_PRODUCT_PLACEHOLDER) : null} />
    </Slide>
  )
}

export default SlideImage
