import * as ACTION from '@actions/wallet'
import AUTH_CONSTANTS from '@constants/auth'
import { Operation, Payment } from '@interfaces/wallet'
import { createReducer } from 'typesafe-actions'

export interface WalletState {
  readonly loading: boolean
  readonly wallet: {
    readonly balance: number
    readonly lastOperationAt?: string
  }
  readonly payments: Payment[]
  readonly operations: Operation[]
  readonly currentPage: number
  readonly totalPages: number
}

const initialState: WalletState = {
  loading: null,

  wallet: { balance: 0 },

  operations: [],

  payments: [], // for available withdraw

  totalPages: 0,
  currentPage: 1,
}

export const wallet = createReducer(initialState)
  .handleAction(AUTH_CONSTANTS.USER_SIGN_OUT, () => initialState)

  .handleAction(ACTION.updateWalletBalance, (state, action) => ({
    ...state,
    wallet: { ...state.wallet, balance: state.wallet.balance + action.payload },
  }))
  .handleAction(ACTION.fetchMyWallet, state => ({ ...initialState, loading: true }))
  .handleAction(ACTION.fetchMyWalletSuccess, (state, action) => {
    return {
      ...state,
      ...action.payload,
      loading: false,
    }
  })
  .handleAction(ACTION.refundPaymentSuccess, (state, { payload }) => {
    const payments = state.payments
    const index = payments.findIndex(p => p.id === payload.payment.id)

    return {
      ...state,
      wallet: { ...state.wallet, balance: state.wallet.balance - payload.refund.baseAmount },
      operations: [payload.operation].concat(state.operations),
      payments: [payload.payment].concat(
        payments
          .slice(0, index > -1 ? index : payments.length)
          .concat(index > -1 ? payments.slice(index + 1) : [])
      ),
    }
  })
