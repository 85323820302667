import React from 'react'
import { useWelcomeCoupon } from '@hooks/useWelcomeCoupon'
import './styles.css'
import { formatCurrency, formatCouponOptions } from '@helpers/currencyHelper'

const Discount = () => {
  const {
    welcomeCoupon: { discount, minOrder, currencyLocale, currency },
  } = useWelcomeCoupon()

  return (
    <div styleName="text">
      <span className="text-blue bold">
        {formatCurrency(discount, currencyLocale, currency, formatCouponOptions)} OFF
      </span>
      {` `}
      <span className="black-text">your first order over</span> {` `}
      <span className="text-blue bold">
        {formatCurrency(minOrder, currencyLocale, currency, formatCouponOptions)}
      </span>
    </div>
  )
}

export default Discount
