import * as Actions from '@actions/ticket'
import { Item } from '@interfaces/order'
import { PaymentStatus } from '@interfaces/payment'
import { Ticket, TicketStatus } from '@interfaces/ticket'
import { concat, findBy } from '@utils/array'
import update from 'immutability-helper'
import { createReducer } from 'typesafe-actions'

export interface TicketState {
  readonly tickets: Ticket[]
  readonly history: Ticket[]
  readonly items: Item[]
  readonly ticketTypes: any[]
  readonly totalCount: number
  readonly totalPages: number
  readonly ticket: any

  error: Error
}

const initialState: TicketState = {
  tickets: [],
  history: [],
  items: [],
  ticket: null,
  totalCount: 0,
  totalPages: 0,
  error: null,
  ticketTypes: []
}

export const ticket = createReducer(initialState)
  .handleAction(Actions.addTicketToList, (state, action) => {
    return state.tickets.findIndex(t => t.itemId === action.payload.ticket.itemId) === -1
      ? { ...state, tickets: [action.payload.ticket].concat(state.tickets) }
      : state
  })
  .handleAction(Actions.removeTicketFromList, (state, action) => {
    const index = state.tickets.findIndex(t => t.id === action.payload.id)

    return {
      ...state,
      tickets: state.tickets.slice(0, index).concat(state.tickets.slice(index + 1)),
    }
  })
  .handleAction(Actions.fetchTicketHistory, (state, action) => ({
    ...state,
    items: [],
  }))
  .handleAction(Actions.fetchTicketsSuccess, (state, action) => ({
    ...state,
    ...action.payload
  }))
  .handleAction(Actions.replyToTicketSuccess, (state, action) => {
    const { caseId } = action.payload.history

    const ticketIndex = state.tickets.findIndex(t => t.id === caseId)
    const historyIndex = state.history.findIndex(h => h.id === caseId)

    const ticket = state.tickets[ticketIndex]

    return {
      ...state,
      tickets: update(state.tickets, {
        $splice: [[ticketIndex, 1], [ticketIndex, 0, { ...ticket, updatedAt: new Date() }]],
      }),
      history: update(state.history, {
        $splice: [
          [historyIndex, 1],
          [
            historyIndex,
            0,
            {
              ...state.history[historyIndex],
              history: [action.payload.history].concat(state.history[historyIndex].history),
            },
          ],
        ],
      }),
    }
  })
  .handleAction(Actions.createTicketSuccess, (state, action) => {
    const index = state.tickets.findIndex((t: any) => t.itemId === action.payload.ticket.itemId)

    return {
      ...state,
      history: [{...action.payload.ticket, expanded: true }].concat(state.history),
      tickets: update(state.tickets, {
        $splice: [
          [index, index === -1 ? 0 : 1],
          [index === -1 ? 0 : index, 0, { ...state.tickets[index], ...action.payload.ticket }],
        ],
      }),
    }
  })
  .handleAction(Actions.closeTicketSuccess, (state, action) => {
    const historyIndex = state.history.findIndex(h => h.id === action.payload.id)
    const ticketIndex = state.tickets.findIndex(t => t.id === action.payload.id)

    const ticket = state.tickets[ticketIndex]

    const newHistory = update(state.history, {
      $splice: [
        [historyIndex, 1],
        [historyIndex, 0, { ...state.history[historyIndex], status: TicketStatus.RESOLVED }],
      ],
    })

    const newHistoryWithUpdatedPayment = newHistory.map(h => {
      const index = h.history.findIndex(h => h.caseId === action.payload.id)
      const curH = h.history[index]

      return {
        ...h,
        history: curH ? h.history.slice(0, index)
        .concat({
          ...curH,
          ...(curH.additionalInformation?.paymentRequest && {
            additionalInformation: {
              ...curH.additionalInformation,
              schedule: null,
              paymentRequest: { ...curH.additionalInformation.paymentRequest, status: PaymentStatus.PAID  }
            }
          }
         )
        }).concat(h.history.slice(index + 1)) : h.history
      }
    })

    return {
      ...state,
      tickets: state.tickets
        .slice(0, ticketIndex)
        .concat({ ...ticket, status: TicketStatus.RESOLVED })
        .concat(state.tickets.slice(ticketIndex + 1)),

      history: newHistoryWithUpdatedPayment,
    }
  })
  .handleAction(Actions.fetchTicketHistorySuccess, (state, action) => {
    return { ...state, history: action.payload.history, items: action.payload.items, ticketTypes: action.payload.ticketTypes, ticket: action.payload.ticket }
  })
  .handleAction(Actions.createExtraInfoReplySuccess, (state, action) => {
    // find case history
    const [caseHistory, caseIndex] =
      action.payload[0] && findBy(state.history, 'id', action.payload[0].caseId)

    // remove all additionalInformation from old data
    const messageHistorys = caseHistory.history.map(h => ({ ...h, additionalInformation: null }))

    // update
    const updatedMessageHistorys = [...messageHistorys, ...action.payload]
    const updatedCaseHistory = { ...caseHistory, history: updatedMessageHistorys, status: 'closed' }
    const updatedHistory = concat(state.history, updatedCaseHistory, caseIndex)

    return { ...state, history: updatedHistory }
  })
