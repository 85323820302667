import { Opt, ProductsRes, SellersRes } from '@interfaces/whatshot'
import { Observable, of } from 'rxjs'
import { catchError, map, pluck } from 'rxjs/operators'
import ajax from '../api/ajax'
// import data from './data.json'
// import seller from './seller.json'

export const fetchHotProducts = (opt: Opt): Observable<ProductsRes> =>
  ajax({ url: `/top_selling_products`, query: { ...opt } }).pipe(
    pluck('response'),
    map(({ data, meta }) => ({
      products: data.products.map(p => ({ ...p, platform: p.shopType })),
      blogArticles: data.blogArticles,
      globalParcels: data.globalParcels,
      reviews: data.reviews,
      sellers: data.sellers,
      departments: data.departments,
      ...meta,
    }))
  )

export const fetchHotSellers = (opt: Opt): Observable<SellersRes> =>
  ajax({ url: `/top_selling_sellers`, query: { ...opt } }).pipe(pluck('response'))

export const fetchHotCategories = (opt: Opt): Observable<SellersRes> =>
  ajax({ url: `/content_categories` }).pipe(pluck('response', 'data', 'categories'))
