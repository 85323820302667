import PageLayout from '@components/superMarket/SearchProducts/Layout/Layout'
import { FC, ReactNode } from 'react'
import Categories from './Categories'
import './styles.css'

const Layout: FC<{
  renderMain: ReactNode
  renderAside?: ReactNode
}> = ({ renderMain, ...props }) => {
  return (
    <div styleName="main">
      <PageLayout main={renderMain} aside={props.renderAside ?? <Categories />} />
    </div>
  )
}

export default Layout
