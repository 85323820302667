import { CommonProduct } from './product'
import { Image } from './image'
import { Meta } from './common'

// ccc
export enum CCCOrder {
  distinct_shuffle = 'distinct_shuffle', // 1 random of each target
  shuffle = 'shuffle', // random order
}
export enum CCCTarget {
  CHEAP = 'cheap',
  COOL = 'cool',
  CRAZY = 'crazy',
}

export enum SortType {
  shuffle = 'shuffle', // random order
}

// ccc opt
export interface CCCOpt {
  target?: CCCTarget
  page?: number
  per?: number
  order?: CCCOrder // undefined, fetch last created first
}

// daily deals & trending opt
export interface DTOpt {
  scheduled?: boolean
  sort?: SortType // undefined, last created first
  page?: number
  per?: number
}

interface Props {
  id: number
  productId: string
  createdAt: Date
  updatedAt: Date
  cover: Image
}

export interface CCC extends Props {
  description: string
  target: CCCTarget
  editionNumber: number
}
export interface Daily extends Props {
  scheduledOn: Date
}
export interface Trending extends Props {
  scheduledOn: Date
}

export type Opt = CCCOpt | DTOpt

export enum ContentType {
  ccc = 'cccs',
  daily = 'dailyDeals',
  trendings = 'trendings',
}

export interface Data {
  [ContentType.ccc]?: CCC[]
  [ContentType.daily]?: Daily[]
  [ContentType.trendings]?: Trending[]

  products: CommonProduct[]
}

export interface Respond {
  meta: Meta
  data: Data
}
