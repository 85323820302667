import { Cloudinary } from 'cloudinary-core'

/**
 *
 * transforms an image object into a proper url
 *
 * @param image
 * @param options
 * @returns {*}
 */
export const imageToUrl = (image, options = {}) => {
  if (!image || typeof image == 'string') return image

  switch (image.provider) {
    case 'taobao':
      return taobaoToUrl(image, options)
    case 'cloudinary':
      return cloudinaryToUrl(image, options)
    case 'aws_s3':
      return awsS3ToUrl(image, options)
    case 'local':
      return localToUrl(image, options)
  }
}

const cloudinaryToUrl = (obj, options = {}) => {
  const cloudName = obj.mimeType?.includes?.('video') ? `${obj.bucket}/video/upload` : obj.bucket

  const cl = Cloudinary.new({ cloud_name: cloudName })

  const url = cl.url(`${obj.folder}/${obj.id}`, {
    //fetchFormat: 'auto',
    quality: 60,
    format: obj.extension,
    secure: true,
    version: false,
    use_root_path: true,
    crop: 'scale',
    ...options,
  })

  return url
}

// Local

import { compact } from 'lodash'

const localToUrl = (image, options = {}) => {
  const host = __IS_DEV__ ? '' : options.host || `//${['admin', global.__HOST__].join('.')}`
  const { id, folder, extension, bucket } = image
  return `${__IS_DEV__ ? '/' : ''}${compact([host, bucket, folder, id]).join('/')}.${extension}`
}

const S3_AMAZON_AWS = 'baopals.com'

const awsS3ToUrl = image => {
  const { bucket, folder, id, extension } = image

  return `https://${bucket}.${S3_AMAZON_AWS}/${folder}/${id}.${extension}`
}

// Taobao

const adjustTaobaoSize = n => Math.ceil(n / 100) * 100
const appendSize = (url, width, height) => {
  if (!width || !height) {
    return url
  }

  const [adjWidth, adjHeight] = [width, height].map(adjustTaobaoSize)
  const suffix = [adjWidth, adjHeight].join('x')

  return `${url}_${suffix}`
}

export const CN_TAOBAO_CDN = 'img.alicdn.com'
export const GW_TAOBAO_CDN = 'gw.alicdn.com'

const prependHost = url => `https://${GW_TAOBAO_CDN}/${url}`

const taobaoToUrl = ({ id: url }, options = {}) => {
  let { width, height, size } = options
  if (size) {
    ;[width, height] = size.split('x')
  }

  url = appendSize(url, width, height)
  url = prependHost(url)

  return url
}
