import React, { useState } from 'react'
import AllCategoryToggler from '@components/product/v4/Layout/WithCategorySidebar/JDCategory/AllCategoryToggler'
import { Panel, PanelGroup } from 'react-bootstrap'
import './styles.css'
import { useQuery, useRouter } from '@hooks/useRouter'

const SellerCategoriesMobileMenu = (props) => {
  const { location, history } = useRouter()
  const query = useQuery()
  const { pathname } = location

  const [activeKey, setActiveKey] = useState(-1)

  const menuItems = props.sellerCategories.map((c) => (
    {
      id: c.externalId,
      category: c.titles?.en,
      active: +query.categoryId === +c.externalId,
      link: `${pathname}?categoryId=${c.externalId}`,
    }
  ))

  const closeMenu = () => props.onMenuStateChange({ isOpen: false })

  const onClick = (url) => {
    history.push(url)
    closeMenu()
  }

  const handleSelect = key => {
    if (key) {
      setActiveKey(key)
    } else {
      setActiveKey(-1)
    }
  }

  return (
    <>
      <AllCategoryToggler onClick={() => onClick(pathname)} withoutJdCategories  />
      <PanelGroup
        accordion={true}
        id="accordion-controlled-example"
        activeKey={activeKey}
        onSelect={handleSelect}
      >
        {menuItems.map(cat => (
          <Panel eventKey={cat.id} styleName="panel" className="tw-mt-0" data-id={cat.id}>
            <Panel.Heading className="strong">
              <Panel.Title toggle={true}>
                <div onClick={() => onClick(cat.link)} data-active={cat.active} styleName="item">
                  {cat.category}
                </div>
              </Panel.Title>
            </Panel.Heading>
          </Panel>
        ))}
      </PanelGroup>
    </>
  )
}

export default SellerCategoriesMobileMenu
