import * as ACTION from '@constants/tracking'

export const fetchTracking = (id, countries) => ({
  type: ACTION.FETCH_TRACKING_REQUEST,
  payload: { id, countries },
  meta: {
    lifecycle: {
      resolve: ACTION.FETCH_TRACKING_SUCCESS,
      reject: ACTION.FETCH_TRACKING_FAILED,
    },
  },
})
export const fetchTrackingSuccess = data => ({
  type: ACTION.FETCH_TRACKING_SUCCESS,
  payload: { data },
})
export const fetchTrackingFailed = error => ({
  type: ACTION.FETCH_TRACKING_FAILED,
  payload: { error },
})
