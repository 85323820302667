import ROUTING from '@constants/url'
import {useLocation} from '@hooks/useRouter'
import React from 'react'
import {MenuItem} from 'react-bootstrap'
import {FormattedMessage} from 'react-intl'
import './styles.css'

interface WrapperProps {
  meta: {
    page: number
    totalpages: number
    unreadCount: number
  }
  actions: any
  toggleDropdown: () => void
}

const InnerWrapper: React.SFC<WrapperProps> = props => {
  const {navigate} = useLocation()
  const handleSetting = () => navigate(ROUTING.NOTIFICATION_SETTINGS, {replace: true})
  const handleViewAll = () => navigate(ROUTING.NOTIFICATION, {replace: true})

  return (
    <>
      <MenuItem onClick={props.toggleDropdown}>
        <div className="flex-center-between bold upper fs12">
          <div
            styleName={props.meta.unreadCount > 0 ? 'link' : 'disabled'}
            onClick={() => props.actions.markAllAsRead()}
          >
            <FormattedMessage id="notifications_action.read_all" />
          </div>

          <div styleName="link" onClick={handleSetting}>
            <i className="icon-settings-security tw-mr-2" />
            <FormattedMessage id="notifications_action.setting" />
          </div>
        </div>
      </MenuItem>

      <div className="dropdown-notifications-list" onClick={props.toggleDropdown}>
        {props.children}
      </div>

      <MenuItem onClick={props.toggleDropdown}>
        <div className="bold upper fs12 text-center">
          <div styleName="link" onClick={handleViewAll}>
            <FormattedMessage id="notifications_action.view_all" />
          </div>
        </div>
      </MenuItem>
    </>
  )
}

export default InnerWrapper

