import AwesomeSortAndFilter from '@components/common/SortAndFilter'
import PriceWidget from '@components/search/PriceWidget'
import Sort from '@components/search/SortWidget'
import React, { FC, ReactNode } from 'react'
import { Route, Switch } from 'react-router-dom'
import KeywordsFilter from './KeywordsFilter'
import Location from './Location'
import Menu from './Menu'
import AddToFavorites from '@components/products/AddToFavorites'
import { Platform } from '@interfaces/platform'
import './styles.css'
import LayoutToggler from '@components/common/LayoutToggler'
import SellerCategoriesDropdown from '@components/products/SortAndFilters/SellerCategoriesDropdown'

/*
  Sorting is availble only when the seller type is Tmall
 */
const SortAndFilters: FC<{
  enableSorting?: boolean
  withLayoutToggler?: boolean
  innerClassName?: string
  menuClassName?: string
  platform?: Platform
  seller?: string
  isFavoriteSeller?: boolean
  loading?: boolean
  sellerCategory: string
  sellerCategories?: any[]
}> = ({ enableSorting, seller, platform, isFavoriteSeller, sellerCategories, ...props }) => {
  return (
    <>
      <div className={`${!seller && "bg-lightish bd-t-light bd-b-light"} tw-py-4 hidden-xs`}>
        <div className={`${props.innerClassName} products-sort-filter ${props.innerClassName ? 'px-4' : 'px-2'}`}>
          <div className="d-flex justify-content-between">
            {seller && !sellerCategories?.length && (
              <>
                <div style={{ width: '140px' }} />
              </>
            )}
            <div className="flex-center" styleName="inner">
              <div className="tw-mr-4 hide-empty">
                <KeywordsFilter
                  className={seller ? "products-filter-seller" : "products-filter-location"}
                  path={seller ? 'shop' : null}
                />
              </div>
              <Location />
              {!seller && (
                <PriceWidget className="tw-mr-12" styleName="price" />
              )}
              {enableSorting && <Sort />}
            </div>
            {seller &&
              props.loading ? (
                <div style={{ width: '140px' }} />
              ) : (
                <AddToFavorites
                  seller={seller}
                  platform={platform}
                  isFavoriteSeller={isFavoriteSeller}
                  sellerCategory={props.sellerCategory}
                />
              )}
          </div>
        </div>
      </div>

      <div className="visible-xs">
        <AwesomeSortAndFilter
          renderLeft={
            seller ? (
              <div className="d-flex">
                <KeywordsFilter path="shop" className="products-filter-seller" />
              </div>
            ) : (
              <div className="d-flex align-items-center">
                {props.withLayoutToggler && <LayoutToggler />}

                {seller &&
                !props.loading && (
                  <div className="tw-ml-2">
                    <AddToFavorites
                      seller={seller}
                      platform={platform}
                      isFavoriteSeller={isFavoriteSeller}
                    />
                  </div>
                )}
              </div>
            )
          }
          className="pt-4 px-4"
          renderMenu={Menu}
          menuClassName={`products ${!enableSorting ? 'sort-disabled' : ''} ${props.menuClassName}`}
        >
          <Switch>
            <Route path="/:path(express|search)">FILTER</Route>
            <Route path="/seller">
              <div className="d-flex align-items-center">
                {props.withLayoutToggler && <LayoutToggler />}

                {!props.loading && (
                  <div className="tw-ml-2">
                    <AddToFavorites
                      seller={seller}
                      platform={platform}
                      isFavoriteSeller={isFavoriteSeller}
                    />
                  </div>
                )}
              </div>
            </Route>
            <Route path="*">SEARCH & FILTER</Route>
          </Switch>
        </AwesomeSortAndFilter>
      </div>
    </>
  )
}

SortAndFilters.defaultProps = {
  enableSorting: false,
  menuClassName: '',
}

export default SortAndFilters
