import { SearchSuggestion } from '@interfaces/suggestion'
import { useLocation } from '@hooks/useRouter'
import { useCombobox } from 'downshift'
import { isProductUrl } from '@utils/taobaoUtils'
import React, { FC, useState, useEffect } from 'react'
import { SuggestionItem } from './SuggestionItem'
import ImageUploader from '../ImageUploader'
import { Desktop, MobileAndTablet } from '@components/common/Responsive'
import { useSuggestions } from '@hooks/useSuggestions'
import ProgressiveSkeleton from '@components/common/ProgressiveSkeleton'
import './styles.css'

interface TextInputWithDropdownProps {
  translate: boolean

  value?: string

  onEnter: (suggestion: SearchSuggestion) => void
}

export const handleEnter = (inputValue, onEnter) => {
  const { id, platform } = isProductUrl(inputValue)

  const imageUrl = (() => {
    try {
      const url = new URL(inputValue)

      if (url.origin.includes('alicdn')) {
        const pathname = url.pathname
        const pngIndex = pathname.indexOf('.png')
        const jpgIndex = pathname.indexOf('.jpg')

        const extIndex = pngIndex > -1 ? pngIndex : jpgIndex

        if (extIndex > -1) {
          return url.href
        }
      }
    } catch (err) { }
  })()

  if (inputValue) {
    onEnter({ id: -1, image: imageUrl, term: id || encodeURIComponent(inputValue), type: id ? 'products' : 'search', provider: platform })
  }
}

const TextInputWithDropdown: FC<TextInputWithDropdownProps> = props => {

  const { navigate } = useLocation()

  return (
    <ImageUploader>
      {
        ({ renderUploader, onResetError }) => {

          return (
            <>
              <Desktop>
                <Inner {...props} onInputFocus={onResetError}>
                  {renderUploader}
                </Inner>
              </Desktop>
              <MobileAndTablet>
                <div
                  className="search-input-wrapper clearfix"
                  id="tour-search-input-step-1"
                  style={{
                    position: "relative",
                    height: "100%"
                  }}
                  onClick={e => {
                    if (e.target.classList.contains('icon-add-photo')) {
                      return
                    }

                    onResetError()
                    navigate(`/search_input?translate=${props.translate}`)
                  }}
                >
                  <input
                    type='search'
                    className="form-control tw-h-full underline-expand"
                    placeholder={'What are you looking for?'}
                  />
                  {renderUploader}
                </div>
              </MobileAndTablet>
            </>

          )

        }
      }
    </ImageUploader>
  )
}

const Inner: FC<TextInputWithDropdownProps> = props => {
  const [value, setValue] = useState(props.value ?? '')
  const [matchedSuggestion, setMatchedSuggestion] = useState(null)
  const { isLoading, isFetching, data, ...rest } = useSuggestions(value)
  const loadingData = [1,2,3,4,5,6].map((num) => ({ id: num, type: 'loading' }))
  const newData = isFetching ? loadingData : (data || [])

  useEffect(() => {
    setInputValue(props.value)
  }, [props.value])

  useEffect(() => {
    const matchedSuggestion = data?.find(d => d.title.toLowerCase() === value.toLowerCase() )
    setMatchedSuggestion(matchedSuggestion)
  }, [value, data])

  const {
    inputValue,
    isOpen: _isOpen,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    setHighlightedIndex,
    setInputValue
  } = useCombobox({
    initialInputValue: value,
    items: newData,
    onStateChange: (changes) => {
      if (changes.type === useCombobox.stateChangeTypes.InputKeyDownEnter) {
        if (matchedSuggestion) {
          props.onEnter(matchedSuggestion)
        } else {
          handleEnter(inputValue.trim().replace('%', ''), props.onEnter)
        }
        setInputValue(inputValue)
      }else if ( changes.type === useCombobox.stateChangeTypes.ItemClick) {
        if (changes.selectedItem) {
          props.onEnter(changes.selectedItem)
        }
      } else if (changes.type === useCombobox.stateChangeTypes.InputFocus) {
        props.onInputFocus()
      } else if ([
        useCombobox.stateChangeTypes.InputKeyDownArrowUp,
        useCombobox.stateChangeTypes.InputKeyDownArrowDown
      ].includes(changes.type)) {
        setHighlightedIndex(-1)
      }
    },
    // onSelectedItemChange: ({ selectedItem }) => {
    //   props.onEnter(selectedItem)
    //
    // },
    itemToString: (item) =>
      item?.title ?? '',

    onInputValueChange: ({ inputValue }) => {
      setValue(inputValue)
    },
  })

  const isOpen = _isOpen && value.length >=2 && newData.length > 0

  return (
    <div
      className="search-input-wrapper clearfix"
      id="tour-search-input-step-1"
      style={{
        position: "relative",
        height: "100%"
      }}
    >

      <input
        type='search'
        className="form-control tw-h-full underline-expand"
        placeholder={'What are you looking for?'}
        styleName="searchInput"
        {...getInputProps()}
        data-testid="combobox-input"
      />
      <i
        className="icon-search visible-md visible-lg black-link"
        onClick={() => {
          matchedSuggestion ? props.onEnter(matchedSuggestion) :
          handleEnter(inputValue.trim().replace('%', ''), props.onEnter)
        }}
      />
      <span className="focus-border" />
      {props.children}
      <Desktop>
        <ul
          {...getMenuProps()}
          styleName="wrapper"
          style={{  borderBottom: `${isOpen ? '1px solid rgb(204, 204, 204)' : 'none'}` }}
        >
          {isOpen &&
            newData.map((item, index) => (
              <>
              {item.type === 'loading' ? (
                <div key={index} className="tw-px-3 tw-pb-1">
                  <ProgressiveSkeleton width="100%" height={10} enableMoveAnimation={true} />
                </div>
              ) : (
                <li
                  key={item.id}
                  data-title={item.title}
                  data-alias={item.term}
                  className="cursor-pointer"
                  styleName="item"
                  style={{ backgroundColor: `${highlightedIndex === index ? '#e2e2e2' : 'transparent'}` }}
                  {...getItemProps({
                    item,
                    index,
                  })}
                >
                  <SuggestionItem item={item} />
                </li>
              )}
            </>
            ))}
        </ul>
      </Desktop>
    </div>
  )
}

TextInputWithDropdown.defaultProps = {
  value: '',
}

export default TextInputWithDropdown
