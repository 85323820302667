import React, {FC} from 'react'
import ProgressiveSkeleton from '@components/common/ProgressiveSkeleton/'

interface Props {
  number: number
}

const Loading: FC<Props> = ({number}) => (
  <div>
    {new Array(number).fill('0').map((_, index) => (
      <div key={index} className="tw-py-4 px-4 bd-light">
        <ProgressiveSkeleton
          width="100%"
          height="20px"
          enableMoveAnimation
          className="tw-mt-0 d-block"
        />
      </div>
    ))}
  </div>
)

export default Loading
