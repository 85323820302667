import { Meta } from '@interfaces/common'
import { ContentType, Data, Opt, Respond, SortType } from '@interfaces/content'
import { fetch, initData, initMeta } from '@utils/content'
import { useEffect, useState } from 'react'

// ccc or dailydeals or trending
// use content respond: {
//   meta
//   data: { array, products }
// }

export const useContent = (opt: Opt, type: ContentType) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [data, setData] = useState<Data>(initData(type))
  const [meta, setMeta] = useState<Meta>(initMeta)

  const fetchData = (opt: Opt) => {
    setLoading(true)

    return fetch(opt, type)
      .then((res: Respond) => {
        if (res) {
          setData(res.data)
          setMeta(res.meta)
          setLoading(false)
        }
      })
      .catch(() => {})
  }

  useEffect(() => {
    setLoading(true)
    fetchData(opt)
  }, Object.keys(opt))

  const rest = {
    ...opt,
    ...(opt.scheduled && {
      sort: SortType.shuffle,
    }),
  }

  const handleShuffle = (opt = {}) => fetchData({ ...rest, order: 'distinct_more', ...opt })

  return { loading, data, meta, handleShuffle }
}
