import qs from 'qs'
import React, { FC } from 'react'

const APP_ID = '1702208563396103'

interface FacebookShareButtonProps {
  url: string
  display: 'page' | 'iframe' | 'async' | 'popup' | 'touch'
  quote?: string
  hashtag?: string
  className?: string
}

const handleClick = ({ display, href, quote, hashtag }) => {
  const query = qs.stringify({
    app_id: APP_ID,
    display,
    href,
    redirect_uri: href,
    ...(quote && { quote }),
    ...(hashtag && { hashtag }),
  })

  const url = `https://www.facebook.com/dialog/share?${query}`

  const strWindowFeatures = 'menubar=no,location=no,resizable=yes,scrollbars=yes,status=yes'

  window.open(url, '', strWindowFeatures)
}

const FacebookShareButton: FC<FacebookShareButtonProps> = ({ display, url: href, ...props }) => {
  return (
    <div
      className="SocialMediaShareButton SocialMediaShareButton--facebook"
      onClick={() => handleClick({ display, href, quote: props.quote, hashtag: props.hashtag })}
    >
      {props.children}
    </div>
  )
}

FacebookShareButton.defaultProps = {
  className: '',
}

export default FacebookShareButton
