import * as ACTION from '../constants/myCollection'
import { addToast } from './toasts'
import * as service from '../services/collection'

// Delete Collection
export const deleteCollection = id => ({
  type: ACTION.DELETE_COLLECTION_REQUEST,
  payload: { id },
  meta: {
    lifecycle: {
      resolve: ACTION.DELETE_COLLECTION_SUCCESS,
      reject: ACTION.DELETE_COLLECTION_FAILED,
    },
  },
})
export const deleteCollectionSuccess = id => ({
  type: ACTION.DELETE_COLLECTION_SUCCESS,
  payload: { id },
})
export const deleteCollectionFailed = error => ({
  type: ACTION.DELETE_COLLECTION_FAILED,
  payload: { error },
})

// Create new collection
export const createCollection = data => ({
  type: ACTION.CREATE_COLLECTION_REQUEST,
  payload: { data },
  meta: {
    lifecycle: {
      resolve: ACTION.CREATE_COLLECTION_SUCCESS,
      reject: ACTION.CREATE_COLLECTION_FAILED,
    },
  },
})
export const createCollectionSuccess = data => ({
  type: ACTION.CREATE_COLLECTION_SUCCESS,
  payload: { data },
})
export const createCollectionFailed = error => ({
  type: ACTION.CREATE_COLLECTION_FAILED,
  payload: { error },
})

// Fetch my collection list
export const fetchMyCollections = product => ({
  type: ACTION.FETCH_MY_COLLECTIONS_REQUEST,
  payload: { product },
  meta: {
    lifecycle: {
      resolve: ACTION.FETCH_MY_COLLECTIONS_SUCCESS,
      reject: ACTION.FETCH_MY_COLLECTIONS_FAILED,
    },
  },
})
export const fetchMyCollectionsSuccess = collections => ({
  type: ACTION.FETCH_MY_COLLECTIONS_SUCCESS,
  payload: {
    collections,
  },
})
export const fetchMyCollectionsFailed = error => ({
  type: ACTION.FETCH_MY_COLLECTIONS_FAILED,
  payload: { error },
})

export const moveCollection = (id, index, type) => {
  return {
    type: ACTION.MOVE_COLLECTION,
    payload: {
      id,
      index,
      type,
    },
  }
}

export const findCollection = (id, list) => {
  const collection = list.filter(c => c.id === id)[0]

  return {
    collection,
    index: list.indexOf(collection),
  }
}

// Add product to collection
export const addProductToCollection = (id, product) => ({
  type: ACTION.ADD_PRODUCT_TO_COLLECTION_REQUEST,
  payload: {
    id,
    product,
  },
  meta: {
    lifecycle: {
      resolve: ACTION.ADD_PRODUCT_TO_COLLECTION_SUCCESS,
      reject: ACTION.ADD_PRODUCT_TO_COLLECTION_FAILED,
    },
  },
})
export const addProductToCollectionSuccess = (id, data) => ({
  type: ACTION.ADD_PRODUCT_TO_COLLECTION_SUCCESS,
  payload: {
    id,
    data,
  },
})
export const addProductToCollectionFailed = error => ({
  type: ACTION.ADD_PRODUCT_TO_COLLECTION_FAILED,
  payload: {
    error,
  },
})

// Update collection
export const updateCollection = (id, collection) => ({
  type: ACTION.UPDATE_COLLECTION_REQUEST,
  payload: {
    id,
    collection,
  },
  meta: {
    lifecycle: {
      resolve: ACTION.UPDATE_COLLECTION_SUCCESS,
      reject: ACTION.UPDATE_COLLECTION_FAILED,
    },
  },
})

export const updateCollectionSuccess = data => ({
  type: ACTION.UPDATE_COLLECTION_SUCCESS,
  payload: {
    data,
  },
})
export const updateCollectionFailed = error => ({
  type: ACTION.UPDATE_COLLECTION_FAILED,
  payload: {
    error,
  },
})

// Fetch single collection
export const fetchMyCollectionDetails = (username, slug) => ({
  type: ACTION.FETCH_MY_SINGLE_COLLECTION_REQUEST,
  payload: { username, slug },
  meta: {
    lifecycle: {
      resolve: ACTION.FETCH_MY_SINGLE_COLLECTION_SUCCESS,
      reject: ACTION.FETCH_MY_SINGLE_COLLECTION_FAILED,
    },
  },
})
export const fetchMyCollectionDetailsSuccess = data => ({
  type: ACTION.FETCH_MY_SINGLE_COLLECTION_SUCCESS,
  payload: { data },
})
export const fetchMyCollectionDetailsFailed = error => ({
  type: ACTION.FETCH_MY_SINGLE_COLLECTION_FAILED,
  payload: { error },
})

export const updateCollectionPosition = () => ({
  type: ACTION.UPDATE_COLLECTION_POSITION_REQUEST,
  meta: {
    lifecycle: {
      resolve: ACTION.UPDATE_COLLECTION_POSITION_SUCCESS,
      reject: ACTION.UPDATE_COLLECTION_POSITION_FAILED,
    },
  },
})
export const updateCollectionPositionSuccess = () => ({
  type: ACTION.UPDATE_COLLECTION_POSITION_SUCCESS,
})
export const updateCollectionPositionFailed = () => ({
  type: ACTION.UPDATE_COLLECTION_POSITION_FAILED,
})

export const fetchFavorites = (page, sort) => ({
  type: ACTION.FETCH_FAVORITES_REQUSET,
  payload: {
    page,
    sort,
  },
  meta: {
    lifecycle: {
      resolve: ACTION.FETCH_FAVORITES_SUCCESS,
      reject: ACTION.FETCH_FAVORITES_FAILED,
    },
  },
})
export const fetchFavoritesSuccess = (collections, users, meta) => ({
  type: ACTION.FETCH_FAVORITES_SUCCESS,
  payload: {
    users,
    collections,
    meta,
  },
})
export const fetchFavoritesFailed = error => ({
  type: ACTION.FETCH_FAVORITES_FAILED,
  payload: { error },
})
