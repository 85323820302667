import SearchInput from '@components/common/SearchInput'
import SearchQueries from '@components/common/SearchQueries'
import qs from 'qs'
import React, { FC } from 'react'

interface SearchProps {
  submitOnEnter?: boolean
  onChange?: (v: string) => void
}

const Search: FC<SearchProps> = ({ onChange, submitOnEnter }) => (
  <SearchQueries>
    {({ q }, { history, location, query }) => {
      const { date, ...rest } = query
      return (
        <SearchInput
          cleanable={true}
          value={q}
          onChange={onChange}
          onEnter={text =>
            submitOnEnter
              ? history.push({
                  pathname: location.pathname,
                  search: qs.stringify({
                    ...rest,
                    ...(text && { q: text }),
                  }),
                })
              : onChange && onChange(text)
          }
          placeholder="Search Baopals customer reviews"
        />
      )
    }}
  </SearchQueries>
)

Search.defaultProps = {
  submitOnEnter: false,
}

export default Search
