import { HttpStatus } from '@constants/httpStatus'
import { Auth, AuthUser } from '@interfaces/auth'
import { HttpError } from './HttpError'

export class UnauthorizedError extends HttpError {
  public name = 'UnauthorizedError'
  public auth: Auth
  public user: AuthUser
  public email: string

  constructor(message?: string, user?: AuthUser, auth?: Auth, email?: string) {
    super(HttpStatus.UNAUTHORIZED)
    Object.setPrototypeOf(this, UnauthorizedError.prototype)

    if (message) {
      this.message = message
    }

    if (user) {
      this.user = user
    }

    if (auth) {
      this.auth = auth
    }

    if (email) {
      this.email = email
    }
  }
}
