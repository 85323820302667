import { flatten, compact } from 'lodash'
const buildBaseUrl = (subdomain, segment) =>
  flatten(compact([`//${subdomain}.${global.__HOST__}`, segment])).join('/')

export const API_URL = __IS_DEV__
  ? '/api/v1'
  : buildBaseUrl(__IS_TEST__ ? 'test-api' : 'api', ['api', 'v1'])

export const AIRWALLEX_ENV = __IS_TEST__ || __IS_DEV__ ? 'demo' : 'prod'

export const ADD_TO_CART = 'ADD_TO_CART'

export const SYSTEM_UNAVAILABLE =
  "Sorry, we're unable to process your request. Please try again later."

export const BLOG_AFFILIATE_TK = 'FjgkY42iNvM'
export const MAX_NOTIFICATIONS_RENDER_NUM = 8

export const DEFAULT_COUNTRY_CODE = 'cn'
export const DEFAULT_CURRENCY = 'cny'
export const DEFAULT_CURRENCY_LOCALE = 'zh-CN'
export const DEFAULT_PRODUCT_PLACEHOLDER = 'https://res.cloudinary.com/baopals/image/upload/v1672279741/product/default_product_image.jpg'
export const EMPTY_REVIEW_IMAGE = 'https://res.cloudinary.com/baopals/image/upload/v1680152345/404%20and%20no%20result/empty_review.png'

export const WHITE_LIST = ['cn', 'hk', 'mo']

export const DEFAULT_HTTP_HEADERS = {
  'X-Key-Inflection': 'camel',
  'Key-Inflection': 'camel',
  'Content-Type': 'application/json',
}

const  bpInitObject = {}
export default bpInitObject
