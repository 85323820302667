import AnimatedDropdown from '@components/common/AnimatedDropdown'
import { useCountries } from '@hooks/useCountries'
import { FC } from 'react'
import { Nav } from 'react-bootstrap'
import { CurrencyItemProps } from './'
import CountrySelector from '@components/common/CountrySelector'
import CurrencySelector from "@components/common/CurrencySelector";
import './styles.css'
import { WHITE_LIST } from '@constants/index'

const Heading = ({ children, className }) => (
  <div className={`strong bd-b-dark tw-mb-2 tw-pb-2 ${className}`}>{children}</div>
)

const CurrencyDropdown: FC<CurrencyItemProps> = props => {
  const { countries, isLoading } = useCountries()
  if(isLoading) return null

  const country = countries.find(c => c.abbr === props.selectedCountry)

  return (
    <Nav pullRight={true}>
      <AnimatedDropdown
        className="fs14"
        title={`${props.selectedCurrency}/${country.alias}`}
        id="nav-dropdown-currency"
        renderMenuItem={
          <div className="p-4" styleName="dropdown">
            <Heading>Region</Heading>
            <div className="tw-pt-2">
              <CountrySelector
                selected={props.selectedCountry}
                onChange={(country) => props.onChange(
                  { locationCountry: country.alias, locale: 'en' },
                  { code: country.abbr }
                )}
                whiteList={WHITE_LIST}
              />
            </div>

            <Heading className="tw-mt-6">Currency</Heading>
            <div className="tw-pt-2">
              <CurrencySelector
                selected={props.selectedCurrency}
                disabled={props.disabled}
                onChange={(currency) =>
                  props.onChange(
                    { currency: currency.value, locale: 'en' }
                  ).then(user => props.onCurrencyChange(user.currency))
                }
                currencies={props.menuItems}
              />
            </div>
          </div>
        }
      />
    </Nav>
  )
}

export default CurrencyDropdown
