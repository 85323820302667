import React, {FC} from "react";
import '../styles.css'
import {useShowText} from '@hooks/useShowText'
import ClothingFitBar from "@components/common/ClothingFitBar";
import SizePurchased from "@components/common/SizePurchased";

interface Props {
  showCompleteDetails?: boolean
  showContentRows?: number
  content: string
  clothingFit?: string
  sku?: any
}

const MAX_ROWS = 10;

const getSkuId = (id: string) => {
  if(!id) return

  return {
    categoryId: id.split(":")[0],
    id: id.split(":")[1],
  }
}

const Content: FC<Props> = props => {
  const {handleToggle, showMore, expandToggle, ref} = useShowText(MAX_ROWS)

  return (
    <>
      <div
        className={
          props.showCompleteDetails ? (
            `${expandToggle ? '' : `line-max line-max-${MAX_ROWS}`}`
          ) : (
            `line-max line-max-${props.showContentRows}`
          )
        }
        styleName="break"
        ref={ref}
      >
        {props.content}
      </div>

      {props.showCompleteDetails && showMore && (
        <a href="#" onClick={handleToggle} className="d-block tw-mt-2">
          {expandToggle ? 'Collapse' : 'Read More'}
        </a>
      )}

      {props.sku?.id && props.showCompleteDetails &&
        <div styleName="size">
          <SizePurchased
            className='tw-mt-12 tw-mb-2 fs12'
            sizeOptions={[{
              titles: props.sku.option,
              id: +getSkuId(props.sku.id)?.id
            }]}
            skuId={props.sku.id}
            onChange={props.onChange}
            clickable={false}
          />
        </div>
      }

      {props.clothingFit && props.showCompleteDetails &&
        <ClothingFitBar
          className='tw-mt-4 tw-mb-2 fs12'
          clothingFit={props.clothingFit}
          clickable={false}
        />
      }

    </>
  )
}

export default Content
