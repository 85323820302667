const observableToPromise = store => next => {
  let pending = {}
  return action => {
    let ret = next(action)
    if (action.meta && action.meta.lifecycle) {
      ret = new Promise((resolve, reject) => {
        pending[action.meta.lifecycle.resolve] = resolve
        pending[action.meta.lifecycle.reject] = reject
      })
      next(action)
    }

    // Success/Error action is dispatched
    if (pending[action.type]) {
      const resolveOrReject = pending[action.type]
      delete pending[action.type]
      resolveOrReject(action)
    }
    return ret
  }
}

export default observableToPromise
