import React from 'react'
import PropTypes from 'prop-types'
import TranslationText from '@components/common/TranslationText'
import './styles.css'

const ProductActiveFilter = ({ id, text, onClick }) => {
  return (
    <div styleName="wrapper" onClick={() => onClick(id)} data-id={id}>
      {React.isValidElement(text) ? text : <TranslationText texts={{ zhCn: text }} />}
    </div>
  )
}

export default ProductActiveFilter
