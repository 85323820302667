import AwesomeBurgerMenu from '@components/common/AwesomeBurgerMenu'
import Modal from '@components/common/Modal'
import Nav from '@components/common/Nav'
import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import Inner from './Inner'
import './styles.css'

interface AddingCollectionPopupProps {
  item: { id: number; pic: string }
  onClose: () => void
  openStyle: 'modal' | 'menu'

  menuClassName?: string
  selectedCollectionId?: number

  onAdded: () => void
}

const AddingCollectionPopup: FC<AddingCollectionPopupProps> = ({ open, openStyle, ...props }) => {
  const inner = (
    <Inner
      openStyle={openStyle}
      item={props.item}
      onAdded={props.onAdded}
      selectedCollectionId={props.selectedCollectionId}
    />
  )

  return (
    <>
      {openStyle === 'modal' && (
        <Modal open={open} onCancel={props.onClose} title="ADD TO WISHLIST" styleName="modal">
          {inner}
        </Modal>
      )}

      {openStyle === 'menu' && (
        <AwesomeBurgerMenu
          overNavbar={true}
          noOverlay={true}
          position="right"
          open={open}
          width="100%"
          className={props.menuClassName}
        >
          <Nav
            className="bd-b-dark"
            onLeftClick={props.onClose}
            renderMiddle={
              <div className="fs20">
                <FormattedMessage id="collections.add" />
              </div>
            }
          />

          {inner}
        </AwesomeBurgerMenu>
      )}
    </>
  )
}

AddingCollectionPopup.defaultProps = {
  menuClassName: '',
}

export default AddingCollectionPopup
