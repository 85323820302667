import React, { useState, useEffect } from 'react'
import SearchHistory from './SearchHistory'
import qs from 'qs'
import TrendingSearches from './TrendingSearches'
import { RecentView } from '../RecentView'
import { SuggestionItem } from '../TextInputWithDropdown/SuggestionItem'
import { Redirect } from 'react-router-dom'
import { showPath } from '@routing/department/linkRoutes'
import { Input } from './Input'
import { Desktop, MobileAndTablet } from '@components/common/Responsive'
import { recentSearchAtom } from '@atoms/recent-search'
import { useAtom } from 'jotai'
import { uniqBy } from 'lodash'
import { useSuggestions } from '@hooks/useSuggestions'

export const SearchInputPage = props => {
  const [matchedSuggestion, setMatchedSuggestion] = useState(null)
  const [value, setValue] = useState('')
  const query = qs.parse(props.location.search, { ignoreQueryPrefix: true })
  const translate = query.translate !== 'false'
  const { data } = useSuggestions(value)

  const [recentSearch, setRecentSearch] = useAtom(recentSearchAtom)

  useEffect(() => {
    const matchedSuggestion = data.find(d => d.title.toLowerCase() === value.toLowerCase() )
    setMatchedSuggestion(matchedSuggestion)
  }, [value, data])

  const handleEnter = _suggestion => {
    const suggestion = { ..._suggestion, translate: _suggestion.translate || props.translate }
    setRecentSearch(uniqBy([suggestion, ...recentSearch], 'term').slice(0, 20))

    const url = showPath(suggestion)

    props.history.push(url)
  }

  return (
    <>
      <Desktop>
        <Redirect to="/404" />
      </Desktop>
      <MobileAndTablet>
        <section className="tw-h-full">
          <Input
            translate={translate}
            {...props}
            value={value}
            onChange={e => setValue(e.target.value)}
            onEnter={handleEnter}
            matchedSuggestion={matchedSuggestion}
          />

          {data.length > 0 ? (
            <div className="pt-4">
              {data.map((s, index) => (
                <div key={index} className="tw-my-8 tw-px-6" onClick={() => handleEnter(s)}>
                  <SuggestionItem item={s} />
                </div>
              ))}
            </div>
          ) : (
            <>
              <SearchHistory />
              <TrendingSearches />
              <RecentView />
            </>
          )}
        </section>
      </MobileAndTablet>
    </>
  )
}
