import qs from 'qs'
import { pick, compact as compactArray } from 'lodash'

import { compact } from '../../utils/objectUtils'
import { Platform } from '@interfaces/platform'
import { platformToQuery } from '@utils/platform'

export const showPath = (link, department) => {
  if (!link) return null

  const isArticle = !!link.slug

  if(isArticle) {
    return `/blog/articles/${link.slug}`
  }
  const isJd = link.provider === Platform.JD

  const isSearch = link.type === 'search'

  const path = [
    `/${
      isJd && ['products', 'seller', 'category', 'seller'].includes(link.type) ? 'express/' : ''
    }${link.type}`,
  ]
    .concat(isSearch ? [] : [link.term])
    .join('/')

  const platform = link.sellerType || link.provider

  const properties = {
    ...pick(link, ['minPrice', 'maxPrice', 'prop', 'sort', 'brandId', 'translate']),
    ...(department && { department: department.slug }),
    ...(isSearch &&
      [Platform.TMALL, Platform.JD].includes(platform) && {
        platform: platformToQuery(platform),
      }),
    ...(isSearch && !link.image && { q: link.term }),
    ...(isSearch && !link.image && { title: link.title }),
    ...(isSearch && link.image && { img: link.image }),
  }

  if (properties.prop) {
    properties.prop = Object.keys(properties.prop)
      .map(k => [k, properties.prop[k].join(',')].join(':'))
      .join(';')
  }

  const queryString = qs.stringify({
    ...compact({
      ...(properties || {}),
      ...(properties?.q && { q: decodeURIComponent(properties.q) }),
    }),
    ...(properties.translate === false && { translate: false }),
  })

  return compactArray([path, queryString]).join('?')
}
