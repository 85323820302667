import * as ACTIONS from '@actions/paymentConfig'
import { useActions } from '@hooks/useActions'
import { useMyCurrency } from '@hooks/useAuth'
import { useEffect } from 'react'

const UserCurrencyHook = () => {
  // const country = useMyCountry()
  const actions = useActions(ACTIONS)

  const currency = useMyCurrency()

  useEffect(
    () => {
      if (currency) {
        actions.updateDefaultCurrency(currency)
      }
    },
    [currency]
  )

  // useEffect(
  //  () => {
  //    const currency = getCurrencyByCountry(country.locationCountryCode, false)
  //    actions.updateDefaultCurrency(currency)
  //  },
  //  [country.locationCountryCode]
  // )

  return null
}

export default UserCurrencyHook
