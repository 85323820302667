import { BadRequestError } from '@errors/BadRequestError'
import useAuth from '@hooks/useAuth'
import { Platform } from '@interfaces/platform'
import {
  ActionRespond,
  FecthOpt,
  FetchRespond,
  MetaData,
  ReviewForm,
  SingleRespond,
  Sort,
} from '@interfaces/reviews'
import { ReviewTarget } from '@interfaces/reviews'
import * as service from '@services/newReview'
import {
  DataProps,
  OptProps,
  updateData,
  updateReviews,
  updateUsers,
  updateVote,
  updateVotes,
} from '@utils/useReviews'
import { useEffect, useState } from 'react'
import { BehaviorSubject, Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

const defaultMeta: MetaData = {
  currentPage: 0,
  totalCount: 0,
  totalPages: 0,
}

const defaultData: DataProps = {
  products: [],
  reviews: [],
  users: [],
}

// abort observable for the modal/menu was closed
const abort$ = new Subject<boolean>()
export const abort = () => abort$.next(true)

const errMsg = "Sorry, we're unable to process your request. Please try again later."

// update reviews counter for create review
export const reviewsCounterAdd = new BehaviorSubject({ add: false })

export const useReviews = (opt: OptProps = {}) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [data, setData] = useState<DataProps>(defaultData)
  const [meta, setMeta] = useState<MetaData>(defaultMeta)

  const fetchReview = (opt: OptProps) => {
    setLoading(true)

    fetch(opt)
      .then((res: FetchRespond) => {
        setData(opt.page > 1 ? updateData(data, res) : res.data)

        setMeta(res.meta)
        setLoading(false)
      })
      .catch(err => {
        console.error(err)
      })
  }

  const fetchSingleReview = (id: number) => {
    setLoading(true)

    fetchSingle(id)
      .then((res: SingleRespond) => {
        setData({
          reviews: [res.review],
          users: res.user ? [res.user] : [],
          products: [res.product],
        })
        setLoading(false)
      })
      .catch(() => { })
  }

  const fetchRandomReview = (nextOpt?: OptProps) =>
    fetchReview({ ...nextOpt, target: ReviewTarget.HOME_SHUFFLE })

  const { user } = useAuth()
  const { reviewId, ...restOpt } = opt
  const keyString = [].concat(Object.values(restOpt)).join(',')
  // reset reviews when trigger filter or user sign in/out
  useEffect(() => fetchReview(restOpt), [keyString, !!user])

  // fetch single review
  useEffect(() => reviewId && fetchSingleReview(reviewId), [reviewId])

  const createReview = (id: number, form: ReviewForm, platform: Platform) => {
    return create(id, form, platform)
      .then((res: ActionRespond) =>
        setData({
          ...data,
          reviews: [...data.reviews, res.review],
          users: [...data.users, res.user],
        })
      )
      .catch(e => {
        if (e instanceof BadRequestError && e.message) {
          return Promise.reject(e)
        }

        throw new Error(errMsg)
      })
  }

  const updateReview = (id: number, form: ReviewForm) =>
    update(id, form)
      .then((res: ActionRespond) =>
        setData({
          ...data,
          reviews: updateReviews(data.reviews, res.review),
          users: updateUsers(data.users, res.user),
        })
      )
      .catch(() => {
        throw new Error(errMsg)
      })

  const upvoteReview = (id: number) =>
    upvote(id)
      .then(() =>
        setData({
          ...data,
          reviews: updateVotes(data.reviews, id, true),
        })
      )
      .catch(console.error)

  const downvoteReview = (id: number) =>
    downvote(id)
      .then(() =>
        setData({
          ...data,
          reviews: updateVotes(data.reviews, id, false),
        })
      )
      .catch(e => {
        throw new Error(e.message)
      })

  return {
    loading,
    data,
    meta,
    fetchRandomReview,
    createReview,
    updateReview,
    upvote: upvoteReview,
    downvote: downvoteReview,
  }
}

export const useCurrentReview = (currentReview: any) => {
  const [review, setReview] = useState(currentReview)

  useEffect(
    () => {
      if (currentReview) {
        setReview({
          ...review,
          ...currentReview,
        })
      }
    },
    [currentReview]
  )

  const upvoteReview = (id: number) =>
    upvote(id)
      .then(() => setReview(updateVote(currentReview, true)))
      .catch(() => { })

  const downvoteReview = (id: number) =>
    downvote(id)
      .then(() => setReview(updateVote(currentReview, false)))
      .catch(() => { })

  const updateReview = (id: number, form: ReviewForm) =>
    update(id, form)
      .then((res: ActionRespond) => {
        setReview(res.review)
        return res.review
      })
      .catch(err => {
        throw new Error(errMsg)
      })

  const createReview = (id: number, form: ReviewForm, platform: Platform) => {
    return create(id, form, platform).then((res: ActionRespond) => {
      setReview(res.review)
      return res.review
    })
  }

  return {
    review,
    createReview,
    updateReview,
    upvote: upvoteReview,
    downvote: downvoteReview,
  }
}

const fetch = (opt?: FecthOpt) =>
  new Promise((resolve, reject) => {
    service.fetchProductReviews(opt).subscribe(
      (res: FetchRespond) => {
        resolve(res)
      },
      err => reject()
    )
  })

const fetchSingle = (id: number) =>
  new Promise((resolve, reject) => {
    service.fetchSingleReview(id).subscribe(
      (res: SingleRespond) => {
        resolve(res)
      },
      err => reject()
    )
  })

const create = (id: number, form: ReviewForm, platform: Platform) =>
  new Promise((resolve, reject) => {
    const sub = service
      .createReview(id, form, platform)
      .pipe(takeUntil(abort$))
      .subscribe(
        (res: ActionRespond) => {
          reviewsCounterAdd.next({ add: true })
          resolve(res)
        },
        err => reject(err)
      )
  })

const update = (id: number, form: ReviewForm) =>
  new Promise((resolve, reject) => {
    const sub = service
      .updateReview(id, form)
      .pipe(takeUntil(abort$))
      .subscribe((res: ActionRespond) => resolve(res), err => reject())
  })

export const upvote = (id: number) =>
  new Promise((resolve, reject) => {
    service.upvoteReview(id).subscribe(
      () => {
        resolve()
      },
      err => reject()
    )
  })

export const downvote = (id: number) =>
  new Promise((resolve, reject) => {
    service.downvoteReview(id).subscribe(
      () => {
        resolve()
      },
      err => reject(err)
    )
  })
