import UrlQuery from '@components/common/UrlQuery'
import { formatCurrency, formatPrice } from "@helpers/currencyHelper";
import {usePaymentConfig} from '@hooks/usePaymentConfig'
import {useLocation, useQuery} from '@hooks/useRouter'
import qs from 'qs'
import React, {FC, useEffect} from 'react'
import {useFormState} from 'react-use-form-state'
import './styles.css'

const Input = props => <input className="form-control fs12" min={0} {...props} />


const PriceWidget: FC<{className?: string; onChange?: (url: string) => void}> = props => {
  const {currency, currencyLocale, currencyRate} = usePaymentConfig()

  const {navigate, location} = useLocation()
  const {minPrice = '', maxPrice = '', ...q} = useQuery()

  const formatOpt = {rounding: true, inverse: false, exchangeRate: currencyRate}

  const minPriceWithRate = minPrice && +minPrice > 0 ? formatPrice(minPrice * 100, formatOpt) : ''
  const maxPriceWithRate = maxPrice && +maxPrice > +minPrice ? formatPrice(maxPrice * 100, formatOpt) : ''

  useEffect(() => {
    formState.setField('minPrice', minPriceWithRate);
    formState.setField('maxPrice', maxPriceWithRate);
  }, [minPrice, maxPrice])

  const handleChange = rest => {
    const url = `${location.pathname}?${qs.stringify({...q, ...rest, page: 1})}`
    props.onChange?.(url)
    return url
  }

  const [formState, {number}] = useFormState(
    {
      minPrice: minPriceWithRate,
      maxPrice: maxPriceWithRate,
    },
    {
      onChange(event, stateValues, nextStateValues) {
        if (props.onChange) {
          handleChange(nextStateValues)
        }
      },
    }
  )

  const values = formState.values || {}

  const disabled =
    (values.minPrice && values.maxPrice && +values.minPrice >= +values.maxPrice) ||
    (values.minPrice === minPriceWithRate && values.maxPrice === maxPriceWithRate)

  return (
    <div className={props.className || ''}>
      <div styleName="wrapper">
        <Input {...number('minPrice')} placeholder={`From ${formatCurrency(0.00, currencyLocale, currency)}`} />
        <div>-</div>
        <Input {...number('maxPrice')} placeholder={`To ${formatCurrency(0.00, currencyLocale, currency)}`} />
        <div className="tw-w-full text-right hidden-xs">
          <UrlQuery
            names={['minPrice', 'maxPrice']}
            values={{
              minPrice: formatPrice(values.minPrice * 100, {...formatOpt, inverse: true}),
              ...(values.maxPrice && {maxPrice: formatPrice(values.maxPrice * 100, {...formatOpt, inverse: true}), }),
              page: 1,
            }}
            disabled={disabled}
          >
            <i className={`icon-check-mark black-link ${disabled ? 'text-grey' : ''}`} />
          </UrlQuery>
        </div>
      </div>
    </div>
  )
}

PriceWidget.defaultProps = {
  className: '',
}

export default PriceWidget
