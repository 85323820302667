import { signUpModal$, closeSignUpModal } from '@hooks/useSignUpModal'
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import SignUpForm from '@components/auth/SignUp/SignUpForm'
import useAuth from '@hooks/useAuth'
import { useLocation } from '@hooks/useRouter'
import ROUTING from '@constants/url'
import './styles.css'

const SignUpModal = () => {
  const [state, setState] = useState({})
  const [copiedAuth, setCopiedAuth] = useState(null)
  const [copiedUser, setCopiedUser] = useState(null)

  const { getAuth } = useAuth()

  useEffect(() => {
    const { auth, user } = getAuth() || {}
    setCopiedAuth(auth)
    setCopiedUser(user)

    const sub = signUpModal$.subscribe(s => {
      setState(s)
    })

    return () => sub.unsubscribe()
  }, [])

  const { navigate } = useLocation()

  const handleSubmitted = ({ email }) => {
    navigate({
      pathname: ROUTING.ACCOUNT_VERIFICATION,
      state: { email },
    })

    closeSignUpModal()
  }

  const open = state.open

  return (
    <Modal
      bsSize="sm"
      show={open}
      backdrop="static"
      onHide={closeSignUpModal}
      className="login-modal-container"
      backdropClassName="login-modal-backdrop"
      styleName="modal"
    >
      <Modal.Body>
        <SignUpForm
          {...copiedUser && { ...copiedUser }}
          {...copiedAuth && { auth: copiedAuth }}
          onSubmitted={handleSubmitted}
          inModal={true}
        />

        <div styleName="close" onClick={closeSignUpModal}>
          <i className="icon-close-round" />
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default SignUpModal
