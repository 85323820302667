import ProductQAndA from '@components/product/ProductQAndA'
import { useProductQA } from '@hooks/useProductQA'
import React, { useState } from 'react'
import { Panel } from 'react-bootstrap'
import './styles.css'
import BtnWithAuthRequired from '@components/common/BtnWithAuthRequired'
import Guide from '@components/product/ProductQAndA/Guide'
import QuestionList from '@components/product/ProductQAndA/QuestionList'
import QaMenu from './QaMenu'
import QAndAForm from '@components/forms/QAndAForm/QAndAForm'

const QA = ({ product }) => {
  const [open, setOpen] = useState(false)
  const { createQA, loading, questions, users } = useProductQA(product.id, product.platform)
  const handleSubmit = ({ question }: { question: string }) =>
    createQA(product.id, question, product.platform).then(() => setOpen(false))

  return (
    <>
      <div className="tw-w-max mx-auto tw-my-12" onClick={() => setOpen(true)}>
        <BtnWithAuthRequired className="product-tab__button btn btn-white" >
          ASK A QUESTION
        </BtnWithAuthRequired>
      </div>
      <Guide />
      <div className="tw-px-4">
        <QuestionList questions={questions} users={users} />
      </div>
      {open && (
        <QaMenu open={open} onCancel={() => setOpen(false)}>
          <QAndAForm loading={loading} errors={[]} onSubmit={handleSubmit} />
        </QaMenu>
      )}
    </>
  )

  // return (
  //   <ProductQAndA
  //     loading={loading}
  //     questions={questions}
  //     users={users}
  //     onCreate={handleSubmit}
  //     productId={product.id}
  //     image={product.cover}
  //     title={product.titles?.en}
  //   />
  // )
}

export default QA
