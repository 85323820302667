import useAuth from '@hooks/useAuth'
import { openLoginModal } from '@hooks/useLoginModal'
import React, { FC, ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

interface UserAuthProps {
  renderBefore?: (fn: any) => React.ReactNode | React.ReactElement
  renderAfter: React.ReactNode
}

const UserAuth: FC<UserAuthProps> = props => {
  const { user } = useAuth()

  const renderBefore =
    typeof props.renderBefore === 'function'
      ? props.renderBefore({ openLoginModal })
      : props.renderBefore

  return user ? props.renderAfter : renderBefore
}

UserAuth.defaultProps = {
  renderBefore: (
    <Link className="bold" to="#" onClick={openLoginModal}>
      <FormattedMessage id="general.sign_in" />
    </Link>
  ),
  renderAfter: null,
}

export default UserAuth
