import * as ACTION from '../constants/trendingSearch'

const initialState = {
  status: null,
  error: null,

  data: null,
}

export default function trendingSearch(state = initialState, { type, payload = {} }) {
  if (!payload) return state
  const { status, error, data } = payload

  switch (type) {
    case ACTION.FETCH_TRENDING_SEARCH_PENDING:
      return {
        status: 0,
        data: null,
        error: null,
      }

    case ACTION.FETCH_TRENDING_SEARCH_SUCCESS:
      return {
        status: 1,
        data,
        error: null,
      }

    case ACTION.FETCH_TRENDING_SEARCH_FAILED:
      return {
        status: -1,
        data: null,
        error,
      }

    default:
      return state
  }
}
