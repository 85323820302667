import React from 'react'
import { Modal } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import './styles.css'

const QRCode = () => {
  return (
    <div styleName="qrcode">
      <img
        width={250}
        src="https://res.cloudinary.com/baopals/image/upload/f_auto/v1529644933/BAOPALS_QRCODE.jpg"
      />
    </div>
  )
}

const WechatFollowModal = ({ show, onHide }) => {
  return (
    <Modal bsSize="sm" show={show} onHide={onHide} styleName="modal">
      <Modal.Header className="tw-pb-0 tw-mt-2" closeButton>
        <h3 className="text-center bold linethrough tw-mt-12 tw-mb-0">WECHAT</h3>
      </Modal.Header>
      <Modal.Body className="tw-pt-4">
        <div className="tw-w-9/12 text-center mx-auto tw-mt-2 tw-mb-12 fs14">
          <FormattedMessage id="footer.wechat_follow" />
        </div>
        <QRCode />
      </Modal.Body>
    </Modal>
  )
}

export default WechatFollowModal
