import DottedLine from '@components/common/DottedLine'
import AsyncLoader from '@components/common/AsyncLoader'
import UserAuth from '@components/common/UserAuth'
import { Redirect, Route, Switch } from 'react-router-dom'
import { DiscoverUserNav } from '../DiscoverUserNav'
import UserProfileProvider from '@components/common/UserProfileProvider'
import UserProfile from '../Profile/UserProfile'

const AsyncLoadUserReviews = AsyncLoader({ loader: () => import('../Users/Reviews') })
const AsyncLoadUserWishlists = AsyncLoader({ loader: () => import('../Users/Wishlists') })
const AsyncLoadUserShops = AsyncLoader({ loader: () => import('../Users/FavoriteSellers') })

const DiscoverUsers = props => {
  const {
    match: {
      params: { username },
    },
  } = props

  return (
    <UserProfileProvider username={username}>
      <div>
        <DottedLine />
        <UserProfile username={username} />
        <div className="hidden-xs">
          <DottedLine />
        </div>
      </div>

      <div className="container md:tw-mt-8">
        <div>
          <div className="hidden-xs">
            <DiscoverUserNav username={username} />
          </div>
          <Switch>
            <Route path={`${props.match.path}/reviews`} component={AsyncLoadUserReviews} />
            <Route path={`${props.match.path}/shops`} component={AsyncLoadUserShops} />
            <Redirect
              exact
              path={`/discover/users/:username`}
              to={`/discover/users/:username/reviews`}
            />
            <UserAuth
              renderBefore={<Redirect to="/404" />}
              renderAfter={
                <Route path={`${props.match.path}/wishlists`} component={AsyncLoadUserWishlists} />
              }
            />
          </Switch>
        </div>
      </div>
    </UserProfileProvider>
  )
}

export default DiscoverUsers
