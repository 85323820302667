import React from 'react'

const FormInput = ({ placeholder, ...rest }: { placeholder: string }) => (
  <div className="form-group relative">
    <input
      className="form-control input-outline outline-0"
      autoCorrect="off"
      autoComplete="off"
      autoCapitalize="none"
      placeholder={placeholder}
      required={true}
      {...rest}
    />
    <span className="focus-border">
      <i />
    </span>
  </div>
)

export default FormInput
