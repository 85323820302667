import { Mobile } from '@components/common/Responsive'
import { Default } from '@components/common/Responsive'
import { ArticleData, Cover, ProductData } from '@interfaces/blog'
import { cloudObjectToURL } from '@utils/cloudStorage'
import { formatDate } from '@utils/dateFormat'
import { sampleSize } from 'lodash'
import qs from 'qs'
import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import ProductsSlider from './ProductsSlider'
import './styles.css'

interface DefaultProps {
  article: ArticleData
  products?: ProductData[]
  className?: string
  cat: string
  covers: Cover[]
}

const ArticleUnit: FC<DefaultProps> = ({ article, products, cat, ...props }) => {
  const categories = article.categories.filter(c => c.parentId || c.slug === 'the-ccc')
  const pathname = `/blog/articles/${article.slug}`
  const link = cat ? `${pathname}?${qs.stringify({ cat })}` : pathname
  const isCCC = !!article.categories.find(c => c.slug === 'the-ccc')

  const renderLink = category => (
    <Link
      styleName="category"
      className="d-block black-link"
      to={`/blog?cat=${category.slug}`}
      key={category.slug}
    >
      {category.title}
    </Link>
  )

  return (
    <div className={`relative ${props.className}`} styleName="wrapper">
      <Link styleName="image" to={link} className="d-block" data-ccc={isCCC}>
        <img src={cloudObjectToURL(article.cover, { size: '800x800', toJPG: true })} />
      </Link>
      <div styleName="content" data-ccc={isCCC}>
        <div className="d-flex justify-content-center">
          <Mobile>{sampleSize(categories, 1).map(renderLink)}</Mobile>
          <Default>{categories.map(renderLink)}</Default>
        </div>
        <Link styleName="title" className="black-link" to={link}>
          {article.title}
        </Link>
        <div styleName="date">{formatDate(article.createdAt)}</div>
        <div styleName="description">{article.description}</div>
        {products.length > 0 && (
          <div styleName="slider">
            <ProductsSlider
              products={products.map(p => ({
                ...p,
                cover: p.cover,
                authorToken: article.authorToken,
                articleId: article.id,
              }))}
            />
          </div>
        )}
      </div>
    </div>
  )
}

ArticleUnit.defaultProps = {
  products: [],
  className: '',
}

export default ArticleUnit
