import React from 'react'
import { useMyAddedCollections } from '@hooks/useMyCollections'
import injectMyCollections from '@hoc/injectMyCollections'
import Pluralize from '../../Pluralize'

const AddedList = ({ myCollections, ...props }) => {
  const ids = useMyAddedCollections()

  const list = myCollections.data ?? []

  const collections = ids.map(id => list.find(c => c.id === id)).filter(c => c)

  return (
    collections.length > 0 && (
      <>
        <div>
          This product has already been added to your <Pluralize showCount={false} count={collections.length} singular="collection" />:
        </div>
        {collections.map(c => (
          <div className="bold" key={c.id}>
            {c.name}
          </div>
        ))}
      </>
    )
  )
}

export default injectMyCollections(AddedList)
