import React, { Component } from 'react'
import PropTypes from 'prop-types'
import TranslationText from '@components/common/TranslationText'
import ProductReviews from './ProductReviews'

class Reviews extends Component {
  shouldComponentUpdate() {
    return false
  }

  render() {
    const { translatedTitle, originalTitle, product } = this.props

    return (
      <TranslationText texts={{ zhCn: originalTitle }}>
        {transl => (
          <>
            <ProductReviews
              id={this.props.productId}
              platform={this.props.platform}
              reviewId={this.props.reviewId}
              categories={this.props.categories}
              product={product}
            />
          </>
        )}
      </TranslationText>
    )
  }
}

Reviews.propTypes = {
  reviewId: PropTypes.number,
  productId: PropTypes.number.isRequired,
  translatedTitle: PropTypes.string,
  originalTitle: PropTypes.string.isRequired,
  productImage: PropTypes.string.isRequired,
}

export default Reviews
