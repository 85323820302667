import AwesomeBurgerMenu from '@components/common/AwesomeBurgerMenu'
import LineThroughText from '@components/common/LineThroughText'
import CancelReviewBtn from '@components/review/CancelReviewBtn'
import React from 'react'

const Menu: React.FC<any> = props =>
  props.open ? (
    <AwesomeBurgerMenu
      overNavbar={true}
      id="update-qa-menu"
      open={props.open}
      width="100%"
      position="right"
      onMenuStateChange={() => {}}
    >
      <div className="p-4">
        {props.loading ? (
          <div>Loading...</div>
        ) : (
          <>
            {props.children}
          </>
        )}

        <CancelReviewBtn onClick={props.onCancel} />
      </div>
    </AwesomeBurgerMenu>
  ) : null

export default Menu
