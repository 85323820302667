import React from 'react'
import './styles.css'

const Arrow: React.SFC<any> = props => {
  const {direction} = props
  const icon = <i className={`fs12 icon-arrow-${direction}`} />

  return (
    <div
      className="fs18 strong tw-h-full bg-fit-center relative"
      styleName="arrow"
      style={{backgroundImage: `url("${props.bgPic}")`}}
      data-direction={direction}
    >
      <div className="inner">
        {!props.loading && (
          <a className="black-link text-wrap" onClick={props.onClick}>
            {direction === 'left' && icon}
            <span className="tw-mx-2 hidden-xs">{props.text}</span>
            {direction === 'right' && icon}
          </a>
        )}
      </div>
    </div>
  )
}

export default Arrow
