import { useQuery } from '@hooks/useRouter'
import { useHotCategories } from '@hooks/useWhatsHot'
import React, { FC, useEffect, useState } from 'react'
import List from '../Sortings/List'

const Categories = (props) => {
  const { categories } = useHotCategories()
  const { category, ...query } = useQuery()

  const [selected, setSelected] = useState(category || '')

  const options = [{ value: '', label: 'Everything' }].concat(categories.filter(c => !c.parentId).map(c => ({ label: c.title, value: c.slug })))

  const handleChange = val => {
    props.onChange?.(val)
    setSelected(val)
  }

  return (
    <List name='category' options={options} value={selected} onChange={handleChange} />
  )
}

export default Categories
