import { HttpStatus } from '@constants/httpStatus'
import { HttpError } from '@errors/HttpError'

export class AccountAlreadyBindError extends HttpError {
  public name = 'AccountAlreadyBindError'

  constructor() {
    super(HttpStatus.BAD_REQUEST)
    Object.setPrototypeOf(this, AccountAlreadyBindError.prototype)
  }
}
