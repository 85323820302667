import AnimatedButton from '@components/common/AnimatedButton'
import React, {FC} from 'react'

interface Props {
  handleSubmit: () => void
}

const Submit: FC<Props> = props => (
  <div className="text-center tw-mt-12">
    <AnimatedButton className="btn btn-white strong" onClick={props.handleSubmit}>
      SAVE REVIEW
    </AnimatedButton>
  </div>
)

export default Submit
