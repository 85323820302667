export const resizeImage = (url: string, { width = 200, height = 200 }) =>
  `${url.replace(/^http:\/\//i, 'https://').replace(/\_\d+x\d+\.jpg/, '')}_${width}x${height}.jpg`

export const resetImageSize = (url: string) => url.replace(/\_\d+x\d+$/, '')

export const imageUrlToFile = (url: string): Promise<File> =>
  fetch(url.replace(/^http:\/\//i, 'https://'))
    .then(resp => resp.arrayBuffer())
    .then(buf => new File([buf], 'image.jpg', { type: 'image/jpeg' }))

export const isAllowedFileTypeForSearch = (name = '', encodedExt = '&#46;') => {
  const IMAGE_SUFFIC_FOR_SEARCH = ['jpg', 'jpeg', 'png']

  return IMAGE_SUFFIC_FOR_SEARCH.some(i =>
    decodeURIComponent(name.toLowerCase()).endsWith(`${encodedExt}${i}`)
  )
}
