import React, { FC } from 'react'
import { Modal } from 'react-bootstrap'
import AddToFavoritesForm from '../Form'

import './styles.css'

const AddToFavoritesModal: FC<{
  open: boolean
  onCloseModal: () => void
  setIsFavoriteSeller: () => void
  publicSeller?: string
  platform?: string
  sellerId?: number
  private?: boolean
  category?: string
  action?: string
  onUpdate?: any
  onDelete: any
}> = props => (
  <Modal show={props.open} onHide={props.onCloseModal} styleName="modal">
    <Modal.Header closeButton={true}>
      <Modal.Title>
        <div className="linethrough strong fs20 tw-mt-12">
          {props.action === 'edit' ? 'EDIT SHOP' : 'ADD SHOP'}
        </div>
      </Modal.Title>
    </Modal.Header>

    <Modal.Body>
      <AddToFavoritesForm
        publicSeller={props.publicSeller}
        platform={props.platform}
        onClose={props.onCloseModal}
        sellerId={props.sellerId}
        private={props.private}
        category={props.category}
        action={props.action}
        onUpdate={props.onUpdate}
        onDelete={props.onDelete}
        setIsFavoriteSeller={props.setIsFavoriteSeller}
      />
    </Modal.Body>
  </Modal>
)

export default AddToFavoritesModal
