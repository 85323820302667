import React from 'react'
import { Grid } from 'react-bootstrap'
import CCCUnit from './CCCUnit'
import { Data, CCCTarget } from '@interfaces/content'

interface Props {
  loading: boolean
  data: Data
  handleShuffle: () => void
}

const Targets = [CCCTarget.COOL, CCCTarget.CHEAP, CCCTarget.CRAZY]

const DeskCCC: React.SFC<Props> = ({ loading, data, handleShuffle }) => (
  <Grid>
    <section className="ccc" id="section-ccc">
      <div className="row">
        {Targets.map((c, index) => {
          const ccc = data && data.cccs.find(data => data.target == c)
          const product = ccc && data.products.find(p => p.id == ccc.productId)
          return (
            <CCCUnit
              key={index}
              loading={loading}
              type={c}
              ccc={ccc}
              product={product}
              handleShuffle={c == CCCTarget.CHEAP ? handleShuffle : null}
            />
          )
        })}
      </div>
    </section>
  </Grid>
)

export default DeskCCC
