import { Desktop, Mobile, Tablet } from '@components/common/Responsive'
import { useProductImage } from '@hooks/useProductImages'
import { Image } from '@interfaces/image'
import React, { ReactNode, useEffect, useMemo } from 'react'
import Carousel from './Carousel'
import Gallery from './Gallery'
import { Platform } from "@interfaces/platform";
import Seller from '@components/common/ProductCard/Seller'
import ImageSearch from './ImageSearch'
import './styles.css'
import { cloudObjectToURL } from '@utils/cloudStorage'

type Component = 'gallery' | 'carousel'

interface ProductImagesProps {
  thumbnails: Image[]
  selectedImage: Image
  onSelectImage: any
  tmallBadge?: boolean
  supermarketBadge?: boolean
  prettyUpImage?: boolean
  prettyUpImageIndex?: number
  platform: Platform
  sellerId: number

  renderOnMobile?: Component
  renderOnTablet?: Component
  renderOnDesktop?: Component

  className?: string
  showCamera?: boolean
}

const mapping: { [string]: ReactNode } = {
  gallery: Gallery,
  carousel: Carousel,
}

export const ProductImagesForProduct = ({ id, ...props }) => {
  const { thumbnails, selected: selectedImage, onChange } = useProductImage(id)

  return useMemo(() => {
    return (
      <ProductImages
        {...props}
        thumbnails={props.thumbnails || thumbnails}
        onSelectImage={props.onSelectImage || onChange}
        selectedImage={props.selectedImage || selectedImage}
      />
    )
  }, [id, selectedImage?.id])
}

const ProductImages: React.SFC<ProductImagesProps | any> = ({ prettyUpImageIndex, ...props }) => {
  const includedSellers = ['2216558867983'].includes(props.sellerId)
  const selectedImage = (() => {
    if (
      props.prettyUpImage &&
      props.selectedImage &&
      props.thumbnails.length > prettyUpImageIndex &&
      props.selectedImage.id === props.thumbnails[0].id &&
      !includedSellers
    ) {
      return props.thumbnails[prettyUpImageIndex]
    }

    return props.selectedImage
  })()

  const rest = {
    className: props.className,
    thumbnails: props.thumbnails.slice((props.prettyUpImage && !includedSellers) ? prettyUpImageIndex : 0, 5),
    selectedImage: selectedImage,
    onSelectImage: props.onSelectImage,
  }

  useEffect(() => {
    if (props.prettyUpImage && rest.selectedImage) {
      props.onSelectImage?.(rest.selectedImage)
    }
  }, [])

  const XS = mapping[props.renderOnMobile]
  const SM = mapping[props.renderOnTablet]
  const MD = mapping[props.renderOnDesktop]

  const children = props.children

  return (
    <>
      {/* Display badge only when it's tmall products */}
      <div styleName='wrapper' className='product-card-image-badge'>
        <Seller id={props.sellerId} platform={props.platform} />
      </div>

      { props.showCamera && selectedImage && (
        <div styleName="imageSearch" id="tour-product-image-search">
          <ImageSearch selectedImage={selectedImage} />
        </div>
      )}

      <Mobile>
        <XS {...rest}>{children}</XS>
      </Mobile>

      <Tablet>
        <SM {...rest}>{children}</SM>
      </Tablet>

      <Desktop>
        <MD {...rest}>{children}</MD>
      </Desktop>
    </>
  )
}

ProductImages.defaultProps = {
  tmallBadge: false,
  supermarketBadge: false,
  prettyUpImage: false,
  prettyUpImageIndex: 1,
  className: '',

  renderOnMobile: 'carousel',
  renderOnTablet: 'gallery',
  renderOnDesktop: 'gallery',
}

export default ProductImages
