import { curry } from 'lodash'

type Fn<T> = (param: T) => void

function isFn<T>(fn: any): fn is Fn<T> {
  return typeof fn === 'function'
}

function doMergeState<T>(fn: (params?: T) => void, old: T, updated: Fn<T> | T) {
  return fn({ ...old, ...(isFn(updated) ? updated(old) : updated) })
}

export const mergeState = curry(doMergeState)
