import useAuth from '@hooks/useAuth'
import React, { FC, useEffect, useState } from 'react'
import { openSignUpModal } from '@hooks/useSignUpModal'

const Btn = ({ onClick, children }) => {
  const { user } = useAuth()

  return <div onClick={!user ? openSignUpModal : onClick}>{children}</div>
}

export default Btn
