import DottedLine from '@components/common/DottedLine'
import ProgressiveSkeleton from '@components/common/ProgressiveSkeleton'
import React from 'react'

const Loading = () => (
  <>
    <div className="container tw-py-4">
      <ProgressiveSkeleton width="50%" height={14} enableMoveAnimation={true} className="tw-mt-0" />
    </div>

    <DottedLine />
  </>
)

export default Loading
