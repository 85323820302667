import React from 'react'
import { matchPath, Route, Switch } from 'react-router-dom'
import TrendingSearch from '@components/common/TrendingSearch'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { useTrendingKeywords } from '@hooks/useTrending'
import { Default, Mobile } from '@components/common/Responsive'
import { showPath } from '../../../../routing/department/linkRoutes'

const Trending = () => {
  const trending = []

  const { loading, keywords } = useTrendingKeywords()

  const all = [].concat(keywords)

  let count = 0
  all.map(s => {
    if (s['title'] == null) {
      return
    }
    count += parseInt(s['title'].length)
  })

  let avg = parseInt(count / all.length)

  let allowedMaxLen = 128
  let available = allowedMaxLen
  let average = allowedMaxLen / all.length

  all.map(s => {
    if (s['title'] == null) {
      return
    }
    if (s['title'].length < average) {
      available += average - s['title'].length
    }
  })

  let newAverage = available / all.length

  let newAll = []
  all.map(s => {
    if (s['title'] == null) {
      return
    }
    if (s['title'].length - 3 > newAverage) {
      // Truncate to allowed average - minus 1 char length for the dots
      newAll.push(s['title'].substring(0, newAverage - 1) + '...')
    } else {
      newAll.push(s['title'])
    }
  })

  const trendingList = newAll.length
    ? keywords.map((search, key) => {
      let title = ''
      return (
        <li key={key}>
          <Link
            to={showPath(search)}
            onClick={() => {
              document.title = search['title'] || ''
            }}
            className="black-link"
            title={search['title'] || ''}
          >
            {newAll[key] ? newAll[key].replace(/^\[\d+\]/, '') : ''}
          </Link>
        </li>
      )
    })
    : []

  const child = (
    <>
      <li className="trending-search__first">
        <FormattedMessage id="homepage.search_trending" />
      </li>
      <li className="trending-search__first trending-search__first--dot">&#8226;</li>
      {trendingList}
    </>
  )

  return (
    <>
      <Default>
        <Switch>
          <Route exact={true} path="/search">
            <ul className="trending-search list-inline tw-full">{child}</ul>
          </Route>

          <Route>
            <ul className="trending-search list-inline tw-w-9/12 tw-mx-auto">{child}</ul>
          </Route>
        </Switch>
      </Default>
      <Mobile>
        <TrendingSearch>{trendingList}</TrendingSearch>
      </Mobile>
    </>
  )
}

export default Trending
