import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import * as ProductPropsActionCreator from '../actions/productProps'
import { connect } from 'react-redux'
import ProductActiveFilters from '@components/product/ProductActiveFilters'
import injectQuery from '@hoc/injectQuery'
import TranslationText from '@components/common/TranslationText'
import { push } from 'connected-react-router'
import qs from 'qs'

const mapStateToProps = state => ({
  selectedBrands: state.productProps.selectedBrands,
  selectedProps: state.productProps.selectedProps,
  brands: state.productProps.brands,
  props: state.productProps.props,
  router: state.router,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...ProductPropsActionCreator, push }, dispatch),
})

@connect(
  mapStateToProps,
  mapDispatchToProps
)
class ProductActiveFiltersContainer extends Component {
  constructor(props) {
    super(props)

    this.onBrandClick = this.onBrandClick.bind(this)
    this.onPropClick = this.onPropClick.bind(this)

    this.onConfirm = this.onConfirm.bind(this)
  }

  onConfirm() {
    const { selectedBrands, selectedProps, actions } = this.props
    const brands = selectedBrands.join(',')
    const props = selectedProps.map(prop => `${prop.id}:${prop.value.join(',')}`).join(';')

    const { brand: prevBrands, prop: prevProps, ...params } = this.props.query

    this.updateQuries({
      ...params,
      ...(brands && { brand: brands }),
      ...(props && { prop: props }),
    })
  }

  onBrandClick(brand) {
    this.props.actions.togglePropSection(false)
    this.props.actions.updateSelectedBrands(brand)
    setTimeout(this.onConfirm)
  }

  onPropClick(id) {
    this.props.actions.togglePropSection(false)
    const [key, value] = id.split(':')
    this.props.actions.updateSelectedProps(key, value)
    setTimeout(this.onConfirm)
  }

  onCancelLocationFilter = () => {
    const { location, ...params } = this.props.query
    this.updateQuries(params)
  }

  onCancelSortingFilter = () => {
    const { sort, ...params } = this.props.query
    this.updateQuries(params)
  }

  onCancelPriceFilter = () => {
    const { minPrice, maxPrice, ...params } = this.props.query
    this.updateQuries(params)
  }

  updateQuries(quries) {
    const { router } = this.props

    this.props.actions.push({
      pathname: router.location.pathname,
      search: qs.stringify(quries),
    })
  }

  extractActivePrice() {
    const { minPrice, maxPrice } = this.props.query

    const text = (() => {
      if (minPrice && maxPrice) {
        return `¥${minPrice} - ¥${maxPrice}`
      } else if (minPrice) {
        return `> ¥${minPrice}`
      } else if (maxPrice) {
        return `< ¥${maxPrice}`
      }
    })()

    return text
      ? [
          {
            id: 'prices',
            text,
            onClick: this.onCancelPriceFilter,
          },
        ]
      : []
  }

  extractActiveSorting() {
    const {
      query: { sort },
    } = this.props

    const text = (() => {
      switch (sort) {
        case 'sales':
          return 'Sales (30 Days)'
        case 'popularity':
          return 'Most Popular'
        case 'price_asc':
          return 'Price low to high'
        case 'price_desc':
          return 'Price high to low'
      }
    })()

    return text
      ? [
          {
            id: 'sort',
            text: (
              <span className="text-uppercase">
                <>SORTED BY: {text}</>
              </span>
            ),
            onClick: this.onCancelSortingFilter,
          },
        ]
      : []
  }

  extractActiveLocation() {
    const {
      query: { location },
    } = this.props

    return location
      ? [
          {
            id: 'location',
            text: <TranslationText texts={{ zhCn: location }} />,
            onClick: this.onCancelLocationFilter,
          },
        ]
      : []
  }

  getActiveFilters() {
    const { brands = [], selectedBrands, props, selectedProps } = this.props

    const activeLocation = this.extractActiveLocation()
    const activePrice = this.extractActivePrice()
    const activeSorting = this.extractActiveSorting()

    const activeBrand = brands.filter(brand => selectedBrands.indexOf(brand.id) > -1)

    const activeFilters = (activeBrand
      ? activeBrand.map(brand => ({
          id: brand.id,
          text: `BRAND: ${brand.name}`,
          onClick: this.onBrandClick,
        }))
      : []
    )
      .concat(
        ...selectedProps.map(prop => {
          const currentProp = props.find(p => p.id == prop.id)

          return currentProp
            ? prop.value
                .map(val => currentProp.values.find(v => v.id == val))
                .filter(v => v)
                .map(prop => ({
                  id: `${currentProp.id}:${prop.id}`,
                  text: prop.name,
                  onClick: this.onPropClick,
                }))
            : []
        })
      )
      .concat(activeLocation)
      .concat(activeSorting)
      .concat(activePrice)

    return activeFilters
  }

  render() {
    const activeFilters = this.getActiveFilters()

    return activeFilters.length ? <ProductActiveFilters filters={activeFilters} /> : null
  }
}

export default injectQuery(ProductActiveFiltersContainer)
