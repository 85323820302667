import * as ActionCreator from '@actions/tracking'
import * as ACTION from '@constants/tracking'
import { ofType } from 'redux-observable'
import { of } from 'rxjs'
import { catchError, map, switchMap } from 'rxjs/operators'

export const fetchTracking = (action$, state$, { api }) =>
  action$.pipe(
    ofType(ACTION.FETCH_TRACKING_REQUEST),
    switchMap(({ payload }) =>
      api.fetchTracking(payload.id, payload.countries).pipe(
        map(data => ActionCreator.fetchTrackingSuccess(data)),
        catchError(err => of(ActionCreator.fetchTrackingFailed(err)))
      )
    )
  )
