import DottedLine from '@components/common/DottedLine'
import ProgressiveSkeleton from '@components/common/ProgressiveSkeleton'
import { FC } from 'react'

interface ImageSearchThumbnailProps {
  totalProducts: number
  image: string
  loading: boolean
}

const ImageSearchThumbnail: FC<ImageSearchThumbnailProps> = props => {
  return props.loading ? (
    <>
      <div className="container tw-py-4">
        <ProgressiveSkeleton
          width="100%"
          height={50}
          enableMoveAnimation={true}
          className="tw-mt-0"
        />
      </div>

      <DottedLine />
    </>
  ) : (
    <>
      <div className="container tw-py-4 text-center">
        <img className="obj-fit-cover bd-light" width={120} height={120} src={props.image} />
      </div>

      <DottedLine />
    </>
  )
}

export default ImageSearchThumbnail
