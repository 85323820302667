import AddedList from '@components/common/AddingCollectionPopup/AddedList'
import './styles.css'
import { useOuter } from './useOuter'

const Inner = ({ openStyle, ...props }) => {
  const { error, renderCollections } = useOuter(
    props.item,
    props.onAdded,
    props.selectedCollectionId
  )

  const renderError = error && <div className="text-danger text-center tw-mb-6 pb-3">{error}</div>

  const renderList = (
    <div className="mb-lg-4 tw-mt-6 mt-lg-0 tw-pb-2 text-center">
      <AddedList />
    </div>
  )

  return (
    <>
      {/* -- Layout for modal -- */}
      {openStyle === 'modal' && (
        <>
          {renderList}

          {renderError}
          <div className="row">
            <div className="col-md-4 col-sm-4">
              <div className="image-wrapper bd-dark">
                <img src={props.item.pic} className="img-responsive" />
              </div>
            </div>

            {renderCollections}
          </div>
        </>
      )}

      {/* -- Layout for menu -- */}
      {openStyle === 'menu' && (
        <>
          {renderList}

          <div className="p-4 tw-m-4">
            <div className="image-wrapper bd-dark">
              <img src={props.item.pic} className="img-responsive" />
            </div>
          </div>

          <div className="pb-3">{renderCollections}</div>

          {renderError}
          <div className="tw-py-4" />
        </>
      )}
    </>
  )
}

export default Inner
