import React, { FC, useEffect, useState } from 'react'
import SearchQueries from '@components/common/SearchQueries'
import { Link } from 'react-router-dom'
import qs from 'qs'

type ReviewsWithPhotosFilterProps = {
  disabled?: boolean
}

const ReviewsWithPhotosFilter: FC<ReviewsWithPhotosFilterProps> = props => {
  const [withPhoto, setWithPhoto] = useState(false)

  const handleChange = (val: boolean) => {
    setWithPhoto(val)
    props.onChange(val)
  }

  return (
    <SearchQueries>
      {({ reviewWithPictures }, rest) => {
        useEffect(
          () => {
            handleChange(reviewWithPictures)
          },
          [reviewWithPictures]
        )
        useEffect(
          () => {
            props.disabled && handleChange(false)
          },
          [props.disabled]
        )

        return (
          <span
            onClick={() => {
              handleChange(!withPhoto)
            }}
          >
            <Link
              className={`checkbox current-color ${props.className}`}
              to={`${rest.location.pathname}?${qs.stringify({
                ...rest.query,
                pic_only: withPhoto ? 0 : 1,
              })}`}
              onClick={props.onClick}
            >
              <label>
                <input type="checkbox" checked={withPhoto} onChange={() => {}} /> Reviews with
                photos
              </label>
            </Link>
          </span>
        )
      }}
    </SearchQueries>
  )
}

ReviewsWithPhotosFilter.defaultProps = {
  disabled: false,
  className: '',
}

export default ReviewsWithPhotosFilter
