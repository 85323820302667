import { AuthProvider, WechatAuthState } from '@interfaces/auth'
import { isWeChat } from '@utils'
import { getRedirectUri, getWechatOAuthUrl } from '@utils/wechat'
import React, { FC, useState } from 'react'
import QRCodeModal from './QRCodeModal'
import './styles.css'
import config from '../../../../config.json'
import qs from 'qs'

interface WeChatAuthProps {
  state: WechatAuthState
  children?: React.ReactNode
}

const PREFIX = 'data:text/css;base64,'

const DEFAULT_PLACE_HOLDER =
  'LmltcG93ZXJCb3ggLnRpdGxlIHtkaXNwbGF5OiBub25lO30KI3d4X2RlZmF1bHRfdGlwID4gcCB7IGRpc3BsYXk6IG5vbmU7IH0KI3d4X2RlZmF1bHRfdGlwOmJlZm9yZSB7IGNvbnRlbnQ6ICdTY2FuIGluIFdlQ2hhdCB0byBzaWduIGluJzsgfQ=='

const ACCOUNT_BINDING_PLACE_HOLDER =
  'LmltcG93ZXJCb3ggLnRpdGxlIHtkaXNwbGF5OiBub25lO30KI3d4X2RlZmF1bHRfdGlwID4gcCB7IGRpc3BsYXk6IG5vbmU7IH0KI3d4X2RlZmF1bHRfdGlwOmJlZm9yZSB7IGNvbnRlbnQ6ICdTY2FuIGluIFdlQ2hhdCB0byBiaW5kIHlvdXIgYWNjb3VudCc7IH0='

const appid = config.wechat[AuthProvider.WECHAT_OPEN_PLATFORM].appId

const BASE_NAME = 'https://open.weixin.qq.com/connect/qrconnect'

// Two way to ask the code from wechat
// 1. Redirect to wechat site when it's in the wechat browser (public account)
// 2. Display QR code to allow user to scan (open platform)
const WeChatAuth: FC<WeChatAuthProps> = ({ state, children, bind }) => {
  const [open, setOpen] = useState<boolean>(false)

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    // Ignore the default behaviour of the anchor and open the modal instead
    if (!isWeChat()) {
      e.preventDefault()
      setOpen(true)
    }
  }

  const wechatUrlParams = {
    appid,
    self_redirect: false,
    redirect_uri: getRedirectUri(),
    response_type: 'code',
    scope: 'snsapi_login',
    state: bind ? WechatAuthState.BIND_ACCOUNT : WechatAuthState.OPEN_PLATFORM_AUTH,
    href: bind ? `${PREFIX}${ACCOUNT_BINDING_PLACE_HOLDER}` : `${PREFIX}${DEFAULT_PLACE_HOLDER}`,
  }

  const qrUrl = [BASE_NAME, qs.stringify(wechatUrlParams)].join('?')

  //     const pathname = (() => {
  //       if (this.props.state?.from?.pathname) {
  //         return this.props.state.from.pathname
  //       }
  //       return location.pathname
  //     })()
  //
  //     const redirectUrl = `${location.origin}${pathname}?${encodeURIComponent(location.search)}&public=0`
  const url = getWechatOAuthUrl(state)

  return (
    <>
      {/*<a href={url} onClick={handleClick}>*/}
      <a href={isWeChat() ? url : qrUrl}>
        {children || (
          <div styleName="wechatIcon">
            <i className="icon-wechat fa-2x" />
          </div>
        )}
      </a>

      <QRCodeModal bind={bind} open={open} onClose={() => setOpen(false)} />
    </>
  )
}

export default WeChatAuth
