import DottedLine from "@components/common/DottedLine"
import { uniqBy } from 'lodash'
import List from "@components/home/Reviews/List"
import ViewMoreText from "@components/home/Reviews/List/ViewMoreText"
import { useReviews } from "@hooks/useReviews"
import { Rating } from "@interfaces/reviews"
import React, { FC, useEffect } from 'react'
import { ReviewTarget } from '@interfaces/reviews'

interface ReviewsBySearchProps {
  keyword: string,
  setReviewsLoading?: (loading: boolean) => void
}

const ReviewsBySearch: FC<ReviewsBySearchProps> = (props) => {
  const {
    loading, data, upvote, downvote, meta: { totalCount: totalReviews, totalPages }
  } = useReviews({
    sq: props.keyword,
    target: ReviewTarget.SEARCH
  })

  useEffect(() => {
    if(props.setReviewsLoading) {
      props.setReviewsLoading(loading)
    }
  }, [loading])

  const reviews = data.reviews

  const renderReviews = (
    <>
      <List
        loading={loading}
        reviews={reviews.slice(0, 3)}
        users={data.users}
        products={data.products}
        handleUpvote={upvote}
        handleDownvote={downvote}
      >
        {/* Display *view more text* only when none of reviews are most recent */}
        {
          totalPages > 1 && (
            <ViewMoreText
              alias={props.keyword}
              totalReviews={loading ? -1 : totalReviews}
            />
          )
        }
      </List>
      {reviews.length > 0 && <DottedLine />}
    </>
  )

  return props.children?.({ renderReviews, totalReviews, loading }) || null
}

export default ReviewsBySearch
