import React from 'react'
import './styles.css'

class ItemsWithLineThrough extends React.PureComponent {
  render() {
    const classNames = ['list-inline', 'list-unstyled', 'tw-mb-0', 'relative']

    if (this.props.dotted) {
      classNames.push('dotted')
    }

    return (
      <ul className={`${classNames.join(' ')} ${this.props.className}`} styleName="wrapper">
        {this.props.children.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    )
  }
}

ItemsWithLineThrough.defaultProps = {
  className: '',
  dotted: false,
}

export default ItemsWithLineThrough
