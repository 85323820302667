export default {
  ADD_ITEM_TO_CART_REQUEST: '@CART/ADD_ITEM_TO_CART_REQUEST',
  ADD_ITEM_TO_CART_SUCCESS: '@CART/ADD_ITEM_TO_CART_SUCCESS',
  ADD_ITEM_TO_CART_FAILURE: '@CART/ADD_ITEM_TO_CART_FAILURE',

  FETCH_CART_REQUEST: '@CART/FETCH_CART_REQUEST',
  FETCH_CART_SUCCESS: '@CART/FETCH_CART_SUCCESS',
  FETCH_CART_FAILURE: '@CART/FETCH_CART_FAILURE',

  UPDATE_CART_ITEM_QTY_REQUEST: '@CART/UPDATE_CART_ITEM_QTY_REQUEST',
  UPDATE_CART_ITEM_QTY_SUCCESS: '@CART/UPDATE_CART_ITEM_QTY_SUCCESS',
  UPDATE_CART_ITEM_QTY_FAILURE: '@CART/UPDATE_CART_ITEM_QTY_FAILURE',

  REMOVE_CART_ITEM_REQUEST: '@CART/REMOVE_CART_ITEM_REQUEST',
  REMOVE_CART_ITEM_SUCCESS: '@CART/REMOVE_CART_ITEM_SUCCESS',
  REMOVE_CART_ITEM_FAILURE: '@CART/REMOVE_CART_ITEM_FAILURE',

  SELECT_CART_ITEMS: '@CART/SELECT_CART_ITEMS',
  DESELECT_CART_ITEMS: '@CART/DESELECT_CART_ITEMS',
} as const
