import { handleEnter } from '../../TextInputWithDropdown'
import { useEffect } from 'react'
import useScroll from '@hooks/useScroll'
import { useAtomValue } from 'jotai'
import { discountNavAtom } from '@atoms/discount-nav'
import './styles.css'

export const Input = props => {
  const { scrollDirection } = useScroll()

  const hasDiscountNav = useAtomValue(discountNavAtom)

  useEffect(
    () => {
      if (scrollDirection === 'down') {
        document.activeElement?.blur();
      }
    },
    [scrollDirection]
  )

  return (
    <div
      className="form-group tw-mb-0 tw-fixed tw-top-0 tw-left-0"
      data-hasDiscountNav={!!hasDiscountNav}
      styleName="wrapper"
      id="searchBarInput"
    >
      <div className="input-group">
        <span
          className="input-group-addon tw-border-l-0 tw-border-t-0 tw-h-20 tw-px-7"
          onClick={() => {
            if (props.location.key) {
              props.history.goBack()
            } else {
              props.history.replace('/')
            }
          }}
        >
          {props.location.key ? (
            <i className="icon-arrow-left" />
          ) : (
            <i className="icon-section-home" />
          )}
        </span>
        <input
          value={props.value}
          onChange={props.onChange}
          onKeyPress={e => e.key === 'Enter' ?
            props.matchedSuggestion ?
              props.onEnter(props.matchedSuggestion) :
              handleEnter(props.value.trim().replace('%', ''), props.onEnter) :
            null
          }
          type="text"
          className="form-control tw-border-r-0 tw-h-20"
          placeholder="What are you looking for?"
          autoFocus
        />
      </div>
    </div>
  )
}
