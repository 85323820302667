import * as ActionCreator from '@actions/paymentConfig'
import constants from '@constants/paymentConfig'
import { of } from 'rxjs'
import { catchError, filter, ignoreElements, map, switchMap, tap } from 'rxjs/operators'
import { isOfType } from 'typesafe-actions'
import { Epic } from './index'

export const paymentConfig: Epic = (action$, state$, { api }) =>
  action$.pipe(
    filter(isOfType(constants.FETCH_PAYMENT_CONFIG_REQUEST)),
    switchMap(() => api.fetchPaymentConfig().pipe(map(ActionCreator.fetchPaymentConfigSuccess))),
    catchError(err => of(ActionCreator.fetchPaymentConfigFailed(err)))
  )
