import { useProductQA } from '@hooks/useProductQA'
import React, { useEffect, useState } from 'react'
import { Tab } from 'react-bootstrap'

import TranslationText from '@components/common/TranslationText'
import ProductQAndA from '@components/product/ProductQAndA'
import Reviews from '../Reviews'
import ProductDescription from '../Tabs/Description'
import Navs from './Navs'

import ProductDesc from '@components/product/JD/ProductDesc'
import { useLocation, useQuery } from '@hooks/useRouter'
import { Platform } from '@interfaces/platform'
import { cloudObjectToURL } from '@utils/'
import './styles.css'

const ProductOverview = ({ product, ...props }) => {
  const query = useQuery()
  const { location } = useLocation()

  useEffect(() => {
    // if (location.state && location.state.fromHome === 1) {
    //   scrollFromHome('product-overview-tab')
    // }
  }, [])

  // Have to fetch QA here due to there's no counter for the qa total count
  // We should sending request only when the qa tab in active state
  const { createQA, loading, questions, users } = useProductQA(product.id, product.platform)
  const handleSubmit = ({ question }: { question: string }) =>
    createQA(product.id, question, product.platform)

  return (
    <Tab.Container
      id="product-overview-tab"
      defaultActiveKey={query.tab ? query.tab : 'details'}
    >
      <div styleName="wrapper">
        <Navs
          qaCount={questions.length}
          reviewsCount={product.reviewsCount}
          productId={product.id}
          taoabaoReviewsCount={product.taobaoReviewsCount}
        />

        <div styleName="tabs-body">
          <Tab.Content animation={true} unmountOnExit={true}>
            <Tab.Pane eventKey="reviews" className="text-center">
              <Reviews
                reviewId={product.internalId}
                platform={product.platform}
                sellerId={product.sellerId}
                productId={product.id}
                translatedTitle={product.titles.en}
                originalTitle={product.titles.zhCn}
                productImage={cloudObjectToURL(product.cover)}
                categories={product.categories}
                product={product}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="details">
              <ProductDescription
                id={product.id}
                platform={product.platform}
                sold={product.sold}
                properties={product.properties}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="qa" className="text-center">
              <ProductQAndA
                loading={loading}
                questions={questions}
                users={users}
                onCreate={handleSubmit}
                productId={product.id}
                image={product.cover}
                title={product.titles?.en}
              />
            </Tab.Pane>
          </Tab.Content>
        </div>
      </div>
    </Tab.Container>
  )
}

export default ProductOverview
