import React, { useEffect, useState } from 'react'
import ajax from '../../../../api/ajax'
import TranslationText from '@components/common/TranslationText'
import SEO from '@components/SEO'
import { useParams } from '@hooks/useRouter'
import { Platform } from '@interfaces/platform'
import { fetchJdCategoryDetails } from '@services/category'
import { platformToProvider } from '@utils/platform'
import {pluck} from 'rxjs/operators'

const description = (title: string) =>
  `Shop a wide selection of ${title} on Baopals.com. Baopals brings you over` +
  " 1 bilion products from China's shopping platforms with great low prices," +
  ' fast delivery and friendly service from your pals.'

interface CategoryDetailProps {
  alias?: string
  platform?: Platform
}

const CategoryDetail: React.FC<CategoryDetailProps> = props => {
  const [title, setTitle] = useState<string>(props.alias)
  const params = useParams()

  useEffect(() => {
    if (!title) {
      if (props.platform === Platform.JD) {
        const cat = fetchJdCategoryDetails(params.id)
        setTitle(cat?.name || '')
      } else {
        ajax({
          url: `/products/categories/${params.id}?provider=${platformToProvider(props.platform)}`
        }).pipe(pluck('response')).toPromise()
          .then(data => {
            if (data.name) {
              setTitle(data.name)
            }
          })
          .catch(() => {})
      }
    }
  }, [])

  if (!title) {
    return null
  }

  return (
    <TranslationText texts={{ zhCn: title }}>
      {text => (
        <>
          <SEO title={text} description={description(text)} />
          <h1 className="categoryPage__header bodo">{text}</h1>
        </>
      )}
    </TranslationText>
  )
}

CategoryDetail.defaultProps = {
  platform: Platform.TAOBAO,
}

export default CategoryDetail
