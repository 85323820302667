import NewUserTooltips from '@components/common/NewUserTooltips'
import { data, tooltips } from '@components/common/NewUserTooltips/json'

const NotificationsTour = ({ width }) => {
  const steps = [
    {
      target: '#tour-notifications',
      content: data.notifications.text,
      type: tooltips.NOTIFICATIONS,
      styles: {
        options: {
          ...(width && { width: width })
        }
      }
    },
  ]

  return <NewUserTooltips steps={steps} />
}

export default NotificationsTour
