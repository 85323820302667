import { SYSTEM_UNAVAILABLE } from '@constants/'
import { HttpStatus } from '@constants/httpStatus'
import { ReactNode } from 'react'
import React from 'react'
import { map } from '../api/ErrorsMapping'
import { HttpError } from './HttpError'
import UnknownError from './UnkownError'

const fortuneErrorText = (
  <span>
    You cannot post while your Fortune is negative. You can earn more Fortune by reviewing purchases
    and receiving upvotes on your reviews. Downvotes cause Fortune to decrease.
  </span>
)

export const mapping: { [key: number]: ReactNode } = {
  0x1706: 'This product is already in this wishlist.',
  0x1704: (params: { limit: number }) =>
    `You may not have more than ${params.limit
    } products in a wishlist. Please create a new wishlist or remove products from this wishlist in order to add this item.`,

  4097: params =>
    `The quantity you entered is over the maximum quantity allowed for this product (maximum is ${params.maximum
    })`,
  4102: params =>
    `You reached the limit of items you can add to your cart (maximum is ${params.limit}).`,
  4099: params =>
    `The quantity you entered is below minimum quantity for this item (minimum is ${params.minimum
    })`,

  5889: params => `You can't have more than ${params.limit} wishlists.`,
  10498: 'Hey pal, it looks like there is no tracking on this item. Try another request.',
  9220: 'Criteria for this request is not met.',
  9217: 'You already have the maximum number of active tickets.',
  9729: 'The filetype you are attempting to upload is not allowed',
  16385: 'You have already been refunded for this item.',
  16387: 'Sorry, this item has a no return policy.',
  10246: 'Slow down there partner! You can only make three posts every fifteen minutes.',
  10247: "This item hasn't been processed. How can you leave a review?!",
  8450: 'Slow down there partner! You can only make three posts every fifteen minutes.',
  8453: fortuneErrorText,
  10245: fortuneErrorText,
  8454: 'You have been banned from posting content.',
  21249: 'Slow down partner! You can only dish out 5 downvotes every 15 minutes. Take a minute and let the rage subside.',
  21508: 'This transaction has been rejected due to suspected fraud.',
  21509: 'This card is either expired and no longer in circulation, or has been cancelled or reported stolen.',
  21510: 'Your issuing bank will not validate this transaction. Please contact your issuing bank for assistance.',
  21511: 'Invalid card number.',
  21512: 'You are unable to complete this transaction due to insufficient funds.',
  21513: 'Processor declined. Your issuing bank has refused the transaction request.',
  21514: 'Service unavailable.',
}

export class BadRequestError extends HttpError {
  public name = 'BadRequestError'
  public error: any
  public message: string
  public code: number

  constructor(error?: any) {
    super(HttpStatus.BAD_REQUEST)
    Object.setPrototypeOf(this, BadRequestError.prototype)

    const body = mapping[error.code]

    this.code = error.code

    this.message =
      (typeof body === 'function' ? body(error) : body) || error.message || SYSTEM_UNAVAILABLE

    const Err = map(error)

    if (Err === UnknownError && error.code) {
      this.error = new Err(error.code)
    } else if (error) {
      this.error = new Err(error)
    }
  }
}
