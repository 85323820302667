export default {
  FETCH_MY_WALLET_REQUEST: '@WALLET/FETCH_MY_WALLET_REQUEST',
  FETCH_MY_WALLET_SUCCESS: '@WALLET/FETCH_MY_WALLET_SUCCESS',
  FETCH_MY_WALLET_FAILURE: '@WALLET/FETCH_MY_WALLET_FAILURE',

  // refund payment
  REFUND_PAYMENT_REQUEST: '@WALLET/REFUND_PAYMENT_REQUEST',
  REFUND_PAYMENT_SUCCESS: '@WALLET/REFUND_PAYMENT_SUCCESS',
  REFUND_PAYMENT_FAILURE: '@WALLET/REFUND_PAYMENT_FAILURE',

  // decrease balance
  UPDATE_WALLET_BALANCE: '@WALLET/DECREASE_WALLET_BALANCE',
} as const
