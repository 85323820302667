import { capitalized } from '@utils/'
import { isUppercase } from '@utils/'
import { GENERAL_MARKET_SELLER, SELLER_PLATFORMS } from '@utils/productUtils'
import { FC } from 'react'
import './styles.css'

export const formatSeller = (name: string, { ignoreUppercase } = { ignoreUppercase: false }) => {
  return name
    ? `${name || ''}`
        .split(' ')
        .map(str => (ignoreUppercase && isUppercase(str) ? str : str.toLowerCase()))
        .join(' ')
        .replace(/jingdong/gi, 'JD')
        .replace(/self.operated/gi, '')
        .split(' ')
        .map(capitalized)
        .map(s => s.trim())
        .join(' ')
        .replace('Jd.com', '')
        .replace(/jd\'s/gi, '')
        .replace(/Jd/gi, '')
        .replace(/Of/gi, 'of')
    : ''
}

const formattedSellerName = {
  [GENERAL_MARKET_SELLER]: 'BAOPALS SUPERMARKET',
}

const FormattedSeller: FC<{ id: number; name: string }> = ({ id, name }) => {
  const _name = `${formattedSellerName[id] || name || ''}`
  const lname = _name.toLowerCase()
  return (
    <>
      <span data-seller-name={lname} styleName="seller">
        {_name.split(' ').map((text, i) => (
          <span key={i} data-text={(text || '').toLowerCase()} styleName="text">
            <span styleName="inner">{text || ''}</span>{' '}
          </span>
        ))}
      </span>
    </>
  )
}

export default FormattedSeller
