import PreventBodyScroll from '@components/common/PreventBodyScroll'
import Tour from '@components/common/Tour'
import useAuth from '@hooks/useAuth'
import { dismissTooltip } from '@services/me'
import { intersection, difference, intersectionBy } from 'lodash'
import React, { useLayoutEffect, FC, useState } from 'react'
import { CallBackProps, EVENTS, STATUS, Step, StoreHelpers } from 'react-joyride'
import { tooltips } from '@components/common/NewUserTooltips/json'

interface NewUserTooltipsProps {
  steps: Step[]
  tooltip?: string[]
  disableScrolling?: boolean
  options?: any
  disableOverlayClose?: boolean
  callback?: any
  alwaysShow?: boolean
  scrollOffset?: number
}

const NewUserTooltips: FC<NewUserTooltipsProps> = props => {
  const { updateToolTips, user } = useAuth()
  const [run, setRun] = useState<boolean>(false)
  const isNotificationTourRead = props.steps[0]?.target === '#tour-notifications' ? true : !user?.tooltips.includes(tooltips.NOTIFICATIONS)

  const stepsInCommon = props.alwaysShow ? props.steps : intersectionBy(props.steps, user?.tooltips?.map?.(t => ({ type: t})) ?? [], 'type').map(s => ({ ...s, disableBeacon: true }))

  const inCommonSize = stepsInCommon.length

  const stepsType = stepsInCommon.map(s => s.type)

  useLayoutEffect(() => {
    if (inCommonSize > 0 && isNotificationTourRead) {
      setRun(true)
    }
  }, [inCommonSize, isNotificationTourRead])

  const dismiss = () => {
    const sub = dismissTooltip(stepsType.join(',')).subscribe(
      () => {
        const userTooltips = difference(user.tooltips, intersection(user.tooltips, stepsType))
        updateToolTips(userTooltips)
        setRun(false)
      },
      err => {
        console.warn(err)
      }
    )

    return () => sub.unsubscribe()
  }

  const handleCallBack = (data: CallBackProps) => {
    const { status, type } = data

    if (status === STATUS.FINISHED && type === EVENTS.STEP_AFTER) {
      return props.callback ? props.callback() : dismiss()
    }
  }

  return (
    <>
      <Tour
        showProgress={inCommonSize > 1}
        steps={stepsInCommon}
        callback={handleCallBack}
        disableScrolling={props.disableScrolling}
        showSkipButton={false}
        disableCloseOnEsc={false}
        run={run}
        continuous={true}
        getHelpers={(helpers: StoreHelpers) => helpers}
        hideBackButton={true}
        spotlightPadding={5}
        scrollOffset={props.scrollOffset || 1000}
        scrollDuration={300}
        locale={{ last: 'Got it' }}
        disableOverlayClose={props.disableOverlayClose}
        options={props.options}
        scrollToFirstStep
      />

      <PreventBodyScroll enabled={run} />
    </>
  )
}

NewUserTooltips.defaultProps = {
  disableOverlayClose: true
}

export default NewUserTooltips
