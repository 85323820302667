import React from 'react'

const EmptyCart = () => {
  return (
    <div className="text-center">
      Your cart is empty.{' '}
      <a className="blue-link bold" href="https://www.baopals.com/">
        Start shopping!
      </a>
    </div>
  )
}

export default EmptyCart
