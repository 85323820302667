import LineThroughText from '@components/common/LineThroughText'
import React from 'react'
import { Modal } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import './styles.css'

const Heading = ({ cancelable, onClose }) => {
  const close = (
    <div styleName="close" onClick={onClose}>
      <i className="icon-close-round" />
    </div>
  )

  return (
    <>
      <div className="hidden-xs hidden-sm bodo" styleName="wrapper">
        <LineThroughText lineWidth={110}>
          <FormattedMessage id="login_modal.header" />
        </LineThroughText>
      </div>
      {cancelable ? close : <Link to="/">{close}</Link>}
      <div className="bodo visible-xs visible-sm" styleName="heading">
        <FormattedMessage id="login_modal.header" />
      </div>
    </>
  )
}

Heading.defaultProps = {
  cancelable: true,
}

export default Heading
