import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import './styles.css'

type ProgressiveSkeletonProps = {
  enableMoveAnimation?: boolean;
  width?: string | number;
  height?: string | number;
  className?: string;
  styleName?: string;
  circle?: boolean;
}

const ProgressiveSkeleton: React.SFC<ProgressiveSkeletonProps> = ({ width, height, circle, ...props }) => {
  return (
    <div 
      styleName='skeleton' 
      className={`${props.className}`}
      style={{ width, height, ...( circle ? { borderRadius: '50%' } : {} ) }}>
      { props.enableMoveAnimation && <span styleName='inner' style={{ width: height, height }}></span> }
    </div>
  )
}

ProgressiveSkeleton.defaultProps = {
  width: 40,
  height: 40,
  className: '',
  enableMoveAnimation: false,
}

export default ProgressiveSkeleton
