import PriceWithCurrency from '@components/common/PriceWithCurrency'
import {toProductLinkStr} from '@components/common/ProductLink'
import {Image} from '@interfaces/image'
import {Platform} from '@interfaces/platform'
import {cloudObjectToURL} from '@utils/'
import {calcBaopalPrice} from '@utils/price'
import React from 'react'
import {Link} from 'react-router-dom'
import { DEFAULT_PRODUCT_PLACEHOLDER } from '@constants/index'
import './styles.css'

interface ProductProps {
  // one of cover and image should be exsit to show the product pic
  cover?: Image
  image?: string

  // one of cover and image should be exsit to make the link
  // disable link if both data are null
  url?: string
  productId?: string

  // show renderSticker or show price sticker if price exsit
  enableSticker?: boolean
  renderSticker?: string | React.ReactNode
  price?: number

  // show title on overlay if title exsit
  enableHover?: boolean
  title?: string

  className?: string
  styleName?: string
}

const ProductWithSticker: React.SFC<ProductProps> = ({
  cover,
  image,
  url,
  productId,
  price,
  title,
  platform,
  ...props
}) => {
  const link = productId ? toProductLinkStr(productId, {platform}) : url ? url : null

  const pic = image ? image : cloudObjectToURL(cover, {size: '250x250', crop: 'scale'}) || DEFAULT_PRODUCT_PLACEHOLDER
  const imageStyle = { backgroundImage: `url("${pic}")` }

  const renderOverlay = () => (
    <>
      <div className="bg-fit-center tw-w-full tw-h-full" styleName="image" style={imageStyle} />

      <div className="tw-w-full" styleName="overlay">
        {title && renderTitle(title)}
        {props.enableSticker && (props.renderSticker || (price && rendetPrice(price)))}
      </div>
    </>
  )

  const overlay = renderOverlay()

  return (
    <div
      className={`bd-light bg-white relative ${props.className || ''}`}
      styleName={props.enableHover ? 'container' : ''}
    >
      {!link ? (
        renderOverlay()
      ) : (
        <>{productId ? <Link to={link}>{overlay}</Link> : <a href={link}>{overlay}</a>}</>
      )}
    </div>
  )
}

ProductWithSticker.defaultProps = {
  enableHover: true,
  enableSticker: true,
}

export default ProductWithSticker

const renderTitle = (title: string) => (
  <div className="upper bold black-text line-max line-max-2" styleName="title">
    {title}
  </div>
)

const rendetPrice = (price: number) => (
  <div className="white-text bold" styleName="sticker">
    <PriceWithCurrency price={+calcBaopalPrice(price / 100) * 100} />
  </div>
)
