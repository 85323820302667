import { resizeImage } from '@utils/image'
import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import './styles.css'

interface QueryLink {
  pathname: string
  search?: string
  state?: any
}

interface ImageProps {
  pic?: string
  link?: React.ReactNode

  disableOverlay?: boolean
  OverlayInfo?: React.ReactNode
  OverlayClassName?: string

  picWidth?: number
  picHeight?: number
  className?: string
  styleName?: string

  circle?: boolean
}

const renderOverlay = (info: React.ReactNode, className: string) => (
  <div className={className} styleName="overlay">
    {info}
  </div>
)

const ImageWithOverlay: FC<ImageProps> = props => {
  const imageSrc = props.pic || BAOPALS.IMAGE_PLACEHOLDER

  const renderImage = (img: string) => (
    <div
      className={`bg-fit-center ${props.circle ? 'bd-circle' : ''}`}
      styleName="image"
      style={{ backgroundImage: `url("${img}")` }}
    />
  )

  return (
    <div
      className={`relative bd-light ${props.circle ? 'bd-circle' : ''} ${props.className}`}
      styleName={`wrapper ${props.disableOverlay ? '' : 'hover'}`}
    >
      <>
        {renderImage(imageSrc)}
        {!props.disableOverlay && renderOverlay(props.OverlayInfo, props.OverlayClassName)}
        <span className="abs-overlay">{props.link}</span>
      </>
    </div>
  )
}

ImageWithOverlay.defaultProps = {
  circle: false,
  picWidth: 150,
  picHeight: 150,
  className: '',
  disableOverlay: false,
  OverlayClassName: 'flex-center-center text-center fs12 upper bold',
  OverlayInfo: (
    <div>
      <FormattedMessage id="general.view" defaultMessage="view" />
    </div>
  ),
}

export default ImageWithOverlay
